import { truncate } from "lodash";
import { Button } from "PFComponents/button";
import { Markdown } from "PFComponents/markdown";
import { useTranslation } from "react-i18next";

import css from "./side_panel.module.scss";

const SUMMARY_LENGTH_LIMIT = 150;

type SummarySectionProps = {
  summary: string;
  onClickMore: () => void;
};

export const SummarySection = ({ summary, onClickMore }: SummarySectionProps): JSX.Element => {
  const { t } = useTranslation("profiles");
  return (
    <div className={css.summary}>
      <Markdown
        raw={truncate(summary, {
          length: SUMMARY_LENGTH_LIMIT
        })}
        crop={1e10}
        emojify={false}
      />
      {summary.length > SUMMARY_LENGTH_LIMIT && (
        <Button style={{ fontSize: 13, transform: "translateY(-2px)" }} kind="text" onClick={onClickMore}>
          {t("show.parts.moreEllipsis")}
        </Button>
      )}
    </div>
  );
};
