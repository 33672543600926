import { isEmpty } from "lodash";
import AutoSelect from "PFComponents/select/autoselect";
import { Typography } from "PFComponents/typography";
import { useDeterministicStringify } from "PFCore/helpers/use_deterministic_stringity";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { letFilterRestore } from "../../use_filtered_collection";
import { ProfileItem } from "../profile_item";

const normalizeValue = (value) => {
  if (value && typeof value === "string") {
    value = { id: value.trim(), text: "" };
  }
  if (!value) {
    return [];
  }
  return Array.isArray(value) ? value : [value];
};

const SelectOneItem = ({ label, labelTooltip, filter, handleChange, error, portalRef, disabled }) => {
  const [displayValues, setDisplayValues] = useState("");
  const stringify = useDeterministicStringify();

  const value = [filter.value].flat().filter(Boolean);

  useEffect(() => {
    if (isEmpty(value)) {
      setDisplayValues(null);
    } else {
      filter.query({ value }).then((resp) => {
        if (resp.entries.length > 0) {
          setDisplayValues(resp.entries[0].text);
        }
      });
    }
  }, [stringify(value)]);

  const letRestore = letFilterRestore(filter);

  return (
    <AutoSelect
      disabled={filter.disabled || disabled}
      key={filter.value?.id || filter.value}
      query={(term) => filter.query({ term })}
      handleChange={(value) => {
        if (value && value[0]) {
          handleChange(value[0].id, value);
        } else {
          handleChange(null);
        }
      }}
      formatOption={
        filter.name === "workforce_member_id"
          ? (item) => ({
              id: item.id,
              displayElement: <ProfileItem profile={item} />,
              item
            })
          : null
      }
      letClear={!isEmpty(value)}
      onRestore={letRestore ? () => handleChange(filter.defaultValue) : undefined}
      closeOnChange={true}
      multi={false}
      allowSameValueInOptions={true}
      values={normalizeValue(filter.value)}
      showValues={false}
      label={label}
      labelTooltip={labelTooltip}
      // TODO: [PROF-7192] - remove workaround with displaying value (introduced in PROF-7187)
      displayValues={
        <Typography variant="bodyBold" noMargin clipOverflow>
          {displayValues}
        </Typography>
      }
      hideDisplayValuesOnFocus
      error={error}
      portalRef={portalRef}
    />
  );
};

SelectOneItem.propTypes = {
  handleChange: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
    defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    title: PropTypes.string,
    query: PropTypes.func,
    disabled: PropTypes.bool
  }).isRequired,
  label: PropTypes.string,
  labelTooltip: PropTypes.shape({
    icon: PropTypes.string,
    content: PropTypes.string
  }),
  error: PropTypes.string,
  portalRef: PropTypes.object,
  disabled: PropTypes.bool
};

export default SelectOneItem;
