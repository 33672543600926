import { useGrowl } from "PFApp/use_growl";
import { Button } from "PFComponents/button";
import ConfirmModal from "PFComponents/confirm/confirm_modal";
import { LoadingDots } from "PFComponents/loading_dots";
import { getProfileName, isSaasAdmin } from "PFCore/helpers/profile";
import { useDeleteUser } from "PFCore/hooks/queries/admin/use_delete_user";
import { useUser } from "PFCore/hooks/queries/admin/use_user";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { resetRegistrationStatus } from "PFCore/services/admin/users/reset_registration_status";
import { sendWelcomeEmail } from "PFCore/services/admin/users/send_welcome_emails";
import { forgotPassword } from "PFCore/services/signup/forgot_password";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import css from "./admin_info.module.scss";

const renderPair = (headerText, value) => (
  <tr>
    <th>{headerText}</th>
    <td style={{ paddingLeft: 10 }}>{value}</td>
  </tr>
);

export const AdminInfo = ({ profile }) => {
  const { data: currentProfile } = useCurrentProfile();
  const growl = useGrowl();
  const { t } = useTranslation("profiles", { keyPrefix: "edit.sections.admin" });

  const { data: queryUser, isFetched } = useUser(profile.id);
  const { mutate: deleteUser } = useDeleteUser(profile.id, {
    onSuccess: (resp) => {
      growl({ message: resp.message });
      history.push("/admin");
    }
  });

  const history = useHistory();
  const [displayRemoveConfirm, setDisplayRemoveConfirm] = useState(false);
  const [displayResetPasswordConfirm, setDisplayResetPasswordConfirm] = useState(false);
  const [displayResetRegistrationConfirm, setDisplayResetRegistrationConfirm] = useState(false);
  const [displayResendWelcomeConfirm, setDisplayResendWelcomeConfirm] = useState(false);

  const handleResetPassword = () => {
    forgotPassword({ email: profile.email }).then(() =>
      growl({ message: t("info.resetPassword.successMessage") })
    );
  };

  const handleResetRegistration = () => {
    resetRegistrationStatus(profile.id).then(
      () => growl({ message: t("info.resetRegistration.successMessage") }),
      () =>
        growl({
          message: t("info.resetRegistration.errorMessage"),
          kind: "error"
        })
    );
  };

  const handleResendWelcome = () => {
    sendWelcomeEmail(profile.id).then(
      () => growl({ message: t("info.welcomeEmails.successMessage") }),
      () =>
        growl({
          message: t("info.welcomeEmails.errorMessage"),
          kind: "error"
        })
    );
  };

  const canAlter = isSaasAdmin(currentProfile) || !isSaasAdmin(profile);
  const canDelete = isSaasAdmin(currentProfile) && currentProfile.id !== profile.id;

  const displayName = getProfileName(profile) || profile.email;

  return (
    <React.Fragment>
      <div kind="generic" style={{ marginBottom: 30 }}>
        <div className={css.alertButtons}>
          {canAlter && (
            <React.Fragment>
              <Button
                kind="secondary"
                qaId="resetPassword"
                onClick={() => setDisplayResetPasswordConfirm(true)}
              >
                {t("info.resetPassword.buttonText")}
              </Button>
              <Button
                kind="secondary"
                qaId="resetRegistration"
                onClick={() => setDisplayResetRegistrationConfirm(true)}
              >
                {t("info.resetRegistration.buttonText")}
              </Button>
              <Button
                kind="secondary"
                qaId="sendWelcome"
                onClick={() => setDisplayResendWelcomeConfirm(true)}
              >
                {t("info.welcomeEmails.buttonText")}
              </Button>
            </React.Fragment>
          )}
          {canDelete && (
            <Button kind="danger" onClick={() => setDisplayRemoveConfirm(true)}>
              {t("translation:remove")}
            </Button>
          )}
        </div>
        {!isFetched && <LoadingDots />}
        {isFetched && (
          <table>
            <tbody>{renderPair(t("info.messagesSent"), queryUser.messages_sent_count)}</tbody>
          </table>
        )}
      </div>
      {displayRemoveConfirm && (
        <ConfirmModal
          title={t("info.deleteUser.title")}
          handleOK={deleteUser}
          handleClose={() => setDisplayRemoveConfirm(false)}
          kindOK="danger"
        >
          {t("translation:sureToDelete", { key: displayName })}
        </ConfirmModal>
      )}
      {displayResetPasswordConfirm && (
        <ConfirmModal
          title={t("info.resetPassword.title")}
          handleOK={handleResetPassword}
          handleClose={() => setDisplayResetPasswordConfirm(false)}
        >
          {t("info.resetPassword.confirmMessage", { name: displayName })}
        </ConfirmModal>
      )}
      {displayResetRegistrationConfirm && (
        <ConfirmModal
          title={t("info.resetRegistration.title")}
          handleOK={handleResetRegistration}
          handleClose={() => setDisplayResetRegistrationConfirm(false)}
        >
          {t("info.resetRegistration.confirmMessage", { name: displayName })}
        </ConfirmModal>
      )}
      {displayResendWelcomeConfirm && (
        <ConfirmModal
          title={t("info.welcomeEmails.title")}
          handleOK={handleResendWelcome}
          handleClose={() => setDisplayResendWelcomeConfirm(false)}
        >
          {t("info.welcomeEmails.confirmMessage", { name: displayName })}
        </ConfirmModal>
      )}
    </React.Fragment>
  );
};

AdminInfo.propTypes = {
  profile: PropTypes.any.isRequired
};
