import InlineRangeCalendar from "PFComponents/calendar/inline_range_calendar";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import PropTypes from "prop-types";

import ComponentPropTypes from "./component_prop_types";

const InlineRangeCalendarWrapper = ({ value, label, onChange, qaId, style, error, required }) => {
  const { formatISODate, formatDatabaseDateTime } = useDateFormatter();
  return (
    <InlineRangeCalendar
      startDate={value && value.start_date && formatISODate(value.start_date)}
      endDate={value && value.end_date && formatISODate(value.end_date)}
      label={label}
      qaId={qaId}
      required={required}
      style={{ ...style, marginBottom: 30 }}
      error={error && Object.values(error)[0]}
      handleChange={(start, end) => {
        if (start === null && end === null) {
          // to handle clear, as the API does not allowed null values for start or end date
          onChange(null);
        } else {
          onChange({
            start_date: formatDatabaseDateTime(start),
            end_date: formatDatabaseDateTime(end)
          });
        }
      }}
      letClear
    />
  );
};

InlineRangeCalendarWrapper.propTypes = {
  ...ComponentPropTypes,
  value: PropTypes.object,
  error: PropTypes.object
};

export default InlineRangeCalendarWrapper;
