import { debounce } from "lodash";
import { RequirementType } from "PFTypes";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

import { NumberInput } from "./number_input";
import css from "./requirement_option.module.scss";

interface RequirementOptionProps {
  id: RequirementType;
  enabled: boolean;
  onChange: (value: number | undefined) => void;
  max?: number;
  defaultValue: number | undefined;
  autofocus: boolean;
  error: boolean;
}

export const RequirementOption = ({
  id,
  enabled,
  onChange,
  max,
  defaultValue,
  autofocus,
  error
}: RequirementOptionProps) => {
  const { t } = useTranslation("bookingModule", { keyPrefix: "bookings.requirement" });

  const debouncedHandleChange = useRef(
    debounce((value: number | undefined) => {
      onChange(value);
    }, 300)
  ).current;

  return (
    <div className={css.root}>
      {enabled && (
        <NumberInput
          defaultValue={defaultValue}
          onChange={debouncedHandleChange}
          min={0}
          max={max}
          autofocus={autofocus}
          error={error}
        />
      )}
      <span>{t(id)}</span>
    </div>
  );
};
