import Radios, { Item } from "PFComponents/radios/radios";
import React from "react";
import { useTranslation } from "react-i18next";

export enum BookingDeleteAllOption {
  ReopenVacancy = "ReopenVacancy",
  KeepVacancy = "KeepVacancy"
}

type DeleteAllBookingsQuestionProps = {
  value: BookingDeleteAllOption;
  onChange: (selectedOption: Item<BookingDeleteAllOption>) => void;
  profileName: string;
};

export const DeleteAllBookingsQuestion = ({
  value,
  onChange,
  profileName
}: DeleteAllBookingsQuestionProps) => {
  const { t } = useTranslation("bookingModule");
  const DELETE_ALL_OPTIONS: Item<BookingDeleteAllOption>[] = [
    {
      id: BookingDeleteAllOption.ReopenVacancy,
      text: t("bookings.delete.body.resetShortlist.reopenVacancy")
    },
    {
      id: BookingDeleteAllOption.KeepVacancy,
      text: t("bookings.delete.body.resetShortlist.keepVacancy", { profileName })
    }
  ];

  return (
    <div>
      <Radios<BookingDeleteAllOption> items={DELETE_ALL_OPTIONS} value={value} handleChange={onChange} />
    </div>
  );
};
