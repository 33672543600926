import moment from "moment";
import { ActivityHistoryEntry, HistorySortDirection } from "PFTypes";
import { useMemo } from "react";

import { useActivityHistoryContext } from "../activity_history_context";
import { useActivityHistory } from "./use_activity_history";

export const useSortedHistoryEntries = () => {
  const { sort } = useActivityHistoryContext();
  const { data: history } = useActivityHistory();

  return useMemo(
    () =>
      (history?.entries || []).sort(
        (entry1, entry2) =>
          moment(entry1.createdAt).diff(entry2.createdAt, "seconds") *
          (sort === HistorySortDirection.FromLatest ? -1 : 1)
      ) as ActivityHistoryEntry[],
    [history?.entries, sort]
  );
};
