import { ESSENTIAL_SKILL_WEIGHT } from "PFTypes";

import { useRoleMatchCustomFieldData } from "./use_role_match_custom_field_data";

export const useEssentialSkills = () => {
  const { getCustomFieldValuesOfWeight } = useRoleMatchCustomFieldData();
  const { roleCustomValues, matchCustomValues, missingMatchCustomValues } = getCustomFieldValuesOfWeight(
    "skills",
    ESSENTIAL_SKILL_WEIGHT
  );

  return {
    roleEssentialSkills: roleCustomValues,
    matchEssentialSkills: matchCustomValues,
    missingMatchEssentialSkills: missingMatchCustomValues
  };
};
