import SidePanel, { SidePanelProps } from "PFComponents/side_panel/side_panel";
import { Id } from "PFTypes";
import { useTranslation } from "react-i18next";

import { HistoryEntriesList, HistorySort } from "./parts";

export const ActivityHistorySidePanel = <TAB_ID extends Id = Id>({
  show,
  ...panelProps
}: SidePanelProps<TAB_ID>) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.history" });

  return (
    <SidePanel show={show} {...panelProps} title={t("modal.title")} actions={<HistorySort />} fullHeight>
      <HistoryEntriesList />
    </SidePanel>
  );
};
