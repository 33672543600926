import { SkillsList } from "PFApp/components/skills_list";
import { useMatchProfileInfo } from "PFApp/hooks/use_match_profile_info";
import { Divider } from "PFComponents/divider";
import { Typography } from "PFComponents/typography";
import { useTranslation } from "react-i18next";

import { InfoSection } from "../../components/info_section";
import { useMatchCardContext } from "../../match_card_context";
import { ActivityMatchCustomFields } from "../match_activity_custom_fields";
import { MatchActivitySkills } from "../match_activity_skills";
import css from "./match_card_body.module.scss";
import { ShortlistStateSection } from "./parts/ShortlistStateSection";

export const MatchCardBody = () => {
  const {
    isCompressed,
    custom_values: customValues,
    id: profileId,
    profile,
    animate
  } = useMatchCardContext();
  const { sideInfo } = useMatchProfileInfo(profile);
  const { t } = useTranslation("activities", { keyPrefix: "show.matches.card" });

  const relatedSkills = customValues?.related || [];
  const indirectAttributes = customValues?.indirect.filter(
    // if already present in related attributes, don't render it in indirect
    ({ id }) => !relatedSkills.find(({ id: relatedId }) => id === relatedId)
  );

  const attributesRenderData = [
    {
      key: "related",
      attributes: relatedSkills,
      title: t("relatedSkills"),
      tooltipContent: t("relatedSkillsTooltip")
    },
    {
      key: "indirect",
      attributes: indirectAttributes,
      title: t("indirectAttributes"),
      tooltipContent: t("indirectAttributesTooltip")
    }
  ].filter(({ attributes }) => !!attributes.length);

  return (
    <div className={css.cardBodyContainer}>
      <Divider color="paletteNeutral0" />
      <div className={css.cardBody}>
        <div className={css.sideArea}>
          <ActivityMatchCustomFields />
          {sideInfo.map(({ customType, value }) => (
            <InfoSection title={customType.display_as || customType.name} key={customType.id}>
              <Typography variant="labelBold">{value}</Typography>
            </InfoSection>
          ))}
        </div>
        <div className={css.mainArea}>
          {isCompressed && <MatchActivitySkills />}
          {attributesRenderData.map(({ key, attributes, title, tooltipContent }) => (
            <InfoSection key={key} title={title} tooltipContent={tooltipContent}>
              <SkillsList
                customFieldName="skills" // TODO: [PROF-7377] For indirect it can be other custom field as well
                availableSkills={attributes}
                profileId={profileId}
                onMoreLessButtonClick={animate}
              />
            </InfoSection>
          ))}
          <ShortlistStateSection />
        </div>
      </div>
    </div>
  );
};
