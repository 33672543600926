import { useIsMutating, useMutation } from "@tanstack/react-query";
import { submitNewBookingTemplate } from "PFApp/booking/services/api";

import { useProfileInvalidate } from "../../profile";
import { Booking, bookingTemplateKeys } from "../query_keys";

const bookingMutationsKeys = {
  create: () => [...bookingTemplateKeys.all(), "create"]
};

export const useBookingTemplateCreate = (...profileIds: number[]) => {
  const { invalidateProfile } = useProfileInvalidate();
  const mutationKey = bookingMutationsKeys.create();

  const createMutation = useMutation(
    async (bookingData: Partial<Booking>) => {
      if (!bookingData) {
        return;
      }

      return submitNewBookingTemplate(bookingData);
    },
    {
      mutationKey,
      onSuccess: () => profileIds.map(invalidateProfile)
    }
  );

  const isCreating = useIsMutating({
    mutationKey
  });

  return {
    mutation: createMutation,
    create: createMutation.mutateAsync,
    isCreating
  };
};
