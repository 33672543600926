import classNames from "classnames";

import css from "./radios.module.scss";

export type Item<ID_TYPE extends string | number> = {
  id: ID_TYPE;
  text?: string;
  style?: React.CSSProperties;
  displayElement?: React.ReactNode;
  additionalElement?: React.ReactNode;
  disabled?: boolean;
  name?: string;
};

type Props<ID_TYPE extends string | number> = {
  items: Item<ID_TYPE>[];
  value?: string | number;
  inline?: boolean;
  handleChange: (item: Item<ID_TYPE>) => void;
  itemClassName?: string;
  disabled?: boolean;
  classes?: Record<string, string>;
};

const Radios = <ID_TYPE extends string | number = string>({
  items,
  value,
  inline = false,
  handleChange,
  itemClassName,
  disabled,
  classes = {}
}: Props<ID_TYPE>): JSX.Element => (
  <>
    {items.map((item) => {
      const className = classNames(css.item, classes.item, itemClassName, {
        [css.inline]: inline,
        [css.block]: !inline,
        [css.disabled]: item.disabled || disabled
      });

      return (
        <>
          <label className={className} key={item.id} style={item.style}>
            <input
              name={item.name || String(item.id)}
              type="radio"
              className={css.input}
              checked={item.id === value}
              onChange={() => handleChange(item)}
              value={item.id}
              disabled={item.disabled}
            />
            <div className={css.knob} />
            {item.text && <span className={css.text}>{item.text}</span>}
            {item.displayElement && (
              <span className={classNames(css.displayElement, classes.displayElement)}>
                {item.displayElement}
              </span>
            )}
          </label>
          {!!item.additionalElement && item.additionalElement}
        </>
      );
    })}
  </>
);

export default Radios;
