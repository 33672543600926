import { Button } from "PFComponents/button";

import AccomplishmentCard from "./accomplishment_card";
import css from "./accomplishment_card.module.scss";

const AddAccomplishmentCard = ({ onClick, title }: { onClick: () => void; title: string }) => (
  <AccomplishmentCard className={css.addCard}>
    <Button icon="add" text={title} className={css.button} onClick={onClick} kind="secondary" />
  </AccomplishmentCard>
);

export default AddAccomplishmentCard;
