import { Context } from "@microsoft/teams-js";
import { decamelizeKeys } from "humps";
import { debounce } from "lodash";
import { SquareLogo } from "PFApp/components/navbar/items/nav_logo";
import { useSession } from "PFApp/use_session";
import { Button } from "PFComponents/button";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import { applyTeamsDomain, isMsTeams, setTeamsDomain } from "PFCore/helpers/ms_teams";
import { isFeatureEnabled } from "PFCore/helpers/use_is_feature_enabled";
import { useQueryParams } from "PFCore/helpers/use_query_params";
import { fetchAccount } from "PFCore/services/accounts/account";
import { UiRoute } from "PFCore/utilities/routes";
import { CurrentAccount, FeatureFlag } from "PFTypes";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import css from "./ms_teams_sign_in.module.scss";

export const MsTeamsSignIn = () => {
  const parsedParams = useQueryParams();
  const application = useMemo(() => parsedParams.get("application") || "tab", [parsedParams]);
  const history = useHistory();
  const { t } = useTranslation("msTeams");

  const [context, setContext] = useState<Context | null>(null);
  const [signedIn, setSignedIn] = useState(false);
  const [account, setAccount] = useState<CurrentAccount | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [skipAutoSignIn, setSkipAutoSignIn] = useState<boolean>(false);

  const { microsoftTeams, location } = window;
  const uiRootDomain = (PF.config.development_domain || location.hostname).split(".").slice(1).join(".");

  const { setTokensForAccount } = useSession();

  useEffect(() => {
    if (microsoftTeams) {
      microsoftTeams.getContext((context) => {
        const redirectPath = context.subEntityId;
        redirectPath && storage.setItem("redirected_from", redirectPath);
        setContext(context);
      });
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  // auto sign in
  useEffect(() => {
    if (
      account &&
      !skipAutoSignIn &&
      !storage.getItem("samlAttempt") &&
      !sessionStorage?.getItem("signedOut")
    ) {
      const isEnabled = isFeatureEnabled(account);
      if (isEnabled(FeatureFlag.AutoSaml)) {
        storage.setItem("samlAttempt", true, { expiresIn: 5 });
        handleSignIn();
      }
    }
  }, [account]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleSuccessSignIn = useCallback(
    (resp) => {
      /* Need to set auth tokens here, because authentication is done in modal,
       * and tokens won't perist if session storage is used, and modal is closed */
      if (resp) {
        let result = resp;
        /* if signin is from Teams desktop app, it will come as string */
        if (typeof resp === "string") {
          const respArray = resp.split("-");
          result = { access_token: respArray[0], refresh_token: respArray[1], account: respArray[2] };
          setTeamsDomain(result.account);
          applyTeamsDomain();
        }
        setTokensForAccount(result.access_token, result.refresh_token, account);
      }
      /* in case it is a configurable tab */
      if (context?.frameContext === "settings") {
        microsoftTeams.settings.setValidityState(true);
        setSignedIn(true);

        microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
          const contentUrl = `${location.origin}/ms_teams`;
          microsoftTeams.settings.setSettings({
            contentUrl,
            websiteUrl: contentUrl,
            entityId: account?.full_domain,
            suggestedDisplayName: "Profinda"
          });
          saveEvent.notifySuccess();
        });
      } else {
        window.location.href = UiRoute("/ms_teams");
      }
    },
    [account] //eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleFailureSignIn = useCallback((reason) => setError(t("errors.other", { reason })), [t]);

  const handleSignIn = useCallback(() => {
    const appDeepLinkParams = "oauthRedirectMethod={oauthRedirectMethod}&authId={authId}";
    const samlParams = `account=${account?.full_domain}&application=${application}`;
    const samlAuthPath = `ms_teams/authsaml?${samlParams}&${appDeepLinkParams}`;

    if (isMsTeams()) {
      microsoftTeams.authentication
        .authenticate({
          url: `${location.origin}/${samlAuthPath}`,
          /* use external browser for to signin from Teams desktop app https://learn.microsoft.com/en-gb/microsoftteams/platform/tabs/how-to/authentication/auth-oauth-provider?WT.mc_id=M365-MVP-5001530#add-authentication-to-external-browsers */
          isExternal: true,
          width: 600,
          height: 535
        })
        .then(handleSuccessSignIn, handleFailureSignIn);
    } else {
      /* sign in from bot */
      history.push(`/${samlAuthPath}`);
    }
  }, [account, application, history, location, microsoftTeams, handleSuccessSignIn, handleFailureSignIn]);

  const debouncedHandleChangeAccountName = useMemo(
    () =>
      debounce((value) => {
        if (!value) {
          setAccount(null);
          return;
        }
        fetchAccount(value)
          .then((response) => {
            const decamelizedResponse = decamelizeKeys(response) as CurrentAccount;
            const isEnabled = isFeatureEnabled(decamelizedResponse);
            if (!isEnabled(FeatureFlag.SamlSignin)) {
              setError(t("errors.samlNotFound"));
            } else {
              setError(null);
              setAccount(decamelizedResponse);
            }
          })
          .catch(({ response }) =>
            setError(response.status === 404 ? t("errors.domainNotFound") : t("errors.unknown"))
          );
      }, 500),
    [t]
  );

  return (
    <div className={css.root}>
      <SquareLogo className={css.squareLogo} />
      <div style={{ marginTop: 10 }}>{t("welcome")}</div>
      {signedIn ? (
        <div>{t("addToChannelInfo", { channelName: context?.channelName })}</div>
      ) : (
        <>
          <div className={css.accountInput}>
            <span>https://</span>
            <InputFieldSet
              defaultValue={(PF.config.ms_teams_domain || "").split(".")[0]}
              style={{ width: 120 }}
              placeholder="accountname"
              inputType="url"
              onKeyDown={() => {
                setSkipAutoSignIn(true);
                setAccount(null);
              }}
              onChange={debouncedHandleChangeAccountName}
            />
            <span>{`.${uiRootDomain}`}</span>
          </div>
          <span className={css.error}>{error}</span>
          <Button
            disabled={!account || !!error}
            qaId="SignInPage.Button.saml"
            kind="landing-blue"
            style={{ display: "block", margin: 20 }}
            onClick={handleSignIn}
          >
            {t("signIn")}
          </Button>
        </>
      )}
    </div>
  );
};
