import { isNil } from "lodash";

export const getMatchDisplayValue = (
  rawScore: number | undefined | null,
  options: { defaultValue?: string; showPercentage?: boolean; digits?: number } = {
    defaultValue: "-",
    showPercentage: false,
    digits: 1
  }
) => {
  if (isNil(rawScore)) {
    return options.defaultValue;
  }
  return `${(rawScore * 100).toFixed(options.digits)}${options.showPercentage ? "%" : ""}`;
};
