import { ColumnHelper } from "@tanstack/react-table";
import classNames from "classnames";
import { CalendarNavigation } from "PFComponents/calendar/bookings_calendar/calendar_navigation";
import { CalendarSelectProps } from "PFComponents/calendar/month_selector";
import Table from "PFComponents/table";
import { Booking } from "PFTypes";
import { useCallback } from "react";

import { useProfileBookingsContext } from "../profile_bookings_provider";
import css from "./bookings_list.module.scss";
import { useBookingsListColumnsGenerator } from "./use_bookings_list_columns_generator";

const COLLAPSED_HEIGHT = 280;

type BookingsListProps = CalendarSelectProps & {
  isLoading: boolean;
  canEdit: boolean;
  hideMonthSelector?: boolean;
  onBookingClick: (booking: Booking) => void;
  onBookingEdit: (booking: Booking) => void;
  isExpanded: boolean;
};

export const BookingsList = ({
  isLoading,
  canEdit,
  hideMonthSelector,
  onBookingClick: onPeriodClick,
  onBookingEdit,
  isExpanded,
  ...calendarSelectProps
}: BookingsListProps): JSX.Element => {
  const { bookings, calendarSearch } = useProfileBookingsContext();

  const {
    bookingTitleColumn,
    bookingCategoryColumn,
    datesColumn,
    roleColumn,
    totalHoursColumn,
    actionColumn
  } = useBookingsListColumnsGenerator({
    onBookingEdit,
    readOnly: !canEdit,
    isExpanded
  });

  const generateColumns = useCallback(
    () => (columnHelper: ColumnHelper<Booking>) =>
      [
        bookingTitleColumn(columnHelper),
        roleColumn(columnHelper),
        bookingCategoryColumn(columnHelper),
        datesColumn(columnHelper),
        totalHoursColumn(columnHelper),
        actionColumn(columnHelper)
      ],
    [isExpanded, canEdit]
  );

  return (
    <div className={css.root}>
      {!hideMonthSelector && (
        <div className={css.stickyMonthSelector}>
          <CalendarNavigation
            showArrows
            minDate={calendarSelectProps.minDate}
            maxDate={calendarSelectProps.maxDate}
            displayMonth={calendarSelectProps.displayMonth}
            setDisplayMonth={calendarSelectProps.setDisplayMonth}
            calendarSearch={calendarSearch}
          />
        </div>
      )}
      <div
        className={classNames(css.editPanel, { [css.disabled]: isLoading })}
        style={{ maxHeight: !isExpanded ? COLLAPSED_HEIGHT : undefined }}
      >
        <Table<Booking>
          tableData={bookings}
          generateColumns={generateColumns()}
          onRowClick={onPeriodClick}
          disableAutoResizing
        />
      </div>
    </div>
  );
};
