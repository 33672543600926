import { Alert } from "PFComponents/alert";
import { Button } from "PFComponents/button";
import ConfirmModal from "PFComponents/confirm/confirm_modal";
import { Typography } from "PFComponents/typography";
import { getProfileName } from "PFCore/helpers/profile";
import { useActivityReviewComplete } from "PFCore/hooks/queries/activity/use_activity_review_complete";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { useErrorsGrowl } from "PFCore/hooks/use_errors_growl";
import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";

import { useActivityPageContext } from "../../activity_page_context";
import { ReviewDeadlineLabel } from "./review_deadline_label";
import css from "./review_section.module.scss";
import { ReviewerLabel } from "./reviewer_label";

export const ReviewerFlow = () => {
  const { formatDate, isDatePassed } = useDateFormatter();
  const growlErrors = useErrorsGrowl();

  const { t } = useTranslation("activities", { keyPrefix: "show.shortlist.review" });
  const { task, allShortlists } = useActivityPageContext();
  const { mutate: completeReview } = useActivityReviewComplete({
    onError: (error) => {
      growlErrors(error, { display_all: true });
    }
  });

  const [showModal, toggleShowModal] = useToggle(false);
  const deadlineDate = task.review?.deadline;
  const deadlinePassed = isDatePassed(deadlineDate);
  const completedDate = task.review?.completed_at;
  const reviewer = task.review?.completed_by;
  const canComplete = !!allShortlists.find((shortlist) => ["accepted", "declined"].includes(shortlist.state));

  const handleSubmit = () => {
    completeReview({ id: task.id });
  };

  const renderContent = () => {
    if (completedDate) {
      return <ReviewerLabel name={getProfileName(reviewer)} date={formatDate(completedDate)} />;
    }

    return (
      <>
        {deadlinePassed && <Alert className={css.label} title={t("deadlinePassed")} kind="warning" />}
        <Button
          className={css.button}
          onClick={toggleShowModal}
          text={t("setCompleted")}
          disabled={!canComplete}
          qaId="ActivityPageCompleteReviewButton"
        />
      </>
    );
  };

  return (
    <>
      {deadlineDate && !completedDate && <ReviewDeadlineLabel date={deadlineDate} />}
      {renderContent()}
      {showModal && (
        <ConfirmModal title={t("setCompleted")} handleOK={handleSubmit} handleClose={toggleShowModal}>
          <Typography variant="bodyRegular">{t("setCompletedMessage")}</Typography>
        </ConfirmModal>
      )}
    </>
  );
};
