import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";

import { getInstance } from "./instance";
import { Session } from "./types";
import {
  addPaginationParamsToResponse,
  clearInterceptors,
  parseRequestToSnakeCase,
  parseResponseToCamelCase,
  refreshTokenAndRetry,
  setHeaders,
  setParamsSerializer
} from "./utils";

const configureRequests = (session: Session, fullDomain?: string): void => {
  getInstance().interceptors.request.use((config: InternalAxiosRequestConfig) => {
    const newConfig = { ...config };

    setHeaders(newConfig, session.getAccessToken(), fullDomain);

    if (newConfig.headers["Content-Type"] === "multipart/form-data") {
      return newConfig;
    }

    parseRequestToSnakeCase(newConfig);

    setParamsSerializer(newConfig);

    return newConfig;
  });
};

const configureResponses = (session: Session, onServerError?: VoidFunction): void => {
  getInstance().interceptors.response.use(
    (response: AxiosResponse) => {
      parseResponseToCamelCase(response);
      addPaginationParamsToResponse(response);

      return response;
    },
    (error: AxiosError) => {
      error.response && parseResponseToCamelCase(error.response);
      const originalRequest = error.config;
      const isUnauthorized = error.response?.status === 401;
      const isOauthTokenUrl = originalRequest?.url?.match("oauth/token");
      const isServerErrror = (error.response?.status || 0) >= 500;

      if (isServerErrror) {
        onServerError?.();
      }

      if (isUnauthorized && !isOauthTokenUrl) {
        return refreshTokenAndRetry(session, originalRequest);
      } else {
        return Promise.reject(error);
      }
    }
  );
};

export const setupAxiosInstance = (
  session: Session,
  fullDomain?: string,
  onServerError?: VoidFunction
): void => {
  clearInterceptors();
  configureRequests(session, fullDomain);
  configureResponses(session, onServerError);
};
