import { FiltersPanel } from "PFApp/components/filters/filters_panel";

import { useInterestedContext } from "./interested_context_provider";

const InterestedFilters = () => {
  const { toggleShowFilters, showFilters, watchersMetadata, watchersQuery, filtersStorage } =
    useInterestedContext();
  const { updateFilter, resetFilters, noFiltersSelected } = filtersStorage;
  const { isLoading } = watchersQuery;

  if (!showFilters || !watchersMetadata) {
    return null;
  }

  return (
    <FiltersPanel
      isLoading={isLoading}
      meta={watchersMetadata}
      onFilterChange={updateFilter}
      clearFilters={resetFilters}
      filtersAreClear={noFiltersSelected}
      closeFilters={toggleShowFilters}
      allowToClose
    />
  );
};

export default InterestedFilters;
