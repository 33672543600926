import { Id } from "PFTypes/id";
import { QueryParams } from "PFTypes/query_params";

export const shortlistsKeys = {
  all: () => ["shortlists"],
  activityShortlists: (activityId: Id, params?: QueryParams) => [
    ...shortlistsKeys.all(),
    String(activityId),
    ...(params ? [params] : [])
  ],
  activitySuspendedShortlists: (activityId: Id, params?: QueryParams) => [
    ...shortlistsKeys.all(),
    "suspended",
    String(activityId),
    ...(params ? [params] : [])
  ],
  fillMutation: (id?: Id) => [...shortlistsKeys.all(), "mutation", "fill"].concat(id ? String(id) : []),
  fillAndBookMutation: (id?: Id) =>
    [...shortlistsKeys.all(), "mutation", "fillAndBook"].concat(id ? String(id) : [])
};

export const rankedShortlistsKeys = {
  all: () => ["rankedShortlists"],
  activityRankedShortlists: (activityId: Id, profileIds: Id[] = []) => [
    ...rankedShortlistsKeys.all(),
    String(activityId),
    ...profileIds
  ]
};
