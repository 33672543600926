import { SortDirection } from "@tanstack/table-core/src/features/Sorting";
import { ACTIVITY_PREVIEW_ACTIONS } from "PFReducers/activity_preview_reducer";
import { Activity } from "PFTypes";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState
} from "react";

import AppContext from "../../../app_context";
import { useProfilePreview } from "../../../use_profile_preview";
import { ActivityHistorySidePanel } from "./activity_history_side_panel";

export type ActivityHistoryContextType = {
  activity: Activity | undefined;
  sort: SortDirection;
  setSort: Dispatch<SetStateAction<SortDirection>>;
  openHistory: (activity: Activity | undefined) => void;
  closeHistory: VoidFunction;
};

const ActivityHistoryContext = createContext<ActivityHistoryContextType>({} as ActivityHistoryContextType);

export const ActivityHistoryProvider = ({ children }: PropsWithChildren) => {
  const [sort, setSort] = useState<SortDirection>("desc");
  const [activityToInspect, setActivityToInspect] = useState<Activity | undefined>(undefined);
  const { dispatch } = useContext(AppContext);
  const { hidePreview } = useProfilePreview();

  const openHistory = useCallback(
    (activity: Activity | undefined) => {
      setActivityToInspect(activity);
      if (!activity) {
        return;
      }

      dispatch({
        type: ACTIVITY_PREVIEW_ACTIONS.ACTIVITY_PREVIEW_HIDE
      });
      hidePreview();
    },
    [dispatch, hidePreview]
  );

  const contextValue = useMemo(
    () => ({
      sort,
      setSort,
      activity: activityToInspect,
      openHistory,
      closeHistory: () => setActivityToInspect(undefined)
    }),
    [activityToInspect, openHistory, sort]
  );

  const show = !!activityToInspect;

  return (
    <ActivityHistoryContext.Provider value={contextValue}>
      <ActivityHistorySidePanel show={show} onClose={() => setActivityToInspect(undefined)} />
      {children}
    </ActivityHistoryContext.Provider>
  );
};

export const useActivityHistoryContext = () => useContext(ActivityHistoryContext);
