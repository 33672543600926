import classNames from "classnames";
import { range } from "lodash";
import useWindowSize from "PFCore/helpers/use_window_size";
import DownArrow from "PFIcons/down_arrow.svg";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import css from "./simple_pagination.less";

const _pad = (num) => `0${num}`.slice(-Math.max(`${num}`.length, 2));

const getSpan = (windowWidth) => {
  let span = 3; // 3 before 3 after: < ... 1 2 3 (4) 5 6 7 ... >
  if (windowWidth < 360) {
    span = 1; // < ... 3 (4) 5 .. >
  } else if (windowWidth < 460) {
    span = 2; // < ... 2 3 (4) 5 6 .. >
  }
  return span;
};

const SimplePagination = ({
  currentPage,
  totalPages,
  handleClick,
  disabled,
  scrollOnClick,
  style,
  className,
  customSpan
}) => {
  const { t } = useTranslation("core");
  const { windowWidth } = useWindowSize();

  if (totalPages <= 1) {
    return null;
  }

  const span = customSpan || getSpan(windowWidth);

  const page = currentPage > totalPages ? totalPages + 1 : currentPage;
  const showWindow = span * 2 < totalPages;
  const low = showWindow ? Math.max(page - span, 1) : 1;
  const high = showWindow ? Math.min(page + span, totalPages) : totalPages;

  const goToPage = (newPage) => {
    handleClick && page !== newPage && handleClick(newPage);

    if (scrollOnClick) {
      $("html, body").animate({ scrollTop: 0 }, 100, "swing");
    }
  };

  const showPrevArrow = showWindow && low > 1;
  const showNextArrow = showWindow && high < totalPages;

  return (
    <div className={classNames(css.root, className, { [css.disabled]: disabled })} style={style}>
      {showPrevArrow && <Edge handleClick={() => goToPage(1)} title={t("goToStart")} />}
      {showPrevArrow && <Gap handleClick={() => goToPage(low - 1)} />}
      {range(low, high + 1).map((i) => (
        <button
          key={i}
          className={classNames(css.page, {
            [css.current]: i === page,
            [css.low]: i === low,
            [css.high]: i === high
          })}
          onClick={() => goToPage(i)}
        >
          {_pad(i)}
        </button>
      ))}
      {showNextArrow && <Gap handleClick={() => goToPage(high + 1)} />}
      {showNextArrow && <Edge handleClick={() => goToPage(totalPages)} title={t("goToEnd")} />}
    </div>
  );
};

SimplePagination.propTypes = {
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  disabled: PropTypes.bool,
  scrollOnClick: PropTypes.bool,
  handleClick: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  customSpan: PropTypes.number
};

SimplePagination.defaultProps = {
  currentPage: 1,
  totalPages: 1,
  disabled: false,
  scrollOnClick: true,
  handleClick: null
};

const Gap = ({ handleClick }) => (
  <button className={css.gap} onClick={handleClick}>
    &hellip;
  </button>
);

Gap.propTypes = {
  handleClick: PropTypes.func.isRequired
};

const Edge = ({ handleClick, title }) => (
  <button className={css.edge} onClick={handleClick} title={title}>
    <DownArrow width={18} height={18} />
  </button>
);

Edge.propTypes = {
  handleClick: PropTypes.func.isRequired,
  title: PropTypes.string
};

export default SimplePagination;
