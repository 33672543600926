import DropdownButton from "PFComponents/dropdown/dropdown_button";
import { Icon } from "PFComponents/icon";
import { useActivityEconomicsScenarioClone } from "PFCore/hooks/queries/activity/use_activity_economics_scenario_clone";
import { Activity, EconomicsScenario, Id } from "PFTypes";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useGrowl } from "../../../../use_growl";
import { RemoveScenarioModal } from "../remove_scenario_modal";
import { ShareScenarioModal } from "../share_scenario_modal";
import { useEconomicsPermissions } from "../use_economics_permissions";

type ScenarioActionsProps = {
  activity: Activity;
  scenario: EconomicsScenario;
  onClone?: (scenarioId: Id) => void;
};

export const ScenarioActions = ({ activity, scenario, onClone }: ScenarioActionsProps) => {
  const growl = useGrowl();
  const { t } = useTranslation("activities", { keyPrefix: "show.economics" });

  const { canCreateScenarios, canManageScenario } = useEconomicsPermissions();

  const { mutate: cloneScenario } = useActivityEconomicsScenarioClone(activity.id);

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const handleClone = () => {
    cloneScenario(scenario.id, {
      onSuccess: (newScenario) => {
        growl({
          kind: "success",
          message: t("scenario.cloneSuccess")
        });

        onClone?.(newScenario.id);
      },
      onError: () => {
        growl({
          kind: "error",
          message: t("scenario.cloneError")
        });
      }
    });
  };

  const options = useMemo(
    () => [
      {
        id: "remove",
        global_id: "remove",
        displayElement: t("remove"),
        item: () => setIsRemoveModalOpen(true),
        disabled: !canManageScenario(scenario.creatorId)
      },
      {
        id: "share",
        global_id: "share",
        displayElement: t("share"),
        item: () => setIsShareModalOpen(true),
        disabled: !canManageScenario(scenario.creatorId)
      },
      {
        id: "clone",
        global_id: "clone",
        displayElement: t("clone"),
        item: () => handleClone(),
        disabled: !canCreateScenarios
      }
    ],
    []
  );

  return (
    <>
      <DropdownButton
        options={options}
        buttonKind="blank"
        buttonStyle={{ height: 18 }}
        style={{ marginLeft: "auto" }}
        handleChange={(fn) => fn && fn(scenario)}
      >
        <Icon name="menu-vertical" size="md" />
      </DropdownButton>
      {isRemoveModalOpen && (
        <RemoveScenarioModal
          scenarioId={scenario.id}
          activityId={activity.id}
          onClose={() => setIsRemoveModalOpen(false)}
        />
      )}
      {isShareModalOpen && (
        <ShareScenarioModal scenarioId={scenario.id} onClose={() => setIsShareModalOpen(false)} />
      )}
    </>
  );
};
