import { Booking, BookingTemplate, Id } from "PFTypes";
import { RequirementType } from "PFTypes/booking";
import { useCallback, useMemo, useState } from "react";

import { useDetailsPanelApiContext } from "../details_panel";
import { Requirement } from "./use_requirement";

export interface FormBooking extends Partial<Omit<Booking, "start_date" | "end_date">> {
  startDate: string;
  endDate: string;
}

export enum BookingFormMode {
  Create = "create",
  Edit = "edit",
  Clone = "clone"
}

export interface BookingFormDataType {
  searchId?: number;
  total?: number;
  entries?: any[];
  profileId?: number;
  startDate?: string;
  endDate?: string;
  title?: string;
  description?: string;
  categoryId?: Id | null;
  bookings?: FormBooking[];
  activityId?: number | null;
  bookingGroupId?: number;
  bookingTemplate?: BookingTemplate;
  shortlistId?: number;
  onSubmit?: () => void;
  overridesCalendar?: boolean;
  overridesNonWorkingDays?: boolean;
}

interface FormPropsType {
  onSuccess?: () => void;
  bookingId?: number;
  mode?: BookingFormMode;
}

interface FormDataStateType {
  isOpen: boolean;
  data?: BookingFormDataType;
  props?: FormPropsType;
}

export type BookingFormType = {
  state: FormDataStateType;
  open: (data?: BookingFormDataType, props?: FormPropsType) => void;
  close: () => void;
  refreshData: (data: BookingFormDataType) => void;
};

export type RepeatedBookingDateRangeData = {
  start: string;
  end: string;
  duration: number;
  state: string;
  phaseSourceId: number | undefined;
  requirement: {
    type: RequirementType.Load;
    value: number;
  };
  startTime: string;
  endTime: string;
  wdayMask: number;
};

export type SingleBookingDateRangeData = {
  start: string;
  end: string;
  duration: number;
  state: string;
  phaseSourceId: number | undefined;
  requirement: Requirement;
};

export type BookingData =
  | Record<number, SingleBookingDateRangeData>
  | Record<number, RepeatedBookingDateRangeData>;

type UseBookingFormReturn = {
  open: (data: BookingFormDataType, props?: FormPropsType) => void;
  close: () => void;
  refreshData: (data: BookingFormDataType) => void;
  state: FormDataStateType;
};

export const useBookingForm = (): UseBookingFormReturn => {
  const [formData, setFormData] = useState<FormDataStateType>({ isOpen: false });

  const { pushToOrderStack, popFromOrderStack } = useDetailsPanelApiContext();

  const open = useCallback((data: BookingFormDataType, props?: FormPropsType) => {
    setFormData({ isOpen: true, data, props });
    const uniqueId = new Date().getTime();
    pushToOrderStack(data.activityId ?? -1, "booking_form", uniqueId);
  }, []);

  const refreshData = useCallback(
    (data: BookingFormDataType) => setFormData((oldData) => ({ ...oldData, data })),
    []
  );

  const close = useCallback(() => {
    setFormData({ isOpen: false });
    popFromOrderStack();
  }, []);

  return useMemo(() => ({ open, close, refreshData, state: formData }), [formData]);
};
