import { isEmpty } from "lodash";
import { fetchMatches } from "PFCore/services/matches";
import { fetchProfiles } from "PFCore/services/profile";
import { Activity } from "PFTypes";

import { SEARCH_ACTIONS } from "../search_reducer";

type PrepareMatchesCollectionFetchProps = {
  payload: any;
  dispatch: Function;
};

export const prepareMatchesCollectionFetch = ({ dispatch, payload }: PrepareMatchesCollectionFetchProps) => {
  const fetchMatchesCollection = (searchTask: Activity, attempt = 1) => {
    // TODO: [ENG-2261] review if we can unify RESET and CLEAR here - this is a bit tricky with the abort
    dispatch({ type: SEARCH_ACTIONS.SEARCH_START_LOADING_MATCHES, payload: {} });

    const { term, matchesPage, matchesRequestOrder: order, matchesRequestFilters, collectionKind } = payload;

    if (searchTask.custom_fields.length === 0) {
      fetchProfiles({
        page: payload.collectionKind === "profiles" ? matchesPage || 1 : 1,
        per_page: 10,
        nested: true,
        filters: {
          ...payload.matchesRequestFilters,
          fields: { ...payload.matchesRequestFilters?.fields, ...(term ? { term } : {}) }
        },
        ...(isEmpty(order) ? null : { order })
      }).then((resp) => {
        dispatch({ type: SEARCH_ACTIONS.SEARCH_SET_MATCHES_COLLECTION, payload: "profiles" });
        dispatch({ type: SEARCH_ACTIONS.SEARCH_MATCHES_DONE, payload: resp });
      });
      dispatch({
        type: SEARCH_ACTIONS.SEARCH_MERGE_STATE,
        payload: { prevMatchesFetch: true }
      });
      return;
    }

    // no filters (just params) because filters are send *to* api with the searchTask
    fetchMatches(searchTask.id, {
      per_page: 10, // if you update this one take a look on MoreButton as well
      page: matchesPage || 1,
      ...(matchesRequestFilters ? { filters: matchesRequestFilters } : null),
      ...(collectionKind === "matches" && !isEmpty(order) ? { order: order } : null)
    })
      .then((resp) => {
        if (resp.meta.status === "ready") {
          dispatch({ type: SEARCH_ACTIONS.SEARCH_SET_MATCHES_COLLECTION, payload: "matches" });
          dispatch({ type: SEARCH_ACTIONS.SEARCH_MATCHES_DONE, payload: resp });
        } else {
          if (attempt < 12) {
            setTimeout(() => {
              dispatch({
                type: SEARCH_ACTIONS.SEARCH_MERGE_STATE,
                payload: { prevMatchesFetch: null }
              });
              fetchMatchesCollection(searchTask, attempt + 1);
            }, (attempt + 3) * 700); // Starting on 1.5 or 2 seconds
          } else {
            dispatch({ type: SEARCH_ACTIONS.SEARCH_CLEAR_MATCHES });
          }
        }
      })
      .catch(() => dispatch({ type: SEARCH_ACTIONS.SEARCH_CLEAR_MATCHES }));

    dispatch({
      type: SEARCH_ACTIONS.SEARCH_MERGE_STATE,
      payload: { prevMatchesFetch: true }
    });
  };

  return fetchMatchesCollection;
};
