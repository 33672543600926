import { useProfilePreview } from "PFApp/use_profile_preview";
import ProfileAvatar from "PFComponents/profile_avatar/profile_avatar";
import { Profile } from "PFTypes";
import { SyntheticEvent } from "react";

type HistoryEntryProfileAvatarProps = {
  profile: Profile;
};

export const HistoryEntryProfileAvatar = ({ profile }: HistoryEntryProfileAvatarProps) => {
  const { showPreview } = useProfilePreview();

  const handleProfileClick = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    showPreview({ id: profile.id, profile });
  };

  return <ProfileAvatar profile={profile} size={40} onClick={handleProfileClick} />;
};
