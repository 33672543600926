import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useCallback } from "react";

import { CustomType } from "../types";

export const useCustomTypeByName = () => {
  const { customTypes } = useCustomTypes();

  return useCallback(
    (type: CustomType["name"]) => customTypes.find(({ name }) => name === type),
    [customTypes]
  );
};
