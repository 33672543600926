import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { BookingCategory, FeatureFlag } from "PFTypes";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface UseCategoryError {
  selectedBookingCategory: BookingCategory | null;
  isDemandRelated: boolean;
}

export const useCategoryError = ({
  selectedBookingCategory,
  isDemandRelated
}: UseCategoryError): string | null => {
  const { t } = useTranslation("bookingModule");
  const isEnabled = useIsFeatureEnabled();
  const [categoryError, setCategoryError] = useState<string | null>(null);
  const isBookingModuleEnabled = isEnabled(FeatureFlag.BookingModule);

  useEffect(() => {
    if (!selectedBookingCategory || !isBookingModuleEnabled) {
      setCategoryError(null);
      return;
    }
    if (isDemandRelated) {
      setCategoryError(
        !selectedBookingCategory.activity_assignable
          ? t("bookings.create.categoryErrors.selectDemandRelatedCategory")
          : null
      );
    } else {
      setCategoryError(
        selectedBookingCategory.activity_assignable
          ? t("bookings.create.categoryErrors.selectNonDemandRelatedCategory")
          : null
      );
    }
  }, [selectedBookingCategory, isDemandRelated]);

  return categoryError;
};
