import { isEmpty } from "lodash";
import AppContext from "PFApp/app_context";
import { useHideGrowl } from "PFApp/use_growl";
import { Growl, GrowlProps } from "PFComponents/growl";
import { useContext } from "react";

import css from "./growls.module.scss";

export type GrowlEntry = GrowlProps & { id: string; timestamp: number };

export const Growls = (): JSX.Element | null => {
  const {
    store: { growls }
  } = useContext(AppContext);
  const hideGrowl = useHideGrowl();

  if (isEmpty(growls)) {
    return null;
  }

  return (
    <div className={css.root}>
      {(growls || []).map((growl) => (
        <Growl {...growl} key={growl.timestamp} onClose={() => hideGrowl(growl.id)} />
      ))}
    </div>
  );
};
