import classNames from "classnames";
import { LoadingSpinner } from "PFComponents/loading_spinner";
import useIsActionKeyPressed from "PFCore/helpers/use_is_action_key_pressed";
import useWindowSize from "PFCore/helpers/use_window_size";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

import css from "./page_tabs.module.scss";

const PageTabs = ({
  items,
  active,
  handleClick,
  counters,
  inline,
  allowNoUnderline,
  action,
  rootClassName,
  qaId,
  capitalize
}) => {
  const [activeStyle, setActiveStyle] = useState({});
  const activeRef = useRef(null);
  const rootRef = useRef(null);

  const { windowWidth, windowHeight } = useWindowSize();
  const isActionKeyPressed = useIsActionKeyPressed();

  const updateUnderline = () => {
    if (activeRef.current) {
      const left = activeRef.current.offsetLeft;
      const width = activeRef.current.offsetWidth;
      const top = activeRef.current.offsetTop;
      setActiveStyle({
        transform: `translate(${left + width / 2 - 35}px, ${top}px)`,
        opacity: 1
      });

      if (rootRef.current) {
        const right = left + width;
        const rootWidth = rootRef.current.offsetWidth;
        if (right > rootWidth) {
          rootRef.current.scrollLeft += right - rootWidth + 10;
        } else if (left < rootRef.current.scrollLeft) {
          rootRef.current.scrollLeft -= rootRef.current.scrollLeft - left + 10;
        }
      }
    } else if (allowNoUnderline) {
      setActiveStyle((prev) => ({ ...(prev || {}), opacity: 0 }));
    }
  };

  useEffect(() => {
    updateUnderline();
    const id = window.setTimeout(updateUnderline, 500); // sometimes needed when on refresh (full rerender)
    return () => window.clearTimeout(id);
  }, [items, active, counters, windowWidth, windowHeight]);

  useEffect(() => {
    updateUnderline();
  }, [windowWidth, windowHeight]);

  const onKeyDown = (e, item) => {
    if (isActionKeyPressed(e)) {
      e.preventDefault();
      handleClick(item);
    }
  };

  return (
    <div className={classNames(rootClassName, css.root, { [css.inline]: inline })} ref={rootRef}>
      <ul className={css.list}>
        {items.map((item) => {
          const counter = counters[item.id];
          const counterValue = counter && typeof counter === "object" ? counter.count : counter;

          return (
            <li key={`${item.id}_${counter}`} ref={item.id === active ? activeRef : null} id={item.id}>
              <button
                onClick={() => !item.disabled && handleClick(item)}
                onKeyDown={(e) => !item.disabled && onKeyDown(e, item)}
                className={classNames(css.item, {
                  [css.active]: item.id === active,
                  [css.capitalize]: capitalize
                })}
                style={item.disabled ? { cursor: "default", opacity: 0.5 } : null}
                data-qa-id={qaId ? `${qaId}.${item.id}` : null}
              >
                <div className={classNames({ [css.innactive]: item.id !== active })}>
                  {item.displayElement}
                </div>
                {(counter != null || counterValue === null) && ( //eslint-disable-line eqeqeq
                  <div
                    className={classNames(css.badge, {
                      [css.active]: counterValue != null && item.id === active //eslint-disable-line eqeqeq
                    })}
                    style={counter?.style}
                  >
                    {counterValue != null ? ( //eslint-disable-line eqeqeq
                      counterValue
                    ) : (
                      <LoadingSpinner style={{ marginTop: 5 }} height={11} width={11} />
                    )}
                  </div>
                )}
              </button>
            </li>
          );
        })}
        {action && (
          <li className={css.action} style={{ padding: 0 }}>
            {action}
          </li>
        )}
      </ul>
      {(activeRef.current || allowNoUnderline) && <div className={css.underline} style={activeStyle} />}
    </div>
  );
};

PageTabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      displayElement: PropTypes.node
    })
  ).isRequired,
  active: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  counters: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({ counter: PropTypes.number, style: PropTypes.object })
    ])
  ),
  inline: PropTypes.bool,
  allowNoUnderline: PropTypes.bool,
  rootClassName: PropTypes.string,
  action: PropTypes.object,
  qaId: PropTypes.string,
  capitalize: PropTypes.bool
};

PageTabs.defaultProps = {
  counters: {},
  inline: false,
  allowNoUnderline: false,
  capitalize: true
};

export default PageTabs;
