import DownloadLink from "PFApp/components/chat/download_link";
import { useAttachments } from "PFCore/hooks/queries/attachments/use_attachments";
import AttachmentIcon from "PFIcons/attachment_2.svg";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import css from "./attachments.module.scss";

const Attachments = ({ profile }) => {
  const { t } = useTranslation("profiles");

  const { data } = useAttachments({ targetType: "Profile", targetId: profile.id });

  const [hasAttachments, attachments] = useMemo(() => {
    const { entries } = data || {};

    return [entries?.length > 0, entries];
  }, [data]);

  return (
    hasAttachments && (
      <div className={css.root}>
        <div className={css.label}>
          <span className={css.attachmentIcon}>
            <AttachmentIcon width={16} />
            <div className={css.attachmentCount}>{attachments.length}</div>
          </span>
          {t("show.parts.attachments")}
        </div>
        <ul className={css.attachments}>
          {attachments.map((attachment) => (
            <li key={attachment.id}>
              <DownloadLink rootClassName={css.link} attachment={attachment} useIcon>
                <span className={css.name}>{attachment.file_name}</span>
              </DownloadLink>
            </li>
          ))}
        </ul>
      </div>
    )
  );
};

Attachments.propTypes = {
  profile: PropTypes.shape({
    id: PropTypes.any
  })
};

export default Attachments;
