import compact from "lodash/compact";
import find from "lodash/find";
import sortBy from "lodash/sortBy";
import ComparePage from "PFApp/activities/compare/compare_page";
import { DetailsPanelContextProvider } from "PFApp/booking/components/details_panel/details_panel_context/details_panel_context_provider";
import usePageWrapper from "PFCore/helpers/use_page_wrapper";
import { useActivity } from "PFCore/hooks/queries/activity";
import { useInfiniteActivityWatchers } from "PFCore/hooks/queries/activity/use_activity_watchers";
import { useActivityRankedShortlists } from "PFCore/hooks/queries/shortlists/use_activity_ranked_shortlists";
import { useActivityShortlists } from "PFCore/hooks/queries/shortlists/use_activity_shortlists";
import { fetchInvites } from "PFCore/services/invites/fetch_invites";
import { fetchProfiles } from "PFCore/services/profile";
import { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { BookingFormContextProvider } from "../../booking/components/booking_form";
import { DeleteBookingModalContextProvider } from "../../booking/components/delete_booking_modal/delete_booking_modal_context_provider";
import { DetailsPanel } from "../../booking/components/details_panel";
import ActivityContextProvider from "../show/activity_context_provider";

const CompareController = () => {
  const history = useHistory();
  const { id, tab, ids, type } = useParams();

  const WrappedComparePage = usePageWrapper(ComparePage);

  const initProfileIds = ids ? compact(ids.split(",")).map((id) => parseInt(id, 10)) : null;

  const [profileIds, setProfilesIds] = useState(initProfileIds);
  const [shortlists, setShortlists] = useState(null);
  const [rankLoaded, setRankLoaded] = useState(false);
  const [availableProfiles, setAvailableProfiles] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const prevPath = useRef(history.location.state?.prevPath);

  const isInterestedType = type === "interested";
  const { watchers } = useInfiniteActivityWatchers({ id }, 1000, {}, {}, { enabled: isInterestedType });
  const watchersWithShortlists = watchers.map(
    (watcher) => (shortlists || []).find(({ profile }) => profile.id === watcher.profile.id) || watcher
  );

  const updateData = (shortlists) =>
    fetchData(id, profileIds, shortlists)
      .then(({ shortlistsWithInvite, availableProfiles }) => {
        if (shortlistsWithInvite.length === 0 && !isInterestedType) {
          exitToShortlist();
        }
        setShortlists(shortlistsWithInvite);
        setAvailableProfiles(availableProfiles);
        setIsLoaded(true);
        setRankLoaded(false);
      })
      .catch(exitToDetails);

  const { data: activity, isSuccess: isActivitySuccess } = useActivity(id);
  useActivityShortlists(
    id,
    {},
    {
      enabled: isActivitySuccess,
      onSuccess: (shortlistsResponse) => updateData(shortlistsResponse.entries)
    }
  );

  useActivityRankedShortlists({
    activityId: id,
    options: {
      enabled: !!shortlists && shortlists.length > 0 && !rankLoaded,
      onSuccess: (response) => {
        const rankedShortlists = shortlists?.map((shortlist) => {
          const rank = response.entries.find(({ profile }) => profile.id === shortlist.profile.id);

          return {
            ...(rank || {}),
            ...shortlist,
            profile: {
              ...(rank?.profile || {}),
              ...shortlist.profile
            }
          };
        });
        setRankLoaded(true);
        setShortlists(rankedShortlists);
      }
    }
  });

  const exitToShortlist = () => history.push(`/activities/${id}/shortlist`);
  const exitToDetails = () => history.push(`/activities/${id}/details`);
  const exitToInterested = () => history.push(`/activities/${id}/interested`);
  const changeTab = (newTab) =>
    history.push({ pathname: getPath(newTab, profileIds, type), state: { prevPath: prevPath.current } });

  useEffect(() => changeTab(tab), []); // if invalid tab goes to details

  const getPath = (newTab, ids) => {
    newTab = newTab === "availability" ? newTab : "details";

    let rest = "";
    if (ids === "__RETAIN__") {
      rest = `/${profileIds ? profileIds.join(",") : ""}`;
    } else if (ids) {
      rest = `/${ids.join(",")}`;
    }
    if (type) {
      rest = rest.concat(`/${type}`);
    }

    return `/activities/${id}/compare/${newTab}${rest}`;
  };

  const removeProfileId = (id) => {
    const ids = profileIds ? profileIds : shortlists.map(({ profile: { id } }) => id);
    setProfilesIds(ids.filter((pid) => pid !== id));
  };

  useEffect(() => {
    // null is valid (means all of the ids), any array is a subset
    if (profileIds && profileIds.length === 0) {
      isInterestedType ? exitToInterested() : exitToShortlist();
    } else {
      history.replace({ state: { prevPath: prevPath.current }, pathname: getPath(tab, profileIds) });
    }
  }, [profileIds]);

  const filteredProfiles = useMemo(
    () =>
      ((isInterestedType ? watchersWithShortlists : shortlists) || []).filter(
        ({ profile: { id } }) => !profileIds || profileIds.includes(id)
      ),
    [shortlists, profileIds, watchers]
  );

  const comparableProfiles = filteredProfiles.map((item) => {
    const availableProfile = availableProfiles && availableProfiles.find(({ id }) => id === item.profile.id);
    return {
      ...item,
      isAvailable: !!availableProfile,
      availabilityMatch: item.scores?.availability_score || availableProfile?.scores?.availability_score,
      profile: { ...item.profile, ...(availableProfile?.profile || {}) }
    };
  });

  const updateOne = (resp) => {
    const shortlist = shortlists && shortlists.find(({ id }) => id === resp.id);
    shortlist ? (shortlist.state = resp.state) : null;
    shortlist && setShortlists([...shortlists]);
  };

  return (
    <ActivityContextProvider id={id}>
      <DetailsPanelContextProvider>
        <BookingFormContextProvider>
          <DeleteBookingModalContextProvider>
            <WrappedComparePage
              loading={!isLoaded}
              updateOne={updateOne}
              tab={tab}
              changeStartDate={() => alert("TODO remove")}
              changeTab={changeTab}
              comparableProfiles={comparableProfiles}
              removeProfileId={removeProfileId}
              task={activity}
            />
            <DetailsPanel />
          </DeleteBookingModalContextProvider>
        </BookingFormContextProvider>
      </DetailsPanelContextProvider>
    </ActivityContextProvider>
  );
};

const fetchData = (id, profileIds, shortlists) =>
  new Promise((resolve, reject) => {
    const invitesPromise = fetchInvites({ targetType: "Task", targetId: id });

    invitesPromise
      .then(({ entries: invites }) => {
        const shortlistsWithInvite = sortBy(
          (shortlists || []).map((shortlist) => ({
            ...shortlist,
            invite: find(invites, { profile_id: shortlist.profile.id })
          })),
          ["id"]
        );

        fetchProfiles({
          filters: {
            fields: {
              id: profileIds || shortlists.map(({ profile }) => profile.id)
            }
          },
          full: true
        })
          .then(({ entries }) =>
            resolve({
              shortlistsWithInvite,
              availableProfiles: entries
            })
          )
          .catch(reject);
      })
      .catch(() => {
        reject();
      });
  });

export default CompareController;
