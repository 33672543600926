import Toggle from "PFComponents/toggle/toggle";
import Tooltip from "PFComponents/tooltip/tooltip";
import InfoIcon from "PFIcons/question_rounded.svg";
import PropTypes from "prop-types";

import ComponentPropTypes from "./component_prop_types";
import css from "./toggle_wrapper.less";

const ToggleWrapper = ({ property, label, onChange, error, value, qaId, required }) => {
  const labelWithtooltip = (
    <div className={css.toggleWrapper}>
      <Tooltip content={<div>{property?.description}</div>}>
        <div className={css.label}>
          <InfoIcon height={24} width={24} />
        </div>
      </Tooltip>
      {label} {required && "*"}
    </div>
  );

  return (
    <Toggle
      onChange={onChange}
      error={error}
      label={property?.description ? labelWithtooltip : label}
      checked={value}
      qaId={qaId}
    />
  );
};

ToggleWrapper.propTypes = {
  ...ComponentPropTypes,
  value: PropTypes.bool
};

export default ToggleWrapper;
