import { LoadingDots } from "PFComponents/loading_dots";
import SimplePagination from "PFComponents/pagination/simple_pagination";
import { useCertificates } from "PFCore/hooks/queries/certificates";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, useHistory, useParams, useRouteMatch } from "react-router-dom";

import { useProfileSectionContext } from "../../profile_section_context_provider";
import AddAccomplishmentCard from "../add_accomplishment_card";
import { CertificateCard } from "./certificate_card";
import css from "./certificates_tab.module.scss";
import EditCertificate from "./edit_certificate";
import NewCertificate from "./new_certificate";

const CertificatesTab = ({ fetchProfile }: { fetchProfile: () => void }) => {
  const [page, setPage] = useState(1);

  const { path } = useRouteMatch();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const { profile, isMe } = useProfileSectionContext();

  const perPage = isMe ? 9 : 10;
  const basePath = `/profiles/${id}/accomplishments/certificates`;

  const certificatesQuery = useCertificates(profile.id, page, perPage);
  const { t } = useTranslation("profiles");

  if (certificatesQuery.isLoading) {
    return <LoadingDots />;
  }

  const handleAddNew = () => history.push(`${basePath}/new`);

  return (
    <Switch>
      <Route exact path={path}>
        <div className={css.container}>
          {isMe && <AddAccomplishmentCard onClick={handleAddNew} title={t("show.parts.addCertificate")} />}
          {certificatesQuery.data.entries.map((certificate) => (
            <CertificateCard
              key={certificate.id}
              certificate={certificate}
              onEditClick={() => history.push(`${basePath}/edit/${certificate.id}`)}
            />
          ))}
        </div>
        <SimplePagination
          currentPage={page}
          totalPages={certificatesQuery.data.meta.totalPages}
          handleClick={setPage}
        />
      </Route>
      <Route path={`${path}/new`}>
        <NewCertificate onClose={() => history.push(basePath)} fetchProfile={fetchProfile} />
      </Route>
      <Route path={`${path}/edit/:certificateId`}>
        <EditCertificate onClose={() => history.push(basePath)} />
      </Route>
    </Switch>
  );
};

export default CertificatesTab;
