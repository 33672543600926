import { useQuery } from "@tanstack/react-query";
import { fetchProfileAvailabilitiesExternal } from "PFApp/booking/services/api";
import { DateRange } from "PFTypes";

import profileKeys from "./query_keys";

type UseProfileAvailabilitiesPerDay = {
  profileId: number;
  dateRange: DateRange;
};

export const useProfileAvailabilitiesPerDay = ({ profileId, dateRange }: UseProfileAvailabilitiesPerDay) =>
  useQuery(profileKeys.availabilitiesPerDay(profileId, dateRange), () =>
    fetchProfileAvailabilitiesExternal(profileId, dateRange)
  );
