import classNames from "classnames";
import { Icon, IconDefaultSize, IconProps } from "PFComponents/icon";
import { forwardRef, SyntheticEvent } from "react";

import css from "./action_icon.module.scss";

export type ActionIconProps = {
  classes?: {
    root?: string;
    icon?: string;
  };
  disableHover?: boolean;
  title?: string;
  onClick?: (event: SyntheticEvent) => void;
  disabled?: boolean;
  qaId?: string;
  id?: HTMLButtonElement["id"];
} & IconProps;

export const ActionIcon = forwardRef<HTMLButtonElement, ActionIconProps>(
  (
    { id, classes = {}, disableHover, onClick, size = IconDefaultSize, title, disabled, qaId, ...iconProps },
    ref
  ): JSX.Element => (
    <button
      ref={ref}
      type="button"
      onClick={onClick}
      aria-label={iconProps.name}
      disabled={disabled}
      title={title}
      data-qa-id={qaId}
      id={id}
      className={classNames(css.actionButton, classes.root, {
        [css.disabled]: disabled,
        [css.disableHover]: disableHover,
        [css.smallActionButton]: size === "sm" || size === "xs"
      })}
    >
      <Icon size={size} className={classes.icon} {...iconProps} />
    </button>
  )
);
ActionIcon.displayName = "ActionIcon";
