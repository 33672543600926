import { Button } from "PFComponents/button/button";
import { Activity, Shortlist } from "PFTypes";
import { useTranslation } from "react-i18next";

import { useBookingFormContext } from "../../../../../components/booking_form";
import { BookingFormMode } from "../../../../../components/booking_form/use_booking_form";
import { useBookingsOverviewContext } from "../../../context/bookings_overview_context";
import css from "../shortlist_actions.modules.scss";

type BookButtonProps = {
  shortListItem: Shortlist;
  activity?: Activity;
  disabled?: boolean;
};

export const BookButton = ({ shortListItem, activity, disabled = false }: BookButtonProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    modal: { open: openModal }
  } = useBookingFormContext();

  const {
    calendarData: { data }
  } = useBookingsOverviewContext();

  const parentActivityId = activity?.parent_activity_id;
  const availability = activity?.availability;
  const bookingCategoryId = activity?.booking_category_id;

  const bookingModalInitialData = {
    profileId: shortListItem.profile.id,
    activityId: shortListItem.activity_id,
    parentActivityId,
    shortlistId: shortListItem.id,
    categoryId: bookingCategoryId,
    availability,
    searchId: data.meta.search_id,
    total: data.meta.total,
    entries: data.entries,
    bookings: []
  };

  return (
    <Button
      text={t("book")}
      className={css.button}
      kind="secondary"
      onClick={() => openModal(bookingModalInitialData, { mode: BookingFormMode.Create })}
      disabled={disabled}
    />
  );
};
