import classNames from "classnames";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";

import css from "./folder_tabs.less";

const getSelectedTabIndex = (tabs, selectedTabId) =>
  Math.max(
    0,
    tabs.findIndex((tab) => tab.id === selectedTabId)
  );

/**
 * @deprecated Use Tabs instead
 */
const FolderTabs = ({
  tabs,
  selectedTabId,
  actionTabContent,
  onTabSelected,
  classes = {},
  additionalContent
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(getSelectedTabIndex(tabs, selectedTabId));

  useEffect(() => {
    setSelectedTabIndex(getSelectedTabIndex(tabs, selectedTabId));
  }, [selectedTabId]);

  const { Content } = tabs[selectedTabIndex];

  const onTabClicked = useCallback((tab, index) => {
    setSelectedTabIndex(index);
    if (onTabSelected) {
      onTabSelected(tab);
    }
  }, []);

  return (
    <div className={classNames(css.container, classes.root)}>
      <div className={classNames(css.tabsOuter, classes.outer)}>
        <div className={css.tabsInner}>
          {tabs.map((tab, index) => (
            <div
              key={tab.id}
              className={classNames(css.tab, classes.tab, { [css.active]: index === selectedTabIndex })}
              onClick={() => onTabClicked(tab, index)}
              onKeyDown={() => onTabClicked(tab, index)}
              tabIndex="0"
              role="button"
            >
              {tab.displayElement}
              {tab.count !== undefined && (
                <div className={classNames(css.badge, { [css.active]: index === selectedTabIndex })}>
                  {tab.count}
                </div>
              )}
            </div>
          ))}
        </div>
        {additionalContent}
        {actionTabContent && <div className={classNames(css.tab, css.action)}>{actionTabContent}</div>}
      </div>

      <div className={classNames(css.content, classes.content)}>{Content}</div>
    </div>
  );
};

FolderTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      displayElement: PropTypes.node.isRequired,
      count: PropTypes.number,
      Content: PropTypes.node.isRequired
    })
  ),
  selectedTabId: PropTypes.string,
  actionTabContent: PropTypes.node,
  onTabSelected: PropTypes.func,
  classes: PropTypes.object,
  additionalContent: PropTypes.node
};

export default FolderTabs;
