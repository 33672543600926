import classNames from "classnames";
import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import SidePanel from "PFComponents/side_panel/side_panel";
import { PageTarget } from "PFTypes/saved_filters";
import { JSX, useRef } from "react";
import { useTranslation } from "react-i18next";

import { SaveAsView } from "../save_as_view";
import { useSavedViewsContext } from "../saved_views_context/saved_views_context";
import { FiltersContent } from "./filters_content";
import { FiltersBaseProps } from "./filters_element";
import { FiltersHeader } from "./filters_header";
import css from "./filters_panel.module.scss";
import { AccordionProps } from "./filters_panel.types";

export type FiltersPanelProps = {
  inSidepanel?: boolean;
  onFiltersChange?: (filters: any) => void;
  clearFilters?: (params?: any) => void;
  closeFilters?: () => void;
  blockedList?: string[];
  filtersAreClear?: boolean;
  allowToClose?: boolean;
  accordionProps?: AccordionProps;
  isLoading?: boolean;
  viewsKey?: PageTarget | null;
  classes?: {
    root?: string;
    header?: string;
    list?: string;
  };
  letRestoreAll?: boolean;
  restoreAllTooltipContent?: React.ReactNode;
  isRelevantToMeDisabled?: boolean;
  portalRef?: React.RefObject<HTMLDivElement>;
  disabled?: boolean;
  disabledLabel?: string;
} & FiltersBaseProps;

export const FiltersPanel = (props: React.PropsWithChildren<FiltersPanelProps>): JSX.Element => {
  const {
    inSidepanel = true,
    meta,
    onFiltersChange,
    clearFilters,
    closeFilters,
    filtersAreClear,
    allowToClose = true,
    isLoading,
    viewsKey = null,
    classes = {},
    letRestoreAll,
    restoreAllTooltipContent,
    isRelevantToMeDisabled,
    disabled,
    disabledLabel
  } = props;

  const lastFocusedFilterNameRef = useRef(null);
  const { t } = useTranslation();
  const { setSelectedViewId } = useSavedViewsContext(viewsKey);

  const renderHeader = () => (
    <FiltersHeader
      meta={meta}
      className={classes.header}
      disabled={disabled}
      disabledLabel={disabledLabel}
      filtersAreClear={filtersAreClear}
      lastFocusedFilterNameRef={lastFocusedFilterNameRef}
      setSelectedViewId={setSelectedViewId}
      clearFilters={clearFilters}
      letRestoreAll={letRestoreAll}
      restoreAllTooltipContent={restoreAllTooltipContent}
    />
  );

  const renderFooter = () => (
    <>
      {viewsKey && (
        <SaveAsView
          className={classNames({ [css.footerRow]: inSidepanel })}
          meta={meta}
          clearFilters={onFiltersChange}
          viewsKey={viewsKey}
          isRelevantToMeDisabled={isRelevantToMeDisabled}
        />
      )}

      {allowToClose && inSidepanel && (
        <div className={css.footerRow}>
          <Button onClick={closeFilters}>{t("done")}</Button>
        </div>
      )}
    </>
  );

  const renderInContainer = (children) => {
    if (inSidepanel) {
      return (
        <SidePanel
          title={renderHeader()}
          show
          fullHeight
          size="large"
          onClose={closeFilters}
          footerRenderer={renderFooter}
        >
          {children}
        </SidePanel>
      );
    }

    return (
      <div className={classNames(classes.root)}>
        {renderHeader()}
        {children}
        {renderFooter()}
      </div>
    );
  };

  return renderInContainer(
    <>
      {isLoading && (
        <div className={css.loadingOverlay}>
          <LoadingDots circlesEnabled circleSize="lg" />
        </div>
      )}
      <FiltersContent {...props} lastFocusedFilterNameRef={lastFocusedFilterNameRef} />
    </>
  );
};
