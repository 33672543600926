import classNames from "classnames";
import Confirm, { ConfirmProps } from "PFComponents/confirm/confirm";
import Modal from "PFComponents/modal/modal";

import css from "./confirm_modal.scss";

export type ConfirmModalProps = {
  handleBeforeClose?: () => boolean;
  targetElementId?: string;
  classes?: {
    backdrop?: string;
    modal?: string;
    fadeIn?: string;
    body?: string;
    footer?: string;
    dialog?: string;
    card?: string;
    button?: string;
  };
} & ConfirmProps;

const ConfirmModal = ({
  handleClose,
  handleBeforeClose,
  style,
  targetElementId,
  classes = {},
  children,
  ...otherProps
}: ConfirmModalProps): JSX.Element => (
  <Modal
    qaId="confirmModal"
    closeOnBackdropClick
    handleClose={handleClose}
    handleBeforeClose={handleBeforeClose}
    style={style}
    targetElementId={targetElementId}
    classes={{ ...classes, dialog: classNames(css.dialog, classes.dialog) }}
  >
    <Confirm {...otherProps} handleClose={handleClose}>
      {children}
    </Confirm>
  </Modal>
);

export default ConfirmModal;
