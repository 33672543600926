import { useTranslation } from "react-i18next";

import { useEventProfiles } from "../../../hooks/use_event_profiles";
import { useTranslatedActivityType } from "../../../hooks/use_translated_activity_type";
import { HistoryEntryProfilesAccordion } from "../components/histor_entry_profiles_accordion";
import { HistoryEntryTemplate } from "../components/history_entry_template";
import { HistoryEntrySubject } from "../components/history_event_subject";
import { HistoryEntryProps } from "../history_entry";

export const HistoryEntryGeneric = ({ entry }: HistoryEntryProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.history" });
  const { targetProfiles, targetProfile, isLoading } = useEventProfiles(entry);
  const activityType = useTranslatedActivityType();

  const badge = t(`entry.type.${entry.event}`, { activityType });

  if (!targetProfiles || (targetProfiles?.length || 0) < 2) {
    return (
      <HistoryEntryTemplate entry={entry}>
        <HistoryEntrySubject badge={badge} profile={targetProfile} isLoading={isLoading} />
      </HistoryEntryTemplate>
    );
  }

  return (
    <HistoryEntryTemplate entry={entry}>
      <HistoryEntryProfilesAccordion
        profiles={targetProfiles}
        badge={`${badge} (${targetProfiles.length})`}
      />
    </HistoryEntryTemplate>
  );
};
