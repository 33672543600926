import { useQueryClient } from "@tanstack/react-query";
import { dataDogRemoveUser } from "PFApp/bootstrapper/use_datadog_rum";
import { broadcastLogIn, broadcastLogOut } from "PFApp/bootstrapper/use_log_in_out_broadcasts";
import api from "PFCore/api";
import { lockSimple } from "PFCore/helpers/lock_simple";
import { initializeMsTeams, isMsTeams } from "PFCore/helpers/ms_teams";
import { isFeatureEnabled } from "PFCore/helpers/use_is_feature_enabled";
import { useQueryParams } from "PFCore/helpers/use_query_params";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfileSet } from "PFCore/hooks/queries/profile";
import { fetchCurrentProfile } from "PFCore/services/current_profile";
import { ApiRoute, UiRoute } from "PFCore/utilities/routes";
import { FeatureFlag } from "PFTypes";
import { useMemo, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { useDeeplink } from "./use_deeplink";

export const useSession = () => {
  const { data: currentAccount, refetch: refetchCurrentAccount } = useCurrentAccount();

  const setCurrentProfile = useCurrentProfileSet();

  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryParams();
  const heartbeatRef = useRef();
  const { saveDeeplink } = useDeeplink();
  const queryClient = useQueryClient();

  const getTokenStorageForAccount = (account) => {
    const isEnabled = isFeatureEnabled(account);
    if (isEnabled(FeatureFlag.SessionStorage) && window.pfSessionStorage) {
      return window.pfSessionStorage;
    } else {
      return window.storage;
    }
  };

  const getRefreshExpirationTime = (account) => (account?.session_ttl_in_minutes || 1200) * 60;

  const setTokensForAccount = (accessToken, refreshToken, account, refresh = false) => {
    const accountTokenStorage = getTokenStorageForAccount(account);
    const refreshExpirationTime = getRefreshExpirationTime(account);

    const refreshTokenOptions = refresh ? { preserveTime: true } : { expiresIn: refreshExpirationTime };

    accountTokenStorage.setItem("profinda_auth_access", accessToken);
    accountTokenStorage.setItem("profinda_auth_refresh", refreshToken, refreshTokenOptions);
  };

  const tokenStorage = useMemo(() => getTokenStorageForAccount(currentAccount), [currentAccount]);

  const getAccessToken = () => tokenStorage.getItem("profinda_auth_access");
  const getRefreshToken = () => tokenStorage.getItem("profinda_auth_refresh");

  const expireSession = (message) => {
    if (location.pathname !== "/sign_out") {
      saveDeeplink();
    }
    storage.removeItem("onboarding__show_job_title");
    const error = message || "sign_in.session_expired";
    signOut({ navigate: true, error });
  };

  const refreshToken = () =>
    lockSimple("refresh_token", () => {
      const accessToken = getAccessToken();
      const refreshToken = getRefreshToken();

      if (accessToken && !refreshToken) {
        expireSession();
        return Promise.resolve();
      }

      return api({
        url: "oauth/token",
        method: "post",
        data: {
          grantType: "refresh_token",
          refreshToken
        }
      })
        .then((resp) => {
          setTokensForAccount(resp.accessToken, resp.refreshToken, currentAccount, true);
        })
        .catch(function (resp) {
          // Couldn't refresh the token so we sign out
          if (resp.responseJSON.expired) {
            expireSession();
          } else {
            signOut();
          }
        });
    });

  const startHeartbeat = () => {
    const heartbeatId = window.setInterval(() => {
      if (!getRefreshToken()) {
        return expireSession();
      }
    }, 30 * 1000);
    heartbeatRef.current = heartbeatId;
    return heartbeatId;
  };

  const stopHeartbeat = () => {
    heartbeatRef.current && window.clearInterval(heartbeatRef.current);
    heartbeatRef.current = null;
  };

  const clearTokens = () => {
    tokenStorage.removeItem("profinda_auth_refresh");
    return tokenStorage.removeItem("profinda_auth_access");
  };

  const signOut = (options = {}) => {
    const navigateTolanding = () => {
      if (typeof sessionStorage !== "undefined" && sessionStorage !== null) {
        sessionStorage.setItem("signedOut", true);
      }
      clearTokens();
      stopHeartbeat();
      queryClient.clear();
      dataDogRemoveUser();
      setCurrentProfile({});
      if (options.redirectUri) {
        history.push(options.redirectUri);
      } else if (isMsTeams() || location.pathname.match(/ms_teams/)) {
        history.push("/ms_teams/signin");
      } else {
        const slo = currentAccount?.slo_target_url;
        let path = "/users/signin";
        if (options.error) {
          path = `${path}?error=${options.error}`;
        }

        if (slo) {
          history.replace(path);
        } else {
          history.push(path);
        }
      }

      if (typeof broadcastLogOut === "function") {
        broadcastLogOut();
      }
    };

    return $.post(ApiRoute("/api/oauth/revoke"), {
      client_id: PF.config.client_id,
      token: getAccessToken()
    }).then(navigateTolanding, navigateTolanding);
  };

  const signIn = (username, password, options = {}) => {
    const deferred = $.Deferred();

    $.post(ApiRoute("/api/oauth/token"), {
      grant_type: "password",
      client_id: PF.config.client_id,
      username,
      password
    }).then(
      (resp) => {
        setTokensForAccount(resp.access_token, resp.refresh_token, currentAccount);
        if (typeof sessionStorage !== "undefined" && sessionStorage !== null) {
          sessionStorage.removeItem("signedOut");
        }

        broadcastLogIn();

        return fetchCurrentProfile().then(
          (profile) => {
            setCurrentProfile(profile);
            refetchCurrentAccount();
            return deferred.resolve(resp);
          },
          (resp) => {
            if (!options.only_success) {
              signOut({});
            }
            return deferred.reject(resp);
          }
        );
      },
      (resp) => {
        if (!options.only_success) {
          signOut({});
        }
        return deferred.reject(resp);
      }
    );

    return deferred.promise();
  };

  const setup = async () => {
    const code = queryParams.get("code");
    const resetPasswordToken = queryParams.get("reset_password_token");
    const isMsTeams = queryParams.get("msTeams");
    const account = queryParams.get("account");
    if (resetPasswordToken) {
      await signOut(
        location.pathname === "/password_edit"
          ? { redirectUri: `${location.pathname}${location.search}` }
          : {}
      );
    } else if (code) {
      try {
        const response = await $.post(ApiRoute("/api/oauth/token"), {
          grant_type: "authorization_code",
          code,
          client_id: PF.config.client_id,
          redirect_uri: UiRoute("/")
        });
        if (isMsTeams) {
          const oauthRedirectMethod = queryParams.get("oauthRedirectMethod");
          const authId = queryParams.get("authId");
          response.account = account;
          if (oauthRedirectMethod === "deeplink") {
            const { access_token: accessToken, refresh_token: refreshToken } = response;
            const result = `${accessToken}-${refreshToken}-${account}`;
            window.location.href = `msteams://teams.microsoft.com/l/auth-callback?authId=${authId}&result=${result}`;
            return history.push("/ms_teams/end");
          } else {
            const microsoftTeams = await initializeMsTeams({ force: true, account });
            return microsoftTeams.authentication.notifySuccess(response);
          }
          // teams tab (ms_teams_sign_in.jsx), or bot should handle tokens from here
        }

        // Clear code param from url so we don't perform authentication twice
        history.push("/");
        setTokensForAccount(response.access_token, response.refresh_token, currentAccount);
        broadcastLogIn();
        return response.access_token;
      } catch (error) {
        await signOut();
      }
    }
  };

  return {
    getAccessToken,
    setTokensForAccount,
    signIn,
    signOut,
    refreshToken,
    startHeartbeat,
    expireSession,
    clearTokens,
    setup
  };
};
