import { Icon } from "PFComponents/icon";
import colors from "PFTheme/tokens/colors";
import { useTranslation } from "react-i18next";

import NavItem from "./nav_item";

export const AnalyticsNavItem = (props) => {
  const { t } = useTranslation("translation", { keyPrefix: "navBar.items" });
  return (
    <NavItem
      icon={<Icon name="insights" fill={colors.paletteWhite0} />}
      isActiveTest={(path) => path.match(/^\/analytics/)}
      {...props}
      name={t("analytics")}
      id="analytics"
      href="/analytics"
    />
  );
};
