import { Profile } from "PFTypes";
import { createContext, useContext, useState } from "react";

type ProfileSectionContextType = {
  isEditMode: boolean;
  setIsEditMode: (val: boolean) => void;
  profile: Profile;
  isMe: boolean;
  currentProfile: Profile;
  selectedElement: Record<string, unknown> | null;
  setSelectedElement: (record: Record<string, unknown> | null) => void;
  resetSelected: () => void;
  isSelected: boolean;
};

type ProfileSectionContextProps = {
  profile: Profile;
  currentProfile: Profile;
};

const ProfileSectionContext = createContext({} as ProfileSectionContextType);

export const ProfileSectionContextProvider = ({
  children,
  currentProfile,
  profile
}: React.PropsWithChildren<ProfileSectionContextProps>) => {
  const [selectedElement, setSelectedElement] = useState<Record<string, unknown> | null>(null);
  const isSelected = !!selectedElement;

  const resetSelected = () => setSelectedElement(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const isMe = !!(profile?.id && profile?.id === currentProfile?.id);

  return (
    <ProfileSectionContext.Provider
      value={{
        isEditMode,
        setIsEditMode,
        isMe,
        profile,
        currentProfile,
        isSelected,
        setSelectedElement,
        resetSelected,
        selectedElement
      }}
    >
      {children}
    </ProfileSectionContext.Provider>
  );
};

export const useProfileSectionContext = () => useContext(ProfileSectionContext);
