import { camelizeKeys } from "humps";
import { Avatar } from "PFComponents/avatar/avatar";
import { fromCache } from "PFComponents/avatar/avatar_cache";
import { isProfileActive } from "PFCore/helpers/profile";
import { getProfileName } from "PFCore/helpers/profile";
import PropTypes from "prop-types";
import { useMemo } from "react";

const ProfileAvatar = ({
  href,
  target,
  size,
  fullSize,
  qaId,
  fontSize,
  defaultText,
  onClick,
  profile,
  Icon,
  handleIconClick,
  tooltipContent,
  isHighlighted
}) => {
  const camelizedProfile = camelizeKeys(profile); // TODO: [PROF-5746] Remove "camelizeKeys" when all ProfileItem usages get camelized profile prop
  const avatar = camelizedProfile.avatar || {};

  const fullName = getProfileName(profile);

  const inactive = !isProfileActive(camelizedProfile);
  const initialsArray = ["firstName", "lastName"].map((key) =>
    camelizedProfile[key] ? camelizedProfile[key][0] : ""
  );
  const text = initialsArray.join("").toUpperCase() || defaultText;

  const imagePath = useMemo(() => {
    if (avatar.custom) {
      if (size > 50 || fullSize) {
        return fromCache(camelizedProfile.id, "full", avatar.fullUrl);
      } else {
        return fromCache(camelizedProfile.id, "thumb", avatar.thumbnailUrl);
      }
    }

    return "";
  }, [avatar.fullUrl, avatar.thumbnailUrl, avatar.custom, fullSize, camelizedProfile.id, size]);

  const imageAlt = `${fullName} avatar`;
  const buttonTitle = `Open chat with ${fullName}`;

  return (
    <Avatar
      key={camelizedProfile.id}
      href={href}
      target={target}
      size={size}
      fullSize={fullSize}
      fontSize={fontSize}
      inactive={inactive}
      qaId={qaId}
      text={text}
      imagePath={imagePath}
      onClick={onClick}
      Icon={Icon}
      handleIconClick={handleIconClick}
      imageAlt={imageAlt}
      buttonTitle={buttonTitle}
      tooltipContent={tooltipContent}
      isHighlighted={isHighlighted}
    />
  );
};

ProfileAvatar.propTypes = {
  profile: PropTypes.any,
  href: PropTypes.string,
  target: PropTypes.oneOf([null, "_blank"]),
  qaId: PropTypes.string,
  defaultText: PropTypes.string,

  size: PropTypes.number,
  fullSize: PropTypes.bool,
  fontSize: PropTypes.number,

  onClick: PropTypes.func,
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  handleIconClick: PropTypes.func,
  tooltipContent: PropTypes.object,
  isHighlighted: PropTypes.bool
};

ProfileAvatar.defaultProps = {
  size: 72,
  fullSize: false,
  target: null,
  defaultText: "?",
  Icon: null
};

export default ProfileAvatar;
