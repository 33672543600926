import { TEMPLATE_KEYS } from "PFApp/constants/templates";
import { useSidePanelClose } from "PFComponents/side_panel/use_side_panel_close";
import { Id } from "PFTypes";
import React, { useCallback, useEffect, useMemo } from "react";

import {
  DetailsPanelApiContext,
  DetailsPanelData,
  DetailsPanelOrderApi,
  DetailsPanelOrderContext,
  DetailsPanelRoleStateContext,
  DetailsPanelStateContext,
  DetailsSidePanelStateContext,
  StackOrderItemType,
  StoredDetailsPanelData
} from "./details_panel_context";
import { useDetailsPanelReducer } from "./use_details_panel_reducer";

export const activityTypeToSidePanelTypeMap = {
  [TEMPLATE_KEYS.ROLE]: "role",
  [TEMPLATE_KEYS.AUDIT_ROLE]: "role",
  [TEMPLATE_KEYS.ENGAGEMENT]: "engagement",
  [TEMPLATE_KEYS.AUDIT_ENGAGEMENT]: "engagement"
} as const;

export const DetailsPanelContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useDetailsPanelReducer();

  const closeDetailsPanel = useCallback(() => {
    dispatch({ type: "closeDetailsPanel" });
    dispatch({ type: "popFromOrderStack" });
  }, []);

  const { isClosing, onSidePanelClose } = useSidePanelClose({
    onClose: closeDetailsPanel
  });

  useEffect(() => {
    dispatch({ type: "setIsDetailsPanelClosing", isClosing });
  }, [isClosing]);

  const handleCloseDetailsPanel = () => {
    onSidePanelClose();
  };

  const api = useMemo(() => {
    const openDetailsPanel = (data: DetailsPanelData, stack = false) => {
      const uniqueId = new Date().getTime();
      const newData: StoredDetailsPanelData = {
        ...data,
        uniqueId
      };
      dispatch({ type: "openDetailsPanel", data: newData, stack });
      if (stack) {
        dispatch({
          type: "pushToOrderStack",
          id: data.id,
          itemType: data.type,
          uniqueId
        });
      } else {
        dispatch({ type: "clearOrderStack" });
      }
    };

    const closeAllDetailsPanels = () => {
      dispatch({ type: "closeAllDetailsPanels" });
    };

    const pushToOrderStack = (id: Id, itemType: StackOrderItemType, uniqueId: number) => {
      dispatch({ type: "pushToOrderStack", id, itemType, uniqueId });
    };

    const popFromOrderStack = () => {
      dispatch({ type: "popFromOrderStack" });
    };

    return {
      openDetailsPanel,
      closeDetailsPanel,
      closeAllDetailsPanels,
      pushToOrderStack,
      popFromOrderStack,
      closeDetailsPanelExternal: handleCloseDetailsPanel
    };
  }, []);

  const stateValue = useMemo(() => state, [state]);

  const isSidePanelOpenedApi = useMemo(
    () => ({
      isThisSidePanelOpened: (id: number, type: DetailsPanelData["type"]) =>
        state.detailsData?.id === id && state.detailsData.type === type
    }),
    [state.detailsData]
  );

  const itemsOrderApi = useMemo<DetailsPanelOrderApi>(
    () => ({
      checkTopValue: (type: StackOrderItemType) => {
        if (state.itemsOrderStack.length === 0) {
          return false;
        }
        return state.itemsOrderStack[state.itemsOrderStack.length - 1].type === type;
      },
      findIndexOfElement: (uniqueId: number) =>
        state.itemsOrderStack.findIndex((item) => item.uniqueId === uniqueId),
      findIndexOfBookingForm: () => state.itemsOrderStack.findIndex((item) => item.type === "booking_form")
    }),
    [state.itemsOrderStack]
  );

  const rolePanelValue = useMemo(
    () => ({
      openAllocateManually: state.detailsData?.openAllocateManually
    }),
    [state.detailsData?.openAllocateManually]
  );

  return (
    <DetailsPanelApiContext.Provider value={api}>
      <DetailsPanelOrderContext.Provider value={itemsOrderApi}>
        <DetailsSidePanelStateContext.Provider value={isSidePanelOpenedApi}>
          <DetailsPanelStateContext.Provider value={stateValue}>
            <DetailsPanelRoleStateContext.Provider value={rolePanelValue}>
              {children}
            </DetailsPanelRoleStateContext.Provider>
          </DetailsPanelStateContext.Provider>
        </DetailsSidePanelStateContext.Provider>
      </DetailsPanelOrderContext.Provider>
    </DetailsPanelApiContext.Provider>
  );
};
