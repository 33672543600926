import RolePill from "PFApp/components/pills/role_pill";

type LegendRoleType = {
  translationKey: string;
  state: "new" | "partially_booked" | "booked";
  Component: React.FC<any>;
};

const basicLegendRoles: LegendRoleType[] = [
  {
    translationKey: "legend.openRole",
    state: "new",
    Component: RolePill
  },
  {
    translationKey: "legend.partialBookedRole",
    state: "partially_booked",
    Component: RolePill
  },
  {
    translationKey: "legend.bookedRole",
    state: "booked",
    Component: RolePill
  }
];

export default basicLegendRoles;
