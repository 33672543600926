import ProfileEditPanel from "PFApp/profiles/edit/profile_edit_panel";
import { getProfileSectionText } from "PFApp/profiles/edit/profile_edit_sections";
import NotFoundComponent from "PFComponents/not_found/not_found";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import EditPositionForm from "../../../../common/experience/edit_position_form";
import { ItemWrapper } from "./item_wrapper";
import { PositionItem } from "./position_item";
import css from "./positions_list.module.scss";

export const PositionsList = ({
  positions,
  handleAdd,
  handleUpdate,
  handleRemove,
  adminPage,
  permissionsGroup,
  profile,
  createDisabled
}) => {
  const { t } = useTranslation("profiles");
  const [creatingNewPosition, setCreatingNewPosition] = useState(false);

  const noPositions = positions?.length === 0;

  return (
    <ProfileEditPanel
      title={getProfileSectionText("positions")}
      headingButtonLabel={t("edit.shared.addPositions")}
      headingButtonDisabled={createDisabled}
      headingButtonQaId={`profile-edit-positions-add-new`}
      handleHeadingButtonClick={() => setCreatingNewPosition(true)}
    >
      <ul className={css.list} data-qa-id={`profile-edit-positions-list`}>
        {creatingNewPosition && (
          <ItemWrapper>
            <EditPositionForm
              handleSuccess={(newRecord) => {
                setCreatingNewPosition(false);
                handleAdd && handleAdd(newRecord);
              }}
              handleRemove={(...args) => {
                setCreatingNewPosition(false);
                handleRemove && handleRemove(...args);
              }}
              profile={profile}
              permissionsGroup={permissionsGroup}
              adminPage={adminPage}
            />
          </ItemWrapper>
        )}
        {!creatingNewPosition && noPositions && (
          <NotFoundComponent
            message={t("edit.shared.noExperience")}
            buttonMessage={t("edit.shared.addExperience")}
            buttonDisabled={createDisabled}
            handleClick={() => setCreatingNewPosition(true)}
          />
        )}
        {positions?.map((position) => (
          <PositionItem
            key={position.id}
            profile={profile}
            permissionsGroup={permissionsGroup}
            isAdmin={adminPage}
            onSuccess={handleUpdate}
            onRemove={handleRemove}
            position={position}
          />
        ))}
      </ul>
    </ProfileEditPanel>
  );
};

PositionsList.propTypes = {
  positions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  handleAdd: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  adminPage: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired,
  permissionsGroup: PropTypes.any.isRequired,
  createDisabled: PropTypes.bool
};
