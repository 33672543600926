import ExperienceSection from "./section_experience";
import { SectionExperienceProps } from "./section_experience";
import { ExperienceSectionContextProvider } from "./section_experience_context_provider";

const ExperienceSectionWrapper = ({ profile, ...props }: SectionExperienceProps) => (
  <ExperienceSectionContextProvider data={profile.positions}>
    <ExperienceSection profile={profile} {...props} />
  </ExperienceSectionContextProvider>
);

export default ExperienceSectionWrapper;
