import classNames from "classnames";
import { Button, ButtonKind } from "PFComponents/button";
import { ActionIcon } from "PFCore/components/action_icon";
import { Card } from "PFCore/components/card";
import { Typography } from "PFCore/components/typography";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import css from "./confirm.module.scss";

export type ConfirmProps = {
  title: string | JSX.Element;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  handleOK?: (event: unknown) => Promise<void> | void;
  handleCancel?: (event: unknown) => void;
  handleClose?: (event: unknown) => void;
  hashChange?: boolean;
  autoHandleClose?: boolean;
  executeCloseOnOk?: boolean;
  labelOK?: React.ReactNode;
  kindOK?: ButtonKind;
  labelCancel?: string;
  disableOKButton?: boolean;
  showOKButton?: boolean;
  footerLabel?: JSX.Element | string;
  showCancelButton?: boolean;
};

const Confirm = ({
  title,
  children,
  style,
  handleOK,
  handleCancel,
  handleClose,
  hashChange = true,
  autoHandleClose = true,
  executeCloseOnOk = true,
  labelOK,
  kindOK,
  labelCancel,
  showOKButton = true,
  disableOKButton = false,
  footerLabel,
  showCancelButton = true
}: ConfirmProps) => {
  const { t } = useTranslation();

  const _handleCancel = useCallback(
    (e) => {
      handleCancel && handleCancel(e);
      handleClose && autoHandleClose && handleClose(e);
    },
    [autoHandleClose, handleCancel, handleClose]
  );

  useEffect(() => {
    if (hashChange) {
      window.addEventListener("hashchange", _handleCancel, false);
    }

    return () => window.removeEventListener("hashchange", _handleCancel);
  }, [hashChange, _handleCancel]);

  const _handleOK = (e) => {
    const handleOkResult = handleOK && handleOK(e);

    // if callback returns promise, close when promise is fulfilled
    if (handleOkResult && handleOkResult.then !== undefined) {
      handleOkResult.then(() => handleClose && autoHandleClose && executeCloseOnOk && handleClose(e));
    } else {
      handleClose && autoHandleClose && executeCloseOnOk && handleClose(e);
    }
  };

  return (
    <div className={css.confirm} style={style}>
      <Card paddingVariant="xl">
        <header className={css.header}>
          <Typography variant="h4" noMargin>
            {title}
          </Typography>

          <ActionIcon name="cross" size="sm" onClick={handleClose} classes={{ root: css.close }} />
        </header>

        <main className={css.body}>{children}</main>
        <footer
          className={classNames(css.footer, css.buttons, {
            [css.noCancelButton]: !showCancelButton
          })}
        >
          {showCancelButton && (
            <Button onClick={_handleCancel} kind="secondary">
              {labelCancel || t("cancel")}
            </Button>
          )}
          <div className={css.footerWithLabel}>
            {footerLabel && (
              <Typography variant="labelRegular" tag="span">
                {footerLabel}
              </Typography>
            )}
            {showOKButton && (
              <Button disabled={!handleOK || disableOKButton} onClick={_handleOK} kind={kindOK}>
                {labelOK || t("accept")}
              </Button>
            )}
          </div>
        </footer>
      </Card>
    </div>
  );
};

export default Confirm;
