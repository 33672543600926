import { ErrorMessage } from "PFComponents/error_message/error_message";
import Radios, { Item } from "PFComponents/radios/radios";
import Toggle from "PFComponents/toggle/toggle";
import { RequirementType } from "PFTypes";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Requirement } from "../use_requirement";
import { RequirementOption } from "./requirement_option";
import css from "./requirement_selector.module.scss";

const HOURS_PER_DAY_MAX_VALUE = 24;
const LOAD_MAX_VALUE = 500;

interface RequirementSelectorProps {
  name: string;
  requirement: Requirement;
  onTypeChange: (type: RequirementType) => void;
  onValueChange: (value: number) => void;
  withTime: boolean;
  onWithTimeChange: () => void;
  startEndHoursDiff: number;
  defaultValues: Partial<Record<RequirementType, number>>;
  isVisible: boolean;
  error: string | null;
}

export const RequirementSelector = ({
  name,
  requirement,
  onTypeChange,
  onValueChange,
  startEndHoursDiff,
  defaultValues,
  withTime,
  onWithTimeChange,
  isVisible,
  error
}: RequirementSelectorProps) => {
  const { t } = useTranslation("bookingModule", { keyPrefix: "bookings" });
  const getRequirementOptionComponent = useCallback(
    ({ id, max, withUseTime }: { id: RequirementType; max: number; withUseTime?: boolean }) => (
      <div className={css.requirementOptionContainer}>
        <RequirementOption
          id={id}
          enabled={requirement.type === id}
          onChange={onValueChange}
          defaultValue={defaultValues[id]}
          max={max}
          autofocus={isVisible}
          error={!!error}
        />
        {withUseTime && (
          <Toggle
            reversed
            checked={withTime}
            onChange={onWithTimeChange}
            description={t("useTime")}
            disabled={requirement.type !== RequirementType.Load}
          />
        )}
      </div>
    ),
    [requirement, onValueChange, onWithTimeChange, withTime, defaultValues]
  );

  const getErrorElement = useCallback(
    (type: RequirementType): Partial<Pick<Item<RequirementType>, "additionalElement">> =>
      !!error && requirement.type === type
        ? {
            additionalElement: (
              <ErrorMessage message={error} iconWidth={16} iconHeight={16} className={css.error} />
            )
          }
        : {},
    [error, requirement.type]
  );

  const items: Item<RequirementType>[] = useMemo(
    () => [
      {
        id: RequirementType.Load,
        name: `${name}_${RequirementType.Load}`,
        displayElement: getRequirementOptionComponent({
          id: RequirementType.Load,
          withUseTime: true,
          max: LOAD_MAX_VALUE
        }),
        ...getErrorElement(RequirementType.Load)
      },
      {
        id: RequirementType.TotalHours,
        name: `${name}_${RequirementType.TotalHours}`,
        displayElement: getRequirementOptionComponent({
          id: RequirementType.TotalHours,
          max: startEndHoursDiff
        }),
        ...getErrorElement(RequirementType.TotalHours)
      },
      {
        id: RequirementType.HoursPerDay,
        name: `${name}_${RequirementType.HoursPerDay}`,
        displayElement: getRequirementOptionComponent({
          id: RequirementType.HoursPerDay,
          max: HOURS_PER_DAY_MAX_VALUE
        }),
        ...getErrorElement(RequirementType.HoursPerDay)
      }
    ],
    [getRequirementOptionComponent, startEndHoursDiff, name, getErrorElement]
  );

  return (
    <div className={css.root}>
      <Radios<RequirementType>
        items={items}
        value={requirement.type}
        handleChange={(item: Item<RequirementType>) => onTypeChange(item.id)}
        classes={{
          item: css.requirementOption,
          displayElement: css.requirementOptionElement
        }}
      />
    </div>
  );
};
