/* eslint-disable react/prop-types */
import { range } from "lodash";
import moment from "moment";
import { Select } from "PFComponents/select/select";
import { useCallback, useMemo } from "react";

import { CalendarSelectProps } from "./month_selector.types";

export const Years = ({
  minDate,
  maxDate,
  displayMonth,
  setDisplayMonth
}: CalendarSelectProps): JSX.Element => {
  const minYear = moment(minDate).get("year");
  const maxYear = moment(maxDate).get("year") + 1;

  const yearOptions = useMemo(
    () =>
      range(minYear, maxYear).map((year, index) => ({
        id: index,
        displayElement: (
          <span
            key={year.toString()}
            // class added to help automation testing
            className={`${year === moment(displayMonth).get("year") ? "selected" : ""}`}
          >
            {year}
          </span>
        ),
        item: year
      })),
    [minDate, maxDate, displayMonth]
  );

  const selectedIndex = yearOptions.findIndex(
    (option) => moment(displayMonth).get("year") === option.item
  ) as number;

  const handleChange = useCallback(
    (value: number) => setDisplayMonth(moment(displayMonth).clone().set({ year: value })),
    [setDisplayMonth, displayMonth]
  );

  return (
    <Select
      readOnly
      data-qa-id="calendar-select-year"
      options={yearOptions}
      selectedIndex={selectedIndex}
      controlledValue
      value={moment(displayMonth).get("year").toString()}
      onChange={handleChange}
    />
  );
};
