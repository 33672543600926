import isEmpty from "lodash/isEmpty";
import values from "lodash/values";
import { Button } from "PFComponents/button";
import { Activity, BookingCategory, Id, RequirementType } from "PFTypes";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import BookingsTotal from "../../../parts/overview/bookings_total";
import WarningIcons from "../../warning_icons/warning_icons";
import { isRepeatedBooking } from "../booking_form.utils";
import { OverrideToggles } from "../override_toggles";
import { BookingData, BookingFormMode, FormBooking } from "../use_booking_form";
import { PotentialWarnings } from "../use_potential_warnings";
import { Requirement } from "../use_requirement";
import css from "./footer.module.scss";

type OverrideToggleData = {
  value: {
    overridesCalendar: boolean;
    overridesNonWorkingDays: boolean;
  };
  onOverridesCalendarChange: Dispatch<SetStateAction<boolean>>;
  onOverridesNonWorkingDaysChange: Dispatch<SetStateAction<boolean>>;
};

type FooterProps = {
  profileId: Id | undefined;
  bookingData: BookingData;
  bookingItems: (Partial<FormBooking> & { key: string })[];
  mode: BookingFormMode;
  activity: Activity | undefined;
  onClose: () => void;
  onSubmit: () => void;
  isSubmitDisabled: boolean;
  overrideToggleData: OverrideToggleData;
  title: string;
  bookingCategory: BookingCategory | null;
} & Pick<PotentialWarnings, "overbookings" | "misalignments">;

export const Footer = ({
  profileId,
  bookingData,
  bookingItems,
  title,
  bookingCategory,
  mode,
  activity,
  overbookings,
  misalignments,
  onClose,
  onSubmit,
  isSubmitDisabled,
  overrideToggleData
}: FooterProps) => {
  const { t } = useTranslation();

  const bookings = ((bookingData && Object.values(bookingData)) || []).map(
    ({ duration, state, end, start }) => ({
      duration,
      state,
      start_date: start,
      end_date: end
    })
  );

  const potentialBookings =
    mode !== BookingFormMode.Edit
      ? bookingItems.map(({ key }, index) => {
          const { start, end } = bookingData[index] || {};
          return {
            key,
            title,
            booking_category_id: bookingCategory?.id,
            start_date: start,
            end_date: end
          };
        })
      : [];

  const misalignmentsWithProfile = profileId
    ? [
        {
          profileId,
          misalignments
        }
      ]
    : [];

  const isOverridesNonWorkingDaysDisabled =
    isEmpty(bookingData) ||
    values<{ requirement: Requirement }>(bookingData).some(
      ({ requirement }) => requirement.type === RequirementType.Load
    );

  return (
    <div className={css.root}>
      <OverrideToggles
        {...overrideToggleData}
        shouldBeVisible={!isRepeatedBooking(bookingData)}
        isOverridesNonWorkingDaysDisabled={isOverridesNonWorkingDaysDisabled}
      />
      <div className={css.buttonsSection}>
        <Button kind="secondary" text={t("cancel")} onClick={onClose} />
        <div className={css.content}>
          <BookingsTotal bookings={bookings} isEditMode={mode !== BookingFormMode.Create} />
          <WarningIcons
            profileId={profileId}
            roleId={activity?.id}
            roleName={activity?.name ?? ""}
            overbookings={overbookings}
            misalignments={misalignmentsWithProfile}
            potentialBookings={potentialBookings}
          />
        </div>
        <Button text={t("save")} onClick={onSubmit} disabled={isSubmitDisabled} />
      </div>
    </div>
  );
};
