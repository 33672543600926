import { datadogRum } from "@datadog/browser-rum";
import { ActionIcon } from "PFComponents/action_icon";
import { Select } from "PFComponents/select/select";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import FacebookIcon from "PFIcons/facebook.svg";
import InstagramIcon from "PFIcons/instagram.svg";
import MsTeamsIcon from "PFIcons/microsoft_teams.svg";
import PhoneIcon from "PFIcons/phone.svg";
import SkypeIcon from "PFIcons/skype.svg";
import SkypeBusinessIcon from "PFIcons/skype_business.svg";
import TwitterIcon from "PFIcons/twitter.svg";
import WebsiteIcon from "PFIcons/website.svg";
import { FeatureFlag } from "PFTypes";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { humanize } from "underscore.string";

import css from "./contact_information_field.less";

const ContactInformationEditField = ({ error, locked, lockedTip, handleChange, handleRemove, contact }) => {
  const { t } = useTranslation("profiles", { keyPrefix: "edit.fields" });
  const isEnabled = useIsFeatureEnabled();

  const [state, setState] = useState({
    input_id: contact.input_id,
    type: contact.type,
    value: contact.value,
    label: contact.label
  });

  const availableTypes = useMemo(() => {
    const types = ["phone"];
    if (isEnabled(FeatureFlag.Website)) {
      types.push("website");
    }
    if (isEnabled(FeatureFlag.Facebook)) {
      types.push("facebook");
    }
    if (isEnabled(FeatureFlag.Instagram)) {
      types.push("instagram");
    }
    if (isEnabled(FeatureFlag.Twitter)) {
      types.push("twitter");
    }
    if (isEnabled(FeatureFlag.Skype)) {
      types.push("skype");
    }
    if (isEnabled(FeatureFlag.SkypeBusiness)) {
      types.push("skype_business");
    }
    types.push("ms_teams");

    return types;
  }, []);

  const getIconClass = (type) => {
    switch (type) {
      case "twitter":
        return TwitterIcon;
      case "facebook":
        return FacebookIcon;
      case "instagram":
        return InstagramIcon;
      case "skype":
        return SkypeIcon;
      case "skype_business":
        return SkypeBusinessIcon;
      case "website":
        return WebsiteIcon;
      case "phone":
        return PhoneIcon;
      case "ms_teams":
        return MsTeamsIcon;
    }
  };

  const getValueLabel = (type) => {
    switch (type) {
      case "twitter":
      case "facebook":
      case "instagram":
      case "skype":
        return t("contactInfo.username");
      case "ms_teams":
      case "skype_business":
        return t("contactInfo.email");
      case "website":
        return t("contactInfo.url");
      case "phone":
        return t("contactInfo.phoneNumber");
      default:
        datadogRum.addError(new Error("Unknown contact_information type"), { type: type });
        return t("contact");
    }
  };

  const onChange = (field, value) => {
    const newState = { ...state, [field]: value };
    setState(newState);
    handleChange && handleChange(newState);
  };

  const onRemove = () => {
    handleRemove && handleRemove(contact);
  };

  const { type, value, label } = state;

  const allowLabel = ["phone", "website"].includes(type);

  const valueLabel = getValueLabel(type);
  const contactLabel = t(`contactLabel.${type}`, { defaultValue: humanize(type) });

  return (
    <div className={css.root} data-qa-id="profile-edit-contact-info-link">
      <div className={css.type} data-qa-id="select-component-type">
        <Select
          label={t("type")}
          readOnly
          locked={locked}
          lockedTip={lockedTip}
          value={contactLabel}
          controlledValue
          options={availableTypes.map((type) => {
            const contactLabel = t(`contactLabel.${type}`, {
              defaultValue: humanize(type)
            });

            const Icon = getIconClass(type);

            return {
              id: type,
              displayElement: (
                <React.Fragment>
                  <Icon width={14} height={14} /> <span style={{ paddingBottom: 12 }}>{contactLabel}</span>
                </React.Fragment>
              ),
              item: type
            };
          })}
          onChange={(value) => onChange("type", value)}
        />
      </div>
      <div className={css.text} data-qa-id="profile-edit-contact-info-link-input">
        <InputFieldSet
          kind="bordered"
          label={valueLabel}
          value={value}
          error={error}
          onChange={(value) => onChange("value", value)}
          locked={locked}
          lockedTip={lockedTip}
        />
      </div>
      {allowLabel && (
        <div className={css.text} data-qa-id="profile-edit-contact-info-label-input">
          <InputFieldSet
            kind="bordered"
            label={t("label")}
            value={label}
            onChange={(value) => onChange("label", value)}
            locked={locked}
            lockedTip={lockedTip}
          />
        </div>
      )}
      {!locked && (
        <div className={css.remove} data-qa-id="profile-edit-contact-info-link-remove">
          <ActionIcon name="remove" onClick={onRemove} disabled={locked} />
        </div>
      )}
    </div>
  );
};

ContactInformationEditField.propTypes = {
  contact: PropTypes.shape({
    type: PropTypes.string.isRequired,
    value: PropTypes.string,
    label: PropTypes.string,
    input_id: PropTypes.number
  }).isRequired,
  error: PropTypes.string,
  locked: PropTypes.bool,
  lockedTip: PropTypes.string,

  handleChange: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired
};

ContactInformationEditField.defaultProps = {
  locked: false,
  error: null
};

export default ContactInformationEditField;
