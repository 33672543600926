import { capitalize } from "lodash";
import { ProfileItem } from "PFApp/components/profile_item";
import { Button } from "PFComponents/button";
import ConfirmModal from "PFComponents/confirm/confirm_modal";
import Toggle from "PFComponents/toggle/toggle";
import { getProfileName } from "PFCore/helpers/profile";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { ActivityProfile, ActivityProfileType, Profile } from "PFTypes";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./activity_profiles_list.module.scss";

type ActivityProfileItemProps = {
  activityProfile: ActivityProfile & { unbiased?: boolean };
  currentProfile: Profile;
  onRemove?: Function;
  handleAddUnbiasedMode?: Function;
  isUnbiasedEnabled: boolean;
};

const ActivityProfileItem = ({
  activityProfile,
  currentProfile,
  onRemove,
  isUnbiasedEnabled,
  handleAddUnbiasedMode
}: ActivityProfileItemProps) => {
  const isUserUnbiased = activityProfile.profile_id !== currentProfile.id;
  const [obscuredRequest, setObscuredRequest] = useState(activityProfile.unbiased);
  const { t } = useTranslation(["activities", "translation"]);

  return (
    <div className={css.profileItem}>
      <ProfileItem profile={activityProfile.profile} style={{ flexGrow: 1, padding: "5px 5px 5px 0" }} />
      {isUnbiasedEnabled && isUserUnbiased && (
        <Toggle
          label={t("activities:parts.hideIdentity")}
          data-qa-id="Toggle"
          inline
          disabled={!handleAddUnbiasedMode}
          checked={obscuredRequest}
          onChange={(status) => {
            setObscuredRequest(status);
            handleAddUnbiasedMode?.({
              ...activityProfile,
              profile: { ...activityProfile.profile, unbiased: status }
            });
          }}
        />
      )}
      {onRemove && (
        <Button
          className={css.removeButton}
          text={t(
            activityProfile.profile_id === currentProfile.id
              ? "activities:show.parts.removeYourself"
              : "translation:remove"
          )}
          kind="danger"
          onClick={() => onRemove(activityProfile)}
        />
      )}
    </div>
  );
};

type ActivityProfilesListProps = {
  activityProfiles: (ActivityProfile & { unbiased?: boolean })[];
  title: string;
  onRemove?: Function | null;
  handleAddUnbiasedMode?: Function;
  showUnbiasedToggle?: boolean;
  isUnbiasedEnabled: boolean;
  getRemoveModalContent?: (profile: Profile) => string | undefined;
  activityProfileType?: ActivityProfileType;
};

const ActivityProfilesList = ({
  title,
  isUnbiasedEnabled,
  activityProfiles,
  onRemove,
  handleAddUnbiasedMode,
  showUnbiasedToggle,
  getRemoveModalContent,
  activityProfileType
}: ActivityProfilesListProps) => {
  const anyProfiles = activityProfiles.length !== 0;
  const { data: currentProfile } = useCurrentProfile();

  const { t } = useTranslation("activities");

  const [activityProfileToRemove, setActivityProfileToRemove] = useState<ActivityProfile | null>(null);

  const handleRemoveClick = (activityProfile: ActivityProfile) => {
    if (activityProfile.id) {
      setActivityProfileToRemove(activityProfile);
    } else {
      onRemove?.(activityProfile);
    }
  };

  const allowRemovingLast = () => {
    const isCurrentProfileLastOwner =
      activityProfiles.length === 1 && activityProfiles[0].profile.id === currentProfile.id;

    if (!activityProfileToRemove || isCurrentProfileLastOwner) {
      return false;
    }

    switch (activityProfileType) {
      case ActivityProfileType.Reviewer:
      case ActivityProfileType.Assignee:
        return activityProfileToRemove.profile.active;
      case ActivityProfileType.Owner:
        if (activityProfileToRemove.profile.active) {
          return activityProfiles?.filter(({ profile }) => profile.active).length > 1;
        } else {
          return true;
        }
      default:
        return true;
    }
  };

  const removeModalTitle = useMemo(
    () => t(`parts.remove${capitalize(activityProfileType) as Capitalize<ActivityProfileType>}`),
    [activityProfileType, t]
  );

  return (
    <>
      {anyProfiles && (
        <div>
          <div className={css.headerWrapper}>
            <div className={css.title}>
              {title}
              {activityProfiles.length >= 2 ? ` (${activityProfiles.length}):` : ":"}
            </div>
          </div>
          {activityProfiles.map((activityProfile) => (
            <ActivityProfileItem
              key={activityProfile.id}
              activityProfile={activityProfile}
              currentProfile={currentProfile}
              onRemove={handleRemoveClick}
              isUnbiasedEnabled={isUnbiasedEnabled && !!showUnbiasedToggle}
              handleAddUnbiasedMode={handleAddUnbiasedMode}
            />
          ))}
        </div>
      )}
      {activityProfileToRemove && (
        <ConfirmModal
          title={removeModalTitle}
          handleOK={() => onRemove?.(activityProfileToRemove)}
          handleClose={() => setActivityProfileToRemove(null)}
          showOKButton={allowRemovingLast()}
          kindOK="danger"
        >
          {getRemoveModalContent?.(activityProfileToRemove.profile) ||
            t("parts.sureWantToRemove", { profileName: getProfileName(activityProfileToRemove.profile) })}
        </ConfirmModal>
      )}
    </>
  );
};

export default ActivityProfilesList;
