import { Row } from "@tanstack/react-table";
import classNames from "classnames";
import Tooltip from "PFComponents/tooltip/tooltip";

import { TableData } from "./table";
import css from "./table.module.scss";
import { OnRowClickFn } from "./table.types";
import { TableCell } from "./table_cell";

type TableRowProps<TABLE_DATA extends TableData> = {
  row: Row<TABLE_DATA>;
  onRowClick?: OnRowClickFn<TABLE_DATA>;
  compact?: boolean;
};

export const TableRow = <TABLE_DATA extends TableData>({
  row,
  onRowClick,
  compact
}: TableRowProps<TABLE_DATA>): JSX.Element => {
  const { disabled, message } = row.original;

  const handleRowClick = () => {
    if (onRowClick) {
      onRowClick(row.original);
    }
  };

  return (
    <Tooltip content={<div>{message}</div>} disabled={!message} placement="top-start" appendTo="parent">
      {/* eslint-disable-next-line max-len */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <tr
        className={classNames(css.row, css.rowBody, { [css.disabled]: disabled })}
        style={{ cursor: onRowClick ? "pointer" : "default" }}
        onClick={handleRowClick}
      >
        {row.getVisibleCells().map((cell) => (
          <TableCell key={cell.id} cell={cell} compact={compact} />
        ))}
      </tr>
    </Tooltip>
  );
};
