import classNames from "classnames";
import { AddOwnersModal } from "PFApp/activities/show/parts/add_owners_modal";
import { useMetadata } from "PFApp/components/activity_commons/use_metadata";
import { Button } from "PFComponents/button";
import { Icon } from "PFComponents/icon";
import ToggleableText from "PFComponents/toggleable_text/toggleable_text";
import Tooltip from "PFComponents/tooltip/tooltip";
import { getProfileName } from "PFCore/helpers/profile";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { sumBookedChildren } from "../../../parts/helpers";
import css from "../../../parts/overview/detail_view/detail_view.module.scss";
import { useBookingActivityContext } from "../../../parts/providers/booking_activity_context_provider";

export const EngagementDetailContent = () => {
  const { formatDate, utc } = useDateFormatter();
  const { t } = useTranslation("bookingModule");

  const [showAddOwners, setShowAddOwners] = useState(false);
  const { activity, id } = useBookingActivityContext();

  const metadata = useMetadata({ activity });
  const filteredMetadata = metadata.filter(
    ({ type, title }) => "availability" !== type && !["Skills", "Duration"].includes(title)
  );

  if (!activity) {
    return null;
  }

  const {
    profile,
    post_until,
    duration,
    owners,
    description,
    created_at,
    coowned,
    children_workflow_states_tally
  } = activity;

  const daysLeft = -Math.trunc(utc().diff(utc(post_until), "days", true));
  const rolesTotal = Object.values(children_workflow_states_tally).reduce((sum, num) => sum + num, 0);
  const rolesBooked = sumBookedChildren(children_workflow_states_tally);

  return (
    <div className={classNames(css.root)}>
      <div className={css.item}>
        <div className={css.label}> {t("owner")}</div>
        <span className={css.spaceBetween}>
          <div>
            {owners.map(({ profile }, index) => (
              <React.Fragment key={profile.id}>
                <Link to={`/profiles/${profile.id}`}>{getProfileName(profile)}</Link>
                {index !== owners.length - 1 && ", "}
              </React.Fragment>
            ))}
          </div>

          {coowned && <Button icon="add" kind="secondary" onClick={() => setShowAddOwners(true)} />}
        </span>
      </div>
      <div className={css.item}>
        <div className={css.label}> {t("description")}</div>
        <ToggleableText text={description} />
      </div>
      <span className={classNames(css.item, css.duration, css.spaceBetween)}>
        <span className={classNames(css.alignCenter, css.gap5)}>
          <Icon name="calendar" size="sm" />
          <span className={css.label}>{t("duration")} </span>
          {duration ? (
            <>
              <span className={css.strong}>
                {duration.start_date ? formatDate(utc(duration.start_date)) : "-"}
              </span>
              <span className={css.text}> {t("details.durationTo")}</span>
              <span className={css.strong}>
                {duration.end_date ? formatDate(utc(duration.end_date)) : "-"}
              </span>
            </>
          ) : (
            "-"
          )}
        </span>
      </span>
      <span className={css.details}>
        <span className={css.item}>
          <div className={css.label}>{t("postUntil")}</div>
          <span className={classNames(css.alignCenter, css.gap5)}>
            <Icon name="hourglass-half" size="sm" />
            {t("day", { count: daysLeft })}
          </span>
        </span>
        <span className={css.item}>
          <div className={css.label}>{t("createdAt")}</div>
          <span className={classNames(css.alignCenter, css.gap5)}>
            <Icon name="calendar" size="sm" />
            {formatDate(utc(created_at))}
          </span>
        </span>
        <span className={css.item}>
          <div className={css.label}>{t("roles")}</div>
          <span className={classNames(css.alignCenter, css.gap5)}>
            <Icon name="role" size="sm" />
            <Link to={`/activities/${id}/roles`}>{rolesTotal}</Link>
          </span>
        </span>
        <span className={css.item}>
          <div className={css.label}>{t("booked")}</div>
          <span className={classNames(css.alignCenter, css.gap5)}>
            <Icon name="check" size="sm" />
            <Link to={`/activities/${id}/roles`}>{rolesBooked}</Link>
          </span>
        </span>
      </span>
      <div className={css.separator} />
      <div className={css.item}>
        <div className={css.label}>{t("privacy")}</div>
        <span className={classNames(css.alignCenter, css.gap5)}>
          {t(activity.private ? "private" : "public")}
          {activity.private && (
            <Tooltip content={t("details.privacyTooltipText")} appendTo="parent">
              <span className={css.alignCenter}>
                <Icon name="info" size="sm" />
              </span>
            </Tooltip>
          )}
        </span>
      </div>
      <span className={css.details}>
        {filteredMetadata.map(
          ({ title, text }) =>
            text !== undefined &&
            text !== "" && (
              <div key={title} className={css.item}>
                <div className={css.label}>{title}</div>
                <span>{text}</span>
              </div>
            )
        )}
      </span>

      <div className={css.separator} />
      <div className={css.item}>
        <div className={css.label}>{t("creator")}</div>
        <Link to={`/profiles/${profile.id}`}>{getProfileName(profile)}</Link>
      </div>
      {showAddOwners && <AddOwnersModal handleClose={() => setShowAddOwners(false)} activity={activity} />}
    </div>
  );
};
