import {
  ActivityHistoryEntry,
  ActivityMember,
  ActivityMemberEvent,
  ActivityMembers,
  ActivityShareEvent,
  CommonActivityHistoryEntryProperties,
  EventAction,
  EventActions,
  EventShortlistAction,
  EventShortlistActions
} from "PFTypes";

export const isActivityMemberEvent = (
  entry: ActivityHistoryEntry
): entry is CommonActivityHistoryEntryProperties & ActivityMemberEvent => {
  const [prefix, suffix] = entry.event.split("_");

  return (
    (ActivityMembers.includes(prefix as ActivityMember) && EventActions.includes(suffix as EventAction)) ||
    (EventShortlistActions.includes(suffix as EventShortlistAction) && prefix === "shortlist")
  );
};

export const isActivityShareEvent = (
  entry: ActivityHistoryEntry
): entry is CommonActivityHistoryEntryProperties & ActivityShareEvent =>
  entry.event === "activity_shared" || entry.event === "help_requested";
