import { uniqueId } from "lodash";
import moment, { Moment, utc } from "moment";
import { useProfileInvalidate } from "PFCore/hooks/queries/profile";
import { useProfileBookings } from "PFCore/hooks/queries/profile/use_profile_bookings";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Booking, BookingCategory, CalendarRange, CurrentProfile, Profile } from "PFTypes";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

import { getRoleJobCodeDisplayAs } from "../../use_role_job_code";

const filterBookings = (bookings: BookingWithProfileId[], search: string | null, currentMonth: string) => {
  if (!search) {
    return bookings;
  }

  return bookings
    .filter(({ start_date, end_date }) => {
      const isBookingBeforeCurrentMonth = utc(end_date).isBefore(currentMonth, "month");
      const isBookingAfterCurrentMonth = utc(start_date).isAfter(currentMonth, "month");
      return !isBookingBeforeCurrentMonth && !isBookingAfterCurrentMonth;
    })
    .filter(({ title }) => title && title.toLowerCase().includes(search.trim().toLowerCase()));
};

export type CalendarSearch = {
  isSearchEnabled: boolean;
  search: string;
  setSearch: (search: string) => void;
};

type ProfileBookingsConfig = { tooltip?: { hideActivityLink?: boolean } };
type ProfileBookingsContextType = {
  selectedBooking: Booking | null;
  bookings: BookingWithProfileId[];
  bookingCategories: BookingCategory[];
  config?: ProfileBookingsConfig;
  jobCodeDisplayAs: string;
  refreshBookings: () => void;
  selectBooking: (booking: Booking) => void;
  handleDateRangeChange: (min: Moment, max: Moment, currentMonth: Moment) => void;
  fetchAllBookings: () => void;
  calendarSearch: CalendarSearch;
};

type ProfileBookingsProviderProps = {
  currentProfile: CurrentProfile;
  profile: Profile;
  config?: ProfileBookingsConfig;
  bookingCategories: BookingCategory[];
  isSearchEnabled?: boolean;
};

type BookingWithProfileId = Booking & {
  _localId: string;
  profile_id: number;
};

const ProfileBookingsContext = createContext({} as ProfileBookingsContextType);

export const ProfileBookingsProvider = ({
  children,
  currentProfile,
  config,
  profile,
  bookingCategories,
  isSearchEnabled
}: React.PropsWithChildren<ProfileBookingsProviderProps>) => {
  const [selectedBooking, selectBooking] = useState<Booking | null>(null);
  const [bookings, setBookings] = useState<BookingWithProfileId[]>([]);
  const [search, setSearch] = useState("");
  const { formatISODate } = useDateFormatter();
  const [dateRange, setDateRange] = useState<CalendarRange>();
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [fetchAllEnabled, setFetchAllEnabled] = useState<boolean>(false);
  const { data } = useProfileBookings({
    profileId: profile.id,
    ...(!fetchAllEnabled ? { dateRange } : {}),
    options: {
      enabled: !!dateRange || fetchAllEnabled
    }
  });
  const { invalidateProfileBookings } = useProfileInvalidate();

  const jobCodeDisplayAs = useMemo(() => getRoleJobCodeDisplayAs(currentProfile), [currentProfile]);

  useEffect(() => {
    if (!data?.entries) {
      return;
    }
    setBookings(
      (data?.entries ?? []).map((entry) => ({
        ...entry,
        _localId: uniqueId("local"),
        // eslint-disable-next-line camelcase
        profile_id: profile.id
      }))
    );
  }, [data?.entries]);

  const refreshBookings = async () => {
    await invalidateProfileBookings({ id: profile.id });
  };

  const handleDateRangeChange = (min: Moment, max: Moment, month: Moment) => {
    setDateRange({
      min: formatISODate(min),
      max: formatISODate(max)
    });
    setCurrentMonth(month);
  };

  return (
    <ProfileBookingsContext.Provider
      value={{
        selectedBooking,
        bookings: isSearchEnabled ? filterBookings(bookings, search, formatISODate(currentMonth)) : bookings,
        bookingCategories,
        config,
        jobCodeDisplayAs,
        refreshBookings,
        selectBooking,
        handleDateRangeChange,
        fetchAllBookings: () => setFetchAllEnabled(true),
        calendarSearch: {
          search,
          setSearch,
          isSearchEnabled: !!isSearchEnabled
        }
      }}
    >
      {children}
    </ProfileBookingsContext.Provider>
  );
};

export const useProfileBookingsContext = () => useContext(ProfileBookingsContext);
