import { Profile } from "PFTypes";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import EditButton from "../edit_button";
import SectionLayout from "../section_layout";
import css from "./section_experience.modules.scss";
import ExperienceSectionContent from "./section_experience_content";
import { useExperienceSectionContext } from "./section_experience_context_provider";

export type SectionExperienceProps = {
  profile: Profile;
  currentProfile: Profile;
  customTypes: Record<string, unknown>[];
  internalExperiences: Record<string, unknown>[];
  fetchInternalExperience: () => void;
  handleProfileUpdate: () => void;
  adminPage: boolean;
  className: string;
};

const ExperienceSection = ({ currentProfile, profile, className }: SectionExperienceProps) => {
  const { setEditMode, isEditMode } = useExperienceSectionContext();
  const { t } = useTranslation("profiles");

  const isMe = profile?.id && profile?.id === currentProfile?.id;

  return (
    <SectionLayout
      header={{
        title: t("show.parts.experience"),
        actionButtons: isMe ? <EditButton isEditMode={isEditMode} setEditMode={setEditMode} /> : <></>
      }}
      sidePanel={<div />}
      className={className}
    >
      <div className={css.content}>
        <ExperienceSectionContent />
      </div>
    </SectionLayout>
  );
};

ExperienceSection.propTypes = {
  profile: PropTypes.object,
  currentProfile: PropTypes.object,
  customTypes: PropTypes.arrayOf(PropTypes.object),
  internalExperiences: PropTypes.object,
  fetchInternalExperience: PropTypes.func,
  handleProfileUpdate: PropTypes.func,
  adminPage: PropTypes.bool
};

export default ExperienceSection;
