import classNames from "classnames";
import { upperFirst } from "lodash";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { fetchProfileWarnings } from "PFApp/booking/services/api";
import { UNDETERMINED_FUTURE } from "PFApp/constants/dates";
import { useGrowl } from "PFApp/use_growl";
import { Button } from "PFComponents/button";
import ConfirmModal from "PFComponents/confirm/confirm_modal";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import {
  useShortlistFill,
  useShortlistFillAndBook,
  useShortlistReject
} from "PFCore/hooks/queries/shortlists/use_shortlist_actions";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Activity, ShortlistMinimized, Template } from "PFTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import css from "./assignment_actions.module.scss";

interface AssignmentActionsProps {
  shortlistItem: ShortlistMinimized;
  template: Template;
  activity: Activity;
}

export const AssignmentActions = ({ shortlistItem, template, activity }: AssignmentActionsProps) => {
  const history = useHistory();
  const { formatISODate } = useDateFormatter();
  const { canSeeBookingModule, task, showAsInternalExperience } = useActivityPageContext();
  const { t } = useTranslation(["activities", "translation"]);
  const { getFormattedErrors } = useResponseErrors();
  const isAutoBookingCreationEnabled = !!template?.automations?.auto_create_bookings?.enabled;
  const growl = useGrowl();

  const { mutateAsync: fillShortlist } = useShortlistFill();
  const { mutateAsync: fillAndBookShortlist } = useShortlistFillAndBook();
  const { mutate: rejectShortlist } = useShortlistReject();
  const { invalidateVacancies, invalidate: invalidateActivity } = useActivityInvalidate();

  const { state } = shortlistItem;
  const [showDeclineConfirm, setShowDeclineConfirm] = useState(false);

  const isAccepted = state === "filled";
  const isDeclined = state === "rejected";

  const errorMessage = ({ response }) => {
    const errors = getFormattedErrors(response, {});
    return errors.id ? upperFirst(errors.id) : t("translation:somethingWentWrong");
  };

  const handleAccept = async () => {
    const options = {
      onSuccess: () => {
        task && invalidateVacancies(task.id);
        invalidateActivity([task.id]);
        if (showAsInternalExperience) {
          growl({
            message: t("activities:show.parts.assignmentAccepted"),
            kind: "success",
            url: "/profile/edit/internal_experience"
          });
        } else {
          growl({
            message: t("activities:show.parts.assignmentSubmitted"),
            kind: "success"
          });
        }
      },
      onError: (response) => growl({ message: errorMessage(response), kind: "error" })
    };

    try {
      await (isAutoBookingCreationEnabled
        ? fillAndBookShortlist(
            { shortlistId: shortlistItem.id, task: activity, profileId: shortlistItem.profile_id },
            options
          )
        : fillShortlist(shortlistItem.id, options));
    } catch (error) {
      return;
    }

    if (!canSeeBookingModule) {
      return;
    }

    const warningsResponse = await fetchProfileWarnings(
      shortlistItem.profile_id,
      {
        min: formatISODate(),
        max: formatISODate(UNDETERMINED_FUTURE)
      },
      true
    );
    const overbookings = warningsResponse?.overbookings || [];
    const params = new URLSearchParams({
      workforceMemberId: String(shortlistItem.profile_id),
      overbookings: String(!!overbookings.length)
    });
    history.push({ pathname: "/booking/overview", search: params.toString() });
  };

  const handleDecline = () => {
    rejectShortlist(shortlistItem.id, {
      onSuccess: () => {
        task && invalidateVacancies(task.id);
        invalidateActivity([task.id]);
        growl({ message: t("activities:show.parts.assignmentSubmitted"), kind: "success" });
      },
      onError: (response) => growl({ message: errorMessage(response), kind: "error" })
    });
  };

  return (
    <div className={css.container}>
      <Button
        icon="check"
        text={isAccepted ? t("translation:accepted") : t("translation:accept")}
        className={classNames(css.button, { [css.buttonAccepted]: isAccepted })}
        kind="secondary"
        qaId="role-accept"
        onClick={handleAccept}
      />
      <Button
        icon="cross"
        text={isDeclined ? t("translation:declined") : t("translation:decline")}
        className={classNames(css.button, { [css.buttonDeclined]: isDeclined })}
        kind="secondary"
        qaId="role-decline"
        onClick={() => setShowDeclineConfirm(true)}
      />
      {showDeclineConfirm && (
        <ConfirmModal
          title={t("activities:show.parts.declineRole")}
          labelOK={t("translation:confirm")}
          handleOK={handleDecline}
          handleClose={() => setShowDeclineConfirm(false)}
          kindOK="danger"
        >
          {t("activities:show.parts.assignmentConfirmDecline")}
        </ConfirmModal>
      )}
    </div>
  );
};
