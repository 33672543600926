import { flatten } from "lodash";
import PropTypes from "prop-types";
import { useMemo } from "react";

import css from "./contact_information.less";
import getContactInfoArray from "./get_contact_info_array";

const ContactInformation = ({ profile, currentAccount, isContactPermitted }) => {
  const contacts = useMemo(() => getContactInfoArray(profile, currentAccount), [profile, currentAccount]);

  if (contacts.length === 0) {
    return null;
  }

  const contactsList = (contatList) => (
    <ul className={css.root} data-qa-id="ContactInformation">
      {contatList.map((contact) => {
        const { Icon, url, target, value, labelTitle, onClick } = contact;

        const content = (
          <span className={css.item}>
            <span className={css.icon}>
              <Icon width={14} height={14} />
            </span>
            <span className={css.value}>
              {flatten(
                `${value}`.split(/@/g).map((part, i) => (i === 0 ? part : [<wbr key={i} />, "@", part]))
              )}
            </span>
          </span>
        );

        return (
          <li key={contact.value + contact.type} data-qa-id={`ContactInformation.${contact.type}`}>
            {url || onClick ? (
              <a href={url} target={target} onClick={onClick} rel="noopener noreferrer" className={css.link}>
                {content}
              </a>
            ) : (
              <span title={labelTitle}>{content}</span>
            )}
          </li>
        );
      })}
    </ul>
  );

  return contactsList(isContactPermitted ? contacts : contacts.filter((item) => item.type === "email"));
};

ContactInformation.propTypes = {
  profile: PropTypes.shape({ id: PropTypes.number }),
  isContactPermitted: PropTypes.bool,
  currentAccount: PropTypes.object
};

export default ContactInformation;
