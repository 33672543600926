import { CustomValuesEditFieldProps } from "PFApp/components/custom_values_edit_field";
import { useSkillsProficiencyOptions } from "PFApp/hooks/skills/use_skills_proficiency_options";
import { DoubleSectionSelectPill } from "PFComponents/double_section_pill/double_section_select_pill";
import { Experience, PureCustomValue } from "PFTypes";

import css from "../skills_select.module.scss";

type SelectedSkillsProps = Pick<CustomValuesEditFieldProps, "handleChange" | "values">;

export const SelectedSkills = ({ values, handleChange }: SelectedSkillsProps) => {
  const proficiencyOptions = useSkillsProficiencyOptions();

  return (
    <div className={css.selectedSkills}>
      {values.map((value) => (
        <DoubleSectionSelectPill
          key={value.id}
          leftContent={String(value.text || value.value)}
          attribute={
            proficiencyOptions.find(({ item }) => item === (value.experience || Experience.Basic))
              ?.displayElement || ""
          }
          attributeOptions={proficiencyOptions}
          onAttributeChange={(newExperience: Experience) => {
            handleChange(
              values.map((skill) => ({
                ...skill,
                experience: skill.id === value.id ? newExperience : skill.experience
              }))
            );
          }}
          onDelete={() => {
            handleChange((values as PureCustomValue[]).filter((skill) => skill.id !== value.id));
          }}
        />
      ))}
    </div>
  );
};
