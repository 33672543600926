import classNames from "classnames";
import moment from "moment";
import { isBookingWithTime } from "PFApp/booking/parts/helpers";
import { usePastDaysLimit } from "PFApp/hooks/use_past_days_limit";
import { PhasesSelect } from "PFComponents/availability/rules_carousel/phases_select";
import InlineCalendar from "PFComponents/calendar/inline_calendar";
import { TimePicker } from "PFComponents/calendar/time_picker";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import ClockIcon from "PFIcons/clock.svg";
import { BookingTemplate, FeatureFlag, RequirementType } from "PFTypes";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { RequirementSelector } from "../../requirement_selector";
import { useRequirement } from "../../use_requirement";
import { useSpecificTime } from "../../use_specific_time";
import { useStartEnd } from "../../use_start_end";
import WeekDaysSelect from "../../week_days_select";
import css from "./range_item.module.scss";

type RangeItemProps = {
  activityId?: number | null;
  booking: any;
  bookingTemplate: BookingTemplate;
  isVisible: boolean;
  itemIndex: number;
  monthsForwardLimit: number;
  portalRef: React.RefObject<HTMLDivElement>;
  specificTime: boolean;
  setBookingData: (unknown) => void;
  errors?: Record<string, string>;
};

export const RangeItem = ({
  activityId,
  booking,
  bookingTemplate,
  isVisible,
  itemIndex,
  monthsForwardLimit,
  portalRef,
  specificTime,
  setBookingData,
  errors = {}
}: RangeItemProps) => {
  const pastDaysLimit = usePastDaysLimit();
  const { formatISODate, formatISODateTime, utc } = useDateFormatter();
  const { t } = useTranslation(["bookingModule", "translation"]);

  const enablePhases = useIsFeatureEnabled()(FeatureFlag.ActivityPhases) && activityId;

  const [withTime, setWithTime] = useState(
    specificTime ||
      (!!booking.startDate &&
        !!booking.endDate &&
        isBookingWithTime(utc(booking.startDate), utc(booking.endDate)))
  );

  const [phaseId, setPhaseId] = useState(booking?.phase_source_id);

  const {
    startDateSpecificTime,
    setStartDateSpecificTime,
    endDateSpecificTime,
    setEndDateSpecificTime,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    wdayMask,
    setWdayMask,
    disabledDays
  } = useSpecificTime({ booking, bookingTemplate });

  const { start, setStart, end, setEnd } = useStartEnd({
    booking,
    specificTime,
    startDateSpecificTime,
    setStartDateSpecificTime,
    endDateSpecificTime,
    setEndDateSpecificTime
  });

  const [state, setState] = useState(booking?.state);
  const {
    requirement,
    defaultRequirementValues,
    handleRequirementTypeChange,
    handleRequirementValueChange,
    error: requirementError
  } = useRequirement({
    booking,
    onRequirementChange: (requirement) => {
      setState("editing");
      if (requirement.type !== RequirementType.Load) {
        setWithTime(false);
      }
    }
  });

  const editTimeWithDate = withTime && !specificTime;
  const maxDate = formatISODate(moment().add(monthsForwardLimit, "months").endOf("month"));
  const duration = booking?.duration;

  useEffect(() => {
    const itemData = {
      start: withTime ? formatISODateTime(start) : formatISODate(start),
      end: withTime ? formatISODateTime(end) : formatISODate(end),
      requirement,
      duration,
      state,
      phaseSourceId: phaseId,
      ...(specificTime
        ? {
            startTime,
            endTime,
            wdayMask
          }
        : {})
    };
    setBookingData((prev) => ({
      ...prev,
      [itemIndex]: itemData
    }));
  }, [
    specificTime,
    start,
    end,
    startTime,
    endTime,
    requirement.type,
    requirement.value,
    withTime,
    state,
    duration,
    wdayMask,
    phaseId
  ]);

  const toggleWithTime = () => {
    setWithTime((prev) => !prev);
  };

  const hoursDiffBetweenStartEndWithoutTime = useMemo(() => {
    const daysCount = utc(formatISODate(utc(end))).diff(utc(formatISODate(utc(start))), "days") + 1;
    return daysCount * 24;
  }, [start, end]);

  return (
    <div className={css.wrapper}>
      <div className={classNames(css.column, css.calendar)}>
        {enablePhases && (
          <PhasesSelect
            activityId={activityId}
            selectedPhaseSourceId={phaseId}
            onChange={({ sourceId }) => setPhaseId(sourceId)}
            errors={errors}
            portalRef={portalRef}
          />
        )}
        <div className={css.datesContainer}>
          <InlineCalendar
            label={t(`${editTimeWithDate ? "translation:startDateAndTime" : "translation:startDate"}`)}
            showTime={editTimeWithDate}
            selectedDate={start}
            shouldCloseOnSelection
            minDate={formatISODateTime(
              utc()
                .startOf("day")
                .subtract(pastDaysLimit ?? 0, "days")
            )}
            handleChange={(date) => {
              setStart(utc(date));
              setState("editing");
              if (utc(end)?.isBefore(utc(date))) {
                setEnd(utc(date).endOf("day"));
              }
            }}
            clearDate={formatISODateTime(utc().startOf("day"))}
            calendarStyle={{ width: 350 }}
            portalRef={portalRef}
          />
          <InlineCalendar
            label={t(`${editTimeWithDate ? "translation:endDateAndTime" : "translation:endDate"}`)}
            showTime={editTimeWithDate}
            minDate={formatISODateTime(start)}
            maxDate={maxDate}
            selectedDate={end}
            shouldCloseOnSelection
            handleChange={(date) => {
              setEnd(editTimeWithDate ? utc(date) : utc(date).endOf("day"));
              setState("editing");
              if (utc(start)?.isAfter(utc(date))) {
                setStart(utc(date).startOf("day"));
              }
            }}
            clearDate={formatISODateTime(utc().endOf("day"))}
            calendarStyle={{ width: 350 }}
            portalRef={portalRef}
          />
        </div>

        {specificTime && (
          <div className={classNames(css.timePicker)}>
            <ClockIcon height={25} width={25} />
            <TimePicker value={startTime} max={endTime} onChange={setStartTime} portalRef={portalRef} />
            {t("translation:to")}
            <TimePicker value={endTime} min={startTime} onChange={setEndTime} portalRef={portalRef} />
          </div>
        )}
      </div>
      {!specificTime && (
        <div className={classNames(css.line, css.calendar)}>
          <RequirementSelector
            name={`requirement_selector_${itemIndex}`}
            requirement={requirement}
            onTypeChange={handleRequirementTypeChange}
            onValueChange={handleRequirementValueChange}
            withTime={withTime}
            onWithTimeChange={toggleWithTime}
            defaultValues={defaultRequirementValues}
            startEndHoursDiff={hoursDiffBetweenStartEndWithoutTime}
            isVisible={isVisible}
            error={requirementError}
          />
        </div>
      )}
      {specificTime && (
        <div className={css.repeat}>
          <span className={css.label}>{t("bookingModule:bookings.create.repeats")}:</span>
          <WeekDaysSelect disabledDays={disabledDays} value={wdayMask} onChange={setWdayMask} />
        </div>
      )}
    </div>
  );
};
