import { isMatch } from "PFApp/search/helpers/normalize_keywords";
// splits normalized keywords array into
//   - taskCustomFields - that are safe to pass as custom fields
//   - cfFilters - that are supposed to be saved along { meta: filters } instead
const prepareKeywords = (choosenKeywords, deletedKeywords) => {
  const taskCustomFields = [];

  choosenKeywords.filter(isMatch).forEach(({ type, id, weight }) => {
    let customField = taskCustomFields.find((cf) => cf.type.name === type.name);
    if (!customField) {
      customField = { type, values: [] };
      taskCustomFields.push(customField);
    }
    customField.values.push({ id, weight });
  });

  deletedKeywords.forEach(({ type }) => {
    let customField = taskCustomFields.find((customField) => customField.type.name === type.name);
    if (!customField) {
      customField = { type, values: [] };
      taskCustomFields.push(customField);
    }
  });

  return {
    taskCustomFields
  };
};

export default prepareKeywords;
