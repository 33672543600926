import { camelCase, capitalize } from "lodash";
import ChangeAssigneeButton from "PFApp/activities/show/parts/change_assignee_button";
import { ActivityProfilesAvatarStack } from "PFApp/components/activity_profiles_avatar_stack";
import { useTemplate } from "PFApp/hooks";
import { Typography } from "PFComponents/typography";
import { Profile } from "PFTypes";
import { ReactNode, useContext } from "react";
import { useTranslation } from "react-i18next";

import ActivityPageContext from "../activity_page_context";
import css from "./activity_profiles_section.module.scss";
import AddCoOwner from "./add_co_owner";
import { useActivityChat } from "./hooks/use_activity_chat";
import { useAssignee } from "./hooks/use_assignee";
import { useOwners } from "./hooks/use_owners";
import { useReviewers } from "./hooks/use_reviewers";
import { AddReviewersButton } from "./review_section/add_reviewers_button";

type LabelProps = {
  title: string;
  className?: string;
  profiles: Profile[];
};

const Label = ({ title, profiles, className }: LabelProps): JSX.Element => {
  const { t } = useTranslation("activities");

  return (
    <Typography className={className} variant="bodyRegular" noMargin>
      {title &&
        `${t(`show.parts.${camelCase(title)}` as unknown as TemplateStringsArray, {
          count: profiles.length
        })}:`}
    </Typography>
  );
};

export const ActivityProfilesSection = () => {
  const { task } = useContext(ActivityPageContext);
  const { startChat } = useActivityChat();

  const { owners, principalOwner, canView: canViewOwners, canWrite: canWriteOwners } = useOwners(task);
  const { assignee, canView: canViewAssignee, canWrite: canWriteAssignee } = useAssignee(task);
  const { reviewers, canView: canViewReviewers, canWrite: canWriteReviewers } = useReviewers(task);

  const template = useTemplate(task?.template_id);
  const isEngagement = template?.key === "engagement";
  const isDraft = task?.state === "draft";

  const rowsData: {
    title: string;
    profiles: Profile[];
    show: boolean;
    button?: ReactNode;
    write: boolean;
  }[] = [
    {
      title: "principalOwner",
      profiles: [principalOwner!],
      show: !!principalOwner,
      write: false
    },
    {
      title: "owner",
      profiles: owners,
      show: canViewOwners,
      write: canWriteOwners,
      button: (
        <AddCoOwner
          buttonKind={isEngagement ? "secondary" : "primary"}
          buttonStyle={{ minWidth: 140 }}
          activity={task}
        />
      )
    },
    {
      title: "assignee",
      profiles: [assignee!],
      show: canViewAssignee,
      write: canWriteAssignee,
      button: (
        <ChangeAssigneeButton
          buttonKind={isEngagement ? "secondary" : "primary"}
          buttonStyle={{ minWidth: 140 }}
        />
      )
    },
    {
      title: "reviewer",
      profiles: reviewers,
      show: canViewReviewers,
      write: canWriteReviewers,
      button: <AddReviewersButton />
    }
  ].filter(({ write, show }) => write || show);

  return (
    <div className={css.root}>
      {rowsData.map(({ show, write, title, profiles, button }) => (
        <>
          <Label className={css.labelCell} title={show ? title : ""} profiles={profiles} />
          <ActivityProfilesAvatarStack
            profiles={show ? profiles : []}
            displayChatIcon={!isDraft}
            handleIconClick={startChat}
            qaId={`${capitalize(title[0])}${title.slice(1)}`}
          />
          <div className={css.buttonCell}>{write && button}</div>
        </>
      ))}
    </div>
  );
};
