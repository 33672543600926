import moment from "moment";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import PropTypes from "prop-types";

import css from "./header.less";

const HeaderTimeAgo = ({ time }) => {
  const { formatDate } = useDateFormatter();

  const createdAt = moment(time);
  const useTimeAgo = createdAt.diff(Date.now(), "days") > -28;
  const copy = useTimeAgo ? createdAt.fromNow(false) : formatDate(createdAt);

  return <div className={css.time}>{copy}</div>;
};

export default HeaderTimeAgo;

HeaderTimeAgo.propTypes = {
  time: PropTypes.string
};
