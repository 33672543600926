import { trim } from "lodash";
import { MatchingRelationType } from "PFApp/constants/matching_relation_type";
import api from "PFCore/api";
import { getProfileName } from "PFCore/helpers/profile";
import { ApiRoute } from "PFCore/utilities/routes";
import {
  Collection,
  MatchedActivity,
  MatchedProfile,
  PaginatedCollection,
  Profile,
  ProfileBasicInformation
} from "PFTypes";
import { Id } from "PFTypes/id";

import { decamelizeDesiredKeys } from "../helpers/decamelize_desired_keys";
import { QueryParams } from "../types/query_params";

export const fetchProfile = (profileId, data?) =>
  //@ts-ignore
  $.ajax({
    url: ApiRoute(`/api/profiles/${profileId}`),
    method: "GET",
    api_version: 2,
    data
  });

export type ProfilesOptionsParams = {
  term?: string;
  policyName?: string;
  value?: Id[];
  perPage?: number;
};

export const fetchProfilesOptions = ({ term, policyName, value, perPage }: ProfilesOptionsParams) => {
  const trimmedTerm = trim(term);

  return api<Collection<ProfileBasicInformation[]>>({
    url: "options/profiles",
    params: {
      ...(trimmedTerm ? { term: trimmedTerm } : {}),
      // eslint-disable-next-line camelcase
      ...(policyName ? { policyName } : {}),
      ...(value ? { value } : {}),
      perPage
    }
  }).then((response) => ({
    ...response,
    entries: response.entries.map((option) => ({
      ...option,
      text: getProfileName(option)
    }))
  }));
};

export const fetchProfileAsAdmin = (profileId) =>
  //@ts-ignore
  $.ajax({
    url: ApiRoute(`/api/admin/profiles/${profileId}`),
    method: "GET",
    api_version: 2
  });

export const suspendProfile = (profileId) =>
  $.ajax({ type: "PUT", url: ApiRoute(`/api/profiles/${profileId}/suspend`) });

/**
 * @deprecated
 * @param {FetchProfileQueryParams} data
 */
export const fetchProfiles = (data): Promise<Collection<Profile[]>> =>
  //@ts-ignore
  $.ajax({
    url: ApiRoute("/api/profiles/index"),
    method: "POST",
    api_version: 2,
    contentType: "application/json; charset=utf-8",
    data: JSON.stringify(data)
  }).then((resp) => ({
    ...resp,
    meta: {
      ...resp.meta,
      page: data.page || 1,
      perPage: data.per_page || 50,
      totalPages: Math.ceil(resp.meta.total / (data.per_page || 50))
    }
  }));

export type FetchProfileQueryParams = QueryParams &
  Partial<{
    full: boolean;
    nested: boolean;
    order_target?: "capacity_index";
    id: Id[];
  }>;

export type FetchProfilesResponse = PaginatedCollection<MatchedProfile[]>;

export const fetchProfilesV2 = (params: FetchProfileQueryParams) =>
  api<FetchProfilesResponse>({
    url: `profiles/index`,
    method: "POST",
    data: params
  }).then((res) => decamelizeDesiredKeys<FetchProfilesResponse>(res, ["meta.filters"])); // TODO: [PROF-5746] Remove decamelizeKeys when app is camelized

export type FetchProfileMatchingRolesParams = {
  profileId: Id;
  relationType?: MatchingRelationType;
} & QueryParams;

export type FetchProfileMatchingRolesResponse = PaginatedCollection<MatchedActivity[]>;

export const fetchProfileMatchingRoles = ({ relationType, ...params }: FetchProfileMatchingRolesParams) =>
  api<FetchProfileMatchingRolesResponse>({
    url: "matching_activities",
    method: "post",
    data: {
      ...params,
      perPage: 10,
      relationType: relationType || MatchingRelationType.PrefilteredAndAvailable
    }
  });
