import { useQuery } from "@tanstack/react-query";
import { fetchProjectsEngagements, fetchProjectsRoles } from "PFApp/booking/services/api";
import { CalendarView } from "PFApp/booking/types";
import { AvailabilityRule, PaginatedCollection, QueryOptions } from "PFTypes";

import { projectsKeys } from "./query_keys";

type ProjectItemActivity = {
  availability?: AvailabilityRule;
  id: number;
  name: string;
  post_until: string;
  children_workflow_states_tally?: Record<string, number>;
  template_id: number;
  vacancies: Record<string, number>;
  state: "draft" | "new" | "complete";
  workflow_state: string;
  parent_activity: { name: string; id: number; template_id: number };
  duration?: {
    end_date: string;
    start_date: string;
  };
  has_bookings: boolean;
  bookable?: boolean;
};

export type ProjectItem = {
  activity: ProjectItemActivity;
  id: number;
  optional_fields: Record<string, any>;
};

export type ProjectsResponse = PaginatedCollection<ProjectItem[]>;

export const useProjectsEntries = (
  page: number = 1,
  view: CalendarView,
  perPage: number = 15,
  order: any,
  filters: any,
  options: QueryOptions<ProjectsResponse> = {}
) =>
  useQuery({
    queryKey: projectsKeys.withParameters(view, filters, order, page, perPage),
    queryFn: () => {
      const params = { page, perPage, order, filters };
      return (view === CalendarView.RolesAndAuditRoles
        ? fetchProjectsRoles(params)
        : fetchProjectsEngagements(params)) as unknown as Promise<ProjectsResponse>;
    },
    ...options
  });
