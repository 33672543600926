import classNames from "classnames";
import { extend, isEqual, omit, uniqueId, without } from "lodash";
import AvatarUploader from "PFApp/profiles/common/avatar_uploader";
import ContactInformationEditField from "PFApp/profiles/edit/fields/contact_information_field";
import { useGrowl } from "PFApp/use_growl";
import { Button } from "PFComponents/button";
import { Card } from "PFComponents/card";
import CardHeader from "PFComponents/card/card_header";
import { Icon } from "PFComponents/icon";
import NotFoundComponent from "PFComponents/not_found/not_found";
import Radios from "PFComponents/radios/radios";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import { isProfileFieldPermitted } from "PFCore/helpers/profile_permissions";
import { useCurrentAccount } from "PFCore/hooks/queries/account/use_current_account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import BubbleChartIcon from "PFIcons/bubble.svg";
import StockMarketIcon from "PFIcons/tree_map.svg";
import { AccessLevel } from "PFTypes";
import PropTypes from "prop-types";
import React, { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./section_edit.module.scss";

const SectionEdit = ({ profile, handleClose, handleProfileUpdate, errors }) => {
  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();

  const { t } = useTranslation("profiles");

  const growl = useGrowl();

  const contactInfoRef = useRef();
  const [loading, setLoading] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [isEmpty, setIsEmpty] = useState(profile?.contact_information?.length === 0);
  const [firstName, setFirstName] = useState(profile.first_name);
  const [lastName, setLastName] = useState(profile.last_name);
  const [bio, setBio] = useState(profile?.summary || "");
  const [chartType, setChartType] = useState(profile.preferences.display?.skills_insights || "bubble");
  const [contacts, setContacts] = useState(
    () =>
      profile.contact_information?.map((item) => ({
        ...item,
        input_id: parseInt(uniqueId(), 10)
      })) || []
  );

  const canEditPhoto = currentAccount.profile_view_customization.edit_photo;

  const isContactRWPermitted = isProfileFieldPermitted(
    currentProfile?.permissions_group,
    profile?.permissions_group?.id,
    "contact_info",
    AccessLevel.ReadWrite
  );

  const isFieldPermitted = (field, level = AccessLevel.ReadWrite) =>
    isProfileFieldPermitted(currentProfile?.permissions_group, profile.permissions_group.id, field, level);

  const isContactsChanged = useMemo(
    () =>
      !isEqual(
        contacts.map((contact) => omit(contact, "input_id")),
        profile.contact_information
      ),
    [contacts]
  );

  const handleUpdate = () => {
    setLoading(true);

    handleProfileUpdate(
      extend(
        { preferences: { display: { skills_insights: chartType } } },
        isFieldPermitted("summary") && { summary: bio },
        isFieldPermitted("first_and_last_name") && { first_name: firstName, last_name: lastName },
        isFieldPermitted("contact_info") && { contact_information: contacts }
      )
    )
      .then(() => {
        setLoading(false);
        setHasChanged(false);
        growl({
          message: t("show.parts.yourInformationUpdated"),
          kind: "success"
        });
      })
      .catch(() => {
        setLoading(false);
        setHasChanged(true);
      });
  };

  const handleAddNew = () => {
    setIsEmpty(false);
    setContacts((prev) => [
      ...prev,
      {
        input_id: parseInt(uniqueId(), 10),
        type: "phone",
        value: ""
      }
    ]);
  };

  const handleChange = (contact) => {
    const index = contacts.findIndex((ct) => ct.input_id === contact.input_id);
    const newContacts = [...contacts];
    newContacts[index] = { ...contact };
    setContacts(newContacts);
    setHasChanged(true);
  };

  const handleRemove = (contact) => {
    setContacts((prev) => without(prev, contact));
    setHasChanged(true);
  };

  return (
    <Card className={css.root} paddingVariant="xl">
      <CardHeader
        titleQaId="profile-picture"
        title={t("show.parts.myInformation")}
        style={{
          margin: -24,
          marginBottom: 0,
          padding: 24,
          boxShadow: "0px 4px 6px rgba(180, 125, 244, 0.18)"
        }}
        action={
          <span className={css.buttons}>
            <Button kind="tertiary" onClick={handleUpdate} disabled={!hasChanged || loading}>
              <Icon name="check" />
              {t("translation:save")}
            </Button>
            <Button
              kind="danger"
              onClick={() => (hasChanged ? confirm(t("show.parts.changesNotSaved")) : true) && handleClose()}
            >
              {t("translation:close")}
            </Button>
          </span>
        }
      />
      <div className={classNames(css.row, css.twoColumnGrid)}>
        <div>
          <div className={css.label}>{t("show.parts.profilePhoto")}</div>
          <AvatarUploader profile={profile} isEditable={canEditPhoto} />
        </div>
        <div>
          <div className={css.label}>{t("show.parts.mySkillsInsights")}</div>
          <div className={css.chartRadios}>
            <Radios
              itemClassName={css.radioItem}
              items={[
                {
                  id: "bubble",
                  displayElement: <BubbleChartIcon className={css.stockIcon} height={28} width={35} />
                },
                {
                  id: "drilldown",
                  displayElement: <StockMarketIcon className={css.stockIcon} height={28} width={28} />
                }
              ]}
              value={chartType}
              handleChange={(item) => {
                setChartType(item.id);
                setHasChanged(true);
              }}
            />
          </div>
        </div>
      </div>

      {isFieldPermitted("first_and_last_name", AccessLevel.ReadOnly) && (
        <div className={classNames(css.name, css.row)}>
          <InputFieldSet
            kind="bordered"
            label={t("show.parts.firstName")}
            value={firstName}
            onChange={(name) => {
              setFirstName(name);
              setHasChanged(true);
            }}
            locked={!isFieldPermitted("first_and_last_name")}
            lockedTip={t("show.parts.noPermissionsTip")}
            error={errors?.first_name}
          />
          <InputFieldSet
            label={t("show.parts.lastName")}
            kind="bordered"
            value={lastName}
            onChange={(name) => {
              setLastName(name);
              setHasChanged(true);
            }}
            locked={!isFieldPermitted("first_and_last_name")}
            lockedTip={t("show.parts.noPermissionsTip")}
            error={errors?.last_name}
          />
        </div>
      )}
      {isFieldPermitted("summary", AccessLevel.ReadOnly) && (
        <div className={css.row}>
          <InputFieldSet
            kind="bordered"
            value={bio}
            label={t("show.parts.bio")}
            inputType="textarea"
            errors={errors ? errors?.["summary"] : {}}
            locked={!isFieldPermitted("summary")}
            lockedTip={t("show.parts.noPermissionsTip")}
            onChange={(val) => {
              setBio(val);
              setHasChanged(true);
            }}
            error={errors?.summary}
          />
        </div>
      )}
      {isFieldPermitted("contact_info", AccessLevel.ReadOnly) && (
        <div className={css.row}>
          <h3>{t("show.parts.contactInformation")}</h3>

          <div ref={contactInfoRef}>
            {isEmpty && (
              <NotFoundComponent
                message={t("show.parts.noContactInformationAdded")}
                buttonMessage={t("show.parts.addNew")}
                buttonDisabled={loading || !isContactRWPermitted}
                handleClick={handleAddNew}
              />
            )}
            {!isEmpty && (
              <div>
                <div
                  style={{ marginTop: 30 }}
                  className={css.wrap}
                  data-qa-id="profile-edit-contact-info-links-section"
                >
                  {contacts?.map((contact, index) => (
                    <ContactInformationEditField
                      key={contact.input_id}
                      contact={contact}
                      error={errors && errors[`contact_information[${index}][value]`]}
                      locked={!isContactRWPermitted}
                      lockedTip={t("show.parts.noPermissionsTip")}
                      handleChange={handleChange}
                      handleRemove={handleRemove}
                    />
                  ))}
                  {isContactsChanged && (
                    <div style={{ display: "block", opacity: 0.7, textAlign: "center", margin: 40 }}>
                      {t("show.parts.someContactsEdited")}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <Button
            kind="secondary"
            rootClassName={css.button}
            disabled={loading || !isContactRWPermitted}
            onClick={handleAddNew}
          >
            <Icon name="add" size="sm" />
            {t("show.parts.contact")}
          </Button>
        </div>
      )}
    </Card>
  );
};

SectionEdit.propTypes = {
  profile: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
  handleProfileUpdate: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.object)
};

export default SectionEdit;
