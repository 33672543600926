import AppContext from "PFApp/app_context";
import ProfileAvatar from "PFComponents/profile_avatar/profile_avatar";
import { PROFILE_PREVIEW_ACTIONS } from "PFReducers/profile_preview_reducer";
import { ProfileMinimized } from "PFTypes";
import { useContext, useMemo } from "react";

import { useProfileChatIcon } from "./use_profile_chat_icon";

type ProfileAvatarConnectedProps = {
  smartLink: boolean;
  onClick?: () => void;
  profile: ProfileMinimized;
  href?: string;
  withChat?: boolean;
  handleIconClick?: () => void;
  Icon?: React.FC<{ width: number; height: number }>;
  size?: number;
};

export const ProfileAvatarConnected = (props: ProfileAvatarConnectedProps) => {
  const { dispatch } = useContext(AppContext);

  const { smartLink, onClick, profile, href, withChat } = props;

  const { Icon, handleClick: handleChatClick } = useProfileChatIcon(profile);

  const handleOpenInRightBar = (event) => {
    event.preventDefault();

    dispatch({
      type: PROFILE_PREVIEW_ACTIONS.PROFILE_PREVIEW_SHOW,
      payload: { id: profile.id, profile }
    });
  };

  const handleIconClick = useMemo(
    () => props.handleIconClick || (withChat ? handleChatClick : null),
    [handleChatClick, withChat, props.handleIconClick]
  );

  const IconComponent = useMemo(() => props.Icon || (withChat ? Icon : null), [Icon, withChat, props.Icon]);

  const usedOnClick = smartLink ? (profile.viewable ? handleOpenInRightBar : null) : onClick;
  const usedHref = smartLink ? (profile.viewable ? `/profiles/${profile.id}` : null) : href;

  return (
    <ProfileAvatar
      {...props}
      onClick={usedOnClick}
      href={usedHref}
      handleIconClick={handleIconClick}
      Icon={IconComponent}
    />
  );
};
