import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { Activity } from "PFTypes";

type UseNotesPermissions = {
  activity: Activity;
};

type UseNotesPermissionsReturn = {};

export const useNotesPermissions = ({ activity }: UseNotesPermissions): UseNotesPermissionsReturn => {
  // TODO: [PROF-1152] Add notes - replace LS flag with permission
  const notesEnabled = localStorage.getItem("notes_enabled") === "true";

  const { data: currentProfile } = useCurrentProfile();
  const hasPermission = useIsCurrentUserPermittedTo();

  const isPrivate = activity.private;

  const currentProfileIsOwner = activity.owners.find((owner) => owner.profile_id === currentProfile.id);
  const currentProfileIsReviewer = activity.reviewers.find(
    (reviewer) => reviewer.profile_id === currentProfile.id
  );
  const currentProfileIsAssignee = activity.assignee?.profile.id === currentProfile.id;

  const hasShortlistWithoutOwnershipPermission = hasPermission("shortlist_without_ownership");

  const shouldDisplayPublicRole =
    !isPrivate &&
    (currentProfileIsOwner ||
      currentProfileIsReviewer ||
      currentProfileIsAssignee ||
      hasShortlistWithoutOwnershipPermission);

  const shouldDisplayPrivateRole = isPrivate && currentProfileIsOwner;

  const shouldDisplayNotes = notesEnabled && (shouldDisplayPublicRole || shouldDisplayPrivateRole);
  return {
    shouldDisplayNotes
  };
};
