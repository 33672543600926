import classNames from "classnames";
import { isNil } from "lodash";
import { Typography } from "PFComponents/typography";

import css from "./match_card_scores.module.scss";

type MatchScoreProps = {
  title: string;
  value?: string | number | null;
  unit?: string;
  isBold?: boolean;
  isLarge?: boolean;
};

export const MatchScore = ({ title, value, unit, isBold, isLarge }: MatchScoreProps) => {
  if (isNil(value)) {
    return null;
  }

  return (
    <div className={css.matchScore}>
      <Typography variant="labelRegular" className={css.title} title={title} clipOverflow noMargin>
        {title}
      </Typography>
      <div className={css.value}>
        <Typography
          variant={isLarge ? "h1" : "h4"}
          className={classNames({
            [css.bold]: isBold,
            [css.thin]: !isBold
          })}
          noMargin
        >
          {value}
        </Typography>
        {unit && (
          <Typography
            variant="bodyRegular"
            tag="span"
            className={classNames(css.unit, css.bold, {
              [css.large]: isLarge
            })}
          >
            {unit}
          </Typography>
        )}
      </div>
    </div>
  );
};
