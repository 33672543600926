import { compact, isEqual } from "lodash";
import { ActionIcon } from "PFComponents/action_icon";
import { Typography } from "PFComponents/typography";
import { Filter, FilterPreviewItem, Value } from "PFCore/types/filters";
import { PageTarget } from "PFTypes/saved_filters";
import { forwardRef, useCallback, useMemo } from "react";

import { useSavedViewsContext } from "../saved_views_context/saved_views_context";
import css from "./filters_preview.module.scss";
import { DisplayValues } from "./use_filters_display_values";

const INFO_DELIMITER = ": ";
const getTitle = (infoText: string | null, text) =>
  [...(infoText ? [infoText] : []), text].join(INFO_DELIMITER);

export type FilterChangeFn = (
  filter: Filter,
  values: Value | null,
  options?: { clear?: boolean; children?: boolean }
) => void;

type FiltersItemProps = {
  filter: FilterPreviewItem;
  displayValues: NonNullable<DisplayValues[string]>;
  onFilterChange: FilterChangeFn;
  viewsKey?: PageTarget | null;
  disabled: boolean;
  filterSectionLabels?: { root: string; children: string };
};

export const FiltersItem = forwardRef<HTMLDivElement, FiltersItemProps>(
  ({ filter, displayValues, onFilterChange, viewsKey, disabled, filterSectionLabels }, ref): JSX.Element => {
    const { setSelectedViewId } = useSavedViewsContext(viewsKey);
    const handleClick = useCallback(
      (id) => {
        setSelectedViewId(null);
        const newValues = Array.isArray(filter.originalValue)
          ? filter.originalValue.filter((item) =>
              // eslint-disable-next-line eqeqeq
              typeof item === "object" ? !isEqual(item, id) : item != id
            )
          : [];
        onFilterChange(filter, newValues?.length > 0 ? newValues : null, { children: filter.children });
      },
      [filter, onFilterChange]
    );

    const infoText = useMemo(() => {
      const filterTitle = filter.type !== "checkboxes" ? filter.title : null;
      if (!filterSectionLabels) {
        return filterTitle;
      }
      const sectionLabel = filter.children ? filterSectionLabels.children : filterSectionLabels.root;
      return compact([sectionLabel, filterTitle]).join(INFO_DELIMITER);
    }, [filterSectionLabels, filter]);

    return (
      <>
        {[displayValues].flat().map(({ id, text }) => (
          <div key={JSON.stringify(id)} ref={ref} className={css.item}>
            <ActionIcon name="cross" size="xs" onClick={() => handleClick(id)} disabled={disabled} />
            <div className={css.itemTextContainer} title={getTitle(infoText, text)}>
              {!!infoText && (
                <Typography variant="labelRegular" noMargin clipOverflow tag="p">
                  {infoText}
                </Typography>
              )}
              <Typography variant="labelBold" noMargin clipOverflow tag="p">
                {text}
              </Typography>
            </div>
          </div>
        ))}
      </>
    );
  }
);

FiltersItem.displayName = "FiltersItem";
