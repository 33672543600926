import PageBox from "PFComponents/box/page_box";
import { Button } from "PFComponents/button";
import { useTranslation } from "react-i18next";

import Experiences from "./experiences";
import css from "./section_experience_content.module.scss";
import { useExperienceSectionContext } from "./section_experience_context_provider";
import ExperienceEditForm from "./section_experience_edit_form";

const ExperienceSectionContent = () => {
  const { isSelected, isEditMode } = useExperienceSectionContext();
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts.sectionExperience" });

  return (
    <>
      {isEditMode && (
        <PageBox className={css.addCard}>
          <Button text={t("add")} kind="secondary" className={css.addButton} />
        </PageBox>
      )}
      {isSelected ? <ExperienceEditForm /> : <Experiences />}
    </>
  );
};

export default ExperienceSectionContent;
