import classNames from "classnames";
import { compact, isNil, maxBy, some } from "lodash";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useEngagementFeedbackParticipants } from "PFApp/activities/show/hooks/use_engagement_feedback_participants";
import { mapWorkflowStatesToUI } from "PFApp/audit_planner/parts/helpers";
import MultiPage from "PFComponents/page/multi_page";
import PageCard from "PFComponents/page/page_card";
import PageTabs from "PFComponents/page/page_tabs";
import { useInfiniteActivityWatchers } from "PFCore/hooks/queries/activity/use_activity_watchers";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Activity } from "PFTypes";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

import { ActivityHistoryProvider } from "../../parts/history/activity_history_context";
import { Economics } from "../economics";
import css from "./activity_page.module.scss";
import {
  ActivityDetails,
  ActivityFeedback,
  ActivityInterested,
  ActivityMatches,
  ActivityRoles,
  ActivityShortlist,
  ActivityVacancies,
  Header
} from "./parts";

export const ActivityPage = () => {
  const {
    task,
    roles,
    isRole,
    isAudit,
    matchesMeta,
    shortlistsMeta,
    suspendedShortlistsMeta,
    comparisonToastComponent,
    tabs,
    showTabs,
    tabActiveId,
    tabsIds,
    handleChangeTab,
    vacancies,
    allShortlists
  } = useActivityPageContext();

  const activity = task as Activity;

  const { data: interested } = useInfiniteActivityWatchers(activity, 0, undefined, undefined, {
    onSuccess: (res) => {
      if (isNil(interestsCount)) {
        setInterestsCount(res.pages[0].meta.total);
      }
    }
  });

  const [interestsCount, setInterestsCount] = useState<number | undefined>(interested?.pages[0].meta.total);

  const { formatDateTime } = useDateFormatter();
  const { t } = useTranslation("activities", { keyPrefix: "show.matches" });
  const { data: feedbackParticipants } = useEngagementFeedbackParticipants(activity);

  const pendingFeedbacks =
    feedbackParticipants?.entries?.filter((participant) =>
      some(participant.feedback, ({ status }) => status === "pending")
    ) || [];
  const feedbackCount = pendingFeedbacks?.length === 0 ? undefined : pendingFeedbacks?.length;

  const tabPages = useMemo(() => {
    const canViewTab = (tabId: string) => tabsIds.indexOf(tabId) >= 0;

    return compact([
      canViewTab("details") && <ActivityDetails key="details" />,
      canViewTab("interested") && (
        <ActivityInterested
          key="interests"
          handleInterestsCountChange={(newCount) => setInterestsCount(newCount)}
        />
      ),
      canViewTab("matches") && <ActivityMatches key="matches" />,
      canViewTab("shortlist") && <ActivityShortlist key="shortlist" />,
      canViewTab("roles") && <ActivityRoles key="roles" />,
      canViewTab("vacancies") && <ActivityVacancies key="vacancies" />,
      canViewTab("feedback") && <ActivityFeedback key="feedback" />,
      canViewTab("economics") && <Economics key="economics" />
    ]);
  }, [tabsIds]);

  if (isAudit && isRole) {
    return (
      <Redirect
        to={`/audit_planner/control_centre/${mapWorkflowStatesToUI(
          mapWorkflowStatesToUI(activity.workflow_state) === "pending"
            ? ""
            : `${activity.parent_activity_id}/${activity.id}`
        )}`}
      />
    );
  }

  const lastActionDate = maxBy(allShortlists || [], "updated_at")?.updated_at;

  const isPageCardVisible = tabActiveId !== "feedback";

  const shortListCount = (shortlistsMeta?.total || 0) + (suspendedShortlistsMeta?.total || 0);
  const fadeHeader = comparisonToastComponent !== null;

  return (
    <ActivityHistoryProvider>
      <div className={css.container}>
        <div className={css.page}>
          <Header className={classNames({ [css.fade]: fadeHeader })} />
          <div
            className={classNames(css.tabs, { [css.fade]: fadeHeader })}
            style={!showTabs ? { justifyContent: "flex-end" } : {}}
          >
            {showTabs && (
              <PageTabs
                handleClick={(tab) => handleChangeTab(tab.id)}
                items={tabs}
                active={tabActiveId}
                counters={{
                  shortlist: Math.max(shortListCount, 0),
                  matches: matchesMeta.total,
                  roles: roles.meta.total,
                  vacancies: vacancies.meta.total,
                  interested: interestsCount,
                  feedback: feedbackCount
                }}
              />
            )}
            <div className={css.actions}>
              {tabActiveId === "shortlist" && (
                <span className={css.updatedAt}>{`${t("lastEdited")}: ${formatDateTime(
                  lastActionDate
                )}`}</span>
              )}
            </div>
          </div>
          {isPageCardVisible ? (
            <PageCard classes={{ wrap: css.card }}>
              <MultiPage items={tabs} active={tabActiveId}>
                {tabPages}
              </MultiPage>
            </PageCard>
          ) : (
            <div className={css.minimalisticPageContainer}>
              <div>
                <MultiPage items={tabs} active={tabActiveId}>
                  {tabPages}
                </MultiPage>
              </div>
            </div>
          )}
          {comparisonToastComponent && <div className={css.compareAlert}>{comparisonToastComponent}</div>}
        </div>
      </div>
    </ActivityHistoryProvider>
  );
};
