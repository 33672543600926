import classNames from "classnames";
import ChatListItem from "PFApp/components/chat/chat_list_item";
import ChatListMenu from "PFApp/components/chat/chat_list_menu";
import ChatSubheader from "PFApp/components/chat/chat_subheader";
import { Button } from "PFComponents/button";
import { getTargetType } from "PFCore/helpers/conversation";
import useDebounce from "PFCore/helpers/use_debounce";
import { useChatConversations } from "PFCore/hooks/queries/chat/use_chat_conversations";
import { fetchParticipants } from "PFCore/services/chat/fetch_paricipants";
import { markNotificationsAsRead } from "PFCore/services/notifications/mark_notifications_as_read";
import ChatIcon from "PFIcons/chat.svg";
import EllipsisIcon from "PFIcons/ellipsis.svg";
import MinimizeIcon from "PFIcons/minimize.svg";
import SearchIcon from "PFIcons/search.svg";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./chat.module.scss";
import cssList from "./chat_list.module.scss";

const CHAT_LIST_PAGE_SIZE = 15;

const canFetchMore = (conversations, total) => conversations.length < total;

type ChatListProps = {
  counter: number;
  forceUpdateAll: () => void;
  isBelow: boolean;
  minimized: boolean;
  onClose: () => void;
  onMinimize: (id: string) => void;
  onOpenConversation: () => void;
  onUnMinimize: (id: string) => void;
  onClearNotifications: () => void;
  openConversationIds: number[];
  style: React.CSSProperties;
};

export const ChatList = ({
  counter,
  forceUpdateAll,
  minimized,
  onMinimize,
  onOpenConversation,
  onUnMinimize,
  onClearNotifications,
  openConversationIds,
  style
}: ChatListProps) => {
  const { t } = useTranslation("core", { keyPrefix: "chat" });
  const [participants, setParticipants] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [term, setTerm] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [page, setPage] = useState(1);

  const {
    data: conversations,
    isFetching,
    isFetched
  } = useChatConversations(
    {
      empty: false,
      me: true,
      perPage: CHAT_LIST_PAGE_SIZE * page,
      term
    },
    {
      keepPreviousData: true
    }
  );

  useEffect(() => {
    if (!conversations) {
      return;
    }

    conversations.entries.forEach((conversation) => {
      if (!participants[conversation.id]) {
        fetchParticipants({
          perPage: 5,
          conversationId: conversation.id,
          withMessage: getTargetType(conversation) !== "Profile"
        }).then((data) => {
          setParticipants((participants) => ({
            ...participants,
            [conversation.id]: data
          }));
        });
      }
    });
  }, [conversations]);

  const handleSearch = useDebounce(() => {
    setTerm(searchValue);
  }, 500);

  const handleUnMinimize = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onUnMinimize?.("list");
  };

  const handleMinimize = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    onMinimize?.("list");
    setDropdownOpen(false);
  };

  const handleScroll = (e) => {
    const el = e.target;

    if (el.scrollHeight - el.scrollTop < el.clientHeight + 50) {
      if (isFetched && canFetchMore(conversations?.entries, conversations?.meta?.total)) {
        setPage(page + 1);
      }
    }
  };

  const handleMarkAllRead = async () => {
    markNotificationsAsRead({ targetTypes: ["Chat::Conversation"] }).then(() => onClearNotifications());
  };

  const renderTitle = () => (
    <div className={classNames(cssList.icon, { [cssList.iconMinimized]: minimized })}>
      <ChatIcon width={15} height={15} />
      {counter > 0 && <div className={cssList.counter}>{counter > 9 ? "9+" : counter}</div>}
    </div>
  );

  const isEmpty = conversations?.entries?.length === 0;

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  return (
    <div
      className={classNames(css.root, css.selected, css.expanded, css.list, {
        [css.minimized]: minimized,
        [cssList.minimized]: minimized
      })}
      style={style}
      data-qa-id="chat-root"
    >
      <div
        role="button"
        className={cssList.header}
        onClick={handleUnMinimize}
        onKeyDown={handleUnMinimize}
        data-qa-id="chat-list-header"
        // eslint-disable-next-line jsx-a11y/tabindex-no-positive
        tabIndex={1}
      >
        {minimized && renderTitle()}
        {!minimized && (
          <div className={cssList.headerActions}>
            <div className={cssList.actionsTitle}>
              <ChatIcon width={15} height={15} />
              <span>{t("allChats")}</span>
            </div>
            <div className={cssList.actions}>
              <Button kind="blank" className={cssList.actionIcon} onClick={handleMinimize}>
                <MinimizeIcon width={20} height={20} />
              </Button>
              <Button kind="blank" className={cssList.actionIcon} onClick={toggleDropdown}>
                <EllipsisIcon width={20} height={20} />
              </Button>
            </div>
          </div>
        )}
        {!minimized && dropdownOpen && (
          <ChatListMenu
            forceUpdateAll={forceUpdateAll}
            markAllRead={handleMarkAllRead}
            handleChange={(fn) => {
              setDropdownOpen(false);
              fn();
            }}
          />
        )}
      </div>
      {!minimized && (
        <>
          <ChatSubheader>
            <input
              value={searchValue}
              placeholder={t("searchTitleOrPeople")}
              className={cssList.input}
              onChange={(e) => {
                const searchValue = e.target.value;
                setSearchValue(searchValue);
                handleSearch();
              }}
            />
            <div className={cssList.searchIcon}>
              <SearchIcon width={20} height={20} />
            </div>
          </ChatSubheader>
          <div className={css.bodyWrap}>
            <div className={cssList.wrap} style={{ opacity: isFetching ? 0.5 : 1 }} onScroll={handleScroll}>
              {!isFetched && isEmpty && <span className={cssList.more}>...</span>}
              {isFetched && isEmpty && (
                <div className={cssList.wrapEmpty}>
                  <div>
                    <ChatIcon width={100} height={100} />
                    <div>{t("noConversations")}</div>
                  </div>
                </div>
              )}
              {!isEmpty && (
                <>
                  <ul className={cssList.list}>
                    {conversations?.entries?.map((conversation) => (
                      <ChatListItem
                        isOpen={openConversationIds.indexOf(conversation.id) >= 0}
                        key={conversation.id}
                        handleOpenConversation={onOpenConversation}
                        conversation={conversation}
                        participants={participants[conversation.id]?.entries || []}
                      />
                    ))}
                  </ul>
                  {canFetchMore(conversations?.entries, conversations?.meta?.total) && (
                    <span className={cssList.more}>...</span>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
