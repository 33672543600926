import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { ProfileAvatarConnected } from "PFApp/components/profile_avatar_connected";
import { useTemplate } from "PFApp/hooks";
import { Button } from "PFComponents/button";
import { getProfileName } from "PFCore/helpers/profile";
import { useShortlistCreate } from "PFCore/hooks/queries/shortlists/use_shortlist_create";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { useActivityChat } from "../parts/hooks/use_activity_chat";
import css from "./activity_details_watcher.less";

const ActivityDetailsWatcher = ({ watcher, isChatEnabled }) => {
  const { startChat } = useActivityChat();
  const { task, shortlists } = useActivityPageContext();
  const template = useTemplate(task.template_id);
  const isRole = template.key === "role";
  const isCooowned = task.coowned;
  const { t } = useTranslation("activities");
  const { mutate: createShortlist, isLoading } = useShortlistCreate();

  const handleShortlist = () => {
    createShortlist({ activityId: task.id, profileId: watcher.profile.id });
  };

  const shortlist = shortlists && shortlists.find(({ profile: { id } }) => id === watcher.profile.id);
  const state = shortlist && shortlist.state;

  return (
    <div className={css.item} key={watcher.profile.id}>
      <div className={css.avatar}>
        <ProfileAvatarConnected profile={watcher.profile} size={32} smartLink />
      </div>
      <div className={css.name}>
        <Button
          text={getProfileName(watcher.profile)}
          kind="blank"
          disabled={!isChatEnabled}
          onClick={() => startChat(watcher.profile)}
        />
      </div>
      {isCooowned && isRole && (
        <div className={css.actions}>
          {!state ? (
            <Button
              text={isLoading ? "..." : t("show.details.shortlist")}
              small
              kind="secondary"
              style={{ minWidth: 62 }}
              onClick={handleShortlist}
            />
          ) : (
            <Button
              text={t(`show.details.${state}`)}
              small
              kind="secondary"
              disabled
              style={{ minWidth: 62 }}
            />
          )}
        </div>
      )}
    </div>
  );
};

ActivityDetailsWatcher.propTypes = {
  watcher: PropTypes.shape({ profile: PropTypes.object }),
  isChatEnabled: PropTypes.bool.isRequired
};

export default ActivityDetailsWatcher;
