import AppContext from "PFApp/app_context";
import ContactInformation from "PFComponents/contact_information/contact_information";
import { isProfileFieldPermitted } from "PFCore/helpers/profile_permissions";
import { useCurrentAccount } from "PFCore/hooks/queries/account/use_current_account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { AccessLevel } from "PFTypes";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { ProfilePreviewHeader } from "./profile_preview_header";

const ProfilePreviewContact = () => {
  const { t } = useTranslation("core");

  const {
    profilePreview: { profile }
  } = useContext(AppContext).store;

  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();

  const isContactPermitted = isProfileFieldPermitted(
    currentProfile.permissions_group,
    profile?.permissions_group?.id,
    "contact_info",
    AccessLevel.ReadOnly
  );

  const list = profile && (
    <ContactInformation
      profile={profile}
      currentAccount={currentAccount}
      isContactPermitted={isContactPermitted}
    />
  );

  if (!list) {
    return null;
  }

  return (
    <div data-qa-id="ProfilePreviewContact">
      <ProfilePreviewHeader>{t("profilePreview.contact")}</ProfilePreviewHeader>
      {list}
    </div>
  );
};

export default ProfilePreviewContact;
