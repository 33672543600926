import { map, merge } from "lodash";
import moment from "moment";
import Gantt from "PFApp/components/gantt/gantt";
import { useRoleJobCode } from "PFApp/use_role_job_code";
import { useCurrentAccount } from "PFCore/hooks/queries/account/use_current_account";
import { useProfilesBookings } from "PFCore/hooks/queries/profile/use_profile_bookings";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import PropTypes from "prop-types";
import { useMemo } from "react";

import { AvailabilityInfoPanel } from "./availability_info_panel";
import { ProfileLabel } from "./profile_label";

const CompareAvailabilityPage = ({
  comparableProfiles,
  startDate,
  changeStartDate,
  availabilityInfo,
  removeProfileId,
  isBlindModeOn
}) => {
  const { data: currentAccount } = useCurrentAccount();
  const jobCodeDisplayAs = useRoleJobCode();
  const { formatISODate } = useDateFormatter();

  const monthsLimit = useMemo(
    () => currentAccount.availability?.calculated_months_limit || 6,
    [currentAccount.availability]
  );

  const endDate = useMemo(
    () => formatISODate(moment(startDate).add(monthsLimit, "months").startOf("isoWeek").set("hour", 12)),
    [startDate, monthsLimit, formatISODate]
  );

  const { bookingsByProfileId } = useProfilesBookings({
    profileIds: map(comparableProfiles, ({ profile }) => profile.id),
    dateRange: { min: startDate, max: endDate }
  });

  const rowItems = useMemo(
    () =>
      map(comparableProfiles, (comparableProfile) => {
        const { profile } = comparableProfile;

        return merge({}, comparableProfile, {
          profile: {
            availability: {
              bookings: bookingsByProfileId[profile.id]?.entries || []
            }
          }
        });
      }),
    [bookingsByProfileId, comparableProfiles]
  );

  return (
    <Gantt
      monthsLimit={monthsLimit}
      rowItems={rowItems}
      startDate={startDate}
      changeStartDate={changeStartDate}
      availabilityInfo={availabilityInfo}
      removeProfileId={removeProfileId}
      infoPanel={<AvailabilityInfoPanel availability={availabilityInfo} />}
      rowItemLabelComponent={(shortlist) => (
        <ProfileLabel
          key={shortlist.id}
          shortlist={shortlist}
          removeProfileId={removeProfileId}
          isBlindModeOn={isBlindModeOn}
        />
      )}
      jobCodeDisplayAs={jobCodeDisplayAs}
    />
  );
};

CompareAvailabilityPage.propTypes = {
  comparableProfiles: PropTypes.arrayOf(PropTypes.shape({ profile: PropTypes.object })).isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  changeStartDate: PropTypes.func.isRequired,
  availabilityInfo: PropTypes.object.isRequired, // see AvailabilityInfoPanel for details
  removeProfileId: PropTypes.func.isRequired,
  isBlindModeOn: PropTypes.bool
};

export default CompareAvailabilityPage;
