import { filterElements } from "PFApp/use_filtered_collection";
import { MutableRefObject } from "react";

import { FiltersAccordion } from "../filters_accordion";
import { FiltersElement } from "./filters_element";
import { FiltersPanelProps } from "./filters_panel";

type FiltersContentProps = { lastFocusedFilterNameRef: MutableRefObject<any> } & Omit<
  React.PropsWithChildren<FiltersPanelProps>,
  | "onFiltersChange"
  | "clearFilters"
  | "closeFilters"
  | "filtersAreClear"
  | "allowToClose"
  | "style"
  | "withTitle"
  | "letRestoreAll"
  | "restoreAllTooltipContent"
  | "isRelevantToMeDisabled"
>;

export const FiltersContent = (props: FiltersContentProps) => {
  const {
    lastFocusedFilterNameRef,
    meta,
    onFilterChange,
    clearRefValue,
    blockedList = [],
    children,
    narrow,
    isLoading,
    viewsKey = null,
    classes = {},
    fetchOptions,
    portalRef,
    accordionProps,
    disabled
  } = props;
  const { children: childrenFiltersRaw, ...rootFiltersRaw } = meta?.filters || {};
  const filters = filterElements(rootFiltersRaw, blockedList);
  const childrenFilters = filterElements(childrenFiltersRaw, blockedList);

  const handleFocus = (filter) => {
    if (lastFocusedFilterNameRef.current !== filter.name) {
      lastFocusedFilterNameRef.current = filter.name;
    }
  };

  const handleBlur = () => {
    if (lastFocusedFilterNameRef.current) {
      lastFocusedFilterNameRef.current = null;
    }
  };

  const getFiltersElementProps = (filters, children: boolean) => ({
    filters,
    viewsKey,
    clearRefValue,
    fetchOptions,
    lastFocusedFilterNameRef,
    meta,
    narrow,
    onFilterChange: (filter, valueId) => onFilterChange?.(filter, valueId, { children }),
    onFocus: handleFocus,
    onBlur: handleBlur,
    disabled: isLoading || disabled,
    shouldUseChildrenFilters: children
  });

  const content = [
    ...(children ? [<div key={0}>{children}</div>] : []),
    <FiltersElement
      key={1}
      {...getFiltersElementProps(filters, false)}
      classes={{ list: classes.list }}
      portalRef={portalRef}
    />,
    ...(childrenFiltersRaw
      ? [
          <FiltersElement
            key={2}
            {...getFiltersElementProps(childrenFilters, true)}
            classes={{ list: classes.list }}
            portalRef={portalRef}
          />
        ]
      : [])
  ];

  return accordionProps ? (
    <FiltersAccordion
      titles={[accordionProps.childrenTitle, accordionProps.filtersTitle]}
      tips={[accordionProps.filtersTip]}
      disabledList={[false, accordionProps.filtersDisabled]}
      activeState={accordionProps.activeState}
      allDisabled={accordionProps.allDisabled}
      onActiveIndexChange={accordionProps.onActiveIndexChange}
      allowMultipleActive={accordionProps.allowMultipleActive}
      style={{ marginTop: 20 }}
    >
      {content}
    </FiltersAccordion>
  ) : (
    <>{content}</>
  );
};
