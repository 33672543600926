import PropTypes from "prop-types";

import css from "./horizontal_lines.less";

const HorizontalLines = ({ rowHeights }) => {
  let top = 0;

  return (
    <div>
      {rowHeights.map((rh) => {
        top += rh;
        return <div key={top} className={css.line} style={{ top }} />;
      })}
    </div>
  );
};

HorizontalLines.propTypes = {
  rowHeights: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default HorizontalLines;
