import { has, isNil } from "lodash";
import { TEMPLATE_KEYS } from "PFApp/constants/templates";
import { useTemplate } from "PFApp/hooks";
import AutoSelect from "PFComponents/select/autoselect";
import { Typography } from "PFComponents/typography";
import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import { fetchProfilesOptions } from "PFCore/services/profile";
import { Collection, PermissionRule, Profile, ProfileBasicInformation } from "PFTypes";
import { ReactNode, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ProfileItem } from "../../../../components/profile_item";
import useBookingModulePermissions from "../../../../use_booking_module_permissions";
import { useBookingActivityContext } from "../../../parts/providers/booking_activity_context_provider";
import { BookingFormDataType, BookingFormMode } from "../use_booking_form";
import { BulkSelectItem } from "./bulk_select_item";
import css from "./worfkorce_member.module.scss";
import { PROFILES_COUNT_LIMIT } from "./workforce_member_select.utils";

export type SelectedUser = Profile | BulkSelectOption;

export const isBulkSelectOption = (user: SelectedUser): user is BulkSelectOption => has(user, "search_id");
export const isProfile = (user: SelectedUser): user is Profile => has(user, "id");

export type BulkSelectOption = {
  search_id: number | undefined;
  displayElement: ReactNode;
  profiles: Profile[];
  total: number | undefined;
  disabled: boolean;
};

type WorkforceMemberSelectProps = {
  selectedUser: SelectedUser | null;
  setSelectedUser: (user: SelectedUser) => void;
  initialData: Partial<BookingFormDataType>;
  mode: BookingFormMode;
  isDisabled?: boolean;
};

export const WorkforceMemberSelect = ({
  initialData,
  mode,
  selectedUser,
  setSelectedUser,
  isDisabled
}: WorkforceMemberSelectProps) => {
  const { t } = useTranslation("bookingModule");
  const { isPermittedToEdit: isPermittedToEditBookings } = useBookingModulePermissions();
  const { activity } = useBookingActivityContext();
  const { key } = useTemplate(activity?.template_id) || {};
  const canReassignBookings = useIsCurrentUserPermittedTo(PermissionRule.ReassignBookings);

  const fetchProfileOptions = useCallback((term) => fetchProfilesOptions({ term }), []);
  const hasSelectedMember = Number.isInteger(initialData.profileId);
  const shouldHideSelectOption =
    (hasSelectedMember && initialData.activityId && mode === BookingFormMode.Edit) ||
    isNil(initialData.searchId);
  const isAuditRoleRelated = key === TEMPLATE_KEYS.AUDIT_ROLE;

  const BULK_SELECT_OPTION: BulkSelectOption[] = useMemo(() => {
    const bulkOptionDisabled = (initialData.total ?? 0) > PROFILES_COUNT_LIMIT;
    return shouldHideSelectOption
      ? []
      : [
          {
            search_id: initialData.searchId,
            displayElement: <BulkSelectItem count={initialData.total || 0} disabled={bulkOptionDisabled} />,
            profiles: initialData.entries ?? [],
            total: initialData.total,
            disabled: bulkOptionDisabled
          }
        ];
  }, [initialData.searchId, initialData.entries, initialData.total]);

  const parseProfileResponse = useCallback(
    (response: Collection<ProfileBasicInformation[]>) => {
      const sortedProfiles = (response.entries as ProfileBasicInformation[]).sort((profileA, profileB) =>
        (profileA?.firstName ?? "").localeCompare(profileB?.firstName ?? "")
      );

      return [...BULK_SELECT_OPTION, ...sortedProfiles];
    },
    [initialData, shouldHideSelectOption]
  );

  useEffect(() => {
    if (selectedUser && isBulkSelectOption(selectedUser)) {
      const bulkOptionDisabled = (initialData.total ?? 0) > PROFILES_COUNT_LIMIT;
      setSelectedUser({
        search_id: initialData.searchId,
        displayElement: <BulkSelectItem count={initialData.total || 0} disabled={bulkOptionDisabled} />,
        profiles: initialData.entries ?? [],
        total: initialData.total,
        disabled: bulkOptionDisabled
      });
    }
  }, [initialData.searchId, initialData.entries, initialData.total]);

  const isWorkforceMemberReadonly =
    initialData.profileId &&
    selectedUser &&
    (!initialData.activityId || isAuditRoleRelated || !canReassignBookings);
  if (isWorkforceMemberReadonly) {
    return (
      <>
        <Typography variant="labelRegular">{t("bookings.create.workforceMember")}</Typography>
        <ProfileItem className={css.profileItem} profile={selectedUser as Profile} />
      </>
    );
  }

  const isNotPermittedToEditBookings = mode === BookingFormMode.Edit && !isPermittedToEditBookings;
  const isRoleRelatedBookingCreateMode =
    hasSelectedMember && initialData.activityId && mode === BookingFormMode.Create;
  const isNonRoleRelatedBookingWithWM = hasSelectedMember && !initialData.activityId;

  const isSelectWorkforceMemberDisabled =
    isNonRoleRelatedBookingWithWM ||
    isRoleRelatedBookingCreateMode ||
    isNotPermittedToEditBookings ||
    isAuditRoleRelated ||
    isDisabled;

  return (
    <>
      <AutoSelect
        label={t("bookings.create.selectWorkforceMember")}
        displayValues={
          selectedUser
            ? (selectedUser as BulkSelectOption)?.displayElement || (
                <ProfileItem profile={selectedUser as Profile} />
              )
            : null
        }
        disabled={isSelectWorkforceMemberDisabled}
        closeOnChange
        letClear={mode === BookingFormMode.Create}
        values={selectedUser ? [selectedUser] : null}
        query={fetchProfileOptions}
        formatOption={(item) => ({
          id: item?.id,
          displayElement: item.displayElement || <ProfileItem profile={item} />,
          item,
          disabled: item.disabled
        })}
        handleChange={(user) => {
          setSelectedUser(user[0]);
        }}
        parseResponse={parseProfileResponse}
        required
        cache={false}
        hideDisplayValuesOnFocus
      />
    </>
  );
};
