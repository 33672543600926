import classNames from "classnames";
import PropTypes from "prop-types";
import { PureComponent } from "react";

import css from "./tooltip.less";

export default class Tooltip extends PureComponent {
  label(value) {
    const { options } = this.props;

    if (options[value]) {
      return options[value].label || "";
    }
  }

  sublabel(value) {
    const { options } = this.props;

    if (options[value]) {
      return options[value].sublabel || "";
    }
  }

  valueCssClass(value) {
    const { options } = this.props;

    if (options[value]) {
      return css[options[value].style];
    }
  }

  leftPosition() {
    const { value, options } = this.props;
    const max = options.length - 1;
    const pos = value / max;
    if (pos >= 1) {
      return "100%";
    }
    const thumbWidth = 14;
    const px = (pos - 0.5) * -1 * thumbWidth;
    return `calc(${pos * 100}% + ${px}px)`;
  }

  render() {
    const { value } = this.props;

    const style = { left: this.leftPosition() };

    const tooltipClassNames = classNames(css.tooltip, this.valueCssClass(value));

    return (
      <div style={style} className={tooltipClassNames}>
        <span>{this.label(value)}</span>
        {this.renderSublabel(value)}
      </div>
    );
  }

  renderSublabel() {
    const { value, showSublabel } = this.props;
    if (showSublabel) {
      return <span className={css.sublabel}>/ {this.sublabel(value)}</span>;
    }
  }
}

Tooltip.propTypes = {
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  options: PropTypes.array.isRequired,
  showSublabel: PropTypes.bool
};

Tooltip.defaultProps = {
  showSublabel: false
};
