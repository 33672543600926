import colors from "PFTheme/tokens/colors";
import { EconomicsScenarioDetails } from "PFTypes";
import { useTranslation } from "react-i18next";

import { BarChart } from "./bar_chart";

type GradeChartProps = { data: EconomicsScenarioDetails["costFieldData"] };

export const GradeChart = ({ data }: GradeChartProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.economics.scenario.charts.grade" });
  const names = data.map(({ costFieldValue }) => costFieldValue);
  const requiredValues = data.map(({ required }) => ({ value: required }));
  const filledValues = data.map(({ possessed }) => ({ value: possessed || 0 }));
  return (
    <BarChart
      title={t("title")}
      hideLegend
      names={names}
      series={[
        {
          name: "required",
          type: "bar",
          data: requiredValues,
          itemStyle: { color: colors.paletteEcharts0 },
          barMaxWidth: 5
        },
        {
          name: "filled",
          type: "bar",
          data: filledValues,
          itemStyle: { color: colors.paletteEcharts1 },
          barMaxWidth: 5,
          barGap: "0%"
        }
      ]}
    />
  );
};
