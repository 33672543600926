import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateCertificate } from "PFApp/profiles/services/api";
import { Certificate, CertificatePayload } from "PFTypes/certificate";

import { certificateKeys } from "./query_keys";

export const useCertificateUpdate = ({ onSuccess, ...queryParams }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: CertificatePayload): any => updateCertificate(payload),
    onSuccess: (resp: Certificate) => {
      queryClient.invalidateQueries({ queryKey: certificateKeys.all() });
      queryClient.invalidateQueries({ queryKey: certificateKeys.single(resp.id) });
      onSuccess?.();
    },
    ...queryParams
  });
};
