import { useGrowl } from "PFApp/use_growl";
import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import { RouterPrompt } from "PFComponents/router_prompt/router_prompt";
import { useCertificateUpdate } from "PFCore/hooks/queries/certificates";
import { useCertificate } from "PFCore/hooks/queries/certificates";
import { Certificate } from "PFTypes/certificate";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import DeleteCertificateModal from "./delete_certificate_modal";
import css from "./edit_certificate.module.scss";
import EditCertificateForm from "./edit_certificate_form";

type EditCertificateProps = {
  onClose: () => void;
};

const EditCertificate = ({ onClose }: EditCertificateProps) => {
  const { certificateId }: { certificateId: string } = useParams();

  const [uploadPayload, setUploadPayload] = useState({});
  const [formChanged, setFormChanged] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { t } = useTranslation(["profiles", "translation"]);

  const growl = useGrowl();

  const handleClose = () => {
    onClose();
  };

  const certificateQuery = useCertificate(certificateId, {
    refetchOnMount: "always",
    onSuccess: (resp: Certificate) =>
      setUploadPayload({
        url: resp.attachmentId ? null : resp.url,
        attachmentId: resp.attachmentId,
        imageUrl: resp.attachmentId ? null : resp.imageUrl
      })
  });

  const updateCertificateMutation = useCertificateUpdate({
    onError: () => {
      growl({
        message: t("profiles:common.certificates.growl.formError"),
        kind: "error"
      });
    },
    onSuccess: () => {
      growl({
        message: t("profiles:common.certificates.growl.updated"),
        kind: "success"
      });
      handleClose();
    }
  });

  const handleSubmit = (payload) => {
    setFormChanged(false);

    const data = { ...uploadPayload, ...payload, id: certificateId };
    updateCertificateMutation.mutate(data);
  };

  const handleFormChange = (isChanged: boolean) => setFormChanged(isChanged);

  const errors = (updateCertificateMutation.error as JQuery.jqXHR)?.responseJSON?.errors;
  const certificate = certificateQuery.data as Certificate;

  if (certificateQuery.isLoading) {
    return <LoadingDots />;
  }

  return (
    <div>
      <RouterPrompt when={formChanged} />
      <div className={css.header}>
        <div className={css.title}>{`${t("profiles:common.certificates.editCertificate")} - ${
          certificate.title
        }`}</div>
        <Button
          icon="remove"
          text={t("profiles:common.certificates.deleteCertificate")}
          className={css.button}
          onClick={() => setShowDeleteModal(true)}
          kind="secondary"
        />
      </div>
      <EditCertificateForm
        certificate={certificate}
        onSubmit={handleSubmit}
        onClose={handleClose}
        errors={errors}
        isSubmitting={updateCertificateMutation.isLoading}
        onFormChange={handleFormChange}
      />
      {showDeleteModal && (
        <DeleteCertificateModal
          certificate={{ ...certificate, id: certificateId }}
          onClose={() => {
            setShowDeleteModal(false);
          }}
          onDeleted={handleClose}
        />
      )}
    </div>
  );
};

export default EditCertificate;
