import classNames from "classnames";
import PropTypes from "prop-types";

import css from "./page_box.module.scss";

const SectionBox = ({ children, style, isLoading, shadow, qaId, className }) => (
  <div
    style={style}
    className={classNames(css.root, css[shadow], css.section, css.children, className, {
      [css.loading]: isLoading
    })}
    data-qa-id={qaId}
  >
    {children}
  </div>
);

SectionBox.propTypes = {
  qaId: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
  isLoading: PropTypes.bool,
  shadow: PropTypes.oneOf(["none", "regular"]),
  className: PropTypes.string
};

export default SectionBox;
