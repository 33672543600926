import classNames from "classnames";
import Tooltip from "PFComponents/tooltip/tooltip";
import copyTextToClipboard from "PFCore/helpers/copyTextToClipboard";
import PropTypes from "prop-types";
import { useMemo } from "react";

import css from "./contact_information_v2.module.scss";
import getContactInfoArray from "./get_contact_info_array";

const ContactInformation = ({ profile, currentAccount, isContactPermitted }) => {
  const contacts = useMemo(() => getContactInfoArray(profile, currentAccount), [profile, currentAccount]);

  const phoneAndEmailContacts = useMemo(
    () => contacts.filter((item) => item.type === "email" || item.type === "phone"),
    [contacts]
  );
  const otherContacts = useMemo(
    () => contacts.filter((item) => item.type !== "email" && item.type !== "phone"),
    [contacts]
  );

  if (contacts.length === 0) {
    return null;
  }

  const handleCopyContactNumber = (onClick, type, value) => {
    if (!onClick && type === "phone" && value) {
      const number = /\+?[\d\s-]*\d/.exec(value)[0];
      copyTextToClipboard(number);
    }
  };

  const contactsList = (contatList) =>
    contatList.map((contact) => {
      const { Icon, url, target, type, value, labelTitle, onClick } = contact;
      const iconWithTooltip = (
        <Tooltip interactive content={<span className={css.value}>{value || labelTitle}</span>}>
          <div
            role="button"
            tabIndex="0"
            className={classNames(css.icon, css[type])}
            data-type={type}
            title={labelTitle}
            onClick={() => handleCopyContactNumber(onClick, type, value)}
            onKeyDown={() => handleCopyContactNumber(onClick, type, value)}
          >
            <Icon width={14} height={14} />
          </div>
        </Tooltip>
      );

      return (
        <li key={contact.value + contact.type} data-qa-id={`ContactInformation.${contact.type}`}>
          <span className={css.item}>
            {url || onClick ? (
              <a
                title={labelTitle}
                href={url}
                target={target}
                onClick={onClick}
                rel="noopener noreferrer"
                className={css.link}
              >
                {iconWithTooltip}
              </a>
            ) : (
              iconWithTooltip
            )}
          </span>
        </li>
      );
    });

  return (
    <div className={css.container}>
      <div className={css.label}>Contact {profile.first_name}</div>
      <ul className={css.list} data-qa-id="ContactInformation">
        {contactsList(
          isContactPermitted
            ? phoneAndEmailContacts
            : phoneAndEmailContacts.filter((item) => item.type === "email")
        )}
        {isContactPermitted && phoneAndEmailContacts.length > 0 && otherContacts.length > 0 && (
          <li className={css.separator}></li>
        )}
        {isContactPermitted && contactsList(otherContacts)}
      </ul>
    </div>
  );
};

ContactInformation.propTypes = {
  currentAccount: PropTypes.object.isRequired,
  profile: PropTypes.shape({ id: PropTypes.number, first_name: PropTypes.string }),
  isContactPermitted: PropTypes.bool
};

export default ContactInformation;
