import { isArray } from "lodash";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { FilterPreviewItem, Value } from "PFCore/types/filters";
import { Meta } from "PFTypes";
import { useEffect, useState } from "react";

import { getFilterQuery } from "../../../use_filtered_collection";
import { getTextFromFilterValue } from "./filters_preview.utils";

export const getFilterHash = (filter: FilterPreviewItem) =>
  `${filter.name}_${filter.children}_${JSON.stringify(filter.value)}`;

type UseFiltersDisplayValuesProps = {
  meta: Omit<Meta, "total">;
  filterPreviewItems: FilterPreviewItem[];
  fetchOptions: any;
};

export type DisplayValues = Record<
  string,
  { id: Value; text: string } | { id: Value; text: string }[] | null
>;

export const useFiltersDisplayValues = ({
  meta,
  filterPreviewItems,
  fetchOptions
}: UseFiltersDisplayValuesProps): DisplayValues => {
  const { formatDate } = useDateFormatter();
  const [displayValues, setDisplayValues] = useState<DisplayValues>({});
  const filtersStringified = JSON.stringify(filterPreviewItems);

  useEffect(() => {
    filterPreviewItems.forEach((filter) => {
      const query = fetchOptions || filter.query || getFilterQuery(filter, meta, !!filter.children);
      const filterHash = getFilterHash(filter);
      if (query && !!filter.value) {
        query({ value: [filter.value].flat() }).then((resp) => {
          setDisplayValues((prev) => ({
            ...prev,
            [filterHash]: isArray(resp?.entries) ? resp.entries : resp
          }));
        });
      } else {
        const text = getTextFromFilterValue({ filter, formatDate });
        const displayValue = text ? { id: filter.value, text } : null;
        setDisplayValues((prev) => ({ ...prev, [filterHash]: displayValue }));
      }
    });
  }, [filtersStringified]);

  return displayValues;
};
