import classNames from "classnames";
import WarningIcon from "PFIcons/warning.svg";

import css from "./error_message.module.scss";

type ErrorMessageProps = {
  message: string;
  className?: string;
  iconWidth?: number;
  iconHeight?: number;
};

export const ErrorMessage = ({
  message,
  className,
  iconWidth = 20,
  iconHeight = 20
}: ErrorMessageProps): JSX.Element => (
  <div className={classNames(css.root, className)}>
    <WarningIcon width={iconWidth} height={iconHeight} />
    <span>{message}</span>
  </div>
);
