import { LoadingDots } from "PFComponents/loading_dots";
import { Typography } from "PFComponents/typography";
import { useTranslation } from "react-i18next";

import { useActivityHistory } from "../../hooks";
import { useSortedHistoryEntries } from "../../hooks/use_sorted_history_entries";
import { useTranslatedActivityType } from "../../hooks/use_translated_activity_type";
import { HistoryEntry } from "../history_entry";
import css from "./history_entries_list.scss";

export const HistoryEntriesList = () => {
  const { error, isLoading } = useActivityHistory();
  const taskName = useTranslatedActivityType();

  const entries = useSortedHistoryEntries();
  const { t } = useTranslation("activities", { keyPrefix: "show.history" });

  const isUnauthorized = error?.response?.status === 422;

  if (isLoading) {
    return (
      <div className={css.info}>
        <LoadingDots circlesEnabled={true} circleSize={"sm"} />
      </div>
    );
  }

  if (isUnauthorized) {
    return (
      <div className={css.info}>
        <Typography variant="h4">{t("modal.unauthorized", { task: taskName })}</Typography>
      </div>
    );
  }

  if (entries.length === 0) {
    return (
      <div className={css.info}>
        <Typography variant="h4">{t("modal.missingData", { task: taskName })}</Typography>
      </div>
    );
  }

  return (
    <div className={css.entriesList}>
      {entries.map((entry) => (
        <HistoryEntry key={`${entry.id}-${entry.event}-${entry.createdAt}`} entry={entry} />
      ))}
    </div>
  );
};
