import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { underscored } from "underscore.string";

import css from "./nav_dropdown.less";

const NavDropdownItem = ({ item, handleCloseSubMenu }) => {
  const handleActionClick = () => {
    handleCloseSubMenu && handleCloseSubMenu();
    item.action && item.action();
  };

  if (item.sep === true) {
    return <li className={css.sep} />;
  }

  const handleClick = item.action ? handleActionClick : handleCloseSubMenu;

  const itemContent = (
    <div className={css.itemContent}>
      <span className={css.overflowed} title={item.title || item.name}>
        {item.name}
      </span>
      {item.badge && (
        <div className={css.badgeItem}>
          <span>{item.badge}</span>
        </div>
      )}
    </div>
  );

  return (
    <li data-qa-id={getQaId(`${item.qaIdName || item.name}`)}>
      {item.external ? (
        <a href={item.href} target={item.target} className={css.link} id={item.id} rel={item.rel}>
          {itemContent}
        </a>
      ) : (
        <Link
          to={item.href}
          target={item.target}
          className={css.link}
          id={item.id}
          onClick={handleCloseSubMenu}
        >
          {itemContent}
        </Link>
      )}
      {item.action && !item.actionHref && (
        <button className={css.action} onClick={handleClick} data-qa-id={`NavDropdownItem.action`}>
          {item.actionIcon || "+"}
        </button>
      )}
      {item.actionHref && (
        <Link
          to={item.actionHref}
          className={css.action}
          onClick={handleClick}
          data-qa-id={`NavDropdownItem.action`}
        >
          {item.actionIcon || "+"}
        </Link>
      )}
    </li>
  );
};

const getQaId = (name) => `NavDropdownItem.${underscored(name)}`;

NavDropdownItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    sep: PropTypes.bool,
    name: PropTypes.node,
    qaIdName: PropTypes.node,
    href: PropTypes.string,
    target: PropTypes.string,
    action: PropTypes.func,
    actionHref: PropTypes.string,
    actionIcon: PropTypes.node,
    rel: PropTypes.string,
    badge: PropTypes.string,
    external: PropTypes.bool,
    id: PropTypes.string
  }),
  handleCloseSubMenu: PropTypes.func
};

NavDropdownItem.defaultProps = {
  external: false
};

export default NavDropdownItem;
