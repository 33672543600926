import moment, { Moment, MomentInput } from "moment";

import {
  ISO_DATE_FORMAT,
  ISO_DATE_TIME_FORMAT,
  ISO_DATE_TIME_FORMAT_NO_OFFSET,
  ISO_TIME_FORMAT,
  TIME_FORMAT_12H,
  TIME_FORMAT_24H
} from "./date";

export type FormatFunction = (rawDate?: MomentInput) => string;

export type DateFormatter = {
  dateFormatString: string;
  dateTimeFormatString: string;
  timeFormatString: string;
  formatDate: FormatFunction;
  formatDateTime: FormatFunction;
  formatISOTime: FormatFunction;
  formatISODate: FormatFunction;
  formatISODateTime: FormatFunction;
  formatDatabaseDateTime: FormatFunction;
  formatShortMonth: FormatFunction;
  formatLongDate: FormatFunction;
  utc: (rawDate?: MomentInput) => Moment;
  isDatePassed: (rawDate?: MomentInput) => boolean;
};

export const dateFormatter: (dateFormat?: string, isAM?: boolean) => DateFormatter = (
  dateFormat = ISO_DATE_FORMAT,
  isAM = false
) => {
  const timeFormat = isAM ? TIME_FORMAT_12H : TIME_FORMAT_24H;
  const dateTimeFormatString = `${dateFormat}, ${timeFormat}`;

  return {
    dateFormatString: dateFormat,
    dateTimeFormatString,
    timeFormatString: timeFormat,
    formatDate: (rawDate) => moment(rawDate).format(dateFormat),
    formatDateTime: (rawDate) => moment(rawDate).format(dateTimeFormatString),
    formatISOTime: (rawTime) => moment.utc(rawTime).format(ISO_TIME_FORMAT),
    formatISODate: (rawDate) => moment(rawDate).format(ISO_DATE_FORMAT),
    formatISODateTime: (rawDate) => moment(rawDate).format(ISO_DATE_TIME_FORMAT_NO_OFFSET),
    formatDatabaseDateTime: (rawDate) => moment.utc(rawDate).format(ISO_DATE_TIME_FORMAT),
    formatShortMonth: (rawDate) => moment(rawDate).format("MMM"),
    formatLongDate: (rawDate) => moment(rawDate).format("LL"),
    utc: (rowDate) => moment.utc(rowDate),
    isDatePassed: (rawDate) => (rawDate ? moment.utc().diff(moment.utc(rawDate).endOf("day")) > 0 : false)
  };
};
