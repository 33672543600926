import { omit, uniqueId } from "lodash";
import useIsBookingModulePermitted from "PFApp/use_is_booking_module_permitted";
import { ActionIcon } from "PFComponents/action_icon";
import { Carousel, CarouselActions } from "PFComponents/carousel";
import { LoadingDots } from "PFComponents/loading_dots/loading_dots";
import MultiToggle, { Option } from "PFComponents/multi_toggle/multi_toggle";
import { Typography } from "PFComponents/typography";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import colors from "PFTheme/tokens/colors";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import modalCss from "../booking_form.module.scss";
import { BookingFormDataType } from "../use_booking_form";
import css from "./booking_range_column.module.scss";
import { RangeItem } from "./range_item";

type BookingRangeColumnProps = {
  initialData: Partial<BookingFormDataType>;
  setBookingItems: (unknown) => void;
  setBookingData: (unknown) => void;
  bookingItems: Record<string, unknown>[];
  isEditMode: boolean;
  loading: boolean;
  isSelectedEngagement: boolean;
  errors?: Record<string, string>;
  show: boolean;
};

enum BookingType {
  Load = "LOAD",
  Time = "TIME"
}

export const BookingRangeColumn = ({
  initialData,
  setBookingItems,
  setBookingData,
  bookingItems,
  isEditMode,
  loading,
  isSelectedEngagement,
  errors = {},
  show
}: BookingRangeColumnProps) => {
  const { data: currentAccount } = useCurrentAccount();
  const { t } = useTranslation("bookingModule");
  const portalRef = useRef(null);

  const isBookingPermitted = useIsBookingModulePermitted();

  const [index, setIndex] = useState(0);
  const [bookingType, setBookingType] = useState<string>(
    isBookingPermitted && initialData?.bookingTemplate ? BookingType.Time : BookingType.Load
  );

  const monthsForwardLimit = currentAccount.availability?.calculated_months_limit!;

  useEffect(() => {
    if (show) {
      setIndex(0);
      setBookingType(
        isBookingPermitted && initialData?.bookingTemplate ? BookingType.Time : BookingType.Load
      );
    }
  }, [show]);

  const addNewDateRange = () => {
    setBookingItems((prev) => {
      setTimeout(() => setIndex(prev.length), 50); // timeout needed for Safari
      return [...prev, { key: uniqueId(`${prev.length}+booking_modal_item+`) }];
    });
  };

  const removeDateRange = (index) => {
    setBookingItems((prev) => {
      setIndex(Math.max(0, index - 1));
      return prev.filter((_, i) => i !== index);
    });

    setBookingData((prev) => {
      const values = Object.values(omit(prev, [index]));
      return values.reduce((acc: object, value, index) => ({ ...acc, [index]: value }), {});
    });
  };

  const disableRepeatBooking =
    !isBookingPermitted ||
    !!initialData?.activityId ||
    (isEditMode && !initialData?.bookingTemplate?.id) ||
    isSelectedEngagement;
  const bookingTypes = useMemo<Option[]>(
    () => [
      {
        id: BookingType.Load,
        value: t("bookings.create.singleBooking")
      },
      ...(disableRepeatBooking
        ? []
        : [
            {
              id: BookingType.Time,
              value: t("bookings.create.repeatedBooking")
            }
          ])
    ],
    [disableRepeatBooking, t]
  );

  return (
    <>
      <section className={modalCss.column} id="calendar_portal">
        {bookingTypes.length > 1 ? (
          <MultiToggle options={bookingTypes} defaultSelected={bookingType} onChange={setBookingType} />
        ) : (
          <Typography variant="bodyBold" noMargin>
            {bookingTypes[0].value}
          </Typography>
        )}
        <CarouselActions
          onClickLeft={() => setIndex(Math.max(index - 1, 0))}
          onClickRight={() => setIndex(Math.min(index + 1, bookingItems.length - 1))}
          index={index}
          scrollLength={bookingItems.length}
          label={
            <div className={css.actionsWrapper}>
              {t("bookings.create.dateRange", {
                index: bookingItems.length > 0 ? index + 1 : index,
                totalItems: bookingItems.length
              })}
              {!isEditMode && (
                <>
                  <ActionIcon name="add" size="sm" onClick={addNewDateRange} />
                  {bookingItems.length > 0 && (
                    <ActionIcon
                      name="remove"
                      size="sm"
                      onClick={() => removeDateRange(index)}
                      fill={colors.paletteRed0}
                    />
                  )}
                </>
              )}
            </div>
          }
          actionsClassName={css.dateRange}
        />
        {loading && <LoadingDots className={css.loading} />}
        <div className={css.carouselWrapper}>
          <Carousel
            items={bookingItems.map((item, itemIndex) => ({
              id: (item.key as string) || itemIndex,
              content: (
                <RangeItem
                  activityId={initialData.activityId}
                  booking={item}
                  bookingTemplate={initialData.bookingTemplate!}
                  isVisible={index === itemIndex}
                  itemIndex={itemIndex}
                  monthsForwardLimit={monthsForwardLimit}
                  portalRef={portalRef}
                  specificTime={bookingType === BookingType.Time}
                  setBookingData={setBookingData}
                  errors={errors}
                />
              )
            }))}
            activeIndex={index}
          />
        </div>
      </section>
      <div ref={portalRef} />
    </>
  );
};
