import Radios, { Item } from "PFComponents/radios/radios";
import { useCallback } from "react";

import css from "./booking_questions.module.scss";

export enum BookingSelectOption {
  This = "This",
  All = "All",
  Future = "Future"
}

export type Messages = Partial<Record<BookingSelectOption, React.ReactNode>>;

type BookingQuestionsProps = {
  className?: string;
  messages?: Messages;
  text: string;
  value: BookingSelectOption;
  items: Item<BookingSelectOption>[];
  onChange: (selectedOption: BookingSelectOption) => void;
  disabled?: boolean;
};

export const BookingQuestions = ({
  text,
  className,
  messages,
  value,
  items,
  onChange,
  disabled
}: BookingQuestionsProps): JSX.Element => {
  const handleChange = useCallback(
    (item: Item<BookingSelectOption>) => {
      onChange(item.id);
    },
    [onChange]
  );

  return (
    <>
      <div className={css.radios}>
        <p>{text}</p>
        <div className={className}>
          <Radios<BookingSelectOption>
            items={items}
            value={value}
            handleChange={handleChange}
            disabled={disabled}
          />
        </div>
      </div>
      {messages && <p>{messages[value]}</p>}
    </>
  );
};
