import { useTranslation } from "react-i18next";

import css from "../../detail_view.module.scss";

type BookingDetailTotalLoadProps = {
  load: number;
};

export const BookingDetailTotalLoad = ({ load }: BookingDetailTotalLoadProps): JSX.Element => {
  const { t } = useTranslation("bookingModule");
  const loadPercentage = Math.round(load * 100);
  const loadLabel = t("details.load", { load: loadPercentage });
  return <span className={css.itemText}>{loadLabel}</span>;
};
