import { Typography } from "PFComponents/typography";
import { useActivity } from "PFCore/hooks/queries/activity";
import { Id } from "PFTypes";

type ActivityInfoProps = {
  label: string;
  activityId: Id;
};

export const ActivityInfo = ({ label, activityId }: ActivityInfoProps) => {
  const { data: activity } = useActivity(activityId);

  if (!activity) {
    return null;
  }

  return (
    <>
      <Typography variant="labelRegular">{label}</Typography>
      <Typography variant="bodyBold">{activity.name}</Typography>
    </>
  );
};
