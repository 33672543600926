import { EngagementOption } from "PFApp/components/engagement_select/engagement_item";
import { useTemplate } from "PFApp/hooks";
import { activityPermissions } from "PFCore/helpers/templates";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { ActivitiesResponse } from "PFCore/services/activities";
import { Activity } from "PFTypes";
import { useCallback } from "react";

import { useEngagementQuery } from "../../../hooks/use_engagement_query";
import { useBookingActivityContext } from "../../parts/providers/booking_activity_context_provider";
import { checkIsGroupBooking } from "./use_engagement_select.utils";
import { SelectedUser } from "./workforce_member_select/workforce_member_select";

type UseEngagementSelect = {
  activityId: number | undefined;
  selectedEngagement: Activity | null;
  setSelectedEngagement: React.Dispatch<React.SetStateAction<Activity | null>>;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  selectedUser: SelectedUser | null;
  isGrouped: boolean;
};

type UseEngagementSelectReturn = {
  shouldDisplayEngagementSelect: boolean;
  engagementSelectQuery: (term: string) => Promise<ActivitiesResponse>;
  engagementSelectParseResponse: (response: ActivitiesResponse) => Activity[];
  engagementSelectHandleChange: (engagement: Activity) => void;
  parseEngagementToOption: (engagement: Activity) => EngagementOption;
};

export const useEngagementSelect = ({
  activityId,
  selectedEngagement,
  setSelectedEngagement,
  title,
  setTitle,
  selectedUser,
  isGrouped
}: UseEngagementSelect): UseEngagementSelectReturn => {
  const { data: currentProfile } = useCurrentProfile();
  const { bookings_allowed: isLinkingToEngagementEnabled } = useTemplate("engagement") || {};
  const { activity } = useBookingActivityContext();
  const activityTemplate = useTemplate(activity?.template_id);
  const isActivityEngagement = activityTemplate?.key === "engagement";

  const engagementMatchesPermissions = ["ro", "rw"].includes(
    activityPermissions(currentProfile?.permissions_group, currentProfile?.templates, "engagement") ?? ""
  );
  const engagementTemplate = currentProfile.templates?.find((item) => item.key === "engagement");

  const isNotGroupBooking = !checkIsGroupBooking(selectedUser, isGrouped);

  const shouldDisplayEngagementSelect =
    !!isLinkingToEngagementEnabled &&
    (!activityId || isActivityEngagement) &&
    !!engagementTemplate &&
    engagementMatchesPermissions &&
    isNotGroupBooking;

  const { engagementSelectQuery, engagementSelectParseResponse } = useEngagementQuery({ isAudit: false });

  const engagementSelectHandleChange = useCallback(
    (engagementOption) => {
      const isTitlePreviousEngagementName = selectedEngagement?.name === title;

      if (engagementOption && (!title || isTitlePreviousEngagementName)) {
        setTitle(engagementOption.name);
      }
      setSelectedEngagement(engagementOption);
    },
    [selectedEngagement, title]
  );

  const parseEngagementToOption = useCallback(
    (activity) => ({
      id: activity.id,
      name: activity.name,
      description: activity.description
    }),
    []
  );

  return {
    shouldDisplayEngagementSelect,
    engagementSelectQuery,
    engagementSelectParseResponse,
    engagementSelectHandleChange,
    parseEngagementToOption
  };
};
