import classNames from "classnames";

import css from "../../detail_view.module.scss";

type BookingDetailItemProps = {
  label: string;
  value: string | number | JSX.Element | undefined;
  icon?: JSX.Element;
  rootClassName?: string;
  headerClassName?: string;
  labelClassName?: string;
};

export const BookingDetailItem = ({
  label,
  value,
  icon,
  rootClassName,
  labelClassName,
  headerClassName
}: BookingDetailItemProps): JSX.Element => (
  <div className={classNames(css.item, rootClassName)}>
    <div className={headerClassName}>
      {icon} <span className={classNames(css.label, labelClassName)}>{label}</span>
    </div>
    <div className={css.itemText}>{value ?? ""}</div>
  </div>
);
