import { useCallback, useEffect, useState } from "react";

type UseOverrideToggles = {
  show: boolean;
  defaultOverridesCalendar: boolean | undefined;
  defaultOverridesNonWorkingDays: boolean | undefined;
};

export const useOverrideToggles = ({
  show,
  defaultOverridesCalendar,
  defaultOverridesNonWorkingDays
}: UseOverrideToggles) => {
  const [overridesCalendar, setOverridesCalendar] = useState(defaultOverridesCalendar ?? true);
  const [overridesNonWorkingDays, setOverridesNonWorkingDays] = useState(
    defaultOverridesNonWorkingDays ?? false
  );

  useEffect(() => {
    if (show) {
      setOverridesCalendar(defaultOverridesCalendar ?? true);
      setOverridesNonWorkingDays(defaultOverridesNonWorkingDays ?? false);
    }
  }, [show]);

  const resetOverrideToggles = useCallback(() => {
    setOverridesCalendar(true);
    setOverridesNonWorkingDays(false);
  }, []);

  return {
    overridesCalendar,
    setOverridesCalendar,
    overridesNonWorkingDays,
    setOverridesNonWorkingDays,
    resetOverrideToggles
  };
};
