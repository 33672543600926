import { flatten, isEqual, last } from "lodash";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { normalizeList } from "PFApp/search/helpers/normalize_keywords";
import KeywordsSliders from "PFApp/search/parts/keywords_sliders";
import { useMatchableTypes } from "PFCore/helpers/activities";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useActivityInvalidate, useActivityUpdate } from "PFCore/hooks/queries/activity";
import { useErrorsGrowl } from "PFCore/hooks/use_errors_growl";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";

const ActivityKeywords = ({ typesOpened = [], onChangeTypeOpen }) => {
  const { customTypes } = useCustomTypes();
  const { task } = useActivityPageContext();
  const growlErrors = useErrorsGrowl();

  const { custom_fields, id, template_id } = task;

  const { update: updateActivity } = useActivityUpdate(id);
  const { invalidate: invalidateActivities } = useActivityInvalidate();

  const [errors, setErrors] = useState({});

  const matchableTypes = useMatchableTypes(template_id);
  const [choosenKeywords, setChoosenKeywords] = useState([]);

  const getCustomFieldByName = (typeName) => task.custom_fields.find((cf) => cf.type?.name === typeName);

  const updateChosenKeywords = useCallback(() => {
    const choosenKeywordsMaybe = normalizeList(
      flatten(task.custom_fields.map(({ values, type }) => (values || []).map((val) => ({ ...val, type })))),
      customTypes,
      matchableTypes,
      []
    );

    // this contraption is needed because we store non-reacty state in shape of taskModel AND we have
    // different representation for api and for ui stored in the same object AND the custom fields
    // are confusing fixing that will require decoupling the whole thing from the taskModel and
    // storing the data and filters separately in a state or in the store:
    if (
      choosenKeywordsMaybe.every((keyword) => keyword.value != null) && // eslint-disable-line eqeqeq
      !isEqual(
        choosenKeywordsMaybe.map((it) => it.value),
        choosenKeywords.map((it) => it.value)
      )
    ) {
      setChoosenKeywords(choosenKeywordsMaybe);
    }
  }, [choosenKeywords, customTypes, matchableTypes, task.custom_fields]);

  const saveTask = (data) =>
    updateActivity(data)
      .then(updateChosenKeywords)
      .catch(({ response }) => {
        const errors = {};
        (response.errors || [])
          .filter((error) => error.source?.pointer?.includes("attribute"))
          .forEach((error) => {
            const key = last(error.source.pointer.split("/"));
            if (key) {
              errors[key] = error.detail;
            }
          });
        setErrors(errors);
        // restore task in case chosen keywords are removed and unable to select
        invalidateActivities([id]);
        growlErrors(response, { display_all: true, version: "v2" });
      }); //Patch gives API error on _sort now?

  const patchOneCustomField = (typeName, process) => {
    const customField = getCustomFieldByName(typeName);
    let newValues = process(customField?.values);

    if (Array.isArray(newValues)) {
      if (typeof newValues[0] === "object") {
        newValues = newValues.map(({ id, weight }) => ({ id, weight }));
      } else {
        newValues = newValues.map((id) => ({ id }));
      }
    } else if (typeof newValues === "number") {
      newValues = [{ id: newValues }];
    }

    if (customField) {
      customField.values = newValues || [];
      saveTask({ custom_fields });
    } else {
      custom_fields.push({ type: { name: typeName }, values: newValues || [] });
      saveTask({ custom_fields });
    }
  };

  useEffect(() => {
    updateChosenKeywords();
  }, [updateChosenKeywords]);

  return (
    <KeywordsSliders
      search={false}
      matchableTypes={matchableTypes}
      style={{ marginBottom: -30 }}
      choosenKeywords={choosenKeywords}
      remove={(keyword) => {
        patchOneCustomField(keyword.type.name, (values) => values.filter(({ id }) => id !== keyword.id));
      }}
      pushWithNewWeight={(keyword, weight) => {
        patchOneCustomField(keyword.type.name, (values) => {
          const value = values.find(({ id }) => id === keyword.id);
          value.weight = weight;
          return values;
        });
      }}
      handleAddOne={(keyword) => {
        patchOneCustomField(keyword.type.name, (values) => [
          ...(values || []),
          { id: keyword.id, global_id: keyword.global_id, value: keyword.value }
        ]);
      }}
      errors={errors}
      typesOpened={typesOpened}
      onChangeOpen={onChangeTypeOpen}
    />
  );
};

ActivityKeywords.propTypes = {
  typesOpened: PropTypes.arrayOf(PropTypes.string),
  onChangeTypeOpen: PropTypes.func
};

export default ActivityKeywords;
