import { useTranslation } from "react-i18next";

import { OverbookingsRange } from "../use_overbooking_ranges";

type OverbookingSubHeaderProps = {
  overbookings: OverbookingsRange["overbookings"];
};

export const OverbookingSubHeader = ({ overbookings }: OverbookingSubHeaderProps) => {
  const { t } = useTranslation("bookingModule", { keyPrefix: "details.overbookings" });
  const avgOverbookedHoursPerDay = -Math.ceil((overbookings[0].available_minutes || 0) / 60);

  return <div>{t("hoursOverPerDay", { count: avgOverbookedHoursPerDay })}</div>;
};
