import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { RequirementType } from "PFTypes";
import { useCallback } from "react";

import { UNDETERMINED_FUTURE } from "../../../../constants/dates";
import { useTemplate } from "../../../../hooks";
import { useBookingActivityContext } from "../../../parts/providers/booking_activity_context_provider";
import { BookingSelectOption } from "../../booking_questions/booking_questions";
import { mapRequirementToBookingData } from "../requirement_selector";
import { RepeatedBookingDateRangeData } from "../use_booking_form";
import { checkIsGroupBooking } from "../use_engagement_select.utils";
import { isBulkSelectOption, isProfile } from "../workforce_member_select/workforce_member_select";
import { UseHandleSubmit } from "./use_handle_submit";
import { getActivityId } from "./use_handle_submit.utils";

type SpecificPayload = {
  activityId?: number | undefined;
  startDate: string;
  endDate: string;
  startTime: string | undefined;
  endTime: string | undefined;
  requirementType: RequirementType;
  requirementValue: number;
  bookingCategoryId: number | undefined;
  wdayMask: number | undefined;
  title: string | undefined;
  description: string | undefined;
  overridesCalendar: boolean | undefined;
  overridesNonWorkingDays: boolean | undefined;
  phaseSourceId: number | undefined;
  profileId?: number | undefined;
  bookingGroupId?: number | undefined;
  searchId?: number | undefined;
  dateRange?: {
    min: string;
    max: string;
  };
};

type BasePayload = {
  profileId: number | undefined;
  activityId: number | undefined;
  sourceType: "ui";
  searchId: number | undefined;
  bookings: SpecificPayload[];
};

type UseBookingPayload = Pick<
  UseHandleSubmit,
  | "bookingData"
  | "mode"
  | "selectedUser"
  | "initialData"
  | "title"
  | "description"
  | "selectedBookingCategory"
  | "overridesCalendar"
  | "overridesNonWorkingDays"
  | "specificTime"
  | "updateRepeatOptionSelected"
  | "selectedEngagement"
> & {
  updateAllGroup?: boolean;
};

type RequestPayload = SpecificPayload[] | BasePayload[];

type UseBookingPayloadReturn = {
  getRequestPayload: () => RequestPayload;
};

export const useBookingPayload = ({
  bookingData,
  mode,
  selectedUser,
  initialData,
  title,
  description,
  selectedBookingCategory,
  overridesCalendar,
  overridesNonWorkingDays,
  specificTime,
  updateAllGroup,
  updateRepeatOptionSelected,
  selectedEngagement
}: UseBookingPayload): UseBookingPayloadReturn => {
  const { formatISODate } = useDateFormatter();
  const { activity } = useBookingActivityContext();
  const activityTemplate = useTemplate(activity?.template_id);
  const isActivityEngagement = activityTemplate?.key === "engagement";

  const getBookingPayload = useCallback(
    (index: number) => {
      const { requirement } = bookingData[index];

      const { requirement_type, requirement_value } = mapRequirementToBookingData({
        type: requirement.type,
        value: requirement.value!
      });
      const requirementData = {
        requirementType: requirement_type,
        requirementValue: requirement_value
      };
      const payload: SpecificPayload = {
        startDate: bookingData[index].start,
        endDate: bookingData[index].end,
        startTime: (bookingData[index] as RepeatedBookingDateRangeData).startTime,
        endTime: (bookingData[index] as RepeatedBookingDateRangeData).endTime,
        ...requirementData,
        bookingCategoryId: selectedBookingCategory?.id,
        wdayMask: (bookingData[index] as RepeatedBookingDateRangeData).wdayMask,
        title,
        description,
        overridesCalendar,
        overridesNonWorkingDays,
        phaseSourceId: bookingData[index].phaseSourceId
      };

      const isNotABulkSelect = !checkIsGroupBooking(selectedUser);
      if ((isActivityEngagement || selectedEngagement?.id) && isNotABulkSelect) {
        payload.activityId = selectedEngagement?.id;
      }

      if (!updateAllGroup && initialData.activityId) {
        payload.profileId = selectedUser && isProfile(selectedUser) ? selectedUser.id : undefined;
      }

      if ((mode === "edit" || mode === "clone") && updateAllGroup) {
        payload.bookingGroupId = initialData.bookingGroupId;
      } else if (selectedUser && isBulkSelectOption(selectedUser)) {
        payload.searchId = selectedUser?.search_id;
      } else if (specificTime && selectedUser) {
        payload.profileId = selectedUser.id;
      }
      if (mode === "edit" && specificTime && updateRepeatOptionSelected === BookingSelectOption.Future) {
        payload.dateRange = {
          min: formatISODate(initialData.bookings?.[index]?.startDate),
          max: formatISODate(UNDETERMINED_FUTURE)
        };
      }
      return payload;
    },
    [
      bookingData,
      selectedBookingCategory,
      title,
      description,
      overridesCalendar,
      overridesNonWorkingDays,
      updateAllGroup,
      initialData,
      selectedUser,
      specificTime,
      updateRepeatOptionSelected,
      mode,
      selectedEngagement
    ]
  );

  const getRequestPayload = useCallback(() => {
    const bookingDataKeys = Object.keys(bookingData);

    const isEditMode = mode === "edit";
    const isRepeatedBookingCreation = !!specificTime;

    if (isEditMode) {
      return [getBookingPayload(0)];
    }
    if (isRepeatedBookingCreation) {
      return bookingDataKeys.map(Number).map(getBookingPayload);
    }

    return [
      {
        profileId: updateAllGroup
          ? undefined
          : selectedUser && isProfile(selectedUser)
          ? selectedUser.id
          : undefined,
        activityId:
          selectedUser && !isBulkSelectOption(selectedUser)
            ? getActivityId(initialData?.activityId, selectedEngagement?.id)
            : undefined,
        sourceType: "ui",
        searchId: selectedUser && isBulkSelectOption(selectedUser) ? selectedUser.search_id : undefined,
        bookings: bookingDataKeys.map(Number).map(getBookingPayload)
      }
    ] as BasePayload[];
  }, [
    bookingData,
    mode,
    specificTime,
    selectedUser,
    updateAllGroup,
    initialData,
    selectedEngagement,
    getBookingPayload
  ]);

  return { getRequestPayload };
};
