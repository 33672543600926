export enum PermissionRule {
  AcceptForEndUser = "accept_for_end_user",
  AddYourselfAsOwner = "add_yourself_as_owner",
  AuditAdmin = "audit_admin",
  AuditApprover = "audit_approver",
  AuditLog = "audit_log",
  AuditResourcer = "audit_resourcer",
  BookingAdmin = "booking_admin",
  BookingApprover = "booking_approver",
  Reporter = "reporter",
  BookingResourcer = "booking_resourcer",
  BookingAccess = "booking_module_access",
  BulkBookingsOperations = "bulk_bookings_operations",
  ContactForm = "contact_form",
  EngagementEconomicsCreateScenarios = "engagement_economics_create_scenarios",
  EngagementEconomicsManage = "engagement_economics_manage",
  ChangeMarketplaceRequirements = "marketplace_requirements_visibility",
  ExportDocuments = "export_documents",
  FillNamedResource = "fill_named_resource",
  FilterByWorkforceMember = "filter_by_workforce_member",
  ImportExportRoles = "import_export_roles",
  Insights = "insights",
  PermissionsGroupsManagement = "permissions_groups_management",
  ProfessionalProfileEdit = "professional_profile_edit",
  ProfileList = "profile_list",
  ProjectManager = "project_manager",
  Resourcer = "resourcer",
  RoleRemoval = "role_removal",
  SeniorPartner = "senior_partner",
  ShortlistWithoutOwnership = "shortlist_without_ownership",
  TalentMarketplace = "talent_marketplace",
  TaskList = "task_list",
  UserManager = "user_manager",
  WallPlannerAccess = "wall_planner_access",
  ReassignBookings = "reassign_bookings",
  IntegrationUser = "integration_user"
}
