import SectionBox from "PFComponents/box/section_box";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import css from "./page_section.module.scss";

const PageSection = ({
  title,
  scrollKey,
  hType,
  children,
  action,
  actionWrapStyle,
  id,
  linkTo,
  tags,
  contentStyle,
  style,
  className,
  titleAction,
  header,
  footer
}) => {
  const HTag = hType;

  const HeaderContent = () => {
    if (header) {
      return header;
    }

    return (
      <>
        {(title || action || titleAction) && title && (
          <HTag className={css.title}>
            {tags}
            {linkTo ? <Link to={linkTo}>{title}</Link> : title}
          </HTag>
        )}
        {titleAction}
        {action && (
          <div style={actionWrapStyle} className={css.actionWrap}>
            {action}
          </div>
        )}
      </>
    );
  };

  return (
    <SectionBox className={className} style={style}>
      <div data-scroll-key={scrollKey} className={css.root} id={id}>
        <div className={css.header}>
          <HeaderContent />
        </div>
        <div className={css.content} style={contentStyle}>
          <div className={css.contentInner}>{children}</div>
        </div>
        {footer && <div className={css.footer}>{footer}</div>}
      </div>
    </SectionBox>
  );
};

PageSection.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  scrollKey: PropTypes.string,
  hType: PropTypes.string,
  children: PropTypes.node.isRequired,
  action: PropTypes.node,
  id: PropTypes.string,
  linkTo: PropTypes.string,
  tags: PropTypes.element,
  actionWrapStyle: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object,
  contentStyle: PropTypes.object,
  titleAction: PropTypes.node,
  header: PropTypes.node,
  footer: PropTypes.node
};

PageSection.defaultProps = {
  hType: "h3",
  title: ""
};

export default PageSection;
