import { ReadOnlyQuestion } from "PFApp/activities/show/activity_page/parts/tabs/feedback/form/parts/questions/question";
import { FeedbackForProfile } from "PFTypes/activity_feedback";
import { useTranslation } from "react-i18next";

import { useLastFeedback } from "../hooks/use_last_feedback";
import { FeedbackSkillsChart } from "./charts/feedback_skills/feedback_skills_chart";
import { InfoSection } from "./internal_experience_component";
import css from "./section_experience.modules.scss";

type InternalExperienceFeedbackProps = {
  roleId: number;
  profileId: number;
};

type FeedbackRowContentProps = {
  feedback: FeedbackForProfile;
};

const FeedbackRowContent = ({ feedback }: FeedbackRowContentProps) => (
  <div className={css.feedbackAnswers}>
    {feedback.questions?.map((question) => (
      <ReadOnlyQuestion
        key={`${question.id}-${feedback.id}`}
        question={question}
        answer={feedback.answers?.find(({ questionId }) => questionId === question.id)?.value}
      />
    ))}
  </div>
);

export const InternalExperienceFeedback = ({ roleId, profileId }: InternalExperienceFeedbackProps) => {
  const { lastFeedback } = useLastFeedback(roleId, profileId);
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts.experienceFeedback" });

  if (!lastFeedback) {
    return null;
  }

  return (
    <>
      {lastFeedback.questions && lastFeedback.answers && (
        <InfoSection label={t("managerFeedback")} content={<FeedbackRowContent feedback={lastFeedback} />} />
      )}
      <div className={css.feedbackChartContainer}>
        <InfoSection
          label={t("skillChart")}
          content={<FeedbackSkillsChart managerFeedback={lastFeedback} />}
        />
      </div>
    </>
  );
};
