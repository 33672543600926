// MultiPage is supposed to be used in tandem with PageTabs

import classNames from "classnames";
import PropTypes from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";

import css from "./multi_page.less";

const MultiPage = ({ items, active, children }) => {
  const timeoutId = useRef();
  const [visible, setVisible] = useState(false);
  const page = useRef(active);

  const index = useMemo(() => {
    const ids = items.map((it) => it.id);
    return Math.max(0, ids.indexOf(page.current));
  }, [page.current, items]);

  useEffect(() => {
    setVisible(false);
    timeoutId.current = window.setTimeout(() => {
      page.current = active;
      setVisible(true);
    }, 100); // match css
    return () => window.clearTimeout(timeoutId.current);
  }, [active]);

  return <div className={classNames(css.child, { [css.visible]: visible })}>{children[index]}</div>;
};

MultiPage.propTypes = {
  children: PropTypes.node.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string
    })
  ).isRequired,
  active: PropTypes.string
};

export default MultiPage;
