import { uniqBy } from "lodash";
import {
  getActivityCustomFieldFilteredValues,
  getActivityCustomFieldValues
} from "PFCore/helpers/activity_custom_fields";
import { CustomValue, Weight } from "PFTypes";

import { useMatchCardContext } from "../match_card_context";

export const useRoleMatchCustomFieldData = () => {
  const { custom_values: customValues, role, shortlist } = useMatchCardContext();

  const applicableCustomValues = shortlist?.profile?.applicable_custom_values || [];

  const getCustomFieldValuesOfWeight = (customFieldName: string, weight?: Weight) => {
    const roleCustomValues =
      weight === undefined
        ? getActivityCustomFieldValues(role, customFieldName)
        : getActivityCustomFieldFilteredValues(role, customFieldName, { weight });

    const matchCustomValues = uniqBy([...customValues.direct, ...applicableCustomValues], "id").filter(
      ({ id: matchedCustomValueId }) =>
        roleCustomValues.findIndex(({ id }) => id === matchedCustomValueId) >= 0
    ) as CustomValue[];

    const missingMatchCustomValues = roleCustomValues.filter(
      ({ id: roleCustomValueId }) => matchCustomValues.findIndex(({ id }) => id === roleCustomValueId) < 0
    );

    return {
      roleCustomValues,
      matchCustomValues,
      missingMatchCustomValues
    };
  };

  return {
    getCustomFieldValuesOfWeight
  };
};
