import { orderBy, take } from "lodash";
import { SkillRadial } from "PFApp/components/skill_radial";
import SkillDetailsTooltipContent from "PFComponents/badges_list/skill_details_tooltip_content";
import { Button } from "PFComponents/button";
import Modal from "PFComponents/modal/modal";
import { Divider } from "PFCore/components/divider";
import { isRankable } from "PFCore/helpers/custom_type";
import { ownProfileCustomTypesAccessLevels } from "PFCore/helpers/custom_types";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { AccessLevel, CustomValue, Id, PureCustomValue } from "PFTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./skills_list.module.scss";
import { useSkillsVisibility } from "./use_skills_visibility";

type SkillsListProps = {
  customFieldName: string;
  availableSkills: CustomValue[];
  missingSkills?: PureCustomValue[];
  profileId: Id;
  limit?: number;
  skillTruncateLength?: number;
  onMoreLessButtonClick?: VoidFunction;
};

const SkillsDivider = () => (
  <Divider type="vertical" color="paletteNeutral1" theme={{ divider: css.divider }} />
);

// TODO: [PROF-7377] Change naming from Skills -> CustomValues
export const SkillsList = ({
  customFieldName,
  availableSkills,
  missingSkills = [],
  profileId,
  limit = 8,
  skillTruncateLength,
  onMoreLessButtonClick
}: SkillsListProps): JSX.Element => {
  const { t } = useTranslation("core");
  const { data: currentProfile } = useCurrentProfile();
  const { customTypes } = useCustomTypes();

  const [modalSkill, setModalSkill] = useState<CustomValue | null>(null);
  const sortedAvailableSkills = orderBy(
    availableSkills,
    [({ top }) => Boolean(top), "value"],
    ["desc", "asc"]
  );

  const sortedMissingSkills = orderBy(missingSkills, "value");

  const {
    visibleAvailableSkillsCount,
    visibleMissingSkillsCount,
    showAll,
    displayShowMoreButton,
    toggleShowAll
  } = useSkillsVisibility({
    availableSkillsCount: sortedAvailableSkills.length,
    missingSkillsCount: sortedMissingSkills.length,
    limit
  });

  const handleMoreLessButtonClick = () => {
    toggleShowAll();
    onMoreLessButtonClick?.();
  };

  const customType = customTypes.find(({ name }) => name === customFieldName);
  const isCustomTypeRankable = Boolean(customType && isRankable(customType));
  const canEditExperience =
    customType &&
    currentProfile.id === profileId &&
    ownProfileCustomTypesAccessLevels(currentProfile)?.[customType.id] === AccessLevel.ReadWrite &&
    isCustomTypeRankable;

  return (
    <>
      <div className={css.skills}>
        {take(sortedAvailableSkills, visibleAvailableSkillsCount).map((skill, index) => {
          const isNotLastSkill = index < visibleAvailableSkillsCount - 1;
          const needToSeparateMissingSkillsList =
            index === visibleAvailableSkillsCount - 1 && visibleMissingSkillsCount > 0;

          return (
            <span className={css.skillWithDivider} key={skill.id}>
              <SkillRadial
                name={String(skill.value)}
                experience={skill.experience}
                expectedExperience={null}
                isCore={skill.top}
                truncateLength={skillTruncateLength}
                isRankable={isCustomTypeRankable}
                onClick={() => {
                  setModalSkill(skill);
                }}
              />
              {(isNotLastSkill || needToSeparateMissingSkillsList) && <SkillsDivider />}
            </span>
          );
        })}
        {take(sortedMissingSkills, visibleMissingSkillsCount).map((skill, index) => {
          const isNotLastSkill = index < visibleMissingSkillsCount - 1;

          return (
            <span className={css.skillWithDivider} key={skill.id}>
              <SkillRadial
                name={String(skill.text)}
                expectedExperience={null}
                truncateLength={skillTruncateLength}
                isRankable={isCustomTypeRankable}
                isMissing
              />
              {isNotLastSkill && <SkillsDivider />}
            </span>
          );
        })}
        {displayShowMoreButton && (
          <Button
            kind="text"
            onClick={(event) => {
              event.stopPropagation();
              handleMoreLessButtonClick();
            }}
            className={css.showMoreButton}
          >
            {showAll ? t("viewLess") : t("viewMore")}
          </Button>
        )}
      </div>

      {/* This modal was copied from CustomValuesList.
          Most likely it will be replaced with other design in the future */}
      {!!modalSkill && (
        // @ts-ignore
        <Modal
          closeOnBackdropClick
          handleClose={() => setModalSkill(null)}
          classes={{
            dialog: css.skillDetailsDialog
          }}
        >
          <SkillDetailsTooltipContent
            badgeProps={{
              ...modalSkill,
              rankable: isCustomTypeRankable
            }}
            profileId={profileId}
            currentProfile={currentProfile}
            isEditMode={canEditExperience}
            close={() => setModalSkill(null)}
          />
        </Modal>
      )}
    </>
  );
};
