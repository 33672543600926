import classNames from "classnames";
import { Card } from "PFComponents/card";

import css from "./accomplishment_card.module.scss";

const AccomplishmentCard = ({ children, className }: { children: any; className?: string }) => (
  <Card className={classNames(css.container, className)}>{children}</Card>
);

export default AccomplishmentCard;
