import { debounce, map } from "lodash";
import AppContext from "PFApp/app_context";
import ChatController from "PFApp/components/chat/chat_controller";
import { ChatList } from "PFApp/components/chat/chat_list";
import { NORMAL } from "PFApp/components/chat/chat_states";
import { fetchNotifications } from "PFCore/services/notifications/fetch_notifications";
import { CHAT_ACTIONS } from "PFReducers/chat_reducer";
import { Component } from "react";

import css from "./chats.less";

const EXPANDED_WIDTH = 500;
const REGULAR_WIDTH = 320;
const MINIMIZED_WIDTH = 320;
const LIST_WIDTH = 320;
const LIST_MINIMIZED_WIDTH = 50;
const CHAT_MINIMIZED_STORAGE_KEY = "chat__list_minimized";

export default class Chats extends Component {
  state = {
    counter: 0
  };

  constructor(props) {
    super(props);

    window.addEventListener(
      "resize",
      debounce(() => this.forceUpdate(), 500)
    );

    this.refetch();
  }

  componentDidMount() {
    const savedChatListMinimized = storage.getItem(CHAT_MINIMIZED_STORAGE_KEY);
    const listMinimized = window.innerWidth < 480 || savedChatListMinimized;

    if (!listMinimized) {
      this.handleUnMinimize("list");
    }
  }

  refetch = () => {
    fetchNotifications({ targetTypes: ["Chat::Conversation"] }).then(({ meta }) => {
      this.setState({ counter: meta ? meta.totalUnread : 0 });
    });
  };

  handleMinimize = (id) => {
    const { dispatch } = this.context;
    if (id === "list") {
      storage.setItem(CHAT_MINIMIZED_STORAGE_KEY, true);
    }

    dispatch({ type: CHAT_ACTIONS.CHAT_MINIMIZE, payload: { id } });
  };

  handleUnMinimize = (id) => {
    const { dispatch } = this.context;

    if (id === "list") {
      storage.setItem(CHAT_MINIMIZED_STORAGE_KEY, false);
    }

    dispatch({ type: CHAT_ACTIONS.CHAT_UNMINIMIZE, payload: { id } });
  };

  handleExpand = (id, value) => {
    const { dispatch } = this.context;

    if (value) {
      dispatch({ type: CHAT_ACTIONS.CHAT_EXPAND, payload: { id } });
    } else {
      dispatch({ type: CHAT_ACTIONS.CHAT_UNEXPAND, payload: { id } });
    }
  };

  handleSelect = (id) => {
    const { dispatch } = this.context;

    dispatch({ type: CHAT_ACTIONS.CHAT_SELECT, payload: { id } });
  };

  handleOpenConversation = (id, options = {}) => {
    const { dispatch } = this.context;

    dispatch({ type: CHAT_ACTIONS.CHAT_RENDER, payload: { id, minimized: options.minimized } });
  };

  handleClose = (id) => {
    const { dispatch } = this.context;

    dispatch({ type: CHAT_ACTIONS.CHAT_UNRENDER, payload: { id } });
  };

  render() {
    const { counter } = this.state;
    const {
      chats: { ids, expandedId, minimizedIds, selectedId }
    } = this.context.store;

    const listWidth = minimizedIds.indexOf("list") !== -1 ? LIST_MINIMIZED_WIDTH : LIST_WIDTH;
    let offset = listWidth + 10;
    let offsets = [];

    // hangouts-like stacking. "topmost" at top and z-index the lower the further it's from it.
    const topmostIndex = ids.indexOf(selectedId);
    const anyTopmostId = topmostIndex > -1;

    if (!window.matchMedia("(max-width: 560px)").matches) {
      ids.forEach((id) => {
        offsets.push(offset);
        if (minimizedIds[id]) {
          offset += MINIMIZED_WIDTH + 10;
        } else if (expandedId === id) {
          offset += EXPANDED_WIDTH + 10;
        } else {
          offset += REGULAR_WIDTH + 10;
        }
      });

      const maxOffset = window.innerWidth - REGULAR_WIDTH;
      if (offset > maxOffset) {
        const mod = (maxOffset - offset) / ids.length;
        offsets = offsets.map((off, i) => Math.max(0, off + mod * i));
      }
    }

    return (
      <div className={css.root}>
        {
          <ChatList
            chatState={NORMAL}
            counter={counter}
            emptyMessage={"Hello"}
            forceUpdateAll={() => this.forceUpdate()}
            minimized={minimizedIds.indexOf("list") !== -1}
            openConversationIds={ids}
            onOpenConversation={this.handleOpenConversation}
            style={{
              right: 0,
              bottom: 0,
              position: "absolute",
              width: listWidth,
              zIndex: 10
            }}
            onMinimize={this.handleMinimize}
            onUnMinimize={this.handleUnMinimize}
            onClose={this.handleClose}
            onClearNotifications={this.refetch}
          />
        }
        {map(ids, (id, index) => (
          <ChatController
            key={id}
            style={{
              right: `${offsets[index] || 0}px`,
              bottom: 0,
              position: "absolute",
              width:
                minimizedIds.indexOf(id) !== -1
                  ? MINIMIZED_WIDTH
                  : expandedId === id
                  ? EXPANDED_WIDTH
                  : REGULAR_WIDTH,
              zIndex: 10 + (anyTopmostId ? ids.length - Math.abs(index - topmostIndex) : 0)
            }}
            conversationId={id}
            selected={selectedId === id}
            expandable={!window.matchMedia("(max-width: 560px)").matches}
            expanded={expandedId === id}
            isBelow={anyTopmostId ? topmostIndex > index : true}
            onMinimize={this.handleMinimize}
            onExpand={this.handleExpand}
            onUnMinimize={this.handleUnMinimize}
            onSelect={this.handleSelect}
            onClose={this.handleClose}
            onClearNotifications={this.refetch}
            minimized={minimizedIds.indexOf(id) !== -1}
            forceUpdateAll={() => this.forceUpdate()}
          />
        ))}
      </div>
    );
  }
}

Chats.contextType = AppContext;
