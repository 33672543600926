import classNames from "classnames";
import AppContext from "PFApp/app_context";
import useChat from "PFApp/use_chat";
import { Button } from "PFComponents/button";
import ProfileAvatar from "PFComponents/profile_avatar/profile_avatar";
import { isMsTeams } from "PFCore/helpers/ms_teams";
import useForceUpdate from "PFCore/helpers/use_force_update";
import { PROFILE_PREVIEW_ACTIONS } from "PFReducers/profile_preview_reducer";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./activity_details_main_chat.less";

const LIMIT = 8;

const ActivityDetailsMainChat = ({ primaryConversation, allParticipants, isEngagement }) => {
  const { renderChat } = useChat();
  const { t } = useTranslation("activities");
  const { dispatch } = useContext(AppContext);

  const isLoading = allParticipants == null; // eslint-disable-line eqeqeq

  const forceUpdate = useForceUpdate();

  const handleOpenGroupConversation = () => {
    if (isMsTeams()) {
      window.open(window.location.href);
    } else if (primaryConversation) {
      renderChat(primaryConversation.id, { expanded: true });
      primaryConversation.unread_count = 0;
      forceUpdate();
    }
  };

  const [revealed, setRevealed] = useState(false);
  const limit = LIMIT + ((allParticipants && allParticipants.length) === LIMIT + 1 ? 1 : 0);
  const participantsShown = allParticipants && (revealed ? allParticipants : allParticipants.slice(0, limit));
  const diff = allParticipants && allParticipants.length - participantsShown.length;

  return (
    <div
      className={classNames(css.wrapper, { [css.wideWrapper]: isEngagement })}
      data-qa-id="ActivityDetailsMainChat"
    >
      <div
        className={classNames(css.avatars, {
          [css.wideAvatar]: isEngagement,
          [css.noAvatar]: participantsShown?.length === 0
        })}
      >
        {!isLoading && (
          <>
            {participantsShown &&
              participantsShown.map((participant) => (
                <div key={participant.id} className={css.avatarWrap}>
                  <ProfileAvatar
                    profile={participant.profile}
                    href={participant.profile.viewable ? `/profiles/${participant.profile.id}` : null}
                    onClick={
                      participant.profile.viewable &&
                      ((event) => {
                        event.preventDefault();
                        const { profile } = participant;
                        dispatch({
                          type: PROFILE_PREVIEW_ACTIONS.PROFILE_PREVIEW_SHOW,
                          payload: { id: profile.id, profile }
                        });
                      })
                    }
                    size={40}
                  />
                </div>
              ))}
            {diff > 0 && (
              <div className={css.avatarWrap}>
                <Button type="text" className={css.more} onClick={() => setRevealed(true)}>
                  <span>{`+${diff} `}</span>
                  {t("show.details.viewMore")}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
      <div className={css.openGroupChatBtnWrap}>
        <Button
          text={t("show.details.joinIn")}
          kind="secondary"
          onClick={handleOpenGroupConversation}
          style={{ marginTop: 10 }}
        />
      </div>
    </div>
  );
};

ActivityDetailsMainChat.propTypes = {
  allParticipants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      profile: PropTypes.object.isRequired
    })
  ),
  primaryConversation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    kind: PropTypes.oneOf(["primary", "normal"]).isRequired,
    unread_count: PropTypes.number
  }),
  isEngagement: PropTypes.bool
};

export default ActivityDetailsMainChat;
