import { Icon } from "PFComponents/icon";
import Toggle from "PFComponents/toggle/toggle";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import css from "./override_toggles.module.scss";
import { OvercheduleDaysTooltip } from "./overschedule_days_tooltip";
import { OverscheduleTimeTooltip } from "./overschedule_time_tooltip";

interface OverrideTogglesProps {
  value: {
    overridesCalendar: boolean;
    overridesNonWorkingDays: boolean;
  };
  onOverridesCalendarChange: (enabled: boolean) => void;
  onOverridesNonWorkingDaysChange: (enabled: boolean) => void;
  shouldBeVisible: boolean;
  isOverridesNonWorkingDaysDisabled: boolean;
}

export const OverrideToggles = ({
  value: { overridesCalendar, overridesNonWorkingDays },
  onOverridesCalendarChange,
  onOverridesNonWorkingDaysChange,
  shouldBeVisible,
  isOverridesNonWorkingDaysDisabled
}: OverrideTogglesProps) => {
  const { t } = useTranslation("bookingModule", { keyPrefix: "bookings" });

  useEffect(() => {
    if (isOverridesNonWorkingDaysDisabled) {
      onOverridesNonWorkingDaysChange(false);
    }
  }, [isOverridesNonWorkingDaysDisabled]);

  return (
    <>
      {shouldBeVisible && (
        <div className={css.container}>
          <OverscheduleTimeTooltip>
            <div className={css.toggleRow}>
              <Icon name="calendar-clash" />
              <Toggle
                reversed
                description={t("overscheduleTime")}
                checked={overridesCalendar}
                onChange={onOverridesCalendarChange}
              />
            </div>
          </OverscheduleTimeTooltip>
          <OvercheduleDaysTooltip isToggleDisabled={isOverridesNonWorkingDaysDisabled}>
            <div className={css.toggleRow}>
              <Icon name="calendar-clash" />
              <Toggle
                reversed
                description={t("overscheduleDays")}
                checked={overridesNonWorkingDays}
                onChange={onOverridesNonWorkingDaysChange}
                disabled={isOverridesNonWorkingDaysDisabled}
              />
            </div>
          </OvercheduleDaysTooltip>
        </div>
      )}
    </>
  );
};
