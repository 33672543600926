import { useGrowl } from "PFApp/use_growl";
import { RouterPrompt } from "PFComponents/router_prompt/router_prompt";
import { useCertificateCreate } from "PFCore/hooks/queries/certificates";
import { FetchUrlMetadataResponse } from "PFCore/services/common";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import UploadAccomplishment, { UploadPayload } from "../upload_accomplishment";
import EditCertificateForm from "./edit_certificate_form";

type NewCertificateProps = {
  onClose: () => void;
  fetchProfile: () => void;
};

const NewCertificate = ({ onClose, fetchProfile }: NewCertificateProps) => {
  const [uploadPayload, setUploadPayload] = useState<UploadPayload | null>({});
  const [urlRetrievedData, setUrlRetrievedData] = useState<FetchUrlMetadataResponse | null>(null);
  const [formChanged, setFormChanged] = useState(false);

  const { t } = useTranslation(["profiles", "translation"]);

  const growl = useGrowl();

  const createCertificateMutation = useCertificateCreate({
    onError: () => {
      growl({
        message: t("profiles:common.certificates.growl.formError"),
        kind: "error"
      });
    },
    onSuccess: () => {
      growl({
        message: t("profiles:common.certificates.growl.added"),
        kind: "success"
      });
      fetchProfile();
      onClose();
    }
  });

  const handleSubmit = (payload) => {
    setFormChanged(false);

    const data = { ...uploadPayload, ...payload };
    createCertificateMutation.mutate(data);
  };

  const handleFormChange = (isChanged: boolean) => setFormChanged(isChanged);

  const errors = (createCertificateMutation.error as JQuery.jqXHR)?.responseJSON?.errors;

  return (
    <div>
      <RouterPrompt when={formChanged} />
      <UploadAccomplishment
        setUploadPayload={setUploadPayload}
        setUrlRetrievedData={setUrlRetrievedData}
        errors={errors}
      />
      <EditCertificateForm
        onSubmit={handleSubmit}
        onClose={onClose}
        urlRetrievedData={urlRetrievedData}
        errors={errors}
        isSubmitting={createCertificateMutation.isLoading}
        onFormChange={handleFormChange}
      />
    </div>
  );
};

export default NewCertificate;
