import ConfirmModal from "PFComponents/confirm/confirm_modal";
import WarningIcon from "PFIcons/warning.svg";
import { useTranslation } from "react-i18next";

import { BookingSelectOption } from "./booking_questions";
import { GroupBookingsQuestions, GroupBookingsQuestionsMode } from "./group_bookings_questions";
import { SpecificTimeBookingsQuestions } from "./specific_time_bookings_questions";
import css from "./update_questions_modal.module.scss";

type UpdateBookingModalProps = {
  profileFullName: string;
  onClose?: () => void;
  onSubmit: () => Promise<void>;
  updateGroupOptionSelected: BookingSelectOption;
  setUpdateGroupOptionSelected: (option: BookingSelectOption) => void;
  updateRepeatOptionSelected: BookingSelectOption;
  setUpdateRepeatOptionSelected: (option: BookingSelectOption) => void;
  isRepeated: boolean;
  bookingGroupId?: number;
};

const UpdateQuestionsModal = ({
  profileFullName,
  onClose,
  onSubmit,
  updateGroupOptionSelected,
  setUpdateGroupOptionSelected,
  updateRepeatOptionSelected,
  setUpdateRepeatOptionSelected,
  isRepeated,
  bookingGroupId
}: UpdateBookingModalProps): JSX.Element => {
  const { t } = useTranslation(["bookingModule", "translation"]);
  const isGrouped = Number.isInteger(bookingGroupId);

  return (
    <ConfirmModal
      title={
        <div className={css.title}>
          <WarningIcon className={css.warningIcon} width={30} height={30} />
          {t("bookingModule:bookings.update.editBooking")}
        </div>
      }
      labelOK={t("translation:continue")}
      handleOK={onSubmit}
      handleClose={onClose}
    >
      <div>
        {isGrouped && (
          <GroupBookingsQuestions
            mode={GroupBookingsQuestionsMode.Update}
            bookingGroupId={bookingGroupId!}
            value={updateGroupOptionSelected}
            onChange={setUpdateGroupOptionSelected}
            userName={profileFullName}
          />
        )}
        {isRepeated && (
          <SpecificTimeBookingsQuestions
            label={t("bookingModule:bookings.update.body.question")}
            isEditMode
            value={updateRepeatOptionSelected}
            onChange={setUpdateRepeatOptionSelected}
          />
        )}
      </div>
    </ConfirmModal>
  );
};

export default UpdateQuestionsModal;
