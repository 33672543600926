import { DEFAULT_SKILL_EXPERIENCE } from "PFTypes";

import { ComponentProps } from "../../activities/edit/sections/field_wrappers/component_prop_types";
import { CustomValuesEditField, CustomValuesEditFieldProps } from "../custom_values_edit_field";
import { SelectedSkills } from "./parts/selected_skills";

export type SkillsSelectProps = Omit<CustomValuesEditFieldProps, "errors" | "handleChange"> &
  Pick<ComponentProps, "label" | "qaId" | "required" | "error"> & {
    onUpdate: CustomValuesEditFieldProps["handleChange"];
    property?: ComponentProps["property"];
    withProficiency?: boolean;
  };

export const SkillsSelect = ({
  values,
  property,
  qaId,
  error,
  onUpdate,
  adminPage,
  withProficiency,
  ...props
}: SkillsSelectProps) => {
  const handleUpdate: CustomValuesEditFieldProps["handleChange"] = (updatedSkills) => {
    onUpdate(
      updatedSkills.map((skill) => ({ ...skill, experience: skill.experience || DEFAULT_SKILL_EXPERIENCE }))
    );
  };

  return (
    <CustomValuesEditField
      {...props}
      handleChange={handleUpdate}
      values={values}
      tip=""
      adminPage={adminPage || false}
      multi={!property || property.kind === "multiple"}
      qaIdPrefix={qaId}
      errors={error}
      hasValuesWithExpiryDate={false}
      displayValuesBelow={withProficiency}
      displayValues={
        withProficiency && values.length > 0 ? (
          <SelectedSkills values={values} handleChange={handleUpdate} />
        ) : undefined
      }
    />
  );
};
