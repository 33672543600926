import Calendar from "PFComponents/calendar/calendar";
import ConfirmModal from "PFComponents/confirm/confirm_modal";
import { Select } from "PFComponents/select/select";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { PostUntilChangeReason, Template } from "PFTypes";
import { useMemo } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { OTHER_REASON_KEY } from "./utils";

export type PostUntilData = {
  date: string;
  reason: PostUntilChangeReason;
};

type ReasonsForm = {
  date: string;
  reason: string;
  otherReason?: string;
};

type ExpiryDateModalProps = {
  date: string;
  reason: PostUntilChangeReason | null;
  template: Template;
  onChange: (value: PostUntilData) => void;
  onClose: () => void;
};

export const ExpiryDateModal = ({ date, reason, template, onChange, onClose }: ExpiryDateModalProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "edit.sections.expiryDateSection.modal" });
  const { formatISODate } = useDateFormatter();

  const { control, handleSubmit, watch } = useForm<ReasonsForm>({
    defaultValues: {
      date: formatISODate(date),
      reason: reason?.key ?? "",
      otherReason: reason?.key === OTHER_REASON_KEY ? reason?.text : ""
    }
  });
  const [watchedReason, watchedOtherReason] = watch(["reason", "otherReason"]);
  const isOtherReason = watchedReason === OTHER_REASON_KEY;
  const reasonsList = template?.localized_post_until_change_reasons ?? [];

  const dropdownOptions = useMemo(
    () =>
      reasonsList.map(({ key, text }) => ({
        id: key,
        displayElement: key === OTHER_REASON_KEY ? t("other") : text,
        item: key
      })),
    [reasonsList]
  );
  const handleSave: SubmitHandler<ReasonsForm> = (formData) => {
    const { date, reason, otherReason } = formData;
    const text =
      reason !== OTHER_REASON_KEY ? reasonsList.find(({ key }) => key === reason)?.text : otherReason;

    if (text) {
      const postUntilData: PostUntilData = {
        date,
        reason: {
          key: reason,
          text
        }
      };
      onChange(postUntilData);
    }

    onClose();
  };

  const shouldEnableSave =
    (watchedReason && watchedReason !== OTHER_REASON_KEY) ||
    (watchedReason === OTHER_REASON_KEY && watchedOtherReason);

  return (
    <ConfirmModal
      title={t("title")}
      labelOK={t("changeAction")}
      handleOK={shouldEnableSave ? handleSubmit(handleSave) : undefined}
      handleClose={onClose}
    >
      <Controller
        control={control}
        name={"reason"}
        render={({ field: { onChange, value } }) => (
          <Select
            label={t("reasonLabel")}
            value={dropdownOptions.find(({ id }) => id === value)?.displayElement}
            controlledValue
            options={dropdownOptions}
            onChange={onChange}
            selectedIndex={dropdownOptions.findIndex((option) => option.id === value)}
          />
        )}
      />
      {isOtherReason && (
        <Controller
          control={control}
          name={"otherReason"}
          render={({ field: { onChange, value } }) => (
            <InputFieldSet
              value={value}
              inputType="textarea"
              maxLength={200}
              required={true}
              qaId="otherReasonInput"
              onChange={onChange}
            />
          )}
        />
      )}
      <Controller
        control={control}
        rules={{ required: true }}
        name={"date"}
        render={({ field: { onChange, value } }) => <Calendar selectedDate={value} handleChange={onChange} />}
      />
    </ConfirmModal>
  );
};
