import Color from "color";
import { filter, isEmpty } from "lodash";
import colors from "PFTheme/tokens/colors";
import { EconomicsScenarioDetails } from "PFTypes";
import { useTranslation } from "react-i18next";

import { RadioChart } from "./radio_chart";

const MAX_SKILLS_AMOUNT = 20;

type SkillsChartProps = {
  skillsRadarData: EconomicsScenarioDetails["skillsRadarData"];
  skillsCoveredPercentage: number;
};

export const SkillsChart = ({ skillsRadarData = [], skillsCoveredPercentage }: SkillsChartProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.economics.scenario.charts.skills" });

  const nonRequiredSkills = skillsRadarData
    .filter(({ requiredExperience }) => !!requiredExperience)
    .slice(0, MAX_SKILLS_AMOUNT);

  const indicator = nonRequiredSkills.map(({ skill }) => ({ text: skill.value }));

  const skillsData = filter(
    [
      {
        value: nonRequiredSkills.map(({ requiredExperience }) => requiredExperience || 0),
        name: t("requested"),
        areaStyle: {
          color: Color(colors.paletteEcharts0).fade(0.7).toString()
        }
      },
      {
        value: nonRequiredSkills.map(({ possessedExperience }) => possessedExperience || 0),
        name: t("filled"),
        areaStyle: {
          color: Color(colors.paletteEcharts1).fade(0.7).toString()
        }
      }
    ],
    ({ value }) => !isEmpty(value)
  );

  if (!skillsData.length) {
    return null;
  }

  return (
    <RadioChart
      title={t("title")}
      coverage={skillsCoveredPercentage}
      data={skillsData}
      indicator={indicator}
      color={[colors.paletteEcharts0, colors.paletteEcharts1]}
    />
  );
};
