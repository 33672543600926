import ConfirmModal from "PFComponents/confirm/confirm_modal";
import { Typography } from "PFComponents/typography";
import { useActivityEconomicsScenarioDelete } from "PFCore/hooks/queries/activity";
import { Id } from "PFTypes";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useGrowl } from "../../../../use_growl";

type RemoveScenarioModalProps = {
  scenarioId: Id;
  activityId: Id;
  onRemove?: () => void;
  onClose?: () => void;
};

export const RemoveScenarioModal = ({
  scenarioId,
  activityId,
  onClose,
  onRemove
}: RemoveScenarioModalProps) => {
  const growl = useGrowl();
  const { t } = useTranslation("activities", { keyPrefix: "show.economics.removeScenarioModal" });
  const { t: defaultT } = useTranslation("translation");

  const { mutate: deleteScenario } = useActivityEconomicsScenarioDelete(activityId);

  const handleDelete = useCallback(() => {
    deleteScenario(scenarioId, {
      onSuccess: () => {
        growl({
          kind: "success",
          message: t("deleteSuccess")
        });

        onRemove?.();
      },
      onError: () => {
        growl({
          kind: "error",
          message: t("deleteError")
        });
      }
    });
  }, [deleteScenario, growl, onRemove, scenarioId, t]);

  return (
    <ConfirmModal
      title={t("title")}
      handleOK={handleDelete}
      handleClose={onClose}
      handleCancel={onClose}
      autoHandleClose={false}
      labelOK={defaultT("remove")}
      kindOK="danger"
    >
      <Typography variant="bodyRegular" noMargin>
        {t("body")}
      </Typography>
    </ConfirmModal>
  );
};
