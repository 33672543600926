import classNames from "classnames";
import { Id } from "PFTypes";
import { useEffect, useState } from "react";

import { PillTab } from "../tabs/pill_tabs";
import { SidePanelBody } from "./body";
import { SidePanelFooter } from "./footer";
import { DefaultFooter } from "./footer/default_footer";
import { SidePanelHeader } from "./header";
import { getHeaderOffset } from "./header/side_panel_header.utils";
import css from "./side_panel.module.scss";
import { useSidePanelClose } from "./use_side_panel_close";
import { useSidePanelScroll } from "./use_side_panel_scroll";
import { useSidePanelTabs } from "./use_side_panel_tabs";

const BASE_Z_INDEX = 610;

type Size = "default" | "middle" | "large";

export type SidePanelTabs<TAB_ID extends Id> = {
  tabs: PillTab<TAB_ID>[];
  defaultSelectedTabId: TAB_ID;
  tabsContent: Record<TAB_ID, React.ReactNode>;
};

type FooterRendererArgs = {
  onSidePanelClose: () => void;
};

export type SidePanelProps<TAB_ID extends Id> = React.PropsWithChildren<{
  title?: string | React.ReactNode;
  actions?: React.ReactNode;
  show: boolean;
  size?: Size;
  onClose?: VoidFunction;
  classes?: Partial<{ show: string }>;
  fullHeight?: boolean;
  footerRenderer?: (args: FooterRendererArgs) => React.ReactNode;
  tabsConfig?: SidePanelTabs<TAB_ID>;
  isOnTop?: boolean;
  zIndex?: number;
  isSidePanelClosing?: boolean;
  noOverflow?: boolean;
}>;

const SidePanel = <TAB_ID extends Id>({
  title,
  actions,
  show,
  children,
  onClose,
  size = "default",
  classes = {},
  fullHeight,
  footerRenderer,
  tabsConfig,
  isOnTop = true,
  zIndex = 0,
  isSidePanelClosing = false,
  noOverflow
}: SidePanelProps<TAB_ID>): JSX.Element | null => {
  const [footerHeight, setFooterHeight] = useState(0);
  const { isClosing, onSidePanelClose } = useSidePanelClose({
    onClose,
    isSidePanelClosing
  });
  const { internalTabsConfig, selectedTabId } = useSidePanelTabs({
    tabsConfig
  });
  const { bodyRef, footerContentRef, hasBorderShadow, hasScroll, handleScroll } = useSidePanelScroll({
    selectedTabId
  });

  useEffect(() => {
    setFooterHeight(footerContentRef.current?.offsetHeight ?? 0);
  }, [footerContentRef]);

  if (!show) {
    return null;
  }

  return (
    <div
      style={{ zIndex: BASE_Z_INDEX + zIndex }}
      className={classNames(
        css.root,
        isClosing ? css.closing : css.animateIn,
        { [css.sizeDefault]: size === "default" },
        { [css.sizeMiddle]: size === "middle" },
        { [css.sizeLarge]: size === "large" },
        { [css.fullHeight]: fullHeight },
        { [css.onTop]: isOnTop },
        { [css.noOverflow]: noOverflow },
        classes.show
      )}
    >
      <SidePanelHeader
        title={title}
        actions={actions}
        onClose={onSidePanelClose}
        tabsConfig={internalTabsConfig}
      />
      <SidePanelBody
        ref={bodyRef}
        maxHeight={`calc(100% - ${footerHeight + getHeaderOffset(!!tabsConfig)}px)`}
        onScroll={handleScroll}
        noOverflow={noOverflow}
      >
        {tabsConfig ? tabsConfig.tabsContent[selectedTabId] : children}
      </SidePanelBody>
      <SidePanelFooter
        ref={footerContentRef}
        hasBorderShadow={hasBorderShadow}
        hasDefaultFooter={!footerRenderer}
        hasScroll={hasScroll}
      >
        {footerRenderer ? footerRenderer({ onSidePanelClose }) : <DefaultFooter />}
      </SidePanelFooter>
    </div>
  );
};

export default SidePanel;
