import classNames from "classnames";
import { last } from "lodash";
import { ActionIcon } from "PFComponents/action_icon";
import BadgesList from "PFComponents/badges_list/badges_list";
import { Icon } from "PFComponents/icon";
import Pill from "PFComponents/pill/pill";
import { Typography } from "PFComponents/typography";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Certificate } from "PFTypes/certificate";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useProfileSectionContext } from "../../profile_section_context_provider";
import AccomplishmentCard from "../accomplishment_card";
import css from "./certificate_card.module.scss";

export const CertificateCard = ({
  certificate,
  onEditClick
}: {
  certificate: Certificate;
  onEditClick: () => void;
}) => {
  const { t } = useTranslation("profiles", { keyPrefix: "common.certificates" });
  const { isEditMode } = useProfileSectionContext();
  const { formatDate } = useDateFormatter();

  const [showImage, setShowImage] = useState(true);

  const { skills, source, issueDate, imageUrl, issuer, title, url } = certificate;

  const anySkills = skills.length > 0;

  const hasSource = !!source;
  const isEditable = !["credly", "imported"].includes(source || "") && isEditMode;

  return (
    <AccomplishmentCard>
      <div className={css.container}>
        <Typography variant="bodyRegular" tag="span">
          {formatDate(issueDate)}
        </Typography>
        <span className={css.imageWithEditButton}>
          {showImage && (
            <img
              alt="certificate"
              src={imageUrl || url}
              className={css.image}
              onError={() => setShowImage(false)}
            />
          )}
          {isEditable && <ActionIcon onClick={onEditClick} name="edit" size="sm" />}
        </span>
        <a className={css.url} target="_blank" rel="noopener noreferrer" href={url}>
          <Icon name="open" /> {t("viewCertificate")}
        </a>
        <div className={css.detailsContainer}>
          <div className={css.details}>
            <Typography variant="bodyBold" tag="span">
              {title}
            </Typography>
            <Typography variant="bodyRegular" tag="span">
              {issuer}
            </Typography>
            {anySkills && (
              <div className={classNames(css.skills, { [css.withSource]: hasSource })}>
                <Typography variant="labelRegular" tag="span">{`${t("skillsGained")}:`}</Typography>
                <BadgesList values={skills} />
              </div>
            )}
          </div>
        </div>
        {hasSource && (
          <Pill className={css.source}>
            {t(`source.${last(source.split("."))}` as unknown as TemplateStringsArray)}
          </Pill>
        )}
      </div>
    </AccomplishmentCard>
  );
};
