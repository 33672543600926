import DropDown from "PFComponents/dropdown/dropdown";
import { allowInvites, getTargetType } from "PFCore/helpers/conversation";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import TickedBoxIcon from "PFIcons/tickedbox.svg";
import UntickedBoxIcon from "PFIcons/untickedbox.svg";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import css from "./chat_menu.less";
import { getChatSendOnEnter } from "./send_on_enter";

const ChatMenu = ({
  conversation,
  showParticipants,
  targetUrl,
  handleChange,
  isFollowing,
  handleFollowingChange,
  setSendOnEnter,
  handleInviteToChat
}) => {
  const { t } = useTranslation("core", { keyPrefix: "chat.menu" });
  const { data: currentProfile } = useCurrentProfile();

  const options = [];
  const addOption = (id, el, fn) => options.push({ id, displayElement: el || id, item: fn });

  const sendsOnEnter = getChatSendOnEnter();
  const allowsInvites = allowInvites(conversation, currentProfile.id);
  const urlNotCurrent = window.location.hash !== targetUrl;
  const targetsProfile = getTargetType(conversation) === "Profile";

  addOption(t("chatParticipants"), null, showParticipants);

  allowsInvites && addOption(t("inviteToChat"), null, handleInviteToChat);

  // if the target is Profile you still have the links in participants list ("Chat Participants")
  !targetsProfile && urlNotCurrent && addOption(t("openActivity"), null, () => (window.location = targetUrl));

  isFollowing && addOption(t("unfollowThisChat"), null, () => handleFollowingChange(false));
  !isFollowing && addOption(t("followThisChat"), null, () => handleFollowingChange(true));

  addOption(
    sendsOnEnter ? "_show" : "_hide",
    <span>
      <span className={css.tickbox}>
        {sendsOnEnter ? <TickedBoxIcon width={16} height={16} /> : <UntickedBoxIcon width={16} height={16} />}
      </span>
      {t("pressEnterToSend")}
    </span>,
    () => setSendOnEnter(!sendsOnEnter)
  );

  return (
    <DropDown
      options={options}
      style={{
        width: 210,
        minWidth: 210,
        right: 8,
        top: 32,
        maxHeight: 200
      }}
      qaId="chat-menu"
      selectedIndex={-1}
      handleClose={() => {}}
      handleChange={handleChange}
    />
  );
};

ChatMenu.propTypes = {
  conversation: PropTypes.any.isRequired,
  isFollowing: PropTypes.bool.isRequired,
  targetUrl: PropTypes.string.isRequired,
  setSendOnEnter: PropTypes.func.isRequired,
  showParticipants: PropTypes.func.isRequired,
  handleFollowingChange: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleInviteToChat: PropTypes.func.isRequired
};

export default ChatMenu;
