/* eslint-disable jsx-a11y/alt-text */
import classNames from "classnames";
import AppContext from "PFApp/app_context";
import { Button } from "PFComponents/button";
import { FileUpload } from "PFComponents/file_upload";
import { LoadingDots } from "PFComponents/loading_dots";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import useDebounce from "PFCore/helpers/use_debounce";
import { useErrorsGrowl } from "PFCore/hooks/use_errors_growl";
import { deleteAttachment } from "PFCore/services/attachments/delete_attachment";
import { fetchUrlMetadata, FetchUrlMetadataResponse } from "PFCore/services/common";
import LinkIcon from "PFIcons/link.svg";
import UploadIcon from "PFIcons/upload.svg";
import { GROWL_ACTIONS } from "PFReducers/growl_reducer";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./upload_accomplishment.module.scss";

export type UploadPayload = {
  url?: string | null;
  attachment_id?: number | null;
  image_url?: string | null;
};

interface UploadedFile {
  file_name: string;
  url: string;
  id: number;
}

type UploadAccomplishmentProps = {
  setUploadPayload: (payload: UploadPayload | null) => void;
  uploadPayload?: UploadPayload;
  setUrlRetrievedData: (data: FetchUrlMetadataResponse | null) => void;
  errors: any[];
};

const UploadAccomplishment = ({
  setUploadPayload,
  uploadPayload,
  setUrlRetrievedData,
  errors
}: UploadAccomplishmentProps) => {
  const { dispatch } = useContext(AppContext);
  const { t } = useTranslation("profiles", { keyPrefix: "common.certificates" });
  const growlErrors = useErrorsGrowl();

  const [link, setLink] = useState(uploadPayload?.url);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [uploadedFile, setUploadedFile] = useState<UploadedFile | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const isValid = (url) => /\.(jpg|jpeg|png|pdf)$/i.test(url);

  useEffect(() => {
    setLink(uploadPayload?.url);
    setUploadPayload({
      url: uploadPayload?.url,
      attachment_id: uploadPayload?.attachment_id,
      image_url: uploadPayload?.image_url
    });
    uploadPayload?.image_url && setPreviewImage(uploadPayload?.image_url);
  }, [uploadPayload?.url]);

  useEffect(
    useDebounce(() => {
      if (link) {
        fetchUrlMetadata(link)
          .then((resp) => {
            if (resp) {
              setPreviewImage(resp?.image);
              setUrlRetrievedData(resp);
              setUploadPayload({
                url: link,
                attachment_id: null,
                image_url: resp?.image
              });
            } else if (isValid(link)) {
              setPreviewImage(link);
              setUrlRetrievedData(null);
              setUploadPayload({
                url: link,
                attachment_id: null,
                image_url: link
              });
            } else {
              setUploadPayload({
                url: link,
                attachment_id: null,
                image_url: link
              });
              setPreviewImage(null);
              setUrlRetrievedData(null);
            }
          })
          .catch(() => {
            setUploadPayload({
              url: link,
              attachment_id: null,
              image_url: link
            });
            setPreviewImage(null);
            setUrlRetrievedData(null);
          });
      }
    }, 500),
    [link]
  );

  const uploadFile = (data: UploadedFile) => {
    setUploadedFile(data);
    if (data) {
      setUploadPayload({
        url: null,
        attachment_id: data.id,
        image_url: null
      });
    }
    setIsLoading(false);
  };

  const resetUploadFile = () => {
    deleteAttachment(uploadedFile?.id);
    setUploadPayload({
      url: null,
      attachment_id: null,
      image_url: null
    });
    setUploadedFile(null);
  };

  const findError = (key) => errors?.find((error) => error?.source?.pointer?.search(key) > 0)?.detail;

  return (
    <div className={css.wrapper}>
      <div className={classNames(css.container, { [css.faded]: link })}>
        <FileUpload<UploadedFile>
          url="attachments"
          name="attachment"
          qaId="certificate-file-upload"
          handleLoad={uploadFile}
          handleChange={() => setIsLoading(true)}
          handleError={(resp) => {
            growlErrors(resp, { display_all: true, version: "v2" });
          }}
          allowedFileTypes={["jpg", "jpeg", "png", "pdf"]}
          handleTypeError={() =>
            dispatch({
              type: GROWL_ACTIONS.GROWL_RENDER,
              payload: {
                message: t("invalidFileType"),
                kind: "error"
              }
            })
          }
        >
          {isLoading ? (
            <LoadingDots />
          ) : (
            <>
              <div className={css.title}>{t("uploadFile")}</div>
              <div className={css.actionsWrapper}>
                <div className={classNames(css.iconContainer, css.uploadIcon)}>
                  <UploadIcon height={110} width={110} />
                </div>
                <div className={classNames(css.actions, css.actionsAttach)}>
                  <div className={classNames(css.subtitle, css.textBreak)}>
                    {uploadedFile?.file_name ? (
                      <span className={css.uploadedFileName}>
                        {t("uploadedFilename", { filename: uploadedFile?.file_name })}
                      </span>
                    ) : (
                      <>
                        <span className={css.dragTitle}>{t("dragDropFiles")}</span>
                        <span>&nbsp;{t("acceptedExtensions")}</span>
                      </>
                    )}
                  </div>
                  <div className={css.inputWrapper}>
                    {!uploadedFile?.url && <Button text={t("browse")} className={css.uploadButton} tag="a" />}
                  </div>
                </div>
              </div>
            </>
          )}
        </FileUpload>
        {uploadedFile?.url && (
          <div className={css.deleteButton}>
            <Button text={t("deleteUploadedFile")} onClick={resetUploadFile} className={css.uploadButton} />
          </div>
        )}
      </div>
      <div className={classNames(css.container, { [css.faded]: uploadedFile })}>
        <div className={css.title}>{t("orPasteURL")}</div>
        <div className={css.actionsWrapper}>
          <div
            className={classNames(css.iconContainer, {
              [css.link]: !previewImage,
              [css.imageLinked]: previewImage
            })}
          >
            {previewImage ? (
              <div className={css.imageLinked}>
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <img src={previewImage} onError={() => setPreviewImage(null)} />
              </div>
            ) : (
              <div className={css.linkIcon}>
                <LinkIcon height={100} width={100} />
              </div>
            )}
          </div>
          <div className={css.actions}>
            <div className={css.subtitle}>
              <span>{t("enterCertificateURL")}</span>
            </div>
            <div className={css.inputWrapper}>
              <InputFieldSet
                placeholder={t("pasteURLHere")}
                value={link || undefined}
                error={!uploadedFile && findError("url")}
                onChange={(url) => setLink(url)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadAccomplishment;
