import { TEMPLATE_KEYS } from "PFApp/constants/templates";
import { useMemo } from "react";

import { checkIfIsDefaultPrivate, isAudit } from "../../../helpers";
import { useActivityEditPageContext } from "../activity_edit_page_context";
import { useIsAssigneeRequired } from "./use_is_assignee_required";

export const useActivityEditPageFlags = () => {
  const { activity, template, isClone, parentActivity } = useActivityEditPageContext();

  const isNew = !activity.id;
  const isForceVisibilitySet = !!template.force_visibility;
  const isForcePrivate = template.force_visibility === "private";
  const isEngagement = [TEMPLATE_KEYS.ENGAGEMENT, TEMPLATE_KEYS.AUDIT_ENGAGEMENT].includes(template.key);
  const isRole = [TEMPLATE_KEYS.ROLE, TEMPLATE_KEYS.AUDIT_ROLE].includes(template.key);
  const isAssigneeRequiredFnc = useIsAssigneeRequired();

  const isDefaultPrivate = isForceVisibilitySet
    ? isForcePrivate
    : checkIfIsDefaultPrivate(template, parentActivity);

  return useMemo(
    () => ({
      isNew,
      isEdit: !isNew,
      isClone,
      isDraft: activity.state === "draft",
      isAudit: isAudit(template),
      isEngagement,
      isRole,
      isForceVisibilitySet,
      isForcePrivate,
      isDefaultPrivate,
      isAssigneeRequired: isAssigneeRequiredFnc(template)
    }),
    [
      isNew,
      isClone,
      activity.state,
      isEngagement,
      isRole,
      isForceVisibilitySet,
      isForcePrivate,
      isDefaultPrivate,
      isAssigneeRequiredFnc,
      template
    ]
  );
};
