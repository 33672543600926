import { isEmpty, uniqueId } from "lodash";
import { useTemplate } from "PFApp/hooks";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import { useBookingsFromAvailability } from "PFCore/hooks/queries/bookings/bookings_from_availability/use_bookings_from_availability";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Activity, BookingCategory, FeatureFlag, PermissionRule, Profile } from "PFTypes";
import { useEffect, useMemo, useRef, useState } from "react";

import { useBookingActivityContext } from "../../parts/providers/booking_activity_context_provider";
import { useBookingFormContext } from "./booking_form_context_provider";
import { BookingFormDataType, FormBooking } from "./use_booking_form";
import { SelectedUser } from "./workforce_member_select/workforce_member_select";

const useBookingInitialData = (
  initialData: BookingFormDataType = {},
  isCreateMode: boolean,
  show: boolean
) => {
  const { data: currentProfile } = useCurrentProfile();
  const { formatISODate, utc } = useDateFormatter();
  const { fetchProfile } = useBookingFormContext();
  const { getBookingCategory, mainCategory } = useBookingCategories();
  const isBookingModuleEnabled = useIsFeatureEnabled()(FeatureFlag.BookingModule);
  const isBookingResourcer = useIsCurrentUserPermittedTo(PermissionRule.BookingResourcer);
  const { activity } = useBookingActivityContext();
  const activityTemplate = useTemplate(activity?.template_id);

  const [title, setTitle] = useState<string>(initialData.title || "");
  const [description, setDescription] = useState<string>(initialData.description || "");
  const prefilledUserRef = useRef<Profile | null>(null);
  const [selectedUser, setSelectedUser] = useState<SelectedUser | null>(null);
  const [selectedEngagement, setSelectedEngagement] = useState<Activity | null>(null);
  const initialBookingCategory = useMemo(() => {
    if (initialData.categoryId) {
      return getBookingCategory(initialData.categoryId) || null;
    } else {
      return isBookingModuleEnabled ? null : mainCategory;
    }
  }, [getBookingCategory, mainCategory, isBookingModuleEnabled, initialData.categoryId]);
  const [selectedBookingCategory, setSelectedBookingCategory] = useState<BookingCategory | null>(
    initialBookingCategory
  );

  const defaultBookingItems = useMemo<(Partial<FormBooking> & { key: string })[]>(
    () =>
      initialData.bookings?.map((item) => ({
        ...item,
        key: uniqueId(`0+booking_modal_item+`)
      })) ?? [
        {
          startDate: formatISODate(utc()),
          endDate: formatISODate(utc()),
          key: uniqueId(`0+booking_modal_item+`)
        }
      ],
    [initialData.bookings, formatISODate, utc]
  );
  const [bookingItems, setBookingItems] =
    useState<(Partial<FormBooking> & { key: string })[]>(defaultBookingItems);
  const isBookingsFromAvailabilityEnabled =
    isCreateMode && !!initialData.profileId && !!initialData.activityId;
  const { data: bookingsFromAvailabilityData, isFetching: isBookingsFromAvailabilityLoading } =
    useBookingsFromAvailability({
      profileId: initialData.profileId,
      activityId: initialData.activityId ?? undefined,
      options: {
        enabled: isBookingsFromAvailabilityEnabled
      }
    });
  const bookingsFromAvailability = isBookingsFromAvailabilityEnabled
    ? bookingsFromAvailabilityData
    : undefined;

  useEffect(() => {
    const bookingItemsAsBookingsFromAvailability = bookingsFromAvailability?.ranges?.map((item) => ({
      ...item,
      startDate: item.start_date,
      endDate: item.end_date,
      key: uniqueId(`0+booking_modal_item+`)
    }));

    const shouldUseBookingsFromAvailability = !isCreateMode || isEmpty(initialData.bookings);
    const changedBookingItems =
      shouldUseBookingsFromAvailability && bookingItemsAsBookingsFromAvailability
        ? bookingItemsAsBookingsFromAvailability
        : defaultBookingItems;

    setBookingItems(changedBookingItems);
    setTitle(bookingsFromAvailability?.title ?? initialData.title ?? "");
    setDescription(bookingsFromAvailability?.description ?? (initialData.description || ""));
    setSelectedBookingCategory(initialBookingCategory);

    if (activity && activityTemplate && activityTemplate.key === "engagement") {
      setSelectedEngagement(activity);
    }
  }, [
    isCreateMode,
    bookingsFromAvailability,
    show,
    JSON.stringify(initialData),
    initialBookingCategory,
    activityTemplate,
    activity
  ]);

  useEffect(() => {
    const fetchPreFilledProfile = async (profileId) => {
      const profileResponse = await fetchProfile(profileId);
      setSelectedUser(profileResponse);
      prefilledUserRef.current = profileResponse;
    };

    if (!isBookingResourcer && !selectedUser) {
      fetchPreFilledProfile(currentProfile.id);
      return;
    }

    if (!initialData.profileId || !!selectedUser) {
      return;
    }

    fetchPreFilledProfile(initialData.profileId);
  }, [fetchProfile, initialData.profileId, selectedUser, currentProfile.id, isBookingResourcer]);

  return {
    title,
    setTitle,
    description,
    setDescription,
    selectedUser,
    setSelectedUser,
    selectedEngagement,
    setSelectedEngagement,
    prefilledUserRef,
    selectedBookingCategory,
    setSelectedBookingCategory,
    bookingItems,
    setBookingItems,
    isBookingsFromAvailabilityLoading,
    bookingGroupId: initialData.bookingGroupId
  };
};

export default useBookingInitialData;
