import { sortBy } from "lodash";
import EchartsWrapper from "PFCore/helpers/echarts_wrapper";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";
import { renderToString } from "react-dom/server";
import { useTranslation } from "react-i18next";

import SkillsList from "../skills_list";
import CategoryTooltip from "./category_tooltip";
import { getDataFromPath, ratio } from "./helpers";
import { getProficiencyIcon, removeChildrenFromLayer } from "./helpers";
import css from "./skills_explorer.module.scss";

const SkillsDrilldown = ({
  currentPath,
  skillsTree,
  setCurrentPath,
  height,
  width,
  profile,
  showTooltip
}) => {
  const { data: currentAccount } = useCurrentAccount();

  const { t } = useTranslation("profiles", { keyPrefix: "show.parts" });
  // The visible data which we will actually use for display purposes.
  const visibleData = useMemo(
    () => removeChildrenFromLayer(getDataFromPath(skillsTree, [...currentPath])),
    [currentPath, skillsTree]
  );

  const onChartClick = useCallback(
    ({ dataIndex }) => {
      if (currentPath.length < 2) {
        const newPath = [...currentPath, dataIndex - 1];
        setCurrentPath([...newPath]);
      }
    },
    [currentPath, visibleData]
  );

  return (
    <>
      <EchartsWrapper
        minWidth={300}
        height={height}
        width={width}
        onClick={onChartClick}
        hidden={currentPath.length > 1}
        options={{
          tooltip: {
            backgroundColor: "transparent",
            padding: 0,
            extraCssText: "",
            position: "bottom",
            appendToBody: true, //this fix the overflow: hidden isue and lets render outside the box
            formatter: ({ data, color }) =>
              renderToString(<CategoryTooltip data={data} color={color} currentAccount={currentAccount} />),
            show: showTooltip
          },
          series: [
            {
              width: "100%",
              height: "100%",
              name: t("charts.profileSkills"),
              top: 0,
              type: "treemap",
              id: "profile_skills_treemap",
              animationDurationUpdate: 800,
              roam: false,
              nodeClick: false,
              data: visibleData.map((item) => {
                const min = Math.min(...visibleData.map(({ value }) => value || 1));
                const max = Math.max(...visibleData.map(({ value }) => value || 1));
                const calculatedRatio = ratio(item.value, min, max);
                return {
                  ...item,
                  label: {
                    padding: [-20, 0, 0, 0], // To achieve vertical align
                    align: "center",
                    verticalAlign: "top",
                    formatter: (params) => {
                      const icon = getProficiencyIcon(params.data);
                      return [params.name, `{icon|${icon}}`].join("\n");
                    },
                    fontFamily: "Muli",
                    fontWeight: 700,
                    fontSize: calculatedRatio * 8 + 12, // From 12px to 20px
                    show: true,
                    rich: {
                      icon: {
                        padding: calculatedRatio * 5 + 5, // from 5px to 10px
                        fontFamily: "PF-skill-rank"
                      }
                    }
                  }
                };
              }),
              upperLabel: {
                show: false
              },
              breadcrumb: {
                show: false
              },
              levels: [
                {
                  itemStyle: {
                    borderWidth: 0,
                    gapWidth: 4,
                    borderColor: "transparent"
                  },
                  visualdimesions: 3,
                  color: ["#70A9A1", "#40798C", "#1F363D"],
                  colorMappingBy: "value"
                }
              ]
            }
          ]
        }}
      />
      {currentPath.length > 1 && (
        <div className={css.skillsDrilldownSkillList}>
          <SkillsList
            collection={sortBy(
              visibleData.map(({ data }) => data),
              "experience"
            ).reverse()}
            profileId={profile.id}
            renderProficiency
          />
        </div>
      )}
    </>
  );
};

SkillsDrilldown.propTypes = {
  currentPath: PropTypes.array,
  skillsTree: PropTypes.array,
  setCurrentPath: PropTypes.func,
  height: PropTypes.number,
  width: PropTypes.number,
  profile: PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired,
  showTooltip: PropTypes.bool
};

SkillsDrilldown.defaultProps = {
  showTooltip: true
};

export default SkillsDrilldown;
