import moment from "moment";
import { ISO_DATE_FORMAT } from "PFCore/helpers/date";

const isBookingWithTime = (startDateUtc, endDateUtc) => {
  if (!startDateUtc || !endDateUtc) {
    return false;
  }
  if (
    startDateUtc.creationData().format === ISO_DATE_FORMAT &&
    endDateUtc.creationData().format === ISO_DATE_FORMAT
  ) {
    return false;
  }
  return (
    startDateUtc.diff(moment(startDateUtc).startOf("day"), "seconds") !== 0 ||
    endDateUtc.diff(moment(endDateUtc).endOf("day"), "seconds") !== 0
  );
};

export default isBookingWithTime;
