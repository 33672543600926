import { SUPPORTING_SKILL_WEIGHT } from "PFTypes";

import { useRoleMatchCustomFieldData } from "./use_role_match_custom_field_data";

export const useSupportingSkills = () => {
  const { getCustomFieldValuesOfWeight } = useRoleMatchCustomFieldData();
  const { roleCustomValues, matchCustomValues, missingMatchCustomValues } = getCustomFieldValuesOfWeight(
    "skills",
    SUPPORTING_SKILL_WEIGHT
  );
  return {
    roleSupportingSkills: roleCustomValues,
    matchSupportingSkills: matchCustomValues,
    missingMatchSupportingSkills: missingMatchCustomValues
  };
};
