import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import ButtonLink from "PFComponents/button/button_link";
import { Typography } from "PFComponents/typography";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import NoShortlistIllustration from "PFTheme/graphics/illustrations/illustration-no-shortlist.svg";
import { useTranslation } from "react-i18next";

import css from "./shortlist_empty_state.module.scss";

export const ShortlistEmptyState = () => {
  const { t } = useTranslation("activities", { keyPrefix: "show.shortlist" });
  const { task: activity, isAudit, canSeeMatches } = useActivityPageContext();
  const { data: currentProfile } = useCurrentProfile();

  // eslint-disable-next-line camelcase
  const isReviewer = activity.reviewers.find(({ profile_id }) => profile_id === currentProfile.id);

  return (
    <div className={css.noItems}>
      <NoShortlistIllustration />
      <Typography variant="h1">{t("noShortlistedCandidates")}</Typography>

      {activity.coowned && (
        <Typography variant="h5" className={css.subtitle}>
          {t("shortlistVisibleToYou")}
          <br />
          {t("wontNotifyAnyone")}
        </Typography>
      )}
      {!isReviewer && canSeeMatches && (
        <ButtonLink
          href={
            isAudit
              ? `/audit_planner/manual_interventions/${activity.parent_activity_id}/${activity.id}/matches`
              : `/activities/${activity.id}/matches`
          }
        >
          {t("viewMatches")}
        </ButtonLink>
      )}
    </div>
  );
};
