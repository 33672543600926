import FolderTabs from "PFComponents/tabs/folder_tabs";
import { useCertificates } from "PFCore/hooks/queries/certificates";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import EditButton from "../edit_button";
import { useProfileSectionContext } from "../profile_section_context_provider";
import SectionLayout from "../section_layout";
import CertificatesTab from "./certificates_tab";
import css from "./section_accomplishments.module.scss";

const DEFAULT_TAB = "certificates";

const AccomplishmentsSection = ({ handleProfileUpdate }) => {
  const { t } = useTranslation("profiles");
  const history = useHistory();
  const { setIsEditMode, isEditMode, profile, isMe } = useProfileSectionContext();
  const { id, section, additionalSection } = useParams<{
    id?: string;
    section: string;
    additionalSection?: string;
  }>();
  const certificatesQuery = useCertificates(profile.id, 1, 0);

  const [tab, setTab] = useState(additionalSection || DEFAULT_TAB);

  const handleTabSelected = useCallback((newTab) => {
    history.push(`/profiles/${id}/${section}/${newTab.id}`);
    setTab(newTab.id);
  }, []);

  const tabs = [
    {
      id: "certificates",
      displayElement: t("show.parts.certificates"),
      count: certificatesQuery.data?.meta?.total,
      Content: <CertificatesTab fetchProfile={() => handleProfileUpdate()} />
    }
  ];

  const isEditDisabled = !isEditMode && !tabs.find(({ id }) => id === tab)?.count;

  return (
    <SectionLayout
      header={{
        actionButtons: isMe ? (
          <EditButton
            disabled={isEditDisabled}
            isEditMode={isEditMode}
            setEditMode={(value) => {
              setIsEditMode(value);
              history.push(`/profiles/${id}/${section}`);
            }}
            labelOn
          />
        ) : (
          <></>
        )
      }}
    >
      <FolderTabs
        tabs={tabs}
        selectedTabId={tab}
        onTabSelected={handleTabSelected}
        classes={{
          tab: css.tab,
          outer: css.tabsOuter,
          content: css.tabsContent
        }}
      />
    </SectionLayout>
  );
};

export default AccomplishmentsSection;
