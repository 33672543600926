import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useGrowl } from "PFApp/use_growl";
import { Button } from "PFComponents/button";
import ConfirmModal from "PFComponents/confirm/confirm_modal";
import { Typography } from "PFComponents/typography";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { ApiRoute } from "PFCore/utilities/routes";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const BecomeCoownerButton = ({ templateName }) => {
  const [showModal, setShowModal] = useState(false);
  const { task } = useActivityPageContext();
  const { invalidate: invalidateActivities } = useActivityInvalidate();

  const { data: currentProfile } = useCurrentProfile();
  const { id: currentProfileId } = currentProfile;
  const { t } = useTranslation("activities");

  const growl = useGrowl();
  const { getFirstError } = useResponseErrors();

  const handleOKClick = () =>
    $.ajax({
      url: ApiRoute("/api/owners"),
      method: "POST",
      // eslint-disable-next-line camelcase
      api_version: 2,
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify({
        // eslint-disable-next-line camelcase
        profile_id: currentProfileId,
        activity_id: task.id
      })
    })
      .then(() => {
        invalidateActivities([task.id]).then(() => {
          growl({
            message: t("show.parts.addedAsCoOwner"),
            kind: "success"
          });
          setShowModal(false);
        });
      })
      .fail((resp) => {
        const error = getFirstError(resp);
        growl({
          message: error || t("show.parts.unknowError"),
          kind: "error"
        });
      });

  return (
    <>
      <Button text={t("show.parts.becomeCoOwner")} onClick={() => setShowModal(true)} />
      {showModal && (
        <ConfirmModal
          title={t("show.parts.becomeCoOwner")}
          handleClose={() => setShowModal(false)}
          handleOK={handleOKClick}
          showOKButton
        >
          <Typography variant="bodyRegular">
            {t("show.parts.addYourselfAsCoOwner", { templateName: templateName || "activity" })}
          </Typography>
        </ConfirmModal>
      )}
    </>
  );
};

BecomeCoownerButton.propTypes = {
  templateName: PropTypes.string
};

export default BecomeCoownerButton;
