import classNames from "classnames";
import BookingPill from "PFApp/components/pills/booking_pill";
import { Typography } from "PFComponents/typography";

import css from "./category_option.module.scss";

interface CategoryOptionProps {
  text: string;
  pillColor: string;
  restrictedTextWidth?: boolean;
}

const CategoryOption = ({ text, pillColor, restrictedTextWidth }: CategoryOptionProps): JSX.Element => (
  <div className={css.option}>
    <div className={classNames(css.text, { [css.restrictedTextWidth]: restrictedTextWidth })}>
      <Typography variant="bodyRegular" tag="span">
        {text}
      </Typography>
    </div>
    <BookingPill
      category={{ color: pillColor }}
      className={css.pill}
      style={{
        borderWidth: 1
      }}
    />
  </div>
);

export default CategoryOption;
