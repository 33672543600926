import classNames from "classnames";

import css from "./section_layout.module.scss";

type SectionLayoutProps = {
  header: Header;
  sidePanel?: JSX.Element;
  className?: string;
};

type Header = {
  title?: string;
  headerContent?: JSX.Element;
  actionButtons?: JSX.Element;
};

const SectionLayout = ({
  header,
  sidePanel,
  className,
  children
}: React.PropsWithChildren<SectionLayoutProps>) => (
  <>
    <div className={classNames(css.mainContainer, className)}>
      <div className={css.header}>
        <h3>{header.title}</h3>
        {header.headerContent}
        {header.actionButtons}
      </div>
      <div className={css.content}>
        <div className={css.list}>{children}</div>
        {sidePanel && (
          <>
            <div className={css.rightPanel}>{sidePanel}</div>
          </>
        )}
      </div>
    </div>
  </>
);

export default SectionLayout;
