import { useTemplate } from "PFApp/hooks";
import { getVisibleProperties } from "PFCore/helpers/templates";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { CustomType } from "PFTypes";

import { MatchCustomFieldSection } from "../../components/match_attribute_section/match_custom_field_section";
import { useRoleMatchCustomFieldData } from "../../hooks/use_role_match_custom_field_data";
import { useMatchCardContext } from "../../match_card_context";
import css from "./activity_match_custom_fields.module.scss";

export const ActivityMatchCustomFields = () => {
  const { role } = useMatchCardContext();
  const { customTypes } = useCustomTypes();
  const template = useTemplate(role?.template_id);
  const { getCustomFieldValuesOfWeight } = useRoleMatchCustomFieldData();

  const matchProperties = getVisibleProperties(template).filter(
    ({ match, type, name }) => match && type === "custom_field" && name !== "skills"
  );
  const matchCustomFields = matchProperties.map(({ name: propertyName }) =>
    customTypes.find(({ name: customFieldName }) => customFieldName === propertyName)
  ) as CustomType[];

  return (
    <div className={css.activityMatchCustomFields}>
      {matchCustomFields.map(({ id, name, display_as: displayAs }) => {
        const { roleCustomValues, matchCustomValues, missingMatchCustomValues } =
          getCustomFieldValuesOfWeight(name);

        if (roleCustomValues.length === 0) {
          return null;
        }

        return (
          <MatchCustomFieldSection
            key={id}
            customFieldLabel={displayAs ?? name}
            customFieldName={name}
            i18nKey="customFieldCount"
            matchCustomValues={matchCustomValues}
            missingCustomValues={missingMatchCustomValues}
            total={roleCustomValues.length}
            skillTruncateLength={18}
          />
        );
      })}
    </div>
  );
};
