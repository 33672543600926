import Tooltip from "PFComponents/tooltip/tooltip";
import { useAvailabilityFormatter } from "PFCore/helpers/availability";
import InfoIcon from "PFIcons/icon_info.svg";
import PropTypes from "prop-types";
import { useMemo } from "react";

import { useReadableAvailability } from "../../../helpers/use_readable_availability";
import css from "./role_availability.module.scss";

export const RoleAvailability = ({ role }) => {
  const { readableAvailability } = useReadableAvailability({ availability: role.availability });
  const { availabilityRangesFormat, availabilityIntervalFormat } = useAvailabilityFormatter();

  const tooltipContent = useMemo(() => {
    if (!role.availability) {
      return null;
    } else if (role.availability?.mode === "time_rule") {
      return availabilityRangesFormat(role.availability, true, css.rangesTooltipContent);
    }

    return availabilityIntervalFormat(role.availability);
  }, [role.availability, availabilityRangesFormat, availabilityIntervalFormat]);

  return (
    <>
      {readableAvailability}
      {role?.availability?.ranges && (
        <Tooltip content={<div className={css.tooltipContent}>{tooltipContent}</div>}>
          <span>
            <InfoIcon width={20} height={20} style={{ transform: "translate(0px, 5px)" }} />
          </span>
        </Tooltip>
      )}
    </>
  );
};

RoleAvailability.propTypes = {
  role: PropTypes.object.isRequired
};
