import * as d3 from "d3-hierarchy";
import { camelCase, sortBy } from "lodash";
import EchartsWrapper from "PFCore/helpers/echarts_wrapper";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";
import { renderToString } from "react-dom/server";

import SkillsList from "../skills_list";
import CategoryTooltip from "./category_tooltip";
import { getDataFromPath, getProficiencyIcon, ratio } from "./helpers";
import { removeChildrenFromLayer } from "./helpers";
import css from "./skills_explorer.module.scss";

const SkillsBubbles = ({ currentPath, skillsTree, setCurrentPath, height, width, profile, showTooltip }) => {
  const { data: currentAccount } = useCurrentAccount();

  const visibleData = useMemo(
    () => removeChildrenFromLayer(getDataFromPath(skillsTree, [...currentPath])),
    [currentPath, skillsTree]
  );

  const seriesData = useMemo(() => {
    const series = [
      {
        id: "skills",
        name: "",
        value: 0,
        depth: 0,
        index: 0
      }
    ];

    const minValue = Math.min(...visibleData.map(({ value }) => value || 1));
    const maxValue = Math.max(...visibleData.map(({ value }) => value || 1));

    visibleData.forEach((element, index) => {
      series.push({
        id: `skills.${camelCase(element.name.replace(".", ""))}`,
        name: element.name,
        data: element.data,
        // value: element.value,
        value: ratio(element.value, minValue, maxValue) * 30 + 5,
        depth: 1,
        index: index + 1
      });
    });
    return series;
  }, [visibleData]);

  const maxValue = Math.max(...seriesData.map(({ value }) => value || 1));
  const minValue = Math.min(...seriesData.map(({ value }) => value || 1));

  const displayRoot = useMemo(
    () =>
      d3
        .stratify()
        .parentId((item) => item.id.substring(0, item.id.lastIndexOf(".")))(seriesData)
        .sum((item) => item.value || 0)
        .sort((item1, item2) => item2.value - item1.value),
    [seriesData]
  );

  const onChartClick = useCallback(
    ({ dataIndex }) => {
      if (currentPath.length < 2) {
        const newPath = [...currentPath, dataIndex - 1];
        setCurrentPath([...newPath]);
      }
    },
    [currentPath, visibleData]
  );
  const overallLayout = (params) => {
    var { context } = params;
    d3.pack().size([width, height]).padding(3)(displayRoot);
    context.nodes = displayRoot.descendants();
  };

  const colors = ["#70A9A1", "#40798C", "#3F565D"];
  const secondColor = "#000";

  const renderItem = (params, api) => {
    var { context } = params;
    // Only do that layout once in each time `setOption` called.
    if (!context.layout) {
      context.layout = true;
      overallLayout(params, api);
    }
    // Find current node by index
    const node = (context?.nodes || []).find((node) => node.data.index === params.dataIndex);
    if (!node) {
      // Reder nothing.
      return;
    }

    const index = parseInt(`${ratio(node.data.value, minValue, maxValue) * 2}`, 10);
    const color = colors[index];

    const icon = getProficiencyIcon(node.data);
    var isLeaf = !node.children || !node.children.length;
    var focus = new Uint32Array(node.descendants().map((node) => node.data.index));
    var nodeName = node.data.name.replace(" and", ",").split(" ").join("\n");
    var z2 = node.data.depth * 2;

    return {
      type: "circle",
      focus,
      shape: {
        cx: node.x,
        cy: node.y,
        r: node.r - node.r / 12 // eslint-disable-line id-length
      },
      transition: ["shape"],
      z2,
      textContent: {
        type: "text",
        style: {
          transition: isLeaf ? "fontSize" : null,
          text: nodeName && [nodeName, icon].join("\n"),
          fontFamily: "Muli, PF-skill-rank",
          overflow: "truncate",
          fill: api.visual("color"),
          lineHeight: node.r * 0.3,
          fontSize: node.r * 0.2
        },
        emphasis: {
          style: {
            overflow: null,
            fill: api.visual("color"),
            lineWidth: 2,
            stroke: "#fff",
            transition: ["x", "y"],
            lineHeight: node.r * 0.4,
            fontSize: node.r * 0.3
          }
        }
      },
      textConfig: {
        position: "inside"
      },
      style: {
        fill: "#fff",
        stroke: {
          type: "linear",
          x: 0, // eslint-disable-line id-length
          y: 0, // eslint-disable-line id-length
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0.4,
              color: isLeaf ? color : "transparent"
            },
            {
              offset: 1,
              color: isLeaf ? secondColor : "transparent"
            }
          ],
          global: false // default is false
        },
        lineWidth: node.r / 5
      }
    };
  };

  return (
    <>
      <EchartsWrapper
        height={height}
        width={width}
        onClick={onChartClick}
        hidden={currentPath.length > 1}
        options={{
          visualMap: [
            {
              show: false,
              min: 0,
              max: maxValue,
              inRange: {
                color: colors
              }
            }
          ],
          hoverLayerThreshold: Infinity,
          tooltip: {
            backgroundColor: "transparent",
            padding: 0,
            extraCssText: "",
            appendToBody: true, //this fix the overflow: hidden isue and lets render outside the box
            formatter: ({ data, color }) =>
              renderToString(<CategoryTooltip data={data} color={color} currentAccount={currentAccount} />),
            show: showTooltip
          },
          series: {
            data: seriesData,
            type: "custom",
            renderItem: renderItem,
            progressive: 0,
            coordinateSystem: "none"
          }
        }}
      />
      {currentPath.length > 1 && (
        <div className={css.skillsDrilldownSkillList}>
          <SkillsList
            collection={sortBy(
              visibleData.map(({ data }) => data),
              "experience"
            ).reverse()}
            profileId={profile.id}
            renderProficiency
          />
        </div>
      )}
    </>
  );
};

SkillsBubbles.propTypes = {
  currentPath: PropTypes.array,
  skillsTree: PropTypes.array,
  setCurrentPath: PropTypes.func,
  height: PropTypes.number,
  width: PropTypes.number,
  profile: PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired,
  showTooltip: PropTypes.bool
};

SkillsBubbles.defaultProps = {
  showTooltip: true
};

export default SkillsBubbles;
