import classNames from "classnames";
import { ActionIcon } from "PFComponents/action_icon";
import { PillTabs } from "PFComponents/tabs/pill_tabs";
import { CurrentProfile, CustomValue, Profile } from "PFTypes";
import { useEffect, useRef, useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";

import { SkillsFrameworksSuggestedSkillsModal } from "../skills_groups/skills_frameworks_suggested_skills_modal";
import { DraggableSkill } from "./draggable_skill";
import css from "./skills_suggestions.module.scss";

type Tab = {
  id: string;
  label: string;
  skills: Partial<CustomValue>[];
  disabled?: boolean;
  infoText?: string;
};

type SkillsSuggestionsProps = {
  profile: Profile | CurrentProfile;
  addToLog: Function;
  onSkillRanked: (customValue: CustomValue) => void;
  tabs: Tab[];
  isOpen?: boolean;
  style?: object;
};

export const SkillsSuggestions = ({
  profile,
  addToLog,
  onSkillRanked,
  tabs,
  isOpen = true,
  style
}: SkillsSuggestionsProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts.sectionSkills" });
  const [isSkillsFrameworksModalOpened, toggleSkillsFrameworksModalOpened] = useToggle(false);

  const [selectedTabId, setSelectedTabId] = useState<string>(tabs[0].id);
  const [open, setOpen] = useState<boolean>(isOpen);
  const [height, setHeight] = useState<number>(0);

  const selectedTab = tabs.find(({ id }) => id === selectedTabId) || tabs[0];
  const { id, disabled, skills } = selectedTab;
  const isFrameworkSuggestedSkills = selectedTab.id === "framework";

  useEffect(() => {
    setHeight(open ? ref.current?.clientHeight || 0 : 0);
  }, [open, selectedTab.id]);

  const toggle = () => setOpen((open) => !open);

  return (
    <div className={classNames(css.skillsSuggestions, { [css.disabled]: disabled })} style={style}>
      <div className={css.header}>
        <PillTabs
          tabs={tabs}
          selectedId={selectedTab.id}
          onTabClick={(newId) => setSelectedTabId(newId)}
          className={css.pillTabs}
        />
        <ActionIcon
          name={open ? "chevron-up" : "chevron-down"}
          size="sm"
          classes={{ root: css.icon }}
          onClick={toggle}
        />
      </div>
      <div className={css.main} style={{ height }}>
        <div ref={ref}>
          <div className={css.suggestedSkillsHeader}>
            <div className={css.info}>
              {selectedTab.infoText && <b>{selectedTab.infoText}</b>}
              <div>
                {selectedTabId === "discovered" ? t("suggestions.discovered.info") : t("suggestions.info")}
              </div>
            </div>
            {isFrameworkSuggestedSkills && (
              <ActionIcon
                name="open"
                size="sm"
                onClick={toggleSkillsFrameworksModalOpened}
                classes={{ root: css.linkToModalIcon }}
              />
            )}
          </div>
          <div className={classNames(css.additionalSkills, css.suggestedSkills)}>
            <Droppable droppableId={id} isDropDisabled>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {skills.map((skill, index) => (
                    <DraggableSkill
                      key={`suggested-${skill.id}`}
                      skill={skill}
                      index={index}
                      addToLog={addToLog}
                      parentSection={"suggested"}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </div>
      </div>
      {isSkillsFrameworksModalOpened && (
        <SkillsFrameworksSuggestedSkillsModal
          profile={profile}
          handleClose={toggleSkillsFrameworksModalOpened}
          onSkillRanked={onSkillRanked}
        />
      )}
    </div>
  );
};
