import { omit } from "lodash";

/**
 * @typedef {Object} CollectionParams - Extendable params collection
 * @property {number} perPage - Number of records to return per page
 * @property {number} page - Page to return
 * @property {number} apiVersion - API version
 * @property {string} [term] - search
 * @property {Object} [filters] - filters
 * @property {Object} [order] - order
 * @property {Object} [date_range] - date range
 * @property {1 | 2 | undefined} [apiVersion] - Version of the API
 */

const _justFetch = (url, params = {}, headers = null, options = {}) => {
  const perPage = params.perPage === undefined ? 25 : params.perPage;
  const apiVersion = params.apiVersion || 2;
  const page = params.page || 1;
  const queryParams = { ...omit(params, ["perPage", "apiVersion", "page"]), page, per_page: perPage };
  const method = options.method || "GET";
  const contentType = options.contentType || "application/x-www-form-urlencoded; charset=UTF-8";
  return $.ajax({
    url,
    headers,
    contentType,
    data: options.stringify ? JSON.stringify(queryParams) : queryParams,
    api_version: apiVersion,
    method
  });
};

/**
 * Get a paginated collection from the API
 * @param {string} url - API URL for the collection
 * @param {CollectionParams} params - Params for the request
 * @param {Object} headers - Additional headers for the request
 * @param {Object} options - Additional options for the request
 * @returns {Promise} - Paginated Collection
 */

export const fetchPaginatedCollection = (url, params = {}, headers = {}, options = {}) => {
  const perPage = params.perPage === undefined ? 25 : params.perPage;
  const page = params.page || 1;
  const totalPages = (total) => Math.max(Math.ceil(total / perPage), 1);
  return _justFetch(url, params, headers, options).then(
    (resp) => ({
      ...resp,
      meta: { ...resp.meta, page, perPage, totalPages: totalPages(resp.meta?.total || 0) }
    }),
    (resp) => resp
  );
};
