import { set } from "lodash";
import ActivityProfilesList from "PFApp/activities/parts/activity_profiles_list";
import { ProfileItem } from "PFApp/components/profile_item";
import ConfirmModal, { ConfirmModalProps } from "PFComponents/confirm/confirm_modal";
import AutoSelect from "PFComponents/select/autoselect";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { fetchProfilesOptions } from "PFCore/services/profile";
import { Activity, ActivityProfile, ActivityProfileType, FeatureFlag, Id, Profile } from "PFTypes";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

import css from "./add_activity_profiles.module.scss";

type AddActivityProfilesProps = ConfirmModalProps & {
  task?: Activity;
  onSubmit: (newProfiles: Profile[]) => void;
  onRemove?: (...args: any[]) => any;
  showUnbiasedToggle?: boolean;
  submitButtonLabel: string | React.ReactNode;
  description?: React.ReactNode;
  onClose: (event: unknown) => void;
  newProfiles: Profile[];
  onNewProfilesChange: Function;
  profiles: ActivityProfile[];
  getRemoveModalContent?: (profile: Profile) => string;
  activityProfileType?: ActivityProfileType;
  enableSubmit?: boolean;
  disabledProfileIds?: Id[];
};

const AddActivityProfiles = ({
  task,
  profiles,
  onClose,
  onSubmit,
  onRemove,
  showUnbiasedToggle,
  submitButtonLabel,
  title,
  description,
  newProfiles,
  onNewProfilesChange,
  children,
  getRemoveModalContent,
  activityProfileType,
  enableSubmit,
  disabledProfileIds = [],
  ...confirmModalProps
}: AddActivityProfilesProps) => {
  const { data: currentProfile } = useCurrentProfile();

  const isEnabled = useIsFeatureEnabled();
  const template = currentProfile.templates.find((item) => item.id === task?.template_id);
  const isUnbiasedEnabled = !!template?.need_matches && isEnabled(FeatureFlag.UnbiasedAllocation);

  const { t } = useTranslation(["activities", "translation"]);

  const bodyRef = useRef<HTMLDivElement>(null);

  const handleSelection = (profiles: Profile[]) => {
    onNewProfilesChange(profiles);
    set(bodyRef, "current.scrollTop", 0);
  };

  const handleRemoveSelected = (selected) => {
    const updatedProfiles = newProfiles.filter(({ id }) => id !== selected.profile_id);
    onNewProfilesChange(updatedProfiles);
  };

  const handleAddUnbiasedMode = (selected) => {
    const updatedProfiles = newProfiles.filter(({ id }) => id !== selected.profile_id);
    onNewProfilesChange([...updatedProfiles, selected.profile]);
  };

  const selectedIds = profiles
    .map(({ profile }) => profile)
    .concat(newProfiles)
    .map(({ id }) => id);

  return (
    <ConfirmModal
      title={title}
      classes={{ body: css.activityProfilesModalBody }}
      disableOKButton={!(enableSubmit || newProfiles.length)}
      labelOK={submitButtonLabel || t("activities:show.parts.add")}
      handleOK={() => onSubmit(newProfiles)}
      handleClose={onClose}
      {...confirmModalProps}
    >
      <AutoSelect
        label={t("translation:workforceMember")}
        closeOnChange
        multi
        values={newProfiles}
        query={(term) => fetchProfilesOptions({ term })}
        filterOptions={(response) => response.filter((item) => currentProfile.id !== item.id)}
        formatOption={(item) => ({
          id: item.id,
          displayElement: <ProfileItem profile={item} />,
          disabled: selectedIds.includes(item.id) || disabledProfileIds.includes(item.id),
          item
        })}
        handleChange={handleSelection}
        showValues={false}
      />
      {(!!profiles.length || !!newProfiles.length) && (
        <div className={css.lists} ref={bodyRef}>
          <ActivityProfilesList
            activityProfiles={newProfiles.map((profile) => ({
              profile,
              profile_id: profile.id,
              activity_id: task?.id
            }))}
            title={t("activities:show.parts.aboutToAdd")}
            isUnbiasedEnabled={isUnbiasedEnabled}
            onRemove={handleRemoveSelected}
            handleAddUnbiasedMode={handleAddUnbiasedMode}
            showUnbiasedToggle={showUnbiasedToggle}
            activityProfileType={activityProfileType}
          />
          <ActivityProfilesList
            activityProfiles={profiles}
            isUnbiasedEnabled={isUnbiasedEnabled}
            title={t("activities:show.parts.alreadyAdded")}
            onRemove={onRemove}
            showUnbiasedToggle={showUnbiasedToggle}
            getRemoveModalContent={getRemoveModalContent}
            activityProfileType={activityProfileType}
          />
        </div>
      )}
      {description && (
        <div className={css.subtitle}>
          <strong>{t("translation:pleaseNote")}</strong>
          <br />
          <div className={css.description}>{description}</div>
        </div>
      )}
      {children}
    </ConfirmModal>
  );
};

export default AddActivityProfiles;
