import api from "PFCore/api";
import { Reviewer } from "PFTypes";

export type ReviewerCreatePayload = {
  profileId: number;
  activityId: number;
  unbiased?: boolean;
};

export const createReviewer = (data: ReviewerCreatePayload): Promise<Reviewer> =>
  api({
    url: "reviewers",
    method: "POST",
    data
  });

export const deleteReviewer = (id: number): Promise<Reviewer> =>
  api({
    url: `reviewers/${id}`,
    method: "DELETE"
  });
