import classNames from "classnames";
import { includes, some } from "lodash";
import moment from "moment";
import TooltipIcon from "PFComponents/tooltip_icon/tooltip_icon";
import roundToDecimals from "PFCore/helpers/round_to_decimals";
import InfoIcon from "PFIcons/info.svg";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import css from "./bookings_total.module.scss";

type Booking = {
  duration: number;
  state: string;
  start_date: string;
  end_date: string;
};

const Icon = (props) => <InfoIcon {...props} />;

const BookingsTotal = ({
  bookings,
  className,
  isEditMode
}: {
  bookings: Booking[];
  className?: string;
  isEditMode?: boolean;
}) => {
  const { t } = useTranslation(["bookingModule", "translation"]);
  const noDuration = bookings.length === 0 || some(bookings, ({ duration }) => duration === undefined);
  const warningString = useMemo(() => {
    const states = bookings.map(({ state }) => state);
    if (includes(states, "pending")) {
      return t("bookingModule:bookings.totalWarnings.pending");
    }
    if (isEditMode || noDuration || includes(states, "editing")) {
      return t("bookingModule:bookings.totalWarnings.editing");
    }
  }, [bookings, t]);

  const durationString = useMemo(() => {
    if (warningString || noDuration) {
      return `${t("translation:total")}: -`;
    }
    const calculatedTime = bookings.reduce((acc, current) => acc + current.duration || 0, 0);
    const momentDuration = moment.duration(calculatedTime, "minutes");
    const hours = roundToDecimals(momentDuration.asHours());
    const days = bookings.reduce(
      (sum, current) => sum + moment(current.end_date).diff(current.start_date, "days") + 1,
      0
    );
    return `${t("bookingModule:details.total", { hours, days })}`;
  }, [bookings, warningString, t]);

  return (
    <div className={classNames(css.total, className)}>
      {warningString && (
        <TooltipIcon
          IconComponent={Icon}
          content={warningString}
          className={css.icon}
          height={22}
          width={22}
        />
      )}
      {durationString}
    </div>
  );
};

export default BookingsTotal;
