import classNames from "classnames";
import { ActionDiv, ActionDivProps } from "PFComponents/divs";
import { forwardRef, Fragment, ReactNode, useMemo } from "react";

import css from "./card.module.scss";

type ConditionalCardProps =
  | Pick<ActionDivProps, "onClick" | "ariaLabel">
  | {
      onClick?: never;
      ariaLabel?: never;
    };

export type CardProps = {
  children: ReactNode;
  qaId?: string;
  shadowVariant?: "none" | "default";
  paddingVariant?: "none" | "lg" | "xl";
  className?: string;
} & ConditionalCardProps;

export const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      children,
      qaId = "Card",
      shadowVariant = "default",
      paddingVariant = "lg",
      onClick,
      ariaLabel,
      className
    },
    ref
  ) => {
    const Wrapper = useMemo(
      () =>
        onClick
          ? ({ children: wrapperChildren }) => (
              <ActionDiv onClick={onClick} ariaLabel={ariaLabel} className={css.actionDiv}>
                {wrapperChildren}
              </ActionDiv>
            )
          : Fragment,
      [ariaLabel, onClick]
    );

    return (
      <Wrapper>
        <div
          ref={ref}
          data-qa-id={qaId}
          className={classNames(css.card, className, {
            [css.noPadding]: paddingVariant === "none",
            [css.largePadding]: paddingVariant === "lg",
            [css.xlargePadding]: paddingVariant === "xl",
            [css.shadowNone]: shadowVariant === "none"
          })}
        >
          {children}
        </div>
      </Wrapper>
    );
  }
);

Card.displayName = "Card";
