import { HTMLAttributes } from "react";

import { HistoryEntryBadge } from "./history_entry_badge";
import css from "./history_entry_row.scss";

type HistoryEntryRowProps = React.PropsWithChildren<{
  badge?: string;
}> &
  HTMLAttributes<HTMLDivElement>;

export const HistoryEntryRow = ({ children, badge, ...divProps }: HistoryEntryRowProps) => (
  <div className={css.entry}>
    {badge && <HistoryEntryBadge badge={badge} />}
    <div {...divProps}>{children}</div>
  </div>
);
