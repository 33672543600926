import api from "PFCore/api";
import { Id, PaginatedCollection, SkillsFramework } from "PFTypes";
import { QueryParams } from "PFTypes/query_params";

export type FetchProfileSkillsFrameworksParams = Pick<QueryParams, "page" | "perPage">;
export type FetchProfileSkillsFrameworksResponse = PaginatedCollection<SkillsFramework[]>;

export const fetchProfileSkillsFrameworks = (
  profileId: Id,
  params: FetchProfileSkillsFrameworksParams
): Promise<FetchProfileSkillsFrameworksResponse> =>
  api({
    url: `profiles/${profileId}/skills_frameworks`,
    params
  });
