import CvUploader from "PFApp/profiles/common/cv_uploader";
import { ActionIcon } from "PFComponents/action_icon";
import { Card } from "PFComponents/card";
import CardHeader from "PFComponents/card/card_header";
import Modal from "PFComponents/modal/modal";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const UploadCvModal = ({ handleModalClose, t }) => (
  <Modal
    handleClose={() => handleModalClose(false)}
    closeOnBackdropClick
    style={{ maxWidth: 700, margin: "0 auto" }}
  >
    <Card paddingVariant="xl">
      <CardHeader
        titleQaId="profile-cv-upload"
        title={t("show.parts.uploadNewCV")}
        style={{ padding: 0, marginBottom: 24 }}
        action={<ActionIcon name="cross" size="xs" onClick={() => handleModalClose(false)} />}
      />
      <CvUploader />
    </Card>
  </Modal>
);

UploadCvModal.propTypes = {
  handleModalClose: PropTypes.func,
  t: PropTypes.func
};

export default withTranslation("profiles")(UploadCvModal);
