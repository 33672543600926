import classNames from "classnames";
import { isNumber } from "lodash";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import QuestionsModal from "PFApp/components/questions_modal";
import questionsModalCss from "PFApp/components/questions_modal/questions_modal.module.scss";
import useQuestionsModal from "PFApp/components/questions_modal/use_questions_modal";
import { MatchingRelationType } from "PFApp/constants/matching_relation_type";
import { useTemplate } from "PFApp/hooks";
import { Button } from "PFComponents/button";
import Modal from "PFComponents/modal/modal";
import { MatchGauge } from "PFComponents/resource_metrics/match_gauge";
import Tooltip from "PFComponents/tooltip/tooltip";
import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import { ApiRoute } from "PFCore/utilities/routes";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./activity_details_common.module.scss";
import watcherCss from "./activity_details_watcher_checkbox.less";

const ActivityDetailsWatcherCheckbox = ({ currentProfileId }) => {
  const { task } = useActivityPageContext();
  const { invalidate: invalidateActivities } = useActivityInvalidate();

  const [matchWeight, setMatchWeight] = useState(null);
  const template = useTemplate(task.template_id);
  const templateName = template.name;
  const { t } = useTranslation("activities");
  const [isUpdatingActivity, setIsUpdatingActivity] = useState(false);
  const {
    questionModalProps,
    isQuestionsModalOpened,
    isLoading,
    handleInterestButtonClick,
    closeQuestionsModal
  } = useQuestionsModal();

  useEffect(() => {
    $.ajax({
      url: ApiRoute(`/api/shortlists/ranked_profiles`),
      api_version: 2, // eslint-disable-line camelcase
      data: {
        relation_type: MatchingRelationType.Matched, // eslint-disable-line camelcase
        activity_id: task.id, // eslint-disable-line camelcase
        profile_ids: [currentProfileId] // eslint-disable-line camelcase
      },
      contentType: "application/json; charset=utf-8"
    }).then((res) => setMatchWeight(res.entries[0]));
  }, []);

  const {
    scores: { normalized_score: normalizedScore, availability_score: availabilityScore }
  } = matchWeight || { scores: {} };

  const onMutationFinish = () => {
    setIsUpdatingActivity(true);
    invalidateActivities([task.id]).then(() => {
      setIsUpdatingActivity(false);
    });
  };

  const interestedIn = !!task.interest_id;

  return (
    <div className={classNames(css.infoItem, watcherCss.box)} data-qa-id="ActivityDetailsWatcherSelector">
      <div className={watcherCss.badges}>
        {(isNumber(normalizedScore) || isNumber(availabilityScore)) && (
          <div className={watcherCss.badgesTitle}>
            {t("show.details.yourMatchScore", { templateName: templateName })}
          </div>
        )}
        <div className={watcherCss.badgesWrapper}>
          {matchWeight && <MatchGauge match={matchWeight} className={watcherCss.match} />}
        </div>
      </div>
      <div className={watcherCss.title}>
        {t("show.details.interestedInTemplate", { templateName: templateName })}
      </div>
      <Tooltip
        content={t("translation:alreadyInterestedClickToUndo", { activityName: task.name })}
        disabled={!interestedIn}
      >
        <div>
          <Button
            text={interestedIn ? t("translation:interested") : t("translation:showInterest")}
            kind="primary"
            small
            onClick={() => {
              handleInterestButtonClick(task.interest_id, task, onMutationFinish, onMutationFinish);
            }}
            disabled={isLoading || isUpdatingActivity}
          />
        </div>
      </Tooltip>

      {isQuestionsModalOpened && (
        <Modal
          classes={{
            dialog: questionsModalCss.questionsModal
          }}
          handleClose={() => closeQuestionsModal()}
        >
          {/* handleClose prop is automatically injected to QuestionsModal */}
          {/* this prop allows to close Modal with animation */}
          <QuestionsModal {...questionModalProps} />
        </Modal>
      )}
    </div>
  );
};

ActivityDetailsWatcherCheckbox.propTypes = {
  currentProfileId: PropTypes.number.isRequired
};

export default ActivityDetailsWatcherCheckbox;
