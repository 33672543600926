import classNames from "classnames";
import { Typography } from "PFComponents/typography";
import { ProfileBasicInformation } from "PFTypes";
import { useTranslation } from "react-i18next";

import { ProfileItem } from "../../../../../../components/profile_item";
import { getPercentValue } from "../../utils";
import css from "./display_option.module.scss";

type DisplayOptionProps = {
  option: ProfileBasicInformation & {
    grade?: string;
    scores: { normalizedScore: number; availabilityScore: number };
  };
};

export const OptionsTableHeader = () => {
  const { t } = useTranslation("activities", { keyPrefix: "show.economics.assignmentForm.options.header" });

  return (
    <div className={classNames(css.grid, css.header)}>
      <Typography variant="labelRegular" noMargin>
        {t("name")}
      </Typography>
      <Typography variant="labelRegular" noMargin>
        {t("grade")}
      </Typography>
      <Typography variant="labelRegular" noMargin>
        {t("match")}
      </Typography>
      <Typography variant="labelRegular" noMargin>
        {t("availability")}
      </Typography>
    </div>
  );
};

export const DisplayOption = ({ option }: DisplayOptionProps) => (
  <div className={css.grid}>
    <ProfileItem profile={option} />
    <span className={css.cell}>
      <Typography variant="bodyBold" noMargin clipOverflow>
        {option.grade || "-"}
      </Typography>
    </span>
    <span className={css.cell}>
      <Typography className={css.cell} variant="bodyBold" noMargin>
        {getPercentValue(option.scores?.normalizedScore)}
      </Typography>
    </span>
    <span className={css.cell}>
      <Typography className={css.cell} variant="bodyBold" noMargin>
        {getPercentValue(option.scores?.availabilityScore)}
      </Typography>
    </span>
  </div>
);
