import ConfirmModal from "PFComponents/confirm/confirm_modal";
import { useTranslation } from "react-i18next";
import { useToggle } from "react-use";

import { useActivityEditFormContext } from "../../activity_edit_form_context";
import { SkillsFrameworksSelectModal } from "../../modals/skills_framework_select_modal";
import { SkillsFrameworksButton } from "./skills_framework_button";

type SetSkillsFrameworkButtonProps = {
  disabled?: boolean;
};

export const SetSkillsFrameworkButton = ({ disabled }: SetSkillsFrameworkButtonProps) => {
  const { t } = useTranslation("skillsFrameworks");
  const { selectedSkillsFrameworks, setSelectedSkillsFrameworks: onChange } = useActivityEditFormContext();

  const [showLinkModal, toggleLinkModal] = useToggle(false);
  const [showUnlinkModal, toggleUnlinkModal] = useToggle(false);
  const clear = () => onChange([]);

  return (
    <>
      <SkillsFrameworksButton
        skillsFrameworks={selectedSkillsFrameworks}
        onAdd={toggleLinkModal}
        onRemove={toggleUnlinkModal}
        disabled={disabled}
      />
      {showLinkModal && (
        <SkillsFrameworksSelectModal
          skillsFrameworks={selectedSkillsFrameworks}
          onSubmit={onChange}
          onClose={toggleLinkModal}
        />
      )}
      {showUnlinkModal && (
        <ConfirmModal
          title={t("setButton.unset.title")}
          handleOK={() => {
            clear();
            toggleUnlinkModal();
          }}
          kindOK="danger"
          handleClose={toggleUnlinkModal}
        >
          {t("setButton.unset.message")}
        </ConfirmModal>
      )}
    </>
  );
};
