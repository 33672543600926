import MarketplaceIcon from "PFIcons/marketplace.svg";
import { useTranslation } from "react-i18next";

import NavItem from "./nav_item";

const MarketplaceNavItem = (props) => {
  const { t } = useTranslation("translation", { keyPrefix: "navBar.items" });
  return (
    <NavItem
      icon={<MarketplaceIcon width={20} height={20} />}
      {...props}
      isActiveTest={(path) => path.match(/^\/marketplace/)}
      name={t("marketplace")}
      id="marketplace"
      href="/marketplace"
    />
  );
};

export default MarketplaceNavItem;
