import { ActionIcon } from "PFComponents/action_icon";
import { Button } from "PFComponents/button";
import { Booking } from "PFTypes";
import { useTranslation } from "react-i18next";

import { DeleteMode } from "./delete_booking.types";
import { useDeleteBookingModalContext } from "./delete_booking_modal_context_provider";

type BookingDeleteButtonProps = {
  className?: string;
  kind?: "icon" | "button";
  deleteMode: DeleteMode;
  booking?: Booking;
  profileFullName: string;
  onDeleted?: (booking: Booking) => Promise<void>;
  isSingleDayBookingTemplate?: boolean;
  disabled?: boolean;
};

const BookingDeleteButton = ({
  className,
  kind = "button",
  deleteMode,
  booking,
  profileFullName,
  onDeleted,
  isSingleDayBookingTemplate,
  disabled
}: BookingDeleteButtonProps): JSX.Element => {
  const { modal } = useDeleteBookingModalContext();
  const { t } = useTranslation("translation");

  const handleClick = () =>
    !!booking &&
    modal.open({
      booking,
      deleteMode,
      profileFullName,
      onDeleted,
      isSingleDayBookingTemplate
    });

  return kind === "icon" ? (
    <ActionIcon
      className={className}
      name="remove"
      size="sm"
      onClick={handleClick}
      disabled={disabled || !booking}
    />
  ) : (
    <Button
      className={className}
      icon="remove"
      text={t("delete")}
      onClick={handleClick}
      kind="secondary"
      disabled={disabled || !booking}
    />
  );
};

export default BookingDeleteButton;
