import SidePanel, { SidePanelTabs } from "PFComponents/side_panel/side_panel";
import { PillTab } from "PFComponents/tabs/pill_tabs";
import { getProfileName } from "PFCore/helpers/profile";
import { useBooking, useBookingInvalidate } from "PFCore/hooks/queries";
import { useBookingNotesInfinite } from "PFCore/hooks/queries/bookings/notes/use_booking_notes";
import { useProfile } from "PFCore/hooks/queries/profile/use_profile";
import { Booking } from "PFTypes";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { DetailsPanelComponentProps, DetailsSidePanelProps } from "../../../../components/details_panel";
import BookingDetail from "./booking_detail";
import { BookingDetailHeader } from "./booking_detail_header";
import { BookingHistory } from "./booking_history";
import { BookingNotes } from "./booking_notes";
import { useBookingNotesPermissions } from "./booking_notes/use_booking_notes_permissions";
import Buttons from "./buttons";
import { useButtonsPermission } from "./buttons/use_buttons_permission";

type TabId = "details" | "notes" | "history";

type BookingDetailSidePanelProps = DetailsSidePanelProps & DetailsPanelComponentProps;

export const BookingDetailSidePanel = React.memo(
  ({ isOnTop, data, onDeleted, onEdited, show, onClose, zIndex }: BookingDetailSidePanelProps) => {
    const { invalidate: invalidateBooking } = useBookingInvalidate();

    const { data: booking, isLoading: isBookingLoading } = useBooking(data?.id, {
      enabled: show && !!data?.id
    });

    const { data: profile } = useProfile(booking?.profile_id, { enabled: show && !!booking?.profile_id });

    const { data: notesData } = useBookingNotesInfinite(booking?.profile_id ?? -1, booking?.id ?? -1, 15, {
      enabled: !!booking?.id
    });

    const { t } = useTranslation("bookingModule");

    const { canSeeActionButtons } = useButtonsPermission({
      bookingEndDate: booking?.end_date,
      isEditPermitted: data?.isEditPermitted
    });
    const { canCreate: canCreateBookingNote } = useBookingNotesPermissions(booking);

    const handleEdit = async () => {
      await invalidateBooking(booking?.id);
      onEdited && onEdited();
    };

    const tabs: PillTab<TabId>[] = [
      {
        id: "details",
        label: t("details.detailsTitle")
      },
      {
        id: "notes",
        label: t("details.notesTitle"),
        count: notesData?.pages?.[0]?.meta?.total ?? 0
      },
      {
        id: "history",
        label: t("details.historyTitle")
      }
    ];

    const containerId = `booking-container-${booking?.id}`;

    const TAB_CONTENT: Record<TabId, ReactNode> = {
      details: <BookingDetail booking={booking} profile={profile} loading={isBookingLoading} />,
      notes: (
        <div id={containerId}>
          <BookingNotes
            readonly={!canCreateBookingNote}
            bookingId={booking?.id as number}
            containerId={containerId}
            profileId={profile?.id as number}
          />
        </div>
      ),
      history: <BookingHistory profileId={profile?.id as number} bookingId={booking?.id as number} />
    };

    const tabsConfig: SidePanelTabs<TabId> = {
      tabs,
      tabsContent: TAB_CONTENT,
      defaultSelectedTabId: "details"
    };

    return (
      <SidePanel
        show={show}
        zIndex={zIndex}
        isSidePanelClosing={data.isClosing}
        onClose={onClose}
        title={<BookingDetailHeader title={booking?.title} />}
        tabsConfig={tabsConfig}
        isOnTop={isOnTop}
        footerRenderer={() =>
          canSeeActionButtons ? (
            <Buttons
              id={data?.id}
              booking={booking as Booking}
              profileFullName={getProfileName(profile)}
              onEdit={handleEdit}
              onDeleted={onDeleted}
              cloneEnabled={!booking?.booking_template_id}
            />
          ) : undefined
        }
      />
    );
  }
);

BookingDetailSidePanel.displayName = "BookingDetailSidePanel";
