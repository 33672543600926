import { useQuery } from "@tanstack/react-query";
import { fetchMatches } from "PFCore/services/matches";
import { Match, PaginatedCollection } from "PFTypes";
import { QueryOptions } from "PFTypes/request_options";

import { matchesKeys } from "./query_keys";

type UseMatchesParams = { page: number; per_page: number };

export const useMatches = (
  activityId: number | string,
  params: UseMatchesParams,
  options: QueryOptions<PaginatedCollection<Match[]>> = {}
) => useQuery(matchesKeys.activity(activityId, params), () => fetchMatches(activityId, params), options);
