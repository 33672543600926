import { range } from "lodash";
import { Icon } from "PFComponents/icon";
import SliderWithTooltip from "PFComponents/slider/slider_with_tooltip";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import css from "./threshold_slider.module.scss";

const THRESHOLD_STYLES = {
  0: "initial",
  100: "higlight"
};

enum ThresholdCap {
  Min = 50,
  Max = 80
}

type ThresholdSliderProps = {
  threshold: number;
  onChange: (threshold: number) => void;
};

export const ThresholdSlider = ({ threshold = ThresholdCap.Min, onChange }: ThresholdSliderProps) => {
  const { t } = useTranslation();

  const showToLowValueWarning = threshold <= ThresholdCap.Min;
  const showToHighValueWarning = threshold >= ThresholdCap.Max;

  const thresholds = useMemo(
    () =>
      range(0, 110, 10).map((value) => ({
        value,
        label: `${value}%`,
        style: THRESHOLD_STYLES[value] || null
      })),
    []
  );

  return (
    <>
      <SliderWithTooltip
        key={threshold}
        style={{ overflow: "initial" }}
        min={0}
        max={10}
        handleChange={(index) => onChange(thresholds[index].value)}
        options={thresholds}
        value={Math.floor(threshold / 10)}
      />
      {(showToLowValueWarning || showToHighValueWarning) && (
        <div className={css.warning}>
          <Icon name="warning" color="paletteOrange0" size="sm" />
          {showToLowValueWarning && t("availabilityRequirement.lowThresholdWarning")}
          {showToHighValueWarning && t("availabilityRequirement.highThresholdWarning")}
        </div>
      )}
    </>
  );
};
