import { Activity, Id, Match, Shortlist } from "PFTypes";
import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { useToggle } from "react-use";

export type MatchCardProfileData = Omit<Match, "profile" | "shortlist"> & {
  profile: Match["profile"] | Shortlist["profile"];
  creator?: Shortlist["creator"];
  shortlist: (Partial<Shortlist> & Pick<Shortlist, "id" | "state">) | null;
};

type MatchCardProviderProps = MatchCardProfileData & {
  role: Activity;
  isBlindModeOn?: boolean;
  isCompressed?: boolean;
  isFillDisabled: boolean;
  isProfileInComparison?: (id: Id) => boolean;
  addProfileToComparison?: (id: Id) => void;
  removeProfileFromComparison?: (id: Id) => void;
};

export type MatchCardContextType = Omit<
  MatchCardProviderProps,
  "isCompressed" | "isBlindModeOn" | "isProfileInComparison"
> & {
  isCompressed: boolean;
  isBlindModeOn: boolean;
  animationTrigger: boolean;
  animate: VoidFunction;
  allowCompare: boolean;
  isProfileSelectedToCompare: boolean;
};

const MatchCardContext = createContext<MatchCardContextType>({} as MatchCardContextType);

export const MatchCardProvider = ({
  children,
  id,
  isCompressed,
  isBlindModeOn,
  isProfileInComparison,
  addProfileToComparison,
  removeProfileFromComparison,
  ...matchCardProps
}: PropsWithChildren<MatchCardProviderProps>) => {
  const [animationTrigger, animate] = useToggle(false);

  const allowCompare = Boolean(
    isProfileInComparison && addProfileToComparison && removeProfileFromComparison
  );

  const contextValue = useMemo(
    () => ({
      id,
      isCompressed: !!isCompressed,
      isBlindModeOn: !!isBlindModeOn,
      animationTrigger,
      animate,
      allowCompare,
      isProfileSelectedToCompare: allowCompare && isProfileInComparison!(id),
      addProfileToComparison,
      removeProfileFromComparison,
      ...matchCardProps
    }),
    [
      id,
      isCompressed,
      isBlindModeOn,
      animationTrigger,
      animate,
      allowCompare,
      isProfileInComparison,
      addProfileToComparison,
      removeProfileFromComparison,
      matchCardProps
    ]
  );
  return <MatchCardContext.Provider value={contextValue}>{children}</MatchCardContext.Provider>;
};

export const useMatchCardContext = () => useContext(MatchCardContext);
