import { useGrowl } from "PFApp/use_growl";
import ConfirmModal from "PFComponents/confirm/confirm_modal";
import { Typography } from "PFComponents/typography";
import { deleteActivity } from "PFCore/services/activities";
import { Activity } from "PFTypes";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { startsWith } from "underscore.string";

type RemoveConfirmModalProps = {
  activity: Activity;
  isAudit: boolean;
  onClose: () => void;
};

export const RemoveConfirmModal = ({ activity, isAudit, onClose }: RemoveConfirmModalProps): JSX.Element => {
  const { t } = useTranslation("core");
  const history = useHistory();
  const location = useLocation();
  const growl = useGrowl();

  return (
    <ConfirmModal
      title={t("activityActions.deleteActivity")}
      handleOK={() => {
        deleteActivity(activity.id).then(
          () => {
            growl({ message: t("activityActions.destroySuccess") });
            if (isAudit) {
              history.push(
                activity.parent_activity_id
                  ? `/audit_planner/control_centre/${activity.parent_activity_id}`
                  : "/audit_planner/control_centre"
              );
            } else if (startsWith(location.pathname, "/activities")) {
              history.push("/");
            } else {
              history.go(0);
            }
          },
          (resp) => {
            const msg =
              resp.status === 404 ? "activityActions.destroyFail_404" : "activityActions.destroyFail";
            growl({ message: t(msg), kind: "error" });
          }
        );
      }}
      handleClose={onClose}
      kindOK="danger"
    >
      <Typography variant="bodyRegular" noMargin>
        {t("activityActions.confirmDelete")}
      </Typography>
    </ConfirmModal>
  );
};
