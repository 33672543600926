import classNames from "classnames";
import useWindowSize from "PFCore/helpers/use_window_size";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

import css from "./nav_dropdown.less";
import NavDropdownItem from "./nav_dropdown_item";

const NavDropdownItemSwitch = ({ item, handleCloseSubMenu }) => {
  if (item.render_condition === undefined || item.render_condition) {
    if (item.content) {
      return <li>{item.content}</li>;
    } else {
      return <NavDropdownItem item={item} handleCloseSubMenu={handleCloseSubMenu} />;
    }
  }

  return null;
};

NavDropdownItemSwitch.propTypes = {
  item: PropTypes.shape({
    render_condition: PropTypes.bool,
    content: PropTypes.node
  }),
  handleCloseSubMenu: PropTypes.func
};

const NavDropdown = ({ shown, items, handleCloseSubMenu, labelledBy }) => {
  const listRef = useRef();
  const [style, setStyle] = useState({});

  const { windowWidth, windowHeight } = useWindowSize();
  useEffect(() => {
    if (!listRef.current) {
      return;
    }

    const newStyle = { top: -20 };

    const height = listRef.current.offsetHeight; // offsetHeight ignores scaling
    const parentRect = listRef.current.parentNode.getBoundingClientRect();

    if (parentRect.top + newStyle.top + height > windowHeight) {
      newStyle.top = windowHeight - height - parentRect.top - 10;
    }

    if (parentRect.top + newStyle.top < 0) {
      newStyle.top = -parentRect.top;
    }

    setStyle(newStyle);
  }, [windowWidth, windowHeight, shown, listRef.current]);

  const onClosePress = (e) => {
    if (e.keyCode === 27) {
      handleCloseSubMenu();
    }
  };

  const onUpdatedFocus = (e) => {
    if (e.relatedTarget && !listRef.current.contains(e.relatedTarget)) {
      handleCloseSubMenu();
    }
  };

  useEffect(() => {
    if (shown) {
      document.addEventListener("keydown", onClosePress);
      listRef.current?.addEventListener("focusout", onUpdatedFocus);
      listRef.current.getElementsByTagName("a")[0].focus();
    } else {
      document.removeEventListener("keydown", onClosePress);
      listRef.current?.removeEventListener("focusout", onUpdatedFocus);
    }
    return () => document.removeEventListener("keydown", onClosePress);
  }, [shown]);

  if (!items) {
    return false;
  }

  return (
    <ul
      className={classNames(css.root, { [css.shown]: shown, [css.hidden]: !shown })}
      ref={listRef}
      style={style}
      data-qa-id="NavDropdown"
      aria-labelledby={labelledBy}
      aria-hidden={!shown}
    >
      {items.map((item, index) => (
        <NavDropdownItemSwitch
          item={item}
          key={`${index}:${item.name || "nav_drop_item_switch"}`}
          handleCloseSubMenu={handleCloseSubMenu}
        />
      ))}
    </ul>
  );
};

NavDropdown.propTypes = {
  shown: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.node,
      href: PropTypes.string,
      action: PropTypes.func,
      actionHref: PropTypes.string,
      actionIcon: PropTypes.node
    })
  ),
  handleCloseSubMenu: PropTypes.func,
  labelledBy: PropTypes.string
};

export default NavDropdown;
