import { Placement } from "@popperjs/core";
import classNames from "classnames";
import { ActionIcon } from "PFComponents/action_icon";
import Tooltip, { TooltipProps } from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import React from "react";
import { useTranslation } from "react-i18next";

import { AssessmentCheckmarks } from "./assessment_checkmarks";
import css from "./simple_badge.module.scss";

export const PlaceholderTooltipContent = () => {
  const { t } = useTranslation("core", { keyPrefix: "simpleBadge" });

  return (
    <div style={{ padding: 10 }}>
      {t("newEntry")}
      <br />
      <small>{t("awaitingAdminApproval")}</small>
    </div>
  );
};

export type BadgeKind = "primary" | "secondary" | "selected" | "faded" | "danger" | "success" | "highlight";

type SimpleBadgeProps = {
  icon?: JSX.Element | null;
  text: string | React.ReactElement | number;
  kind?: BadgeKind;
  classes?: Record<string, string>;
  showTooltip?: boolean;
  small?: boolean;
  style?: React.CSSProperties;
  onClick?: ((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void) | null;
  expandable?: boolean;
  isExpanded?: boolean;
  isLoading?: boolean;
  handleExpanded?: (val: boolean) => void;
  expansionBoxChild?: JSX.Element;
  topRightIcon?: JSX.Element;
  leftIcons?: JSX.Element | JSX.Element[];
  leftIconsTooltipContent?: JSX.Element;
  tooltipContent?: JSX.Element | string | null;
  tooltipTheme?: TooltipProps["theme"];
  tooltipPlacement?: Placement;
  disabled?: boolean;
};

const SimpleBadge = ({
  icon = null,
  text,
  kind = "primary",
  showTooltip,
  small,
  style,
  onClick,
  expandable,
  isExpanded,
  isLoading,
  handleExpanded,
  expansionBoxChild,
  topRightIcon,
  leftIcons,
  tooltipContent = <PlaceholderTooltipContent />,
  leftIconsTooltipContent,
  tooltipTheme = "pf-skill",
  tooltipPlacement = "auto",
  classes,
  disabled
}: SimpleBadgeProps) => {
  const Tag = onClick ? "a" : "div";
  const title = typeof text === "string" ? text : typeof text === "number" ? text.toString() : "";

  return (
    <Tag
      style={style}
      className={classNames(css.root, css[kind], classes?.root, {
        [css.disabled]: disabled,
        [css.small]: small,
        [css.expandable]: expandable,
        [css.loading]: isLoading
      })}
      onClick={(event) => onClick?.(event)}
    >
      {leftIcons && (
        <AssessmentCheckmarks
          title={title}
          tooltipContent={leftIconsTooltipContent}
          showTooltip={showTooltip}
        >
          {leftIcons}
        </AssessmentCheckmarks>
      )}
      <Tooltip
        theme={tooltipTheme}
        disabled={!showTooltip}
        content={tooltipContent}
        placement={tooltipPlacement}
        lazy
        interactive
      >
        <div className={classNames(css.headerWrapper, { [css.isExpandable]: expandable })}>
          <div className={css.textWrapper}>
            {topRightIcon && <div className={css.topRightIcon}>{topRightIcon}</div>}
            {icon && <div className={css.icon}>{icon}</div>}

            <Typography
              variant={small ? "labelRegular" : "bodyRegular"}
              tag="span"
              title={title}
              className={css.text}
              clipOverflow
            >
              {text}
            </Typography>
          </div>
          {expandable && (
            <ActionIcon
              name={isExpanded ? "caret-down" : "caret-right"}
              size="sm"
              onClick={() => handleExpanded?.(!isExpanded)}
            />
          )}
        </div>
      </Tooltip>
      <div
        className={classNames({
          [css.expandBoxClosed]: expandable && !isExpanded
        })}
      >
        {expansionBoxChild}
      </div>
    </Tag>
  );
};

export default SimpleBadge;
