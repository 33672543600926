import moment from "moment";
import { capitalize } from "underscore.string";

import i18n from "../i18n";
export default class DateValue {
  constructor(iso_string) {
    this.set(iso_string);
  }

  format() {
    if (!this.date || !this.date.isValid()) {
      return "";
    }
    return this.date.format(PF.config.date_format);
  }

  formatDateTime() {
    if (!this.date || !this.date.isValid()) {
      return "";
    }
    return this.date.format(PF.config.datetime_format);
  }

  formatHumanDate(options = {}) {
    if (!this.date || !this.date.isValid()) {
      return "";
    }
    let formatted_date = this.date.format(PF.config.date_format);
    if (options.show_timezone) {
      formatted_date += ` ${this.formatLocalTimezone()}`;
    }

    return formatted_date;
  }

  formatHumanDateTime(options = {}) {
    if (!this.date || !this.date.isValid()) {
      return "";
    }
    let formatted_date = this.date.format(PF.config.datetime_format);
    if (options.show_timezone) {
      formatted_date += ` ${this.formatLocalTimezone()}`;
    }

    return formatted_date;
  }

  formatLocalTimezone() {
    return new (Date()
      .toString()
      .match(/\([^)]+\)/)[0])();
  }

  toTime() {
    if (this.date !== null ? this.date.isValid() : undefined) {
      return this.date.unix();
    } else {
      return 0;
    }
  }

  set(iso_string) {
    const iso_date_format = "YYYY-MM-DD HH:mm:ss Z";
    this.date = moment(iso_string, iso_date_format);
  }

  dueFormat() {
    return i18n.t("core:time.inWords.due", { date: this.format() });
  }

  agoFormat() {
    const { value, type } = this.diffValue(moment());
    if (value === 0) {
      return capitalize(i18n.t("core:time.inWords.today"));
    } else {
      return i18n.t("core:time.inWords.ago", {
        time_in_words: i18n.t(`core:time.inWords.${type}`, { count: -value })
      });
    }
  }

  // Uses agoFormat for recent dates, but full date for more distant ones
  //
  // @return [string]
  smartAgoFormat() {
    if (this.date.isBefore(moment().subtract(3, "day"))) {
      return this.date.format(PF.config.date_format);
    } else {
      return this.date.fromNow();
    }
  }

  postUntilFormat() {
    if (this.toTime() * 1000 > Date.now()) {
      return this.leftFormat();
    } else {
      return this.expiredFormat();
    }
  }

  leftFormat() {
    const { value, type } = this.diffValue(moment());
    if (value === 0) {
      return i18n.t("core:time.inWords.expiresToday");
    } else if (value < 0) {
      return this.expiredFormat();
    } else {
      return i18n.t("core:time.inWords.left", {
        time_in_words: i18n.t(`core:time.inWords.${type}`, { count: value })
      });
    }
  }

  expiredFormat() {
    return i18n.t("core:time.inWords.expired", { date: this.format() });
  }

  onWeekdayFormat() {
    const date = this.date._d;
    return i18n.t("core:time.inWords.onWeekday", {
      weekday: i18n.t("core:date.dayNames")[date.getDay()],
      day_in_month: date.getDate(),
      month: i18n.t("core:date.monthNames")[date.getMonth() + 1]
    });
  }

  fromFormat() {
    return i18n.t("core:time.inWords.fromDuration.current", { from: this.format() });
  }

  isValid() {
    return this.date.isValid();
  }

  fromToDurationFormat(to_date) {
    const to = new DateValue(to_date);
    if (this.isValid()) {
      if (to.isValid()) {
        const duration = to.timeSpan(this.date);
        const duration_format = this.getDateTimeSpanFormat(duration);

        return i18n.t("core:time.inWords.fromToDuration.base", {
          from: this.format(),
          to: to.format(),
          duration: duration_format
        });
      } else {
        return i18n.t("core:time.inWords.fromToDuration.current", { from: this.format() });
      }
    } else {
      return ""; // from (@) must be valid
    }
  }

  daysLeft() {
    return this.date.diff(moment(), "days");
  }

  getDateTimeSpanFormat(args) {
    if (args === null) {
      args = undefined;
    }
    if (!args) {
      args = this.timeSpan();
    }
    const format = [];
    if (args.years) {
      format.push(i18n.t("core:time.inWords.years", { count: args.years }));
    }
    if (args.months) {
      format.push(i18n.t("core:time.inWords.months", { count: args.months }));
    }
    if (args.days) {
      format.push(i18n.t("core:time.inWords.days", { count: args.days }));
    }
    return format.join(", ") || i18n.t("core:time.inWords.days", { count: 1 });
  }

  timeSpan(val) {
    let months, years;
    let days = this.date.diff(val, "days");

    if (days > 365) {
      years = this.date.diff(val, "years");
      months = this.date.diff(val, "months") - 12 * years;
      days = 0;
    } else if (days > 60) {
      months = this.date.diff(val, "months");
      days = 0;
    }

    return { days, months, years };
  }

  diffValue(val) {
    let months;
    moment(val).fromNow(true);
    const days = this.date.diff(val, "days");
    if (days > 30 * 2) {
      months = this.date.diff(val, "months");
      return { value: months, type: "months" };
    }
    if (days > 356 * 2) {
      const years = this.date.diff(val, "years");
      return { value: years, type: "years" };
    }

    return { value: days, type: "days" };
  }
}
