import SimpleBadge from "PFCore/components/badges_list/simple_badge";
import { useCustomValue } from "PFCore/hooks/queries/custom_fields/use_custom_value";
import i18n from "PFCore/i18n";
import PropTypes from "prop-types";
import { useState } from "react";

import SkillBadgeMetadata from "./skill_badge_metadata";

const getSkillsSource = (key) =>
  ({
    user_added: i18n.t("core:skillsSource.userAdded"),
    skill_suggestion: i18n.t("core:skillsSource.skillSuggestion"),
    hr_added: i18n.t("core:skillsSource.hrAdded"),
    internal_experience: i18n.t("core:skillsSource.internalExperience")
  }[key]);

const ExpandableBadge = ({
  id,
  icon,
  text,
  kind,
  showTooltip,
  topLeftIcon,
  topRightIcon,
  openByDefault,
  profileId,
  currentProfile,
  style
}) => {
  // pass is expanded as prop as the regular skills are going ot be closed by default
  const [isExpanded, setIsExpanded] = useState(openByDefault || false);

  const { data: skillData, isLoading } = useCustomValue(id, profileId, { enabled: isExpanded });

  const expansionBox = skillData && (
    <SkillBadgeMetadata
      addedOn={skillData?.createdAt}
      internalExperiences={skillData?.internalExperienceIds}
      usedFor={skillData?.seniority} // seniority is received in months
      // remove 1 on matches count as the current user is counted
      skillSource={getSkillsSource(skillData?.source)}
      othersWithIt={skillData?.experienceStats?.total - 1}
      rankingValues={skillData?.experienceStats}
      ownExperience={skillData?.experience}
      isDisabled={kind === "disabled"}
      profileId={profileId}
      currentProfile={currentProfile}
    />
  );

  return (
    <SimpleBadge
      style={style}
      expandable
      isExpanded={isExpanded}
      handleExpanded={setIsExpanded}
      expansionBoxChild={expansionBox}
      icon={icon}
      text={text}
      kind={kind}
      showTooltip={showTooltip}
      leftIcons={topLeftIcon}
      topRightIcon={topRightIcon}
      isLoading={isLoading}
    />
  );
};

ExpandableBadge.propTypes = {
  id: PropTypes.number,
  icon: PropTypes.object,
  text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  kind: PropTypes.string,
  showTooltip: PropTypes.bool,
  topLeftIcon: PropTypes.any,
  topRightIcon: PropTypes.any,
  openByDefault: PropTypes.bool,
  profileId: PropTypes.number,
  currentProfile: PropTypes.object,
  style: PropTypes.object
};

ExpandableBadge.defaultProps = {
  text: ""
};

export default ExpandableBadge;
