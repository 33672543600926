import { Button } from "PFComponents/button";
import { useTranslation } from "react-i18next";

import { useMatchCardContext } from "../match_card_context";

export const AddToComparisonButton = () => {
  const { t } = useTranslation("activities");

  const { id, isProfileSelectedToCompare, addProfileToComparison, removeProfileFromComparison } =
    useMatchCardContext();

  const handleClick = isProfileSelectedToCompare
    ? () => removeProfileFromComparison!(id)
    : () => addProfileToComparison!(id);

  return (
    <Button
      kind={isProfileSelectedToCompare ? "primary" : "blank"}
      icon="compare"
      title={t("show.parts.addToComparison")}
      onClick={(event) => {
        event.stopPropagation();
        handleClick();
      }}
    />
  );
};
