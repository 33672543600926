import { useGrowl } from "PFApp/use_growl";
import ConfirmModal from "PFComponents/confirm/confirm_modal";
import { Typography } from "PFComponents/typography";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { useCertificateDelete } from "PFCore/hooks/queries/certificates";
import { Certificate } from "PFTypes/certificate";
import { useTranslation } from "react-i18next";

import css from "./delete_certificate_modal.module.scss";

type DeleteCertificateModalProps = {
  certificate: Certificate;
  onClose: () => void;
  onDeleted: () => void;
};

const DeleteCertificateModal = ({ certificate, onClose, onDeleted }: DeleteCertificateModalProps) => {
  const growl = useGrowl();
  const { t } = useTranslation(["profiles", "translation"]);
  const { getFirstError } = useResponseErrors();

  const updateCertificateMutation = useCertificateDelete({
    id: certificate.id,
    onError: (response) => {
      const error = getFirstError(response);
      growl({
        message: error || t("translation:somethingWentWrong"),
        kind: "error"
      });
    },
    onSuccess: () => {
      growl({ kind: "success", message: t("profiles:common.certificates.growl.deleted") });
      onDeleted();
    }
  });

  return (
    <ConfirmModal
      title={`${t("profiles:common.certificates.deleteCertificate")}?`}
      labelOK={t("translation:delete")}
      handleOK={() => updateCertificateMutation.mutate(certificate.id)}
      handleClose={onClose}
      kindOK="danger"
    >
      <Typography variant="bodyRegular" className={css.certificateTitle} noMargin>
        {certificate.title}
      </Typography>
    </ConfirmModal>
  );
};

export default DeleteCertificateModal;
