import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { LoadingDots } from "PFComponents/loading_dots";
import Radios from "PFComponents/radios/radios";
import { Dispatch, SetStateAction, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useFutureBookings } from "../../hooks/use_future_bookings";
import { FormBooking } from "../booking_form/use_booking_form";

export enum ReassignSelectOption {
  ThisBooking = "ThisBooking",
  AllBookings = "AllBookings",
  ThisAndFuture = "ThisAndFuture"
}

type BookingsToReassignSelectorProps = {
  profileId: number;
  activityId: number;
  thisBooking: FormBooking;
  reassignOptionSelected: string;
  setReassignOptionSelected: Dispatch<SetStateAction<string>>;
};

export const BookingsToReassignSelector = ({
  profileId,
  activityId,
  thisBooking,
  reassignOptionSelected,
  setReassignOptionSelected
}: BookingsToReassignSelectorProps): JSX.Element => {
  const { t } = useTranslation("bookingModule");

  const { bookings, hasFutureBookings, isFetching } = useFutureBookings({
    thisBooking: { id: thisBooking.id ?? -1, startDate: thisBooking.startDate },
    activityId,
    profileId
  });

  const radioItems = useMemo(() => {
    if (isEmpty(bookings)) {
      return [];
    }
    const hasMoreThanOneBooking = !isNil(bookings.meta?.total) && bookings.meta.total > 1;
    return [
      ...(hasMoreThanOneBooking
        ? [{ id: ReassignSelectOption.ThisBooking, text: t("bookings.reassign.reassignThisBooking") }]
        : []),
      ...(hasFutureBookings
        ? [
            {
              id: ReassignSelectOption.ThisAndFuture,
              text: t("bookings.reassign.reassignThisAndFutureBookings")
            }
          ]
        : []),
      ...(hasMoreThanOneBooking
        ? [
            {
              id: ReassignSelectOption.AllBookings,
              text: t("bookings.reassign.reassignAllBookings", { count: bookings.meta.total })
            }
          ]
        : [])
    ];
  }, [bookings, hasFutureBookings, t]);

  return (
    <div>
      {isFetching && <LoadingDots />}
      {!!bookings && !isEmpty(radioItems) && (
        <Radios<ReassignSelectOption>
          items={radioItems}
          value={reassignOptionSelected}
          handleChange={(item) => setReassignOptionSelected(item.id)}
        />
      )}
    </div>
  );
};
