import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import { MatchCustomFieldSection } from "../../components/match_attribute_section/match_custom_field_section";
import { useEssentialSkills } from "../../hooks/use_essential_skills";
import { useRequiredSkills } from "../../hooks/use_required_skills";
import { useSupportingSkills } from "../../hooks/use_supporting_skills";
import css from "./match_activity_skills.module.scss";

type MatchActivitySkillsProps = {
  className?: string;
};

export const MatchActivitySkills = ({ className }: MatchActivitySkillsProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.matches.card" });
  const { roleRequiredSkills, matchRequiredSkills, missingMatchRequiredSkills } = useRequiredSkills();
  const { roleEssentialSkills, matchEssentialSkills, missingMatchEssentialSkills } = useEssentialSkills();
  const { roleSupportingSkills, matchSupportingSkills, missingMatchSupportingSkills } = useSupportingSkills();

  const renderData = [
    {
      key: "essential",
      matchSkills: matchEssentialSkills,
      roleSkills: roleEssentialSkills,
      missingSkills: missingMatchEssentialSkills,
      customFieldLabel: t("essentialSkills")
    },
    {
      key: "supporting",
      matchSkills: matchSupportingSkills,
      roleSkills: roleSupportingSkills,
      missingSkills: missingMatchSupportingSkills,
      customFieldLabel: t("supportingSkills")
    },
    {
      key: "required",
      matchSkills: matchRequiredSkills,
      roleSkills: roleRequiredSkills,
      missingSkills: missingMatchRequiredSkills,
      customFieldLabel: t("requiredSkills")
    }
  ].filter(({ roleSkills }) => !!roleSkills.length);

  return (
    <div className={classNames(css.skillsContainer, className)}>
      {renderData.map(({ key, matchSkills, roleSkills, missingSkills, customFieldLabel }) => (
        <MatchCustomFieldSection
          key={key}
          customFieldLabel={customFieldLabel}
          customFieldName="skills"
          i18nKey="customFieldCount"
          total={roleSkills.length}
          matchCustomValues={matchSkills}
          missingCustomValues={missingSkills}
        />
      ))}
    </div>
  );
};
