import classNames from "classnames";
import { useDetailsPanelRoleStateContext } from "PFApp/booking/components/details_panel/details_panel_context/details_panel_context";
import { BookingDetailsActivity } from "PFApp/booking/parts/overview/detail_view/booking_detail/booking_details_activity";
import css from "PFApp/booking/parts/overview/detail_view/detail_view.module.scss";
import { useBookingActivityContext } from "PFApp/booking/parts/providers/booking_activity_context_provider";
import { TEMPLATE_KEYS } from "PFApp/constants/templates";
import { Typography } from "PFComponents/typography";
import { getProfileName } from "PFCore/helpers/profile";
import { useBookingActivity } from "PFCore/hooks/queries/activity/use_booking_activity";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { AvailabilityRule } from "PFTypes";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Buttons } from "./buttons";
import { CustomFields } from "./custom_fields";
import { Details } from "./details";
import { Duration } from "./duration";
import { Owners } from "./owners";
import { Skills } from "./skills";

type RoleDetailProps = {
  setAllocateManually: Dispatch<SetStateAction<boolean>>;
};

export const RoleDetail = ({ setAllocateManually }: RoleDetailProps) => {
  const { data: currentProfile } = useCurrentProfile();
  const { openAllocateManually } = useDetailsPanelRoleStateContext();

  const { t } = useTranslation();
  const { activity, canEdit } = useBookingActivityContext();
  const {
    data: parentActivity,
    isLoading: isParentActivityLoading,
    isError: isParentActivityError
  } = useBookingActivity(activity?.parent_activity_id);
  const { bookingCategories } = useBookingCategories();
  const hasWriteAccessToAtLeastOneCategory = bookingCategories
    .filter(({ activity_assignable: demandRelated }) => !!demandRelated)
    .some((i) => !i.readonly);

  const isAuditRole = useMemo(
    () =>
      activity
        ? currentProfile.templates.find(({ id }) => id === activity?.template_id)?.key ===
          TEMPLATE_KEYS.AUDIT_ROLE
        : undefined,
    [activity, currentProfile.templates]
  );

  useEffect(() => {
    if (!openAllocateManually) {
      return;
    }
    if (isAuditRole !== undefined && hasWriteAccessToAtLeastOneCategory) {
      setAllocateManually(!isAuditRole);
    }
  }, [openAllocateManually, isAuditRole, hasWriteAccessToAtLeastOneCategory]);

  if (!activity) {
    return null;
  }

  return (
    <>
      <div className={classNames(css.root, css.roleDetail)}>
        <Owners activity={activity} />
        <div className={css.item}>
          <Typography variant="bodyBold" noMargin>
            {t("description")}
          </Typography>
          <Typography variant="bodyRegular" noMargin>
            {activity.description}
          </Typography>
        </div>
        {parentActivity && !isParentActivityError && (
          <div className={css.item}>
            <div className={css.separator} />
            <BookingDetailsActivity activity={parentActivity} isLoading={isParentActivityLoading} />
            <div className={css.separator} />
          </div>
        )}
        {!!activity.availability && <Duration availability={activity.availability as AvailabilityRule} />}
        <Details activity={activity} />
        <div className={css.separator} />
        {canEdit && !isAuditRole && hasWriteAccessToAtLeastOneCategory && (
          <>
            <Buttons setAllocateManually={setAllocateManually} />
            <div className={css.separator} />
          </>
        )}
        <Skills activity={activity} />
        <CustomFields activity={activity} />
        <div className={css.separator} />
        <div className={css.item}>
          <Typography variant="bodyBold" noMargin>
            {t("creator")}
          </Typography>
          <Typography variant="bodyRegular" noMargin>
            {getProfileName(activity.profile)}
          </Typography>
        </div>
      </div>
    </>
  );
};
