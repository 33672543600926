import { capitalize } from "lodash";
import { useGrowl } from "PFApp/use_growl";
import ConfirmModal from "PFComponents/confirm/confirm_modal";
import AutoSelect from "PFComponents/select/autoselect";
import { Typography } from "PFComponents/typography";
import { useSendInvitation } from "PFCore/hooks/queries/invites/use_send_invitation";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { fetchProfilesOptions } from "PFCore/services/profile";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useActivityHistoryInvalidate } from "../../activities/parts/history/hooks/use_activity_history_invalidate";
import { ProfileItem } from "../profile_item";
import css from "./share_modal.module.scss";

type ShareModalProps = {
  activityType: string;
  activityIds: number[];
  invites?: any[];
  onClose?: VoidFunction;
  onSuccess?: (resp?) => void;
};

export const ShareModal = ({ activityType, activityIds, invites, onClose, onSuccess }: ShareModalProps) => {
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation(["core", "translation"]);
  const growl = useGrowl();

  const invalidateHistory = useActivityHistoryInvalidate(activityIds);

  const [profiles, setProfiles] = useState([]);

  const { mutate: handleShare, isLoading } = useSendInvitation(
    activityIds,
    profiles.map(({ id }) => id),
    {
      onSuccess: (resp) => {
        growl({
          message: activityType
            ? t("core:activityShare.inviteMessage.activitiesShared", {
                activityType: capitalize(activityType)
              })
            : t("core:activityShare.inviteMessage.success")
        });
        onSuccess?.(resp);
        invalidateHistory();
      },
      onError: () =>
        growl({
          kind: "error",
          message: t("core:activityShare.inviteMessage.error")
        })
    }
  );

  const handleClose = () => {
    setProfiles([]);
    onClose?.();
  };

  return (
    <ConfirmModal
      title={
        activityType
          ? t("core:activityShare.shareActivityTemplates", {
              count: activityIds.length,
              templateKey: activityType
            })
          : t("core:activityShare.shareActivities", { count: activityIds.length })
      }
      handleClose={handleClose}
      handleOK={handleShare}
      disableOKButton={profiles.length === 0 || isLoading}
      labelOK={t("core:activityShare.buttonText")}
    >
      <AutoSelect
        key={profiles.length}
        kind="bordered"
        style={{ marginTop: 50 }}
        query={(term) => fetchProfilesOptions({ term })}
        formatOption={(item) => ({
          id: item.id,
          displayElement: <ProfileItem profile={item} />,
          item: item
        })}
        filterOptions={(options) =>
          options.filter(
            (option) =>
              currentProfile.id !== option.id &&
              !profiles.find(({ id }) => id === option.id) &&
              !(invites || []).find(({ profile_id }) => profile_id === option.id)
          )
        }
        label={t("core:activityShare.buttonLabel")}
        placeholder={t("core:activityShare.buttonPlaceholder")}
        value={profiles}
        values={profiles}
        icon="search"
        controlledValue
        closeOnChange
        handleChange={setProfiles}
        multi
        cache={false}
      />
      <div className={css.info}>
        <Typography variant="bodyBold">{t("translation:pleaseNote")}</Typography>
        <Typography variant="bodyRegular">{t("core:activityShare.buttonInfo")}</Typography>
      </div>
    </ConfirmModal>
  );
};
