import { useBookingFormContext } from "PFApp/booking/components/booking_form";
import { BookingFormMode } from "PFApp/booking/components/booking_form/use_booking_form";
import ViewInBookingModuleButton from "PFApp/components/view_in_booking_module_button/view_in_booking_module_button";
import useBookingModulePermissions from "PFApp/use_booking_module_permissions";
import useIsAvailabilityPermitted from "PFApp/use_is_availability_permitted";
import { ActionIcon } from "PFComponents/action_icon";
import { Button } from "PFComponents/button";
import { Icon } from "PFComponents/icon";
import MultiToggle, { Option } from "PFComponents/multi_toggle/multi_toggle";
import { useBookingApprovalsInvalidate } from "PFCore/hooks/queries/bookings/approvals/use_booking_approvals_invalidate";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { Profile } from "PFTypes";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useProfileBookingsContext } from "../../../../common/profile_bookings_provider";
import css from "./availability_section_header.module.scss";

type HeaderToggleType = "calendar" | "list";

type AvailabilitySectionHeaderProps = {
  activeTab: HeaderToggleType;
  setActiveTab: (newTab: HeaderToggleType) => void;
  profile: Profile;
  isLoading: boolean;
  isMe: boolean;
  onExpand: () => void;
  isExpanded: boolean;
  shouldHideExpandAction: boolean;
};

export const AvailabilitySectionHeader = ({
  activeTab,
  setActiveTab,
  profile,
  isMe,
  isExpanded,
  onExpand,
  shouldHideExpandAction,
  isLoading
}: AvailabilitySectionHeaderProps) => {
  /* @ts-ignore */
  const canEditAvailability = useIsAvailabilityPermitted(profile, ["rw"]) && isMe;
  const { bookingCategories } = useBookingCategories();
  const { t } = useTranslation(["profiles", "availability"]);

  const { refreshBookings } = useProfileBookingsContext();
  const { invalidate: invalidateBookingApprovals } = useBookingApprovalsInvalidate();

  const { modal } = useBookingFormContext();

  const options = useMemo<Option<HeaderToggleType>[]>(
    () => [
      {
        id: "calendar",
        value: <Icon name="calendar" size="sm" />
      },
      { id: "list", value: <Icon name="list" size="sm" /> }
    ],
    []
  );

  const { canProfileCreateBooking } = useBookingModulePermissions();
  const canSeeBookingModule = canProfileCreateBooking(profile.id);
  const hasWriteAccessToAtLeastOneCategory = bookingCategories
    .filter(({ activity_assignable: demandRelated }) => !demandRelated)
    .some((i) => !i.readonly);

  const handleBookingsUpdate = () => {
    invalidateBookingApprovals();
    refreshBookings();
  };

  return (
    <div className={css.container}>
      <div className={css.label_wrapper}>{t("profiles:show.parts.availability")}</div>
      <div className={css.content}>
        <MultiToggle<HeaderToggleType>
          defaultSelected={activeTab}
          options={options}
          onChange={setActiveTab}
          classes={{ container: css.multiToggle }}
        />
        {!shouldHideExpandAction && (
          <ActionIcon name={isExpanded ? "collapse" : "expand"} size="sm" onClick={onExpand} />
        )}
        {canSeeBookingModule && <ViewInBookingModuleButton workforceMemberId={profile.id} />}
        {canEditAvailability && (
          <Button
            kind="primary"
            onClick={() =>
              modal.open(
                { onSubmit: handleBookingsUpdate, profileId: profile.id },
                { mode: BookingFormMode.Create }
              )
            }
            disabled={isLoading || !hasWriteAccessToAtLeastOneCategory}
          >
            {t("availability:actions.book")}
          </Button>
        )}
      </div>
    </div>
  );
};
