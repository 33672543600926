import { isArray, noop } from "lodash";
import { Button, ButtonProps } from "PFComponents/button";
import DropDown, { DropdownOption, DropDownProps } from "PFComponents/dropdown/dropdown";
import useClickOutside from "PFCore/helpers/use_click_outside";
import { IconName } from "PFTheme/graphics/icons";
import {
  CSSProperties,
  ForwardRefExoticComponent,
  MouseEvent,
  ReactNode,
  RefAttributes,
  useRef,
  useState
} from "react";
import { usePopper } from "react-popper";

type DropdownButtonProps = {
  label?: ReactNode;
  children?: ReactNode;
  icon?: IconName;
  text?: string;
  small?: boolean;
  style?: CSSProperties | null;
  className?: string;
  disabled?: boolean;
  qaId?: string;
  options: DropdownOption[];
  buttonKind?: ButtonProps["kind"];
  buttonStyle?: CSSProperties;
  buttonClassName?: string;
  dropDownStyle?: CSSProperties;
  dropDownClassName?: string;
  dropdownProps?: Partial<DropDownProps>;
  handleChange: (item: any) => void;
  handleClose?: () => void;
  handleClick?: (event: any, menuShown: boolean) => void;
  popperOptions?: object;
  customButtonComponent?: ForwardRefExoticComponent<
    {
      disabled: boolean;
      onClick: (event: MouseEvent) => void;
    } & RefAttributes<HTMLButtonElement>
  >;
};

const DropdownButton = ({
  label,
  icon,
  text,
  small,
  style = null,
  className,
  disabled = false,
  qaId = "Button",
  options,
  buttonKind,
  buttonStyle,
  buttonClassName,
  dropDownStyle,
  dropDownClassName,
  dropdownProps = {},
  children,
  handleChange,
  handleClose,
  handleClick = noop,
  popperOptions,
  customButtonComponent: CustomButtonComponent
}: DropdownButtonProps): JSX.Element => {
  const [menuShown, setMenuShown] = useState(false);
  const [popperReferenceElement, setPopperReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(popperReferenceElement, popperElement, {
    placement: "auto-end",
    strategy: "fixed",
    ...popperOptions
  });

  const menuRef = useRef<HTMLDivElement>(null);
  useClickOutside(menuRef, () => {
    setMenuShown(false);
    handleClose && handleClose();
  });

  const defaultStyle: React.CSSProperties = {
    minWidth: 100,
    maxWidth: 300,
    textAlign: "left",
    position: "relative",
    display: "block",
    marginBottom: 0,
    ...dropDownStyle
  };

  const childContent =
    label || children
      ? {
          children: (
            <>
              {label}
              {children}
            </>
          )
        }
      : { icon, text };

  const button = CustomButtonComponent ? (
    <CustomButtonComponent
      ref={setPopperReferenceElement}
      disabled={disabled}
      onClick={(event) => {
        event.stopPropagation();
        setMenuShown(!menuShown);
        handleClick && handleClick(event, !menuShown);
      }}
    />
  ) : (
    <Button
      {...childContent}
      ref={setPopperReferenceElement}
      small={small}
      kind={buttonKind}
      disabled={disabled}
      qaId={qaId}
      onClick={(event) => {
        event.stopPropagation();
        setMenuShown(!menuShown);
        handleClick && handleClick(event, !menuShown);
      }}
      style={buttonStyle}
      className={buttonClassName}
    />
  );

  return (
    <div
      ref={menuRef}
      className={className}
      style={{ position: "relative", display: "inline-block", ...style }}
    >
      {button}
      {menuShown && (
        <div ref={setPopperElement} style={{ ...styles.popper, zIndex: 20 }} {...attributes.popper}>
          <DropDown
            style={defaultStyle}
            rootClassName={dropDownClassName}
            options={options}
            handleChange={(item) => {
              if (!isArray(item)) {
                !item.keepOpen && setMenuShown(false);
              }

              handleChange && handleChange(item);
            }}
            handleClose={() => {
              setMenuShown(false);
              handleClose && handleClose();
            }}
            {...dropdownProps}
          />
        </div>
      )}
    </div>
  );
};

export default DropdownButton;
