import { Button } from "PFComponents/button/button";
import { TableSorting } from "PFComponents/table/table_sorting";
import { HistorySortDirection } from "PFTypes";
import { useTranslation } from "react-i18next";

import { useActivityHistoryContext } from "../../activity_history_context";
import { useSortedHistoryEntries } from "../../hooks/use_sorted_history_entries";

export const HistorySort = () => {
  const { t } = useTranslation("activities", { keyPrefix: "show.history.sort" });
  const { sort: direction, setSort } = useActivityHistoryContext();
  const entries = useSortedHistoryEntries();

  if (entries.length === 0) {
    return null;
  }

  const isFromLatest = direction === HistorySortDirection.FromLatest;

  return (
    <Button
      kind="blank"
      onClick={() =>
        setSort(isFromLatest ? HistorySortDirection.FromOldest : HistorySortDirection.FromLatest)
      }
    >
      {t(isFromLatest ? "fromLatest" : "fromOldest")}
      <TableSorting isSorted={direction} />
    </Button>
  );
};
