import { usePreviousValue } from "PFCore/hooks/use_previous_value";
import { Activity, Id, Match, Shortlist } from "PFTypes";
import { useRef } from "react";
import { Transition } from "react-transition-group";

import { MatchCardProfileData, MatchCardProvider } from "./match_card_context";
import { AnimatedMatchCard } from "./parts/animated_match_card";

type MatchCardProps = {
  role: Activity;
  isCompressed?: boolean;
  isBlindModeOn?: boolean;
  isFillDisabled?: boolean;
  isProfileInComparison?: (id: Id) => boolean;
  addProfileToComparison?: (id: Id) => void;
  removeProfileFromComparison?: (id: Id) => void;
} & ConditionalMatchCardProps;

type ConditionalMatchCardProps =
  | {
      match: Match;
      shortlist?: never;
    }
  | {
      shortlist: Shortlist;
      match?: Match;
    };

export const MatchCard = ({
  isCompressed,
  isBlindModeOn,
  match,
  shortlist,
  isFillDisabled = false,
  ...cardProps
}: MatchCardProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const wasCompressed = usePreviousValue(isCompressed);

  const parsedMatch: MatchCardProfileData = {
    id: match?.id ?? shortlist!.profile.id,
    profile: match?.profile ?? shortlist!.profile,
    // eslint-disable-next-line camelcase
    custom_values: match?.custom_values ?? { direct: [], indirect: [], related: [] },
    customValues: match?.customValues ?? { direct: [], indirect: [], related: [] },
    scores: match?.scores ?? {
      score: null,
      direct: null,
      indirect: null,
      related: null,
      normalized_score: null, // eslint-disable-line camelcase
      normalized_direct: null, // eslint-disable-line camelcase
      normalized_indirect: null, // eslint-disable-line camelcase
      normalized_related: null, // eslint-disable-line camelcase
      availability_score: null // eslint-disable-line camelcase
    },
    shortlist: shortlist || match.shortlist,
    creator: shortlist?.creator,
    // eslint-disable-next-line camelcase
    optional_fields: match?.optional_fields || shortlist?.optional_fields
  };

  return (
    <Transition nodeRef={ref} in={isCompressed} timeout={300}>
      {(state) => (
        <MatchCardProvider
          {...parsedMatch}
          isCompressed={
            state === "exiting" || state === "entering" ? wasCompressed || undefined : isCompressed
          }
          isBlindModeOn={isBlindModeOn}
          isFillDisabled={isFillDisabled}
          {...cardProps}
        >
          <AnimatedMatchCard ref={ref} state={state} />
        </MatchCardProvider>
      )}
    </Transition>
  );
};
