import safePrune from "PFCore/helpers/safe_prune";
import CashIcon from "PFIcons/cash.svg";
import PropTypes from "prop-types";

import css from "./grade_and_rate_cell.less";

const GradeAndRateCell = ({ customTypes, getValue }) => {
  const gradeDisplayAs = customTypes.find(({ name }) => name === "grade").display_as;
  const grade = getValue("grade");
  const rate = getValue("day_rate");
  const gradeLength = `${gradeDisplayAs}: ${grade ? grade.text : "-"}`.length;
  const pruned = safePrune(grade ? grade.text : "-", gradeLength > 50 ? 20 : 50);

  return (
    <div className={css.root}>
      <div>
        <span>{gradeDisplayAs}:</span> <b title={grade ? grade.text : "-"}>{pruned}</b>
      </div>
      {rate && (
        <div>
          <CashIcon width={20} height={20} /> <b>{rate.text}</b>
        </div>
      )}
    </div>
  );
};

GradeAndRateCell.propTypes = {
  customTypes: PropTypes.array.isRequired,
  getValue: PropTypes.func.isRequired
};

export default GradeAndRateCell;
