import classNames from "classnames";
import { Typography } from "PFCore/components/typography";
import { Id } from "PFTypes";

import css from "./pill_tabs.module.scss";

export type PillTab<T> = {
  id: T;
  label: string;
  count?: number;
};

type PillTabsProps<T> = {
  tabs: PillTab<T>[];
  selectedId: T;
  onTabClick: (id: T) => void;
  className?: string;
};

/**
 * @deprecated Use Tabs instead
 */
export const PillTabs = <T extends Id>({
  tabs,
  selectedId,
  onTabClick,
  className
}: PillTabsProps<T>): JSX.Element => (
  <div className={classNames(css.pillTabs, className)}>
    {tabs.map(({ id, count, label }) => {
      const isSelected = id === selectedId;

      return (
        <button
          key={id}
          onClick={() => onTabClick(id)}
          className={classNames(css.tabButton, { [css.selected]: isSelected })}
        >
          <Typography variant={isSelected ? "bodyBold" : "bodyRegular"} noMargin>
            {label}
          </Typography>
          {count && (
            <Typography variant="labelBold" className={css.count} noMargin>
              {count}
            </Typography>
          )}
        </button>
      );
    })}
  </div>
);
