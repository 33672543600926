import { useInfiniteQuery, UseInfiniteQueryOptions } from "@tanstack/react-query";
import { reverse } from "lodash";

import {
  fetchMessages,
  FetchMessagesPayload,
  FetchMessagesResponse
} from "../../../services/chat/fetch_messages";
import { chatKeys } from "./query_keys";

export const useChatMessages = (
  payload: FetchMessagesPayload,
  options: Omit<
    UseInfiniteQueryOptions<FetchMessagesResponse>,
    "queryKey" | "queryFn" | "getNextPageParam"
  > & {
    placeholderData?: () => undefined;
  } = {}
) => {
  const queryKey = chatKeys.messages(payload.conversationId || "");

  return useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam = 1 }) => {
      const { entries, meta } = await fetchMessages({
        ...payload,
        page: pageParam
      });

      return {
        entries: reverse(entries),
        meta
      };
    },
    getNextPageParam: (_, pages) => pages.length + 1,
    select: (data) => ({
      pages: [...data.pages].reverse(),
      pageParams: [...data.pageParams].reverse()
    }),
    placeholderData: { pages: { entries: [], meta: { total: 0 } }, pageParams: [] } as any,
    ...options
  });
};
