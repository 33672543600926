import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { Button } from "PFComponents/button";
import { useShortlistAssign } from "PFCore/hooks/queries/shortlists/use_shortlist_actions";
import { useTranslation } from "react-i18next";

import { useRoleProfileActionButtonsContext } from "../context/role_profile_action_buttons_context";
import { ProfileActionButtonsProps } from "./profile_action_buttons_props";
import css from "./shortlist_buttons.module.scss";

export const InviteButton = ({ onError, onSuccess }: ProfileActionButtonsProps) => {
  const { t } = useTranslation("activities");
  const { task } = useActivityPageContext();
  const { shortlist } = useRoleProfileActionButtonsContext();
  const { mutate: assignShortlist, isLoading } = useShortlistAssign();
  const isAlreadyInvited = shortlist!.state === "assigned";

  const handleClick = () => {
    assignShortlist(shortlist!.id, { onSuccess: () => onSuccess?.("invite"), onError: () => onError?.() });
  };

  const labelText = isAlreadyInvited ? t("show.parts.invited") : t("show.parts.invite");
  const reviewing = task.review && task.review.state !== "complete";

  return (
    <Button
      onClick={(event) => {
        event.stopPropagation();
        handleClick();
      }}
      disabled={isAlreadyInvited || reviewing}
      className={css.shortlistButton}
    >
      {!isLoading ? labelText : "..."}
    </Button>
  );
};
