import { Avatar } from "PFComponents/avatar";
import BadgesList from "PFComponents/badges_list/badges_list";
import PropTypes from "prop-types";

import css from "./cards.module.scss";

const ChampionsCard = ({ item }) => (
  <div className={css.champions}>
    <Avatar size={60} imagePath={item.avatar.thumbnail_url} />
    <div className={css.content}>
      <div className={css.name}>{`${item.avatar.name} is an expert in:`}</div>
      <BadgesList values={item.skills.map((item) => ({ text: item }))} />
    </div>
  </div>
);

ChampionsCard.propTypes = {
  item: PropTypes.shape({
    avatar: PropTypes.shape({
      thumbnail_url: PropTypes.string,
      name: PropTypes.string
    }),
    skills: PropTypes.array
  })
};

export default ChampionsCard;
