import { isObject } from "lodash";
import moment from "moment";
import {
  filterValueIsInteger,
  isAvailabilityRule,
  isDateWithRangesValue,
  isDateWithSingleRangeValue
} from "PFCore/helpers/filters/type_checks";
import { Filter, FilterPreviewItem, FilterType } from "PFCore/types/filters";

type TextFromFilterValue = {
  filter: Filter;
  formatDate: (rawDate: any) => string;
};

export const getTextFromFilterValue = ({ filter, formatDate }: TextFromFilterValue): string => {
  const { title, value } = filter;

  if (filterValueIsInteger(value)) {
    const min = value.min ?? ("statistics" in filter ? filter.statistics.min : 0);
    const max = value.max ?? ("statistics" in filter ? filter.statistics.max : 0);
    const valueMin = Math.max(value.gte ?? min, min);
    const valueMax = Math.min(value.lte ?? max, max);
    if (valueMin === valueMax) {
      return `${title}: ${valueMin}`;
    }
    return `${title}: ${valueMin} - ${valueMax}`;
  }

  if (isDateWithRangesValue(value) || isAvailabilityRule(value)) {
    const minDate = formatDate(moment.min(value.ranges.map((range) => moment(range.start))));
    const maxDate = formatDate(moment.max(value.ranges.map((range) => moment(range.end))));

    return `${minDate} - ${maxDate}`;
  }

  if (isDateWithSingleRangeValue(value)) {
    return `${formatDate(value.start)} - ${formatDate(value.end)}`;
  }

  if (Array.isArray(value)) {
    return (isObject(value[0]) ? value[0]?.text : value[0].toString()) ?? "";
  }

  if (isObject(value)) {
    return value.text;
  }

  if (value === true || value === false || typeof value === "number") {
    return value.toString();
  }

  return value ?? "";
};

const SKIP_FILTER_TYPES: FilterType[] = ["radio", "toggle_checkbox"];

export type FilterWithChildrenFlag = Filter & { children?: boolean };
export const mapToFilterPreviewItems = (filters: FilterWithChildrenFlag[] = []): FilterPreviewItem[] =>
  filters
    .filter(({ value }) => !!value)
    .filter((filter) => !SKIP_FILTER_TYPES.includes(filter.type))
    .map((filter) =>
      [filter.value].flat().map((value) => ({ ...filter, originalValue: filter.value, value: value }))
    )
    .flat();
