import { omit } from "lodash";
import { CustomValuesEditField } from "PFApp/components/custom_values_edit_field";
import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import { allowsNewValues } from "PFCore/helpers/custom_type";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { FeatureFlag } from "PFTypes";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { MatchAttributes } from "../../components/match_attributes";
import { useCustomFieldLink } from "../../hooks";
import ComponentPropTypes from "./component_prop_types";
import css from "./custom_Values_edit_field_wrapper.module.scss";
import { SkillsCustomValuesEditFieldWrapper } from "./skills_wrapper";

const CustomValuesEditFieldWrapper = ({
  property,
  label,
  onChange,
  value,
  error,
  customType,
  connectedFields,
  currentProfile,
  name,
  description,
  qaIdPrefix,
  required,
  usedOn
}) => {
  const isEnabled = useIsFeatureEnabled();
  const isSkillsCustomType = customType.name === "skills";
  const activityCanCreateValues = isEnabled(FeatureFlag.ActivityCanCreateValues);
  const { t } = useTranslation("activities");

  const handleRelevantAttributeChange = (values) => {
    const formattedValues = values.map((value) => omit({ text: value.value, ...value }, ["weight"]));
    onChange(formattedValues);
  };

  const { isLinked, canLink, restore } = useCustomFieldLink(customType, value);

  if (isSkillsCustomType) {
    return (
      <SkillsCustomValuesEditFieldWrapper
        property={property}
        label={label}
        onChange={onChange}
        value={value}
        error={error}
        customType={customType}
        connectedFields={connectedFields}
        currentProfile={currentProfile}
        name={name}
        description={description}
        qaIdPrefix={qaIdPrefix}
        required={required}
        usedOn={usedOn}
      />
    );
  }

  return (
    <>
      <CustomValuesEditField
        key={(value || []).map((item) => item.id).join()}
        label={label}
        tip=""
        customType={customType}
        connectedFields={connectedFields}
        values={value}
        multi={property.kind === "multiple"}
        qaIdPrefix={qaIdPrefix}
        adminPage={false}
        errors={error}
        letClear
        letCreate={activityCanCreateValues && allowsNewValues(customType)}
        handleChange={onChange}
        required={required}
        hasValuesWithExpiryDate={false}
        isActivity
        onRestore={!isLinked && canLink ? restore : undefined}
        restoreLabel={t("edit.engagementLink.restore")}
      />

      {isLinked && (
        <div className={css.engagementLinkInfo}>
          <Icon name={"info"} />
          <Typography variant={"bodyRegular"} noMargin>
            {t("edit.engagementLink.valueLinked")}
          </Typography>
        </div>
      )}

      {property.match && (
        <MatchAttributes
          name={name}
          description={description}
          attributes={value}
          onMatchAttributeChange={handleRelevantAttributeChange}
          userId={currentProfile.id}
          usedOn={usedOn}
          type={customType.name}
        />
      )}
    </>
  );
};

CustomValuesEditFieldWrapper.propTypes = {
  ...ComponentPropTypes,
  value: PropTypes.array,
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  customType: PropTypes.object,
  connectedFields: PropTypes.array,
  currentProfile: PropTypes.object,
  qaIdPrefix: PropTypes.string
};

export default CustomValuesEditFieldWrapper;
