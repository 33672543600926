import { Moment } from "moment";
import { isBookingWithTime } from "PFApp/booking/parts/helpers";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Dispatch, SetStateAction, useState } from "react";

interface UseStartEnd {
  booking: { startDate: string; endDate: string };
  specificTime: boolean;
  startDateSpecificTime: Moment;
  setStartDateSpecificTime: Dispatch<SetStateAction<Moment>>;
  endDateSpecificTime: Moment;
  setEndDateSpecificTime: Dispatch<SetStateAction<Moment>>;
}

interface UseStartEndReturn {
  start: Moment;
  setStart: Dispatch<SetStateAction<Moment>>;
  end: Moment;
  setEnd: Dispatch<SetStateAction<Moment>>;
}

const getStart = (
  booking: { startDate: string; endDate: string },
  utc: (date?: unknown) => Moment
): Moment => {
  if (booking?.startDate) {
    return isBookingWithTime(utc(booking.startDate), utc(booking.endDate))
      ? utc(booking.startDate)
      : utc(booking.startDate).startOf("day");
  }
  return utc().startOf("day");
};

const getEnd = (booking: { startDate: string; endDate: string }, utc: (date?: unknown) => Moment): Moment => {
  if (booking?.endDate) {
    return isBookingWithTime(utc(booking.startDate), utc(booking.endDate))
      ? utc(booking.endDate)
      : utc(booking.endDate).endOf("day");
  }
  return utc().endOf("day");
};

export const useStartEnd = ({
  booking,
  specificTime,
  startDateSpecificTime,
  setStartDateSpecificTime,
  endDateSpecificTime,
  setEndDateSpecificTime
}: UseStartEnd): UseStartEndReturn => {
  const { utc } = useDateFormatter();

  const [startDateLoad, setStartDateLoad] = useState<Moment>(getStart(booking, utc));
  const [endDateLoad, setEndDateLoad] = useState<Moment>(getEnd(booking, utc));

  const start = specificTime ? startDateSpecificTime : startDateLoad;
  const setStart = specificTime ? setStartDateSpecificTime : setStartDateLoad;
  const end = specificTime ? endDateSpecificTime : endDateLoad;
  const setEnd = specificTime ? setEndDateSpecificTime : setEndDateLoad;

  return {
    start,
    setStart,
    end,
    setEnd
  };
};
