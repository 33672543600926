import { ProfileAvatarConnected } from "PFApp/components/profile_avatar_connected";
import { Avatar } from "PFComponents/avatar";
import { profileHash } from "PFCore/helpers/profile_hash";
import { ProfileNameLink } from "PFCore/helpers/profile_name_link";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Availability from "../../../profiles/common/availability";
import css from "./compare_availability_mobile_page.less";

const CompareAvailabilityMobilePage = ({ profiles, isBlindModeOn }) => {
  const { t } = useTranslation("activities");

  return (
    <div className={css.root}>
      {profiles.map((profile) => (
        <div key={profile.id} className={css.item}>
          {isBlindModeOn ? (
            <Avatar size={50} backgroundColor="#ffc0cb" text={profileHash(profile.id)} />
          ) : (
            <ProfileAvatarConnected profile={profile} size={50} smartLink />
          )}
          <div className={css.name}>
            {isBlindModeOn ? (
              t("compare.candidateHash", { profileHash: profileHash(profile.id) })
            ) : (
              <ProfileNameLink profile={profile} />
            )}
          </div>
          <Availability activeViews={["calendar"]} profile={profile} readOnly={true} />
        </div>
      ))}
    </div>
  );
};

CompareAvailabilityMobilePage.propTypes = {
  profiles: PropTypes.array,
  isBlindModeOn: PropTypes.bool
};

export default CompareAvailabilityMobilePage;
