import { useMutation } from "@tanstack/react-query";
import { createDictionaryConnection, CreateDictionaryConnectionParams } from "PFCore/services/custom_values";
import { MutationOptions } from "PFTypes/request_options";

import { ActivitiesCollection } from "../activities/query_keys";
import { useActivitiesInvalidate } from "../activities/use_activities_invalidate";
import { useProfileInvalidate } from "../profile/use_profile_invalidate";

export const useDictionaryConnectionCreate = ({
  onSuccess,
  ...options
}: MutationOptions<CreateDictionaryConnectionParams> = {}) => {
  const { invalidateCurrentProfile } = useProfileInvalidate();
  const { invalidateByCollection } = useActivitiesInvalidate();

  return useMutation({
    mutationFn: (payload: CreateDictionaryConnectionParams) => createDictionaryConnection(payload),
    onSuccess: (data, ...params) => {
      invalidateCurrentProfile();
      invalidateByCollection(ActivitiesCollection.MatchingActivityStatus);
      onSuccess?.(data, ...params);
    },
    ...options
  });
};
