import { useCurrentAccount } from "PFCore/hooks/queries/account";
import GlobeIcon from "PFIcons/globe.svg";
import { useTranslation } from "react-i18next";

import NavItem from "./nav_item";

const CustomLinksNavItem = (props) => {
  const { t } = useTranslation("translation", { keyPrefix: "navBar.items" });
  const { data: currentAccount } = useCurrentAccount();
  const customization = currentAccount.customization || {};
  const customLinks = customization.custom_links || [];

  return (
    customLinks.length !== 0 && (
      <NavItem
        icon={<GlobeIcon width={20} height={20} />}
        name={t("links")}
        id="external"
        items={customLinks.map((link) => ({
          name: link.title,
          href: link.url,
          target: "_blank",
          external: true
        }))}
        dropdownBottom={true}
        {...props}
      />
    )
  );
};

export default CustomLinksNavItem;
