import colors from "PFTheme/tokens/colors";
import { Currency, EconomicsScenario } from "PFTypes";
import { useTranslation } from "react-i18next";

import { BarChart } from "./bar_chart";

type Unit = "m" | "k";
type BudgetVsCostChartProps = {
  budget: number;
  cost: EconomicsScenario["cost"];
};

export const getNumberInUnits = (number: number, unit?: Unit): { value: number; unit?: Unit } => {
  if (unit === "m" || number > 999999) {
    return { value: number / 1000000, unit: "m" };
  }
  if (unit === "k" || number > 999) {
    return { value: number / 1000, unit: "k" };
  }
  return { value: number };
};

export const BudgetVsCostChart = ({ budget = 0, cost = { value: 0 } }: BudgetVsCostChartProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.economics.scenario.charts.budgetVsCost" });
  const { unit, value: budgetValue } = getNumberInUnits(budget);
  return (
    <BarChart
      reversePositive
      title={t("title")}
      symbol={(cost.currency && Currency[cost.currency]) || ""}
      unit={unit}
      series={[
        {
          name: t("budget"),
          type: "bar",
          data: [{ value: budgetValue, unit }],
          itemStyle: { color: colors.paletteEcharts0 },
          barMaxWidth: 5
        },
        {
          name: t("cost"),
          type: "bar",
          data: [{ value: unit ? getNumberInUnits(cost.value, unit).value : cost.value, unit }],
          itemStyle: { color: colors.paletteEcharts1 },
          barMaxWidth: 5,
          barGap: "0%"
        }
      ]}
    />
  );
};
