import classNames from "classnames";
import { flatten, isEqual, uniqBy } from "lodash";
import { Button } from "PFComponents/button";
import InlineCalendar from "PFComponents/calendar/inline_calendar";
import { LoadingDots } from "PFComponents/loading_dots";
import AutoSelect from "PFComponents/select/autoselect";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import Toggle from "PFComponents/toggle/toggle";
import { useMatchableTypes } from "PFCore/helpers/activities";
import { getId } from "PFCore/helpers/get_id";
import useDebounce from "PFCore/helpers/use_debounce";
import useWindowSize from "PFCore/helpers/use_window_size";
import { useKeywords } from "PFCore/hooks/queries/bookings/keywords/use_keywords";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { fetchAutocompleteSkills } from "PFCore/services/autocomplete/fetch_autocomplete_custom_values";
import { FetchUrlMetadataResponse } from "PFCore/services/common";
import { KeywordsParams } from "PFCore/services/keywords";
import AddIconSvg from "PFIcons/add.svg";
import { Keyword } from "PFTypes";
import { Certificate, CertificatePayload } from "PFTypes/certificate";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./edit_certificate_form.module.scss";

type EditCertificateFormProps = {
  onSubmit: (certificate: CertificatePayload) => void;
  onClose: () => void;
  certificate?: Certificate;
  errors?: any[];
  urlRetrievedData?: FetchUrlMetadataResponse | null;
  isSubmitting: boolean;
  onFormChange: (isChanged: boolean) => void;
};

const EditCertificateForm = ({
  onSubmit,
  onClose,
  certificate,
  errors,
  urlRetrievedData,
  isSubmitting,
  onFormChange
}: EditCertificateFormProps) => {
  const { data: currentProfile } = useCurrentProfile();
  const { templates } = currentProfile;

  const { formatISODate } = useDateFormatter();
  const { t: defaultT } = useTranslation("translation");
  const { t } = useTranslation("profiles", { keyPrefix: "common.certificates" });

  const defaults = {
    title: certificate?.title || urlRetrievedData?.title || "",
    issuer: certificate?.issuer || "",
    issueDate: certificate?.issueDate || null,
    expiryDate: certificate?.expiryDate || null,
    expires: certificate?.expiryDate ? true : false,
    skills: certificate?.skills || []
  };

  const [title, setTitle] = useState(defaults.title);
  const [issuer, setIssuer] = useState(defaults.issuer);
  const [issueDate, setIssueDate] = useState(defaults.issueDate);
  const [expiryDate, setExpiryDate] = useState(defaults.expiryDate);
  const [expires, setExpires] = useState(defaults.expires);
  const [skills, setSkills] = useState(defaults.skills);
  const [suggestedSkills, setSuggestedSkills] = useState<Keyword[]>([]);

  const findError = (key) => errors?.find((error) => error?.source?.pointer?.search(key) > 0)?.detail;

  const searchTemplate = templates.find(({ key }) => key === "task");
  const matchableTypeNames = useMatchableTypes(searchTemplate?.id).map(({ name }) => name);
  const text = `${title} ${urlRetrievedData?.description || ""}`;

  useEffect(() => {
    onFormChange(!isEqual(defaults, { title, issuer, issueDate, expiryDate, expires, skills }));
  }, [title, issuer, issueDate, expiryDate, skills]);

  useEffect(() => {
    setTitle(urlRetrievedData?.title || certificate?.title || "");
  }, [urlRetrievedData]);

  const getParams = () => {
    const selectedSkills = skills?.map(getId);

    const params: KeywordsParams = {
      text,
      matchTypes: matchableTypeNames
    };
    if (selectedSkills.length > 0) {
      params.selectedIds = selectedSkills;
    }

    return params;
  };

  const [params, setParams] = useState(getParams());

  const { data, isFetching: isLoading } = useKeywords(params, {
    enabled: Boolean(params.selectedIds?.length || params.text?.trim())
  });

  const debounceSetRelevantAttributes = useDebounce(() => {
    setParams(getParams());
  }, 500);

  useEffect(() => {
    if (data?.entries) {
      setSuggestedSkills(data.entries as Keyword[]);
    }
  }, [data, skills]);

  useEffect(() => {
    debounceSetRelevantAttributes();
  }, [title, urlRetrievedData?.description, skills]);

  const renderSuggestion = () =>
    suggestedSkills?.map((suggestion) => (
      <Button
        className={css.relevantAttributesButton}
        key={`${suggestion.id}+${suggestion.globalId}`}
        kind="autosuggest"
        onClick={() => {
          handleSkillsChange({ ...suggestion, text: suggestion.value });
        }}
      >
        <>
          <AddIconSvg height={20} width={20} />
          {suggestion.value}
        </>
      </Button>
    ));

  const handleSkillsChange = (selectedSkills) => {
    const dedupedAttributes = uniqBy(flatten([...skills, selectedSkills]), "id");
    setSkills(dedupedAttributes);
  };

  const { windowWidth } = useWindowSize();
  const calendarStyle = windowWidth <= 600 ? { minWidth: "auto" } : {};

  return (
    <div className={css.wrapper}>
      <div className={css.row}>
        <div>
          <InputFieldSet
            label={t("title")}
            value={title}
            placeholder={t("enterCertificateName")}
            required
            qaId="certificates-form-title"
            error={findError("title")}
            onChange={setTitle}
          />
        </div>
        <div>
          <InputFieldSet
            label={t("issuingCompany")}
            value={issuer}
            placeholder={t("providerName")}
            required
            qaId="certificates-form-company"
            error={findError("issuer")}
            onChange={setIssuer}
          />
        </div>
        <div>
          <InlineCalendar
            label={t("completed")}
            placeholder={t("addDate")}
            selectedDate={issueDate ? formatISODate(issueDate) : null}
            maxDate={formatISODate()}
            name="Completed"
            qaId="certificates-form-calendar"
            error={findError("issue_date")}
            handleChange={(value) => setIssueDate(formatISODate(value))}
            required
            calendarStyle={calendarStyle}
          />
        </div>
      </div>
      <div className={classNames(css.row, css.spaceBetween)}>
        <Toggle
          onChange={setExpires}
          description={t("doesExpire")}
          checked={expires}
          qaId="certificates-form-toggle"
          reversed
        />
        {expires && (
          <InlineCalendar
            placeholder={t("expiryDate")}
            selectedDate={expiryDate ? formatISODate(expiryDate) : null}
            qaId="certificates-form--expiry-calendar"
            handleChange={(value) => setExpiryDate(formatISODate(value))}
            minDate={formatISODate()}
            style={{ width: 160, marginTop: -20 }}
            error={findError("expiry")}
            required
            calendarStyle={calendarStyle}
          />
        )}
      </div>
      <div className={classNames(css.row, css.spaceBetween)}>
        <AutoSelect
          /*This key hack let us render the component and pass the new added values from the suggestions*/
          key={skills?.map((item) => item.text).join()}
          qaId="certificates-form-skills"
          label={t("whichSkillsLearned")}
          placeholder={t("typeSkillHere")}
          values={skills}
          closeOnChange
          multi
          error={findError("skills")}
          query={(term) => fetchAutocompleteSkills({ term, global: false })}
          style={{ width: "100%" }}
          handleChange={setSkills}
          handleRemove={setSkills}
          letClear
        />
      </div>
      {isLoading && <LoadingDots />}
      {suggestedSkills.length > 0 && (
        <div className={css.relevantAttributes}>
          <div
            style={{ marginBottom: 30, opacity: isLoading ? 0.5 : 1 }}
            data-qa-id="certificates-suggestions"
          >
            <div className={css.header}>{t("suggestedAttributes")}</div>
            <div className={css.subHeader}>{t("identifiedRelatedAttributes")}</div>
            {renderSuggestion()}
          </div>
        </div>
      )}
      <div className={classNames(css.row, css.spaceBetween)}>
        <Button
          className={css.button}
          disabled={isSubmitting}
          onClick={() => {
            const payload = {
              title,
              issuer,
              issueDate,
              expiryDate: expires ? expiryDate : null,
              skills: skills.map(({ id }) => ({ id })),
              source: certificate?.source || "user",
              expires
            } as CertificatePayload;

            onSubmit(payload);
          }}
        >
          {isSubmitting ? <LoadingDots /> : certificate ? t("saveCertificate") : t("addCertificate")}
        </Button>
        <Button text={defaultT("cancel")} className={css.button} onClick={onClose} />
      </div>
    </div>
  );
};

export default EditCertificateForm;
