import { compact } from "lodash";
import { useCustomValueToString } from "PFCore/helpers/custom_value";
import { getJobTitle } from "PFCore/helpers/profile";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { CustomType, CustomValue, Profile } from "PFTypes";
import { useCallback, useMemo } from "react";

type ProfileInfoEntry = {
  customType: CustomType;
  value: string;
};

type UseMatchProfileInfoReturn = Record<"headerInfo" | "sideInfo", ProfileInfoEntry[]>;

export const useMatchProfileInfo = (
  profile: Partial<Profile> & Pick<Profile, "positions">
): UseMatchProfileInfoReturn => {
  const { customTypes } = useCustomTypes();

  const { getCustomValuesListString } = useCustomValueToString();

  const getCustomValue = useCallback(
    (sectionKey: string) => {
      const customType = customTypes.find(({ sections }) =>
        (sections || []).find(({ key }) => key === sectionKey)
      );
      const customValues: CustomValue[] = (customType && profile[customType.name]) || [];
      const valueAndTypeExist = !!customValues.length && !!customType;

      return valueAndTypeExist
        ? {
            customType,
            value: getCustomValuesListString(customValues, customType.value_type)
          }
        : undefined;
    },
    [profile, customTypes, getCustomValuesListString]
  );

  const jobTitleEntry = useMemo<ProfileInfoEntry | null>(() => {
    const jobTitleCustomType = customTypes.find(({ name }) => name === "job_title");
    const jobTitle = getJobTitle(profile) as string | null;

    if (!jobTitleCustomType || !jobTitle) {
      return null;
    }

    return { customType: jobTitleCustomType, value: jobTitle };
  }, [customTypes, profile]);

  return useMemo(
    () => ({
      headerInfo: compact([
        jobTitleEntry,
        getCustomValue("match_info_1"),
        getCustomValue("match_info_2"),
        getCustomValue("match_info_3")
      ]),
      sideInfo: compact([
        getCustomValue("match_sidebar_info_1"),
        getCustomValue("match_sidebar_info_2"),
        getCustomValue("match_sidebar_info_3")
      ])
    }),
    [getCustomValue, jobTitleEntry]
  );
};
