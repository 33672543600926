import WorkflowIcon from "PFIcons/workflow.svg";
import { useTranslation } from "react-i18next";

import NavItem from "./nav_item";

const WorkflowNavItem = (props) => {
  const { t } = useTranslation("translation", { keyPrefix: "navBar.items" });
  return (
    <NavItem
      icon={<WorkflowIcon width={20} height={20} />}
      isActiveTest={(path) => path.match(/^\/workflow/)}
      {...props}
      name={t("workflow")}
      id="workflow"
      href="/workflow"
    />
  );
};

export default WorkflowNavItem;
