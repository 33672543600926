import { isNil } from "lodash";
import { useGrowl } from "PFApp/use_growl";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { useBookingTemplateUpdate, useBookingUpdate } from "PFCore/hooks/queries";
import { useBookingTemplateCreate } from "PFCore/hooks/queries/bookings/booking_templates/use_booking_template_create";
import { useBookingCreate } from "PFCore/hooks/queries/bookings/use_booking_create";
import { Activity, BookingCategory, Profile } from "PFTypes";
import { RefObject, useState } from "react";
import { useTranslation } from "react-i18next";

import { useBookingActivityContext } from "../../../parts/providers/booking_activity_context_provider";
import { BookingSelectOption } from "../../booking_questions/booking_questions";
import { ReassignSelectOption } from "../../reassign_booking_modal/bookings_to_reassign_selector";
import {
  getSubmitErrorMessageTranslationKey,
  getSubmitSuccessMessageTranslationKey
} from "../booking_form.utils";
import { BookingData, BookingFormDataType, BookingFormMode } from "../use_booking_form";
import {
  BulkSelectOption,
  isProfile,
  SelectedUser
} from "../workforce_member_select/workforce_member_select";
import { useBookingPayload } from "./use_booking_payload";
import { getActivityId } from "./use_handle_submit.utils";
import { usePostSubmitActions } from "./use_post_submit_actions";

export type BookingResponse = {
  id: number;
};
type OnSubmitData = {
  profileId: number | undefined;
  activityId: number | undefined;
  parentActivityIds: number[];
};

export type UseHandleSubmit = {
  bookingData: BookingData;
  initialData: BookingFormDataType;
  title?: string;
  description?: string;
  prefilledUserRef: RefObject<Profile | null>;
  selectedUser: SelectedUser | null;
  selectedBookingCategory: BookingCategory | null;
  mode: BookingFormMode;
  bookingId?: number;
  specificTime?: boolean;
  overridesCalendar?: boolean;
  overridesNonWorkingDays?: boolean;
  onSubmit?: (data: OnSubmitData) => void | Promise<any>;
  onSuccess: () => void;
  onError: () => void;
  updateGroupOptionSelected: BookingSelectOption;
  updateRepeatOptionSelected: BookingSelectOption;
  reassignOptionSelected: ReassignSelectOption;
  selectedEngagement: Activity | null;
};

type UseHandleSubmitReturn = {
  handleSubmit: () => Promise<void>;
  isLoading: boolean;
  errors: Record<string, string> | null;
};

export const useHandleSubmit = ({
  bookingData,
  initialData,
  title,
  description,
  selectedUser,
  prefilledUserRef,
  selectedBookingCategory,
  mode,
  bookingId,
  overridesCalendar,
  overridesNonWorkingDays,
  onSubmit,
  onSuccess,
  onError,
  specificTime,
  updateGroupOptionSelected,
  updateRepeatOptionSelected,
  reassignOptionSelected,
  selectedEngagement
}: UseHandleSubmit): UseHandleSubmitReturn => {
  const growl = useGrowl();
  const { t } = useTranslation("bookingModule");
  const { activity, parentActivity } = useBookingActivityContext();

  const profileIds: number[] =
    selectedUser && isProfile(selectedUser)
      ? [selectedUser?.id]
      : (selectedUser?.profiles || []).map(({ id }) => id);
  const updateAllGroup = updateGroupOptionSelected === BookingSelectOption.All;

  const { update: updateBooking } = useBookingUpdate(updateAllGroup ? undefined : bookingId);
  const { update: updateBookingTemplate } = useBookingTemplateUpdate(
    updateAllGroup ? undefined : initialData.bookingTemplate?.id
  );
  const { create: createBooking } = useBookingCreate(...profileIds);
  const { create: createBookingTemplate } = useBookingTemplateCreate(...profileIds);

  const [errors, setErrors] = useState<Record<string, string> | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { getFormattedErrors } = useResponseErrors();

  const { getRequestPayload } = useBookingPayload({
    bookingData,
    mode,
    selectedUser,
    initialData,
    title,
    description,
    selectedBookingCategory,
    overridesCalendar,
    overridesNonWorkingDays,
    specificTime,
    updateAllGroup,
    updateRepeatOptionSelected,
    selectedEngagement
  });

  const { postSubmitActions } = usePostSubmitActions({
    selectedUser,
    initialData,
    specificTime,
    refreshGroup:
      updateAllGroup || (mode === "create" && !isNil((selectedUser as BulkSelectOption)?.search_id)),
    selectedEngagement,
    prefilledUserRef,
    reassignOptionSelected
  });

  const getApiFunction = () => {
    if (specificTime) {
      return mode === "edit" ? updateBookingTemplate : createBookingTemplate;
    } else {
      return mode === "edit" ? updateBooking : createBooking;
    }
  };

  const handleSubmit = async () => {
    if (!bookingData) {
      return;
    }

    setErrors(null);
    setIsLoading(true);

    const payloadItems = getRequestPayload();
    const apiCallFunction = getApiFunction();

    Promise.all<Promise<BookingResponse>[]>(payloadItems.map((payload) => apiCallFunction(payload)))
      .then(async (bookings) => {
        await postSubmitActions(bookings);
        await onSubmit?.({
          profileId: selectedUser && isProfile(selectedUser) ? selectedUser.id : undefined,
          activityId: getActivityId(activity?.id, selectedEngagement?.id),
          parentActivityIds: parentActivity?.id ? [parentActivity.id] : []
        });
        const isAffectingGroup =
          !isNil((selectedUser as BulkSelectOption)?.search_id) ||
          (!isNil(initialData.bookingGroupId) && updateGroupOptionSelected === BookingSelectOption.All);
        const { actionId, messageKey } = getSubmitSuccessMessageTranslationKey(mode, isAffectingGroup);
        growl({
          id: actionId,
          kind: "success",
          message: t(messageKey),
          hidePreviousByTypeAndId: true,
          ttl: 5000
        });
        setIsLoading(false);
        onSuccess?.();
      })
      .catch(({ response }) => {
        const responseErrors = getFormattedErrors(response) || {};
        setIsLoading(false);
        if (Object.keys(responseErrors).length > 0) {
          responseErrors.base && growl({ kind: "error", message: responseErrors.base });
          setErrors(responseErrors);
          return;
        }
        growl({
          kind: "error",
          message: t(getSubmitErrorMessageTranslationKey(mode))
        });
        onError?.();
      });
  };

  return {
    handleSubmit,
    isLoading,
    errors
  };
};
