import { isEmpty, isNil } from "lodash";
import commonCss from "PFApp/activities/show/activity_page/activity_page.module.scss";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useActivityMatchesFilters } from "PFApp/activities/show/hooks/use_activity_matches_filters";
import ActivityFilters from "PFApp/activities/show/parts/activity_filters";
import ActivityKeywords from "PFApp/activities/show/parts/activity_keywords";
import RefreshAction from "PFApp/activities/show/parts/refresh_action";
import LazyLocalExportTrigger from "PFApp/components/document_buttons/lazy_local_export_trigger";
import { useFiltersPanelContext } from "PFApp/components/filters/context/filters_panel_context";
import FiltersButton from "PFApp/components/filters/filters_button";
import { FiltersPanel } from "PFApp/components/filters/filters_panel";
import { SearchEntity } from "PFApp/components/search_autocomplete/search_autocomplete.types";
import { SearchInput } from "PFApp/components/search_input";
import SortBy from "PFApp/components/sort_by";
import { checkFiltersAreClear, getValueFilters, letFiltersRestore } from "PFApp/use_filtered_collection";
import { Alert } from "PFComponents/alert";
import { getTermFilterData } from "PFCore/helpers/get_term_filter_data";
import { useShortlistsInvalidate } from "PFCore/hooks/queries/shortlists/use_shortlists_invalidate";
import { getExportProfiles } from "PFCore/services/export_profiles";
import { RecentSearchesPageKey } from "PFTypes/recent_searches";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { TooltipButtonIcon } from "../../../../components/tooltip_button_icon";
import { useActivityPropertiesNames } from "../hooks/use_activity_properties_names";
import { useActivityPropertyFilters } from "./matches_constraints/hooks/use_activity_property_filters";
import css from "./matches_header.module.scss";

type MatchesHeaderProps = {
  isCardsCompressed: boolean;
  setIsCardsCompressed: (value: boolean) => void;
};

export const MatchesHeader = ({ isCardsCompressed, setIsCardsCompressed }: MatchesHeaderProps) => {
  const {
    isFiltersPanelOpen,
    openedFilterTypes,
    openFiltersPanel,
    addOpenedFilterType,
    removeOpenedFilterType,
    activeFiltersAccordionIndex,
    setActiveFiltersAccordionIndex,
    areFiltersDisabled
  } = useFiltersPanelContext();
  const { task, matches, matchesFetchState, matchesMeta, updateMatchesParams, matchesReload } =
    useActivityPageContext();

  const handleChangeTypeOpen = (open: boolean, typeName: string) => {
    if (open) {
      addOpenedFilterType(typeName);
    } else {
      removeOpenedFilterType(typeName);
    }
  };

  const { id, coowned, state } = task || {};
  const { invalidateShortlists } = useShortlistsInvalidate();
  const { t } = useTranslation(["activities", "translation"]);

  const valueFilters = getValueFilters(matchesMeta.filters);
  const filtersAreClear = checkFiltersAreClear(matchesMeta.filters);

  const schemaPropertiesNames = useActivityPropertiesNames();
  const { excludedPropertyFilters } = useActivityPropertyFilters();

  const orderMeta = matchesMeta?.order;
  const canShowFilters = matchesMeta?.filters;
  const isCompleted = state === "complete";

  const blockedFiltersList = ["term", ...schemaPropertiesNames];

  const handleOrderChange = (value) => updateMatchesParams({ order: value, page: 1 });

  const { resetFilters, updateFilter } = useActivityMatchesFilters();
  const letRestoreAll = letFiltersRestore(valueFilters, matchesMeta.default_filters);
  const filtersCounter = isEmpty(matchesMeta.filters) ? 0 : excludedPropertyFilters.length;

  const { termFilter, termValue, isTermFilterAvailable } = getTermFilterData(matchesMeta);

  const hasAnyMatches = matchesMeta.total > 0;
  const countMessage = useMemo(() => {
    const { page, perPage, total } = matchesMeta;
    return `${(page - 1) * perPage + 1} - ${Math.min(page * perPage, total)} ${t("translation:of")} ${total}`;
  }, [matchesMeta, t]);

  return (
    <>
      <div className={css.container}>
        <div className={css.sortByBlock}>
          {!isCompleted && !!orderMeta && (
            <SortBy
              sort={orderMeta.value?.text}
              options={orderMeta.options.map((option) => ({
                id: option.text,
                displayElement: option.text,
                item: option
              }))}
              onSortChange={handleOrderChange}
              kind="blank"
              noLabel
            />
          )}
          {hasAnyMatches && <span>{countMessage}</span>}
        </div>
        {!isCompleted && canShowFilters && (
          <div style={{ marginLeft: 10 }}>
            <FiltersButton
              filtersAreClear={filtersAreClear}
              onClick={() => openFiltersPanel(!isFiltersPanelOpen)}
            />
          </div>
        )}
        <TooltipButtonIcon
          icon={isCardsCompressed ? "expand" : "collapse"}
          onClick={() => setIsCardsCompressed(!isCardsCompressed)}
          tooltipContent={
            isCardsCompressed
              ? t("activities:show.iconsTooltips.standardCards")
              : t("activities:show.iconsTooltips.compressedCards")
          }
          qaId="ActivityMatchesTabToggleCardsButton"
        />
        <LazyLocalExportTrigger
          quantity={matches?.length || 0}
          getProfilesCollection={() => {
            const items = (matches || []).map(({ profile }) => profile);
            return getExportProfiles({
              profileIds: items.map(({ id }) => id),
              activityId: id
            }).then((resp) => ({
              entries: resp.map((profile) => ({
                profile
              })),
              meta: { total: resp.length, page: 1 }
            }));
          }}
          buttonKind="secondary"
          icon="export"
          qaId="ActivityMatchesTabExportButton"
          tooltipContent={t("activities:show.iconsTooltips.export")}
        />
        {!isCompleted && coowned && (
          <RefreshAction
            activityId={id}
            onUpdate={() =>
              matchesReload &&
              matchesReload().then(() => {
                invalidateShortlists(id);
              })
            }
            showLabel={false}
            qaId="ActivityMatchesTabRefreshMatchesButton"
          />
        )}
        <SearchInput
          disabled={areFiltersDisabled || !isTermFilterAvailable}
          term={termValue}
          pageKey={RecentSearchesPageKey.ActivityMatches}
          autocompleteConfig={[
            {
              entity: SearchEntity.Profile,
              useAsTerm: true
            }
          ]}
          includeRecentSearches={false}
          popperOptions={{
            placement: "bottom-end"
          }}
          classes={{ dropdown: commonCss.searchDropdown }}
          onTermChange={(newTerm: string) => {
            updateFilter(termFilter!, newTerm);
          }}
          qaId="ActivityMatchesTabSearchInput"
        />
      </div>
      {isFiltersPanelOpen && canShowFilters && (
        <FiltersPanel
          disabled={areFiltersDisabled}
          disabledLabel={t("activities:show.matches.filtersDisabled")}
          isLoading={matchesFetchState === "pending"}
          closeFilters={() => openFiltersPanel(false)}
          filtersAreClear={filtersAreClear}
          meta={matchesMeta}
          clearFilters={resetFilters}
          accordionProps={{
            childrenTitle: t("activities:show.activityFilters"),
            filtersTitle: filtersCounter
              ? `${t("activities:show.moreFilters")} (${filtersCounter})`
              : t("activities:show.moreFilters"),
            filtersDisabled: filtersCounter === 0,
            allDisabled: areFiltersDisabled,
            activeState: !isNil(activeFiltersAccordionIndex) ? { [activeFiltersAccordionIndex]: true } : {},
            onActiveIndexChange: (index) => setActiveFiltersAccordionIndex(index)
          }}
          onFilterChange={updateFilter}
          blockedList={blockedFiltersList}
          letRestoreAll={letRestoreAll}
          restoreAllTooltipContent={t("activities:show.matches.restoreAllTooltip")}
        >
          <div>
            <ActivityKeywords typesOpened={openedFilterTypes} onChangeTypeOpen={handleChangeTypeOpen} />
            <ActivityFilters
              meta={matchesMeta}
              schemaPropertiesNames={schemaPropertiesNames}
              onFilterChange={updateFilter}
            />
          </div>
        </FiltersPanel>
      )}
      {letRestoreAll && !filtersAreClear && (
        <Alert
          title={t("activities:show.matches.filtersChangedWarning")}
          action={{
            text: t("translation:filters.restoreAll"),
            icon: "history",
            kind: "text",
            onClick: () => resetFilters(matchesMeta.default_filters)
          }}
          small
        />
      )}
    </>
  );
};
