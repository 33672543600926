import { ColumnDef, ColumnHelper } from "@tanstack/react-table";
import { sortingDateFn } from "PFComponents/table/table.utils";
import { Booking } from "PFTypes";
import { useTranslation } from "react-i18next";

import css from "./bookings_list.module.scss";
import { ActionColumn } from "./columns/action_column";
import { BookingCategoryColumn } from "./columns/booking_category_column";
import { DatesColumn } from "./columns/dates_column";
import { TextColumn } from "./columns/text_column";
import { TotalHoursColumn } from "./columns/total_hours_column";
import { useSortRoles } from "./hooks/use_sort_roles";
import { useSortTitles } from "./hooks/use_sort_titles";
import { useSortTotalHours } from "./hooks/use_sort_total_hours";

enum ColumnId {
  BookingTitle = "bookingTitleColumn",
  Role = "roleColumn",
  BookingCategory = "bookingCategory",
  Dates = "datesColumn",
  TotalHours = "totalHoursColumn",
  Action = "actionColumn"
}

type ColumnGenerator<TVALUE = any> = (columnHelper: ColumnHelper<Booking>) => ColumnDef<Booking, TVALUE>;

type UseBookingsListColumnsGeneratorReturn = {
  bookingTitleColumn: ColumnGenerator<Booking>;
  roleColumn: ColumnGenerator<Booking>;
  bookingCategoryColumn: ColumnGenerator<Booking>;
  datesColumn: ColumnGenerator<Booking>;
  totalHoursColumn: ColumnGenerator<Booking>;
  actionColumn: ColumnGenerator<Booking>;
};

type UseBookingsListColumnsGenerator = {
  onBookingEdit: (booking: Booking) => void;
  readOnly: boolean;
  isExpanded: boolean;
};

export const useBookingsListColumnsGenerator = ({
  readOnly,
  isExpanded,
  onBookingEdit
}: UseBookingsListColumnsGenerator): UseBookingsListColumnsGeneratorReturn => {
  const { t } = useTranslation("availability", { keyPrefix: "bookings.list" });
  const { sortTotalHours } = useSortTotalHours();
  const { sortRoles } = useSortRoles();
  const { sortTitles } = useSortTitles();
  return {
    bookingTitleColumn: (columnHelper: ColumnHelper<Booking>) =>
      columnHelper.accessor((row) => row, {
        id: ColumnId.BookingTitle,
        cell: (info) => (
          <TextColumn
            booking={info.getValue()}
            text={info.getValue().title ?? t("untitled")}
            isExpanded={isExpanded}
          />
        ),
        header: () => <span className={css.tableHeader}>{t("columns.bookingTitle")}</span>,
        sortingFn: (rowA, rowB) => sortTitles(rowA.original, rowB.original)
      }),
    roleColumn: (columnHelper: ColumnHelper<Booking>) =>
      columnHelper.accessor((row) => row, {
        id: ColumnId.Role,
        cell: (info) => (
          <TextColumn
            booking={info.getValue()}
            text={info.getValue().activity?.name ?? t("other")}
            isExpanded={isExpanded}
          />
        ),
        header: () => <span className={css.tableHeader}>{t("columns.role")}</span>,
        sortingFn: (rowA, rowB) => sortRoles(rowA.original, rowB.original)
      }),
    bookingCategoryColumn: (columnHelper: ColumnHelper<Booking>) =>
      columnHelper.accessor((row) => row, {
        id: ColumnId.BookingCategory,
        cell: (info) => <BookingCategoryColumn booking={info.getValue()} isExpanded={isExpanded} />,
        header: () => <span className={css.tableHeader}>{t("columns.bookingCategory")}</span>,
        sortingFn: (rowA, rowB) => rowA.original.booking_category_id - rowB.original.booking_category_id
      }),
    datesColumn: (columnHelper: ColumnHelper<Booking>) =>
      columnHelper.accessor((row) => row, {
        id: ColumnId.Dates,
        cell: (info) => (
          <DatesColumn startDate={info.getValue().start_date} endDate={info.getValue().end_date} />
        ),
        header: () => <span className={css.tableHeader}>{t("columns.dates")}</span>,
        size: 300,
        sortingFn: (rowA, rowB) =>
          sortingDateFn(
            rowA.original?.start_date ? new Date(rowA.original?.start_date) : undefined,
            rowB.original?.start_date ? new Date(rowB.original?.start_date) : undefined
          )
      }),
    totalHoursColumn: (columnHelper: ColumnHelper<Booking>) =>
      columnHelper.accessor((row) => row, {
        id: ColumnId.TotalHours,
        cell: (info) => <TotalHoursColumn booking={info.getValue()} />,
        header: () => <span className={css.tableHeader}>{t("columns.totalHours")}</span>,
        size: 150,
        sortingFn: (rowA, rowB) => sortTotalHours(rowA.original, rowB.original)
      }),
    actionColumn: (columnHelper: ColumnHelper<Booking>) =>
      columnHelper.accessor((row) => row, {
        id: ColumnId.Action,
        cell: (row) => <ActionColumn booking={row.getValue()} readOnly={readOnly} onEdit={onBookingEdit} />,
        header: () => <span className={css.tableHeader}></span>,
        size: 50,
        enableSorting: false
      })
  };
};
