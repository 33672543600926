/* eslint-disable react/prop-types */
import classNames from "classnames";
import moment, { Moment, MomentInput } from "moment";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { ActionIcon } from "../../../action_icon";
import css from "./calendar_header.module.scss";

type ChangeMonthButtonProps = {
  dir: number;
  dateLow: MomentInput;
  dateHigh: MomentInput;
  displayMonth: MomentInput;
  setDisplayMonth: (value: Moment) => void;
};

export const ChangeMonthButton = ({
  dir,
  dateLow,
  dateHigh,
  displayMonth,
  setDisplayMonth
}: ChangeMonthButtonProps): JSX.Element => {
  const { t } = useTranslation();

  const handleClick = useCallback(
    //@ts-ignore-line
    () => setDisplayMonth(displayMonth.clone().add(dir, "month")),
    [setDisplayMonth, displayMonth, dir]
  );

  return (
    <ActionIcon
      name={dir === 1 ? "chevron-right" : "chevron-left"}
      size="sm"
      title={t("calendar.changeMonth")}
      classes={{
        root: classNames({
          [css.changeMonthDisabled]:
            !dateLow || !dateHigh || moment(dateLow).format("YYYY-MM") <= moment(dateHigh).format("YYYY-MM")
        })
      }}
      onClick={handleClick}
    />
  );
};
