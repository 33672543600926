import AppContext from "PFApp/app_context";
import { Icon } from "PFComponents/icon";
import PageTabs from "PFComponents/page/page_tabs";
import { SEARCH_ACTIONS } from "PFReducers/search_reducer/search_reducer";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import css from "./search_tabs.module.scss";

const BackTab = () => {
  const { t } = useTranslation();

  return (
    <>
      <Icon name="chevron-left" size="xs" fill="currentcolor" /> {t("back")}
    </>
  );
};

const SearchTabs = () => {
  const {
    dispatch,
    store: {
      search: { tab, matchesMeta, allActivitiesMeta, availableTemplates }
    }
  } = useContext(AppContext);
  const { t } = useTranslation("search");

  const listOfActivities = useMemo(
    () => availableTemplates?.map((activity) => activity.key) || [],
    [availableTemplates]
  );

  const counters = useMemo(
    () => ({
      matches: matchesMeta.total || 0,
      ...listOfActivities?.reduce((acc, item) => ({ ...acc, [item]: allActivitiesMeta?.[item]?.total }), {})
    }),
    [matchesMeta.total, listOfActivities, allActivitiesMeta]
  );

  const tabsItems = useMemo(
    () => [
      { id: "all", displayElement: t("tabs.all") },
      { id: "matches", displayElement: t("tabs.profiles") },
      ...listOfActivities.map((activity) => ({
        id: activity,
        displayElement: t(`tabs.${activity}` as unknown as TemplateStringsArray)
      }))
    ],
    [listOfActivities, t]
  );

  if (!["all", "matches", ...listOfActivities].includes(tab)) {
    return (
      <PageTabs
        allowNoUnderline
        handleClick={() => dispatch({ type: SEARCH_ACTIONS.SEARCH_SET_TAB, payload: "all" })}
        items={[{ id: "all", displayElement: <BackTab /> }]}
        active={null}
        counters={{}}
      />
    );
  }

  return (
    <div className={css.wrapper}>
      <PageTabs
        handleClick={(item) => dispatch({ type: SEARCH_ACTIONS.SEARCH_SET_TAB, payload: item.id })}
        items={tabsItems}
        active={tab}
        counters={counters}
      />
    </div>
  );
};

export default SearchTabs;
