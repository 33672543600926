import classNames from "classnames";
import PropTypes from "prop-types";

import css from "./page_box.module.scss";

const PageBox = ({ children, style, isLoading, shadow, qaId, className }) => (
  <div style={style} className={classNames(css.root, css[shadow], className)} data-qa-id={qaId}>
    <div className={css.children} style={{ opacity: isLoading ? 0.6 : 1 }}>
      {children}
    </div>
  </div>
);

PageBox.propTypes = {
  qaId: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
  isLoading: PropTypes.bool,
  shadow: PropTypes.oneOf(["none", "regular"]),
  className: PropTypes.string
};

export default PageBox;
