import moment from "moment";
import ActivityPreviewLink from "PFApp/components/activity_commons/activity_preview_link";
import PinIcon from "PFIcons/pin";
import PropTypes from "prop-types";

import css from "./content.less";
import Description from "./description";

const EventContent = ({ task }) => {
  const metadata = task.metadata || {};
  const startDay = moment(metadata.start_date).format("D");
  const startMonth = moment(metadata.start_date).format("MMM");
  const endDay = moment(metadata.end_date).format("D");
  const endMonth = moment(metadata.end_date).format("MMM");

  return (
    <div className={css.wrapper}>
      <ActivityPreviewLink activity={task} className={css.name}>
        <div className={css.rawRow}>
          <div className={css.event}>
            <div>{startDay === endDay ? startDay : `${startDay}-${endDay}`}</div>
            <small>{startMonth === endMonth ? startMonth : `${startMonth} - ${endMonth}`}</small>
          </div>
          <div className={css.marginColumn}>
            <div className={css.eventDescription}>
              <Description description={task.description} id={task.id} />
            </div>
            {metadata.location && (
              <div className={css.location}>
                <PinIcon width={20} height={20} style={{ fill: "#555", opacity: 0.3, marginRight: 5 }} />
                {metadata.location}
              </div>
            )}
          </div>
        </div>
      </ActivityPreviewLink>
    </div>
  );
};

EventContent.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    metadata: PropTypes.shape({
      end_date: PropTypes.string,
      start_date: PropTypes.string,
      link: PropTypes.string,
      location: PropTypes.string
    })
  })
};

export default EventContent;
