import { ProfileAvatarConnected } from "PFApp/components/profile_avatar_connected";
import { ActionIcon } from "PFComponents/action_icon";
import { Avatar } from "PFComponents/avatar";
import { getProfileName } from "PFCore/helpers/profile";
import { profileHash } from "PFCore/helpers/profile_hash";
import { Shortlist } from "PFTypes";
import { useTranslation } from "react-i18next";

import css from "./profile_label.module.scss";

type ProfileLabelProps = {
  shortlist: Shortlist;
  removeProfileId: (id: number) => void;
  isBlindModeOn?: boolean;
};

export const ProfileLabel = ({
  shortlist: { profile },
  removeProfileId,
  isBlindModeOn
}: ProfileLabelProps) => {
  const { t } = useTranslation("activities");

  return (
    <div className={css.root}>
      <div>
        {isBlindModeOn ? (
          <Avatar size={30} backgroundColor="#ffc0cb" text={profileHash(profile.id)} />
        ) : (
          <ProfileAvatarConnected profile={profile} size={30} smartLink />
        )}
      </div>
      <div className={css.name}>
        {isBlindModeOn
          ? t("compare.candidateHash", { profileHash: profileHash(profile.id) })
          : getProfileName(profile)}
      </div>
      <ActionIcon name="cross" size="xs" onClick={() => removeProfileId(profile.id)} />
    </div>
  );
};
