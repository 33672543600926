import ConfirmModal from "PFCore/components/confirm/confirm_modal";
import { Typography } from "PFCore/components/typography/typography";
import { useTranslation } from "react-i18next";

type RemoveNoteModalProps = {
  onClose: () => void;
  onRemove: (noteId: number) => void;
};

export const RemoveNoteModal = ({ onClose, onRemove }: RemoveNoteModalProps): JSX.Element => {
  const { t } = useTranslation(["core", "translation"]);
  return (
    <ConfirmModal
      title={t("core:notes.removeNote")}
      labelOK={t("translation:remove")}
      kindOK="danger"
      handleOK={onRemove}
      handleClose={onClose}
    >
      <Typography variant="bodyRegular" tag="span">
        {t("core:notes.removeNoteQuestion")}
      </Typography>
    </ConfirmModal>
  );
};
