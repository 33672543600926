import { ActivityHistoryEntry, ActivityHistoryEvent } from "PFTypes";

import { HistoryEntryGeneric } from "./parts/history_entry_generic";
import { HistoryEntryOwner } from "./parts/history_entry_owner";

export type HistoryEntryProps = {
  entry: ActivityHistoryEntry;
};

type HistoryEntryComponent = React.ComponentType<HistoryEntryProps>;

const HistoryEntryComponents: Partial<Record<ActivityHistoryEvent, HistoryEntryComponent>> & {
  default: HistoryEntryComponent;
} = {
  owner_updated: HistoryEntryOwner,
  owner_created: HistoryEntryOwner,
  owner_deleted: HistoryEntryOwner,
  default: HistoryEntryGeneric
};

const getHistoryEntryComponent = (entry: ActivityHistoryEntry) =>
  HistoryEntryComponents[entry.event] || HistoryEntryComponents.default;

export const HistoryEntry = ({ entry }: HistoryEntryProps) => {
  const Component = getHistoryEntryComponent(entry);
  return <Component entry={entry} />;
};
