import { noop, some } from "lodash";
import Chat from "PFApp/components/chat/chat";
import { LOADING, NORMAL } from "PFApp/components/chat/chat_states";
import { usePusherEvent } from "PFCore/base/pusher";
import useDebounce from "PFCore/helpers/use_debounce";
import { useChatConversation } from "PFCore/hooks/queries/chat/use_chat_conversation";
import { useChatInvalidate } from "PFCore/hooks/queries/chat/use_chat_invalidate";
import { useChatMessages } from "PFCore/hooks/queries/chat/use_chat_messages";
import { useChatParticipants } from "PFCore/hooks/queries/chat/use_chat_participants";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";

const ChatController = ({
  style,
  conversationId,
  selected,
  expanded,
  expandable,
  emptyMessage,
  isBelow,
  minimized,
  onSelect,
  onMinimize,
  onExpand,
  onUnMinimize,
  onClose,
  onClearNotifications,
  forceUpdateAll
}) => {
  const { data: currentProfile } = useCurrentProfile();

  const [chatState, setChatState] = useState(LOADING);
  const [flashTitle, setFlashTitle] = useState(false);
  const [lastReceivedMessage, setLastReceivedMessage] = useState(null);

  const { invalidateConversationsByTarget } = useChatInvalidate();

  const { data: conversation, refetch: refetchConversation } = useChatConversation(conversationId);

  const { data: participants, refetch: refetchChatParticipants } = useChatParticipants({
    conversationId: conversationId,
    perPage: 5
  });
  const { data: currentParticipants } = useChatParticipants({
    conversationId: conversationId,
    profileId: currentProfile.id,
    perPage: 1
  });

  const {
    data: messages,
    isFetching,
    isFetchingNextPage: isFetchingPreviousMessages,
    refetch: refetchMessages,
    fetchNextPage
  } = useChatMessages(
    {
      conversationId,
      targetId: conversation?.target_id,
      targetType: conversation?.target_type
    },
    { enabled: !!conversation, keepPreviousData: true }
  );

  const allParticipants = useMemo(
    () => (currentParticipants.length ? [currentParticipants[0], ...participants] : participants),
    [currentParticipants, participants]
  );

  useEffect(() => {
    if (conversation && !isFetching) {
      if (conversation.unread_count > 0) {
        onClearNotifications();
      }
      setChatState(NORMAL);
    }
  }, [conversation, isFetching]);

  useEffect(() => {
    if (isFetching) {
      setChatState(LOADING);
    }
  }, [isFetching]);

  const handlePusherNewMessage = (args) => {
    setLastReceivedMessage(args);
    // On activity page we do not show newly created conversations (without any message)
    // We need to refresh data when first message is received
    if (conversation.target && conversation.created_at === conversation.last_activity_at) {
      invalidateConversationsByTarget(conversation.target);
      refetchConversation();
    }
  };

  usePusherEvent(`private-conversation-${conversationId}`, "message", handlePusherNewMessage);
  usePusherEvent(`private-conversation-${conversationId}`, "change", refetchChatParticipants);

  // PusherWrapper is binding the lexical scope so we need to set the lastReceivedMessage in state
  // In order to keep in sync with query update we need to debounce the flashTitle update and refetch
  useEffect(
    useDebounce(() => {
      const { pages } = messages;
      if (lastReceivedMessage && !some(pages[0]?.entries, { id: lastReceivedMessage.id })) {
        refetchMessages();

        setFlashTitle(true);
        setTimeout(() => {
          setFlashTitle(false);
        }, 3000);
      }

      setLastReceivedMessage(null);
    }, 200),
    [lastReceivedMessage]
  );

  const handleLoadPreviousMessages = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  return (
    <Chat
      style={style}
      conversationId={conversationId}
      selected={selected}
      expanded={expanded}
      expandable={expandable}
      isBelow={isBelow}
      minimized={minimized}
      emptyMessage={emptyMessage}
      onClose={onClose}
      onExpand={onExpand}
      onLoadPreviousMessages={handleLoadPreviousMessages}
      onMinimize={onMinimize}
      onSelect={onSelect}
      onUnMinimize={onUnMinimize}
      forceUpdateAll={forceUpdateAll}
      participants={allParticipants}
      messages={messages}
      conversation={conversation || {}}
      chatState={chatState}
      setChatState={setChatState}
      currentProfile={currentProfile}
      flashTitle={flashTitle}
      isFetchingPreviousMessages={isFetchingPreviousMessages}
    />
  );
};

ChatController.propTypes = {
  style: PropTypes.object,
  conversation: PropTypes.any,
  conversationId: PropTypes.number,
  selected: PropTypes.bool,
  expanded: PropTypes.bool,
  expandable: PropTypes.bool,
  emptyMessage: PropTypes.string,
  isBelow: PropTypes.bool,
  minimized: PropTypes.bool,

  onSelect: PropTypes.func.isRequired,
  onMinimize: PropTypes.func,
  onExpand: PropTypes.func,
  onUnMinimize: PropTypes.func,
  onClose: PropTypes.func,
  onClearNotifications: PropTypes.func,
  forceUpdateAll: PropTypes.func
};

ChatController.defaultProps = {
  style: null,
  conversation: null,
  conversationId: null,
  selected: false,
  expanded: false,
  emptyMessage: "No messages",

  onClose: noop,
  onMinimize: noop,
  onExpand: noop,
  onUnMinimize: noop,
  onClearNotifications: noop
};

export default ChatController;
