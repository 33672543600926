import { isEmpty } from "lodash";
import { handleRequestFiltersReplace } from "PFApp/use_filtered_collection/format_filters";
import { LoadingDots } from "PFComponents/loading_dots";
import { Filters, Value } from "PFTypes";
import { Id } from "PFTypes/id";
import { SavedFilter } from "PFTypes/saved_filters";
import { Dispatch, SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { RELEVANT_TO_ME_ID } from "../../../constants/relevant_to_me";
import { SavedViewsType } from "../saved_views_context/saved_views_context";
import css from "./save_as_view.module.scss";
import { ViewAdditionalParams } from "./save_as_view.types";
import { SaveAsViewModalInitialData, SaveAsViewModalMode } from "./save_as_view_modal";
import { SavedViewBadge } from "./saved_view_badge";

type SavedViewsBadgesProps = {
  savedViewSelected: SavedFilter | null;
  views: (SavedFilter & ViewAdditionalParams)[];
  isRelevantToMeDisabled?: boolean;
  valueFilters: Filters<Value>;
  clearFilters?: (filters: any) => void;
  onChangeSelectedView: Dispatch<SetStateAction<Id | null>>;
  open: (data: SaveAsViewModalInitialData) => void;
} & Pick<SavedViewsType, "deleteFiltersView" | "isLoading">;

export const SavedViewsBadges = ({
  savedViewSelected,
  views,
  isLoading,
  deleteFiltersView,
  clearFilters,
  onChangeSelectedView,
  valueFilters,
  open
}: SavedViewsBadgesProps): JSX.Element => {
  const { t } = useTranslation();

  const handleRemove = useCallback(
    (id: Id, onSuccess?: VoidFunction) => {
      deleteFiltersView(id).then(() => {
        onSuccess?.();
      });

      if (savedViewSelected?.id === id) {
        clearFilters && clearFilters({});
      }
    },
    [clearFilters, deleteFiltersView, savedViewSelected]
  );

  const handleApplyFilters = useCallback(
    (id: Id, filters: Filters) => {
      onChangeSelectedView(id);
      const filtersToApply = handleRequestFiltersReplace(valueFilters, filters);
      clearFilters && clearFilters(filtersToApply);
    },
    [clearFilters, onChangeSelectedView, valueFilters]
  );

  const shareHandler = (id: Id, name: string) =>
    id !== RELEVANT_TO_ME_ID
      ? () => open({ id, viewName: name, mode: SaveAsViewModalMode.Share })
      : undefined;

  return (
    <div className={css.viewsBadges}>
      <div className={css.label}>
        {isEmpty(views) ? t("noSavedFilters") : `${t("savedFilters", { count: views.length })}:`}
      </div>
      {views.map(
        ({
          id,
          name,
          availability,
          fields,
          numbers,
          strings,
          children,
          disabled,
          allowRemove,
          tooltipContent
        }) => (
          <SavedViewBadge
            key={id}
            name={name}
            disabled={disabled}
            allowRemove={allowRemove}
            tooltipContent={tooltipContent}
            isSelected={savedViewSelected?.id === id}
            onClick={
              !disabled
                ? () => handleApplyFilters(id, { availability, fields, numbers, strings, children })
                : null
            }
            onShareClick={shareHandler(id, name)}
            onRemove={(onSuccess) => handleRemove(id, onSuccess)}
          />
        )
      )}
      {isLoading && <LoadingDots circlesEnabled circleSize="xs" />}
    </div>
  );
};
