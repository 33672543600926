import uniq from "lodash/uniq";
import moment from "moment";
import { useBookingsReassign } from "PFCore/hooks/queries/bookings/reassign/use_bookings_reassign";
import { useMatchesShortlistReplace } from "PFCore/hooks/queries/matches/use_matches_shortlist_replace";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";

import { useRefreshBookingData } from "../../../hooks/use_refresh_booking_data";
import { bookRoleByShortlist } from "../../../services/api";
import { ReassignSelectOption } from "../../reassign_booking_modal/bookings_to_reassign_selector";
import { BookingFormDataType } from "../use_booking_form";
import { isProfile } from "../workforce_member_select/workforce_member_select";
import { BookingResponse, UseHandleSubmit } from "./use_handle_submit";
import { getActivityId } from "./use_handle_submit.utils";

const getMinBookingDateTime = (bookings: BookingFormDataType["bookings"] = []) =>
  Math.min(...bookings.map(({ startDate }) => new Date(startDate).getTime()));

type UsePostSubmitActions = Pick<
  UseHandleSubmit,
  | "selectedUser"
  | "initialData"
  | "specificTime"
  | "selectedEngagement"
  | "prefilledUserRef"
  | "reassignOptionSelected"
> & {
  refreshGroup?: boolean;
};

export const usePostSubmitActions = ({
  selectedUser,
  initialData,
  specificTime,
  refreshGroup,
  selectedEngagement,
  prefilledUserRef,
  reassignOptionSelected
}: UsePostSubmitActions) => {
  const { formatISODate } = useDateFormatter();

  const { mutate: bulkReassignBookings } = useBookingsReassign({});

  const refreshBookingData = useRefreshBookingData();

  const { replaceMatchesShortlistInCache } = useMatchesShortlistReplace();

  const postSubmitActions = async (bookings: BookingResponse[]) => {
    if (initialData.shortlistId) {
      const shortlist = await bookRoleByShortlist(initialData.shortlistId);
      replaceMatchesShortlistInCache(shortlist);
    }
    const oldProfileId = prefilledUserRef.current?.id;
    if (
      reassignOptionSelected !== ReassignSelectOption.ThisBooking &&
      initialData.activityId &&
      oldProfileId &&
      selectedUser &&
      isProfile(selectedUser)
    ) {
      const min =
        reassignOptionSelected === ReassignSelectOption.ThisAndFuture
          ? formatISODate(getMinBookingDateTime(initialData.bookings))
          : formatISODate(moment().subtract(2, "year"));
      const max = formatISODate(moment().add(2, "year"));

      await bulkReassignBookings({
        activityId: initialData.activityId,
        oldProfileId,
        newProfileId: selectedUser?.id,
        dateRange: {
          min,
          max
        }
      });
    }
    refreshBookingData({
      bookingIds: bookings.map(({ id }) => id),
      bookingGroupId: initialData.bookingGroupId,
      shortlistId: initialData.shortlistId,
      activityId: getActivityId(initialData?.activityId, selectedEngagement?.id),
      profileIds:
        selectedUser && isProfile(selectedUser)
          ? uniq([selectedUser.id, ...(oldProfileId ? [oldProfileId] : [])])
          : undefined,
      refreshGroup,
      refreshProfile: specificTime
    });
  };

  return {
    postSubmitActions
  };
};
