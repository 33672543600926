/* eslint-disable react/prop-types */
import { getWeekdaysShort } from "PFCore/helpers/date";
import { JSX } from "react";

import css from "./calendar_header.module.scss";

const CalendarHeader = (): JSX.Element => {
  const weekDays = getWeekdaysShort();

  return (
    <div className={css.root} data-qa-id="CalendarHeader">
      <div className={css.daynames}>
        {weekDays
          .map((name) => name.toUpperCase())
          .map((name) => (
            <div key={name} className={css.dayNameContainer}>
              <span className={css.dayname} key={name}>
                {name}
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CalendarHeader;
