import { Button } from "PFComponents/button";
import { Typography } from "PFComponents/typography";
import { IconName } from "PFTheme/graphics/icons";
import NoProfilesIllustration from "PFTheme/graphics/illustrations/illustration-no-profiles.svg";
import { ReactElement } from "react";

import css from "./empty_state.module.scss";

type EmptyStateProps = {
  illustration?: ReactElement;
  title?: string;
  message?: string;
  buttonIcon?: IconName;
  buttonLabel?: string;
  onButtonClick?: VoidFunction;
};

export const EmptyState = ({
  illustration,
  title,
  message,
  buttonLabel,
  onButtonClick
}: EmptyStateProps): JSX.Element => (
  <div className={css.emptyState}>
    {illustration || <NoProfilesIllustration />}
    {title && (
      <Typography variant="h1" noMargin className={css.title}>
        {title}
      </Typography>
    )}
    {message && (
      <Typography variant="h4" noMargin>
        {message}
      </Typography>
    )}
    {onButtonClick && <Button onClick={onButtonClick}>{buttonLabel}</Button>}
  </div>
);
