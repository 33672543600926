import { ActionIcon } from "PFComponents/action_icon";
import { Card } from "PFComponents/card";
import CardHeader from "PFComponents/card/card_header";
import { Markdown } from "PFComponents/markdown";
import Modal from "PFComponents/modal/modal";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const SummaryModal = ({ handleModalClose, profile, t }) => (
  <Modal
    handleClose={() => {
      handleModalClose(false);
    }}
    before
    closeOnBackdropClick
    style={{ maxWidth: 700, margin: "0 auto" }}
  >
    <Card paddingVariant="xl">
      <CardHeader
        titleQaId="contact-edit"
        title={t("show.parts.profileSummary")}
        style={{ padding: 0, marginBottom: 24 }}
        action={
          <ActionIcon
            name="cross"
            size="xs"
            onClick={() => {
              handleModalClose(false);
            }}
          />
        }
      />
      <Markdown raw={profile.summary} crop={1e10} emojify={false} />
    </Card>
  </Modal>
);

SummaryModal.propTypes = {
  handleModalClose: PropTypes.func,
  profile: PropTypes.shape({
    id: PropTypes.any,
    last_name: PropTypes.string,
    first_name: PropTypes.string,
    summary: PropTypes.string
  }),
  t: PropTypes.func
};

export default withTranslation("profiles")(SummaryModal);
