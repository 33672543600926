import classNames from "classnames";
import SkillRatingIcon from "PFComponents/icons/skill_rating_icon";
import DownArrowIcon from "PFIcons/down_arrow.svg";
import { CustomValue, Experience } from "PFTypes";
import { Id } from "PFTypes/id";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import SkillsList from "../../skills_list";
import css from "./skills_proficiency_group.module.scss";

const LEVEL_VALUES = {
  [Experience.Basic]: 1 / 5,
  [Experience.Intermediate]: 3 / 5,
  [Experience.Advanced]: 5 / 5
};

type SkillsProficiencyGroupProps = {
  experience: Experience;
  skills: CustomValue[];
  profileId: Id;
};

export const SkillsProficiencyGroup = ({ skills, experience, profileId }: SkillsProficiencyGroupProps) => {
  const { t } = useTranslation("profiles");
  const [isCategoryCollapsed, setIsCategoryCollapsed] = useState(false);

  const experienceToNameMap = useMemo(
    () => ({
      [Experience.Basic]: t("show.parts.basic"),
      [Experience.Intermediate]: t("show.parts.intermediate"),
      [Experience.Advanced]: t("show.parts.advanced")
    }),
    [t]
  );

  return (
    <div className={css.content}>
      <div className={css.headLeft}>
        <SkillRatingIcon value={LEVEL_VALUES[experience]} className={css.skillRatingIcon} />
        <button
          className={classNames(css.iconButton, { [css.arrowClosed]: isCategoryCollapsed })}
          onClick={() => setIsCategoryCollapsed((prev) => !prev)}
        >
          <DownArrowIcon height={24} width={24} />
        </button>
      </div>
      <div className={css.headRight}>{`${experienceToNameMap[experience]} (${skills.length})`}</div>
      <div className={classNames(css.skillColumn, { [css.categoryCollapsed]: isCategoryCollapsed })}>
        <SkillsList collection={skills} profileId={profileId} />
      </div>
    </div>
  );
};
