import classNames from "classnames";
import BookingPill from "PFApp/components/pills/booking_pill";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import ArrowDownIcon from "PFIcons/down_arrow.svg";
import InfoIcon from "PFIcons/info.svg";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./legend.module.scss";
import basicLegendEngagements from "./legend_basic_engagements";
import basicLegendRoles from "./legend_basic_roles";
import basicLegendWarnings from "./legend_basic_warnings";
import LegendItem from "./legend_item";

const warningClassNames = {
  error: "warningIconError",
  warning: "warningIconWarning",
  critical: "warningIconCritical"
};

const Legend = ({ theme = {}, display = {} }) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation("bookingModule");
  const displaySettings = { roles: true, engagements: true, warnings: true, ...display };
  const { bookingCategories } = useBookingCategories();

  return (
    <label
      className={classNames(
        css.root,
        { [css.expanded]: expanded },
        theme.root,
        theme.expanded && { [theme.expanded]: expanded }
      )}
    >
      <button className={classNames(css.button, theme.button)} onClick={() => setExpanded((prev) => !prev)}>
        <InfoIcon width={16} height={16} />
        {t("legend.label")}
        <ArrowDownIcon width={20} height={20} className={classNames({ [css.arrowUp]: !expanded })} />
      </button>
      <div className={classNames(css.legendContainer, theme.legendContainer)}>
        <div className={classNames(css.legendContainerItems, theme.legendContainerItems)}>
          <h3 className={classNames(css.title, theme.title)}>{t("legend.label")}</h3>
          {displaySettings.roles &&
            basicLegendRoles.map(({ translationKey, state, Component }) => (
              <LegendItem key={translationKey} title={t(translationKey)}>
                <Component state={state} className={css.legendPill} />
              </LegendItem>
            ))}
          {displaySettings.engagements &&
            basicLegendEngagements.map(({ translationKey, state, Component }) => (
              <LegendItem key={translationKey} title={t(translationKey)}>
                <Component state={state} className={css.legendPill} />
              </LegendItem>
            ))}
          {bookingCategories.map((category) => (
            <LegendItem key={category.id} title={category.display_as}>
              <BookingPill category={category} style={{ height: 20, width: 80 }} />
            </LegendItem>
          ))}
          {displaySettings.warnings &&
            basicLegendWarnings.map(({ translation, IconComponent, type }, index) => (
              <LegendItem
                key={`${translation.key}_${type}`}
                title={t(translation.key, translation.variables)}
                className={classNames({ [css.warningsDivider]: index === 0 })}
              >
                <IconComponent width={15} height={15} className={css[warningClassNames[type]]} />
              </LegendItem>
            ))}
        </div>
      </div>
    </label>
  );
};

Legend.propTypes = {
  displayWarnings: PropTypes.bool,
  categoryItems: PropTypes.array,
  display: PropTypes.shape({
    roles: PropTypes.bool,
    engagements: PropTypes.bool,
    warnings: PropTypes.bool
  }),
  theme: PropTypes.shape({
    root: PropTypes.string,
    expanded: PropTypes.string,
    button: PropTypes.string,
    legendContainer: PropTypes.string,
    legendContainerItems: PropTypes.string
  })
};

Legend.defaultPtops = {
  displayWarnings: true
};

export default Legend;
