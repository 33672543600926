import { isNil, isNumber } from "lodash";
import roundToDecimals from "PFCore/helpers/round_to_decimals";
import { useFirstLoad } from "PFCore/hooks/use_first_load";
import { useCallback, useEffect, useState } from "react";

export interface UseNumberInput {
  onChange: (value: number | undefined) => void;
  min: number;
  max?: number;
  defaultValue: number | undefined;
}

interface UseNumberInputReturn {
  value: string | undefined;
  handleChange: (value: string) => void;
}

const getLimitedValue = (value: number, min: number, max?: number): number => {
  if (value < min) {
    return min;
  }
  if (!isNil(max) && value > max) {
    return max;
  }
  return roundToDecimals(value);
};

const NUMBER_WITH_DECIMALS_REGEX = /^\d+(\.\d{0,2})?$/;

export const useNumberInput = ({
  defaultValue,
  onChange,
  min,
  max
}: UseNumberInput): UseNumberInputReturn => {
  const isFirstLoad = useFirstLoad();
  const [value, setValue] = useState<string | undefined>(
    isNumber(defaultValue) ? String(defaultValue) : undefined
  );

  const handleChange = useCallback(
    (inputValue: string): void => {
      if (!inputValue) {
        setValue("");
        onChange(undefined);
        return;
      }
      if (!NUMBER_WITH_DECIMALS_REGEX.test(inputValue)) {
        return;
      }
      if (!inputValue?.endsWith(".")) {
        const newValue = getLimitedValue(Number(inputValue), min, max);
        setValue(String(newValue));
        onChange(newValue);
        return;
      }
      const [integerPart] = inputValue.split(".");
      const newIntegerValue = getLimitedValue(Number(integerPart), min, max);
      setValue(`${newIntegerValue}.`);
      onChange(newIntegerValue);
    },
    [min, max, onChange]
  );

  useEffect(() => {
    if (isNil(value) || isFirstLoad) {
      return;
    }
    handleChange(value);
  }, [max]);

  return {
    value,
    handleChange
  };
};
