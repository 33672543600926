import { Id } from "./id";

export enum HistorySortDirection {
  FromLatest = "desc",
  FromOldest = "asc"
}

export const EventActions = ["created", "updated", "deleted"] as const;
export type EventAction = (typeof EventActions)[number];

export const ActivityMembers = ["assignee", "owner", "reviewer", "interest"] as const;
export type ActivityMember = (typeof ActivityMembers)[number];

export const EventShortlistActions = [
  "assigned",
  "booked",
  "confirmed",
  "created",
  "declined",
  "deleted",
  "filled",
  "rejected",
  "reset"
] as const;
export type EventShortlistAction = (typeof EventShortlistActions)[number];

type EventOperatorProfile = {
  id: Id;
  name: string;
};

export type ActivityMemberEvent = {
  event: `${ActivityMember}_${EventAction}` | `shortlist_${EventShortlistAction}`;
  updatedAttributes: {
    principal?: [boolean, boolean];
    profileId: [Id | null, Id | null];
  };
};

export type ActivityShareEvent = {
  event: "activity_shared" | "help_requested";
  updatedAttributes: {
    profileId: [Id | null, Id[] | null];
  };
};

export type CommonActivityHistoryEntryProperties = {
  id: Id;
  operator?: EventOperatorProfile;
  createdAt: string; // iso date
};

export type ActivityHistoryEntry = CommonActivityHistoryEntryProperties &
  (ActivityMemberEvent | ActivityShareEvent);

export type ActivityHistoryEvent = ActivityHistoryEntry["event"];
