import classNames from "classnames";
import { Button } from "PFComponents/button";
import Tooltip from "PFComponents/tooltip/tooltip";
import SkillRatingIcon from "PFCore/components/icons/skill_rating_icon";
import { createDictionaryConnection } from "PFCore/services/custom_values";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "../icon";
import css from "./custom_values_list.module.scss";

const RatingIcon = ({ customValue, isEditMode, onRateChange, color, size, disabled }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("core", { keyPrefix: "skillRatings" });

  const normalizedRankValue = (newExperience) => {
    const { experience, suggested_experience } = customValue;
    const MAX_EXPERIENCE = 5;
    const experienceValue = newExperience || experience;

    // When it comes to the profile experience, null means "not rated yet"
    // and we need to pass it along
    if (
      experienceValue === null &&
      suggested_experience == null // eslint-disable-line eqeqeq
    ) {
      return null;
    }

    // Profile experience can have one of the following numerical values - 0, 1, 3, 5
    // Dividing it by 5 will adjust the value, so each of the experience
    // levels fits into a different SkillRatingIcon range.
    return parseInt(experienceValue || suggested_experience) / MAX_EXPERIENCE;
  };

  const [experience, setExperience] = useState(normalizedRankValue());
  useEffect(() => {
    setExperience(normalizedRankValue());
  }, [customValue.experience]);

  const isSuggested = !experience && !customValue.top && !customValue.developmental && !customValue.rare;

  const handleWeightChange = (experience) => {
    setLoading(true);
    createDictionaryConnection({
      experience,
      interest: true,
      source: isSuggested ? "skill_suggestion" : null,
      customValueId: customValue.id
    })
      .then(() => {
        setExperience(normalizedRankValue(experience));
        customValue.experience = experience;
      })
      .then(() => {
        setLoading(false);
        onRateChange && onRateChange(customValue);
      });
  };

  const updateExperience = (experience) => {
    if (isEditMode) {
      handleWeightChange(experience);
    }
  };

  if (isEditMode && isSuggested) {
    return (
      <Tooltip
        content={
          <div>
            <div className={css.suggestionTooltipHeader}>{t("prompt")}</div>
            <div>
              <Button
                kind="blank"
                rootClassName={css.suggestionTooltipItem}
                onClick={() => updateExperience(1)}
              >
                {t("basic")}
              </Button>
            </div>
            <div>
              <Button
                kind="blank"
                rootClassName={css.suggestionTooltipItem}
                onClick={() => updateExperience(3)}
              >
                {t("intermediate")}
              </Button>
            </div>
            <div>
              <Button
                kind="blank"
                rootClassName={css.suggestionTooltipItem}
                onClick={() => updateExperience(5)}
              >
                {t("advanced")}
              </Button>
            </div>
          </div>
        }
        trigger="click"
        theme="pf-white"
        interactive
      >
        <span data-level="unrated" className={css.suggestionTooltipIcon}>
          <Icon name="add" size="sm" />
        </span>
      </Tooltip>
    );
  }

  return (
    <SkillRatingIcon
      className={classNames({ [css.disabled]: disabled || loading, [css.weightButton]: isEditMode })}
      updateExperience={updateExperience}
      value={experience}
      isEditMode={isEditMode}
      color={color}
      size={size}
    />
  );
};

RatingIcon.propTypes = {
  isEditMode: PropTypes.bool,
  disabled: PropTypes.bool,
  customValue: PropTypes.shape({
    experience: PropTypes.number,
    interest: PropTypes.bool,
    suggested_experience: PropTypes.number,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    value: PropTypes.string,
    top: PropTypes.bool,
    developmental: PropTypes.bool,
    rare: PropTypes.bool
  }).isRequired,
  onRateChange: PropTypes.func,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

RatingIcon.defaultProps = {
  isEditMode: false
};

export default RatingIcon;
