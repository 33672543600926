import moment from "moment";
import i18n from "PFCore/i18n";
import { capitalize } from "underscore.string";

import roundToDecimals from "./round_to_decimals";

export const DEFAULT_LOCALE = i18n.language;
export const DEFAULT_DATE_FORMAT = "DD MMM YYYY";

export const ISO_DATE_FORMAT = "YYYY-MM-DD";
export const ISO_DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";
// The Z on ISO_DATE_TIME_FORMAT makes use of the time zone, making the end date to be the day after
export const ISO_DATE_TIME_FORMAT_NO_OFFSET = "YYYY-MM-DDTHH:mm:ss";
export const ISO_TIME_FORMAT = "HH:mm";

export const TIME_FORMAT_12H = "hh:mm a";
export const TIME_FORMAT_24H = "HH:mm";

export const DATE_MONTH_YEAR = "MMM YYYY";

export const getWeekdays = () => moment.weekdays(true);
export const getWeekdaysShort = () => moment.weekdaysShort(true);
export const getWeekdaysMin = () => getWeekdaysShort().map((day) => day[0]);
export const getMonths = () => moment.months();

const diffValue = (startDate, endDate) => {
  const days = startDate.diff(endDate, "days");
  if (days > 30 * 2) {
    const months = startDate.diff(endDate, "months");
    return { value: months, type: "months" };
  }
  if (days > 356 * 2) {
    const years = startDate.diff(endDate, "years");
    return { value: years, type: "years" };
  }

  return { value: days, type: "days" };
};

export const agoFormat = (date) => {
  const { value, type } = diffValue(moment(date), moment());
  if (value === 0) {
    return capitalize(i18n.t("core:time.inWords.today"));
  } else {
    return i18n.t("core:time.inWords.ago", {
      time_in_words: i18n.t(`core:time.inWords.${type}`, { count: -value })
    });
  }
};

export const fromFormat = (date) => i18n.t("core:time.inWords.fromDuration.current", { from: date });

export const dateFromMonths = (months) => {
  if (months < 1) {
    return i18n.t("core:time.inWords.lessThanAMonth");
  } else if (months > 0 && months < 12) {
    return i18n.t("core:time.inWords.months", { count: months });
  } else if (months >= 12) {
    return months % 12 === 0
      ? i18n.t("core:time.inWords.years", { count: months / 12 })
      : i18n.t("core:time.inWords.lessThanYears", { count: Math.ceil(months / 12) });
  } else {
    return "";
  }
};

export const durationFromMonths = (months) => {
  const duration = moment.duration(months, "months");

  if (months > 0) {
    if (months <= 11) {
      return i18n.t("core:time.inWords.months", { count: duration.asMonths() });
    } else {
      if (months % 12 === 0) {
        return i18n.t("core:time.inWords.years", { count: months / 12 });
      } else {
        const wholeYears = Math.floor(months / 12);
        const remainingMonths = months % 12;
        return i18n.t("core:time.inWords.yearsAndMonths", { years: wholeYears, months: remainingMonths });
      }
    }
  } else {
    return "";
  }
};

export const smartAgoFormat = (date) => {
  if (moment(date).isBefore(moment().subtract(3, "day"))) {
    return moment(date).format(DEFAULT_DATE_FORMAT);
  } else {
    return moment(date).fromNow();
  }
};

export const daysFromNowFormat = (date) => {
  const dayDiff = moment().diff(moment(date), "days");

  if (dayDiff === 0) {
    return i18n.t("core:time.inWords.due");
  }

  return `${dayDiff > 0 ? "+" : ""}${i18n.t("core:time.inWords.days", { count: dayDiff })}`;
};

export const minutesToHours = (minutes = 0) => roundToDecimals(minutes / 60);
export const hoursToMinutes = (hours = 0) => roundToDecimals(hours * 60);
