import { useQuery } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { isEmpty } from "lodash";
import { fetchWorkforce } from "PFApp/booking/services/api";
import { PER_PAGE_UNPAGINATED } from "PFApp/constants/unpaginated";
import { CalendarRange, QueryOptions } from "PFTypes";

import { workforceKeys } from "./query_keys";
import { WorkforceResponse } from "./use_workforce_entries";

const EMPTY_RESPONSE = { entries: [], meta: { total: 0, totalPages: 0, perPage: 0, page: 0 } };
const DEFAULT_ORDER = { name: ["profile.name"], text: "A-Z", direction: "asc" };
const getWorkforceMemberFilters = (pinnedProfiles: number[]) => ({
  fields: { workforce_member_id: pinnedProfiles }
});

type UsePinnedWorkforceMembers = {
  pinnedIds: number[];
  dateRange: CalendarRange;
  options: QueryOptions<WorkforceResponse>;
};

export const usePinnedWorkforceMembers = ({
  pinnedIds,
  dateRange,
  options = {}
}: UsePinnedWorkforceMembers): UseQueryResult<WorkforceResponse> => {
  const filters = getWorkforceMemberFilters(pinnedIds);
  return useQuery<WorkforceResponse, { status: number }>({
    queryKey: workforceKeys.pinned(filters, DEFAULT_ORDER, dateRange, 1, PER_PAGE_UNPAGINATED),
    queryFn: () => {
      // In case workforce member filter is empty it returns all entries, but we want otherwise.
      // Setting enable=false doesn't solve the issue because then keepPreviousData remembers wrong prev data.
      if (isEmpty(pinnedIds)) {
        return Promise.resolve(EMPTY_RESPONSE);
      }
      return fetchWorkforce(
        1,
        PER_PAGE_UNPAGINATED,
        DEFAULT_ORDER,
        filters,
        dateRange
      ) as unknown as Promise<WorkforceResponse>;
    },
    retry: false,
    keepPreviousData: true,
    ...options
  });
};
