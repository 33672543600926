import classNames from "classnames";
import { BookingCategorySelect as BookingCategorySelectInput } from "PFApp/booking/components/booking_category_select/booking_category_select";
import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import { BookingCategory } from "PFTypes";
import { useTranslation } from "react-i18next";

import css from "./booking_category_select.modules.scss";

interface Props {
  category?: BookingCategory | null;
  onCategoryChange: (value: BookingCategory | null) => void;
  error?: string;
  className: string;
  isRoleRelated: boolean;
}

export const BookingCategorySelect = ({
  category,
  onCategoryChange,
  error,
  className,
  isRoleRelated
}: Props) => {
  const { t } = useTranslation(["bookingModule", "translation"]);

  const getIcon = (active: boolean) => <Icon name={active ? "check" : "cross"} size="md" />;

  return (
    <>
      <BookingCategorySelectInput
        category={category ?? null}
        onCategoryChange={onCategoryChange}
        className={className}
        error={error}
        required
        demand={isRoleRelated}
      />
      <div
        className={classNames(css.bookingSelections, {
          [css.boxDisabled]: !category
        })}
      >
        <div className={classNames(css.categoryBox)}>
          {category ? getIcon(category?.billable) : <Icon name="question" size="md" />}
          <Typography variant="bodyBold" tag="span">
            {t("translation:billable")}
          </Typography>
        </div>
        <div className={classNames(css.categoryBox)}>
          {category ? getIcon(category?.affects_availability) : <Icon name="question" size="md" />}
          <Typography variant="bodyBold" tag="span">
            {t("translation:affectsAvailability")}
          </Typography>
        </div>
      </div>
    </>
  );
};
