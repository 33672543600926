import EngagementPill from "PFApp/components/pills/engagement_pill";

type LegendEngagementType = {
  translationKey: string;
  state: "new" | "partially_booked" | "booked";
  Component: React.FC<any>;
};

const basicLegendEngagements: LegendEngagementType[] = [
  {
    translationKey: "engagement",
    state: "new",
    Component: EngagementPill
  },
  {
    translationKey: "legend.partialBookedEngagement",
    state: "partially_booked",
    Component: EngagementPill
  },
  {
    translationKey: "legend.bookedEngagement",
    state: "booked",
    Component: EngagementPill
  }
];

export default basicLegendEngagements;
