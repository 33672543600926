import { useMutation } from "@tanstack/react-query";
import { decamelizeKeys } from "humps";
import { createReviewer, ReviewerCreatePayload } from "PFCore/services/reviewers";
import { Reviewer } from "PFTypes";
import { MutationOptions } from "PFTypes/request_options";

import { useActivityInvalidate } from "../activity/use_activity_invalidate";

export const useReviewerCreate = ({ onSuccess, ...options }: MutationOptions<ReviewerCreatePayload> = {}) => {
  const { invalidate: invalidateActivity } = useActivityInvalidate();

  return useMutation({
    mutationFn: (payload: ReviewerCreatePayload) => createReviewer(payload),
    onSuccess: (data, ...params) => {
      const owner = decamelizeKeys(data) as Reviewer;
      owner.activity_id && invalidateActivity([owner.activity_id]);
      onSuccess?.(owner, ...params);
    },
    ...options
  });
};
