import { createContext, useContext, useEffect } from "react";

import { useProfileSectionContext } from "../profile_section_context_provider";
import { Experience } from "./experience_card";

type ExperienceSectionContextType = {
  data: Experience[];
  selectedElement: Record<string, unknown> | null;
  setSelectedElement: (experience: Experience) => void;
  resetSelected: () => void;
  isSelected: boolean;
  isEditMode: boolean;
  setEditMode: (val: boolean) => void;
};

type ExperienceSectionContextProps = {
  data: unknown[];
};

const parseExperience = (data: any[]): Experience[] =>
  data.map((position) => ({
    title: position.job_title[0].text || "",
    duration: { from: position.start_date, to: position.end_date },
    company: position.company,
    description: position.summary || "",
    badges: [
      ...(position.sectors
        ? position.sectors.map((sector) => ({ isSelected: false, title: sector.text }))
        : [])
    ],
    lastEdited: position.updated_at,
    isCurrent: position.current
  }));

const ExperienceSectionContext = createContext({} as ExperienceSectionContextType);

export const ExperienceSectionContextProvider = ({
  children,
  data
}: React.PropsWithChildren<ExperienceSectionContextProps>) => {
  const { isEditMode, setIsEditMode, setSelectedElement, selectedElement, resetSelected, isSelected } =
    useProfileSectionContext();

  useEffect(() => {
    setSelectedElement(null);
  }, [isEditMode]);

  return (
    <ExperienceSectionContext.Provider
      value={{
        isEditMode,
        setEditMode: setIsEditMode,
        data: parseExperience(data),
        selectedElement,
        setSelectedElement,
        resetSelected,
        isSelected
      }}
    >
      {children}
    </ExperienceSectionContext.Provider>
  );
};

export const useExperienceSectionContext = () => useContext(ExperienceSectionContext);
