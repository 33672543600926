import { useGrowl } from "PFApp/use_growl";
import DropdownButton from "PFComponents/dropdown/dropdown_button";
import { Icon } from "PFComponents/icon";
import { useActivityEconomicsScenarioUnassign } from "PFCore/hooks/queries/activity";
import { EconomicsVacancy, Id } from "PFTypes";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

type ScenarioActionsProps = {
  vacancy: EconomicsVacancy;
  scenarioId: Id;
  disabled?: boolean;
};

export const VacancyActions = ({ vacancy, scenarioId, disabled }: ScenarioActionsProps) => {
  const growl = useGrowl();
  const { t } = useTranslation("activities", { keyPrefix: "show.economics" });

  const { mutate: unassign } = useActivityEconomicsScenarioUnassign(scenarioId);

  const handleDelete = useCallback(() => {
    unassign(vacancy.assignment.id, {
      onSuccess: () => {
        growl({
          kind: "success",
          message: t("scenario.unassignSuccess")
        });
      },
      onError: () => {
        growl({
          kind: "error",
          message: t("scenario.unassignError")
        });
      }
    });
  }, [unassign, vacancy.assignment?.id, growl, t]);

  const options = useMemo(
    () => [{ id: "remove", displayElement: t("remove"), item: handleDelete }],
    [t, handleDelete]
  );

  return (
    <DropdownButton
      options={options}
      buttonKind="blank"
      buttonStyle={{ height: 18 }}
      style={{ marginLeft: "auto" }}
      handleChange={(fn) => fn && fn(vacancy)}
      disabled={disabled}
    >
      <Icon name="menu-vertical" size="md" />
    </DropdownButton>
  );
};
