import ActivityPreviewLink from "PFApp/components/activity_commons/activity_preview_link";
import BadgesList from "PFComponents/badges_list/badges_list";
import { useActivityNameAndId } from "PFCore/helpers/activities";
import PropTypes from "prop-types";

import css from "./content.less";
import Description from "./description";

const EngagementContent = ({ task }) => {
  const getRoleNameId = useActivityNameAndId();
  const { name, id } = getRoleNameId(task);

  const brands = task.custom_fields.find((item) => item.type.name === "brands");

  return (
    <div className={css.wrapper}>
      <ActivityPreviewLink activity={task}>
        <div className={css.header}>
          <h3>{name}</h3>
          {id && <div>{`ID: ${id}`}</div>}
        </div>
        <div className={css.description}>
          <Description description={task.description} id={task.id} />
        </div>
        {brands && <BadgesList values={brands.values} />}
      </ActivityPreviewLink>
    </div>
  );
};

EngagementContent.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number,
    template_id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    metadata: PropTypes.shape({
      location: PropTypes.string
    }),
    custom_fields: PropTypes.array,
    created_at: PropTypes.string
  })
};

export default EngagementContent;
