import { REQUIRED_SKILL_WEIGHT } from "PFTypes";

import { useRoleMatchCustomFieldData } from "./use_role_match_custom_field_data";

export const useRequiredSkills = () => {
  const { getCustomFieldValuesOfWeight } = useRoleMatchCustomFieldData();
  const { roleCustomValues, matchCustomValues, missingMatchCustomValues } = getCustomFieldValuesOfWeight(
    "skills",
    REQUIRED_SKILL_WEIGHT
  );

  return {
    roleRequiredSkills: roleCustomValues,
    matchRequiredSkills: matchCustomValues,
    missingMatchRequiredSkills: missingMatchCustomValues
  };
};
