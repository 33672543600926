import { isNil } from "lodash";
import roundToDecimals from "PFCore/helpers/round_to_decimals";
import { BookingRequirementData, RequirementType } from "PFTypes";

import { Requirement } from "../use_requirement";

export const DEFAULT_LOAD_REQUIREMENT = 100;

const toHours = (valueInMinutes: number) => roundToDecimals(valueInMinutes / 60);
const toMinutes = (valueInHours: number) => roundToDecimals(valueInHours * 60);

export const isSomeRequirementInvalid = (data: { requirement: Requirement }[]) =>
  data.some(({ requirement }) => !requirement.value);

export const mapRequirementToBookingData = ({
  type,
  value
}: Required<Requirement>): BookingRequirementData => ({
  requirement_type: type, //eslint-disable-line camelcase
  requirement_value: type !== RequirementType.Load ? toMinutes(value) : value //eslint-disable-line camelcase
});

export const mapBookingDataToRequirement = ({
  requirement_type, //eslint-disable-line camelcase
  requirement_value //eslint-disable-line camelcase
}: BookingRequirementData): Requirement =>
  isNil(requirement_type)
    ? {
        type: RequirementType.Load,
        value: DEFAULT_LOAD_REQUIREMENT
      }
    : {
        type: requirement_type, //eslint-disable-line camelcase
        value: requirement_type !== RequirementType.Load ? toHours(requirement_value) : requirement_value //eslint-disable-line camelcase
      };
