import { find } from "lodash";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { usePhases } from "PFCore/hooks/queries/phases/use_phases";
import CalendarIcon from "PFIcons/calendar_fixed.svg";
import { FeatureFlag } from "PFTypes";
import { Phase } from "PFTypes/phase";
import { useTranslation } from "react-i18next";

import css from "../../detail_view.module.scss";
import { BookingDetailDate } from "./booking_detail_date";
import { BookingDetailItem } from "./booking_detail_item";

type BookingDetailDurationProps = {
  startDate: string | undefined;
  endDate: string | undefined;
  phaseSourceId?: string;
};

export const BookingDetailDuration = ({
  startDate,
  endDate,
  phaseSourceId
}: BookingDetailDurationProps): JSX.Element => {
  const { t } = useTranslation("translation");

  const hasActivityPhases = useIsFeatureEnabled()(FeatureFlag.ActivityPhases);
  const { data: phases } = usePhases(null, {
    enabled: hasActivityPhases,
    placeholderData: { entries: [], meta: {} } as any
  });

  const phase = find(phases!.entries as Phase[], ["sourceId", phaseSourceId]);

  return (
    <BookingDetailItem
      label={t("duration")}
      labelClassName={css.noWrap}
      rootClassName={css.duration}
      headerClassName={css.alignCenter}
      icon={<CalendarIcon viewBox="3 0 20 20" height={20} fill="var(--Palette-text)" />}
      value={<BookingDetailDate startDate={startDate} endDate={endDate} phase={phase} />}
    />
  );
};
