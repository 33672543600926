import AppContext from "PFApp/app_context";
import ConfirmModal from "PFComponents/confirm/confirm_modal";
import Pill from "PFComponents/pill/pill";
import AutoSelect from "PFComponents/select/autoselect";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { GROWL_ACTIONS } from "PFCore/reducers/growl_reducer";
import { fetchProfilesOptions } from "PFCore/services/profile";
import { ApiRoute } from "PFCore/utilities/routes";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

const InviteModal = ({ conversation, participants, handleClose }) => {
  const [items, setItems] = useState([]);
  const { dispatch } = useContext(AppContext);
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation();

  return (
    <ConfirmModal
      title={t("conversations.inviteToConversation", { title: conversation?.title || "" })}
      handleClose={handleClose}
      labelOK={t("conversations.inviteParticipants", { count: items.length })}
      showOKButton={items.length > 0}
      handleOK={() => {
        Promise.all(
          items.map(({ id }) =>
            $.post({
              api_version: 2,
              url: ApiRoute("/api/chat/participants"),
              data: { conversation_id: conversation.id, profile_id: id }
            })
          )
        ).then(() => {
          dispatch({
            type: GROWL_ACTIONS.GROWL_RENDER,
            payload: {
              message: t("conversations.invitedToConvo", {
                count: items.length,
                invited: items.length === 1 ? items[0].text : items.length
              })
            }
          });
        });
      }}
    >
      <AutoSelect
        placeholder={t("conversations.userNamePlaceholder")}
        cache={false}
        closeOnChange
        multi={true}
        handleChange={(items) => setItems(items)}
        query={(term) => fetchProfilesOptions({ term })}
        filterOptions={(response) => response.filter((item) => currentProfile.id !== item.id)}
        formatOption={(item) => {
          const alreadyParticipates = !!participants.find(({ profile }) => profile.id === item.id);
          const pending = !!items.find(({ id }) => id === item.id);

          return {
            id: item.id,
            disabled: alreadyParticipates || pending,
            displayElement: (
              <>
                {alreadyParticipates && <Pill>{t("conversations.participant")}</Pill>}
                {pending && <Pill>{t("conversation.pending")}</Pill>}
                <span>{` ${item.text}`}</span>
              </>
            ),
            item
          };
        }}
      />
      {items.length > 0 && (
        <p style={{ marginTop: 30 }}>
          {t("conversations.participantWillReceiveEmail", { count: items.length })}
        </p>
      )}
    </ConfirmModal>
  );
};

InviteModal.propTypes = {
  participants: PropTypes.array,
  conversation: PropTypes.shape({ id: PropTypes.number, title: PropTypes.string }),
  handleClose: PropTypes.func
};

export default InviteModal;
