import { ColumnHelper } from "@tanstack/react-table";
import Checkbox from "PFComponents/checkbox/checkbox";

import { TableData } from "../table";
import { BulkSelect } from "./table_bulk_select";
import css from "./table_bulk_select.module.scss";

export const generateSelectColumn = <TABLE_DATA extends TableData>(columnHelper: ColumnHelper<TABLE_DATA>) =>
  columnHelper.accessor((row) => row, {
    id: "select",
    enableSorting: false,
    header: (info) => (
      <BulkSelect
        onChange={info?.table?.getToggleAllRowsSelectedHandler()}
        isAllSelected={info?.table?.getIsAllRowsSelected()}
        isPartiallySelected={!!info?.table?.getIsSomeRowsSelected()}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        className={css.tableCheckbox}
        checked={row.getIsSelected()}
        handleChange={(checked) => row.toggleSelected(checked)}
      />
    ),
    size: 50
  });
