import { compact, forEach, sortBy, uniqBy } from "lodash";
import {
  SkillImportance,
  SkillsFramework,
  SkillsFrameworkCustomType,
  SkillsFrameworkCustomValue,
  SkillsFrameworkRank
} from "PFTypes";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";

import {
  SkillsFrameworkCustomField,
  SkillsFrameworkCustomValueDecorated,
  SkillsFrameworkDecorated,
  SkillsFrameworkKind
} from "./skills_framework_select_modal.types";
import { useConflictResolver } from "./use_conflict_resolver";

type UseSkillsFrameworkModalReturn = {
  essentialSkills: SkillsFrameworkDecorated["skills"];
  supportingSkills: SkillsFrameworkDecorated["skills"];
  primaryFramework?: SkillsFramework;
  secondaryFrameworks: SkillsFrameworkDecorated[];
  setPrimaryFramework: Dispatch<SetStateAction<SkillsFramework>>;
  setSecondaryFrameworks: Dispatch<SetStateAction<SkillsFramework[]>>;
  customFields: SkillsFrameworkCustomField[];
  handleSubmit?: () => void;
  handleCustomFieldConflictResolve: (
    value: SkillsFrameworkCustomValue,
    type: SkillsFrameworkCustomType
  ) => void;
};

const kinds = [SkillsFrameworkKind.Secondary, SkillsFrameworkKind.Faded, SkillsFrameworkKind.Selected];

export const useSkillsFrameworkModal = (
  skillsFrameworks: SkillsFramework[],
  onSubmit: (
    skillsFrameworks: SkillsFramework[],
    skills: SkillsFramework["skills"],
    customFields: SkillsFrameworkCustomField[]
  ) => void
): UseSkillsFrameworkModalReturn => {
  const [primaryFramework, setPrimaryFramework] = useState<SkillsFramework | undefined>(
    skillsFrameworks.find(({ rank }) => rank === SkillsFrameworkRank.Primary)
  );
  const [secondaryFrameworks, setSecondaryFrameworks] = useState<SkillsFramework[]>(
    skillsFrameworks.filter(({ rank }) => rank === SkillsFrameworkRank.Secondary)
  );
  const [customFields, setCustomFields] = useState<SkillsFrameworkCustomField[]>([]);
  const { handleConflictResolve, getCustomFieldsWithConflicts } = useConflictResolver();

  const secondaryFrameworksWithClassName = useMemo<SkillsFrameworkDecorated[]>(
    () =>
      secondaryFrameworks.map((framework, index) => ({
        ...framework,
        kind: kinds[index % 3]
      })),
    [secondaryFrameworks]
  );

  const selectedFrameworks = useMemo(
    () => compact([primaryFramework, ...secondaryFrameworksWithClassName]),
    [primaryFramework, secondaryFrameworksWithClassName]
  );

  const getSkillsByImportance = useCallback(
    (importance: SkillImportance) =>
      selectedFrameworks.reduce((acc: SkillsFramework["skills"], framework: SkillsFrameworkDecorated) => {
        const frameworkSkills = framework.skills
          .filter((framework) => framework.importance === importance)
          .map((skill) => ({
            ...skill,
            customValue: { ...skill.customValue, kind: framework.kind }
          }));
        return [...acc, ...frameworkSkills];
      }, []),
    [selectedFrameworks]
  );

  const essentialSkills = useMemo<SkillsFrameworkDecorated["skills"]>(
    () => getSkillsByImportance(SkillImportance.Essential),
    [getSkillsByImportance]
  );

  const supportingSkills = useMemo<SkillsFrameworkDecorated["skills"]>(
    () => getSkillsByImportance(SkillImportance.Supporting),
    [getSkillsByImportance]
  );

  useEffect(() => {
    const newCustomFields = selectedFrameworks.reduce(
      (acc: SkillsFrameworkCustomField[], framework: SkillsFrameworkDecorated) => {
        forEach(framework.customFields || [], (field) => {
          const existingItem = acc.find(({ type }) => type.id === field.customType.id);
          const valueDecorated = {
            ...field.customValue,
            kind: framework.kind,
            primary: framework.id === primaryFramework?.id
          };
          if (existingItem) {
            existingItem.values = uniqBy(sortBy([...existingItem.values, valueDecorated], "primary"), "id");
          } else {
            acc.push({
              type: field.customType,
              values: [valueDecorated]
            });
          }
        });
        return acc;
      },
      []
    );
    setCustomFields(getCustomFieldsWithConflicts(newCustomFields));
  }, [selectedFrameworks, getCustomFieldsWithConflicts, primaryFramework]);

  const handleSubmit = useCallback(
    () => onSubmit(selectedFrameworks, [...essentialSkills, ...supportingSkills], customFields),
    [customFields, essentialSkills, supportingSkills, selectedFrameworks, onSubmit]
  );

  const handleCustomFieldConflictResolve = useCallback(
    (value: SkillsFrameworkCustomValueDecorated, type: SkillsFrameworkCustomType) =>
      setCustomFields((prev) => handleConflictResolve(value, type, prev)),
    [handleConflictResolve]
  );

  return {
    essentialSkills,
    supportingSkills,
    customFields,
    secondaryFrameworks: secondaryFrameworksWithClassName,
    primaryFramework,
    setPrimaryFramework,
    setSecondaryFrameworks,
    handleSubmit: selectedFrameworks.length > 0 ? handleSubmit : undefined,
    handleCustomFieldConflictResolve
  };
};
