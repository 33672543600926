import CheckIcon from "PFIcons/check.svg";
import XIcon from "PFIcons/close.svg";

import css from "../../detail_view.module.scss";

const getIcon = (checked) =>
  checked ? <CheckIcon height={12} width={12} /> : <XIcon viewBox="5 5 10 10" height={12} width={12} />;

type BookingDetailBooleanPropertyProps = {
  checked: boolean;
  label: string;
};

export const BookingDetailBooleanProperty = ({
  checked,
  label
}: BookingDetailBooleanPropertyProps): JSX.Element => (
  <div className={css.itemText}>
    {getIcon(checked)} {label}
  </div>
);
