import AnimatedText from "PFComponents/animated_text/animated_text";
import ProgressBar from "PFComponents/progress_bar/progress_bar";
import { getProfileName } from "PFCore/helpers/profile";
import CheckIcon from "PFIcons/check_2.svg";
import { Profile } from "PFTypes";
import { Trans, useTranslation } from "react-i18next";

import ProfileAvatarEdit from "../profile_avatar_edit";
import css from "./side_panel.module.scss";

type ProfileSectionProps = {
  profile: Profile;
  progressCompletion: number;
  isCompleted: boolean;
  isMe: boolean;
  handleProfileUpdate: (data: any, options?: { renderGrowl: boolean }) => void;
  errors: any;
};

export const ProfileSection = ({
  profile,
  progressCompletion,
  isCompleted,
  isMe,
  handleProfileUpdate,
  errors
}: ProfileSectionProps): JSX.Element => {
  const { t } = useTranslation("profiles");
  const profileName = getProfileName(profile);
  return (
    <>
      <ProfileAvatarEdit
        profile={profile}
        isMe={isMe}
        handleProfileUpdate={handleProfileUpdate}
        errors={errors}
      />
      <div className={css.name}>
        <h3>{profileName}</h3>
        {isCompleted && <CheckIcon className={css.completedIcon} height={17} width={20} />}
      </div>
      {isMe && !isCompleted && (
        <>
          <ProgressBar values={[{ value: progressCompletion }]} totalValue={100} />
          <div className={css.profileCompletionInfo}>
            <Trans
              i18nKey="show.parts.profilePercentComplete"
              t={t}
              components={[<AnimatedText value={progressCompletion} incrementsPerSecond={125} key="0" />]}
            />
          </div>
        </>
      )}
    </>
  );
};
