import { KEY_ENTER, KEY_SPACE } from "PFComponents/text/keys";

const useIsActionKeyPressed = () => (e) => {
  const { keyCode } = e;
  if (keyCode === KEY_ENTER || keyCode === KEY_SPACE) {
    return true;
  } else {
    return false;
  }
};

export default useIsActionKeyPressed;
