import { uniqBy } from "lodash";
import AppContext from "PFApp/app_context";
import { ChoosenKeyword } from "PFApp/search/search_box/choosen_keywords";
import { Button } from "PFComponents/button";
import AnalyticsPush from "PFCore/helpers/analytics_push";
import RefreshIcon from "PFIcons/refresh.svg";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import Keyword from "./keyword";
import css from "./smart_suggestions_list.less";

const PAGE_SIZE = 6;

const SmartSuggestionsList = ({
  items,
  title,
  onAdd,
  onRemove,
  selected,
  cycle,
  cycleOffset,
  style,
  usedOn,
  userId
}) => {
  const {
    store: {
      search: { term }
    }
  } = useContext(AppContext);

  const letCycle = cycle && items.length > PAGE_SIZE;

  const safeItems = uniqBy(items, "value");

  let shownItems;
  if (letCycle) {
    const offset = ((cycleOffset || 0) % Math.ceil(safeItems.length / PAGE_SIZE)) * PAGE_SIZE;
    shownItems = safeItems.slice(offset, offset + PAGE_SIZE);
    if (shownItems.length < PAGE_SIZE) {
      shownItems = safeItems.slice(-PAGE_SIZE);
    }
  } else {
    shownItems = safeItems.slice(0, PAGE_SIZE);
  }

  const pushToAnalytics = (item, action) =>
    AnalyticsPush(`${usedOn}_recomendations`, `click in ${usedOn} suggestion`, {
      eventCategory: `${usedOn}_recomendations`,
      eventAction: `click in ${usedOn} suggestion`,
      userId,
      action,
      searchText: term,
      recomendations: shownItems,
      selectedRecomendation: item.value,
      index: shownItems.findIndex(({ value }) => value === item.value)
    });

  const handleRemove = (item) => {
    onRemove(item);
    pushToAnalytics(item, "decline");
  };

  const handleAdd = (item) => {
    onAdd(item);
    pushToAnalytics(item, "accept");
  };

  return (
    shownItems.length > 0 && (
      <div className={css.list} style={style} data-qa-id="SmartSuggestionsList">
        <span className={css.listTitle}>{title}</span>
        <ul style={{ margin: 0, padding: 0 }}>
          {shownItems.map((item) =>
            selected && selected(item) ? (
              <ChoosenKeyword key={item.value} text={item.value} onRemove={() => handleRemove(item)} />
            ) : (
              // only user can assign this weight
              <Keyword
                key={item.value}
                text={item.value}
                onAdd={() => handleAdd({ ...item, weight: null })}
              />
            )
          )}
        </ul>
        {letCycle && <RefreshLink cycle={cycle} />}
      </div>
    )
  );
};

SmartSuggestionsList.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  selected: PropTypes.func,
  cycle: PropTypes.func,
  cycleOffset: PropTypes.number,
  style: PropTypes.object,
  usedOn: PropTypes.string,
  userId: PropTypes.number.isRequired
};

const RefreshLink = ({ cycle }) => {
  const { t } = useTranslation("search");
  const [angle, setAngle] = useState(0);

  const handleRefresh = () => {
    cycle();
    setAngle(angle - 180);
  };

  const style = { transform: `rotate(${angle}deg)` };

  return (
    <Button kind="text" rootClassName={css.refresh} onClick={handleRefresh}>
      <RefreshIcon width={20} height={20} style={style} /> {t("smartSuggestions.refreshSuggestions")}
    </Button>
  );
};

RefreshLink.propTypes = {
  cycle: PropTypes.func
};

export default SmartSuggestionsList;
