import { merge } from "lodash";

import BookingActivityContextProvider from "../../parts/providers/booking_activity_context_provider";
import { BookingForm, BookingFormProps } from "./booking_form";
import { useBookingFormContext } from "./booking_form_context_provider";
import { BookingFormMode } from "./use_booking_form";

export const BookingFormWrapper = ({
  initialData = {},
  ...modalProps
}: Omit<BookingFormProps, "mode" | "show">) => {
  const {
    modal: {
      state: { isOpen, data, props }
    }
  } = useBookingFormContext();

  return (
    <BookingActivityContextProvider id={data?.activityId ?? undefined}>
      <BookingForm
        show={isOpen}
        initialData={merge({}, data, initialData)}
        mode={props?.mode ?? BookingFormMode.Create}
        {...props}
        {...modalProps}
      />
    </BookingActivityContextProvider>
  );
};
