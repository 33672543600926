import { Activity, TemplateConfiguration } from "PFTypes";

export const isSubtemplatesLogicActive = (
  hasSubtemplates: boolean,
  hasSubtemplateKey: boolean,
  isEdit: boolean,
  isClone: boolean
): boolean => {
  if (!hasSubtemplates) {
    return false;
  }

  if ((isEdit || isClone) && !hasSubtemplateKey) {
    return false;
  }

  return true;
};

export const getPayloadCustomFields = (customFields) =>
  customFields.map((customField) => {
    const values = customField.values.map((attribute) => ({
      id: attribute.id,
      text: attribute.text,
      value: attribute.value,
      weight: attribute.weight,
      experience: attribute.experience
    }));
    return { ...customField, values };
  });

export const getInitialQuestionValue = (
  questionNumber: number,
  activity: Activity,
  templateConfig: TemplateConfiguration | undefined,
  isEdit: boolean,
  isClone: boolean
): string => {
  const activityQuestion = activity.metadata?.interest_questions?.[questionNumber - 1];
  if (isEdit) {
    return activityQuestion || "";
  }

  const areQuestionsProvidedInActivity = !!activity.metadata?.interest_questions;
  if (isClone && areQuestionsProvidedInActivity) {
    return activityQuestion || "";
  }

  return templateConfig?.[`question${questionNumber}`] || "";
};

export const getInitialQuestionsToggleValue = (
  activity: Activity,
  templateConfig: TemplateConfiguration | undefined,
  isEdit: boolean,
  isClone: boolean
): boolean => {
  if (isEdit || isClone) {
    return !!activity.metadata?.interest_questions;
  }

  return !!templateConfig?.show_questions;
};

export const getQuestionsTileVisibility = (
  activity: Activity,
  templateConfig: TemplateConfiguration | undefined,
  isEdit: boolean,
  isClone: boolean,
  isAtLeastOneQuestion: boolean
): boolean => {
  if (isEdit) {
    return isAtLeastOneQuestion;
  }

  if (isClone) {
    const areQuestionsProvidedInActivity = !!activity.metadata?.interest_questions;

    return areQuestionsProvidedInActivity;
  }

  return !!templateConfig?.show_questions && (isAtLeastOneQuestion || !!templateConfig?.allow_rm_to_edit);
};

export const WORKFLOW_STATUSES_WITH_BOOKINGS = ["partially_booked", "booked", "confirmed"];
