/* eslint-disable camelcase */
import classNames from "classnames";
import moment from "moment";
import { useTemplate } from "PFApp/hooks";
import EditInternalExperienceForm from "PFApp/profiles/common/experience/edit_internal_experience_form";
import EditPositionForm from "PFApp/profiles/common/experience/edit_position_form";
import { useGrowl } from "PFApp/use_growl";
import { ActionIcon } from "PFComponents/action_icon";
import CustomValuesList from "PFComponents/custom_values_list/custom_values_list";
import { Markdown } from "PFComponents/markdown";
import { customTypesAccessLevelsAgainstProfile } from "PFCore/helpers/custom_types";
import { durationFromMonths } from "PFCore/helpers/date";
import scrollTo from "PFCore/helpers/scroll_to";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { useCurrentAccount } from "PFCore/hooks/queries/account/use_current_account";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import DownArrowIcon from "PFIcons/down_arrow.svg";
import RoundedAddIcon from "PFIcons/rounded_add_bis.svg";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import InternalExperienceComponent from "./internal_experience_component";
import css from "./section_experience.modules.scss";

const ExperienceComponent = ({
  experience,
  profile,
  adminPage,
  currentProfile,
  customTypes,
  fetchInternalExperience,
  canEdit,
  handleProfileUpdate,
  isMe
}) => {
  const { t } = useTranslation("profiles");
  const growl = useGrowl();
  const { getFormattedErrors } = useResponseErrors();
  const { data: currentAccount } = useCurrentAccount();

  const { formatDate } = useDateFormatter();
  const { internalPositions } = experience;
  const [collapsedFullSection, setCollapsedFullSection] = useState(false);
  const [collapsedDescription, setCollapsedDescription] = useState(true);

  const [addNewInternalExperience, setAddNewInternalExperience] = useState(false);

  const internalExperienceTemplate = useTemplate({ key: "work" });

  const ownGroup = currentProfile.permissions_group;
  const ownGroupPermissions = ownGroup.cross_group_permissions[ownGroup.id];

  const internalExperiencePermissions =
    ownGroupPermissions.activities_access_levels[internalExperienceTemplate?.id];

  const isInternalExperienceVisible =
    internalExperienceTemplate && ["ro", "rw"].includes(internalExperiencePermissions);

  const newExperience = {
    // eslint-disable-next-line camelcase
    custom_fields: [],
    name: "",
    description: "",
    metadata: {
      // eslint-disable-next-line camelcase
      engagement_hours: undefined,
      // eslint-disable-next-line camelcase
      position_id: experience.id, // positionId, this is the main experiecne position ID
      // eslint-disable-next-line camelcase
      date_range: { start_date: null, end_date: null, current: false },
      location: ""
    }
  };

  const CollapseButton = () => (
    <button
      className={classNames(css.noStyleButton, css.collapseButton)}
      onClick={() => setCollapsedDescription((prev) => !prev)}
    >
      {collapsedDescription ? t("translation:more") : t("translation:less")}
    </button>
  );

  const isCfPermitted = useCallback(
    (name) => {
      const accessLevels = customTypesAccessLevelsAgainstProfile(profile, currentProfile);
      const customType = customTypes.find((ct) => ct.name === name);

      return ["ro", "rw"].includes(accessLevels[customType?.id]);
    },
    [profile, currentProfile, customTypes]
  );

  const handleRemove = (data) =>
    handleProfileUpdate(data, { renderGrowl: false }).catch((resp) => {
      const errors = getFormattedErrors(resp);
      let message = t("translation:form.notSaved");
      const key = errors && Object.keys(errors).find((key) => /internal_experience/.test(key));
      if (key) {
        message = errors[key];
      }
      growl({
        message,
        kind: "error"
      });
    });

  const cfName = (name) => customTypes.find((ct) => ct?.name === name)?.display_as;

  const mainJobRenderer = (position) => {
    const mainPositionCustomFields = Object.keys(position).filter((item) => isCfPermitted(item));
    const cfkeyToValue = mainPositionCustomFields.reduce(
      (acc, item) => ({
        ...acc,
        [item]: cfName(item)
      }),
      {}
    );

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [editMode, setEditMode] = useState(false);
    const isOnlyDuration = position.duration && !position.start_date;
    const isStartDateValid = moment(position.start_date).isValid();
    const isEndDateValid = moment(position.end_date).isValid();

    return (
      <>
        <div className={css.row}>
          {canEdit && (
            <ActionIcon
              onClick={() => setEditMode((prev) => !prev)}
              name={editMode ? "cross" : "edit"}
              size="sm"
              classes={{ root: css.editIcon }}
            />
          )}
          <div className={css.title} data-scroll-anchor={`internal-experience-${experience.id}`}>
            <div className={css.column}>
              {position.job_title && (
                <div className={css.titleCompany}>{`${position.job_title[0].value}`}</div>
              )}
              {position.company && <div className={css.titleCompany}>{position.company[0].text}</div>}
            </div>
            <div className={css.row}>
              {<div className={classNames(css.dot, { [css.filledDot]: position.main })} />}
              {isOnlyDuration
                ? durationFromMonths(position.duration)
                : `${isStartDateValid ? formatDate(position.start_date) : ""}${
                    isEndDateValid ? ` - ${formatDate(position.end_date)}` : ""
                  }`}
            </div>
          </div>

          <>
            <div className={css.description}>
              {editMode ? (
                <EditPositionForm
                  adminPage={adminPage}
                  position={position}
                  profile={profile}
                  handleSuccess={() => {
                    setEditMode(false);
                    handleProfileUpdate({}); // just refetch profile
                  }}
                  handleRemove={(data) => {
                    handleRemove(data);
                    setEditMode(false);
                  }}
                />
              ) : (
                <div className={css.experienceGridWrapper}>
                  {position.summary && (
                    <div className={css.descriptionMarkdown}>
                      <Markdown
                        raw={position.summary}
                        crop={collapsedDescription ? 400 : 1e10}
                        displayToggle={false}
                        emojify={false}
                      />
                      {position.summary && position.summary.length > 100 && <CollapseButton />}
                    </div>
                  )}
                  {mainPositionCustomFields.map(
                    (cf) =>
                      position[cf] &&
                      Array.isArray(position[cf]) && (
                        <div key={cf} className={css.experienceGrid}>
                          <div>{cfkeyToValue[cf]}</div>
                          <div>
                            <CustomValuesList
                              type={position[cf]}
                              currentProfile={currentProfile}
                              currentAccount={currentAccount}
                              profileId={profile.id}
                              moreLimit={6}
                              showMoreButton
                              customValues={position[cf].map((value) => ({ ...value, className: "light" }))}
                            />
                          </div>
                        </div>
                      )
                  )}
                </div>
              )}
            </div>
          </>
        </div>
      </>
    );
  };

  const navigateToParent = (id) => {
    const ieElement = document.querySelector(`[data-scroll-anchor="internal-experience-${id}"]`);
    scrollTo(window, 0, ieElement ? ieElement.getBoundingClientRect().top + window.pageYOffset - 50 : 0);
  };

  return (
    <div className={css.experienceWrapper}>
      {mainJobRenderer(experience)}
      {isInternalExperienceVisible && (
        <>
          <div
            className={classNames(css.row, css.separator, {
              [css.separatorAddNew]: addNewInternalExperience
            })}
          >
            <div className={classNames(css.title, css.separatorArrow)}>
              <button
                className={classNames(css.arrowButtonCollapseExpand, css.noStyleButton, {
                  [css.rotatedIcon]: collapsedFullSection
                })}
                onClick={() => setCollapsedFullSection((prev) => !prev)}
              >
                {internalPositions.length > 0 && (
                  <DownArrowIcon height={36} width={36} className={css.arrowIcon} />
                )}
              </button>
            </div>
            <div className={classNames(css.description, css.separatorDescription)}>
              <div className={css.row}>
                <div className={classNames({ [css.becomeColumn]: addNewInternalExperience })}>
                  <div className={css.descriptionRow}>
                    {t("common.experience.internalExperience.title")}
                    {isMe && "rw" === internalExperiencePermissions && (
                      <button
                        className={classNames(css.addButton, css.noStyleButton)}
                        onClick={() => {
                          setCollapsedFullSection(false);
                          setAddNewInternalExperience((prev) => !prev);
                        }}
                      >
                        <RoundedAddIcon />
                      </button>
                    )}
                  </div>
                  {addNewInternalExperience && (
                    <div
                      style={{ height: "100%", width: "100%" }}
                      className={classNames(css.column, { [css.collapsedFullSection]: collapsedFullSection })}
                    >
                      <EditInternalExperienceForm
                        experience={newExperience}
                        profile={profile}
                        adminPage={false}
                        handleSuccess={() => {
                          fetchInternalExperience();
                          navigateToParent(experience.id);
                          setAddNewInternalExperience(false);
                        }}
                        handleRemove={() => {
                          navigateToParent(experience.id);
                          setAddNewInternalExperience(false);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={classNames(css.column, { [css.collapsedFullSection]: collapsedFullSection })}>
            {internalPositions.map((position, index) => (
              <React.Fragment key={`${position.id}+${index}`}>
                <InternalExperienceComponent
                  experience={position}
                  profile={profile}
                  fetchInternalExperience={fetchInternalExperience}
                  canEdit={canEdit}
                  customTypes={customTypes}
                  isCfPermitted={isCfPermitted}
                />
              </React.Fragment>
            ))}
          </div>
        </>
      )}
      <div className={css.sectionSeparator} />
    </div>
  );
};

ExperienceComponent.propTypes = {
  experience: PropTypes.object,
  profile: PropTypes.object,
  adminPage: PropTypes.bool,
  currentProfile: PropTypes.object,
  customTypes: PropTypes.arrayOf(PropTypes.object),
  fetchInternalExperience: PropTypes.func,
  canEdit: PropTypes.bool,
  handleProfileUpdate: PropTypes.func,
  isMe: PropTypes.bool
};

export default ExperienceComponent;
