/* eslint-disable react/prop-types */
import moment from "moment";
import { getWeekdaysShort } from "PFCore/helpers/date";

import { CalendarSelectProps, MonthSelector } from "../../month_selector";
import css from "./calendar_header.module.scss";
import { ChangeMonthButton } from "./change_month_button";

type CalendarHeaderProps = CalendarSelectProps & {
  showArrows: boolean;
  label: string;
};

const CalendarHeader = ({
  showArrows,
  label,
  minDate,
  maxDate,
  displayMonth,
  setDisplayMonth
}: CalendarHeaderProps): JSX.Element => {
  const selectProps = { minDate, maxDate, displayMonth, setDisplayMonth };
  const buttonProps = { displayMonth, setDisplayMonth };
  const weekDays = getWeekdaysShort();

  return (
    <div className={css.root} data-qa-id="CalendarHeader">
      <div className={css.title}>
        {label && <strong data-qa-id="CalendarHeader.label">{label}</strong>}

        <MonthSelector {...selectProps} />

        {showArrows && (
          <div className={css.changeMonthContainer}>
            <ChangeMonthButton {...buttonProps} dir={-1} dateLow={displayMonth} dateHigh={moment(minDate)} />
            <div className={css.separator} />
            <ChangeMonthButton {...buttonProps} dir={1} dateLow={maxDate} dateHigh={displayMonth} />
          </div>
        )}
      </div>

      <div className={css.daynames}>
        {weekDays.map((name) => (
          <span className={css.dayname} key={name}>
            {name}
          </span>
        ))}
      </div>
    </div>
  );
};

export default CalendarHeader;
