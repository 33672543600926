import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { fetchActivity } from "PFApp/booking/services/api";
import { Id } from "PFTypes/id";

import { Activity } from "../../../types";
import activityKeys from "./query_keys";

export const useBookingActivity = (
  activityId?: Id,
  options: Omit<UseQueryOptions<Activity>, "queryKey" | "queryFn" | "initialData"> & {
    initialData?: () => undefined;
  } = {}
): UseQueryResult<Activity> =>
  useQuery<Activity>(
    activityKeys.bookingActivity(activityId),
    () => fetchActivity(activityId) as unknown as Promise<Activity>,
    {
      retry: false,
      enabled: !!activityId,
      ...options
    }
  );
