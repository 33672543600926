import { ActionIcon } from "PFComponents/action_icon";
import { Button } from "PFComponents/button";
import { getProfileName } from "PFCore/helpers/profile";
import { useBooking } from "PFCore/hooks/queries";
import { useBookingTemplate } from "PFCore/hooks/queries/bookings/booking_templates/use_booking_template";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Booking, Profile } from "PFTypes";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useBookingsOverviewContext } from "../../../../../parts/overview/context/bookings_overview_context";
import { getDeleteMode } from "../../../../../parts/overview/detail_view/booking_detail/buttons/buttons";
import { useBookingFormContext } from "../../../../booking_form";
import { BookingFormDataType, BookingFormMode } from "../../../../booking_form/use_booking_form";
import CategoryBadge from "../../../../category_badge";
import BookingDeleteButton from "../../../../delete_booking_modal/booking_delete_button";
import {
  OverbookingPotentialBooking,
  useDetailsPanelApiContext
} from "../../../details_panel_context/details_panel_context";
import css from "./booking_item.module.scss";

type BookingItemProps = {
  bookingId?: number;
  profile: Profile;
  onDeleted?: (entity: Booking) => Promise<void>;
  onEdited?: () => void;
  potentialBooking?: OverbookingPotentialBooking;
};

export const BookingItem = ({
  bookingId,
  onDeleted,
  onEdited,
  profile,
  potentialBooking
}: BookingItemProps) => {
  const { modal } = useBookingFormContext();

  const { closeDetailsPanel, closeAllDetailsPanels, openDetailsPanel } = useDetailsPanelApiContext();

  const {
    calendarData: { data }
  } = useBookingsOverviewContext();
  const { utc } = useDateFormatter();
  const { t } = useTranslation("translation");

  const { data: booking } = useBooking(bookingId, { enabled: !!bookingId });

  const {
    activity_id: activityId,
    activity_siblings_count: activitySiblingsCount,
    booking_category_id: bookingCategoryId,
    booking_group_id: bookingGroupId,
    description,
    start_date: startDate,
    end_date: endDate,
    load,
    title,
    booking_template_id: bookingTemplateId,
    readonly,
    duration,
    requirement_type,
    requirement_value,
    phase_source_id,
    overrides_calendar,
    overrides_non_working_days
  } = booking || {};
  const { title: potentialBookingTitle, booking_category_id: potentialBookingCategoryId } =
    potentialBooking || {};

  const { data: bookingTemplate, isLoading: bookingTemplateLoading } = useBookingTemplate(bookingTemplateId, {
    enabled: Number.isInteger(bookingTemplateId)
  });

  const isSingleDayBookingTemplate = useMemo(
    () => bookingTemplate && utc(bookingTemplate.end_date).diff(bookingTemplate.start_date, "days") === 0,
    [bookingTemplate, utc]
  );

  const deleteMode = getDeleteMode(activityId || null, activitySiblingsCount || 0);

  const handleEdit = () => {
    if (!!bookingId && bookingId === modal.state.props?.bookingId) {
      closeDetailsPanel();
    } else if (booking) {
      const initialBookingModalData: BookingFormDataType = {
        profileId: profile.id,
        title,
        description,
        bookings: [
          {
            startDate: startDate!,
            endDate: endDate!,
            load,
            duration,
            requirement_type,
            requirement_value,
            phase_source_id
          }
        ],
        categoryId: bookingCategoryId,
        activityId,
        bookingTemplate,
        bookingGroupId,
        searchId: data.meta.search_id,
        total: data.meta.total,
        entries: data.entries,
        overridesCalendar: overrides_calendar,
        overridesNonWorkingDays: overrides_non_working_days
      };
      modal.open(initialBookingModalData, { bookingId, onSuccess: onEdited, mode: BookingFormMode.Edit });
    }
  };

  const handleDelete = (entity: Booking) => {
    if (entity.id === modal.state.props?.bookingId) {
      modal.close();
      closeAllDetailsPanels();
    }

    return onDeleted ? onDeleted(entity) : Promise.resolve();
  };

  return (
    <div className={css.bookingItem}>
      <Button
        small
        kind="blank"
        onClick={() =>
          bookingId &&
          openDetailsPanel(
            {
              id: bookingId,
              type: "booking"
            },
            true
          )
        }
        disabled={!bookingId}
        text={title || potentialBookingTitle || t("booking")}
      />
      <div className={css.actionsContainer}>
        <CategoryBadge
          categoryId={bookingCategoryId ?? potentialBookingCategoryId}
          className={css.categoryBadge}
          titleClassName={css.categoryBadgeTitle}
        />
        <ActionIcon
          className={css.action}
          name="edit"
          size="sm"
          onClick={handleEdit}
          disabled={readonly || (Number.isInteger(bookingTemplateId) && bookingTemplateLoading) || !booking}
        />
        <BookingDeleteButton
          kind="icon"
          className={css.action}
          deleteMode={deleteMode}
          booking={booking}
          profileFullName={getProfileName(profile)}
          onDeleted={handleDelete}
          isSingleDayBookingTemplate={isSingleDayBookingTemplate}
          disabled={readonly || !booking}
        />
      </div>
    </div>
  );
};
