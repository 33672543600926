import { useIsMutating, useQuery } from "@tanstack/react-query";
import { useBookingFormContext } from "PFApp/booking/components/booking_form";
import { fetchBooking, fetchBookingExternal } from "PFApp/booking/services/api";
import { Booking } from "PFCore/types";
import { QueryOptions } from "PFTypes/request_options";
import { useEffect, useState } from "react";

import { bookingKeys } from "./query_keys";
import { getBookingDeleteMutationKey } from "./use_booking_delete";
import { useIsExternalUsage } from "./use_is_external_usage";

export const useBooking = (bookingId?: number, options: QueryOptions<Booking> = {}) => {
  const { profileId } = useBookingFormContext();
  const isExternalUsage = useIsExternalUsage();
  const internalUsageEnabled = !isExternalUsage && !!bookingId;
  const externalUsageEnabled = isExternalUsage && !!bookingId && !!profileId;

  const [disabled, setDisabled] = useState(false);

  const isDeleting = !!useIsMutating(getBookingDeleteMutationKey(bookingId));

  useEffect(() => {
    if (isDeleting) {
      setDisabled(true);
    }
  }, [isDeleting]);

  return useQuery<Booking>(
    bookingKeys.single(bookingId, profileId),
    async () => {
      if (isExternalUsage) {
        return await fetchBookingExternal(profileId, bookingId);
      } else {
        return await fetchBooking(bookingId);
      }
    },
    {
      retry: false,
      ...options,
      enabled: !disabled && (externalUsageEnabled || internalUsageEnabled) && (options.enabled ?? true)
    }
  );
};
