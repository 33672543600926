import classNames from "classnames";
import { findIndex } from "lodash";
import moment from "moment";
import { ActionIcon } from "PFComponents/action_icon";
import DropDown, { DropdownOption } from "PFComponents/dropdown/dropdown";
import { KEY_ENTER, KEY_ESC } from "PFComponents/text/keys";
import { ISO_TIME_FORMAT, TIME_FORMAT_12H } from "PFCore/helpers/date";
import useClickOutside from "PFCore/helpers/use_click_outside";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";

import css from "./time_picker.module.scss";

const isoTimeToMoment = (time: string) => moment.utc(time, ISO_TIME_FORMAT);

type TimePickerProps = {
  value?: string | null;
  onChange: (value: string) => void;
  onBlur?: (event: SyntheticEvent) => void;
  step?: number; // in minutes
  portalRef?: React.RefObject<HTMLElement>;
  min?: string | null;
  max?: string | null;
  className?: string;
  disabled?: boolean;
  name?: string;
  placeholder?: string;
};

export const TimePicker = ({
  value,
  onChange,
  step = 15,
  min,
  max,
  portalRef,
  className,
  disabled,
  name,
  placeholder,
  onBlur
}: TimePickerProps) => {
  const { utc, timeFormatString, formatISOTime } = useDateFormatter();

  const [popperReferenceElement, setPopperReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const valueFormatted = useMemo(
    () => (value ? isoTimeToMoment(value).format(timeFormatString) : undefined),
    [value, timeFormatString]
  );

  const [inputValue, setInputValue] = useState(valueFormatted);
  const [showDropdown, setShowDropdown] = useState(false);

  const { styles, attributes } = usePopper(popperReferenceElement, popperElement, {
    placement: "bottom-start"
  });

  useEffect(() => setInputValue(valueFormatted), [valueFormatted]);

  useClickOutside({ current: popperReferenceElement }, (e) => {
    if (portalRef?.current && portalRef.current.contains(e.target as Node)) {
      return;
    }
    setShowDropdown(false);
  });

  const width = timeFormatString === TIME_FORMAT_12H ? 110 : 84;

  const handleChange = (newValue) => {
    setShowDropdown(false);
    onChange(formatISOTime(moment.utc(newValue, timeFormatString)));
  };

  const handleIconClick = () => setShowDropdown((prev) => !prev);

  const dropdownOptions = useMemo<DropdownOption[]>(() => {
    const startTime = (min ? isoTimeToMoment(min) : utc().startOf("day")).subtract(step, "m");
    const endTime = (max ? isoTimeToMoment(max) : utc().endOf("day")).subtract(step, "m");
    const options = [] as DropdownOption[];
    while (startTime < endTime) {
      const timeOption = startTime.add(step, "m").format(timeFormatString);
      options.push({
        displayElement: timeOption,
        item: timeOption,
        id: timeOption
      });
    }
    return options;
  }, [step, timeFormatString, utc, min, max]);
  const selecteIndex = findIndex(dropdownOptions, ["item", inputValue]);

  // input handlers
  const handleValueOnInputChange = useCallback(() => {
    if (valueFormatted === inputValue) {
      return;
    }
    const momentInputValue = moment.utc(inputValue, timeFormatString);
    const isBelowMax = max ? momentInputValue < isoTimeToMoment(max) : true;
    const isAboveMin = min ? momentInputValue > isoTimeToMoment(min) : true;
    if (momentInputValue.isValid() && isAboveMin && isBelowMax) {
      onChange(formatISOTime(momentInputValue));
    } else {
      setInputValue(valueFormatted);
    }
  }, [formatISOTime, max, min, inputValue, onChange, valueFormatted, timeFormatString]);

  const handleInputChange = (event) => setInputValue(event.target.value);
  const handleInputBlur = useCallback(
    (event) => {
      handleValueOnInputChange();
      onBlur?.(event);
    },
    [handleValueOnInputChange, onBlur]
  );

  const handleInputKeyDown = useCallback(
    (event) => {
      if (event.keyCode === KEY_ENTER) {
        handleValueOnInputChange();
      } else if (event.keyCode === KEY_ESC) {
        setInputValue(valueFormatted); // reset to previous value
      }
    },
    [handleValueOnInputChange, valueFormatted]
  );

  const Dropdown = (
    <div ref={setPopperElement} style={{ ...styles.popper, zIndex: 20 }} {...attributes.popper}>
      <DropDown
        style={{ minWidth: width }}
        dropDownClassName={css.dropdownItem}
        options={dropdownOptions}
        handleChange={handleChange}
        handleClose={() => setShowDropdown(false)}
        selectedIndex={selecteIndex}
      />
    </div>
  );

  return (
    <div className={classNames(css.container, className)} style={{ width }} ref={setPopperReferenceElement}>
      <input
        value={inputValue}
        name={name}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        onKeyDown={handleInputKeyDown}
        disabled={disabled}
        placeholder={placeholder}
        aria-label="Time Picker"
      />
      <ActionIcon name="clock" size="sm" onClick={handleIconClick} disabled={disabled} />
      {showDropdown && (portalRef?.current ? ReactDOM.createPortal(Dropdown, portalRef.current) : Dropdown)}
    </div>
  );
};
