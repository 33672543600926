import { cloneDeep, compact, flatten } from "lodash";
import { ECOption } from "PFCore/helpers/echarts_wrapper";
import calendarPng from "PFImages/calendar.png";
import filterPng from "PFImages/filter.png";
import colors from "PFTheme/tokens/colors";
import fontSizes from "PFTheme/tokens/fontSizes";
import { MatchesDistribution } from "PFTypes/matches_distribution";

// In API we have prefiltered and available_and_prefiltered range which is a sub range of the prefiltered
// range, but in the chart library I have to display them as two separate ranges so it looks fine
const getRangesMinMax = (
  counters: MatchesDistribution["counters"],
  dontModify: boolean,
  roundDownGap: (num: number) => number
): MatchesDistribution["counters"] => {
  if (dontModify) {
    return counters;
  }
  const newCounters = cloneDeep(counters);
  if (roundDownGap(counters.prefiltered.min) === roundDownGap(counters.prefiltered_and_available.min)) {
    if (counters.prefiltered.max > counters.prefiltered_and_available.max) {
      newCounters.prefiltered.min = counters.prefiltered_and_available.max;
    } else {
      newCounters.prefiltered_and_available.min = counters.prefiltered.max;
    }
  }
  if (roundDownGap(counters.prefiltered.max) === roundDownGap(counters.prefiltered_and_available.max)) {
    if (counters.prefiltered.min < counters.prefiltered_and_available.min) {
      newCounters.prefiltered.max = counters.prefiltered_and_available.min;
    } else {
      newCounters.prefiltered_and_available.max = counters.prefiltered.min;
    }
  }

  return newCounters;
};

const transformData = (
  data: MatchesDistribution["data"],
  min: number,
  max: number,
  xGap: number,
  roundDownGap: (num: number) => number
) => {
  // interpolating percentages so that we have a possibility to highlight between the bars
  // [10, 15, 20] -> [7.5, 10, 12.5, 15, 17.5, 20, 22.5]
  const _percentages: Record<string, number> = Object.entries(data).reduce((acc, [key, value]) => {
    if (value !== 0 || (parseInt(key) > min && parseInt(key) < max)) {
      const newKey = roundDownGap(parseInt(key)).toString();
      acc[newKey] = acc[newKey] !== undefined ? acc[newKey] + value : value;
    }
    return acc;
  }, {});
  const percentages = Object.keys(_percentages);

  const xAxisData = flatten(
    percentages.map((category, index) => {
      const matchPercentage = parseInt(category);
      return index === percentages.length - 1
        ? [matchPercentage - xGap / 2, matchPercentage, matchPercentage + xGap / 2]
        : [matchPercentage - xGap / 2, matchPercentage];
    })
  );

  // category takes value of the same index as it has so we need to set 0 as value to interpolated percentages
  const seriesData = flatten(
    Object.values(_percentages).map((value, index) =>
      index === percentages.length - 1 ? [0, value, 0] : [0, value]
    )
  );

  return { xAxisData, seriesData };
};

export const matchesChartConfig = (
  isActivityFilterEnabled: boolean,
  distribiution?: MatchesDistribution
): ECOption | {} => {
  if (!distribiution) {
    return {};
  }

  const xGap = distribiution.counters.matched.max - distribiution.counters.matched.min > 55 ? 10 : 5;
  const roundDownGap = (num: number) => Math.floor(num / xGap) * xGap;
  const getHighlightValueUp = (num: number) => ((Math.ceil(num / (xGap / 2)) * xGap) / 2).toString();
  const getHighlightValueDown = (num: number) => ((Math.floor(num / (xGap / 2)) * xGap) / 2).toString();

  const { xAxisData, seriesData } = transformData(
    distribiution.data,
    distribiution.counters.matched.min,
    distribiution.counters.matched.max,
    xGap,
    roundDownGap
  );

  const isSameRanges =
    isActivityFilterEnabled &&
    getHighlightValueDown(distribiution.counters.prefiltered.max) ===
      getHighlightValueDown(distribiution.counters.prefiltered_and_available.max) &&
    getHighlightValueDown(distribiution.counters.prefiltered.min) ===
      getHighlightValueDown(distribiution.counters.prefiltered_and_available.min);

  const {
    prefiltered: { min: prefilteredMin, max: prefilteredMax, total: prefilteredTotal },
    prefiltered_and_available: { min: availableMin, max: availableMax, total: availableTotal }
  } = getRangesMinMax(distribiution.counters, !isActivityFilterEnabled || isSameRanges, roundDownGap);

  const needsLabelOffset = !isSameRanges && Math.abs(prefilteredMin - availableMin) < xGap;

  const config = {
    xAxis: {
      data: xAxisData,
      axisLine: { lineStyle: { color: colors.paletteBlue1 } },
      axisTick: { show: false },
      axisLabel: {
        // TODO: [PROF-2617] use fontFamilies token
        fontFamily: "Muli",
        color: colors.paletteBlue2,
        interval: 0,
        // dont display interpolated categories labels (2.5/7.5...)
        formatter: (value: string) => (parseFloat(value) % xGap === 0 ? value : "")
      }
    },
    grid: {
      top: 16,
      bottom: 0,
      left: 0,
      right: 0,
      borderColor: colors.paletteNeutral0,
      containLabel: true
    },
    yAxis: {
      minInterval: 5,
      axisLine: { show: true, lineStyle: { color: colors.paletteBlue1 } },
      axisLabel: {
        // TODO: [PROF-2617] use fontFamilies token
        fontFamily: "Muli",
        color: colors.paletteBlue2,
        verticalAlign: "top"
      }
    },
    series: [
      {
        name: "Profiles",
        type: "bar",
        data: seriesData,
        barWidth: "130%",
        itemStyle: {
          color: "#436FB6"
        },
        markArea: {
          data: compact([
            !isSameRanges && [
              {
                xAxis:
                  prefilteredMin === availableMax
                    ? getHighlightValueUp(prefilteredMin)
                    : getHighlightValueDown(prefilteredMin),
                itemStyle: {
                  color: colors.paletteNeutral0,
                  opacity: 0.6
                },
                name: "Prefiltered",
                label: {
                  position: [needsLabelOffset && prefilteredMin >= availableMin ? 16 : 0, -16],
                  formatter: `{icon|} ${prefilteredTotal}`,
                  color: colors.paletteBlue0,
                  fontSize: fontSizes.fontSizeLabel,
                  rich: {
                    icon: {
                      backgroundColor: {
                        image: filterPng
                      },
                      height: 12
                    },
                    count: {
                      fontFamily: "Muli"
                    }
                  }
                },
                emphasis: { disabled: true }
              },
              {
                xAxis:
                  prefilteredMax === availableMin
                    ? getHighlightValueDown(prefilteredMax)
                    : getHighlightValueDown(prefilteredMax)
              }
            ],
            isActivityFilterEnabled &&
              availableMax &&
              availableMin && [
                {
                  xAxis:
                    availableMin === prefilteredMax
                      ? getHighlightValueUp(availableMin)
                      : getHighlightValueDown(availableMin),
                  itemStyle: {
                    color: colors.paletteNeutral3,
                    opacity: 0.6
                  },
                  name: "Prefiltered and available",
                  label: {
                    position: [needsLabelOffset && availableMin > prefilteredMin ? 16 : 0, -16],
                    formatter: `{icon|} ${availableTotal}`,
                    color: colors.paletteBlue0,
                    fontSize: fontSizes.fontSizeLabel,
                    rich: {
                      icon: {
                        backgroundColor: {
                          image: calendarPng
                        },
                        height: 12
                      },
                      count: {
                        fontFamily: "Muli"
                      }
                    }
                  },
                  emphasis: { disabled: true }
                },
                {
                  xAxis:
                    availableMax === prefilteredMin
                      ? getHighlightValueDown(availableMax)
                      : getHighlightValueDown(availableMax)
                }
              ]
          ])
        }
      }
    ]
  };

  return config;
};
