import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";

import { getPermittedTypes, loadForSections, PermissionObject } from "./custom_type";

export const useCustomTypes = () => {
  const { data: currentProfile } = useCurrentProfile();
  const { custom_types: customTypes } = currentProfile;

  return {
    customTypes,
    loadForSections: (sections: string | string[]) => loadForSections(sections, customTypes),
    getPermittedTypes: (permitionsObject: PermissionObject) =>
      getPermittedTypes(customTypes, permitionsObject)
  };
};
