import classNames from "classnames";
import moment from "moment";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

import css from "./header.less";

const Header = ({ startDate, dayWidth, calculatedMonthsLimit }) => {
  // start date is supposed to be Monday
  // we show couple days prior give some visual space there
  const weeksRef = useRef(null);

  const { formatShortMonth } = useDateFormatter();

  const [weeksToShow, setWeeksToShow] = useState(5);
  const [monthsToShow, setMonthsToShow] = useState([]);

  useEffect(() => {
    if (weeksRef.current) {
      const renderedWeeks = Math.floor(calculatedMonthsLimit * (31 / 7));
      setWeeksToShow(renderedWeeks + 2); // +2 because some  padding
    }
  }, [weeksToShow, dayWidth, startDate]);

  useEffect(() => {
    const startDateMoment = moment.utc(startDate);
    const list = [];

    const renderedMonths = calculatedMonthsLimit + 2; // +2 because you can have a huge padding both ends

    for (let i = 0; i < renderedMonths; i++) {
      const date = moment.utc(startDate).add(i, "months").startOf("month"); // if months is too big js increments year as you'd expect
      const daysOffset = Math.round((date.unix() - startDateMoment.unix()) / (24 * 3600));
      list.push({
        copy: `${formatShortMonth(date)} ${date.year()}`,
        offset: daysOffset * dayWidth
      });
    }

    setMonthsToShow(list);
  }, [dayWidth, startDate]);

  const weekStyle = { width: dayWidth * 7 };
  const isCompressed = dayWidth < 10;

  return (
    <div className={css.root}>
      <div className={css.months}>
        {monthsToShow.map(({ copy, offset }) => (
          <div className={css.month} style={{ transform: `translate(${offset}px, 0)` }} key={copy}>
            {copy}
          </div>
        ))}
      </div>
      <div className={classNames(css.weeks, { [css.compresWeeks]: isCompressed })} ref={weeksRef}>
        {Array.apply(null, { length: weeksToShow + 1 }).map((_, i) => {
          const date = moment.utc(startDate).add(i, "weeks");
          const dayOfTheMonth = date.date();
          const month = formatShortMonth(date);

          return (
            <div className={css.week} key={`${dayOfTheMonth} ${month} ${date.year()}`} style={weekStyle}>
              <b className={css.weekDay}>{dayOfTheMonth}</b>
              <span className={css.weekMonth}>{month}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

Header.propTypes = {
  startDate: PropTypes.string,
  dayWidth: PropTypes.number,
  calculatedMonthsLimit: PropTypes.number
};

export default Header;
