import { decamelizeKeys } from "humps";
import api from "PFCore/api";
import { Match, PaginatedCollection } from "PFTypes";

export const fetchMatches = (
  activityId,
  payload: { page?: number; per_page?: number; refresh?: boolean } = { page: 1, per_page: 10 }
): Promise<PaginatedCollection<Match[]>> =>
  api({
    url: "matches/index",
    method: "POST",
    data: { activityId: activityId, ...payload }
  }).then((response) => {
    const { perPage, totalPages } = response.meta;
    const decamelizeResponse = decamelizeKeys(response);
    return {
      ...decamelizeResponse,
      meta: { ...decamelizeResponse.meta, perPage, totalPages }
    } as PaginatedCollection<Match[]>;
  });
