import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createCertificate } from "PFApp/profiles/services/api";
import { CertificatePayload } from "PFTypes/certificate";

import { certificateKeys } from "./query_keys";

export const useCertificateCreate = ({ onSuccess, ...queryParams }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: CertificatePayload): any => createCertificate(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: certificateKeys.all() });
      onSuccess?.();
    },
    ...queryParams
  });
};
