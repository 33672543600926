import colors from "PFTheme/tokens/colors";
import { useTranslation } from "react-i18next";

import { BarChart } from "./bar_chart";

type HoursChartProps = { hoursTotal: number; coverage: number };

export const HoursChart = ({ hoursTotal, coverage }: HoursChartProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.economics.scenario.charts.hours" });
  return (
    <BarChart
      coverage={coverage}
      title={t("title")}
      hideLegend
      series={[
        {
          name: "required",
          type: "bar",
          data: [{ value: hoursTotal }],
          itemStyle: { color: colors.paletteEcharts0 },
          barMaxWidth: 5
        },
        {
          name: "filled",
          type: "bar",
          data: [{ value: hoursTotal * (coverage / 100) }],
          itemStyle: { color: colors.paletteEcharts1 },
          barMaxWidth: 5,
          barGap: "0%"
        }
      ]}
    />
  );
};
