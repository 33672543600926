import { Typography } from "PFComponents/typography";

import css from "./history_entry_row.scss";

type HistoryEntryBadgeProps = {
  badge: string;
};

export const HistoryEntryBadge = ({ badge }: HistoryEntryBadgeProps) => (
  <Typography className={css.badge} noMargin variant={"bodyRegular"}>
    {badge}
  </Typography>
);
