import classNames from "classnames";
import ActivityActions from "PFApp/components/activity_actions/activity_actions";
import HeaderTimeAgo from "PFApp/components/card_tx/header/header_time_ago";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import ActivityTypeBadge from "./activity_type_badge";
import css from "./header.less";

const Header = ({ type, task, isFeed, fromSearch, onShare, handleCloseActivitySuccess }) => {
  const { t } = useTranslation("core");

  return (
    <div className={css.wrapper}>
      <div className={classNames(css.section, css.sectionBadge)}>
        <ActivityTypeBadge type={type} />
      </div>
      <div className={css.section}>
        <HeaderTimeAgo time={task.created_at} />
        <ActivityActions
          task={task}
          buttonSize="xs"
          isFeed={isFeed}
          fromSearch={fromSearch}
          handleCloseActivitySuccess={handleCloseActivitySuccess}
          additionalOptions={
            onShare && [
              {
                id: "share",
                displayElement: t("activityActions.share"),
                item: () => onShare(task.id)
              }
            ]
          }
        />
      </div>
    </div>
  );
};

Header.propTypes = {
  type: PropTypes.shape({ key: PropTypes.string, name: PropTypes.string }),
  task: PropTypes.shape({ id: PropTypes.number, created_at: PropTypes.string }),
  isFeed: PropTypes.bool,
  fromSearch: PropTypes.bool,
  onShare: PropTypes.func,
  handleCloseActivitySuccess: PropTypes.func
};

export default Header;
