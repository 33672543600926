import { fetchPaginatedCollection } from "PFCore/helpers/collection";
import { ApiRoute } from "PFCore/utilities/routes";

export const NOTIFICATIONS_ACTIONS = {
  NOTIFICATIONS_SET: "NOTIFICATIONS_SET",
  NOTIFICATIONS_SET_PAGE: "NOTIFICATIONS_SET_PAGE",
  NOTIFICATIONS_APPEND: "NOTIFICATIONS_APPEND",
  NOTIFICATION_SET_MARK_AS_READ: "NOTIFICATION_SET_MARK_AS_READ",
  NOTIFICATIONS_TOGGLE_SHOW_UNREAD_ONLY: "NOTIFICATIONS_TOGGLE_SHOW_UNREAD_ONLY",

  NOTIFICATIONS_SET_LOADING: "NOTIFICATIONS_SET_LOADING",
  NOTIFICATIONS_SET_LOADING_DONE: "NOTIFICATIONS_SET_LOADING_DONE"
};

export const NOTIFICATIONS_ASYNC_ACTIONS = {
  MARK_AS_READ: "MARK_AS_READ_NOTIFICATION",
  BULK_MARK_AS_READ: "BULK_MARK_AS_READ_NOTIFICAITONS",

  NOTIFICATIONS_FETCH_COLLECTION: "NOTIFICATIONS_FETCH_COLLECTION"
};

export const notificationsReducer = (state, { type, payload }) => {
  switch (type) {
    case NOTIFICATIONS_ACTIONS.NOTIFICATIONS_SET:
      return { ...state, notifications: { ...state.notifications, notifications: payload } };

    case NOTIFICATIONS_ACTIONS.NOTIFICATIONS_SET_LOADING:
      return { ...state, notifications: { ...state.notifications, isLoading: true } };

    case NOTIFICATIONS_ACTIONS.NOTIFICATIONS_SET_LOADING_DONE:
      return { ...state, notifications: { ...state.notifications, isLoading: false } };

    case NOTIFICATIONS_ACTIONS.NOTIFICATIONS_SET_PAGE:
      return { ...state, notifications: { ...state.notifications, page: payload } };

    case NOTIFICATIONS_ACTIONS.NOTIFICATIONS_TOGGLE_SHOW_UNREAD_ONLY:
      return {
        ...state,
        notifications: { ...state.notifications, showUnreadOnly: !state.notifications.showUnreadOnly }
      };

    case NOTIFICATIONS_ACTIONS.NOTIFICATIONS_APPEND: {
      const collection = state.notifications.notifications || { entries: [] };
      collection.entries = [...collection.entries, ...payload.entries];
      collection.meta = payload.meta;
      return { ...state, notifications: { ...state.notifications, notifications: collection } };
    }

    case NOTIFICATIONS_ACTIONS.NOTIFICATION_SET_MARK_AS_READ: {
      const collection = state.notifications.notifications;
      const { showUnreadOnly } = state.notifications;
      if (collection && collection.entries) {
        const index = collection.entries.findIndex(({ id }) => id === payload);
        if (index !== -1) {
          if (collection.entries[index].status !== "read") {
            collection.meta.total_unread--;
          }
          if (showUnreadOnly) {
            collection.entries.splice(index, 1);
          } else {
            collection.entries[index].status = "read";
          }
        }
      }
      return { ...state, notifications: { ...state.notifications } };
    }
  }
};

export const notificationsDispatch = (dispatch, action) => {
  switch (action.type) {
    case NOTIFICATIONS_ASYNC_ACTIONS.NOTIFICATIONS_FETCH_COLLECTION: {
      const page = action.payload.page || 1;
      const perPage = action.payload.perPage || 10;
      const data = {
        page,
        per_page: perPage,
        excluded_target_types: ["Chat::Conversation"]
      };

      if (action.payload.showUnreadOnly) {
        data.read = false;
      }

      dispatch({ type: NOTIFICATIONS_ACTIONS.NOTIFICATIONS_SET_LOADING });

      return fetchPaginatedCollection(ApiRoute("/api/notifications"), data).then((resp) => {
        dispatch({ type: NOTIFICATIONS_ACTIONS.NOTIFICATIONS_SET_LOADING_DONE });
        if (page === 1) {
          dispatch({ type: NOTIFICATIONS_ACTIONS.NOTIFICATIONS_SET, payload: resp });
        } else {
          dispatch({ type: NOTIFICATIONS_ACTIONS.NOTIFICATIONS_APPEND, payload: resp });
          dispatch({ type: NOTIFICATIONS_ACTIONS.NOTIFICATIONS_SET_PAGE, payload: page });
        }
      });
    }
    case NOTIFICATIONS_ASYNC_ACTIONS.MARK_AS_READ: {
      return $.ajax(ApiRoute(`/api/notifications/${action.payload}`), {
        method: "PUT",
        data: {
          status: "read"
        },
        api_version: 2
      });
    }
    case NOTIFICATIONS_ASYNC_ACTIONS.BULK_MARK_AS_READ: {
      return $.ajax(ApiRoute(`/api/notifications/mark_as_read`), {
        method: "PUT",
        data: action.payload,
        api_version: 2
      });
    }
  }
};
