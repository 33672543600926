import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { Booking, Profile } from "PFTypes";

import BookingActivityContextProvider from "../../../providers/booking_activity_context_provider";
import BookingDetailContent from "./booking_detail_content";

type BookingDetailProps = {
  booking: Booking | undefined;
  loading: boolean;
  profile: Profile | undefined;
};

const BookingDetail = (props: BookingDetailProps): JSX.Element | null => {
  const { booking } = props;

  const { getCategory } = useBookingCategories();
  const category = booking ? getCategory(booking) : undefined;
  const bookingActivityId = booking?.activity?.id;
  return (
    <BookingActivityContextProvider id={bookingActivityId}>
      {!!category && <BookingDetailContent {...props} category={category} />}
    </BookingActivityContextProvider>
  );
};

export default BookingDetail;
