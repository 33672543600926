import { getAPIFilters, useMetaFilters } from "PFApp/use_filtered_collection";
import { Filters, Value } from "PFTypes";
import { QueryParams } from "PFTypes/query_params";
import { useCallback } from "react";

import { useActivityPageContext } from "../activity_page_context";

export const useActivityShortlistsFilters = () => {
  const { shortlistsMeta, updateShortlistsParams } = useActivityPageContext();

  const updateFilterValues = useCallback(
    (updatedFilters: Filters<Value>) => {
      updateShortlistsParams({ filters: getAPIFilters(updatedFilters), page: 1 });
    },
    [updateShortlistsParams]
  );

  const metaFilters = useMetaFilters(shortlistsMeta, updateFilterValues);

  const handleOrderChange = (order: QueryParams["order"]) => {
    updateShortlistsParams({ order });
  };

  return {
    handleOrderChange,
    ...metaFilters
  };
};
