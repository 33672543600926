import classNames from "classnames";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Phase } from "PFTypes/phase";
import { useTranslation } from "react-i18next";

import { isBookingWithTime } from "../../../../helpers";
import css from "../../detail_view.module.scss";

type BookingDetailDateProps = {
  startDate: string | undefined;
  endDate: string | undefined;
  phase?: Phase;
};

export const BookingDetailDate = ({ startDate, endDate, phase }: BookingDetailDateProps): JSX.Element => {
  const { t } = useTranslation("translation");
  const { formatDate, formatDateTime, utc } = useDateFormatter();
  const isWithTime = isBookingWithTime(utc(startDate), utc(endDate));
  return (
    <div className={css.alignCenter} style={{ flexWrap: "wrap" }}>
      {phase && <span className={classNames(css.strong, css.noWrap)}>{phase.name}:</span>}
      <span className={classNames(css.strong, css.noWrap)}>
        {isWithTime ? formatDateTime(utc(startDate)) : formatDate(utc(startDate))}
      </span>
      <span className={classNames(css.text, css.noWrap)}>{t("to")}</span>
      <span className={classNames(css.strong, css.noWrap)}>
        {isWithTime ? formatDateTime(utc(endDate)) : formatDate(utc(endDate))}
      </span>
    </div>
  );
};
