import classNames from "classnames";
import { ActionIcon } from "PFComponents/action_icon";
import scrollTo from "PFCore/helpers/scroll_to";
import RoundedAddIcon from "PFIcons/rounded_add_bis.svg";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import EditPositionForm from "../../common/experience/edit_position_form";
import ExperienceComponent from "./experience_component";
import PageSection from "./page_section";
import css from "./section_experience.modules.scss";

const ExperienceSection = ({
  profile,
  currentProfile,
  internalExperiences,
  fetchInternalExperience,
  customTypes,
  handleProfileUpdate,
  adminPage,
  className
}) => {
  const experiences = profile.positions.map((position) => ({
    ...position,
    internalPositions: internalExperiences.filter(
      (experience) => experience.metadata.position_id === position.id
    )
  }));

  const { t } = useTranslation("profiles");
  const { additionalSection } = useParams();

  const [addNewExperience, setAddNewExperience] = useState(false);
  const [canEdit, setCanEdit] = useState(false);

  const isMe = profile?.id && profile?.id === currentProfile?.id;

  useEffect(() => {
    const ieElement = document.querySelector(
      `[data-scroll-anchor="internal-experience-${additionalSection}"]`
    );
    scrollTo(window, 0, ieElement ? ieElement.getBoundingClientRect().top + window.pageYOffset - 50 : 0);
  }, [additionalSection]);

  return (
    <PageSection
      className={classNames(css.wrapper, className)}
      title={t("show.parts.experience")}
      titleAction={
        isMe && (
          <button
            className={classNames(css.addButton, css.noStyleButton, css.titleAction)}
            onClick={() => setAddNewExperience((prev) => !prev)}
          >
            <RoundedAddIcon />
          </button>
        )
      }
      action={
        isMe && (
          <ActionIcon
            onClick={() => setCanEdit((prev) => !prev)}
            name={canEdit ? "cross" : "edit"}
            size="sm"
          />
        )
      }
    >
      <div className={css.content}>
        {addNewExperience && (
          <>
            <EditPositionForm
              adminPage={adminPage}
              position={{ current: true }}
              profile={profile}
              handleSuccess={() => {
                setAddNewExperience(false);
                handleProfileUpdate({}); // just refetch profile
              }}
              handleRemove={() => setAddNewExperience(false)}
            />
            <div className={css.newExperienceSeparator}></div>
          </>
        )}
        {experiences.map((experience, index) => (
          <React.Fragment key={`${experience.id}+${index}`}>
            <ExperienceComponent
              adminPage={adminPage}
              experience={experience}
              customTypes={customTypes}
              profile={profile}
              currentProfile={currentProfile}
              fetchInternalExperience={fetchInternalExperience}
              canEdit={canEdit}
              handleProfileUpdate={handleProfileUpdate}
              isMe={isMe}
            />
          </React.Fragment>
        ))}
      </div>
    </PageSection>
  );
};

ExperienceSection.propTypes = {
  className: PropTypes.string,
  profile: PropTypes.object,
  currentProfile: PropTypes.object,
  customTypes: PropTypes.arrayOf(PropTypes.object),
  internalExperiences: PropTypes.object,
  fetchInternalExperience: PropTypes.func,
  handleProfileUpdate: PropTypes.func,
  adminPage: PropTypes.bool
};

export default ExperienceSection;
