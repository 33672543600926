import PropTypes from "prop-types";

export const scoresShapePropType = PropTypes.shape({
  direct: PropTypes.number,
  indirect: PropTypes.number,
  related: PropTypes.number,
  normalized_score: PropTypes.number,
  availability_score: PropTypes.number,
  score: PropTypes.number
});

export const customValuesShapePropType = PropTypes.shape({
  direct: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      global_id: PropTypes.string,
      text: PropTypes.string
    })
  ),
  related: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      global_id: PropTypes.string,
      text: PropTypes.string
    })
  ),
  indirect: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      global_id: PropTypes.string,
      text: PropTypes.string
    })
  )
});
