import { useIsMutating, useMutation, useQueryClient } from "@tanstack/react-query";
import { updateBooking } from "PFApp/booking/services/api";
import { MutationOptions } from "PFTypes/request_options";

import { useProfileInvalidate } from "../profile";
import { bookingMutationsKeys } from "./mutation_keys";
import { Booking, bookingKeys } from "./query_keys";

export const useBookingUpdate = (id?: number, options?: MutationOptions<Booking>) => {
  const cache = useQueryClient();
  const { invalidateProfileWithBooking } = useProfileInvalidate();

  const bookingCacheKey = bookingKeys.single(id || -1);
  const mutationKey = id ? bookingMutationsKeys.update(id) : ["disabled-booking-update-mutation"];

  const updateMutation = useMutation({
    mutationKey,
    mutationFn: async (bookingData: Partial<Booking>) => {
      await cache.cancelQueries(bookingCacheKey);
      return updateBooking(id, bookingData);
    },
    onSuccess: () =>
      Promise.all([cache.invalidateQueries(bookingCacheKey), id && invalidateProfileWithBooking(id)]),
    ...options
  });

  const isUpdating = useIsMutating({
    mutationKey
  });

  return {
    mutation: updateMutation,
    update: updateMutation.mutateAsync,
    isUpdating
  };
};
