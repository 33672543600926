import classNames from "classnames";
import SkillDetailsTooltipContent from "PFComponents/badges_list/skill_details_tooltip_content";
import SkillRatingIcon from "PFComponents/icons/skill_rating_icon";
import Tooltip from "PFComponents/tooltip/tooltip";
import { requiresApproval } from "PFCore/helpers/custom_value";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import ChampionsIcon from "PFIcons/champions.svg";
import CheckIcon from "PFIcons/check_3.svg";
import CheckCredlyIcon from "PFIcons/check_credly.svg";
import DumbbellIcon from "PFIcons/dumbbell.svg";
import PropTypes from "prop-types";

import css from "./skills_list.modules.scss";

const SkillsList = ({ collection, profileId, renderProficiency }) => {
  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();
  const isValidatedIconVisible = currentAccount.profile_view_customization.validated_skill_text;

  return collection.map((skill) => (
    <div className={css.singleSkill} key={skill.id}>
      {renderProficiency && <SkillRatingIcon value={skill.experience / 5} className={css.profiency} />}

      <Tooltip
        theme="pf-skill"
        content={
          <SkillDetailsTooltipContent
            badgeProps={{
              id: skill.id,
              text: skill.text,
              admin_approved: skill.admin_approved,
              global_id: skill.global_id
            }}
            profileId={profileId}
            currentProfile={currentProfile}
            style={{ fontSize: "15px", border: "unset" }}
            isEditMode={false}
          />
        }
        placement="right"
        lazy
        interactive
      >
        <div
          className={classNames(css.text, {
            [css.notApproved]: requiresApproval(
              currentProfile.custom_types.find(({ name }) => name === "skills"),
              skill
            )
          })}
        >
          {skill.text}
        </div>
      </Tooltip>
      {skill.top && (
        <div className={classNames(css.icon, css.topSkill)}>
          <ChampionsIcon height={24} width={24} />
        </div>
      )}
      {skill.developmental && (
        <div className={classNames(css.icon, css.developmentalSkill)}>
          <DumbbellIcon height={24} width={24} />
        </div>
      )}
      {isValidatedIconVisible &&
        skill.assessed &&
        (skill.assessment_source === "credly" ? (
          <div className={classNames(css.icon, css.assessedSkill)}>
            <CheckCredlyIcon height={20} width={20} />
          </div>
        ) : (
          <div className={classNames(css.icon, css.assessedSkill)}>
            <CheckIcon height={24} width={24} />
          </div>
        ))}
      {isValidatedIconVisible && skill.em_verified && (
        <div className={classNames(css.icon, css.feedbackVerifiedSkill)}>
          <CheckIcon height={24} width={24} />
        </div>
      )}
    </div>
  ));
};

SkillsList.propTypes = {
  collection: PropTypes.array,
  profileId: PropTypes.oneOfType(PropTypes.number, PropTypes.string),
  renderProficiency: PropTypes.bool
};

SkillsList.defaultProps = {
  renderProficiency: false
};

export default SkillsList;
