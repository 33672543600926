import classNames from "classnames";
import BookingPill from "PFApp/components/pills/booking_pill";
import { BookingCategory } from "PFTypes";
import { useTranslation } from "react-i18next";

import css from "../../detail_view.module.scss";

type BookingDetailCategoryProps = {
  category: BookingCategory;
};

export const BookingDetailCategory = ({ category }: BookingDetailCategoryProps): JSX.Element => {
  const { t } = useTranslation("translation");
  return (
    <div className={css.item}>
      <div className={classNames(css.alignCenter, css.bookingCategory)}>
        <div>
          <div className={css.label}>{t("bookingCategory")}</div>
          <div className={css.itemText}>{category.display_as}</div>
        </div>
        <BookingPill category={category} className={css.bookingCategoryPill} />
      </div>
    </div>
  );
};
