import Tooltip from "PFComponents/tooltip/tooltip";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./skill_rating_icon.module.scss";

export const useExperienceToStringMap = () => {
  const { t } = useTranslation("core", { keyPrefix: "skillRatings" });
  return useMemo(
    () => ({
      1: t("basic"),
      3: t("intermediate"),
      5: t("advanced")
    }),
    [t]
  );
};

const getLevelName = (value) => {
  let levelName = "unrated";

  if (value > 0.66) {
    levelName = 3;
  } else if (value > 0.33) {
    levelName = 2;
  } else if (value > 0) {
    levelName = 1;
  } else if (value === 0) {
    levelName = 0;
  }

  return levelName;
};

const SkillRatingIcon = ({ value, size, className, isEditMode, updateExperience, color, strokeWidth }) => {
  const experienceToStringMap = useExperienceToStringMap();
  const levelName = useMemo(() => getLevelName(value), [value]);

  const [levelToShow, setLevelToShow] = useState(levelName);
  useEffect(() => {
    setLevelToShow(levelName);
  }, [levelName]);

  const onMouseOut = isEditMode ? () => setLevelToShow(levelName) : null;

  const onMouseOver = isEditMode
    ? (event) => {
        event.stopPropagation();
        setLevelToShow(event.target.getAttribute("data-level"));
      }
    : null;

  const selectedIcon = (rating) => (
    <>
      <Tooltip theme="pf-dark-blue" disabled={!isEditMode} content={experienceToStringMap[1]}>
        <circle
          cx="9"
          cy="10"
          r="8"
          stroke={color}
          strokeWidth={strokeWidth}
          data-level={1}
          fill={rating > 0 ? color : "transparent"}
          onClick={() => isEditMode && updateExperience(1)}
        />
      </Tooltip>
      <line data-level={1} x1="17" y1="10" x2="22" y2="10" stroke={color} strokeWidth={strokeWidth} />
      <Tooltip theme="pf-dark-blue" disabled={!isEditMode} content={experienceToStringMap[3]}>
        <circle
          cx="30"
          cy="10"
          r="8"
          stroke={color}
          strokeWidth={strokeWidth}
          data-level={2}
          fill={rating > 1 ? color : "transparent"}
          onClick={() => isEditMode && updateExperience(3)}
        />
      </Tooltip>
      <line data-level={2} x1="38" y1="10" x2="43" y2="10" stroke={color} strokeWidth={strokeWidth} />
      <Tooltip theme="pf-dark-blue" disabled={!isEditMode} content={experienceToStringMap[5]}>
        <circle
          cx="51"
          cy="10"
          r="8"
          stroke={color}
          strokeWidth={strokeWidth}
          data-level={3}
          fill={rating > 2 ? color : "transparent"}
          onClick={() => isEditMode && updateExperience(5)}
        />
      </Tooltip>
    </>
  );

  return (
    <svg
      className={className}
      data-level={levelName}
      height={size}
      focusable="false"
      role="presentation"
      viewBox="0 0 60 19"
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
    >
      <g className={css.fill}>{selectedIcon(levelToShow)}</g>
    </svg>
  );
};

SkillRatingIcon.propTypes = {
  /** Normalized value (between 0 and 1). */
  value: PropTypes.number,
  /** 't-shirt' size. */
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Visual style */
  className: PropTypes.string,
  isEditMode: PropTypes.bool,
  updateExperience: PropTypes.func,
  color: PropTypes.string,
  strokeWidth: PropTypes.number
};

SkillRatingIcon.defaultProps = {
  value: null,
  size: "1em",
  color: "#0C1457",
  strokeWidth: 2
};

export default SkillRatingIcon;
