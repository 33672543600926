import { useQueries, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { fetchBookings } from "PFApp/profiles/services/api";
import { Booking, Collection } from "PFTypes";

import { CalendarRange } from "../../../types";
import { profileKeys } from "./query_keys";

type ProfilesBookingsPayload = {
  profileId: number;
  dateRange?: CalendarRange;
  activityId?: number;
  perPage?: number;
  options: Omit<UseQueryOptions<Collection<Booking[]>>, "queryKey" | "queryFn" | "initialData"> & {
    initialData?: () => undefined;
  };
};

export const useProfileBookings = ({
  profileId,
  dateRange,
  options = {},
  activityId,
  perPage
}: ProfilesBookingsPayload) =>
  useQuery<Collection<Booking[]>>(
    profileKeys.bookings(profileId, ["list", ...(dateRange ? [dateRange] : []), activityId, perPage]),
    async () => await fetchBookings({ profileId, dateRange, activityId, perPage }),
    {
      retry: false,
      ...options
    }
  );

type ProfileBookingsPayload = {
  profileIds: number[];
  dateRange?: CalendarRange;
  options?: Omit<UseQueryOptions<Collection<Booking[]>>, "queryKey" | "queryFn" | "initialData"> & {
    initialData?: () => undefined;
  };
};

export const useProfilesBookings = ({ profileIds, dateRange, options = {} }: ProfileBookingsPayload) => {
  const queries = useQueries({
    queries: profileIds.map((profileId) => ({
      queryKey: profileKeys.bookings(profileId, ["list", ...(dateRange ? [dateRange] : [])]),
      queryFn: async () => await fetchBookings({ profileId, dateRange }),
      retry: false,
      ...options
    }))
  });

  const bookingsByProfileId = queries.reduce(
    (result, { data }, index) => (!data ? result : { ...result, [profileIds[index]]: data }),
    {}
  );

  return {
    bookingsByProfileId,
    isFetching: queries.some(({ isFetching }) => isFetching)
  };
};
