import Radios from "PFComponents/radios/radios";
import PropTypes from "prop-types";

const RadioItem = ({ filter, handleChange, disabled }) => {
  // we send { _owner: "my" }, but we receive value: { id: "my", text: "Your" }
  const value = typeof filter.value === "string" ? filter.value : filter.value.id;

  return (
    <div>
      <span style={{ paddingBottom: 7, display: "block" }}>{filter.title}</span>
      <Radios
        inline
        items={filter.options}
        name={filter.title}
        value={value}
        handleChange={(value) => handleChange(value.id)}
        disabled={disabled}
      />
    </div>
  );
};

RadioItem.propTypes = {
  handleChange: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    title: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.any, text: PropTypes.string })).isRequired,
    value: PropTypes.oneOfType([
      PropTypes.oneOf(["my", "all"]),
      PropTypes.shape({ id: PropTypes.any, text: PropTypes.string })
    ]).isRequired
  }).isRequired,
  disabled: PropTypes.bool
};

export default RadioItem;
