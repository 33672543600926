import AddRoleButton from "PFApp/activities/show/parts/add_role_button";
import PropTypes from "prop-types";

import css from "./roles_actions.less";

const RolesActions = ({ canCreateRoles, roleKey, activity }) => (
  <div className={css.buttonWrapper}>
    {canCreateRoles && <AddRoleButton activityId={activity.id} style={{ marginLeft: 6 }} roleKey={roleKey} />}
  </div>
);

RolesActions.propTypes = {
  activity: PropTypes.obj,
  canCreateRoles: PropTypes.bool,
  roleKey: PropTypes.string
};

RolesActions.defaultProps = {
  canCreateRoles: true
};

export default RolesActions;
