import { isArray } from "lodash";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { CustomField, Metadata, ParsedSchemaProperty } from "PFTypes";
import { Dispatch, SetStateAction, useCallback } from "react";

import { FormFieldValue } from "../../activity_edit_form.types";

type UseFieldValueChange = {
  templateKey: string;
  setCustomFields: Dispatch<SetStateAction<CustomField[]>>;
  setMetadata: Dispatch<SetStateAction<Metadata>>;
};

export const useFieldValueChange = ({ templateKey, setCustomFields, setMetadata }: UseFieldValueChange) => {
  const { customTypes } = useCustomTypes();

  return useCallback(
    (values: FormFieldValue, property: ParsedSchemaProperty) => {
      const isCustomField = property.type === "custom_field";
      const propertyName = property.name;

      if (!isCustomField || !isArray(values)) {
        setMetadata((prevMetadata) => {
          const valueObject = {};
          valueObject[propertyName] = values;
          return { ...prevMetadata, ...valueObject };
        });
        return;
      }

      const weightedValues =
        templateKey === "audit_role" && property.match && propertyName !== "skills"
          ? values.map((value) => ({ ...value, weight: 10 }))
          : values;

      const customType = customTypes.filter((type) => type.name === propertyName)[0];
      if (!customType) {
        return;
      }

      setCustomFields((prevCustomFields) => {
        const newCustomFields = [...prevCustomFields];
        const customField = newCustomFields.find((customField) => customField.type.name === customType.name);

        if (customField) {
          customField.values = weightedValues as CustomField["values"];
          return [...newCustomFields];
        }

        return [
          ...prevCustomFields,
          {
            type: { id: customType.id, name: propertyName },
            values: weightedValues
          } as CustomField
        ];
      });
    },
    [customTypes, setCustomFields, setMetadata, templateKey]
  );
};
