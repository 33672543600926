import { MisalignmentItem, Overbooking } from "PFApp/booking/types";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { BookingCategory, FeatureFlag } from "PFTypes";
import { useEffect, useState } from "react";

import { fetchPotentialWarnings } from "../../services/api";
import { isSomeRequirementInvalid, mapRequirementToBookingData } from "./requirement_selector";
import { BookingData } from "./use_booking_form";
import { isBulkSelectOption, SelectedUser } from "./workforce_member_select/workforce_member_select";

export type PotentialWarnings = {
  overbookings: Overbooking[];
  misalignments: MisalignmentItem[];
  exeeds_calendar_hours: boolean;
  includes_non_working_days: boolean;
};

interface UsePotentialWarnings {
  bookingId?: number;
  bookingData: BookingData;
  activityId?: number;
  selectedUser: SelectedUser | null;
  selectedBookingCategory?: BookingCategory | null;
  disabled?: boolean;
}

const usePotentialWarnings = ({
  bookingId,
  bookingData,
  activityId,
  selectedUser,
  selectedBookingCategory,
  disabled
}: UsePotentialWarnings) => {
  const isBookingModuleEnabled = useIsFeatureEnabled()(FeatureFlag.BookingModule);
  const { getFormattedErrors } = useResponseErrors();
  const [potentialWarnings, setPotentialWarnings] = useState<Partial<PotentialWarnings>>({});
  const setRequests = useState<JQuery.jqXHR[]>([])[1];
  const [errors, setErrors] = useState<Record<string, string> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const cancelPreviousRequests = () => {
    setRequests((prevRequests) => {
      prevRequests.forEach((xhr) => xhr.abort());
      return [];
    });
  };

  useEffect(() => {
    if (!isBookingModuleEnabled || disabled) {
      return;
    }
    cancelPreviousRequests();
    const data = bookingData && Object.values(bookingData);
    if (
      !data ||
      !data.length ||
      (selectedUser && isBulkSelectOption(selectedUser)) ||
      !selectedUser ||
      isSomeRequirementInvalid(data) ||
      !selectedBookingCategory
    ) {
      setPotentialWarnings({});
      return;
    }

    setLoading(true);
    const potentialBookings = data.map(({ start, end, requirement }) => ({
      id: bookingId,
      // eslint-disable-next-line camelcase
      start_date: start,
      // eslint-disable-next-line camelcase
      end_date: end,
      ...mapRequirementToBookingData({ type: requirement.type, value: requirement.value! }),
      // eslint-disable-next-line camelcase
      booking_category_id: selectedBookingCategory?.id,
      // eslint-disable-next-line camelcase
      ...(activityId && { activity_id: activityId })
    }));
    const potentialWarningsRequest = fetchPotentialWarnings(selectedUser.id, potentialBookings);
    setRequests((prev) => [...prev, potentialWarningsRequest]);
    potentialWarningsRequest
      .then((response) => {
        setPotentialWarnings({
          ...response,
          misalignments: activityId ? response.misalignments[activityId]?.[selectedUser.id] || [] : []
        });
        setErrors(null);
        setLoading(false);
      })
      .catch((err: any) => {
        if (err.statusText === "abort") {
          return;
        }
        console.debug("Potential warnings request error", err);
        const responseErrors = getFormattedErrors(err);
        if (Object.keys(responseErrors).length > 0) {
          setErrors(responseErrors);
          return;
        }
        setLoading(false);
      });
  }, [bookingData, selectedUser, selectedBookingCategory, activityId]);

  return {
    ...potentialWarnings,
    errors,
    loading
  };
};

export default usePotentialWarnings;
