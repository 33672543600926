import PropTypes from "prop-types";
import { PureComponent } from "react";

import css from "./dots.less";

export default class Dots extends PureComponent {
  render() {
    return <div className={css.dots}>{this.renderOptions()}</div>;
  }

  renderOptions() {
    const { options } = this.props;
    const optionsCount = options.length;

    return options.map((option, optionKey) => this.renderOption(option, optionKey, optionsCount));
  }

  renderOption(option, optionKey, optionsCount) {
    const leftPosition = optionKey * (100 / (optionsCount - 1));

    return <div className={css.dot} style={{ left: `${leftPosition}%` }} key={optionKey} />;
  }
}

Dots.propTypes = {
  options: PropTypes.array.isRequired
};
