import classNames from "classnames";
import { isNumber } from "lodash";
import SimpleBadge from "PFComponents/badges_list/simple_badge";
import { Button } from "PFComponents/button";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./badges_list.module.scss";
import SkillDetailsTooltipContent from "./skill_details_tooltip_content";

const BadgesList = ({
  values,
  rootClassName,
  customButton,
  icon,
  moreLimit,
  style,
  hasValuesWithExpiryDate,
  currentProfile,
  profileId,
  handleMoreClicked,
  handleItemClick,
  showTooltip
}) => {
  const { t } = useTranslation("core");
  const { formatDate } = useDateFormatter();
  const [state, setState] = useState({ revealed: values.length < moreLimit });
  const { revealed } = state;

  const className = classNames(css.root, { [rootClassName]: rootClassName });

  const shownValues = revealed ? values : values.slice(0, moreLimit);

  // TODO: [ENG-2787] Unify this logic with ExpandableBadgesList
  const renderMoreButton = () => {
    const { revealed } = state;

    if (moreLimit && moreLimit < values.length) {
      const handleToggleClick = () => {
        setState({ revealed: !revealed });
        handleMoreClicked && handleMoreClicked(!revealed);
      };
      const qaId = revealed ? "BadgesList.show_less" : "BadgesList.show_more";

      return (
        <Button kind="text" onClick={handleToggleClick} qaId={qaId}>
          {revealed ? (
            t("viewLess")
          ) : (
            <>
              <span>{`+${values.length - moreLimit} `}</span> {t("viewMore")}
            </>
          )}
        </Button>
      );
    } else {
      return false;
    }
  };

  const button = !customButton && renderMoreButton();

  return (
    <div className={className} style={style} data-qa-id="BadgesList">
      {shownValues.map((badgeProps, index) => {
        const isSkill = isNumber(badgeProps.experience);

        return (
          <SimpleBadge
            key={`${badgeProps._key || badgeProps.text}:${index}`}
            icon={icon}
            {...badgeProps}
            text={
              hasValuesWithExpiryDate && badgeProps?.expiryDate
                ? `${badgeProps.text} (${t("time.inWords.expiresShort")}: ${formatDate(
                    badgeProps.expiryDate
                  )})`
                : badgeProps.text
            }
            showTooltip={showTooltip}
            tooltipContent={
              isSkill &&
              profileId &&
              currentProfile && (
                <SkillDetailsTooltipContent
                  badgeProps={badgeProps}
                  profileId={profileId}
                  currentProfile={currentProfile}
                  style={{ fontSize: "15px", border: "unset" }}
                />
              )
            }
            onClick={handleItemClick ? () => handleItemClick(badgeProps) : null}
          />
        );
      })}
      {button}
      {customButton}
    </div>
  );
};

BadgesList.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
      icon: PropTypes.any,
      showTooltip: PropTypes.bool,
      kind: PropTypes.string,
      className: PropTypes.string
    })
  ).isRequired,
  rootClassName: PropTypes.string,
  moreLimit: PropTypes.number,
  handleItemClick: PropTypes.func,
  handleMoreClicked: PropTypes.func,
  /** If provided we will display the "badges left" number
   * ie. "+123 more" instead of just "more" */
  customButton: PropTypes.node,
  icon: PropTypes.node,
  style: PropTypes.object,
  hasValuesWithExpiryDate: PropTypes.bool,
  currentProfile: PropTypes.object,
  profileId: PropTypes.number,
  showTooltip: PropTypes.bool
};

BadgesList.defaultProps = {
  moreLimit: 1000000,
  handleMoreClicked: null,
  customButton: null,
  hasValuesWithExpiryDate: false,
  showTooltip: false
};

export default BadgesList;
