// eslint-disable-next-line max-len
// THIS FILE IS AUTO-GENERATED BY FIGMAGIC. DO NOT MAKE EDITS IN THIS FILE! CHANGES WILL GET OVER-WRITTEN BY ANY FURTHER PROCESSING.

const colors = {
  paletteTrans2: "#0000001f",
  paletteTrans1: "#00000014",
  paletteTransLight0: "#ffffff0a",
  paletteTrans0: "#0000000a",
  paletteEcharts0: "#5470c6ff",
  paletteEcharts1: "#91cc75ff",
  paletteEcharts2: "#fac858ff",
  paletteEcharts3: "#ee6666ff",
  paletteEcharts4: "#73c0deff",
  paletteEcharts5: "#3ba272ff",
  paletteEcharts6: "#fc8452ff",
  paletteEcharts7: "#9a60b4ff",
  paletteReserved1: "#5e7a83ff",
  paletteReserved2: "#40798cff",
  paletteReserved3: "#70a9a1ff",
  paletteReserved0: "#1f363dff",
  paletteNeutral0: "#cfdaf7ff",
  paletteNeutral3: "#8f9ed1ff",
  paletteNeutral2: "#f8f9fdff",
  paletteNeutral1: "#e7eaf8ff",
  paletteBlue3: "#31a2ceff",
  paletteGreen2: "#c8eedeff",
  paletteGreen1: "#1f6648ff",
  paletteGreen0: "#248e61ff",
  paletteOrange0: "#ffcd38ff",
  paletteOrange3: "#ff6b00ff",
  paletteOrange2: "#ffe8adff",
  paletteOrange1: "#9b5a01ff",
  paletteWhite2: "#e0e0e0ff",
  paletteWhite3: "#d5d5d5ff",
  paletteWhite1: "#f7f7f8ff",
  paletteRed0: "#d42a36ff",
  paletteRed2: "#ffe2e2ff",
  paletteRed1: "#a30013ff",
  paletteWhite0: "#ffffffff",
  paletteBlue1: "#0c1457ff",
  paletteBlack0: "#000000ff",
  paletteBlack1: "#333333ff",
  paletteBlue2: "#5c6e9eff",
  paletteBlue0: "#0d2976ff",
  palettePrimaryAction: "#6ef29cff",
  palettePrimary0: "#2358f8ff",
  palettePrimary5: "#436fb6ff",
  palettePrimary3: "#c5f0feff",
  palettePrimary2: "#4b75ffff",
  palettePrimary1: "#1531b8ff"
} as const;

export default colors;
