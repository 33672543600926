import { useInfiniteQuery, UseInfiniteQueryOptions } from "@tanstack/react-query";
import {
  fetchBookingNotes,
  fetchBookingNotesExternal,
  IBookingNotesResponse
} from "PFApp/booking/services/booking_notes_api";
import { getNextPageParam } from "PFCore/helpers/get_next_page_param";

import { useIsExternalUsage } from "../use_is_external_usage";
import { noteKeys } from "./query_keys";

export const useBookingNotesInfinite = (
  profileId: number,
  bookingId: number,
  perPage?: number,
  options: Omit<UseInfiniteQueryOptions<IBookingNotesResponse>, "queryKey" | "queryFn" | "initialData"> & {
    initialData?: () => undefined;
  } = {}
) => {
  const isExternalUsage = useIsExternalUsage();
  return useInfiniteQuery({
    queryKey: noteKeys.ofBooking(bookingId, isExternalUsage, perPage),
    queryFn: ({ pageParam = 1 }) => {
      const params = {
        bookingId,
        page: pageParam,
        perPage
      };
      if (isExternalUsage) {
        return fetchBookingNotesExternal({
          profileId,
          ...params
        }) as unknown as Promise<IBookingNotesResponse>;
      } else {
        return fetchBookingNotes(params) as unknown as Promise<IBookingNotesResponse>;
      }
    },
    getNextPageParam,
    ...options
  });
};
