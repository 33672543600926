import classNames from "classnames";
import AddIcon from "PFIcons/add.svg";
import CollapseIcon from "PFIcons/collapse.svg";
import React, { Fragment, PropsWithChildren, ReactNode, useEffect, useState } from "react";

import css from "./filters_accordion.less";

interface FiltersAccordionProps {
  titles: ReactNode[];
  tips: ReactNode[];
  disabledList: boolean[];
  activeState?: Record<number, boolean | undefined>;
  onActiveIndexChange?: (index: number | null) => void;
  style: React.CSSProperties;
  allowMultipleActive?: boolean;
  allDisabled?: boolean;
}

export const FiltersAccordion = ({
  titles,
  tips,
  disabledList,
  allDisabled,
  activeState = {},
  onActiveIndexChange,
  children,
  style,
  allowMultipleActive = false
}: PropsWithChildren<FiltersAccordionProps>) => {
  const [innerActiveState, setInnerActiveState] = useState(activeState ?? {});

  const handleActiveIndexChange = (index, isActive) => {
    setInnerActiveState((prevState) => ({ ...(allowMultipleActive ? prevState : {}), [index]: isActive }));
    onActiveIndexChange?.(isActive ? index : null);
  };

  useEffect(() => {
    setInnerActiveState(activeState ?? {});
  }, [activeState]);

  return (
    <div className={css.root} style={style}>
      {React.Children.map(children, (child, index) => {
        const isActive = !!innerActiveState[index];
        const isDisabled = disabledList[index] || allDisabled;
        return (
          <Fragment key={index}>
            <div className={classNames(css.header, { [css.open]: isActive, [css.disabled]: isDisabled })}>
              <div style={{ flexGrow: 2 }}>{titles[index]}</div>
              <small className={css.tip}>{tips[index]}</small>
              {!isDisabled && (
                // eslint-disable-next-line max-len
                // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <a onClick={() => handleActiveIndexChange(index, !innerActiveState[index])}>
                  {isActive ? <CollapseIcon width={24} height={24} /> : <AddIcon width={24} height={24} />}
                </a>
              )}
            </div>
            {!isDisabled && isActive && <div className={css.item}>{child}</div>}
          </Fragment>
        );
      })}
    </div>
  );
};
