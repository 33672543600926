import { Alert } from "PFComponents/alert";
import { Button } from "PFComponents/button";
import { useBookingTemplate } from "PFCore/hooks/queries/bookings/booking_templates/use_booking_template";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Booking } from "PFTypes/booking";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useBookingFormContext } from "../../../../../components/booking_form";
import {
  BookingFormDataType,
  BookingFormMode
} from "../../../../../components/booking_form/use_booking_form";
import BookingDeleteButton from "../../../../../components/delete_booking_modal/booking_delete_button";
import { DeleteMode } from "../../../../../components/delete_booking_modal/delete_booking.types";
import { useDetailsPanelApiContext } from "../../../../../components/details_panel";
import { useBookingsOverviewContext } from "../../../context/bookings_overview_context";
import css from "../../detail_view.module.scss";

export const getDeleteMode = (activityId: number | null, activitySiblingsCount: number): DeleteMode => {
  if (!activityId) {
    return DeleteMode.NoActivityBooking;
  }
  if (activitySiblingsCount > 0) {
    return DeleteMode.MultipleBookings;
  }
  return DeleteMode.LastBooking;
};

interface ButtonsProps {
  id: number;
  profileFullName: string;
  booking: Booking;
  onDeleted?: (booking: Booking) => Promise<void>;
  onEdit: () => void;
  cloneEnabled: boolean;
}

const Buttons = ({
  id,
  booking,
  profileFullName,
  onDeleted,
  onEdit,
  cloneEnabled
}: ButtonsProps): JSX.Element => {
  const { modal } = useBookingFormContext();
  const {
    calendarData: { data }
  } = useBookingsOverviewContext();
  const { closeDetailsPanel } = useDetailsPanelApiContext();
  const { utc } = useDateFormatter();
  const { t } = useTranslation(["bookingModule", "translation"]);

  const {
    start_date: startDate,
    end_date: endDate,
    title,
    description,
    profile_id: profileId,
    booking_category_id: categoryId,
    phase_source_id,
    requirement_type,
    requirement_value,
    load,
    duration,
    activity,
    booking_template_id: bookingTemplateId,
    booking_group_id: bookingGroupId,
    // eslint-disable-next-line camelcase
    activity_id,
    // eslint-disable-next-line camelcase
    activity_siblings_count,
    source,
    readonly,
    overrides_calendar,
    overrides_non_working_days
  } = booking || {};
  const { id: activityId } = activity || {};

  const { data: bookingTemplate, isLoading: bookingTemplateLoading } = useBookingTemplate(bookingTemplateId, {
    enabled: Number.isInteger(bookingTemplateId)
  });

  const isSingleDayBookingTemplate = useMemo(
    () => bookingTemplate && utc(bookingTemplate.end_date).diff(bookingTemplate.start_date, "days") === 0,
    [bookingTemplate, utc]
  );

  const initialBookingModalData: BookingFormDataType = {
    profileId,
    title,
    description,
    bookings: [
      {
        startDate,
        endDate,
        load,
        duration,
        requirement_type: requirement_type,
        requirement_value: requirement_value,
        phase_source_id
      }
    ],
    categoryId,
    activityId,
    bookingTemplate,
    bookingGroupId,
    searchId: data.meta.search_id,
    total: data.meta.total,
    entries: data.entries,
    overridesCalendar: overrides_calendar,
    overridesNonWorkingDays: overrides_non_working_days
  };

  const handleEdit = () =>
    modal.open(initialBookingModalData, { bookingId: id, onSuccess: onEdit, mode: BookingFormMode.Edit });

  const handleClone = () =>
    modal.open(
      {
        ...initialBookingModalData,
        title: `[${t("bookingModule:bookings.clone.cloneAction").toUpperCase()}] ${title ?? ""}`
      },
      { bookingId: id, onSuccess: onEdit, mode: BookingFormMode.Clone }
    );

  const deleteMode = getDeleteMode(activity_id, activity_siblings_count);

  const handleDelete = (entity: Booking) => {
    closeDetailsPanel();
    return onDeleted ? onDeleted(entity) : Promise.resolve();
  };

  return (
    <div className={css.footer}>
      {source === "ui" ? (
        !readonly && (
          <div className={css.buttons}>
            <Button
              icon="edit"
              text={t("translation:edit")}
              onClick={handleEdit}
              kind="secondary"
              disabled={Number.isInteger(bookingTemplateId) && bookingTemplateLoading}
              className={css.wideButton}
            />
            {cloneEnabled && (
              <Button
                icon="duplicate"
                text={t("bookingModule:bookings.clone.cloneAction")}
                onClick={handleClone}
                kind="secondary"
                className={css.wideButton}
              />
            )}
            <BookingDeleteButton
              className={css.wideButton}
              deleteMode={deleteMode}
              booking={booking}
              profileFullName={profileFullName}
              onDeleted={handleDelete}
              isSingleDayBookingTemplate={isSingleDayBookingTemplate}
            />
          </div>
        )
      ) : (
        <Alert title={t("bookingModule:bookings.delete.externalSourceWarning")} hideIcon small />
      )}
    </div>
  );
};

export default Buttons;
