import classNames from "classnames";
import PropTypes from "prop-types";

import css from "./pill.module.scss";

/* eslint-disable jsx-a11y/click-events-have-key-events */
const BasicPill = ({ children, onClick, className, style, ...props }) => (
  <div
    aria-hidden="true"
    className={classNames(css.pill, className)}
    style={style}
    onClick={onClick}
    {...props}
  >
    {children}
  </div>
);

BasicPill.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node
};

export default BasicPill;
