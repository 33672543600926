import { debounce } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

import css from "./range_input.less";

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

const RangeInput = ({ handleChange, low, high, min, max, disabled }) => {
  const wrapRef = useRef();
  const lowThumbRef = useRef();
  const highThumbRef = useRef();
  const lowTipRef = useRef();
  const highTipRef = useRef();
  const currentTargetRef = useRef();

  const range = max - min;
  const toInt = (normalized) => Math.round(min + normalized * range);

  const [posLow, setPosLow] = useState(range !== 0 ? (low - min) / range : 0);
  const [posHigh, setPosHigh] = useState(range !== 0 ? (high - min) / range : 0);
  const [spread, setSpread] = useState(0);

  const updateSpread = () => {
    if (lowTipRef.current && highTipRef.current) {
      const { right } = lowTipRef.current.getBoundingClientRect();
      const { left } = highTipRef.current.getBoundingClientRect();
      setSpread(Math.max(0, right - left) / 2);
    }
  };

  useEffect(() => {
    updateSpread();
  }, [lowTipRef.current, highTipRef.current]);

  useEffect(() => {
    if (!wrapRef.current) {
      return;
    }

    const update = (event) => {
      if (disabled || !currentTargetRef.current) {
        return;
      }

      const rect = wrapRef.current.getBoundingClientRect();
      const touch = event.changedTouches && event.changedTouches[0];
      const clientX = touch ? touch.clientX : event.clientX;
      const pos = clamp((clientX - rect.left) / rect.width, 0, 1);

      updateSpread();

      if (currentTargetRef.current === "low") {
        if (toInt(pos) < toInt(posHigh)) {
          setPosLow(pos);
        }
      } else if (currentTargetRef.current === "high") {
        if (toInt(pos) > toInt(posLow)) {
          setPosHigh(pos);
        }
      }
    };

    const off = () => (currentTargetRef.current = null);

    const apply = debounce((event) => {
      if (disabled) {
        return;
      }

      if (currentTargetRef.current) {
        update(event);
        handleChange(toInt(posLow), toInt(posHigh));
      }
      off();
    }, 10);

    lowThumbRef.current.addEventListener("mousedown", () => (currentTargetRef.current = "low"));
    highThumbRef.current.addEventListener("mousedown", () => (currentTargetRef.current = "high"));
    lowThumbRef.current.addEventListener("touchstart", () => (currentTargetRef.current = "low"));
    highThumbRef.current.addEventListener("touchstart", () => (currentTargetRef.current = "high"));

    window.addEventListener("mousemove", update);
    window.addEventListener("touchmove", update);
    window.addEventListener("touchend", apply);
    window.addEventListener("mouseup", apply);
    window.addEventListener("blur", off);

    return () => {
      window.removeEventListener("mousemove", update);
      window.removeEventListener("touchmove", update);
      window.removeEventListener("touchend", apply);
      window.removeEventListener("mouseup", apply);
      window.removeEventListener("blur", off);
    };
  }, [wrapRef.current, posHigh, posLow]);

  const leftCalc = range !== 0 ? Math.round(posLow * range) / range : 0;
  const rightCalc = range !== 0 ? Math.round((1 - posHigh) * range) / range : 0;
  const left = `${clamp(leftCalc * 100, 0, 100)}%`;
  const right = `${clamp(rightCalc * 100, 0, 100)}%`;

  return (
    <div className={css.root} ref={wrapRef}>
      <div className={css.wrap}>
        <div className={css.line} />
        <div className={css.range} style={{ left, right }} />
        <div className={css.thumbs}>
          <div ref={lowThumbRef} className={css.thumb} style={{ left }}>
            <div className={css.clickTarget} />
            <div className={css.tip}>
              <span ref={lowTipRef}>
                <span style={{ transform: `translate(${-spread}px, 0)` }}>{toInt(posLow)}</span>
              </span>
            </div>
          </div>
          <div ref={highThumbRef} className={css.thumb} style={{ right }}>
            <div className={css.clickTarget} />
            <div className={css.tip}>
              <span ref={highTipRef} className={css.tipInner}>
                <span style={{ transform: `translate(${spread}px, 0)` }}>{toInt(posHigh)}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

RangeInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  low: PropTypes.number.isRequired,
  high: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  disabled: PropTypes.bool
};

export default RangeInput;
