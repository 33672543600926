import classNames from "classnames";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import NavDropdown from "./nav_dropdown";
import css from "./nav_item.less";

const NavItem = ({
  icon,
  items,
  name,
  counter,
  href,
  id,
  openKey,
  forceOpen,
  revealed,
  isActiveTest,
  noHover,
  dropdownBottom,
  handleCloseSubMenu,
  handleOpenSubMenu,
  handleClick
}) => {
  const location = useLocation();

  const onClick = (e) => {
    const isMiddleClick = e.button === 1 || e.button === 4;

    if (isMiddleClick) {
      return; // just follow href, otherwise ignore
    }

    if (items || handleClick) {
      e.preventDefault(); // avoid navigation with href (because dropdown/function)
    }

    if (items) {
      openKey === id
        ? handleCloseSubMenu && handleCloseSubMenu()
        : handleOpenSubMenu && handleOpenSubMenu(id);
    } else {
      // no submenu nav items stills close submenus of other items
      handleCloseSubMenu && handleCloseSubMenu();
    }

    if (handleClick) {
      handleClick();
    }
  };

  const [active, setActive] = useState(false);

  useEffect(() => {
    const onHashChange = () => isActiveTest && setActive(!!isActiveTest(location.pathname));
    isActiveTest && window.addEventListener("hashchange", onHashChange);
    onHashChange();
    return () => isActiveTest && window.removeEventListener("hashchange", onHashChange);
  }, [location.pathname]);

  const isOpen = openKey === id || forceOpen;
  const showsDropdown = items && isOpen;
  const showCounter = counter ? counter > 0 : false;
  const itemContent = (
    <>
      <div className={css.icon}>{icon}</div>
      {showCounter && <div className={css.counter}>{counter}</div>}
      <div className={css.screenReader}>{name}</div>
    </>
  );

  const displayId = `${id}_nav_link`;

  return (
    <li
      data-qa-id={`NavItem.${id}`}
      id={id}
      className={classNames(css.item, {
        [css.isOpen]: isOpen,
        [css.isActive]: active,
        [css.isRevealed]: revealed
      })}
    >
      <svg className={css.blobTail} viewBox="0 0 100 90" width="100" height="90">
        <path d="M0,0V90H50C50,68 60,60 80,60 100,60 100,30 80,30 60,30 50,22 50,0Z" />
      </svg>
      {!noHover && <span className={css.blob} />}

      <span className={css.openBlob} />
      <svg className={css.openBlobTail} viewBox="0 0 51 90" width="51" height="90">
        <path d="M50,90C50,70 45,60 20,60 0,60 0,30 20,30 45,30 50,20 50,0 h1 v90z" />
      </svg>
      {href ? (
        <Link
          to={href}
          className={css.link}
          onClick={onClick}
          id={displayId}
          role="button"
          aria-expanded={showsDropdown}
          aria-haspopup={true}
          aria-label={name}
        >
          {itemContent}
        </Link>
      ) : (
        <button
          className={css.link}
          onClick={onClick}
          id={displayId}
          aria-expanded={showsDropdown}
          aria-haspopup={true}
        >
          {itemContent}
        </button>
      )}

      <div className={css.linkName}>{name}</div>

      <NavDropdown
        items={items}
        handleCloseSubMenu={handleCloseSubMenu}
        shown={showsDropdown}
        bottom={dropdownBottom}
        labelledBy={displayId}
      />
    </li>
  );
};

NavItem.propTypes = {
  id: PropTypes.string.isRequired,
  counter: PropTypes.number,
  counterHref: PropTypes.string,
  name: PropTypes.node,
  href: PropTypes.string,
  icon: PropTypes.node,
  openKey: PropTypes.string,
  forceOpen: PropTypes.bool,
  isActiveTest: PropTypes.func,
  revealed: PropTypes.bool,
  dropdownBottom: PropTypes.bool,
  noHover: PropTypes.bool,

  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.node,
      href: PropTypes.string,
      action: PropTypes.func,
      actionHref: PropTypes.string,
      actionIcon: PropTypes.node
    })
  ),

  handleOpenSubMenu: PropTypes.func,
  handleCloseSubMenu: PropTypes.func,
  handleClick: PropTypes.func
};

export default NavItem;
