import { Activity, Template } from "PFTypes";

export const isAudit = (template: Template | undefined) => Boolean(template?.key.includes("audit"));

export const checkIfIsDefaultPrivate = (template: Template, parentActivity?: Activity) => {
  if (parentActivity && template.default_visibility === "inherit_from_parent") {
    return parentActivity.private;
  } else {
    return template.default_visibility === "private";
  }
};
