import classNames from "classnames";
import { ChangeEvent } from "cleave.js/react/props";
import { uniqueId } from "lodash";
import { KeyboardEventHandler, useCallback } from "react";

import { Typography } from "../typography/typography";
import css from "./toggle.module.scss";
import { ToggleReadOnlyIcon } from "./toggle_read_only_icon";

export type ToggleProps = {
  label?: string | JSX.Element;
  tip?: string | JSX.Element;
  inline?: boolean;
  compact?: boolean;
  qaId?: string;
  checked?: boolean;
  disabled?: boolean;
  description?: string | JSX.Element;
  onChange: (value: boolean) => void;
  reversed?: boolean;
  error?: string;
  readOnly?: boolean;
  className?: string;
  required?: boolean;
};

const Toggle = ({
  label = "",
  tip = "",
  inline,
  qaId = "Toggle",
  checked = false,
  disabled,
  description,
  onChange,
  reversed,
  error,
  readOnly,
  compact,
  className,
  required
}: ToggleProps): JSX.Element => {
  const toggleId = uniqueId("toggle");

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => !readOnly && onChange(event.target.checked),
    [readOnly, onChange]
  );
  const handleClick = useCallback(() => !readOnly && onChange(!checked), [readOnly, onChange, checked]);
  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (event.key === "Enter") {
        handleClick();
      }
    },
    [handleClick]
  );

  return (
    <div className={classNames(css.root, className)}>
      <div
        className={classNames(css.toggleContainer, {
          [css.readOnly]: readOnly,
          [css.errorLabel]: error,
          [css.labelInline]: inline,
          [css.labelVertical]: !inline,
          [css.disabled]: disabled
        })}
      >
        {label && (
          <Typography variant="labelRegular" htmlFor={toggleId} className={css.label}>
            {label}
            {required && "*"}
          </Typography>
        )}
        <div
          className={classNames(css.inputContainer)}
          style={{
            flexDirection: reversed ? "row-reverse" : "row"
          }}
          data-qa-id={qaId}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          role="button"
          tabIndex={0}
        >
          {description && (
            <Typography variant="bodyRegular" tag="span">
              {description}
              {required && "*"}
            </Typography>
          )}
          {readOnly ? (
            <ToggleReadOnlyIcon checked={checked} />
          ) : (
            <>
              <input
                id={toggleId}
                className={css.input}
                type="checkbox"
                checked={checked}
                disabled={disabled}
                onChange={handleChange}
              />
              <div className={css.switch} style={{ margin: compact ? "0" : "10px 0" }} />
            </>
          )}
        </div>
      </div>

      {!error && tip && (
        <div className={css.tip}>
          <Typography variant="labelRegular">{tip}</Typography>
        </div>
      )}
      {error && (
        <div className={css.error}>
          <Typography variant="labelRegular">{error}</Typography>
        </div>
      )}
    </div>
  );
};

export default Toggle;
