import TalentIcon from "PFIcons/talent.svg";
import { useTranslation } from "react-i18next";

import NavItem from "./nav_item";

const FeedNavItem = (props) => {
  const { t } = useTranslation("translation", { keyPrefix: "navBar.items" });
  return (
    <NavItem
      icon={<TalentIcon width={20} height={20} />}
      {...props}
      isActiveTest={(path) => path.match(/^\/feed/)}
      name={t("activityFeed")}
      id="feed"
      href="/feed/my_feed"
    />
  );
};

export default FeedNavItem;
