import { useIsMutating, useMutation } from "@tanstack/react-query";
import { submitNewBooking } from "PFApp/booking/services/api";

import { useProfileInvalidate } from "../profile";
import { useBookingsForUpdateInvalidate } from "./bulk_operation/use_bookings_for_update_invalidate";
import { bookingMutationsKeys } from "./mutation_keys";
import { Booking } from "./query_keys";

export const useBookingCreate = (...profileIds: number[]) => {
  const { invalidateProfile } = useProfileInvalidate();
  const { invalidate: invalidateSelectMode } = useBookingsForUpdateInvalidate();
  const mutationKey = bookingMutationsKeys.create();

  const createMutation = useMutation(
    async (bookingData: Partial<Booking>) => {
      if (!bookingData) {
        return;
      }

      return submitNewBooking(bookingData);
    },
    {
      mutationKey,
      onSuccess: () => {
        profileIds.map(invalidateProfile);
        invalidateSelectMode("total_selection");
      }
    }
  );

  const isCreating = useIsMutating({
    mutationKey
  });

  return {
    mutation: createMutation,
    create: createMutation.mutateAsync,
    isCreating
  };
};
