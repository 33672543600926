import { useBookings } from "PFCore/hooks/queries";
import { Booking, PaginatedCollection } from "PFTypes";
import { useMemo } from "react";

import { PER_PAGE_UNPAGINATED } from "../../constants/unpaginated";

type UseFutureBookingsReturn = {
  bookings: PaginatedCollection<Booking[], {}> | undefined;
  isFirstBooking: boolean;
  hasFutureBookings: boolean;
  isFetching: boolean;
};

type useFutureBookingsProps = {
  thisBooking: { startDate: string; id: number };
  activityId: number;
  profileId: number;
};

export const useFutureBookings = ({
  thisBooking,
  activityId,
  profileId
}: useFutureBookingsProps): UseFutureBookingsReturn => {
  const { data: bookings, isFetching } = useBookings({
    profileId,
    activityId,
    perPage: PER_PAGE_UNPAGINATED
  });

  const isFirstBooking = useMemo(() => {
    const thisBookingStartDateTimestamp = new Date(thisBooking.startDate).getTime();
    return (
      bookings?.entries
        ?.filter(({ id }) => id !== thisBooking.id)
        .every(
          ({ start_date: startDate }) => new Date(startDate).getTime() >= thisBookingStartDateTimestamp
        ) ?? false
    );
  }, [bookings, thisBooking.id, thisBooking.startDate]);

  const hasFutureBookings = useMemo(() => {
    const thisBookingStartDateTimestamp = new Date(thisBooking.startDate).getTime();
    return (
      bookings?.entries
        ?.filter(({ id }) => id !== thisBooking.id)
        .some(
          ({ start_date: startDate }) => new Date(startDate).getTime() >= thisBookingStartDateTimestamp
        ) ?? false
    );
  }, [bookings, thisBooking.id, thisBooking.startDate]);

  return { bookings, isFirstBooking, hasFutureBookings, isFetching };
};
