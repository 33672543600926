import classNames from "classnames";
import { Icon } from "PFComponents/icon";

import Tooltip from "../../../tooltip/tooltip";
import css from "../input_field_set.module.scss";

type LockedIconProps = { className?: string; lockedTip?: string; lockedTipPosition: "top" | "bottom" };

export const LockedIcon = ({ className, lockedTip, lockedTipPosition }: LockedIconProps) => (
  <Tooltip placement={lockedTipPosition} content={<span>{lockedTip}</span>}>
    <div className={classNames(css.icon, css.lockIcon, className)}>
      <Icon name="locked" size="sm" />
    </div>
  </Tooltip>
);
