import { Icon } from "PFComponents/icon/icon";
import { Typography } from "PFComponents/typography";
import { IconName } from "PFTheme/graphics/icons";
import { PropsWithChildren, ReactNode } from "react";

import { AnimatedDiv } from "../divs";
import css from "./accordion.module.scss";
import { useAccordionState } from "./use_accordion_state";

const getIconName = (iconSet: IconSet, isOpen: boolean): IconName => {
  switch (iconSet) {
    case "chevron":
      return isOpen ? "chevron-down" : "chevron-right";
    case "caret":
      return isOpen ? "caret-down" : "caret-right";
  }
};

export type AccordionState = "open" | "closed";
type HeaderSize = "small" | "large";
type IconSet = "chevron" | "caret";

export type AccordionProps = {
  header: ReactNode;
  defaultState?: AccordionState;
  state?: AccordionState;
  onStateChange?: (state: AccordionState) => void;
  headerSize?: HeaderSize;
  iconSet?: IconSet;
  actions?: React.ReactNode;
  animated?: boolean;
};

export const Accordion = ({
  header,
  defaultState = "open",
  state,
  onStateChange,
  children,
  headerSize = "large",
  iconSet = "caret",
  actions,
  animated = true
}: PropsWithChildren<AccordionProps>): JSX.Element => {
  const { toggle, isOpen } = useAccordionState({
    onStateChange,
    defaultState,
    state
  });

  const Wrapper = animated ? AnimatedDiv : "div";

  return (
    <Wrapper className={css.root}>
      <div className={css.header}>
        <div className={css.headerTitle} onClick={toggle} onKeyDown={toggle} role="button" tabIndex={0}>
          <Icon name={getIconName(iconSet, isOpen)} size="sm" />
          <Typography variant={headerSize === "large" ? "h4" : "h5"} noMargin>
            {header}
          </Typography>
        </div>
        {actions && <div>{actions}</div>}
      </div>
      {isOpen && (
        <div className={css.content}>
          <div className={css.contentMargin}>{children}</div>
        </div>
      )}
    </Wrapper>
  );
};
