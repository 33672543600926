import { mapKeys } from "lodash";
import colors from "PFTheme/tokens/colors";
import fontSizes from "PFTheme/tokens/fontSizes";
import fontWeights from "PFTheme/tokens/fontWeights";
import letterSpacings from "PFTheme/tokens/letterSpacings";
import lineHeights from "PFTheme/tokens/lineHeights";
import radii from "PFTheme/tokens/radii";
import spacing from "PFTheme/tokens/spacing";

export const loadDesignTokens = () => {
  const tokensFlatMap = {
    ...colors,
    ...fontSizes,
    ...fontWeights,
    ...letterSpacings,
    ...lineHeights,
    ...radii,
    ...spacing
  };

  mapKeys(tokensFlatMap, (value, key) => {
    document.documentElement.style.setProperty(`--${key}`, String(value));
  });
};
