import classNames from "classnames";
import { isNil } from "lodash";
import { DetailsPanelData } from "PFApp/booking/components/details_panel";
import { useBookingModuleContext } from "PFApp/booking/parts/providers/booking_module_context_provider";
import { LoadingDots } from "PFComponents/loading_dots";
import { Markdown } from "PFComponents/markdown";
import { useProfilesWarnings } from "PFCore/hooks/queries/profile";
import { useProfile } from "PFCore/hooks/queries/profile/use_profile";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useBookingModulePermissions from "../../../../../use_booking_module_permissions";
import css from "../../../../parts/overview/detail_view/detail_view.module.scss";
import { Buttons } from "./buttons";
import { Details } from "./details";
import { Skills } from "./skills";

export interface ProfileDetailContentProps {
  data: DetailsPanelData;
}

export const ProfileDetailContent = ({ data }: ProfileDetailContentProps): JSX.Element => {
  const { calendarRange } = useBookingModuleContext();
  const { t } = useTranslation("translation");
  const { data: profile, isLoading: isProfileLoading } = useProfile(data?.id);
  const { warningsByProfile, isLoading: isWarningsLoading } = useProfilesWarnings(
    [{ id: profile?.id || -1 }],
    calendarRange,
    !!profile
  );
  const { canProfileCreateBooking } = useBookingModulePermissions();
  const canSeeBookingButton = canProfileCreateBooking(profile?.id);

  const utilization = useMemo(() => {
    if (isNil(profile?.id) || isNil(warningsByProfile[profile!.id])) {
      return 0;
    }
    return Math.round((warningsByProfile[profile!.id].billable_utilization || 0) * 100);
  }, [profile, warningsByProfile]);

  if (!profile || isProfileLoading || isWarningsLoading) {
    return <LoadingDots />;
  }

  const bookingsTotal = profile.availability?.bookings?.length || 0;

  const { summary } = profile;

  return (
    <div className={classNames(css.root)}>
      <div className={css.separator} />
      <Details profile={profile} bookingsTotal={bookingsTotal} utilization={utilization} />
      <div className={css.separator} />
      {canSeeBookingButton && (
        <>
          <Buttons id={profile.id} />
          <div className={css.separator} />
        </>
      )}
      <div className={css.item}>
        <div className={css.label}>{t("bio")}</div>
        {summary ? <Markdown raw={summary} emojify={false} /> : "-"}
      </div>
      <div className={css.separator} />
      <Skills profile={profile} />
    </div>
  );
};
