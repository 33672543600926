import { difference, range, uniq } from "lodash";
import { Moment } from "moment";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { BookingTemplate } from "PFTypes";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";

interface UseSpecificTime {
  booking: {
    key: string;
    startDate: string;
    endDate: string;
    load?: number;
    state?: string;
    duration?: number;
  };
  bookingTemplate: BookingTemplate;
}

interface UseSpecificTimeReturn {
  startDateSpecificTime: Moment;
  setStartDateSpecificTime: Dispatch<SetStateAction<Moment>>;
  endDateSpecificTime: Moment;
  setEndDateSpecificTime: Dispatch<SetStateAction<Moment>>;
  startTime: string;
  setStartTime: Dispatch<SetStateAction<string>>;
  endTime: string;
  setEndTime: Dispatch<SetStateAction<string>>;
  wdayMask: number | null;
  setWdayMask: Dispatch<SetStateAction<number | null>>;
  disabledDays: number[];
}

export const useSpecificTime = ({ booking, bookingTemplate }: UseSpecificTime): UseSpecificTimeReturn => {
  const { formatISODate, formatISOTime, utc } = useDateFormatter();

  const [startDateSpecificTime, setStartDateSpecificTime] = useState<Moment>(
    utc(bookingTemplate?.start_date || booking?.startDate)
  );
  const [endDateSpecificTime, setEndDateSpecificTime] = useState<Moment>(
    utc(bookingTemplate?.end_date || booking?.endDate)
  );
  const [startTime, setStartTime] = useState<string>(
    bookingTemplate?.start_time || formatISOTime(utc().startOf("day"))
  );
  const [endTime, setEndTime] = useState<string>(
    bookingTemplate?.end_time || formatISOTime(utc().endOf("day"))
  );
  const [wdayMask, setWdayMask] = useState<number | null>(null);

  const allowedDays = useMemo(() => {
    const periodLength =
      utc(formatISODate(endDateSpecificTime)).diff(formatISODate(startDateSpecificTime), "days") + 1;
    if (periodLength >= 7) {
      return range(0, 5);
    }
    const boundaries = uniq(
      [
        utc(formatISODate(startDateSpecificTime)).isoWeekday(),
        utc(formatISODate(endDateSpecificTime)).isoWeekday()
      ].map((i) => (i + 6) % 7)
    );
    if (boundaries.length === 1) {
      return boundaries;
    }
    if (boundaries[0] > boundaries[1]) {
      return difference(range(0, 7), range(boundaries[1] + 1, ((boundaries[0] + 6) % 7) + 1));
    }
    return range(boundaries[0], boundaries[1] + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateSpecificTime, endDateSpecificTime]);

  const disabledDays = useMemo(() => difference(range(0, 7), allowedDays), [allowedDays]);

  useEffect(() => {
    if (wdayMask === null && bookingTemplate) {
      setWdayMask(bookingTemplate.wday_mask);
    } else {
      setWdayMask(allowedDays.map((i) => Math.pow(2, i)).reduce((curr, acc) => curr + acc, 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedDays]);

  return {
    startDateSpecificTime,
    setStartDateSpecificTime,
    endDateSpecificTime,
    setEndDateSpecificTime,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    wdayMask,
    setWdayMask,
    disabledDays
  };
};
