import Checkbox from "PFComponents/checkbox/checkbox";
import ConfirmModal from "PFComponents/confirm/confirm_modal";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./invite_confirm_modal.module.scss";

export type InviteConfirmReasons = {
  list: string[];
  other?: string;
};

type InviteConfirmModalProps = {
  setDisplayConfirm: (displayConfirm: boolean) => void;
  onSubmit: (reasons: InviteConfirmReasons) => void;
};

export const InviteConfirmModal = ({ setDisplayConfirm, onSubmit }: InviteConfirmModalProps) => {
  const { t } = useTranslation(["activities", "translation"]);

  const shortlistReasons = [
    { id: "availability", text: t("activities:parts.availability") },
    { id: "skill", text: t("activities:parts.skill") },
    { id: "location", text: t("activities:parts.location") },
    { id: "cost", text: t("activities:parts.cost") },
    { id: "experience", text: t("activities:parts.experience") },
    { id: "other", text: t("activities:parts.other") }
  ] as const;

  const [reasons, setReasons] = useState<string[]>([]);
  const [otherReason, setOtherReason] = useState("");
  const [otherReasonError, setOtherReasonError] = useState<string | undefined>(undefined);

  const handleCancel = useCallback(() => {
    setReasons([]);
    setOtherReason("");
    setDisplayConfirm(false);
  }, [setDisplayConfirm]);

  const handleSubmit = () => {
    if (reasons.includes("other") && otherReason === "") {
      setOtherReasonError(t("activities:parts.reasonForOther"));
      return;
    }

    const reasonValues = reasons.reduce<InviteConfirmReasons>(
      (acc, id) => {
        const reasonText = shortlistReasons.find((reason) => reason.id === id)?.text!;
        if (id === "other") {
          return { ...acc, other: otherReason };
        }
        return { ...acc, list: [...acc.list, reasonText] };
      },
      { list: [] }
    );

    onSubmit(reasonValues);
  };

  const handleOtherReason = (val: string) => {
    setOtherReason(val);
    setOtherReasonError(undefined);
  };

  return (
    <ConfirmModal
      title={t("activities:parts.decisionBasedOn")}
      showOKButton
      labelOK={t("translation:submit")}
      handleOK={handleSubmit}
      handleClose={handleCancel}
      executeCloseOnOk={false}
    >
      {shortlistReasons.map((item) => (
        <Checkbox
          key={item.id}
          className={css.reason}
          handleChange={(checked) =>
            setReasons(checked ? [...reasons, item.id] : reasons.filter((value) => value !== item.id))
          }
          checked={!!reasons.find((value) => value === item.id)}
          label={item.text}
          id={item.id}
        />
      ))}
      {reasons.find((reason) => reason === "other") && (
        <InputFieldSet
          maxLength={500}
          value={otherReason}
          onChange={handleOtherReason}
          error={otherReasonError}
        />
      )}
      <div className={css.visibilityCopy}>{t("activities:parts.reasonVisibility")}</div>
    </ConfirmModal>
  );
};
