import { camelCase, compact } from "lodash";
import { isAdmin, isSaasAdmin } from "PFCore/helpers/profile";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import useIsPermittedTo from "PFCore/helpers/use_is_permitted_to";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import CogIcon from "PFIcons/cog.svg";
import { FeatureFlag, PermissionRule } from "PFTypes";
import { useTranslation } from "react-i18next";

import NavItem from "./nav_item";

const AdminNavItem = (props) => {
  const { t } = useTranslation("translation", { keyPrefix: "navBar.items" });
  const { data: currentProfile } = useCurrentProfile();

  const items = useItems();

  if (!isAdmin(currentProfile)) {
    return null;
  }

  return (
    <NavItem
      icon={<CogIcon width={20} height={20} />}
      isActiveTest={(path) => path.match(/^\/admin/)}
      name={t("admin")}
      href={"/admin"}
      id="admin"
      {...props}
      items={items}
      dropdownBottom={true}
    />
  );
};

const useItems = () => {
  const { t } = useTranslation();
  const { data: currentProfile } = useCurrentProfile();

  const isEnabled = useIsFeatureEnabled();
  const isPermittedTo = useIsPermittedTo(currentProfile);
  const saasAdmin = isSaasAdmin(currentProfile);

  // user_manager is a permission that actually limits permissions
  if (isPermittedTo(PermissionRule.UserManager)) {
    return null;
  }

  const link = (hrefKey, nameKey, actionHref) => ({
    name: t(`navBar.adminSiteNav.${camelCase(nameKey || hrefKey)}`, { defaultValue: nameKey || hrefKey }),
    href: `/admin/${hrefKey}`,
    actionHref: actionHref ? `/admin/${hrefKey}/new` : null
  });

  const isAuditLogPermitted = isPermittedTo(PermissionRule.AuditLog);

  return compact([
    isEnabled(FeatureFlag.SkillsFramework) && link("skills_frameworks", null, true),
    isEnabled(FeatureFlag.SecureDomains) && link("allowed_domains", null, true),
    isPermittedTo(PermissionRule.PermissionsGroupsManagement) &&
      link("permissions_groups", "permissions", true),
    isEnabled(FeatureFlag.RequestAccess) && link("access_requests", null, false),
    isAuditLogPermitted && link("audit_logs", null, false),
    saasAdmin && isEnabled(FeatureFlag.SamlSignin) && link("saml_configs", "SAML", true),
    { sep: true },
    link("users", "manage_users", true),
    link("custom_fields", null, saasAdmin),
    link("skills_management", null, false),
    link("branding", null, false),
    link("rss", "RSS Feeds", false),
    link("account_contacts", null, false),
    link("email_templates", null, true),
    link("collaborators_configs", null, true),
    link("interest_questions", null, false),
    isEnabled(FeatureFlag.BookingModule) &&
      isPermittedTo(PermissionRule.BookingAdmin) &&
      link("booking", null, false),
    { sep: true },
    saasAdmin && { name: t("navBar.adminSiteNav.saasAdminAccounts"), href: "/saas_admin/accounts" }
  ]);
};

export default AdminNavItem;
