import ExperienceCard from "./experience_card";
import { useExperienceSectionContext } from "./section_experience_context_provider";

const Experiences = () => {
  const { data: experiences, isEditMode, setSelectedElement } = useExperienceSectionContext();

  return (
    <>
      {experiences.map((experience) => (
        <ExperienceCard
          key={JSON.stringify(experience)}
          experience={experience}
          editMode={isEditMode}
          onEdit={setSelectedElement}
        />
      ))}
    </>
  );
};

export default Experiences;
