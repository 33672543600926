import { ApiRoute } from "PFCore/utilities/routes";
import { CustomValue, Meta } from "PFTypes";

export type DiscoveredSkillsResponse = { entries: CustomValue[]; meta: Meta };

export const fetchDiscoveredSkills = (): Promise<DiscoveredSkillsResponse> =>
  //@ts-ignore
  $.ajax({
    url: ApiRoute("/api/discovered_skills"),
    method: "GET",
    api_version: 2 // eslint-disable-line camelcase
  });
