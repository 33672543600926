import ProfilesIcon from "PFIcons/profiles.svg";
import { useTranslation } from "react-i18next";

import NavItem from "./nav_item";

const ProfileNavItem = (props) => {
  const { t } = useTranslation("translation", { keyPrefix: "navBar.items" });
  return (
    <NavItem
      icon={<ProfilesIcon width={20} height={20} style={{ marginTop: -1 }} />}
      isActiveTest={(path) => path.match(/^\/profiles/) && path !== "/profiles/me"}
      name={t("profiles")}
      href="/profiles"
      id="profiles"
      {...props}
    />
  );
};

export default ProfileNavItem;
