import { ActionIcon } from "PFComponents/action_icon";
import { Typography } from "PFComponents/typography";
import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import { useShortlistReset } from "PFCore/hooks/queries/shortlists/use_shortlist_actions";
import { useShortlistDelete } from "PFCore/hooks/queries/shortlists/use_shortlist_delete";
import { useTranslation } from "react-i18next";

import { useRoleProfileActionButtonsContext } from "../context/role_profile_action_buttons_context";
import { useShortlistButtonsVisibility } from "../hooks/use_shortlist_buttons_visibility";
import css from "./shortlist_buttons.module.scss";

export const ShortlistStateLabel = () => {
  const { t } = useTranslation("activities");
  const { role, shortlist } = useRoleProfileActionButtonsContext();
  const { displayUndoButton } = useShortlistButtonsVisibility();
  const { invalidateVacancies } = useActivityInvalidate();

  const { mutate: deleteShortlist } = useShortlistDelete({ activityId: role.id });
  const { mutate: resetShortlist } = useShortlistReset({
    onSuccess: () => {
      if (["filled", "rejected", "booked", "confirmed"].includes(shortlist!.state || "")) {
        invalidateVacancies(role.id);
      }
    }
  });

  const handleClick = () => {
    if (shortlist!.state === "pending") {
      deleteShortlist(shortlist!.id);
    } else {
      resetShortlist(shortlist!.id);
    }
  };

  const labelText =
    shortlist!.state === "pending"
      ? t("show.parts.shortlisted")
      : t(`show.shortlist.state.${shortlist!.state}`);

  return (
    <div className={css.labelWrapper}>
      <Typography variant="labelRegular">{labelText}</Typography>
      {displayUndoButton && (
        <ActionIcon
          name="history"
          size="sm"
          onClick={(event) => {
            event.stopPropagation();
            handleClick();
          }}
        />
      )}
    </div>
  );
};
