import { ActivityHistoryEntry, Id } from "PFTypes";
import { useMemo } from "react";

import { useEventsProfiles } from "./use_events_profiles";

export const useEventProfiles = ({ updatedAttributes }: ActivityHistoryEntry) => {
  const { findProfiles, isLoading } = useEventsProfiles();

  const targetProfiles = useMemo(() => {
    const profileIds = updatedAttributes.profileId?.[1];
    const targetIds = Array.isArray(profileIds) ? profileIds : [profileIds];

    return findProfiles([updatedAttributes.profileId?.[0], ...targetIds].filter((id) => id !== null) as Id[]);
  }, [findProfiles, updatedAttributes.profileId]);

  const targetProfile = useMemo(() => targetProfiles?.[0], [targetProfiles]);

  return { targetProfile, targetProfiles, isLoading };
};
