import { useTranslation } from "react-i18next";

import WeekDaysSelect from "../../../../../components/booking_form/week_days_select";
import css from "../../detail_view.module.scss";
import { BookingDetailDate } from "./booking_detail_date";
import { BookingDetailItem } from "./booking_detail_item";

type BookingDetailRepeatsProps = {
  startDate: string;
  endDate: string;
  wdayMask: number;
};

export const BookingDetailRepeats = ({
  startDate,
  endDate,
  wdayMask
}: BookingDetailRepeatsProps): JSX.Element => {
  const { t } = useTranslation("bookingModule");
  return (
    <BookingDetailItem
      label={t("bookings.create.repeats")}
      labelClassName={css.noWrap}
      rootClassName={css.duration}
      value={
        <>
          <BookingDetailDate startDate={startDate} endDate={endDate} />
          <div className={css.item}>
            <WeekDaysSelect value={wdayMask} disabled />
          </div>
        </>
      }
    />
  );
};
