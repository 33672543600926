import { fetchProfile as fetchUserProfile } from "PFCore/services/profile";
import { Profile } from "PFTypes";
import { createContext, useContext, useMemo } from "react";

import { BookingFormWrapper } from "./booking_form_wrapper";
import { BookingFormType, useBookingForm } from "./use_booking_form";

type BookingFormContextType = {
  profileId?: number;
  modal: BookingFormType;
  fetchProfile: (id: number) => Promise<Profile> | JQuery.jqXHR<Profile>;
};

const BookingFormContext = createContext({} as BookingFormContextType);

export type BookingFormConfig = {
  profileId?: number;
  fetchProfile?: (id: number) => Promise<Profile> | JQuery.jqXHR<Profile>;
  onModalSubmit?: () => Promise<unknown>;
};

export const BookingFormContextProvider = ({
  children,
  fetchProfile,
  onModalSubmit,
  profileId
}: React.PropsWithChildren<BookingFormConfig>) => {
  const modal = useBookingForm();
  const value = useMemo(
    () => ({ modal, fetchProfile: fetchProfile || fetchUserProfile, profileId }),
    [modal, fetchProfile, profileId]
  );

  return (
    <BookingFormContext.Provider value={value}>
      <BookingFormWrapper onSubmit={modal.state.data?.onSubmit || onModalSubmit} />
      {children}
    </BookingFormContext.Provider>
  );
};

export const useBookingFormContext = () => useContext(BookingFormContext);
