import classNames from "classnames";
import { isEmpty, isNumber } from "lodash";
import { ActionIcon } from "PFComponents/action_icon";
import Toggle from "PFComponents/toggle/toggle";
import { AccountAvailability, Id } from "PFTypes";
import { AvailabilityMode, AvailabilityRule } from "PFTypes/booking";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { RANGES_ERROR_KEY, ROOT_ERROR_KEY } from "../constants";
import { RulesCarousel } from "../rules_carousel";
import { ThresholdSlider } from "../threshold_slider";
import { getDefaultAvailabilityRule } from "../utils";
import css from "./availability_input.module.scss";

type AvailabilityInputProps = {
  accountAvailability: AccountAvailability;
  availability: Partial<AvailabilityRule>;
  allowedModes: string[];
  defaultAvailabilityMode?: AvailabilityMode;
  defaultAvailabilityThreshold?: number;
  errors?: Record<string, string>;
  isFilter: boolean;
  minDate?: { start: string; end?: string };
  activityId?: Id;
  onChange: (availability: AvailabilityRule | null, timeRuleModeChecked?: boolean) => void;
  onRestore?: () => void;
};

export const AvailabilityInput = ({
  onChange,
  accountAvailability,
  availability,
  allowedModes,
  defaultAvailabilityMode = AvailabilityMode.Within,
  defaultAvailabilityThreshold = 70,
  errors,
  isFilter,
  minDate,
  activityId,
  onRestore
}: AvailabilityInputProps) => {
  const { t } = useTranslation();

  const [isTimeRule, setIsTimeRule] = useState<boolean>(
    (availability?.mode || defaultAvailabilityMode) === AvailabilityMode.TimeRule ||
      (allowedModes.includes("time_rule") && allowedModes.length === 1)
  );

  useEffect(() => {
    if (availability.mode) {
      setIsTimeRule(availability.mode === AvailabilityMode.TimeRule);
    }
  }, [availability.mode]);

  const rootError = errors?.[ROOT_ERROR_KEY] || errors?.[RANGES_ERROR_KEY];

  const defaultAvailabilityRule = getDefaultAvailabilityRule(
    isTimeRule,
    accountAvailability,
    defaultAvailabilityThreshold
  );

  const handleChange = (change: Partial<AvailabilityRule>) => {
    onChange({ ...defaultAvailabilityRule, ...availability, ...change });
  };

  const handleThresholdChange = (value: number) => {
    handleChange({ availability_threshold: value });
  };

  const handleToggleMode = (value: boolean) => {
    setIsTimeRule(value);
    onChange(null, value);
  };

  const handleClear = () => onChange(null);
  const isTimeRuleToggleVisible = allowedModes.includes("time_rule") && allowedModes.length > 1;

  const threshold = isNumber(availability.availability_threshold)
    ? availability.availability_threshold
    : defaultAvailabilityThreshold;

  return (
    <div className={css.root}>
      <div className={classNames(css.content, { [css.errorBox]: !!rootError })}>
        <div className={css.description}>
          <span>{t("availabilityRequirement.description")}</span>
          <div className={css.iconButtons}>
            {onRestore && (
              <ActionIcon name="history" size="sm" onClick={onRestore} fill="var(--palettePrimary0)" />
            )}
            {!isEmpty(availability) && (
              <ActionIcon name="filter-clean" size="sm" onClick={handleClear} fill="var(--palettePrimary0)" />
            )}
          </div>
        </div>
        <ThresholdSlider threshold={threshold} onChange={handleThresholdChange} />
        {isTimeRuleToggleVisible && (
          <Toggle
            inline
            label={t("availabilityRequirement.timeRules")}
            checked={isTimeRule}
            onChange={handleToggleMode}
          />
        )}
        <RulesCarousel
          accountAvailability={accountAvailability}
          defaultAvailabilityRule={defaultAvailabilityRule}
          isFilter={isFilter}
          isTimeRule={isTimeRule}
          allowedModes={allowedModes}
          availability={availability}
          minDate={minDate}
          onChange={handleChange}
          errors={errors}
          activityId={activityId}
        />
      </div>
      {rootError && <div className={css.errorMessage}>{rootError}</div>}
    </div>
  );
};
