import classNames from "classnames";
import { head, isEmpty } from "lodash";
import { useTemplate } from "PFApp/hooks";
import { Divider } from "PFComponents/divider";
import SidePanel from "PFComponents/side_panel/side_panel";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import { TextArea } from "PFComponents/text/text_area";
import { Typography } from "PFComponents/typography";
import { getProfileName } from "PFCore/helpers/profile";
import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import { useIsExternalUsage } from "PFCore/hooks/queries/bookings/use_is_external_usage";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { ActivitiesResponse } from "PFCore/services/activities";
import { Activity, AvailabilityRule, PermissionRule, Profile } from "PFTypes";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { EngagementSelect } from "../../../components/engagement_select";
import {
  ActivityTemplateKey,
  activityTypeTranslationKeys
} from "../../parts/overview/detail_view/booking_detail/booking_details_activity";
import { useBookingActivityContext } from "../../parts/providers/booking_activity_context_provider";
import { BookingSelectOption } from "../booking_questions/booking_questions";
import UpdateQuestionsModal from "../booking_questions/update_questions_modal";
import { useDetailsPanelApiContext, useDetailsPanelOrderContext } from "../details_panel";
import { ReassignSelectOption } from "../reassign_booking_modal/bookings_to_reassign_selector";
import { ReassignBookingModal } from "../reassign_booking_modal/reassign_booking_modal";
import { ActivityInfo } from "./activity_info";
import { AvailabilityInfo } from "./availability_info";
import { BookingCategorySelect } from "./booking_category_select";
import css from "./booking_form.module.scss";
import { getHeaderTitleTranslationKey, isRepeatedBooking } from "./booking_form.utils";
import { useBookingFormContext } from "./booking_form_context_provider";
import { BookingRangeColumn } from "./booking_range_column";
import { Footer } from "./footer/footer";
import { useOverrideToggles } from "./override_toggles/use_override_toggles";
import { isSomeRequirementInvalid } from "./requirement_selector";
import {
  BookingData,
  BookingFormDataType,
  BookingFormMode,
  RepeatedBookingDateRangeData
} from "./use_booking_form";
import useBookingInitialData from "./use_booking_initial_data";
import { useCategoryError } from "./use_category_error";
import { useEngagementSelect } from "./use_engagement_select";
import { useHandleSubmit } from "./use_handle_submit";
import usePotentialWarnings from "./use_potential_warnings";
import {
  BulkSelectOption,
  isBulkSelectOption,
  isProfile,
  WorkforceMemberSelect
} from "./workforce_member_select/workforce_member_select";
import { PROFILES_COUNT_LIMIT } from "./workforce_member_select/workforce_member_select.utils";

export type BookingFormProps = {
  show: boolean;
  onClose?: () => void;
  initialData?: BookingFormDataType;
  bookingId?: number;
  onSuccess?: () => void;
  onSubmit?: () => void;
  mode: BookingFormMode;
};

export const BookingForm = ({
  show,
  onClose,
  initialData = {},
  bookingId,
  onSuccess,
  onSubmit,
  mode
}: BookingFormProps): JSX.Element => {
  const { t } = useTranslation(["bookingModule", "translation"]);
  const { activity, parentActivity } = useBookingActivityContext();

  const { closeDetailsPanel } = useDetailsPanelApiContext();
  const { findIndexOfBookingForm } = useDetailsPanelOrderContext();
  const {
    modal: { close }
  } = useBookingFormContext();
  const { utc } = useDateFormatter();

  const [showQuestionsModal, setShowQuestionsModal] = useState(false);
  const [showReassignModal, setShowReassignModal] = useState(false);
  const [updateGroupOptionSelected, setUpdateGroupOptionSelected] = useState(BookingSelectOption.This);
  const [updateRepeatOptionSelected, setUpdateRepeatOptionSelected] = useState(BookingSelectOption.All);
  const [reassignOptionSelected, setReassignOptionSelected] = useState(ReassignSelectOption.ThisBooking);

  const [bookingData, setBookingData] = useState<BookingData>({});
  const inputTagRef = useRef<HTMLInputElement>(null);

  const handleFocus = () => {
    inputTagRef?.current?.select();
  };

  const activityTemplate = useTemplate(activity?.template_id);
  const isGrouped = Number.isInteger(initialData.bookingGroupId);
  const isBookingResourcer = useIsCurrentUserPermittedTo(PermissionRule.BookingResourcer);
  const isAuditBooking = activityTemplate?.key?.includes("audit");
  const specificTime = !!Object.values(bookingData as Record<number, RepeatedBookingDateRangeData>).find(
    (item) => item.wdayMask
  );

  const {
    title,
    setTitle,
    description,
    setDescription,
    selectedUser,
    setSelectedUser,
    prefilledUserRef,
    selectedEngagement,
    setSelectedEngagement,
    selectedBookingCategory,
    setSelectedBookingCategory,
    bookingItems,
    setBookingItems,
    isBookingsFromAvailabilityLoading
  } = useBookingInitialData(initialData, mode === BookingFormMode.Create, show);

  const {
    overridesCalendar,
    overridesNonWorkingDays,
    setOverridesCalendar,
    setOverridesNonWorkingDays,
    resetOverrideToggles
  } = useOverrideToggles({
    show,
    defaultOverridesCalendar: initialData.overridesCalendar,
    defaultOverridesNonWorkingDays: initialData.overridesNonWorkingDays
  });

  const {
    handleSubmit,
    isLoading,
    errors: submitErrors
  } = useHandleSubmit({
    bookingData,
    initialData,
    title,
    description,
    prefilledUserRef,
    selectedUser,
    selectedBookingCategory,
    mode,
    bookingId,
    specificTime,
    overridesCalendar,
    overridesNonWorkingDays,
    onSubmit,
    onSuccess: () => {
      onSuccess?.();
      handleClose();
      if (specificTime && [BookingFormMode.Edit, BookingFormMode.Clone].includes(mode)) {
        closeDetailsPanel();
      }
    },
    onError: () => handleClose(),
    updateGroupOptionSelected,
    updateRepeatOptionSelected,
    reassignOptionSelected,
    selectedEngagement
  });

  const isExternalUsage = useIsExternalUsage();
  const categoryError = useCategoryError({
    selectedBookingCategory,
    isDemandRelated: !!selectedEngagement || !!parentActivity?.id || !!initialData.activityId
  });

  const {
    overbookings,
    misalignments,
    errors: potentialWarningsErrors,
    loading: isPotentialWarningsLoading
  } = usePotentialWarnings({
    bookingId,
    bookingData,
    activityId: initialData.activityId ?? selectedEngagement?.id,
    selectedUser,
    selectedBookingCategory,
    disabled: isLoading
  });
  const isRepeated = isRepeatedBooking(bookingData);

  const {
    shouldDisplayEngagementSelect,
    engagementSelectQuery,
    engagementSelectParseResponse,
    engagementSelectHandleChange,
    parseEngagementToOption
  } = useEngagementSelect({
    activityId: initialData.activityId ?? undefined,
    selectedEngagement,
    setSelectedEngagement,
    title,
    setTitle,
    selectedUser,
    isGrouped
  });

  const confirmModalHeader = getHeaderTitleTranslationKey(mode);

  const resetValues = () => {
    setSelectedUser(null);
    setSelectedEngagement(null);
    setBookingItems([]);
    setBookingData({});
    setSelectedBookingCategory(null);
    resetOverrideToggles();
    setUpdateGroupOptionSelected(BookingSelectOption.This);
    setUpdateRepeatOptionSelected(BookingSelectOption.All);
    setReassignOptionSelected(ReassignSelectOption.ThisBooking);
  };

  const handleClose = () => {
    onClose?.();
    resetValues();
    close();
  };

  const isSubmitEnabled =
    !isLoading &&
    !isPotentialWarningsLoading &&
    selectedUser &&
    selectedBookingCategory &&
    bookingItems.length > 0 &&
    ((selectedUser as BulkSelectOption)?.total || 0) <= PROFILES_COUNT_LIMIT &&
    !isSomeRequirementInvalid(Object.values(bookingData)) &&
    !categoryError;

  const handleOK = () => {
    const isSingleDayBookingTemplate =
      initialData.bookingTemplate &&
      utc(initialData.bookingTemplate.end_date).diff(initialData.bookingTemplate.start_date, "days") === 0;
    const shouldShowReassignModal =
      mode === BookingFormMode.Edit &&
      selectedUser &&
      initialData.profileId &&
      initialData.profileId !== (selectedUser as Profile)?.id;

    if (shouldShowReassignModal) {
      setShowReassignModal(true);
    } else if (
      mode === BookingFormMode.Edit &&
      ((isGrouped && !initialData.bookingTemplate) || (specificTime && !isSingleDayBookingTemplate))
    ) {
      setShowQuestionsModal(true);
    } else {
      handleSubmit();
    }
  };

  return (
    <>
      <SidePanel
        show={show}
        zIndex={findIndexOfBookingForm() + 1}
        onClose={handleClose}
        title={t(confirmModalHeader)}
        footerRenderer={({ onSidePanelClose }) => (
          <Footer
            profileId={selectedUser && !isBulkSelectOption(selectedUser) ? selectedUser.id : undefined}
            bookingData={bookingData}
            bookingItems={bookingItems}
            title={title}
            bookingCategory={selectedBookingCategory}
            mode={mode}
            activity={activity}
            misalignments={misalignments ?? []}
            overbookings={overbookings ?? []}
            onSubmit={handleOK}
            onClose={onSidePanelClose}
            isSubmitDisabled={!isSubmitEnabled}
            overrideToggleData={{
              value: { overridesCalendar, overridesNonWorkingDays },
              onOverridesCalendarChange: setOverridesCalendar,
              onOverridesNonWorkingDaysChange: setOverridesNonWorkingDays
            }}
          />
        )}
      >
        <div className={css.content}>
          <div className={css.section}>
            {activity?.availability && (
              <AvailabilityInfo availability={activity.availability as AvailabilityRule} />
            )}
            <WorkforceMemberSelect
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              mode={mode}
              initialData={initialData}
              isDisabled={!isBookingResourcer}
            />
            {shouldDisplayEngagementSelect && (
              <EngagementSelect<ActivitiesResponse, Activity>
                selectedEngagement={selectedEngagement}
                letClear={activityTemplate?.key !== "engagement"}
                query={engagementSelectQuery}
                parseResponse={engagementSelectParseResponse}
                handleChange={engagementSelectHandleChange}
                parseEngagementToOption={parseEngagementToOption}
                isRepeatedBooking={isRepeated}
              />
            )}
            {initialData.activityId && activityTemplate?.key !== "engagement" && (
              <ActivityInfo
                label={t(
                  `translation:${activityTypeTranslationKeys[activityTemplate?.key as ActivityTemplateKey]}`
                )}
                activityId={initialData.activityId}
              />
            )}
            {parentActivity?.id && !shouldDisplayEngagementSelect && (
              <ActivityInfo
                label={isAuditBooking ? t("translation:auditEngagement") : t("translation:engagement")}
                activityId={parentActivity?.id}
              />
            )}
          </div>
          <Divider color="paletteNeutral0" />
          <div className={css.section}>
            <BookingRangeColumn
              show={show}
              initialData={initialData}
              setBookingItems={setBookingItems}
              setBookingData={setBookingData}
              bookingItems={bookingItems}
              isEditMode={mode !== BookingFormMode.Create}
              loading={isBookingsFromAvailabilityLoading}
              errors={submitErrors ?? undefined}
              isSelectedEngagement={!!selectedEngagement}
            />
          </div>
          <Divider color="paletteNeutral0" />
          <div className={css.section}>
            <BookingCategorySelect
              isRoleRelated={!!initialData.activityId || !!selectedEngagement}
              category={selectedBookingCategory}
              onCategoryChange={(newCategory) => {
                const isTitlePreviousCategoryName = selectedBookingCategory?.display_as === title;
                if (newCategory && (!title || isTitlePreviousCategoryName)) {
                  setTitle(newCategory.display_as);
                }
                setSelectedBookingCategory(newCategory);
              }}
              className={css.selectInput}
              error={
                (submitErrors?.booking_category_id ||
                  potentialWarningsErrors?.booking_category_id ||
                  categoryError) ??
                undefined
              }
            />
            <InputFieldSet
              label={t("bookingModule:bookings.create.title")}
              value={title}
              maxLength={250}
              onFocus={handleFocus}
              onChange={setTitle}
              className={classNames(css.selectInput)}
              ref={inputTagRef}
            />
            <div>
              <Typography variant="bodyRegular" className={css.label}>
                {t("bookingModule:bookings.create.description")}
              </Typography>
              <TextArea
                value={description}
                onChange={setDescription}
                className={css.textarea}
                minHeight={145}
                maxLength={2000}
                placeholder={t("bookingModule:bookings.create.descriptionPlaceholder")}
              />
            </div>
          </div>
        </div>
      </SidePanel>
      {showQuestionsModal && (
        <UpdateQuestionsModal
          profileFullName={getProfileName(selectedUser && isProfile(selectedUser) ? selectedUser : null)}
          updateGroupOptionSelected={updateGroupOptionSelected}
          setUpdateGroupOptionSelected={setUpdateGroupOptionSelected}
          updateRepeatOptionSelected={updateRepeatOptionSelected}
          setUpdateRepeatOptionSelected={setUpdateRepeatOptionSelected}
          isRepeated={specificTime}
          bookingGroupId={initialData.bookingGroupId}
          onClose={() => setShowQuestionsModal(false)}
          onSubmit={handleSubmit}
        />
      )}
      {showReassignModal && !!activity && !isEmpty(initialData?.bookings) && (
        <ReassignBookingModal
          activity={activity}
          profileFrom={prefilledUserRef.current ?? (selectedUser as Profile)}
          // TODO: [PROF-3444] Handle matching scores section when the API/HAL part is ready
          profileTo={{
            profile: selectedUser as Profile
          }}
          readOnlyMode
          reassignOptionSelected={reassignOptionSelected}
          setReassignOptionSelected={setReassignOptionSelected}
          onClose={() => setShowReassignModal(false)}
          onSubmit={handleSubmit}
          shouldDisplayReassignOptions={!isExternalUsage}
          thisBooking={{ id: bookingId, ...head(initialData.bookings)! }}
        />
      )}
    </>
  );
};
