import { getTheme } from "PFCore/get_theme";
import { THEME_VARS_ACTIONS } from "PFCore/reducers/theme_vars_reducer";
import { useEffect } from "react";

export const useThemeVars = (dispatch) => {
  const { theme } = PF.config;
  const { themeConfig } = PF.config;
  const themeVars = getTheme(theme, { themeConfig });

  useEffect(() => {
    dispatch({ type: THEME_VARS_ACTIONS.INJECT_STYLES, payload: themeVars });
  }, []);
};
