import Radios from "PFComponents/radios/radios";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ConversationSection = ({ communicationType, handleCommunicationChange, qaIdPrefix }) => {
  const { t } = useTranslation("activities");

  return (
    <div data-qa-id={`${qaIdPrefix}-conversation`}>
      <Radios
        items={[
          { id: "group", text: t("edit.sections.groupConversation") },
          { id: "direct", text: t("edit.sections.oneToOnePrivate") }
        ]}
        value={communicationType}
        handleChange={(item) => handleCommunicationChange(item.id)}
      />
    </div>
  );
};

export default ConversationSection;

ConversationSection.propTypes = {
  communicationType: PropTypes.string.isRequired,
  handleCommunicationChange: PropTypes.func.isRequired,
  qaIdPrefix: PropTypes.string
};
