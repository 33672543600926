import { isNil } from "lodash";
import { useBookingInvalidate } from "PFCore/hooks/queries";
import { useBookingApprovalsInvalidate } from "PFCore/hooks/queries/bookings/approvals/use_booking_approvals_invalidate";
import { useBookedProfilesInvalidate } from "PFCore/hooks/queries/bookings/booked_profiles/use_booked_profiles_invalidate";
import { useBookingTemplateInvalidate } from "PFCore/hooks/queries/bookings/booking_templates/use_booking_template_invalidate";
import { useBookingHistoryInvalidate } from "PFCore/hooks/queries/bookings/history/use_booking_history_invalidate";
import { useProfileInvalidate } from "PFCore/hooks/queries/profile";
import { useShortlistsInvalidate } from "PFCore/hooks/queries/shortlists/use_shortlists_invalidate";
import { useWorkforceMetricsInvalidate } from "PFCore/hooks/queries/workforce_metrics/use_workforce_metrics_invalidate";
import { useCallback } from "react";

type UseRefreshBookingDataPayload = {
  bookingIds?: number[];
  bookingGroupId?: number;
  shortlistId?: number;
  activityId?: number | null;
  profileIds?: number[];
  refreshGroup?: boolean;
  refreshProfile?: boolean;
};

type UseRefreshBookingDataReturn = (data: UseRefreshBookingDataPayload) => void;

export const useRefreshBookingData = (): UseRefreshBookingDataReturn => {
  const { invalidateAllProfiles, invalidateBookingsForProfile, invalidateProfile } = useProfileInvalidate();
  const { invalidateBookingsForBookingGroup, invalidateProfileBookingsList } = useBookingInvalidate();
  const { invalidateByBookingGroupId: invalidateBookedProfilesByBookingGroupId } =
    useBookedProfilesInvalidate();
  const { invalidateShortlists } = useShortlistsInvalidate();

  const { invalidate: invalidateBookingHistory } = useBookingHistoryInvalidate();
  const { invalidate: invalidateBookingApprovals } = useBookingApprovalsInvalidate();
  const { invalidateBookingsTemplatesForBookingGroup } = useBookingTemplateInvalidate();
  const { invalidate: invalidateWorkforceMetrics } = useWorkforceMetricsInvalidate();

  return useCallback(
    async ({
      bookingIds = [],
      bookingGroupId,
      shortlistId,
      activityId,
      profileIds,
      refreshGroup,
      refreshProfile
    }: UseRefreshBookingDataPayload) => {
      bookingIds.forEach((bookingId) => {
        invalidateBookingHistory(bookingId);
      });
      if (refreshGroup) {
        invalidateAllProfiles();
        if (bookingGroupId) {
          invalidateBookingsForBookingGroup(bookingGroupId);
          invalidateBookingsTemplatesForBookingGroup(bookingGroupId);
        }
      } else if (refreshProfile) {
        (profileIds ?? [])
          .filter((profileId) => !!profileId)
          .forEach((profileId) => {
            invalidateBookingsForProfile(profileId);
            invalidateProfile(profileId);
          });
      }
      bookingGroupId && invalidateBookedProfilesByBookingGroupId(bookingGroupId);
      invalidateBookingApprovals();
      if (shortlistId && !isNil(activityId)) {
        invalidateShortlists(activityId);
      }
      invalidateWorkforceMetrics();
      if (activityId) {
        (profileIds ?? [])
          .filter((profileId) => !!profileId)
          .forEach((profileId) => {
            profileId && invalidateProfileBookingsList(profileId, activityId);
          });
      }
    },
    []
  );
};
