import { Button } from "PFComponents/button";
import { SyntheticEvent } from "react";

type TablePropertiesButtonProps = {
  onClick: (e: SyntheticEvent) => void;
};

const TablePropertiesButton = ({ onClick }: TablePropertiesButtonProps) => (
  <Button
    icon="table"
    kind="secondary"
    onClick={onClick}
    id="table_properties_button"
    data-qa-id="WorkflowPageTablePropertiesButton"
  />
);

export default TablePropertiesButton;
