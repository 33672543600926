import Slider from "PFComponents/slider/slider";
import Tooltip from "PFComponents/slider/tooltip";
import PropTypes from "prop-types";
import { PureComponent } from "react";

export default class SliderWithTooltip extends PureComponent {
  state = {
    value: this.props.value
  };

  handleInput = (event) => {
    this.setState({ value: event.target.valueAsNumber });
  };

  handleChange = (event) => {
    this.setState({ value: event.target.valueAsNumber });
  };

  render() {
    const { style } = this.props;
    const { value } = this.state;

    return (
      <div style={{ paddingTop: 25, position: "relative", overflow: "hidden", ...style }}>
        <Tooltip {...this.props} value={value} />
        <Slider {...this.props} value={value} handleInput={this.handleInput} />
      </div>
    );
  }
}

SliderWithTooltip.propTypes = {
  ...Slider.propTypes,
  ...Tooltip.propTypes,
  handleChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  style: PropTypes.object
};

SliderWithTooltip.defaultProps = {
  weight: null,
  handleChange: null
};
