import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { BookingCategory, ProfileDayAvailability } from "PFTypes";

import { CalendarPeriod } from "../bookings_calendar.types";
import { CalendarDay } from "../use_calendar_days";
import { DisplayDate } from "./display_date";
import css from "./week.module.scss";
import { WeekPeriods } from "./week_periods/week_periods";

type DayWithAvailability = CalendarDay & {
  availability: ProfileDayAvailability | undefined;
};

type WeekProps = {
  isFirstWeek: boolean;
  days: DayWithAvailability[];
  bookingCategories: BookingCategory[];
  jobCodeDisplayAs: string;
  onClick?: (booking: CalendarPeriod) => void;
  isExpanded: boolean | undefined;
  periodsByLevels: CalendarPeriod[][];
};

export const Week = ({
  isFirstWeek,
  days,
  onClick,
  periodsByLevels,
  isExpanded,
  bookingCategories,
  jobCodeDisplayAs
}: WeekProps): JSX.Element => {
  const { utc } = useDateFormatter();
  const startWeekDate = utc(days[0].date);
  const endWeekDate = utc(days[days.length - 1].date);

  return (
    <div className={css.root}>
      {days.map((day, index) => {
        const isFirstDay = index === 0 && isFirstWeek;
        return (
          <DisplayDate
            key={day.date}
            date={day.date}
            config={day.config}
            isExpanded={isExpanded}
            isFirstDay={isFirstDay}
            availability={day.availability}
          />
        );
      })}
      <WeekPeriods
        isExpanded={isExpanded}
        periodsByLevels={periodsByLevels}
        startWeekDate={startWeekDate}
        endWeekDate={endWeekDate}
        bookingCategories={bookingCategories}
        jobCodeDisplayAs={jobCodeDisplayAs}
        onClick={onClick}
      />
    </div>
  );
};
