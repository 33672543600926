import { useTemplate } from "PFApp/hooks";
import { getActiveActivityProfiles } from "PFCore/helpers/get_active_activity_profiles";
import { Activity, Owner, Profile } from "PFTypes";
import { useMemo } from "react";

import { getOrderedActivityProfiles } from "../helpers/get_ordered_activity_profiles";

type OwnersResult = {
  owners: Profile[];
  principalOwner?: Profile;
  canView: boolean;
  canWrite: boolean;
};

export const useOwners = (activity?: Partial<Activity>): OwnersResult => {
  const activityType = useTemplate(activity?.template_id);
  const activeOwners = getActiveActivityProfiles<Owner>(activity?.owners || []);
  const principalOwner = activeOwners.find((item) => item.principal)?.profile;

  const owners = useMemo(
    () =>
      getOrderedActivityProfiles(
        activeOwners.filter(({ principal }) => !principal).map(({ profile }) => profile)
      ),
    [activeOwners]
  );

  const isEngagementOrRole = ["role", "engagement", "audit_role", "audit_engagement"].includes(
    activityType?.key || ""
  );
  const canWrite = isEngagementOrRole && !!activity?.coowned;

  return { owners, principalOwner, canView: owners.length > 0, canWrite };
};
