/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useGrowl } from "PFApp/use_growl";
import BrainLoading from "PFComponents/brain_loading/brain_loading";
import { Button } from "PFComponents/button";
import { FileUpload } from "PFComponents/file_upload";
import { usePusherEvent } from "PFCore/base/pusher";
import { gidToId } from "PFCore/helpers/gid";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import CVIcon from "PFIcons/illie_cv.svg";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./cv_upload.less";

let timeoutId;

const CVUpload = ({ processingMessage, handleSuccess, handleTimeout, handleCancel }) => {
  const { data: currentProfile } = useCurrentProfile();
  const growl = useGrowl();
  const { t } = useTranslation("profiles", { keyPrefix: "common.cvUpload" });

  const [state, setState] = useState({
    loading: false,
    processing: false
  });
  const [isUnmounted, setIsUnmounted] = useState(false);

  const handleCVUpToDate = useCallback((data) => {
    if (data.status === "up-to-date") {
      handleSuccess?.(data);
    }
  }, []);
  const handleNewChangeRequest = useCallback((data) => {
    data.id = gidToId(data.resource_gid);
    handleSuccess?.(data);
  }, []);

  usePusherEvent(`private-profile-${currentProfile.id}`, "cv", handleCVUpToDate);
  usePusherEvent(`private-profile-${currentProfile.id}`, "change_request_create", handleNewChangeRequest);

  useEffect(
    () => () => {
      setIsUnmounted(true);
      clearTimeout(timeoutId);
    },
    []
  );

  const notifyTimeout = () => {
    if (isUnmounted) {
      return;
    }

    handleTimeout && handleTimeout();

    growl({
      message: t("cvStillProcessing")
    });
  };

  const handleChange = () => {
    setState({ loading: true });
  };

  const handleLoad = () => {
    growl({ message: t("upload.success") });
    setState({ loading: false, processing: true });
    timeoutId = setTimeout(notifyTimeout, 30000);
  };

  const handleError = (response) => {
    let message = t("upload.error");

    if (response && response.errors && response.errors.length > 0) {
      const error = response.errors[0];

      if (error.detail && error.detail.indexOf("not a valid CV file") !== -1) {
        message = t("upload.notValidExt");
      } else {
        message = error.detail;
      }
    }

    growl({ message, kind: "error" });
    setState({ loading: false, processing: false });
  };

  const { loading, processing } = state;

  return (
    <div className={css.root}>
      {(loading || processing) && <BrainLoading style={{ margin: 0 }} />}
      {(loading || processing) && <h1 className={css.title}>{t("title")}</h1>}

      {loading && <div className={css.information}>{t("uploading")}</div>}
      {loading && <div className={css.information} />}

      {processing && processingMessage}

      {!loading && !processing && (
        <>
          <FileUpload
            url="curriculums"
            name="file"
            qaId="profile-edit-curriculum-input"
            handleChange={handleChange}
            handleLoad={handleLoad}
            handleError={handleError}
          >
            <div className={css.label}>
              <div className={css.attachment}>
                <CVIcon width={200} height={200} />
                <h1 className={css.title}>{t("title")}</h1>
                <div className={css.information}>{t("uploadInfo")}</div>
                <div className={css.information}>{t("extensionsInfo")}</div>
              </div>
              <Button kind="primary" qaId="profile-edit-upload-button" tag={"a"}>
                {t("translation:upload")}
              </Button>
            </div>
          </FileUpload>
          {handleCancel && (
            <div style={{ marginTop: 20 }}>
              {/* eslint-disable-next-line max-len */}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <a onClick={handleCancel} data-qa-id="profile-edit-skip">
                {t("orSkip")}
              </a>
            </div>
          )}
        </>
      )}
    </div>
  );
};

CVUpload.propTypes = {
  processingMessage: PropTypes.node.isRequired,
  handleSuccess: PropTypes.func,
  handleCancel: PropTypes.func,
  handleTimeout: PropTypes.func
};

export default CVUpload;
