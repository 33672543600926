import { Icon } from "PFComponents/icon";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import { useAvailabilityFormatter } from "PFCore/helpers/availability";
import { useReadableAvailability } from "PFCore/helpers/use_readable_availability";
import { AvailabilityMode, AvailabilityRule } from "PFTypes";

import css from "./availability_info.module.scss";

type AvailabilityInfoProps = {
  availability: AvailabilityRule;
};

export const AvailabilityInfo = ({ availability }: AvailabilityInfoProps) => {
  const { availabilityRangesFormat, availabilityIntervalFormat } = useAvailabilityFormatter();
  const { readableAvailability } = useReadableAvailability({ availability: availability });

  const getRangesTooltipContent = (availability) => {
    if (!availability) {
      return null;
    }
    return availability.mode === AvailabilityMode.TimeRule
      ? availabilityRangesFormat(availability, true, css.rangesTooltipContent)
      : availabilityIntervalFormat(availability);
  };

  const rangesTooltipContent = getRangesTooltipContent(availability);

  return (
    <span className={css.availabilityLabel}>
      <Typography variant="bodyBold" noMargin>
        {readableAvailability}
      </Typography>
      {availability?.ranges && (
        <Tooltip maxWidth={500} content={<div className={css.tooltipContent}>{rangesTooltipContent}</div>}>
          <span className={css.alignCenter}>
            <Icon name="info" size="sm" />
          </span>
        </Tooltip>
      )}
    </span>
  );
};
