import BookingIcon from "PFIcons/booking.svg";
import { useTranslation } from "react-i18next";

import NavItem from "./nav_item";

const BookingNavItem = (props) => {
  const { t } = useTranslation("translation", { keyPrefix: "navBar.items" });
  return (
    <NavItem
      icon={<BookingIcon width={23} height={23} />}
      isActiveTest={(path) => path.match(/^\/booking/)}
      {...props}
      name={t("booking")}
      id="booking"
      href="/booking"
    />
  );
};

export default BookingNavItem;
