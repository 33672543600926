import ConfirmModal from "PFComponents/confirm/confirm_modal";
import { Icon } from "PFComponents/icon";
import { LoadingDots } from "PFComponents/loading_dots";
import AutoSelect from "PFComponents/select/autoselect";
import { Typography } from "PFComponents/typography";
import { fetchProfiles } from "PFCore/services/profile";
import { Activity, IMatchScores, Profile } from "PFTypes";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ProfileItem } from "../../../components/profile_item";
import { FormBooking } from "../booking_form/use_booking_form";
import { BookingsToReassignSelector, ReassignSelectOption } from "./bookings_to_reassign_selector";
import { MatchScoreSection } from "./match_score_section";
import { ProfileItemDisplay } from "./profile_item_display";
import css from "./reassign_booking_modal.module.scss";

type SelectedUserData = {
  profile: Profile | undefined;
  scores?: IMatchScores;
};

type ReassignBookingModalProps = {
  activity: Activity;
  profileFrom: Profile | undefined;
  profileTo?: SelectedUserData;
  readOnlyMode?: boolean;
  reassignOptionSelected: ReassignSelectOption;
  setReassignOptionSelected: Dispatch<SetStateAction<ReassignSelectOption>>;
  onClose?: () => void;
  onSubmit: () => Promise<void>;
  shouldDisplayReassignOptions: boolean;
  thisBooking: FormBooking;
  isLoading?: boolean;
};

export const ReassignBookingModal = ({
  activity,
  profileFrom,
  profileTo,
  readOnlyMode,
  reassignOptionSelected,
  setReassignOptionSelected,
  onSubmit,
  onClose,
  shouldDisplayReassignOptions,
  thisBooking,
  isLoading
}: ReassignBookingModalProps) => {
  const { t } = useTranslation("bookingModule");
  const [selectedUser, setSelectedUser] = useState<SelectedUserData | null>(profileTo ?? null);

  useEffect(() => {
    setSelectedUser(profileTo ?? null);
  }, [profileTo]);

  // TODO: [PROF-3444] Display matching scores section when the API/HAL part is ready
  const displayMatchingScoreSection = false;
  const roleName = activity?.name ?? "";

  return (
    <ConfirmModal
      title={t("bookings.reassign.title", { roleName })}
      labelOK={t("bookings.reassign.reassignAction")}
      handleOK={onSubmit}
      handleClose={onClose}
      style={{ maxWidth: 715 }}
    >
      <div className={css.root}>
        <Typography variant="bodyRegular">{t("bookings.reassign.question")}</Typography>
        {shouldDisplayReassignOptions && !!profileFrom && (
          <BookingsToReassignSelector
            profileId={profileFrom.id}
            activityId={activity.id}
            thisBooking={thisBooking}
            reassignOptionSelected={reassignOptionSelected}
            setReassignOptionSelected={setReassignOptionSelected}
          />
        )}
        {isLoading || !profileFrom || !selectedUser?.profile ? (
          <LoadingDots circlesEnabled circleSize="xs" />
        ) : (
          <div className={css.reassignContainer}>
            <ProfileItemDisplay profile={profileFrom} />
            <div>
              <Icon name="reassign" />
            </div>

            <div className={css.personToContainer}>
              {readOnlyMode ? (
                selectedUser && <ProfileItemDisplay profile={selectedUser.profile} />
              ) : (
                <div className={css.personTo}>
                  <AutoSelect
                    displayValues={
                      selectedUser ? <ProfileItemDisplay profile={selectedUser.profile} /> : null
                    }
                    rootClassName={css.selectInput}
                    multi={false}
                    disabled={readOnlyMode}
                    closeOnChange
                    values={selectedUser ? [selectedUser.profile] : null}
                    query={(term) => {
                      const filters = term ? { fields: { term: term } } : {};
                      return fetchProfiles({ filters, page: 1, per_page: 20, full: true });
                    }}
                    formatOption={({ scores, profile, profiles, displayElement, search_id, total }) => ({
                      id: profile?.id,
                      displayElement: <ProfileItem profile={profile} />,
                      item: profile ? { profile, scores } : { profiles, displayElement, search_id, total }
                    })}
                    handleChange={(user) => {
                      setSelectedUser(user[0]);
                    }}
                    parseResponse={(resp) => (Array.isArray(resp.entries) ? resp.entries : resp)}
                    required
                  />
                </div>
              )}

              {displayMatchingScoreSection && (
                <div className={css.matchScoresSection}>
                  {selectedUser && <MatchScoreSection scores={selectedUser.scores} />}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </ConfirmModal>
  );
};
