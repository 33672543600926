import { useQuery } from "@tanstack/react-query";
import { fetchCertificate } from "PFApp/profiles/services/api";
import { Certificate } from "PFTypes/certificate";
import { Id } from "PFTypes/id";

import { certificateKeys } from "./query_keys";

export const useCertificate = (id: Id, queryOptions = {}) =>
  useQuery<Certificate>({
    queryKey: certificateKeys.single(id),
    queryFn: () => fetchCertificate(id) as unknown as Promise<Certificate>,
    ...queryOptions
  });
