import { useDeterministicStringify } from "PFCore/helpers/use_deterministic_stringity";
import { Filter, Filters, Value } from "PFCore/types/filters";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

import { handleRequestFiltersChange, handleRequestFiltersClear } from "./format_filters";
import { checkFiltersAreDefault, getAPIFilters } from "./helpers";

export type UseFiltersReturn = {
  selectedFilters: Filters<Value>;
  noFiltersSelected: boolean;
  updateFilter: (filter: Filter, value: Value, options?: { children?: boolean; clear?: boolean }) => void;
  setFilters: (filters: Filters<Value>) => void;
  resetFilters: (params?: unknown) => void;
  clearFilters: (params?: unknown) => void;
};

export type UseFiltersArgs = {
  selectedFilters: Filters<Value>;
  setSelectedFilters: (updatedFilters: Filters<Value>) => void;
  defaultValue?: Filters<Value>;
};

export const useFilters = ({
  selectedFilters,
  setSelectedFilters,
  defaultValue = {}
}: UseFiltersArgs): UseFiltersReturn => {
  const history = useHistory<{ filters: Filters<Value> }>();
  const stringify = useDeterministicStringify();

  if (history.location.state?.filters) {
    setSelectedFilters({ ...selectedFilters, ...history.location.state?.filters });
    // @ts-ignore
    history.replace();
  }

  const noFiltersSelected = useMemo(
    () => checkFiltersAreDefault(selectedFilters, defaultValue),
    [stringify(getAPIFilters(selectedFilters))]
  );

  const updateFilter = (filter, value, { clear, children } = { clear: false, children: false }) => {
    const { children: childrenFilters, ...rootFilters } = selectedFilters;
    const newFilters = clear
      ? handleRequestFiltersClear(children ? selectedFilters.children : rootFilters)
      : handleRequestFiltersChange(children ? selectedFilters.children : rootFilters, filter, value);
    setSelectedFilters(
      children ? { ...rootFilters, children: newFilters } : { ...newFilters, children: childrenFilters }
    );
  };

  const setFilters = (filters) => {
    //change all filters at once
    setSelectedFilters(filters);
  };

  const resetFilters = (params = {}) => {
    setSelectedFilters({ ...defaultValue, ...params });
  };

  const clearFilters = useCallback(
    (params = {}) => {
      setSelectedFilters(params);
    },
    [setSelectedFilters]
  );

  return {
    selectedFilters,
    noFiltersSelected,
    updateFilter,
    setFilters,
    resetFilters,
    clearFilters
  };
};
