import classNames from "classnames";
import BookingsTotal from "PFApp/booking/parts/overview/bookings_total";
import { useBookingActivityContext } from "PFApp/booking/parts/providers/booking_activity_context_provider";
import { useRoleJobCode } from "PFApp/use_role_job_code";
import { LoadingDots } from "PFComponents/loading_dots";
import { getProfileName } from "PFCore/helpers/profile";
import { useBookingActivity } from "PFCore/hooks/queries/activity/use_booking_activity";
import { useBookingTemplate } from "PFCore/hooks/queries/bookings/booking_templates/use_booking_template";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Profile } from "PFTypes";
import { Booking, BookingCategory } from "PFTypes/booking";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import css from "../../detail_view.module.scss";
import { BookingDetailsActivity } from "../booking_details_activity";
import { BookingDetailCategorySettings } from "./booking_detail_category_settings";
import { BookingDetailDuration } from "./booking_detail_duration";
import { BookingDetailItem } from "./booking_detail_item";
import { BookingDetailRepeats } from "./booking_detail_repeats";
import { BookingDetailTotalLoad } from "./booking_detail_total_load";
import { BookingDetailCategory } from "./booking_details_category";

type BookingDetailContentProps = {
  booking?: Booking;
  category: BookingCategory;
  loading: boolean;
  profile: Profile | undefined;
};

const BookingDetailContent = ({
  booking,
  profile,
  category,
  loading
}: BookingDetailContentProps): JSX.Element => {
  const { activity, isLoading: isActivityLoading } = useBookingActivityContext();
  const {
    data: parentActivity,
    isLoading: isParentActivityLoading,
    isError: isParentActivityError
  } = useBookingActivity(activity?.parent_activity_id);

  const { formatDate } = useDateFormatter();
  const { t } = useTranslation(["translation", "bookingModule"]);

  const {
    start_date: startDate,
    end_date: endDate,
    description,
    created_at: createdAt,
    booking_template_id: bookingTemplateId,
    phase_source_id: phaseSourceId,
    metadata: { booking_ref: bookingRef, job_code: jobCode }
  } = booking || { metadata: {} };
  const { id: activityId } = activity || {};

  const { data: bookingTemplate } = useBookingTemplate(bookingTemplateId, {
    enabled: Number.isInteger(bookingTemplateId)
  });

  const jobCodeDisplayAs = useRoleJobCode();
  const jobCodeValue = jobCode || activityId;

  if (!booking || loading || (!!booking.activity && isActivityLoading)) {
    return <LoadingDots />;
  }

  return (
    <>
      <div className={css.root}>
        <BookingDetailCategory category={category} />
        <BookingDetailCategorySettings
          billable={category.billable}
          affectsAvailability={!!category.affects_availability}
        />
        {activity && (
          <>
            <div className={css.separator} />
            <div className={classNames(css.activities)}>
              <BookingDetailsActivity activity={activity} parentActivity={parentActivity} />
              {!isParentActivityError && (
                <BookingDetailsActivity activity={parentActivity} isLoading={isParentActivityLoading} />
              )}
            </div>
            <div className={css.separator} />
          </>
        )}
        <BookingDetailDuration startDate={startDate} endDate={endDate} phaseSourceId={phaseSourceId} />
        {bookingTemplate && (
          <BookingDetailRepeats
            startDate={bookingTemplate.start_date}
            endDate={bookingTemplate.end_date}
            wdayMask={bookingTemplate.wday_mask}
          />
        )}
        <div className={css.totalContainer}>
          <BookingDetailTotalLoad load={booking.calculated_load} />
          <BookingsTotal className={css.itemText} bookings={[booking]} />
        </div>

        {description && <BookingDetailItem label={t("translation:description")} value={description} />}
        {jobCodeValue && (
          <BookingDetailItem
            label={t(jobCodeDisplayAs as unknown as TemplateStringsArray)}
            value={jobCodeValue}
          />
        )}
        {bookingRef && <BookingDetailItem label={t("bookingModule:details.bookingRef")} value={bookingRef} />}
        <BookingDetailItem
          label={t("bookingModule:details.workforceMember")}
          value={
            profile ? (
              <Link to={`/profiles/${profile.id}`}>
                <span>{getProfileName(profile)}</span>
              </Link>
            ) : (
              <LoadingDots />
            )
          }
        />
        <BookingDetailItem label={t("translation:createdAt")} value={formatDate(createdAt)} />
      </div>
    </>
  );
};

export default BookingDetailContent;
