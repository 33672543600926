import { ratingToValue } from "PFApp/profiles/show/parts/section_skills/constants";
import Checkbox from "PFComponents/checkbox/checkbox";
import SkillRatingIcon from "PFComponents/icons/skill_rating_icon";
import CheckIcon from "PFIcons/check.svg";

import { SkillToRank, useFeedbackFormContext } from "../../feedback_form_context_provider";
import { useFeedbackFormMutations } from "../../hooks/use_feedback_form";
import css from "./skills_evaluation.module.scss";

type SkillRowProps = {
  skill: SkillToRank;
  readOnly?: boolean;
};

export const SkillRow = ({ skill, readOnly }: SkillRowProps): JSX.Element => {
  const { formData } = useFeedbackFormContext();
  const { handleRankChange, handleActivation } = useFeedbackFormMutations();
  const skillId = skill.id || skill.globalId;
  const skillRank =
    ratingToValue[
      formData?.skills.find((rankedSkill) => (rankedSkill.id || rankedSkill.globalId) === skillId)?.experience
    ];

  return (
    <div className={css.skillRow}>
      <div className={css.skillCheckboxCell}>
        {readOnly ? (
          <CheckIcon width={10} height={10} />
        ) : (
          <Checkbox
            checked={!!skill.isActive}
            handleChange={() => handleActivation(skill, !skill.isActive)}
          />
        )}
      </div>
      <div className={css.skillNameCell}>{skill.name}</div>
      <div className={css.skillRankCell}>
        <SkillRatingIcon
          updateExperience={(newRank) => handleRankChange(newRank, skillId)}
          value={skillRank}
          isEditMode={!readOnly}
        />
      </div>
    </div>
  );
};
