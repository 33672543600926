import ConfirmModal from "PFComponents/confirm/confirm_modal";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import css from "./delete_note_modal.module.scss";

interface IProps {
  closeModal: () => void;
  handleOK?: (id: number) => void;
  targetElementId: string;
}

const DeleteNoteModal = ({ closeModal, handleOK, targetElementId }: IProps) => {
  const { t } = useTranslation(["translation", "bookingModule"]);
  return (
    <ConfirmModal
      title={t("bookingModule:details.notes.deleteNote")}
      labelOK={t("translation:delete")}
      handleOK={handleOK}
      handleClose={closeModal}
      kindOK="danger"
      targetElementId={targetElementId}
      classes={{
        backdrop: css.modalBackdrop,
        fadeIn: css.modalFadeIn,
        modal: css.modalRoot
      }}
    >
      {t("bookingModule:details.notes.areYouSureToDeleteNote")}
    </ConfirmModal>
  );
};

DeleteNoteModal.propTypes = {
  closeModal: PropTypes.func,
  handleOK: PropTypes.func,
  targetElementId: PropTypes.string
};

export default DeleteNoteModal;
