import AuditPlannerIcon from "PFIcons/audit_planner.svg";
import { useTranslation } from "react-i18next";

import NavItem from "./nav_item";

const AuditPlannerNavItem = (props) => {
  const { t } = useTranslation("translation", { keyPrefix: "navBar.items" });
  return (
    <NavItem
      icon={<AuditPlannerIcon width={20} height={20} />}
      isActiveTest={(path) => path.match(/^\/audit_planner/)}
      {...props}
      name={t("auditPlanner")}
      id="audit_planner"
      href="/audit_planner"
    />
  );
};

export default AuditPlannerNavItem;
