/* eslint-disable camelcase */
import { ApiRoute } from "PFCore/utilities/routes";

import { BookingNote } from "../parts/overview/detail_view/booking_detail/booking_notes/types";
import jqXHR = JQuery.jqXHR;
import api from "PFCore/api";
import { fetchPaginatedCollection } from "PFCore/helpers/collection";

type FetchBookingNotesRequest = {
  bookingId: number;
  perPage?: number;
  page?: number;
};
type FetchBookingNotesExternalRequest = {
  profileId: number;
  bookingId: number;
  perPage?: number;
  page?: number;
};
type AddBookingNoteRequest = { bookingId: number; body: string };
type DeleteBookingNoteRequest = { bookingId: number; id: number };
type EditBookingNoteRequest = {
  bookingId: number;
  id: number;
  body: string;
};
export type IBookingNotesResponse = {
  entries: BookingNote[];
  meta: {
    total: number;
    totalPages: number;
    page: number;
  };
};

export const fetchBookingNotes = ({
  bookingId: booking_id,
  perPage = 15,
  page = 1
}: FetchBookingNotesRequest): jqXHR<IBookingNotesResponse> =>
  /* @ts-ignore */
  fetchPaginatedCollection(ApiRoute(`/api/booking_module/bookings/${booking_id}/booking_notes`), {
    perPage,
    page
  });

export const fetchBookingNotesExternal = ({
  profileId,
  bookingId,
  perPage = 15,
  page = 1
}: FetchBookingNotesExternalRequest): jqXHR<IBookingNotesResponse> =>
  /* @ts-ignore */
  fetchPaginatedCollection(ApiRoute(`/api/profiles/${profileId}/booking_notes/${bookingId}`), {
    perPage,
    page
  });

export const addBookingNote = ({ bookingId: booking_id, body }: AddBookingNoteRequest): Promise<void> =>
  api({
    url: `booking_module/bookings/${booking_id}/booking_notes`,
    method: "POST",
    data: { body }
  });

export const editBookingNote = ({
  bookingId: booking_id,
  id,
  body
}: EditBookingNoteRequest): jqXHR<BookingNote> =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute(`/api/booking_module/bookings/${booking_id}/booking_notes/${id}`),
    method: "PUT",
    api_version: 2,
    dataType: "json",
    processData: false,
    contentType: "application/json",
    data: JSON.stringify({ body })
  });

export const deleteBookingNote = ({ bookingId: booking_id, id }: DeleteBookingNoteRequest) =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute(`/api/booking_module/bookings/${booking_id}/booking_notes/${id}`),
    method: "DELETE",
    api_version: 2
  });
