import { useQuery } from "@tanstack/react-query";
import { fetchWorkforce } from "PFApp/booking/services/api";
import { getAPIFilters } from "PFApp/use_filtered_collection";
import { PaginatedCollection, Profile, QueryOptions } from "PFTypes";

import { workforceKeys } from "./query_keys";

export type WorkforceItem = {
  profile: Pick<Profile, "id" | "first_name" | "last_name"> & { has_bookings: boolean } & Record<string, any>;
  scores?: Record<string, any>;
  id: number;
  optional_fields: Record<string, any>;
};

export type WorkforceResponse = PaginatedCollection<
  WorkforceItem[],
  { total_billable_utilization?: number; dynamic_wm_info_custom_types?: Record<number, string> }
>;

export const useWorkforceEntries = (
  page: number = 1,
  perPage: number = 15,
  order: any,
  filters: any,
  dateRange: any,
  options: QueryOptions<WorkforceResponse> = {}
) =>
  useQuery<WorkforceResponse, { status: number }>({
    queryKey: workforceKeys.withParameters(filters, order, dateRange, page, perPage),
    queryFn: () =>
      fetchWorkforce(
        page,
        perPage,
        order,
        getAPIFilters(filters),
        dateRange
      ) as unknown as Promise<WorkforceResponse>,
    retry: false,
    ...options
  });
