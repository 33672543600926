import AppContext from "PFApp/app_context";
import { SEARCH_ACTIONS } from "PFReducers/search_reducer/search_reducer";
import { useContext } from "react";

import KeywordsSliders from "./keywords_sliders";

const SearchKeywordSliders = () => {
  const {
    dispatch,
    store: {
      search: { choosenKeywords, matchTypes }
    }
  } = useContext(AppContext);

  return (
    <KeywordsSliders
      search
      choosenKeywords={choosenKeywords}
      matchableTypes={matchTypes}
      remove={(keyword) => {
        dispatch({ type: SEARCH_ACTIONS.SEARCH_REMOVE_CHOOSEN_KEYWORD, payload: keyword });
      }}
      pushWithNewWeight={(keyword, weight) => {
        dispatch({ type: SEARCH_ACTIONS.SEARCH_PUSH_CHOOSEN_KEYWORD, payload: { ...keyword, weight } });
      }}
      handleAddOne={(payload) => {
        dispatch({ type: SEARCH_ACTIONS.SEARCH_PUSH_CHOOSEN_KEYWORD, payload });
      }}
    />
  );
};

export default SearchKeywordSliders;
