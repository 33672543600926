import { isNil } from "lodash";
import { BookingRequirementData, RequirementType } from "PFTypes";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { DEFAULT_LOAD_REQUIREMENT, mapBookingDataToRequirement } from "./requirement_selector";

export type Requirement = {
  type: RequirementType;
  value?: number;
};

interface UseRequirement {
  booking: BookingRequirementData;
  onRequirementChange: (requirement: Requirement) => void;
}

interface UseRequirementReturn {
  requirement: Requirement;
  defaultRequirementValues: Partial<Record<RequirementType, number>>;
  handleRequirementTypeChange: (type: RequirementType) => void;
  handleRequirementValueChange: (value: number) => void;
  error: string | null;
}

export const useRequirement = ({ booking, onRequirementChange }: UseRequirement): UseRequirementReturn => {
  const { t } = useTranslation("bookingModule", { keyPrefix: "bookings" });
  const defaultRequirement = useMemo(() => mapBookingDataToRequirement(booking), [booking]);
  const [requirement, setRequirement] = useState<Requirement>(defaultRequirement);
  const [error, setError] = useState<string | null>(null);

  const defaultRequirementValues: Partial<Record<RequirementType, number>> = useMemo(
    () => ({
      [RequirementType.Load]:
        booking.requirement_type === RequirementType.Load && !isNil(booking?.requirement_value)
          ? booking.requirement_value
          : DEFAULT_LOAD_REQUIREMENT,
      [defaultRequirement.type]: defaultRequirement.value
    }),
    [booking, defaultRequirement]
  );

  const handleRequirementTypeChange = (type: RequirementType) => {
    setError(defaultRequirementValues[type] === 0 ? t("requirementError") : null);
    setRequirement({
      type,
      value: defaultRequirementValues[type]
    });
  };

  const handleRequirementValueChange = (value: number | undefined) => {
    setError(value === 0 ? t("requirementError") : null);
    setRequirement((prev) => ({
      ...prev,
      value
    }));
  };

  useEffect(() => {
    onRequirementChange(requirement);
  }, [requirement]);

  return {
    requirement,
    defaultRequirementValues,
    handleRequirementTypeChange,
    handleRequirementValueChange,
    error
  };
};
