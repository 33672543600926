import { Profile } from "PFTypes";
import { useMemo } from "react";

import { useMatchCardContext } from "../match_card_context";

export const useIsMatchTrainee = () => {
  const { profile } = useMatchCardContext();

  // TODO: [PROF-3865] matched_by is not present in ShortlistProfile until the linked task is done
  const matchedBy = useMemo(() => (profile as Profile)?.matched_by || [], [profile]);

  return useMemo(() => matchedBy.includes("developmental"), [matchedBy]);
};
