import classNames from "classnames";
import EditInternalExperienceForm from "PFApp/profiles/common/experience/edit_internal_experience_form";
import { ActionIcon } from "PFComponents/action_icon";
import CustomValuesList from "PFComponents/custom_values_list/custom_values_list";
import { Markdown } from "PFComponents/markdown";
import { useReadableDuration } from "PFCore/helpers/use_readable_duration";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import DownArrowIcon from "PFIcons/down_arrow.svg";
import { Activity, CustomType, Profile } from "PFTypes";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { InternalExperienceFeedback } from "./internal_experience_feedback";
import css from "./section_experience.modules.scss";

type InternalExperienceProps = {
  experience: Activity;
  customTypes: CustomType[];
  canEdit: boolean;
  isCfPermitted: (cfName: string) => boolean;
  fetchInternalExperience: () => void;
  profile: Profile;
};

type InfoSectionProps = {
  label: string;
  content: React.ReactNode | string;
  collapsedSection?: boolean;
};

export const InfoSection = ({ label, content, collapsedSection }: InfoSectionProps) => (
  <>
    <div className={classNames(css.internalTitle, { [css.displayHidden]: collapsedSection })}>{label}</div>
    <div className={classNames(css.internalDescription, { [css.displayHidden]: collapsedSection })}>
      {content}
    </div>
  </>
);

const InternalExperienceComponent = ({
  experience,
  customTypes,
  canEdit,
  isCfPermitted,
  fetchInternalExperience,
  profile
}: InternalExperienceProps) => {
  const { t } = useTranslation(["profiles", "translation"]);
  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();
  const [collapsedSection, setCollapsedSection] = useState(true);
  const [collapsedDescription, setCollapsedDescription] = useState(true);
  const customFields = useMemo(() => experience.custom_fields || [], [experience.custom_fields]);

  const [editMode, setEditMode] = useState(false);
  const readableDuration = useReadableDuration({
    duration: experience.metadata.date_range
  });

  const CollapseButton = () => (
    <button
      className={classNames(css.noStyleButton, css.collapseButton)}
      onClick={() => setCollapsedDescription((prev) => !prev)}
    >
      {collapsedDescription ? t("translation:more") : t("translation:less")}
    </button>
  );

  const experienceCustomFields = useMemo(() => {
    const experienceCustomFields = {};

    customFields.forEach((customField) => {
      const customType = (customTypes || []).find((customType) => customType.name === customField.type.name);
      if (customType?.name) {
        experienceCustomFields[customType.name] = {
          type: customType,
          values: customField.values
        };
      }
    });
    return experienceCustomFields;
  }, [customTypes, customFields]);

  const allCustomFields = Object.keys(experienceCustomFields);

  return (
    <div
      className={classNames(css.row, css.internalPositionDetails)}
      key={`${experience.id}`}
      data-scroll-anchor={`internal-experience-${experience.id}`}
    >
      <div
        className={classNames(css.dateTitle, {
          [css.noDatePresent]: !readableDuration
        })}
      >
        {readableDuration}
        <div className={css.internalArrow}>
          <button
            className={classNames(css.noStyleButton, { [css.rotatedIcon]: collapsedSection })}
            onClick={() => setCollapsedSection((prev) => !prev)}
          >
            <DownArrowIcon height={24} width={24} className={css.arrowIcon} />
          </button>
        </div>
      </div>
      {canEdit && (
        <ActionIcon
          onClick={() =>
            setEditMode((prev) => {
              if (collapsedSection) {
                setCollapsedSection(false);
              }
              return !prev;
            })
          }
          name={editMode ? "cross" : "edit"}
          size="sm"
          classes={{ root: css.editIcon }}
        />
      )}
      <div className={css.rowWrapper}>
        <div
          className={classNames(css.gridContainer, {
            [css.gridContainerCollapsed]: collapsedSection,
            [css.gridEditMode]: editMode
          })}
        >
          {editMode ? (
            <EditInternalExperienceForm
              experience={experience}
              profile={profile}
              adminPage={false}
              handleSuccess={fetchInternalExperience}
              handleRemove={() => {
                fetchInternalExperience();
                setEditMode(false);
              }}
            />
          ) : (
            <>
              <div className={css.internalTitle}>{t("translation:role")}</div>
              <div className={css.internalDescription}>{experience.name}</div>
              {allCustomFields.map(
                (name) =>
                  isCfPermitted(name) && (
                    <InfoSection
                      key={name}
                      label={name}
                      content={
                        <CustomValuesList
                          type={experienceCustomFields[name]}
                          customValues={experienceCustomFields[name].values.map((value) => ({
                            ...value,
                            className: "light"
                          }))}
                          profileId={profile.id}
                          currentProfile={currentProfile}
                          currentAccount={currentAccount}
                          moreLimit={6}
                          showMoreButton
                        />
                      }
                      collapsedSection={collapsedSection}
                    />
                  )
              )}
              {experience.metadata.location && (
                <InfoSection
                  label={t("profiles:show.parts.location")}
                  content={experience.metadata.location}
                  collapsedSection={collapsedSection}
                />
              )}
              <InfoSection
                label={t("profiles:show.parts.description")}
                content={
                  <>
                    <Markdown
                      raw={experience.description}
                      crop={collapsedDescription ? 100 : 1e10}
                      displayToggle={false}
                      emojify={false}
                    />
                    {experience.description && experience.description.length > 100 && <CollapseButton />}
                  </>
                }
                collapsedSection={collapsedSection}
              />
              {experience.metadata.engagement_hours && (
                <InfoSection
                  label={t("profiles:show.parts.hours")}
                  content={experience.metadata.engagement_hours}
                  collapsedSection={collapsedSection}
                />
              )}
              {!collapsedSection && experience.parent_activity_id && (
                <InternalExperienceFeedback roleId={experience.parent_activity_id} profileId={profile.id} />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InternalExperienceComponent;
