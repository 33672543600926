import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import { useActivityVacancies } from "PFCore/hooks/queries/activity/use_activity_vacancies";
import { useProfiles } from "PFCore/hooks/queries/profiles/use_profiles";
import { useShortlistsInvalidate } from "PFCore/hooks/queries/shortlists/use_shortlists_invalidate";
import { closeVacancy, reopenVacancy } from "PFCore/services/vacancies";
import { useCallback, useMemo, useState } from "react";

import { activityContextPlaceholder } from "../activity_page_context";
import { useActivityPermissions } from "./use_activity_permissions";

const emptyVacanciesProfiles = { entries: [], meta: { total: 0, page: 1, perPage: 10, totalPages: 1 } };
export const useActivityPageVacancies = (task) => {
  const defaultParams = { page: 1, perPage: 5, activityId: task.id };
  const [params, setParams] = useState(defaultParams);

  const { canSeeVacancies } = useActivityPermissions(task);

  const { data: vacancies, isLoading } = useActivityVacancies(params, {
    placeholderData: activityContextPlaceholder.vacancies,
    enabled: canSeeVacancies
  });
  const vacanciesFilledProfiles = useMemo(
    () => (vacancies?.entries || []).filter((item) => item.state !== "opened"),
    [vacancies]
  );

  const filledProfilesIds = useMemo(() => {
    const filledProfiles = (vacancies?.entries || []).filter((item) => item.state !== "opened");

    return filledProfiles.filter((item) => item.profileId).map((item) => item.profileId);
  }, [vacancies]);

  const { data: vacanciesProfiles } = useProfiles(
    {
      filters: {
        fields: { id: filledProfilesIds }
      },
      perPage: filledProfilesIds.length
    },
    {
      placeholderData: emptyVacanciesProfiles,
      enabled: filledProfilesIds.length > 0
    }
  );

  const { invalidateShortlists, invalidateRankedShortlists } = useShortlistsInvalidate();
  const { invalidate: invalidateActivities, invalidateVacancies } = useActivityInvalidate();

  const closeVacancies = (selectedReasons, closureDetails) =>
    Promise.allSettled(
      Object.keys(selectedReasons).map((vacancyId) => {
        const reason = selectedReasons[vacancyId];
        if (reason) {
          const isProfileId = reason === "filled_with_named_resource";
          const details = closureDetails[vacancyId];
          const detailsKey = isProfileId ? "profileId" : "closureDetails";

          return closeVacancy({
            activityId: task.id,
            vacancyId,
            closureReason: reason,
            [detailsKey]: details
          });
        }
      })
    ).then((response) => {
      invalidateActivities([task.id]);
      invalidateShortlists(task.id);
      invalidateRankedShortlists(task.id);
      setTimeout(() => invalidateVacancies(task.id), 500); // closing vacancies needs a moment to process

      return response;
    });

  const reopenVacancies = (vacancyId) =>
    reopenVacancy({
      activityId: task.id,
      vacancyId
    }).then(() => {
      invalidateShortlists(task.id);
      invalidateRankedShortlists(task.id);
      invalidateVacancies(vacancyId);
      invalidateActivities([task.id]);
    });

  const existsOpenVacancy = !!vacancies?.entries.some((vacancy) => vacancy.state === "opened");

  const fetchRolesVacanciesPage = useCallback(
    (page: number = defaultParams.page, perPage: number = defaultParams.perPage) =>
      setParams({
        ...defaultParams,
        page,
        perPage
      }),
    [setParams]
  );
  return {
    vacancies: vacancies || activityContextPlaceholder.vacancies,
    vacanciesProfiles: vacanciesProfiles || emptyVacanciesProfiles,
    vacanciesFilledProfiles,
    fetchRolesVacanciesPage,
    closeVacancies,
    reopenVacancies,
    loaded: !!vacancies,
    loading: isLoading,
    existsOpenVacancy
  };
};
