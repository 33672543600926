/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from "classnames";
import { KEY_ESC, KEY_TAB } from "PFComponents/text/keys";
import { useFocusableElements } from "PFCore/helpers/use_focusable_elements";
import PropTypes from "prop-types";
import { cloneElement, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

import css from "./modal.less";

const Modal = ({
  style,
  children,
  qaId,
  closeOnBackdropClick,
  handleBeforeClose,
  noPadding = false,
  targetElementId,
  classes,
  ...otherProps
}) => {
  const [fadeIn, setFadeIn] = useState();
  const fadeInTransitionId = useRef(undefined);
  const isUnmounted = useRef(false);
  const modalRef = useRef(null);
  const focusableElements = useRef([]);
  const previousFocusedElement = useRef(null);
  const getFocusablElements = useFocusableElements(modalRef.current);

  const modalTarget = document.getElementById(targetElementId);

  // Handle fade in
  useEffect(() => {
    fadeInTransitionId.current = window.setTimeout(() => {
      if (isUnmounted.current) {
        return;
      }
      setFadeIn(true);
    }, 50);

    return () => {
      isUnmounted.current = true;
      clearTimeout(fadeInTransitionId.current);
    };
  }, []);

  const handleClose = (e) => {
    const doClose = () => {
      setFadeIn(false);
      window.setTimeout(() => {
        if (isUnmounted.current) {
          return;
        }
        otherProps.handleClose && otherProps.handleClose(e);
      }, 600);
    };

    if ((handleBeforeClose && handleBeforeClose(doClose)) === false) {
      return;
    }

    doClose();
  };

  // Handle focus locking
  useEffect(() => {
    if (modalRef.current && getFocusablElements()) {
      const updatefocusableElements = () => {
        focusableElements.current = getFocusablElements();
      };

      previousFocusedElement.current = document.activeElement;
      const observer = new MutationObserver(() => {
        updatefocusableElements();
      });
      updatefocusableElements();
      observer.observe(modalRef.current, { childList: true });

      if (focusableElements.current.length > 0) {
        focusableElements.current[0].focus();
      }

      return () => {
        observer.disconnect();
        previousFocusedElement.current.focus();
      };
    }
  }, [modalRef.current]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (!focusableElements.current) {
        return;
      }

      const { keyCode, shiftKey } = e;
      const { length, 0: firstElement, [length - 1]: lastElement } = focusableElements.current;

      if (keyCode === KEY_TAB) {
        if (length === 1 || length === 0) {
          escape.preventDefault();
          return;
        }

        // If focused on last item then focus on first item when tab is pressed
        if (!shiftKey && document.activeElement === lastElement) {
          e.preventDefault();
          firstElement.focus();
          return;
        }

        // If focused on first item then focus on last item when shift + tab is pressed
        if (shiftKey && document.activeElement === firstElement) {
          e.preventDefault();
          lastElement.focus();
          return;
        }
      } else if (keyCode === KEY_ESC) {
        handleClose(e);
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [focusableElements]);

  const childWithClose = cloneElement(children, { ...otherProps, handleClose });

  if (!modalTarget) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      data-qa-id={qaId}
      className={classNames(css.backdrop, { [css.in]: fadeIn }, classes.backdrop)}
      onClick={(event) => {
        event.stopPropagation();
        if (closeOnBackdropClick) {
          handleClose();
        }
      }}
    >
      <div
        className={classNames(
          css.modal,
          { [css.in]: fadeIn, [css.noPadding]: noPadding, [classes.fadeIn]: fadeIn },
          classes.modal
        )}
      >
        {/* eslint-disable */}
        <div
          style={{
            ...style
          }}
          className={classes.dialog}
          aria-modal
          role="dialog"
          ref={modalRef}
          onClick={closeOnBackdropClick ? (e) => e.stopPropagation() : null}
        >
          {childWithClose}
        </div>
      </div>
    </div>,
    document.getElementById(targetElementId)
  );
};

export default Modal;

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  closeOnBackdropClick: PropTypes.bool,
  qaId: PropTypes.string,
  style: PropTypes.object,
  handleClose: PropTypes.func,
  handleBeforeClose: PropTypes.func,
  noPadding: PropTypes.bool,
  targetElementId: PropTypes.string,
  classes: PropTypes.shape({
    backdrop: PropTypes.string,
    modal: PropTypes.string,
    dialog: PropTypes.string,
    fadeIn: PropTypes.string
  })
};

Modal.defaultProps = {
  closeOnBackdropClick: false,
  qaId: "Modal",
  handleClose: null,
  targetElementId: "modal_region",
  classes: {}
};
