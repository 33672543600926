import classNames from "classnames";
import { useActivityTemplate } from "PFApp/activities/show/hooks/use_activity_template";
import AnyFilter from "PFApp/components/filters/any_filter";
import PropTypes from "prop-types";

import { filterElements, getFilterDefault, getFilterQuery } from "../../../use_filtered_collection";
import css from "./activity_filters.less";

const ActivityFilters = ({ meta, schemaPropertiesNames, onFilterChange }) => {
  const filters = filterElements(meta.filters).filter(({ name }) => schemaPropertiesNames.includes(name));

  const template = useActivityTemplate();

  return (
    <ul className={css.list}>
      {filters.map((filter) => (
        <li
          className={classNames(css.item, css[filter.name])}
          key={filter.name}
          data-qa-id={`filter--${filter.name}`}
        >
          <AnyFilter
            filter={{
              ...filter,
              query: getFilterQuery(filter, meta),
              defaultValue: getFilterDefault(filter, meta)
            }}
            handleChange={(value, valueObject) => onFilterChange(filter, value, valueObject)}
            scope={0} /* We don't want to limit scope on the template*/
            templateKey={template?.key}
          />
        </li>
      ))}
    </ul>
  );
};

ActivityFilters.propTypes = {
  schemaPropertiesNames: PropTypes.arrayOf(PropTypes.string),
  meta: PropTypes.object,
  onFilterChange: PropTypes.func
};

export default ActivityFilters;
