import classNames from "classnames";
import LockKeyholeIcon from "PFIcons/lock_keyhole.svg";

import BasicPill from "./basic_pill";
import css from "./pill.module.scss";

interface IProps {
  withIcon: boolean;
  style?: object;
  className?: string;
  onClick?: (event) => void;
  children: React.ReactNode;
}

export const NO_CATEGORY_COLOR = "#D9D9D9"; //--Color-gray-8

const NoCategoryBookingPill = ({ withIcon, style, className, onClick, children }: IProps) => (
  //@ts-ignore
  <BasicPill
    className={classNames(css.noCategoryBookingPill, className)}
    style={{ ...style, background: NO_CATEGORY_COLOR }}
    onClick={onClick}
  >
    {withIcon && <LockKeyholeIcon className={css.noCategoryBookingPillIcon} />}
    {children}
  </BasicPill>
);

export default NoCategoryBookingPill;
