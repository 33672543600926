import { SearchEntityData } from "PFApp/components/search_autocomplete/search_autocomplete.types";
import { useQueryParams } from "PFCore/helpers/use_query_params";
import { useEffect, useState } from "react";
import { KeyboardEvent } from "react";
import { useHistory } from "react-router-dom";

type UseSearchInput = {
  term: string;
  onTermChange: (text: string, entity?: SearchEntityData | null, focus?: boolean) => void;
  withUrlChanges?: boolean;
};

export const useSearchInput = ({ term, onTermChange, withUrlChanges = true }: UseSearchInput) => {
  const history = useHistory();
  const searchParams = useQueryParams();
  const [localTerm, setLocalTerm] = useState(term);

  useEffect(() => {
    setLocalTerm(term ?? "");
  }, [term]);

  const updateUrl = (term: string) => {
    if (!withUrlChanges) {
      return;
    }
    term ? searchParams.set("term", term) : searchParams.delete("term");
    history.replace({ search: searchParams.toString() });
  };

  const handleSubmit = () => {
    updateUrl(localTerm);
    onTermChange(localTerm);
  };

  const handleClear = () => {
    setLocalTerm("");
    onTermChange("", null, true);
    updateUrl("");
  };

  const handleSearchKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return {
    localTerm,
    handleSearchKeyDown,
    handleClear,
    handleSubmit,
    setLocalTerm
  };
};
