import api from "PFCore/api";
import { PureCustomValueCamelCased } from "PFTypes";

export type AutocompleteCustomValuesParams = {
  term?: string;
  type?: string | string[];
  global?: boolean;
  value?: any;
};

export type AutocompleteCustomValuesResponse = PureCustomValueCamelCased[];

export const fetchAutocompleteCustomValues = (
  params: AutocompleteCustomValuesParams
): Promise<AutocompleteCustomValuesResponse> =>
  api({
    url: "autocomplete/custom_values",
    headers: {
      apiVersion: 1
    },
    params
  });

export const fetchAutocompleteSkills = (params: Omit<AutocompleteCustomValuesParams, "type">) =>
  fetchAutocompleteCustomValues({ type: "skills", ...params });
