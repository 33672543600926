import { ActionIcon } from "PFComponents/action_icon";
import { Button } from "PFComponents/button";
import Calendar from "PFComponents/calendar/calendar";
import { DoubleSectionPill } from "PFComponents/double_section_pill/double_section_pill";
import { DropdownOption } from "PFComponents/dropdown/dropdown";
import { Icon } from "PFComponents/icon";
import Tooltip from "PFComponents/tooltip/tooltip";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { CustomValue } from "PFTypes";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { usePopper } from "react-popper";

import css from "./calendar_badge.module.scss";

export type CalendarBadgeValue = Omit<DropdownOption, "item"> & { item: CustomValue };

export type CalendarBadgeProps = {
  displayValue: string;
  onRemove: (value: CalendarBadgeValue, event: SyntheticEvent) => void;
  value: CalendarBadgeValue;
  onDateChange: (item: CustomValue, event: SyntheticEvent) => void;
  isLocked: boolean;
  popperOptions?: object;
};

export const CalendarBadge = ({
  displayValue,
  onRemove,
  value,
  onDateChange,
  isLocked,
  popperOptions
}: CalendarBadgeProps) => {
  const [renderCalendar, setRenderCalendar] = useState(false);
  const [date, setDate] = useState<string | null>(value.item.expiry_date!);

  const badgeRef = useRef<HTMLLIElement>(null);
  const modalSelector = document.getElementById("modal_region");

  const [popperReferenceElement, setPopperReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const { formatISODate, formatDate, utc } = useDateFormatter();
  const { t } = useTranslation("core");

  const { styles, attributes } = usePopper(popperReferenceElement, popperElement, {
    placement: "top",
    strategy: "absolute",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [-2, 15]
        }
      }
    ],
    ...popperOptions
  });

  useEffect(() => {
    const handleClick = (event) => {
      if (modalSelector && !modalSelector.contains(event.target)) {
        setRenderCalendar(false);
      }
    };

    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const CalendarRenderer = (
    <div className={css.calendarWrapper} ref={setPopperElement} style={styles.popper} {...attributes.popper}>
      <Calendar
        style={{ width: 360 }}
        selectedDate={date ? formatISODate(utc(date)) : undefined}
        handleChange={(newDate, _options, event) => {
          setDate(newDate);
          setRenderCalendar(false);
          onDateChange({ ...value.item, expiry_date: formatISODate(utc(newDate)) } as CustomValue, event);
        }}
      />
    </div>
  );

  const handleDelete = isLocked ? undefined : (event) => onRemove(value, event);

  const leftContent = (
    <Tooltip content={<div>{displayValue}</div>}>
      <div className={css.displayInBadge} style={{ maxWidth: badgeRef?.current?.clientWidth }}>
        {displayValue}
      </div>
    </Tooltip>
  );

  const rightContent = (
    <>
      {date ? (
        <div className={css.date}>
          <Button
            kind="blank"
            disabled={isLocked}
            onClick={(event) => {
              setRenderCalendar((prev) => !prev);
              event.preventDefault();
            }}
            ref={setPopperReferenceElement}
          >
            {`${t("time.inWords.expiresShort")} ${formatDate(utc(date))}`}
          </Button>
          {!isLocked && (
            <ActionIcon
              size="xs"
              name="cross"
              onClick={(event) => {
                setDate(null);
                onDateChange({ ...value.item, expiry_date: null } as CustomValue, event);
              }}
            />
          )}
        </div>
      ) : (
        <Button
          ref={setPopperReferenceElement}
          kind="blank"
          tag="a"
          disabled={isLocked}
          onClick={(event) => {
            setRenderCalendar((prev) => !prev);
            event.preventDefault();
          }}
        >
          {t("time.inWords.expires")}
          <Icon size="xs" name="calendar" />
        </Button>
      )}
    </>
  );

  return (
    <li className={css.root} ref={badgeRef}>
      <DoubleSectionPill leftContent={leftContent} onDelete={handleDelete} rightContent={rightContent} />
      {renderCalendar &&
        ReactDOM.createPortal(CalendarRenderer, document.getElementById("modal_region") as HTMLElement)}
    </li>
  );
};
