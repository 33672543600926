export const KEY_LEFT = 37;
export const KEY_UP = 38;
export const KEY_RIGHT = 39;
export const KEY_DOWN = 40;
export const KEY_ENTER = 13;
export const KEY_CTRL = 17;
export const KEY_ESC = 27;
export const KEY_TAB = 9;
export const KEY_SPACE = 32;
export const KEY_LEFT_CMD = 91;
export const KEY_RIGHT_CMD = 93;
