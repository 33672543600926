import { has, some } from "lodash";

import { BookingData, BookingFormMode } from "./use_booking_form";

export const getHeaderTitleTranslationKey = (mode: BookingFormMode | undefined) => {
  if (mode === BookingFormMode.Edit) {
    return "bookingModule:bookings.update.editBooking";
  }
  if (mode === BookingFormMode.Clone) {
    return "bookingModule:bookings.clone.cloneBooking";
  }
  return "bookingModule:bookings.create.createBooking";
};

export const getSubmitSuccessMessageTranslationKey = (
  mode: BookingFormMode | undefined,
  isAffectingGroup: boolean
): {
  actionId: string;
  messageKey:
    | "bookings.create.bulk.success"
    | "bookings.update.success"
    | "bookings.clone.success"
    | "bookings.create.success";
} => {
  if (isAffectingGroup) {
    return {
      actionId: "bm-booking-form-create-bulk-success-action",
      messageKey: "bookings.create.bulk.success"
    };
  }
  if (mode === BookingFormMode.Edit) {
    return {
      actionId: "bm-booking-form-update-success-action",
      messageKey: "bookings.update.success"
    };
  }
  if (mode === BookingFormMode.Clone) {
    return {
      actionId: "bm-booking-form-clone-success-action",
      messageKey: "bookings.clone.success"
    };
  }
  return {
    actionId: "bm-booking-form-create-success-action",
    messageKey: "bookings.create.success"
  };
};

export const getSubmitErrorMessageTranslationKey = (mode: BookingFormMode | undefined) => {
  if (mode === BookingFormMode.Edit) {
    return "bookings.update.error";
  }
  if (mode === BookingFormMode.Clone) {
    return "bookings.clone.error";
  }
  return "bookings.create.error";
};

export const getAcceptButtonTranslationKey = (mode: BookingFormMode | undefined) => {
  if (mode === BookingFormMode.Clone) {
    return "bookings.clone.cloneAction";
  }
  return "accept";
};

export const isRepeatedBooking = (bookings: BookingData): boolean =>
  some(bookings, (booking) => has(booking, "wdayMask"));
