import { CustomType, CustomValue, Experience } from "PFTypes";

import { Id } from "./id";

export enum SkillImportance {
  Essential = 0,
  Supporting = 1
}

export enum SkillsFrameworkRank {
  Primary = "primary",
  Secondary = "secondary"
}

export const SkillsFrameworkRanks = Object.values(SkillsFrameworkRank);

export type SkillsFrameworkCustomValue = Pick<CustomValue, "id" | "value">;
export type SkillsFrameworkCustomType = Pick<CustomType, "id" | "name" | "display_as" | "displayAs">;

export type SkillsFramework<TValue = SkillsFrameworkCustomValue> = {
  id: Id;
  name: string;
  description?: string;
  customFields: {
    id: Id;
    customType: SkillsFrameworkCustomType;
    customValue: TValue;
  }[];
  skills: {
    id: Id;
    importance: SkillImportance;
    experience: Experience;
    customValue: TValue;
    proposed: boolean;
  }[];
  activeProfilesCount?: number;
  skillsCount?: number;
  rank: SkillsFrameworkRank;
  coverage: number;
  updatedAt: string; // iso date
};

export type SkillsFrameworkCreateUpdateData = {
  name: string;
  description?: string;
  customFields: {
    customTypeId: Id;
    customValueId: Id;
  }[];
  skills: {
    skillId: Id;
    importance: SkillImportance;
    experience: Experience;
  }[];
  rank: SkillsFrameworkRank;
};
