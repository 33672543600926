import classNames from "classnames";
import LazyLocalExportTrigger from "PFApp/components/document_buttons/lazy_local_export_trigger";
import { ProfileAvatarConnected } from "PFApp/components/profile_avatar_connected";
import useIsChatEnabled from "PFApp/use_is_chat_enabled";
import { ActionIcon } from "PFComponents/action_icon";
import { Button } from "PFComponents/button";
import PlusIcon from "PFComponents/icons/plus_icon";
import Modal from "PFComponents/modal/modal";
import ProfileAvatar from "PFComponents/profile_avatar/profile_avatar";
import copyTextToClipboard from "PFCore/helpers/copyTextToClipboard";
import { getProfileName } from "PFCore/helpers/profile";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useQueryParams } from "PFCore/helpers/use_query_params";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { getExportProfiles } from "PFCore/services/export_profiles";
import { UiRoute } from "PFCore/utilities/routes";
import UploadIcon from "PFIcons/upload_2.svg";
import { FeatureFlag } from "PFTypes";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useGrowl } from "../../../use_growl";
import Attachments from "./attachments";
import css from "./profile_avatar_edit.module.scss";
import EditSection from "./section_edit";
import UploadCvModal from "./upload_cv_modal";

const ProfileAvatarEdit = ({ profile, isMe, handleProfileUpdate, errors }) => {
  const { data: currentAccount } = useCurrentAccount();

  const { t } = useTranslation("profiles");
  const queryParams = useQueryParams();
  const isEnabled = useIsFeatureEnabled();
  const isProfileExportEnabled = isEnabled(FeatureFlag.ProfileExporter);
  const isChatEnabled = useIsChatEnabled();
  const growl = useGrowl();

  const [renderEditModal, setRenderEditModal] = useState(false);
  const [renderPhotoModal, setRenderPhotoModal] = useState(false);
  const [renderUploadModal, setRenderUploadModal] = useState(false);

  useEffect(() => {
    if (/contact_info/.test(window.location.href) && queryParams.get("edit") === "true") {
      setRenderEditModal(true);
    }
  }, []);

  const isCvParsingEnabled = currentAccount?.cv_parser_config?.enabled;

  const handleCopyProfileLink = () => {
    const profileUrl = UiRoute(`/profiles/${profile.id}`);
    copyTextToClipboard(profileUrl)
      .then(() => growl({ message: t("show.parts.shareProfileSuccess"), kind: "success", ttl: 2500 }))
      .catch(() => growl({ message: t("unknownError"), kind: "error", ttl: 2500 }));
  };

  return (
    <div className={css.avatarWrapOuter}>
      <div className={css.avatarWrap}>
        <>
          {isMe && (
            <ActionIcon
              name="edit"
              size="sm"
              onClick={() => setRenderEditModal(true)}
              classes={{ root: css.editIcon }}
              title={t("show.parts.editProfilePicture")}
            />
          )}
          <div className={css.filesMenu}>
            <UploadIcon height={15} />
            <div className={css.filesMenuConnector} />
            <div className={css.filesMenuConnectorShadow} />
            <div className={css.filesMenuItems}>
              {profile && isProfileExportEnabled && (
                <LazyLocalExportTrigger
                  buttonKind="blank"
                  quantity={1}
                  getProfilesCollection={() =>
                    getExportProfiles({
                      profileIds: [profile.id]
                    }).then((resp) => ({
                      entries: resp.map((profile) => ({
                        profile
                      })),
                      meta: { total: resp.length, page: 1 }
                    }))
                  }
                />
              )}
              {isMe && isCvParsingEnabled && (
                <Button
                  text={t("show.parts.importCV")}
                  rootClassName={css.item}
                  onClick={() => setRenderUploadModal(true)}
                  kind="blank"
                />
              )}
              <Button
                text={t("show.parts.shareProfile")}
                icon="copy"
                iconPlacement="right"
                kind="blank"
                onClick={() => handleCopyProfileLink()}
              />
              <Attachments profile={profile} />
            </div>
          </div>
        </>
        <button
          className={classNames(css.noStyleButton, { [css.hoverWrapper]: isMe })}
          onClick={() => isMe && setRenderPhotoModal(true)}
        >
          <ProfileAvatarConnected profile={profile} size={170} withChat={isChatEnabled} />
          {isMe && <PlusIcon className={css.editAvatarIcon} size={25} />}
        </button>
      </div>

      {renderEditModal && (
        <Modal
          style={{ maxWidth: "950px", margin: "auto", maxHeight: "100vh" }}
          noPadding
          handleClose={() => setRenderEditModal(false)}
        >
          <EditSection profile={profile} handleProfileUpdate={handleProfileUpdate} errors={errors} />
        </Modal>
      )}
      {renderPhotoModal && (
        <Modal
          style={{
            width: "50vh",
            height: "50vh",
            position: "fixed",
            left: "50%",
            top: "45%",
            transform: "translate(-50%,-50%)"
          }}
          noPadding
          handleClose={() => setRenderPhotoModal(false)}
          closeOnBackdropClick
        >
          <>
            <ProfileAvatar size={40} fullSize profile={profile} onClick={() => setRenderPhotoModal(false)} />
            <div className={css.photoModalName}>{getProfileName(profile)}</div>
          </>
        </Modal>
      )}
      {renderUploadModal && <UploadCvModal handleModalClose={setRenderUploadModal} />}
    </div>
  );
};

ProfileAvatarEdit.propTypes = {
  isMe: PropTypes.bool,
  profile: PropTypes.shape({
    id: PropTypes.any,
    last_name: PropTypes.string,
    first_name: PropTypes.string,
    statistics: PropTypes.shape({
      progress_completion: PropTypes.number
    })
  }),
  handleProfileUpdate: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.object)
};

export default ProfileAvatarEdit;
