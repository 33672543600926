import { Id } from "PFTypes/id";

import { Snakify } from "./snakify";

export enum Experience {
  Basic = 1,
  Intermediate = 3,
  Advanced = 5
}

export const DEFAULT_SKILL_EXPERIENCE = Experience.Intermediate;

export const SUPPORTING_SKILL_WEIGHT = null;
export const ESSENTIAL_SKILL_WEIGHT = 5;
export const REQUIRED_SKILL_WEIGHT = 10;

export type Weight =
  | typeof SUPPORTING_SKILL_WEIGHT
  | typeof ESSENTIAL_SKILL_WEIGHT
  | typeof REQUIRED_SKILL_WEIGHT;

type _CustomValue = {
  adminApproved: boolean;
  assessed: boolean;
  assessmentSource: string;
  certificateIds: Array<number>;
  createdAt: string;
  developmental: boolean;
  experience?: Experience;
  expiryDate: string | null;
  globalId: string | null;
  id: number;
  interest: boolean | null;
  rare: boolean | null;
  seniority: number;
  source: string | null;
  suggestedExperience: number;
  text: string;
  top: boolean;
  value: string | number;
  emVerified: boolean;
  customTypeId?: Id;
  state: CustomValueState;
  weight?: Weight;
  description: string | null;
  globalDescription: string | null;
  updatedAt: string;
  creator?: {
    id: Id;
    name: string;
  };
  skillsFrameworks?: {
    id: Id;
    name: string;
  }[];
};

export type CustomValue = Snakify<_CustomValue>;

export type PureCustomValueCamelCased = {
  id: Id;
  globalId?: Id | null;
  ordinal?: number;
  added?: boolean;
  text: string;
  type: string;
  curatedAt?: string | null;
  description?: string;
  weight?: Weight;
  state?: CustomValueState;
  experience?: Experience;
};

export type CustomValueDateRangeCameCased = {
  startDate: string; // iso date
  endDate: string; // iso date
};

export type CustomValueDateRange = Snakify<CustomValueDateRangeCameCased>;

export type PureCustomValue = Snakify<PureCustomValueCamelCased>;

export enum CustomValueState {
  Approved = "approved",
  Blocked = "blocked",
  Created = "created",
  Merged = "merged"
}

export enum CustomValueMetaAction {
  Merge = "merge",
  Block = "block",
  Approve = "approve",
  Edit = "edit",
  Export = "export",
  Reset = "reset"
}

export type CustomValueMetaActionName = `${CustomValueMetaAction}`;
