import { useQuery } from "@tanstack/react-query";
import useRequestAttempts from "PFCore/hooks/use_request_attempts";
import { fetchShortlistsRanked, RankedShortlistCollection } from "PFCore/services/shortlist";
import { Id } from "PFTypes/id";
import { QueryParams } from "PFTypes/query_params";
import { QueryOptions } from "PFTypes/request_options";

import { rankedShortlistsKeys } from "./query_keys";

type UseActivityRankedShortlists = {
  activityId: Id;
  profileIds?: Id[];
  params?: QueryParams;
  options?: QueryOptions<RankedShortlistCollection>;
};

export const useActivityRankedShortlists = ({
  activityId,
  profileIds = [],
  params = {},
  options = {}
}: UseActivityRankedShortlists) => {
  const queryKey = rankedShortlistsKeys.activityRankedShortlists(activityId, profileIds);
  const { handleSuccessfulAttempt } = useRequestAttempts({
    queryKey,
    revertOnNotReady: true
  });

  const query = useQuery<RankedShortlistCollection>(
    queryKey,
    () => fetchShortlistsRanked(activityId, profileIds, params.page, params.perPage),
    {
      retry: false,
      staleTime: 0,
      ...options,
      onSuccess: (response) => {
        handleSuccessfulAttempt(response, query.refetch, options?.onSuccess);
      }
    }
  );

  return query;
};
