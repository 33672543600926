import { camelCase } from "lodash";
import { isMsTeams, openChatInMsTeams } from "PFCore/helpers/ms_teams";
import { isFeatureEnabled } from "PFCore/helpers/use_is_feature_enabled";
import i18n from "PFCore/i18n";
import EmailIcon from "PFIcons/envelope.svg";
import FacebookIcon from "PFIcons/facebook.svg";
import WebsiteIcon from "PFIcons/globe_solid.svg";
import InstagramIcon from "PFIcons/instagram.svg";
import MsTeamsIcon from "PFIcons/microsoft_teams.svg";
import PhoneIcon from "PFIcons/phone_mobile.svg";
import SkypeIcon from "PFIcons/skype.svg";
import SkypeBusinessIcon from "PFIcons/skype_business.svg";
import TwitterIcon from "PFIcons/twitter.svg";
import { humanize } from "underscore.string";

const getContactInfoArray = (profile, currentAccount) => {
  const isEnabled = isFeatureEnabled(currentAccount);

  let contacts = [];

  if (profile.email) {
    contacts.push({ type: "email", value: profile.email, url: `mailto:${profile.email}` });
  }

  let links = (profile.contact_information || []).map((item) => ({ ...item }));

  const rejectByFeatureType = (featureType) => {
    // all the faetures names are the same as the type
    if (!isEnabled(featureType)) {
      links = links.filter(({ type }) => type !== featureType);
    }
  };

  rejectByFeatureType("facebook");
  rejectByFeatureType("twitter");
  rejectByFeatureType("skype");
  rejectByFeatureType("skype_business");
  rejectByFeatureType("instagram");
  rejectByFeatureType("website");

  contacts = contacts.concat(links);

  contacts.forEach((contact) => {
    const { type, value, label } = contact;
    // prettier-ignore
    switch (type) {
      case "email": contact.Icon = EmailIcon; break;
      case "twitter": contact.Icon = TwitterIcon; break;
      case "facebook": contact.Icon = FacebookIcon; break;
      case "instagram": contact.Icon = InstagramIcon; break;
      case "skype": contact.Icon = SkypeIcon; break;
      case "skype_business": contact.Icon = SkypeBusinessIcon; break;
      case "website": contact.Icon = WebsiteIcon; break;
      case "phone": contact.Icon = PhoneIcon; break;
      case "ms_teams": contact.Icon = MsTeamsIcon; break;
      default: contact.Icon = "div"; break;
    }

    if (["email", "skype", "skype_business"].includes(type)) {
      contact.target = "_self";
    } else if (contact.url) {
      contact.target = "_blank";
    } else {
      contact.target = "_blank";
    }

    if (type === "ms_teams") {
      const userName = contact.value;
      contact.value = "Message in MS Teams";
      if (isMsTeams()) {
        contact.onClick = () => openChatInMsTeams(userName);
        contact.url = null;
      }
    }

    if (type === "website") {
      contact.value = label || value;
    } else if (type === "phone" && label) {
      contact.value = `${value} ${label}`;
    }

    contact.labelTitle = i18n.t(`contactInformation.options.${camelCase(type)}`, {
      defaultValue: humanize(type)
    });
  });
  return contacts;
};

export default getContactInfoArray;
