import AddRoleButton from "PFApp/activities/show/parts/add_role_button";
import NoRolesIcon from "PFIcons/no_roles.svg";
import { Activity } from "PFTypes";
import { useTranslation } from "react-i18next";

import AddCoOwner from "../parts/add_co_owner";
import css from "./no_linked_roles.module.scss";

type NoLinkedRolesProps = {
  activity: Activity;
  showActions: boolean;
  canCreateRoles: boolean;
  roleKey: string;
};

export const NoLinkedRoles = ({
  activity,
  showActions = true,
  canCreateRoles = true,
  roleKey
}: NoLinkedRolesProps) => {
  const { t } = useTranslation("activities");

  return (
    <div className={css.noRolesWrapper}>
      <NoRolesIcon />
      <div className={css.title}>{t("show.parts.engagementNoRoles")}</div>
      <div className={css.subTitle}>{t("show.parts.addToGetStarted")}</div>
      {showActions && (
        <div className={css.buttons}>
          {canCreateRoles && <AddRoleButton activityId={activity.id} roleKey={roleKey} />}
          <AddCoOwner activity={activity} />
        </div>
      )}
    </div>
  );
};
