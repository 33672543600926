import SectionBox from "PFComponents/box/section_box";
import CustomValuesList from "PFComponents/custom_values_list/custom_values_list";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Interest } from "../../query/api.types";
import css from "./interest_item.module.scss";

type InterestItemProps = {
  interest: Interest;
};

export const InterestItem = ({ interest }: InterestItemProps): JSX.Element => {
  const { t } = useTranslation("profiles");
  const { formatDate } = useDateFormatter();
  const { data: currentAccount } = useCurrentAccount();

  const { activity } = interest;

  const values = activity.customFields.find(({ type }) => type.name === "skills")?.values || [];

  return (
    <SectionBox className={css.root}>
      <div className={css.item}>
        <small>
          {t("show.parts.interestRegistered")} {formatDate(interest.createdAt)}
        </small>
        <Link to={`/activities/${activity.id}`}>
          <div className={css.title}>{activity.name}</div>
        </Link>
        <CustomValuesList
          moreLimit={3}
          showMoreButton
          customValues={values}
          currentAccount={currentAccount}
        />
      </div>
    </SectionBox>
  );
};

export default InterestItem;
