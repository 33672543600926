import { Moment } from "moment";
import { Company } from "PFTypes";
import { FunctionComponent } from "react";

type BadgeType = { title: string; isSelected: boolean };

export type ExperienceCardProps = {
  experience: Experience;
  editMode: boolean;
  onEdit: (experience: Experience) => void;
};

export type Experience = {
  lastEdited: string | Date | Moment;
  description: string;
  title: string;
  company: Company;
  duration: {
    from: string | Date | Moment;
    to: string | Date | Moment;
  };
  badges: BadgeType[];
  isCurrent: boolean;
};

const ExperienceCard: FunctionComponent<ExperienceCardProps> = () => <></>;

export default ExperienceCard;
