import classNames from "classnames";
import { ActionIcon } from "PFComponents/action_icon";
import RangeCalendar from "PFComponents/calendar/range_calendar";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import PropTypes from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";

import css from "./inline_calendar.module.scss";

const InlineRangeCalendar = ({
  label,
  name,
  error,
  kind,
  disabled,
  style,
  qaId,
  startDate,
  endDate,
  handleChange,
  options,
  letClear,
  onRestore,
  className,
  popperOptions,
  popperClassName,
  inputFieldSetProps,
  selectedPeriod,
  onStartDateChange,
  portalRef,
  ...calendarProps
}) => {
  const calendarRef = useRef();
  const [popperRef, setPopperRef] = useState();
  const [popperReferenceElement, setPopperReferenceElement] = useState();
  const [showCalendar, setShowCalendar] = useState(false);
  const { formatDate } = useDateFormatter();
  const handleFocusOut = (event) => {
    if (event.relatedTarget && !calendarRef.current.contains(event.relatedTarget)) {
      handleHideCalendar();
    }
  };

  useEffect(() => {
    if (showCalendar) {
      window.addEventListener("mousedown", handleHideCalendar);
      calendarRef.current?.addEventListener("focusout", handleFocusOut);
    } else {
      window.removeEventListener("mousedown", handleHideCalendar);
      calendarRef.current?.removeEventListener("focusout", handleFocusOut);
    }
    return () => {
      window.removeEventListener("mousedown", handleHideCalendar);
      calendarRef.current?.removeEventListener("focusout", handleFocusOut);
    };
  }, [showCalendar]);

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const handleHideCalendar = () => setShowCalendar(false);

  const handleCalendarChange = (startDate, endDate, period) => {
    setShowCalendar(false);
    handleChange(startDate, endDate, period);
  };

  const value = useMemo(() => {
    if (selectedPeriod && options) {
      const periodOption = options.find(({ id }) => id === selectedPeriod);
      return periodOption?.displayElement;
    }
    return startDate && endDate ? `${formatDate(startDate)} - ${formatDate(endDate)}` : "";
  }, [startDate, endDate, selectedPeriod, options, formatDate]);

  const { styles, attributes } = usePopper(popperReferenceElement, popperRef, {
    placement: "bottom-start",
    ...popperOptions
  });

  const CalendarDropdown = (
    <div
      ref={setPopperRef}
      className={classNames(css.popper, popperClassName)}
      style={styles.popper}
      {...attributes.popper}
    >
      <div className={css.inlineRoot} ref={calendarRef}>
        <RangeCalendar
          {...calendarProps}
          startDate={startDate}
          endDate={endDate}
          handleChange={handleCalendarChange}
          onMouseDown={stopPropagation}
          options={options}
          onStartDateChange={onStartDateChange}
          portalRef={portalRef}
        />
      </div>
    </div>
  );

  return (
    <div className={classNames(css.root, className)} style={style} ref={setPopperReferenceElement}>
      <div className={css.actions}>
        {onRestore && (
          <ActionIcon name="history" size="sm" onClick={onRestore} fill="var(--palettePrimary0)" />
        )}
        {value && letClear && (
          <ActionIcon
            name="filter-clean"
            size="sm"
            onClick={() => handleChange(null, null, null)}
            fill="var(--palettePrimary0)"
          />
        )}
      </div>
      <InputFieldSet
        icon="calendar"
        label={label}
        name={name}
        disabled={disabled}
        locked={calendarProps.locked}
        lockedTip={calendarProps.lockedTip}
        error={error}
        kind={kind}
        value={value}
        qaId={qaId}
        onFocus={() => setShowCalendar(true)}
        onMouseDown={stopPropagation}
        inputClassName={css.input}
        {...inputFieldSetProps}
      />
      {showCalendar &&
        !disabled &&
        (portalRef?.current ? ReactDOM.createPortal(CalendarDropdown, portalRef.current) : CalendarDropdown)}
    </div>
  );
};

InlineRangeCalendar.propTypes = {
  ...InputFieldSet.propTypes,
  ...RangeCalendar.propTypes,
  style: PropTypes.object,
  letClear: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  popperOptions: PropTypes.object,
  popperClassName: PropTypes.string,
  periodLabels: PropTypes.object,
  onStartDateChange: PropTypes.func,
  inputFieldSetProps: PropTypes.shape({
    ...InputFieldSet.propTypes
  }),
  onRestore: PropTypes.func,
  portalRef: PropTypes.object
};

InlineRangeCalendar.defaultProps = {
  style: null,
  letClear: false,
  withPopper: false,
  inputFieldSetProps: {}
};

export default InlineRangeCalendar;
