import { Options as PopperOptions } from "@popperjs/core";
import classNames from "classnames";
import { trim } from "lodash";
import {
  SEARCH_AUTOCOMPLETE_FOOTER_BUTTON_ID,
  SEARCH_AUTOCOMPLETE_FOOTER_ID,
  SearchAutocomplete
} from "PFApp/components/search_autocomplete";
import {
  SearchConfig,
  SearchEntityData
} from "PFApp/components/search_autocomplete/search_autocomplete.types";
import { ActionIcon } from "PFComponents/action_icon";
import { useRecentSearches } from "PFCore/helpers/use_recent_searches";
import { useSearchInput } from "PFCore/helpers/use_search_input";
import { RecentSearchesPageKey } from "PFTypes/recent_searches";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePopper } from "react-popper";

import css from "./search_input.module.scss";

const INPUT_VALUE_MAX_LENGTH = 255;
const AUTOCOMPLETE_SEARCH_ICON_ID = "autocomplete-search-icon";

type SearchInputProps = {
  term: string;
  onTermChange: (newTerm: string, entity?: SearchEntityData | null) => void;
  qaId?: string;
  placeholder?: string;
  disabled?: boolean;
  autocompleteConfig?: SearchConfig;
  pageKey: RecentSearchesPageKey;
  includeRecentSearches?: boolean;
  popperOptions?: Partial<PopperOptions>;
  classes?: {
    root?: string;
    dropdown?: string;
  };
};

export const SearchInput = ({
  term,
  onTermChange,
  qaId,
  placeholder,
  disabled,
  autocompleteConfig = [],
  pageKey,
  includeRecentSearches = true,
  popperOptions,
  classes = {}
}: SearchInputProps) => {
  const { t } = useTranslation("translation");
  const [isFocused, setFocused] = useState(false);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const { getRecentSearches, saveSearchAsRecent } = useRecentSearches(pageKey);

  useEffect(() => {
    if (includeRecentSearches) {
      saveSearchAsRecent(term);
    }
  }, [term]);

  const handleTermChange = (
    newTerm: string,
    entity: SearchEntityData | null = null,
    focus: boolean = false
  ) => {
    const trimmedTerm = trim(newTerm);

    onTermChange(trimmedTerm, entity);
    if (focus) {
      searchInputRef?.current?.focus();
      setFocused(true);
    } else {
      searchInputRef?.current?.blur();
      setFocused(false);
    }
  };

  const { localTerm, setLocalTerm, handleSearchKeyDown, handleClear, handleSubmit } = useSearchInput({
    term,
    onTermChange: handleTermChange,
    withUrlChanges: false
  });

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(searchInputRef.current, popperElement, {
    placement: "bottom-start",
    ...popperOptions
  });

  const isSearchIconDisplayed = !term || isFocused;
  const isSearchActionDisabled = disabled || (!localTerm && !term);
  const withAutocomplete = includeRecentSearches || !!autocompleteConfig.length;

  return (
    <div className={classNames(css.search, classes.root)}>
      <input
        ref={searchInputRef}
        onChange={(event) => setLocalTerm(event.target.value)}
        onKeyDown={handleSearchKeyDown}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={(event) => {
          const target = event.relatedTarget || document.activeElement;
          // to fix nothing happening on dropdown item click
          const clickedInDropdown =
            target &&
            (target.hasAttribute("data-dropdown-root") ||
              target.hasAttribute("data-dropdown-item") ||
              target.hasAttribute("data-dropdown-list"));

          // to fix nothing happening on search icon click
          const clickedOnSearchIcon = target?.id === AUTOCOMPLETE_SEARCH_ICON_ID;
          // to fix nothing happening on see all results button click
          const clickedInDropdownFooter =
            target?.id === SEARCH_AUTOCOMPLETE_FOOTER_ID ||
            target?.id === SEARCH_AUTOCOMPLETE_FOOTER_BUTTON_ID;

          if (!clickedInDropdown && !clickedOnSearchIcon && !clickedInDropdownFooter) {
            setFocused(false);
          }
        }}
        placeholder={placeholder ?? t("search")}
        value={localTerm}
        data-qa-id={qaId}
        disabled={disabled}
        className={css.input}
        maxLength={INPUT_VALUE_MAX_LENGTH}
      />
      <div className={css.iconWrapper}>
        <ActionIcon
          id={isSearchIconDisplayed ? AUTOCOMPLETE_SEARCH_ICON_ID : undefined}
          name={isSearchIconDisplayed ? "search" : "cross"}
          size="sm"
          onClick={isSearchIconDisplayed ? handleSubmit : handleClear}
          disabled={disabled || (!localTerm && !term)}
          title={isSearchIconDisplayed ? t("search") : t("clear")}
          classes={{ icon: css.icon }}
        />
      </div>
      {withAutocomplete && isFocused && (
        <div ref={setPopperElement} style={{ ...styles.popper, zIndex: 25 }} {...attributes.popper}>
          <SearchAutocomplete
            config={autocompleteConfig}
            recentSearches={includeRecentSearches ? getRecentSearches(localTerm) : []}
            term={localTerm}
            onTermChange={(text, entity) => {
              setLocalTerm(text);
              handleTermChange(text, entity);
            }}
            showFooter={!isSearchActionDisabled}
            classes={{ dropdown: classes.dropdown }}
          />
        </div>
      )}
    </div>
  );
};
