import classNames from "classnames";
import Dots from "PFComponents/slider/dots";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import css from "./slider.less";

export default class Slider extends PureComponent {
  state = {
    value: this.props.value
  };

  handleInput = (event) => {
    const { handleInput } = this.props;
    this.setState({ value: event.target.valueAsNumber });

    handleInput && handleInput(event);
  };

  handleChange = (event) => {
    const { handleChange } = this.props;
    this.setState({ value: event.target.valueAsNumber });

    handleChange && handleChange(event.target.value);
  };

  render() {
    const { value } = this.state;
    const { max, options } = this.props;

    const optionClass = options[value] ? css[options[value].style] : null;
    const inputClassNames = classNames(css.input, optionClass);

    return (
      <div className={css.inputWrapper}>
        {this.renderTrack()}
        <input
          type="range"
          className={inputClassNames}
          onMouseUp={this.handleChange}
          onTouchEnd={this.handleChange}
          onInput={this.handleInput}
          onChange={this.handleInput}
          value={value}
          min={0}
          max={max}
          step={1}
        />
      </div>
    );
  }

  renderTrack() {
    const { showDots, options } = this.props;

    let dots = null;
    if (showDots) {
      dots = <Dots options={options} />;
    }

    return (
      <React.Fragment>
        <div className={css.track} />
        {dots}
      </React.Fragment>
    );
  }
}

Slider.propTypes = {
  max: PropTypes.number.isRequired,
  value: PropTypes.number,
  handleChange: PropTypes.func,
  handleInput: PropTypes.func,
  options: PropTypes.array.isRequired,
  showDots: PropTypes.bool
};

Slider.defaultProps = {
  handleChange: null,
  handleInput: null,
  showDots: true
};
