import ConfirmModal from "PFComponents/confirm/confirm_modal";
import { useTranslation } from "react-i18next";

type PublishConfirmModalProps = {
  templateName: string;
  onOk: () => void;
  onClose: () => void;
};

export const PublishConfirmModal = ({
  templateName,
  onOk,
  onClose
}: PublishConfirmModalProps): JSX.Element => {
  const { t } = useTranslation("activities");
  return (
    <ConfirmModal title={t("edit.noRequirements")} handleOK={onOk} handleClose={onClose}>
      {t("edit.creatingNoRequirementsWarning", { templateName })}
    </ConfirmModal>
  );
};
