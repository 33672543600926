import { isEmpty } from "lodash";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import css from "PFApp/activities/show/parts/add_activity_profiles.module.scss";
import { ProfileItem } from "PFApp/components/profile_item";
import { useGrowl } from "PFApp/use_growl";
import ConfirmModal from "PFComponents/confirm/confirm_modal";
import AutoSelect from "PFComponents/select/autoselect";
import { getProfileName } from "PFCore/helpers/profile";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { fetchProfilesOptions } from "PFCore/services/profile";
import { ApiRoute } from "PFCore/utilities/routes";
import { FeatureFlag } from "PFTypes";
import PropTypes from "prop-types";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

const ChangeAssigneeModal = ({ onClose }) => {
  const { task } = useActivityPageContext();
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation("activities");
  const { t: tTranslation } = useTranslation();
  const { invalidate: invalidateActivities } = useActivityInvalidate();

  const growl = useGrowl();
  const isEnabled = useIsFeatureEnabled();
  const { getFirstError } = useResponseErrors();

  const taskAssignee = !isEmpty(task.assignee?.profile) ? task.assignee : null;
  const [assignee, setAssignee] = useState(taskAssignee?.profile);

  const description = (
    <div>
      <Trans i18nKey={"show.parts.changeAssigneeDescription"} t={t} components={[<strong key="0" />]} />
    </div>
  );

  const handleClick = () => {
    if (!assignee) {
      if (taskAssignee) {
        return $.ajax({
          url: ApiRoute(`/api/assignees/${taskAssignee.id}`),
          method: "DELETE",
          // eslint-disable-next-line camelcase
          api_version: 2
        })
          .then(() => {
            invalidateActivities([task.id]).then(() => {
              growl({
                kind: "success",
                message: t("show.parts.assigneeRemoved")
              });
            });
          })
          .catch(() => {
            growl({
              kind: "error",
              message: t("show.parts.unableToRemoveAssignee")
            });
          });
      }

      return null;
    }

    return $.ajax({
      url: ApiRoute("/api/assignees"),
      method: "POST",
      // eslint-disable-next-line camelcase
      api_version: 2,
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify({
        // eslint-disable-next-line camelcase
        profile_id: assignee.id,
        // eslint-disable-next-line camelcase
        activity_id: task.id
      })
    })
      .then(() =>
        invalidateActivities([task.id]).then(() => {
          growl({
            message: t("show.parts.profileAssigned", { profileName: getProfileName(assignee) }),
            kind: "success"
          });
        })
      )
      .fail((response) => {
        const error = getFirstError(response);
        growl({
          kind: "error",
          message: error || t("show.parts.profileAssigned", { profileName: getProfileName(assignee) })
        });
      });
  };

  return (
    <ConfirmModal
      title={t("show.parts.setAssignee")}
      labelOK={tTranslation("set")}
      handleOK={handleClick}
      handleClose={onClose}
    >
      <AutoSelect
        label={t("selectEllipsis")}
        closeOnChange
        multi={false}
        displayValues={assignee ? <ProfileItem profile={assignee} /> : null}
        query={(term) => fetchProfilesOptions({ term, policyName: "resourcer" })}
        parseResponse={(response) => response.entries.filter((item) => !assignee || assignee.id !== item.id)}
        filterOptions={(response) => response.filter((item) => currentProfile.id !== item.id || task.coowned)}
        formatOption={(item) => ({
          id: item.id,
          displayElement: <ProfileItem profile={item} />,
          item: item
        })}
        handleChange={(profiles) => {
          setAssignee(profiles[0]);
        }}
        letClear={!isEnabled(FeatureFlag.RequireAssignee)}
      />
      {description && (
        <div className={css.subtitle}>
          <strong>{t("translation:pleaseNote")}</strong>
          <br />
          <div className={css.description}>{description}</div>
        </div>
      )}
    </ConfirmModal>
  );
};

ChangeAssigneeModal.propTypes = {
  onClose: PropTypes.func
};

export default ChangeAssigneeModal;
