import PageBox from "PFComponents/box/page_box";
import { Button } from "PFComponents/button";
import { useTranslation } from "react-i18next";

import { useExperienceSectionContext } from "./section_experience_context_provider";
import css from "./section_experience_edit_form.modules.scss";

const ExperienceEditForm = () => {
  const { resetSelected } = useExperienceSectionContext();
  const { t } = useTranslation();

  return (
    <PageBox>
      <div className={css.mainButtonsContainer}>
        <Button text={t("cancel")} kind="secondary" className={css.mainButton} onClick={resetSelected} />
        <Button text={t("delete")} kind="secondary" className={css.mainButton} onClick={resetSelected} />
        <Button text={t("save")} className={css.mainButton} onClick={resetSelected} />
      </div>
    </PageBox>
  );
};

export default ExperienceEditForm;
