/* eslint-disable camelcase */
import { PER_PAGE_UNPAGINATED } from "PFApp/constants/unpaginated";
import api from "PFCore/api";
import { ApiRoute } from "PFCore/utilities/routes";
import { Collection, Match, Shortlist } from "PFTypes";
import { Id } from "PFTypes/id";

export type ShortlistCollection = Collection<Shortlist[]>;
export type RankedShortlistCollection = Collection<Omit<Match, "optionalFields" | "optional_fields">[]>;

export const fetchShortlists = (
  activity_id,
  page = 1,
  per_page = PER_PAGE_UNPAGINATED,
  filters,
  order
): Promise<ShortlistCollection> =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute("/api/shortlists"),
    method: "GET",
    api_version: 2,
    stringify: true,
    data: { activity_id, page, per_page, filters, order }
  });

export const fetchShortlistsRanked = (
  activity_id: Id,
  profile_ids: Id[] = [],
  page = 1,
  per_page = PER_PAGE_UNPAGINATED
): Promise<RankedShortlistCollection> =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute("/api/shortlists/ranked_profiles"),
    method: "GET",
    api_version: 2,
    stringify: true,
    data: {
      activity_id,
      ...(profile_ids.length ? { profile_ids } : {}),
      page,
      per_page
    }
  });

export const fetchSuspendedShortlists = (
  activityId: Id,
  page = 1,
  perPage = PER_PAGE_UNPAGINATED
): Promise<ShortlistCollection> =>
  /* @ts-ignore */
  $.ajax({
    url: ApiRoute("/api/shortlists/suspended_profiles"),
    method: "GET",
    api_version: 2,
    data: {
      activity_id: activityId,
      page,
      per_page: perPage
    }
  });

export const newShortlist = (activityId, profileId, named) =>
  api({
    url: "shortlists",
    method: "post",
    data: {
      profileId,
      activityId,
      named
    }
  });

export const deleteShortlist = (shortlistId) =>
  api({
    url: `shortlists/${shortlistId}`,
    method: "delete"
  });

export const assignShortlist = (shortlistId) =>
  api({
    url: `shortlists/${shortlistId}/assign`,
    method: "put",
    data: {
      shortlistId
    }
  });

export const resetShortlist = (shortlistId) =>
  api({
    url: `shortlists/${shortlistId}/reset`,
    method: "put",
    data: {
      shortlistId
    }
  });

export const acceptShortlist = (shortlistId, data = {}) =>
  api({
    url: `shortlists/${shortlistId}/accept`,
    method: "put",
    data
  });

export const declineShortlist = (shortlistId, data = {}) =>
  api({
    url: `shortlists/${shortlistId}/decline`,
    method: "PUT",
    data
  });

export const fillShortlist = (shortlistId: Id, approverId?: Id, requestReason?: string) =>
  api({
    url: `shortlists/${shortlistId}/fill`,
    method: "put",
    data: {
      approverId,
      requestReason
    }
  });

type FillAndBookShortistOptions = {
  skipBookingsCreation?: boolean;
  approverId?: number;
  requestReason?: string;
};

export const fillAndBookShortlist = (shortlistId: Id, data?: FillAndBookShortistOptions) =>
  api({
    url: `shortlists/${shortlistId}/fill_and_book`,
    method: "put",
    data
  });

export const rejectShortlist = (shortlistId) =>
  api({
    url: `shortlists/${shortlistId}/reject`,
    method: "put"
  });

export const confirmShortlist = (shortlistId: number) =>
  api({
    url: `shortlists/${shortlistId}/confirm`,
    method: "PUT"
  });
