import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { fetchBookingsFromAvailability } from "PFApp/booking/services/api";

import { bookingsFromAvailabilityKeys } from "./query_keys";

interface BookingFromAvailability {
  start_date: string;
  end_date: string;
  load: number;
  duration: number;
}

interface BookingsFromAvailabilityResponse {
  ranges: BookingFromAvailability[];
  title: string;
  description: string;
}

interface Props {
  activityId?: number;
  profileId?: number;
  options?: Omit<
    UseQueryOptions<BookingsFromAvailabilityResponse>,
    "queryKey" | "queryFn" | "initialData"
  > & {
    initialData?: () => undefined;
  };
}

export const useBookingsFromAvailability = ({ activityId, profileId, options = {} }: Props) =>
  useQuery<BookingsFromAvailabilityResponse>(
    bookingsFromAvailabilityKeys.byActivityAndProfile(activityId, profileId),
    () =>
      fetchBookingsFromAvailability(
        activityId,
        profileId
      ) as unknown as Promise<BookingsFromAvailabilityResponse>,
    {
      retry: false,
      ...options
    }
  );
