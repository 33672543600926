import classNames from "classnames";
import { ActionIcon } from "PFComponents/action_icon";
import { Typography } from "PFComponents/typography";
import { SyntheticEvent } from "react";

import css from "./double_section_pill.module.scss";

type PillKind = "primary" | "secondary" | "selected" | "faded";

export type DoubleSectionPillProps = {
  leftContent: JSX.Element | string;
  rightContent: JSX.Element | string;
  onDelete?: (event: SyntheticEvent) => void;
  kind?: PillKind;
};

export const DoubleSectionPill = ({
  leftContent,
  rightContent,
  onDelete,
  kind = "primary"
}: DoubleSectionPillProps): JSX.Element => (
  <span className={classNames(css.wrapper, css[kind])}>
    <div className={classNames(css.leftSection, css.section)}>
      {onDelete && <ActionIcon name="cross" size="xs" onClick={onDelete} />}
      <Typography variant="bodyButtons" tag="span">
        {leftContent}
      </Typography>
    </div>
    <div className={classNames(css.rightSection, css.section)}>
      <Typography variant="bodyRegular" tag="span">
        {rightContent}
      </Typography>
    </div>
  </span>
);
