import classNames from "classnames";
import { camelizeKeys } from "humps";
import { isString, toString } from "lodash";
import ProfileAvatar from "PFComponents/profile_avatar/profile_avatar";
import { getJobTitle, getProfileName } from "PFCore/helpers/profile";
import { Profile, ProfileBasicInformation } from "PFTypes";

import css from "./profile_item.module.scss";

type ProfileItemProps = {
  className?: string;
  profile: ProfileBasicInformation | Profile;
  style?: React.CSSProperties;
};

const calculateJobTitle = (profile: ProfileBasicInformation | Profile): string => {
  const { jobTitle } = profile as ProfileBasicInformation;
  const { positions } = profile as Profile;

  if (isString(jobTitle)) {
    return jobTitle;
  }

  if (positions) {
    return toString(getJobTitle(profile as Profile));
  }

  return "";
};

export const ProfileItem = ({ className, profile, style }: ProfileItemProps): JSX.Element => {
  const camelizedProfile = camelizeKeys(profile) as ProfileBasicInformation | Profile; // TODO: [PROF-5746] Remove "camelizeKeys" when all ProfileItem usages get camelized profile prop
  const { text } = camelizedProfile as ProfileBasicInformation;
  const name = text || getProfileName(camelizedProfile);
  const calculatedJobTitle = calculateJobTitle(camelizedProfile);

  return (
    <div className={classNames(className, css.container)} style={style}>
      {profile.avatar && <ProfileAvatar profile={camelizedProfile} size={40} />}
      <div className={classNames(css.dropdownItem, { [css.noAvatar]: !profile.avatar })}>
        <b className={css.dropdownName}>{name}</b>
        <div className={css.dropdownInfo}>
          {calculatedJobTitle} <i>{camelizedProfile.email}</i>
        </div>
      </div>
    </div>
  );
};
