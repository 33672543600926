import SimpleBadge from "PFComponents/badges_list/simple_badge";
import Radios from "PFComponents/radios/radios";
import { useMemo } from "react";

import { SkillsFrameworkCustomValueDecorated } from "../skills_framework_select_modal.types";
import css from "./conflict_radios.module.scss";

type ConflictRadiosProps = {
  value?: SkillsFrameworkCustomValueDecorated["id"];
  values: SkillsFrameworkCustomValueDecorated[];
  onChange: (value: ConflictRadiosProps["values"][0]) => void;
};
export const ConflictRadios = ({ value, values, onChange }: ConflictRadiosProps) => {
  const options = useMemo(
    () =>
      values.map(({ id, value, kind }) => ({
        id,
        name: String(value),
        displayElement: <SimpleBadge key={id} text={value} kind={kind} />,
        style: { display: "flex" }
      })),
    [values]
  );

  return (
    <div className={css.container}>
      <Radios<SkillsFrameworkCustomValueDecorated["id"]>
        items={options}
        value={value || values[0]?.id}
        handleChange={onChange}
        classes={{
          item: css.item
        }}
      />
    </div>
  );
};
