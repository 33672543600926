import { debounce, noop } from "lodash";
import { InputProps } from "PFComponents/text/input";
import { InputFieldSet, InputFieldSetProps } from "PFComponents/text/input_field_set";
import { useCallback, useEffect, useState } from "react";

const DEBOUNCE_TIME = 1500;

type SearchItemProps = Pick<
  InputFieldSetProps,
  "error" | "autofocus" | "disabled" | "onBlur" | "onFocus" | "label" | "labelTooltip"
> & {
  handleChange: InputProps["onChange"];
  handleBlur: InputProps["onBlur"];
  handleFocus: InputProps["onFocus"];
  filter: { title: string; value?: string };
};
export const SearchItem = ({
  label,
  labelTooltip,
  filter,
  autofocus,
  disabled,
  handleChange,
  handleBlur,
  handleFocus,
  error
}: SearchItemProps) => {
  const [value, setValue] = useState(filter.value);
  const debouncedHandleChange = debounce(handleChange || noop, DEBOUNCE_TIME);

  // we don't want to redefine debouncedHandleChange on each render,
  // and for now handleChange will always change reference
  const onChange = useCallback((value, valueObject) => {
    setValue(value);
    debouncedHandleChange(value, valueObject);
  }, []);

  useEffect(() => {
    setValue(filter.value || "");
  }, [filter.value]);

  return (
    <InputFieldSet
      value={value}
      label={label}
      labelTooltip={labelTooltip}
      onChange={onChange}
      autofocus={autofocus}
      onBlur={handleBlur}
      onFocus={handleFocus}
      error={error}
      disabled={disabled}
    />
  );
};
