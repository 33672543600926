import { useCurrentAccount } from "PFCore/hooks/queries/account";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import css from "./nav_logo.less";

const NavLogo = () => {
  const { data: currentAccount } = useCurrentAccount();
  const logo = currentAccount.logo?.thumbnail_url;

  return (
    <li className={css.li} data-qa-id="NavLogo">
      <Link to="/" className={css.link} title="NavLogo">
        {logo ? <img src={logo} alt="NavLogo" /> : <SquareLogo className={css.squareLogo} />}
      </Link>
    </li>
  );
};

/* eslint-disable max-len */
// prettier-ignore
export const SquareLogo = ({ className }) =>
  <svg className={className} width="40" height="40" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd">
    <path fill="#fff" d="M0 0h128v128H0z"/>
    <g opacity="0.5">
      <path d="M37.15 30.418c-5.714 0-10.346 4.632-10.346 10.344 0 5.713 4.632 10.345 10.345 10.345 5.715 0 10.344-4.632 10.344-10.345 0-5.712-4.63-10.344-10.344-10.344z"/>
      <path d="M37.15 77.31c-5.714 0-10.346 4.632-10.346 10.345C26.804 93.371 31.436 98 37.149 98c5.715 0 10.344-4.63 10.344-10.344 0-5.714-4.63-10.346-10.344-10.346z"/>
    </g>
    <g>
      <path d="M37.15 53.864c-5.714 0-10.346 4.633-10.346 10.346 0 5.715 4.632 10.344 10.345 10.344 5.715 0 10.344-4.63 10.344-10.344 0-5.713-4.63-10.345-10.344-10.345v-.001z"/>
      <path d="M94.14 51.142c-.09-10.434-7.442-10.434-22.058-10.434h-1.816v22.139c2.36.09 4.812.09 7.262.09 11.075 0 16.612-1.906 16.612-10.798v-.997zM77.71 73.645c-2.451 0-4.812 0-7.444-.09V97.87H57.922V30.454C64.366 30.091 70.357 30 76.894 30c16.52 0 29.592 3.176 29.683 21.142v.996c0 18.147-13.162 21.507-28.867 21.507z"/>
    </g>
  </svg>;

SquareLogo.propTypes = {
  className: PropTypes.string
};
export default NavLogo;
