import classNames from "classnames";
import { ActionIcon } from "PFComponents/action_icon";
import { Button } from "PFComponents/button";
import { AnimatedDiv } from "PFComponents/divs";
import { Profile } from "PFTypes";
import { useToggle } from "react-use";

import { HistoryEntryRow } from "../history_entry_row";
import { HistoryEntryBadge } from "../history_entry_row/history_entry_badge";
import { HistoryEntrySubject } from "../history_event_subject";
import css from "./history_entry_profiles_accordion.module.scss";

type HistoryEntryProfilesAccordionProps = {
  profiles: Profile[];
  badge: string;
};

export const HistoryEntryProfilesAccordion = ({ profiles, badge }: HistoryEntryProfilesAccordionProps) => {
  const [isOpen, toggleOpen] = useToggle(false);

  return (
    <AnimatedDiv>
      <HistoryEntryRow className={css.firstProfileRow}>
        <div className={css.badgeContainer}>
          <ActionIcon onClick={toggleOpen} name={isOpen ? "chevron-down" : "chevron-right"} size="sm" />
          <Button kind="blank" onClick={toggleOpen}>
            <HistoryEntryBadge badge={badge} />
          </Button>
        </div>
        <div
          className={classNames(css.profiles, {
            [css.closed]: !isOpen
          })}
        >
          {profiles.map((targetProfile) => (
            <HistoryEntrySubject key={`history-entry-subject-${targetProfile.id}`} profile={targetProfile} />
          ))}
        </div>
      </HistoryEntryRow>
    </AnimatedDiv>
  );
};
