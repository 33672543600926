import { useIsMutating, useMutation, useQueryClient } from "@tanstack/react-query";
import { updateBookingTemplate } from "PFApp/booking/services/api";
import { BookingTemplate } from "PFTypes";
import { MutationOptions } from "PFTypes/request_options";

import { bookingTemplateKeys } from "../query_keys";
import { useBookingTemplateInvalidate } from "./use_booking_template_invalidate";

const bookingTemplateMutationsKeys = {
  update: (id: number) => [...bookingTemplateKeys.single(id), "update"]
};

export const useBookingTemplateUpdate = (id?: number, options?: MutationOptions<BookingTemplate>) => {
  const cache = useQueryClient();
  const { invalidate: invalidateBookingTemplates } = useBookingTemplateInvalidate();

  const bookingTemplateCacheKey = bookingTemplateKeys.single(id);
  const mutationKey = id ? bookingTemplateMutationsKeys.update(id) : ["disabled-booking-update-mutation"];

  const updateMutation = useMutation(
    async (data: Partial<BookingTemplate>) => {
      await cache.cancelQueries(bookingTemplateCacheKey);
      return updateBookingTemplate(id, data);
    },
    {
      mutationKey,
      onSuccess: () => {
        if (id === undefined) {
          return;
        }

        return invalidateBookingTemplates(id);
      },
      ...options
    }
  );

  const isUpdating = useIsMutating({
    mutationKey
  });

  return {
    mutation: updateMutation,
    update: updateMutation.mutateAsync,
    isUpdating
  };
};
