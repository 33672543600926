import { ActionIcon } from "PFComponents/action_icon";
import RangeInput from "PFComponents/discrete_range/range_input";
import Tooltip from "PFComponents/tooltip/tooltip";
import PropTypes from "prop-types";

import { letFilterRestore } from "../../use_filtered_collection";
import css from "./filters.less";

const isValid = (value) => {
  const { min, max } = value;
  return typeof min === "number" && typeof max === "number";
};

const IntegerItem = ({ label, filter, handleChange, disabled }) => {
  if (!isValid(filter.statistics)) {
    return false; // this could only happen if api sends something wrong
  }

  const showClearLink = !_.isEmpty(filter.value);
  const letRestore = letFilterRestore(filter);
  const value = filter.value || {};

  const min = value.min ?? filter.statistics.min ?? 0;
  const max = value.max ?? filter.statistics.max ?? 0;

  const handleInputChange = (newLow, newHigh) => {
    if (newLow === null && newHigh === null) {
      handleChange(null); // reset the filter
      return;
    }

    const newValue = {
      gte: newLow,
      lte: newHigh
    };

    handleChange(newValue);
  };

  const low = Math.max(value.gte ?? min, min);
  const high = Math.min(value.lte ?? max, max);

  return (
    <>
      <span style={{ paddingBottom: 5 }} className={css.title}>
        {label}
      </span>
      <Tooltip content="Drag the handles to adjust the range" theme="pf-light">
        <div className={css.rangeInput}>
          <div className={css.actions}>
            {letRestore && (
              <ActionIcon
                name="history"
                size="sm"
                onClick={() => handleChange(filter.defaultValue)}
                fill="var(--palettePrimary0)"
              />
            )}
            {showClearLink && (
              <ActionIcon
                name="filter-clean"
                size="sm"
                onClick={() => handleInputChange(null, null)}
                fill="var(--palettePrimary0)"
              />
            )}
          </div>
          <RangeInput
            low={low}
            high={high}
            min={min}
            max={max}
            handleChange={handleInputChange}
            key={`${low}:${high}`}
            disabled={disabled}
          />
        </div>
      </Tooltip>
    </>
  );
};

IntegerItem.propTypes = {
  filter: PropTypes.shape({
    statistics: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number
    }),
    value: PropTypes.shape({
      gte: PropTypes.number,
      lte: PropTypes.number
    }),
    title: PropTypes.string,
    defaultValue: PropTypes.shape({
      gte: PropTypes.number,
      lte: PropTypes.number
    })
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  labelTooltip: PropTypes.shape({
    icon: PropTypes.string,
    content: PropTypes.string
  }),
  disabled: PropTypes.bool
};

export default IntegerItem;
