import classNames from "classnames";
import moment from "moment";
import { ProfileDayAvailability } from "PFTypes";
import { useMediaQuery } from "react-responsive";
import { lpad } from "underscore.string";

import { Typography } from "../../../typography";
import { CalendarDay } from "../use_calendar_days";
import css from "./display_date.module.scss";
import { MOBILE_QUERY, MOBILE_VERTICAL_OFFSET } from "./week_periods/utils";

const EXPANDED_HEIGHT = 120;
const COLLAPSED_HEIGHT = 60;

const minutesToHours = (minutes: number | undefined): number => {
  if (minutes) {
    return Math.round(minutes / 60);
  }
  return 0;
};

type DisplayDateProps = {
  isExpanded: boolean | undefined;
  isFirstDay: boolean;
  availability: ProfileDayAvailability | undefined;
} & CalendarDay;

export const DisplayDate = ({
  date,
  config,
  isExpanded,
  isFirstDay,
  availability
}: DisplayDateProps): JSX.Element => {
  const mobileMatches = useMediaQuery({ query: MOBILE_QUERY });
  const momentDate = moment(date);
  const dateNumber = momentDate.get("date");
  const isFirstDayOfMonth = dateNumber === 1;
  const shouldBeDisplayedWithMonth = (isFirstDayOfMonth || isFirstDay) && isExpanded;
  const dateNumberString = lpad(String(dateNumber), 2, "0");
  const dateLabel = shouldBeDisplayedWithMonth
    ? `${dateNumberString} ${moment(date).format("MMM")}`
    : dateNumberString;

  const nominalHours = minutesToHours(availability?.nominalWorkingMinutes);
  const availableHours = minutesToHours(availability?.availableMinutes);
  const bookedHours = `${nominalHours - availableHours}/${nominalHours}h`;

  const isPastDate = momentDate.isBefore(moment().startOf("day"));

  const shouldDisplayBookedHours = !isPastDate && availability;

  const expandedHeight = mobileMatches ? EXPANDED_HEIGHT + MOBILE_VERTICAL_OFFSET : EXPANDED_HEIGHT;
  const mobileStyle: React.CSSProperties = {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
  };

  return (
    <div
      style={{ height: isExpanded ? expandedHeight : COLLAPSED_HEIGHT, ...(mobileMatches && mobileStyle) }}
      className={classNames(css.root, {
        [css.disabled]: config.disabled,
        [css.nonWorking]: !config.working
      })}
    >
      <Typography
        variant="labelRegular"
        tag="span"
        className={classNames({ [css.notCurrentMonth]: !config.isCurrentMonth })}
      >
        {dateLabel}
      </Typography>
      {shouldDisplayBookedHours && (
        <Typography
          variant="labelBold"
          tag="span"
          className={classNames({ [css.notCurrentMonth]: !config.isCurrentMonth || !availability })}
        >
          {bookedHours}
        </Typography>
      )}
    </div>
  );
};
