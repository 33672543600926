import moment from "moment";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import useWindowSize from "PFCore/helpers/use_window_size";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { FeatureFlag } from "PFTypes";
import PropTypes from "prop-types";
import { useMemo } from "react";

import CompareAvailabilityMobilePage from "./availability/compare_availability_mobile_page";
import CompareAvailabilityPage from "./availability/compare_availability_page";
import CompareHeader from "./common/compare_header";
import { CompareDetailsPage } from "./details/compare_details_page";

const ComparePage = ({
  task,
  comparableProfiles,
  tab,
  changeStartDate,
  changeTab,
  removeProfileId,
  updateOne
}) => {
  const { data: currentProfile } = useCurrentProfile();

  const isEnabled = useIsFeatureEnabled();
  const { formatISODate } = useDateFormatter();

  const profiles = (comparableProfiles || []).map((sh) => sh.profile);
  const availability = task?.metadata?.availability || null;

  const startDate = useMemo(() => {
    let availabilityStartDate = moment();
    if (availability) {
      if (availability.mode === "time_rule") {
        availabilityStartDate = moment.min(availability.ranges.map((range) => moment(range.start)));
      } else {
        availabilityStartDate = moment(availability.start);
      }
    }
    return formatISODate(moment.max(moment(), availabilityStartDate).startOf("isoWeek").set("hour", 12));
  }, [availability, formatISODate]);

  const { windowWidth } = useWindowSize();

  const isBlindModeEnabled = isEnabled(FeatureFlag.UnbiasedAllocation);
  const isActivityUnbiased = task?.reviewers?.find(
    (reviewer) => reviewer.profile_id === currentProfile.id
  )?.unbiased;

  const isBlindModeOn = isBlindModeEnabled && isActivityUnbiased;

  return (
    <>
      <CompareHeader
        task={task}
        tabActiveId={tab}
        changeTab={changeTab}
        comparableProfiles={comparableProfiles}
        isBlindModeOn={isBlindModeOn}
      />

      {
        {
          details: (
            <CompareDetailsPage
              task={task}
              updateOne={updateOne}
              comparableProfiles={comparableProfiles}
              removeProfileId={removeProfileId}
              changeTab={changeTab}
              isBlindModeOn={isBlindModeOn}
            />
          ),
          availability:
            windowWidth > 800 ? (
              <CompareAvailabilityPage
                startDate={startDate}
                comparableProfiles={comparableProfiles}
                changeStartDate={changeStartDate}
                availabilityInfo={availability}
                removeProfileId={removeProfileId}
                isBlindModeOn={isBlindModeOn}
              />
            ) : (
              <CompareAvailabilityMobilePage profiles={profiles} isBlindModeOn={isBlindModeOn} />
            )
        }[tab]
      }
    </>
  );
};

ComparePage.propTypes = {
  task: PropTypes.object.isRequired,
  comparableProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      profile: PropTypes.shape({
        id: PropTypes.number,
        applicable_custom_values: PropTypes.arrayOf(
          PropTypes.shape({ experience: PropTypes.number, text: PropTypes.string, weight: PropTypes.number })
        ),
        isAvailable: PropTypes.bool,
        availabilityMatch: PropTypes.number
      })
    })
  ).isRequired,
  tab: PropTypes.oneOf(["details", "availability"]).isRequired,
  changeTab: PropTypes.func.isRequired,
  removeProfileId: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date),
  changeStartDate: PropTypes.func,
  updateOne: PropTypes.func
};

export default ComparePage;
