import classNames from "classnames";
import { useTranslation } from "react-i18next";

import css from "../../detail_view.module.scss";
import { BookingDetailBooleanProperty } from "./booking_detail_boolean_property";

type BookingDetailCategorySettingsProps = {
  billable: boolean;
  affectsAvailability: boolean;
};

export const BookingDetailCategorySettings = ({
  billable,
  affectsAvailability
}: BookingDetailCategorySettingsProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className={classNames(css.item, css.categorySettings)}>
      <BookingDetailBooleanProperty checked={billable} label={t("billable")} />
      <BookingDetailBooleanProperty checked={affectsAvailability} label={t("affectsAvailability")} />
    </div>
  );
};
