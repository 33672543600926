import { flexRender, HeaderGroup } from "@tanstack/react-table";
import classNames from "classnames";

import { TableData } from "./table";
import css from "./table.module.scss";
import { TableSorting } from "./table_sorting";

type TableHeaderProps<T extends TableData> = {
  headerGroups: HeaderGroup<T>[];
  compact?: boolean;
};

export const TableHeader = <TABLE_DATA extends TableData>({
  headerGroups,
  compact
}: TableHeaderProps<TABLE_DATA>): JSX.Element => (
  <thead className={css.header}>
    {headerGroups.map((headerGroup) => (
      <tr key={headerGroup.id} className={classNames(css.row)}>
        {headerGroup.headers.map((header) => (
          <th
            key={header.id}
            className={classNames({ [css.compactHeader]: compact })}
            onClick={header.column.getToggleSortingHandler()}
            style={{
              width: header.getSize() !== 0 ? header.getSize() : undefined
            }}
          >
            <div className={classNames({ [css.sortableColumn]: header.column.getCanSort() })}>
              {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
              {header.column.getCanSort() && <TableSorting isSorted={header.column.getIsSorted()} />}
            </div>
          </th>
        ))}
      </tr>
    ))}
  </thead>
);
