import ProfileAvatar from "PFComponents/profile_avatar/profile_avatar";
import useIsPermittedTo from "PFCore/helpers/use_is_permitted_to";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import EnvelopeIcon from "PFIcons/envelope.svg";
import LeaveIcon from "PFIcons/leave.svg";
import { PermissionRule } from "PFTypes";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import NavItem from "./nav_item";
import Tips from "./tips";

const YouNavItem = (props) => {
  const { t } = useTranslation("translation", { keyPrefix: "navBar.youNav" });
  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();
  const profileName = currentProfile.first_name || t("yourProfile");
  const isPermittedTo = useIsPermittedTo(currentProfile);
  const signOutNavItem = {
    qaIdName: "sign_out",
    name: (
      <>
        <LeaveIcon width={14} height={14} style={{ marginRight: 10, verticalAlign: "middle" }} />
        <span style={{ verticalAlign: "middle" }}>{t("logout")}</span>
      </>
    ),
    title: t("logout"),
    href: "/sign_out",
    id: "sign_out_link"
  };

  const isAccountSwitched = currentProfile.account_id && currentProfile.account_id !== currentAccount.id;
  const items = isAccountSwitched
    ? [signOutNavItem]
    : [
        {
          name: t("myProfile"),
          href: "/profiles/me",
          badge: t("percentComplete", { value: currentProfile?.statistics?.progress_completion }),
          id: "my_profile"
        },
        { name: t("settings"), href: "/profile/edit", id: "profile_settings" },
        { sep: true },
        {
          name: (
            <>
              <EnvelopeIcon width={14} height={14} style={{ marginRight: 10, verticalAlign: "middle" }} />
              <span style={{ verticalAlign: "middle" }}>{t("sendFeedback")}</span>
            </>
          ),
          title: t("sendFeedback"),
          href: "/contact_us",
          render_condition: isPermittedTo(PermissionRule.ContactForm)
        },
        { sep: true },
        signOutNavItem,
        { content: <Tips handleCloseSubMenu={props.handleCloseSubMenu} /> }
      ];

  return (
    <NavItem
      icon={
        <div style={{ marginTop: -3 }}>
          <ProfileAvatar profile={currentProfile} size={30} />
        </div>
      }
      name={<span style={{ marginTop: 3 }}>{profileName}</span>}
      href={"/profiles/me"}
      isActiveTest={(path) => path.match(/^\/profiles\/me/) || path.match(/^\/profile\/edit/)}
      noHover
      id="you"
      {...props}
      items={items}
      dropdownBottom={true}
    />
  );
};

YouNavItem.propTypes = {
  handleCloseSubMenu: PropTypes.func.isRequired
};

export default YouNavItem;
