import moment from "moment";
import { getProfileName } from "PFCore/helpers/profile";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { useTranslation } from "react-i18next";
import { humanize, titleize } from "underscore.string";

export const workflowStatesByTabs = {
  exceptions: ["exception"],
  manual_interventions: ["shortlisting"]
};

export const tabByWorkflowState = {
  shortlisting: "manual_interventions",
  not_filled: "manual_interventions",
  exception: "exceptions",
  pending: "manual_interventions"
};

export const getExceptionType = (exception) => {
  const isMultiple = exception.availability && exception.compliance;
  return isMultiple
    ? "Multiple"
    : Object.keys(Object.values(exception)[0])
        .map((type) => titleize(humanize(type)))
        .join(", ");
};

export const exceptionsArrayToException = (exceptions) => {
  const exceptionsArray = exceptions.map(({ exception_type, exceptions }) => ({
    [exception_type]: exceptions
  }));
  const requester =
    exceptions.find(({ requester }) => requester)?.requester ||
    (exceptions.find(({ requester_id }) => requester_id) && {
      id: exceptions.find(({ requester_id }) => requester_id).requester_id
    });
  const approver =
    exceptions.find(({ approver }) => approver)?.approver ||
    (exceptions.find(({ approver_id }) => approver_id) && {
      id: exceptions.find(({ approver_id }) => approver_id).approver_id
    });
  const request_reason = exceptions.find(({ request_reason }) => request_reason)?.request_reason;
  return {
    exceptions: exceptionsArray.reduce((acc, curr) => ({ ...curr, ...acc }), {}),
    requester,
    approver,
    request_reason
  };
};

export const getExceptionFromRole = (role) => {
  const exceptions = role.shortlists?.find(({ state }) => ["filled", "booked"].includes(state))?.exceptions;
  return exceptions && exceptions.length > 0 ? exceptionsArrayToException(exceptions) : null;
};

export const mapWorkflowStatesToUI = (status) => {
  if (status === "shortlisting") {
    return "not_filled";
  }

  if (["auto_shortlisting", "auto_filling"].includes(status)) {
    return "pending";
  }

  return status;
};

export const isRoleDisabled = (role) =>
  ["pending", "auto_filling", "auto_shortlisting"].includes(role.workflow_state);

export const isOverbooked = (role) => {
  const profile =
    role.shortlists && role.shortlists.find(({ state }) => ["filled", "booked"].includes(state))?.profile;
  const bookings =
    profile?.availability?.bookings?.filter(({ activity_id }) => activity_id === role.id) || [];

  return bookings.some(({ available_minutes }) => available_minutes < 0);
};

export const useOverbookingMessage = (role) => {
  const { formatDate } = useDateFormatter();
  const { t } = useTranslation("auditPlanner");

  const profile =
    role.shortlists && role.shortlists.find(({ state }) => ["filled", "booked"].includes(state))?.profile;
  const bookings = profile?.availability?.bookings.filter(
    ({ activity_id, available_minutes }) => activity_id === role.id && available_minutes < 0
  );

  if (!profile || !bookings.length === 0) {
    return null;
  }

  const availableMinutes = bookings.reduce(
    (acc, curr) => (curr.available_minutes < 0 ? acc + curr.available_minutes : acc),
    0
  );
  const startDate = bookings
    .slice(1)
    .reduce(
      (acc, curr) => (moment(curr.start_date).isBefore(acc) ? curr.start_date : acc),
      bookings[0].start_date
    );

  const endDate = bookings
    .slice(1)
    .reduce((acc, curr) => (moment(curr.end_date).isAfter(acc) ? curr.end_date : acc), bookings[0].end_date);

  return t("parts.userIsOverbooked", {
    name: getProfileName(profile),
    hours: -availableMinutes / 60,
    startDate: formatDate(moment(startDate).utc()),
    endDate: formatDate(moment(endDate).utc())
  });
};
