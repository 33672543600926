import PropTypes from "prop-types";

import css from "./content.less";
import Description from "./description";

const PostContent = ({ task }) => {
  // We do render an "a" when a link is present and a "div" when is not
  const metadata = task.metadata || {};
  const isLink = metadata && metadata.og && metadata.og.url;
  const RenderTag = isLink ? "a" : "div";

  const linkProps = isLink
    ? {
        target: "_blank",
        rel: "noopener noreferrer",
        href: metadata && metadata.og && metadata.og.url
      }
    : {};

  return (
    <RenderTag className={css.wrapper} {...linkProps}>
      <div>
        {metadata && metadata.og ? (
          <>
            <div className={css.postDescription}>
              <Description description={task.description} descriptionLength={300} />
            </div>
            {metadata.og.image && <img src={metadata.og.image} className={css.image} alt="" />}
            <div className={css.postTitle}>{metadata.og.title}</div>
            <div className={css.postText}>
              <Description description={metadata.og.description} id={task.id} descriptionLength={300} />
            </div>
          </>
        ) : (
          <div className={css.singlePost}>
            <Description description={task.description} descriptionLength={300} />
          </div>
        )}
      </div>
    </RenderTag>
  );
};

PostContent.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    metadata: PropTypes.shape({
      og: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        type: PropTypes.string,
        image: PropTypes.string,
        url: PropTypes.string
      })
    })
  })
};

export default PostContent;
