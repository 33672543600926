import { isEmpty } from "lodash";
import AvailabilityWarningIcon from "PFApp/activities/show/shortlist/availability_warning_icon";
import ProfileCardConnected from "PFApp/components/profile_card_connected";
import { PER_PAGE_UNPAGINATED } from "PFApp/constants/unpaginated";
import { LoadingDots } from "PFComponents/loading_dots";
import SimplePagination from "PFComponents/pagination/simple_pagination";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useActivityRankedShortlists } from "PFCore/hooks/queries/shortlists/use_activity_ranked_shortlists";
import { useActivityShortlists } from "PFCore/hooks/queries/shortlists/use_activity_shortlists";
import { useActivitySuspendedShortlists } from "PFCore/hooks/queries/shortlists/use_activity_suspended_shortlists";
import { Shortlist } from "PFTypes";
import { useState } from "react";

import { useBookingActivityContext } from "../../../providers/booking_activity_context_provider";
import { ShortlistActions } from "./shortlist_actions";

export const ShortlistItems = (): JSX.Element => {
  const { data: currentProfile } = useCurrentProfile();
  const [page, setPage] = useState(1);

  const { id } = useBookingActivityContext();
  const { data: shortlists } = useActivityShortlists(id!, { page });
  const { data: suspendedShortlists } = useActivitySuspendedShortlists(id!, { page });
  const allShortlists = [
    ...(shortlists?.entries || []),
    ...(suspendedShortlists?.entries || [])
  ] as Shortlist[];

  const totalItems = (shortlists?.meta?.total || 0) + (suspendedShortlists?.meta?.total || 0);
  const shortlistsPagesTotal = Math.ceil(totalItems / PER_PAGE_UNPAGINATED);

  const { data: rankedShortlists } = useActivityRankedShortlists({ activityId: id! });

  return !isEmpty(allShortlists) ? (
    <>
      {allShortlists.map((item) => {
        const match = (rankedShortlists?.entries || []).find((it) => it.profile.id === item.profile.id);
        const avaialbilityScore = match?.scores?.availability_score;
        return (
          <ProfileCardConnected
            key={item.id}
            profileResponse={match || item}
            gaugeProps={{
              availabilityIcon:
                avaialbilityScore && avaialbilityScore <= 0 ? <AvailabilityWarningIcon /> : null
            }}
            embeded
            isColumn
            currentProfile={currentProfile}
            actions={<ShortlistActions item={item} />}
          />
        );
      })}
      {allShortlists.length > 0 && (
        <SimplePagination
          currentPage={page}
          handleClick={(number) => setPage(number)}
          totalPages={shortlistsPagesTotal}
        />
      )}
    </>
  ) : (
    <LoadingDots circlesEnabled pageViewEnabled />
  );
};
