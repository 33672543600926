import { FillAndBookButton as WrappedFillAndBookButton } from "PFApp/components/fill_and_book_button";

import { useRoleProfileActionButtonsContext } from "../context/role_profile_action_buttons_context";

export const FillAndBookButton = () => {
  const { role, profile, shortlist, isFillDisabled } = useRoleProfileActionButtonsContext();

  return (
    <WrappedFillAndBookButton
      profileId={profile.id}
      activity={role}
      shortlistId={shortlist?.id}
      isFillButtonDisabled={isFillDisabled}
    />
  );
};
