import classNames from "classnames";
import { useDetailsPanelApiContext } from "PFApp/booking/components/details_panel/details_panel_context/details_panel_context";
import { useRowIconComponent } from "PFApp/booking/hooks/use_row_icon_component";
import { TEMPLATE_KEYS } from "PFApp/constants/templates";
import { useTemplate } from "PFApp/hooks";
import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import ExternalLinkIcon from "PFIcons/external_link.svg";
import { Activity } from "PFTypes";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { activityTypeToSidePanelTypeMap } from "../../../../components/details_panel/details_panel_context/details_panel_context_provider";
import css from "../detail_view.module.scss";
import { useActivityLink } from "./use_activity_link";

export const activityTypeTranslationKeys = {
  [TEMPLATE_KEYS.ROLE]: "role",
  [TEMPLATE_KEYS.AUDIT_ROLE]: "auditRole",
  [TEMPLATE_KEYS.ENGAGEMENT]: "engagement",
  [TEMPLATE_KEYS.AUDIT_ENGAGEMENT]: "auditEngagement"
} as const;

export type ActivityTemplateKey = "role" | "audit_role" | "engagement" | "audit_engagement";

interface BookingDetailsActivityProps {
  activity?: Activity;
  parentActivity?: Activity;
  isLoading?: boolean;
}

export const BookingDetailsActivity = ({
  activity,
  parentActivity,
  isLoading
}: BookingDetailsActivityProps): JSX.Element | null => {
  const { t } = useTranslation("translation");
  const { openDetailsPanel } = useDetailsPanelApiContext();

  const { name, template_id: templateId } = activity || {};
  const { key: templateKey } = useTemplate(templateId) || {};

  const RowIconComponent = useRowIconComponent({
    templateId: templateId ?? null,
    type: templateKey || ""
  });

  const linkUrl = useActivityLink({ activity, parentActivity });

  const openActivitySidePanel = () => {
    activity?.id &&
      templateKey &&
      openDetailsPanel(
        {
          id: activity.id,
          type: activityTypeToSidePanelTypeMap[templateKey]
        },
        true
      );
  };

  if (!activity) {
    return null;
  }

  if (isLoading) {
    return <LoadingDots />;
  }

  return (
    <div className={css.activityDetails}>
      {templateKey && <div className={css.label}>{t(activityTypeTranslationKeys[templateKey])}</div>}
      <div className={classNames(css.alignCenter, css.gap5)}>
        {RowIconComponent && <RowIconComponent />}
        {name && (
          <Button kind="text" className={css.activityName} onClick={openActivitySidePanel}>
            {name}
          </Button>
        )}
        {linkUrl && (
          <Link to={linkUrl}>
            <ExternalLinkIcon height={20} width={20} className={css.icon} />
          </Link>
        )}
      </div>
    </div>
  );
};
