import { differenceBy } from "lodash";
import {
  CurrentProfile,
  CustomField,
  CustomType,
  DEFAULT_SKILL_EXPERIENCE,
  ESSENTIAL_SKILL_WEIGHT,
  Keyword,
  PureCustomValue,
  REQUIRED_SKILL_WEIGHT,
  SUPPORTING_SKILL_WEIGHT,
  Weight
} from "PFTypes";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { MatchAttributes } from "../../../components/match_attributes";
import { ActivitySkillsSettings } from "../../components/activity_skills_settings";
import { ComponentProps } from "../component_prop_types";
import { SkillsEditField } from "./parts/skills_edit_field";

type SkillsCustomValuesEditFieldWrapperProps = ComponentProps & {
  value: PureCustomValue[];
  error: Record<string, any>[] | string;
  customType: CustomType;
  connectedFields: CustomField[];
  currentProfile: CurrentProfile;
  qaIdPrefix: string;
  name?: string;
  description?: string;
  usedOn: "Activity_edition" | "Activity_creation";
};

export const SkillsCustomValuesEditFieldWrapper = ({
  property,
  onChange,
  value: skills,
  error,
  customType,
  connectedFields,
  currentProfile,
  name,
  description,
  qaIdPrefix,
  required,
  usedOn
}: SkillsCustomValuesEditFieldWrapperProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "edit.sections.match" });
  const developmentalSkillsEnabled = localStorage.getItem("developmental_skills_enabled") === "true";

  const requiredSkills = skills.filter(({ weight }) => weight === REQUIRED_SKILL_WEIGHT);
  const essentialSkills = skills.filter(({ weight }) => weight === ESSENTIAL_SKILL_WEIGHT);
  const supportingSkills = skills.filter(({ weight }) => weight === SUPPORTING_SKILL_WEIGHT);

  const getHandleSkillsChangeFunction = useCallback(
    (weight: Weight = null) =>
      (updatedValues: PureCustomValue[]) => {
        const skillsWithOtherWeight = skills.filter((skill) => skill.weight !== weight);

        const updatedSkills = updatedValues.map((skill) => ({ ...skill, weight }));

        onChange([...skillsWithOtherWeight, ...updatedSkills]);
      },
    [onChange, skills]
  );

  const handleRequiredSkillsChange = useCallback(getHandleSkillsChangeFunction(REQUIRED_SKILL_WEIGHT), [
    getHandleSkillsChangeFunction
  ]);
  const handleEssentialSkillsChange = useCallback(getHandleSkillsChangeFunction(ESSENTIAL_SKILL_WEIGHT), [
    getHandleSkillsChangeFunction
  ]);
  const handleSupportingSkillsChange = useCallback(getHandleSkillsChangeFunction(SUPPORTING_SKILL_WEIGHT), [
    getHandleSkillsChangeFunction
  ]);

  const handleRelevantAttributeChange = (
    values: (Keyword | PureCustomValue)[],
    weight = ESSENTIAL_SKILL_WEIGHT
  ) => {
    const currentValueIds = skills.map(({ id }) => id);
    const formattedValues = values
      .map((value) => ({
        ...value,
        text: String("value" in value ? value.value : value.text),
        weight: (value as PureCustomValue).weight || null
      }))
      .map((val) =>
        currentValueIds.includes(val.id) ? val : { ...val, weight, experience: DEFAULT_SKILL_EXPERIENCE }
      ) as PureCustomValue[];

    onChange(formattedValues);
  };

  return (
    <>
      {developmentalSkillsEnabled && <ActivitySkillsSettings />}
      <SkillsEditField
        label={t("essentialSkills")}
        qaId={`${qaIdPrefix}_essential`}
        connectedFields={connectedFields}
        customType={customType}
        onUpdate={handleEssentialSkillsChange}
        property={property}
        filterOptions={(options) => differenceBy(options, [...requiredSkills, ...supportingSkills], "id")}
        values={essentialSkills}
        required={required}
        error={error}
      />

      {property.match && (
        <MatchAttributes
          name={name}
          description={description}
          attributes={skills}
          onMatchAttributeChange={(values) => handleRelevantAttributeChange(values)}
          userId={currentProfile.id}
          usedOn={usedOn}
          type={customType.name}
        />
      )}

      <SkillsEditField
        label={t("supportingSkills")}
        customType={customType}
        connectedFields={connectedFields}
        values={supportingSkills}
        qaId={qaIdPrefix}
        onUpdate={handleSupportingSkillsChange}
        filterOptions={(options) => differenceBy(options, [...requiredSkills, ...essentialSkills], "id")}
        property={property}
      />
      <SkillsEditField
        label={t("hardFilter")}
        customType={customType}
        connectedFields={connectedFields}
        values={requiredSkills}
        qaId={qaIdPrefix}
        onUpdate={handleRequiredSkillsChange}
        filterOptions={(options) => differenceBy(options, [...essentialSkills, ...supportingSkills], "id")}
        property={property}
      />
    </>
  );
};
