import { decamelizeKeys } from "humps";
import { Profile } from "PFTypes";
import { Id } from "PFTypes/id";
import { QueryParams } from "PFTypes/query_params";

import api from "../api/api";

type ExportProfilesQueryParams = QueryParams & { profileIds?: Id[]; activityId?: Id };
type ExportProfilesResponse = Partial<Profile & { score: number }>[];

// TODO: [PROF-5746] Remove decamelizeKeys when app is camelized
export const getExportProfiles = (data: ExportProfilesQueryParams) =>
  api<ExportProfilesResponse>({ url: "export/profiles/index", method: "POST", data }).then(
    (resp) => decamelizeKeys(resp) as ExportProfilesResponse
  );
