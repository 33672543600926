import classNames from "classnames";
import { compact } from "lodash";
import { CustomValuesEditField } from "PFApp/components/custom_values_edit_field";
import { getCanEditCustomFieldData } from "PFApp/components/custom_values_edit_field/custom_values_edit_field.utils";
import { useGrowl } from "PFApp/use_growl";
import { ActionIcon } from "PFComponents/action_icon";
import { Button } from "PFComponents/button";
import { RouterPrompt } from "PFComponents/router_prompt/router_prompt";
import AutoSelect from "PFComponents/select/autoselect";
import { useCustomValueToString } from "PFCore/helpers/custom_value";
import { getJobTitle, getMainPosition, getProfileName } from "PFCore/helpers/profile";
import { ProfileNameLink } from "PFCore/helpers/profile_name_link";
import useConfirm from "PFCore/helpers/use_confirm";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import useIsPermittedTo from "PFCore/helpers/use_is_permitted_to";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { fetchProfilesOptions } from "PFCore/services/profile";
import { FeatureFlag, PermissionRule } from "PFTypes";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { EXPIRABLE_CUSTOM_TYPES } from "../../common/expirable_custom_types";
import { ShortlistForProfileButton } from "../../shortlist_for_profile";
import css from "./basic_info.module.scss";
import PageSection from "./page_section";

const Pair = ({ title, qaId, children }) => (
  <div data-qa-id={qaId} className={css.item} key={qaId}>
    <span className={css.title}>{title}</span> <span className={css.val}>{children}</span>
  </div>
);

Pair.propTypes = {
  title: PropTypes.string,
  qaId: PropTypes.string,
  children: PropTypes.node
};

const CustomFieldItem = ({ profile, isMe, customType, hasValuesWithExpiryDate, translation }) => {
  const { name, value_type: valueType, display_as: displayAs } = customType;
  const { formatDate, utc } = useDateFormatter();
  const { getCustomValuesListString } = useCustomValueToString();

  const valueWithTime = useCallback(
    (cf, index, itemsLength, translation) => (
      <div key={cf.text} className={css.valueTime}>
        {cf.text}
        {cf.expiry_date && (
          <>
            {" | "}
            <div className={css.valueTimeText}>
              {translation("show.parts.expDate", { date: formatDate(utc(cf.expiry_date)) })}
            </div>
          </>
        )}
        {index < itemsLength - 1 && <div className={css.coma}>{", "}</div>}
      </div>
    ),
    [formatDate, utc]
  );

  const valueWithTimeFormater = useCallback(
    (items, translation) => items.map((cf, index) => valueWithTime(cf, index, items.length, translation)),
    [valueWithTime]
  );

  const getMainPositionCfVals = useCallback(
    (name) => {
      const mainPositionValue = getMainPosition(profile)[name];
      // if value is not array it means it is not a custom field but other position attribute e.g. start_date
      Array.isArray(mainPositionValue) ? mainPositionValue : null;
    },
    [profile]
  );

  const qaId = `profile-${name}`;
  const profileCfVals = profile[name] || [];
  const cfVals = profileCfVals.length > 0 ? profileCfVals : getMainPositionCfVals(name) || [];

  if (valueType && (cfVals.length > 0 || isMe)) {
    const text = hasValuesWithExpiryDate
      ? valueWithTimeFormater(cfVals, translation, formatDate)
      : getCustomValuesListString(cfVals, valueType);

    return (
      <Pair title={displayAs || name} qaIs={qaId}>
        {hasValuesWithExpiryDate ? text.map((item) => item) : text || "-"}
      </Pair>
    );
  }

  // we only should skip cfs if not a "me" profile:
  return <React.Fragment key={qaId} />;
};

CustomFieldItem.propTypes = {
  profile: PropTypes.any,
  isMe: PropTypes.bool.isRequired,
  customType: PropTypes.shape({
    name: PropTypes.string,
    value_type: PropTypes.string,
    display_as: PropTypes.string
  }).isRequired,
  hasValuesWithExpiryDate: PropTypes.bool,
  translation: PropTypes.func
};

const BasicInfo = ({
  profile,
  basicCustomTypesNames,
  isEditMode,
  isLoading,
  isMe,
  onProfileUpdate,
  onEditModeChange,
  errors,
  className,
  portalRef
}) => {
  const { data: currentProfile } = useCurrentProfile();
  const { customTypes } = useCustomTypes();
  const { t } = useTranslation("profiles");
  const growl = useGrowl();
  const isEnabled = useIsFeatureEnabled();
  const isPermittedTo = useIsPermittedTo(currentProfile);
  const [profileFieldsToUpdate, setProfileFieldsToUpdate] = useState({});

  const isChanged = Object.values(profileFieldsToUpdate).length > 0;
  const confirmNavigation = useConfirm(isChanged);
  const { supervisor } = profile;
  const supervisorValue = supervisor
    ? [
        {
          id: supervisor.id,
          text: supervisor.text || getProfileName(supervisor)
        }
      ]
    : [];

  const handleEditModeChange = ({ requireConfirmation = true }) => {
    if (requireConfirmation && isEditMode && isChanged) {
      if (!confirmNavigation()) {
        return;
      }
    }
    setProfileFieldsToUpdate({});
    setSupervisorValues(supervisorValue);
    onEditModeChange();
  };

  const basicCustomTypes = compact(
    basicCustomTypesNames.map((name) => customTypes.find((customType) => customType.name === name))
  );

  const [supervisorValues, setSupervisorValues] = useState(supervisorValue);

  const isAllowedToEdit = isMe || isPermittedTo(PermissionRule.ProfessionalProfileEdit);
  const hasEditableFields =
    isEnabled(FeatureFlag.ReportsTo) ||
    basicCustomTypes
      .map((customType) =>
        getCanEditCustomFieldData({
          adminPage: false,
          profile,
          customType,
          currentProfile,
          t
        })
      )
      .some(({ locked }) => !locked);

  useEffect(() => {
    if (isEditMode && (!isAllowedToEdit || !hasEditableFields)) {
      handleEditModeChange({ requireConfirmation: false });
    }
  }, [isEditMode, isAllowedToEdit, hasEditableFields]);

  return (
    <PageSection
      className={className}
      hType="h3"
      id="basic_info"
      title={t("show.parts.organisationData")}
      contentStyle={{ overflow: "auto" }}
      action={
        <div className={css.sectionActions}>
          {isAllowedToEdit && hasEditableFields && (
            <ActionIcon onClick={handleEditModeChange} name={isEditMode ? "cross" : "edit"} size="sm" />
          )}
          <ShortlistForProfileButton profile={profile} />
        </div>
      }
    >
      <div className={css.root}>
        {isEditMode ? (
          <div className={classNames(css.editPanel, { [css.disabled]: isLoading })}>
            <RouterPrompt when={isChanged} />
            {isEnabled(FeatureFlag.ReportsTo) && (
              <AutoSelect
                controlledValue
                style={{ marginBottom: 30, flexGrow: 1 }}
                multi={false}
                query={(term) => fetchProfilesOptions({ term })}
                filterOptions={(response) => response.filter((item) => currentProfile.id !== item.id)}
                values={supervisorValues}
                label={t("show.parts.reportsTo")}
                handleChange={(value) => {
                  setSupervisorValues(value);
                  setProfileFieldsToUpdate((prev) => ({ ...prev, supervisor_id: value[0]?.id || null }));
                }}
                closeOnChange
                tip={t("show.parts.yourSupervisor")}
                qaId="profile-basic-info-reports-to"
                letClear
                lockedTipPosition="bottom"
              />
            )}
            {basicCustomTypes.map((customType) => (
              <CustomValuesEditField
                key={customType.name}
                customType={customType}
                values={profileFieldsToUpdate[customType.name] || profile[customType.name]}
                errors={errors && errors[customType.name]}
                qaIdPrefix="profile-basic-info"
                adminPage={false}
                profile={profile}
                handleChange={(values) =>
                  setProfileFieldsToUpdate((prev) => ({ ...prev, [customType.name]: values }))
                }
                lockedTipPosition="bottom"
                classes={{ root: css.customValueField }}
                portalRef={portalRef}
              />
            ))}
            <Button
              kind="primary"
              style={{ alignSelf: "center" }}
              onClick={() =>
                onProfileUpdate(profileFieldsToUpdate).then(() => {
                  setProfileFieldsToUpdate({});
                  growl({
                    message: t("show.parts.organisationDataUpdated"),
                    kind: "success"
                  });
                })
              }
              qaId="profile-basic-info-update-button"
              disabled={isLoading}
            >
              {t("translation:update")}
            </Button>
          </div>
        ) : (
          <div className={css.columns}>
            <Pair title={t("show.parts.jobTitle")} qaId={"profile-role"}>
              {getJobTitle(profile) || ""}
            </Pair>

            {isEnabled(FeatureFlag.ReportsTo) && profile.supervisor && (
              <Pair title={t("show.parts.reportsTo")} qaId="profile-reports-to">
                <ProfileNameLink profile={profile.supervisor} />
              </Pair>
            )}

            {basicCustomTypes.map((customType) => (
              <CustomFieldItem
                key={customType.name}
                profile={profile}
                isMe={currentProfile.id === profile.id}
                customType={customType}
                hasValuesWithExpiryDate={EXPIRABLE_CUSTOM_TYPES.includes(customType?.name)}
                translation={t}
              />
            ))}
          </div>
        )}
      </div>
    </PageSection>
  );
};

BasicInfo.propTypes = {
  profile: PropTypes.shape({
    id: PropTypes.number.isRequired,
    supervisor: PropTypes.object,
    permissions_group: PropTypes.object
  }).isRequired,
  onProfileUpdate: PropTypes.func.isRequired,
  onEditModeChange: PropTypes.func.isRequired,
  basicCustomTypesNames: PropTypes.array.isRequired,
  isEditMode: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMe: PropTypes.bool,
  errors: PropTypes.object,
  className: PropTypes.string,
  portalRef: PropTypes.object
};

BasicInfo.defaultProps = {
  isEditMode: false
};

export default BasicInfo;
