import classNames from "classnames";
import moment from "moment";
import { useCallback, useMemo } from "react";

import css from "./week_days_select.module.scss";

const weekDayNumbers = Array.from({ length: 7 }, (_, i) => i);
const weekdays = weekDayNumbers.map((i) => moment.weekdaysShort(i + 1)).map((wd) => wd[0]);

const Weekday = ({
  disabled,
  weekDayNumber,
  onClick,
  selected
}: {
  weekDayNumber: number;
  onClick: (day: number) => void;
  selected: boolean;
  disabled: boolean;
}) => (
  <button
    disabled={disabled}
    className={classNames(css.day, { [css.selected]: selected, [css.disabled]: disabled })}
    onClick={() => onClick(weekDayNumber)}
  >
    {weekdays[weekDayNumber]}
  </button>
);

type IProps = {
  value: number | null;
  onChange?: (value: number) => void;
  disabled?: boolean;
  disabledDays?: number[];
};

const WeekDaysSelect = ({ disabledDays, value, onChange, disabled }: IProps) => {
  const selectedIndexes = useMemo(() => (value || 0).toString(2).split("").reverse(), [value]);

  const toggleDay = useCallback(
    (i) => {
      if (disabled) {
        return;
      }
      const val = value || 0;
      const selected = val.toString(2).split("").reverse()[i] === "1";
      const newValue = selected ? val - Math.pow(2, i) : val + Math.pow(2, i);
      if (newValue > 0) {
        onChange && onChange(newValue);
      }
    },
    [value]
  );

  return (
    <div className={classNames(css.root, { [css.disabled]: disabled })}>
      {weekDayNumbers.map((i) => (
        <Weekday
          key={i}
          disabled={!!disabledDays?.includes(i)}
          weekDayNumber={i}
          selected={selectedIndexes[i] === "1"}
          onClick={toggleDay}
        />
      ))}
    </div>
  );
};

export default WeekDaysSelect;
