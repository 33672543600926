import { Button } from "PFComponents/button";
import { useTranslation } from "react-i18next";

import css from "./edit_button.module.scss";

type EditButtonProps = {
  isEditMode: boolean;
  setEditMode: (value: boolean) => void;
  labelOn?: boolean;
  disabled?: boolean;
};

const EditButton = ({ setEditMode, isEditMode, labelOn, disabled }: EditButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button
      icon={isEditMode ? "check" : "edit"}
      text={isEditMode ? (labelOn ? t("save") : "") : t("edit")}
      className={css.button}
      onClick={() => setEditMode(!isEditMode)}
      kind="secondary"
      disabled={disabled}
    />
  );
};

export default EditButton;
