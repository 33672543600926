import { find } from "lodash";
import { Select } from "PFComponents/select/select";
import { Id } from "PFTypes";
import { Phase } from "PFTypes/phase";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { usePhases } from "../../../../hooks/queries/phases/use_phases";
import { DropdownOption } from "../../../dropdown/dropdown";
import { PHASES_ERROR_KEY } from "../../constants";
import { getFieldError } from "../../errors_helper";

type PhasesSelectProps = {
  className?: string;
  selectedPhaseSourceId?: string;
  activityId?: Id;
  onChange: (phase: Phase) => void;
  errors?: Record<string, string>;
  portalRef?: React.RefObject<HTMLDivElement>;
};

export const PhasesSelect = ({
  className,
  selectedPhaseSourceId,
  activityId,
  onChange,
  errors = {},
  portalRef
}: PhasesSelectProps) => {
  const { t } = useTranslation();
  const { data: phases } = usePhases(activityId);

  const phasesOptions: DropdownOption[] = useMemo(
    () =>
      phases?.entries.map((phase) => ({
        id: phase.sourceId,
        displayElement: phase.name,
        item: phase
      })) || [],
    [phases]
  );

  const handlePhaseChange = (phase: Phase) => {
    const selectedPhase = find(phases?.entries, ["sourceId", phase.sourceId]) as Phase;

    if (!selectedPhase) {
      return;
    }

    onChange(selectedPhase);
  };

  return (
    <Select
      className={className}
      label={t("availabilityRequirement.phasesSelectLabel")}
      placeholder={t("availabilityRequirement.phasesSelectPlaceholder")}
      options={phasesOptions}
      disabled={!phasesOptions.length}
      value={find(phasesOptions, ["id", selectedPhaseSourceId])?.displayElement}
      // @ts-ignore
      onChange={handlePhaseChange}
      error={getFieldError(errors, PHASES_ERROR_KEY)}
      portalRef={portalRef}
      controlledValue
      required
    />
  );
};
