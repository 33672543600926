import { Typography } from "PFComponents/typography";

import { CompletionRateBadge } from "./completion_rate_badge";
import css from "./header.module.scss";

type HeaderProps = {
  reversePositive?: boolean;
  coverage?: number;
  title: string;
  series: {
    data: { value: number; unit?: string }[];
  }[];
};

export const Header = ({ coverage, reversePositive, series, title }: HeaderProps) => (
  <div className={css.header}>
    <Typography variant="h4" noMargin>
      {title}
    </Typography>
    <CompletionRateBadge series={series} reversePositive={reversePositive} coverage={coverage} />
  </div>
);
