import { Id } from "PFTypes";

import { ActionIcon } from "../../action_icon";
import { PillTab, PillTabs } from "../../tabs/pill_tabs";
import { Typography } from "../../typography";
import css from "./side_panel_header.module.scss";
import { HEADER_SPACING_OFFSET } from "./side_panel_header.utils";

type SidePanelHeaderProps<TAB_ID> = {
  title?: string | React.ReactNode;
  actions?: React.ReactNode;
  onClose?: () => void;
  tabsConfig: HeaderTabs<TAB_ID> | undefined;
};

export type HeaderTabs<TAB_ID> = {
  tabs: PillTab<TAB_ID>[];
  selectedTabId: TAB_ID;
  onTabClick: (id: TAB_ID) => void;
};

export const SidePanelHeader = <TAB_ID extends Id>({
  title,
  actions,
  onClose,
  tabsConfig
}: SidePanelHeaderProps<TAB_ID>) => (
  <div
    className={css.headerContainer}
    style={{ gap: HEADER_SPACING_OFFSET, marginBottom: HEADER_SPACING_OFFSET }}
  >
    <div className={css.header}>
      {title &&
        (typeof title === "string" ? (
          <Typography noMargin variant="h4">
            {title}
          </Typography>
        ) : (
          title
        ))}
      {(onClose || actions) && (
        <div className={css.actions}>
          {actions}
          {onClose && (
            <ActionIcon
              name="cross"
              color="paletteBlue0"
              size="sm"
              onClick={onClose}
              classes={{ root: css.close }}
            />
          )}
        </div>
      )}
    </div>
    {tabsConfig && (
      <div className={css.pillTabsContainer}>
        <PillTabs
          tabs={tabsConfig.tabs}
          selectedId={tabsConfig.selectedTabId}
          onTabClick={(newId) => tabsConfig.onTabClick(newId)}
        />
      </div>
    )}
  </div>
);
