import { AvailabilityMode } from "PFTypes/booking";

export const DEFAULT_MONTHS_FORWARD_LIMIT = 6;

export const ROOT_ERROR_KEY = "metadata[availability]";
export const RANGES_ERROR_KEY = `${ROOT_ERROR_KEY}[ranges]`;
export const DURATION_VALUE_ERROR_KEY = `[duration][value]`;
export const START_ERROR_KEY = `[start]`;
export const END_ERROR_KEY = `[end]`;
export const PHASES_ERROR_KEY = `[phase_source_id]`;
export const AVAILABILITY_MODES = [
  AvailabilityMode.TimeRule,
  AvailabilityMode.Within,
  AvailabilityMode.PerWeek,
  AvailabilityMode.PerMonth
];
