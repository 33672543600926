import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { FeatureFlag, PermissionRule } from "PFTypes";

export const useBookingModulePermissions = () => {
  const { data: currentProfile } = useCurrentProfile();

  const isPermittedTo = useIsCurrentUserPermittedTo();
  const isEnabled = useIsFeatureEnabled();
  const isBMEnabled = isEnabled(FeatureFlag.BookingModule);
  const isPermittedToEdit = isPermittedTo(PermissionRule.BookingResourcer);
  const isPermittedToAccess = isPermittedTo(PermissionRule.BookingAccess);

  const canProfileCreateBooking = (profileId?: number) =>
    isBMEnabled && (isPermittedToEdit || (isPermittedToAccess && profileId === currentProfile.id));

  return {
    isEnabled: isBMEnabled,
    isPermittedToEdit,
    isPermittedToAccess,
    isAdmin: isPermittedTo(PermissionRule.BookingAdmin),
    canProfileCreateBooking
  };
};

export default useBookingModulePermissions;
