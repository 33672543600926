import classNames from "classnames";
import { IconName } from "PFTheme/graphics/icons";

import { Icon } from "../../../icon";
import Tooltip from "../../../tooltip/tooltip";
import { Typography } from "../../../typography";
import css from "../input_field_set.module.scss";

type LabelProps = {
  label?: string;
  labelTooltip?: {
    content: string;
    icon: IconName;
  };
  required?: boolean;
  hidden?: boolean;
  tagId: string;
};

export const Label = ({ label, labelTooltip, required, hidden, tagId }: LabelProps) => {
  const text = required ? `${label} *` : label;

  return (
    <label htmlFor={tagId} className={classNames(css.label, { [css.hidden]: hidden })} title={label}>
      <Typography tag="span" variant="labelRegular" noMargin>
        {text}
      </Typography>
      {labelTooltip && (
        <Tooltip
          content={
            <Typography variant="bodyRegular" tag="span">
              {labelTooltip.content}
            </Typography>
          }
        >
          <span className={css.iconContainer}>
            <Icon size="sm" name={labelTooltip.icon} className={css.icon} />
          </span>
        </Tooltip>
      )}
    </label>
  );
};
