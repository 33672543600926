import classNames from "classnames";
import { fetchActivity } from "PFCore/services/activities";
import { removeInterest, showInterest } from "PFCore/services/activities";
import LikedIcon from "PFIcons/liked";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./footer.less";

const PostFooter = ({ taskId }) => {
  const { t } = useTranslation("feed");
  const [liked, setLiked] = useState(false);
  // null when current user is not a watcher
  const [interestId, setInterestId] = useState(null);
  const [likesCount, setLikesCount] = useState(0);
  const [updateTask, setUpdateTask] = useState(false);

  useMemo(() => {
    fetchActivity(taskId).then((resp) => {
      setInterestId(resp?.interest_id);
      setLiked(!!resp?.interest_id);
      setLikesCount(resp?.statistics?.interests_count);
    });
  }, [updateTask]);

  const handleClick = () => {
    (interestId ? removeInterest(taskId, interestId) : showInterest(taskId)).then(() =>
      setUpdateTask((prev) => !prev)
    );
    setLiked((prev) => !prev);
  };

  return (
    <div className={css.wrapper}>
      {/* eslint-disable-next-line max-len */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div className={css.likeRow} onClick={handleClick}>
        <LikedIcon className={classNames(css.like, { [css.liked]: liked })} />
        {likesCount > 0 && t("post.like", { count: likesCount })}
      </div>
    </div>
  );
};

PostFooter.propTypes = {
  profileId: PropTypes.number,
  taskId: PropTypes.number
};

PostFooter.defaultProps = {
  likesCount: 0
};

export default PostFooter;
