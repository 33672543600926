import classNames from "classnames";
import { isString } from "lodash";
import { IconName } from "PFTheme/graphics/icons";
import { forwardRef } from "react";
import { humanize } from "underscore.string";

import { Icon } from "../icon";
import css from "./button.module.scss";

// Usage of autosuggest should be replaced with clickable badge
export type ButtonKind =
  | "primary"
  | "secondary"
  | "tertiary"
  | "ghost"
  | "danger"
  | "text"
  | "blank"
  | "landing-blue"
  | "autosuggest";

type ConditionalProps =
  | {
      icon?: never;
      iconPlacement?: never;
      text?: never;
      children: React.ReactNode;
    }
  | {
      icon?: IconName;
      iconPlacement?: "left" | "right";
      text?: string;
      children?: never;
    };

export type ButtonProps = ConditionalProps & {
  href?: string;
  target?: "_blank";
  disabled?: boolean;
  kind?: ButtonKind;
  small?: boolean;
  style?: React.CSSProperties;
  qaId?: string | number;
  className?: string;
  id?: string;
  download?: string;
  title?: string;
  tag?: "a" | "button";
  type?: "button" | "submit" | "reset";
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export const Button = forwardRef(
  (
    {
      children,
      icon,
      text,
      kind = "primary",
      tag: Tag = "button",
      disabled,
      small,
      style,
      className,
      id,
      qaId,
      title,
      // button props
      type,
      // anchor props
      href,
      target,
      download,
      iconPlacement = "left",
      onClick,
      onMouseEnter,
      onMouseLeave
    }: ButtonProps,
    ref
  ) => {
    const iconOnly = icon && !text && !children;
    const tagClassName = classNames(css.button, className, css[`button-${kind}`], {
      [css.buttonSmall]: small,
      [css.iconOnly]: iconOnly && !small,
      [css.iconOnlySmall]: iconOnly && !!small
    });

    const renderButtonContent = () => {
      if (children) {
        return children;
      }

      const iconComponent = icon && <Icon name={icon} size="sm" />;

      return (
        <>
          {iconPlacement === "left" && iconComponent}
          {text && <span className={css.text}>{text}</span>}
          {iconPlacement === "right" && iconComponent}
        </>
      );
    };

    return (
      <Tag
        ref={ref as any}
        disabled={disabled}
        href={href}
        target={target}
        rel={target === "_blank" ? "noopener noreferrer" : undefined}
        className={tagClassName}
        id={id}
        style={style}
        data-qa-id={qaId}
        download={download}
        title={title || text || (isString(children) && children) || humanize(icon || "")}
        type={type}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {renderButtonContent()}
      </Tag>
    );
  }
);

Button.displayName = "Button";
