import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { filterElements } from "PFApp/use_filtered_collection";
import { useMemo } from "react";

import { useActivityPropertiesNames } from "../../../hooks/use_activity_properties_names";

export const useActivityPropertyFilters = () => {
  const schemaPropertiesNames = useActivityPropertiesNames();
  const { matchesMeta } = useActivityPageContext();

  const excludedPropertyFilters = useMemo(
    () => filterElements(matchesMeta.filters, schemaPropertiesNames),
    [matchesMeta.filters, schemaPropertiesNames]
  );

  const includedPropertyFilters = useMemo(
    () => filterElements(matchesMeta.filters, ({ name }) => !!name && schemaPropertiesNames.includes(name)),
    [matchesMeta.filters, schemaPropertiesNames]
  );

  return { excludedPropertyFilters, includedPropertyFilters };
};
