import { compact } from "lodash";
import { LoadingSpinner } from "PFComponents/loading_spinner";
import { Item } from "PFComponents/radios/radios";
import { Typography } from "PFComponents/typography";
import { Booking } from "PFTypes";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import { useFutureBookings } from "../../hooks/use_future_bookings";
import { BookingQuestions, BookingSelectOption, Messages } from "../booking_questions/booking_questions";
import { BookingDeleteAllOption, DeleteAllBookingsQuestion } from "./delete_all_bookings_question";
import { DeleteMode } from "./delete_booking.types";
import css from "./delete_booking_modal.module.scss";

type RoleRelatedBookingsModalContentProps = {
  booking: Booking;
  deleteMode: DeleteMode;
  userName: string;
  roleName: string;
  value: BookingSelectOption;
  onChange: (selectedOption: BookingSelectOption) => void;
  deleteAllOption: BookingDeleteAllOption;
  onChangeDeleteAllOption: (option: BookingDeleteAllOption) => void;
  isPermittedToResetShortlist: boolean;
};

export const RoleRelatedBookingsModalContent = ({
  booking,
  deleteMode,
  userName,
  roleName,
  value,
  onChange,
  deleteAllOption,
  onChangeDeleteAllOption,
  isPermittedToResetShortlist
}: RoleRelatedBookingsModalContentProps) => {
  const { t } = useTranslation("bookingModule");

  const { isFirstBooking, hasFutureBookings, isFetching } = useFutureBookings({
    thisBooking: { id: booking.id, startDate: booking.start_date },
    activityId: booking.activity_id ?? -1,
    profileId: booking.profile_id
  });

  const DELETE_OPTIONS: Item<BookingSelectOption>[] = compact([
    { id: BookingSelectOption.This, text: t("bookings.delete.options.this") },
    !isFirstBooking &&
      hasFutureBookings && { id: BookingSelectOption.Future, text: t("bookings.delete.options.future") },
    { id: BookingSelectOption.All, text: t("bookings.delete.options.all") }
  ]);

  const messages: Messages = {
    This: t("bookings.delete.body.deleteSingleBooking", {
      userName,
      roleName
    }),
    Future: t("bookings.delete.body.deleteThisAndFutureBookings", { userName, roleName }),
    All: (
      <Trans
        t={t}
        i18nKey="bookings.delete.body.deleteAllBookings"
        values={{
          userName,
          roleName,
          suffixText: isPermittedToResetShortlist ? t("bookings.delete.body.deleteAllBookingsSuffix") : ""
        }}
        components={{ 1: <br /> }}
      />
    )
  };

  const modalBody = useMemo(() => {
    switch (deleteMode) {
      case DeleteMode.NoActivityBooking:
        return t("bookings.delete.body.noActivity");
      case DeleteMode.LastBooking:
        return t("bookings.delete.body.deleteSingleBooking", {
          userName,
          roleName
        });
    }
  }, [deleteMode, t, roleName, userName]);

  return (
    <>
      {deleteMode === DeleteMode.MultipleBookings && (
        <>
          {isFetching ? (
            <LoadingSpinner />
          ) : (
            <BookingQuestions
              text={t("bookings.delete.body.question")}
              className={css.roleRelatedOptions}
              messages={messages}
              value={value}
              items={DELETE_OPTIONS}
              onChange={onChange}
            />
          )}
          {isPermittedToResetShortlist && value === BookingSelectOption.All && (
            <DeleteAllBookingsQuestion
              value={deleteAllOption}
              onChange={(item) => onChangeDeleteAllOption(item.id)}
              profileName={userName}
            />
          )}
        </>
      )}
      <Typography variant="bodyRegular">{modalBody}</Typography>
      {isPermittedToResetShortlist && deleteMode === DeleteMode.LastBooking && (
        <>
          <Typography variant="bodyRegular">
            {t("bookings.delete.body.resetShortlist.lastBookingMessage")}
          </Typography>
          <DeleteAllBookingsQuestion
            value={deleteAllOption}
            onChange={(item) => onChangeDeleteAllOption(item.id)}
            profileName={userName}
          />
        </>
      )}
    </>
  );
};
