import api from "PFCore/api";
import { ExportResponse } from "PFCore/services/common";
import { PaginatedCollection, Role } from "PFTypes";
import { DocFormat } from "PFTypes/doc_format";
import { QueryParams } from "PFTypes/query_params";

export type FetchRolesParams = QueryParams<{
  id?: number[];
  parentActivityId?: number;
  state?: string[];
  workflow_state?: string[];
}>;

export type FetchRolesResponse = PaginatedCollection<{ activity: Role; id: number }[]>;

export const fetchRolesDocs = (docType: DocFormat, params: FetchRolesParams): Promise<ExportResponse> =>
  api({
    url: "roles",
    params,
    headers: {
      Accept: `application/vnd.profinda+${docType};version=2`
    }
  });

export const fetchRoles = (params: FetchRolesParams): Promise<FetchRolesResponse> =>
  api({
    url: "roles",
    params
  });

export const fetchAuditRoles = (params: FetchRolesParams): Promise<FetchRolesResponse> =>
  api({
    url: "audit_roles",
    params
  });
